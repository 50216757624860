import * as wbCardApi from 'api/wbApi/wbCardApi';
import ListStorePrototype from '../prototypes/ListStore.prototype';
import { WbItem } from '../productStore';
import { WbCardConfiguration, WBCardType } from 'api/wbApi/wbCardApi';

export type WbCardWithConfiguration = {
    wb_item_id: number;
    card: WBCardType;
    configuration: WbCardConfiguration;
    enable: boolean;
};

type WbPriceImt = {
    nmId: number;
    price: number;
    discount: number;
    promoCode: number;
};

export type WbPriceImtProduct = WbPriceImt & {
    product_id: number | null;
    wbItem: WbItem;
    enable: boolean;
};

class WbCardStore extends ListStorePrototype<WbCardWithConfiguration, WbPriceImtProduct> {
    listFilterClear = {};

    constructor() {
        super('wb_item_id', 'user', wbCardApi);
    }
}

export default new WbCardStore();
