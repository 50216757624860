import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import { DeliveryOnMap } from 'api/deliveryApi';

import deliveryStore from 'stores/deliveryStore';
import deliveryPointsStore from 'stores/deliveryPointsStore';
import productStore, { StoreIds } from 'stores/productStore';
import commonStore from 'stores/commonStore';

import Box from '@mui/material/Box';

import { MAP_ID, initSearchMap } from 'components/Items/Ransom/EditingBlocks/DeliveryPointModal';
import { DeliveryLinkState } from 'components/Items/Delivery/DeliveryLink';
import DeliveryMapPult from './DeliveryMapPult';
import DeliveryMapListItems from './DeliveryMapListItems';
import MapMobileDrawer from './MapMobileDrawer';

const updateMapMakers = async () => {
    const { lat, lng }: { lat: number; lng: number } = deliveryPointsStore.searchMap.getCenter();
    deliveryPointsStore.setCoords(lat, lng);
    await deliveryStore.debounceFilterFetch();
};

// @ts-ignore
window._openDelivery = (delivery_id: number) => {
    locationHistory.push(DeliveryLinkState(delivery_id));
};

const setMapMarkers = async (mapDeliveriesList: DeliveryOnMap[]) => {
    // @ts-ignore
    let DG: any = window.DG;

    if (!DG && !DG.markerClusterGroup) {
        return;
    }

    if (deliveryPointsStore.markers) {
        deliveryPointsStore.markers.removeFrom(deliveryPointsStore.searchMap);
    }

    deliveryPointsStore.markers = DG.markerClusterGroup({
        maxClusterRadius: commonStore.isMobile ? 50 : 30,
        showCoverageOnHover: true,
        spiderfyOnMaxZoom: false
    });

    const store_id = productStore.getCurrentStoreId();

    for (const { geo_lat, geo_lon, address, delivery_id } of mapDeliveriesList) {
        const marker = DG.marker(DG.latLng(geo_lat, geo_lon), {
            title: address,
            delivery_id
        });
        marker.bindPopup(
            `${
                store_id === StoreIds.Ozon ? `<i>${delivery_id}</i><br />` : ''
            }${address}<br /><button onclick="window._openDelivery(${delivery_id}, '${address}')">открыть</button>`
        );
        deliveryPointsStore.markers.addLayer(marker);
    }

    deliveryPointsStore.searchMap.addLayer(deliveryPointsStore.markers);

    // if (deliveryPointsStore.activeStorePointId) {
    //     activateMarker(deliveryPointsStore.activeStorePointId);
    // }
};

const DeliveryMapView = () => {
    const { coords } = deliveryPointsStore;
    const { isMobile } = commonStore;

    useMemo(() => {
        initSearchMap(MAP_ID, coords[0], coords[1], updateMapMakers);
    }, []);

    const { mapDeliveriesList } = deliveryStore;

    useEffect(() => {
        setMapMarkers(mapDeliveriesList);
    }, [mapDeliveriesList]);

    return (
        <>
            <Box sx={{ display: 'flex', height: '100%', overflowY: 'hidden' }}>
                <Box id={MAP_ID} sx={{ height: '100%', position: 'relative', zIndex: 0, flex: 1 }}>
                    <DeliveryMapPult />
                </Box>
                {!isMobile && (
                    <Box sx={{ width: 350, overflowY: 'auto' }}>
                        <DeliveryMapListItems />
                    </Box>
                )}
            </Box>
            {isMobile && <MapMobileDrawer />}
        </>
    );
};

export default observer(DeliveryMapView);
