import React from 'react';
import { observer } from 'mobx-react';

import authStore from 'stores/authStore';

import WbTokenField from 'components/List/Api/ApiCard/WbTokenNewField';

import ApiCardsList from './ApiCardsLits';
import Grid from 'ui/Grid';
import Container from '@mui/material/Container';

const ApiWbCard = () => {
    return (
        <>
            <Container maxWidth="sm">
                <Grid>
                    <WbTokenField />
                </Grid>
            </Container>
            {authStore.wbTokenNew && <ApiCardsList />}
        </>
    );
};

export default observer(ApiWbCard);
