import React, { useEffect, useState } from 'react';

import { DeliveryPointAddress, GenderType } from 'stores/ransomStore';
import productStore from 'stores/productStore';
import * as deliveryPointsApi from 'api/deliveryPointsApi';
import { ClosestPointAccount } from 'api/deliveryPointsApi';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green, orange } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import Badge from '@mui/material/Badge';
import HomeIcon from '@mui/icons-material/Home';
import Tooltip from '@mui/material/Tooltip';

import { useToggleModal } from 'ui/Modal';

import RansomChooseAccountsModal from './RansomChooseAccountsModal';

const RansomClosestAddressBtn = ({
    store_point_id,
    product_id,
    gender,
    onChangeStorePoint
}: {
    store_point_id: number;
    product_id: number | null;
    gender: GenderType;
    onChangeStorePoint: (storeDeliveryPoint: DeliveryPointAddress) => void;
}) => {
    const [loading, setLoading] = useState(true);
    const [closestPointAccounts, setClosestPointAccounts] = useState<ClosestPointAccount[]>([]);

    useEffect(() => {
        setLoading(true);
        setClosestPointAccounts([]);
        deliveryPointsApi
            .findClosestStoreAccountsByPoint(productStore.getCurrentStoreId(), store_point_id, product_id, gender)
            .then(setClosestPointAccounts)
            .finally(() => {
                setLoading(false);
            });
    }, [store_point_id, product_id, gender]);

    const success = closestPointAccounts.length > 0;
    const foundCurrentStore = closestPointAccounts.some(
        ({ storeDeliveryPoint }) => storeDeliveryPoint.store_point_id === store_point_id
    );
    const [showModal, toggleModal] = useToggleModal();

    const buttonSx = {
        ...(foundCurrentStore && {
            bgcolor: green[200],
            '&:hover': {
                bgcolor: green[400]
            }
        }),
        ...(!foundCurrentStore &&
            success && {
                bgcolor: orange[200],
                '&:hover': {
                    bgcolor: orange[300]
                }
            })
    };

    return (
        <Box sx={{ m: 1, position: 'relative' }}>
            <Tooltip
                title={
                    foundCurrentStore
                        ? 'Подобран аккаунт с вашим ПВЗ'
                        : success
                        ? 'Есть аккаунт с ближайшим ПВЗ'
                        : 'Будет взят новый аккаунт'
                }
            >
                <Badge color="primary" variant="dot" invisible={foundCurrentStore || !success}>
                    <Fab color="default" sx={buttonSx} size="small" disabled={!success} onClick={toggleModal}>
                        <HomeIcon />
                    </Fab>
                </Badge>
            </Tooltip>
            {loading && (
                <CircularProgress
                    size={48}
                    sx={{
                        position: 'absolute',
                        top: -4,
                        left: -4,
                        zIndex: 1
                    }}
                />
            )}
            {showModal && (
                <RansomChooseAccountsModal
                    closestPointAccounts={closestPointAccounts}
                    onClose={toggleModal}
                    foundCurrentStore={foundCurrentStore}
                    onChangeStorePoint={onChangeStorePoint}
                    current_store_point_id={store_point_id}
                />
            )}
        </Box>
    );
};

export default RansomClosestAddressBtn;
