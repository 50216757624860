import { observable, action, makeObservable } from 'mobx';
import isEqual from 'common/isEqual';
import deepCopy from 'common/deepCopy';
import indexDB from 'common/indexDB';
import { nProgress, nProgressItem } from '../helpers/decorators.helpers';
import wait from 'common/wait';

export const CREATING_ITEM_ID = 0;

export type ItemType<ItemObjectType extends TItemExtended, ItemPropertyType> = {
    item?: ItemObjectType | null;
    editingItem: Partial<ItemObjectType>;
    editingBlockId?: symbol;
    loadingItem: boolean;
    errors: string[];
    property: Partial<ItemPropertyType>;
};

export interface ApiModuleType<ItemObjectType extends TItemExtended> {
    fetchItem?: (id: ItemKey) => Promise<ItemObjectType>;
    saveItem?: (id: ItemKey, changedItem: Partial<ItemObjectType>) => Promise<number>;
    createItem?: (item: Partial<ItemObjectType>) => Promise<number>;
}

export type TItemExtended = {
    enable: boolean;
};

export interface ItemStoreInterface<TItem extends TItemExtended, TProperty = {}> {
    moduleName: string;
    fetchItem(id: ItemKey, withoutCache?: boolean): Promise<TItem | null>;
    reloadItem(id: ItemKey): Promise<void>;
    setEditingItem(id: ItemKey, editingProps: Partial<TItem>): void;
    setEditingBlockId(id: number, symbol: Symbol): void;
    getItem(id: number): ItemType<TItem, TProperty>;
    saveItem(id: number): Promise<Partial<TItem> | boolean>;
    getItemForAccess(id: number): ItemType<TItem, TProperty>;
    changeArrayValue(
        id: ItemKey,
        arrayFieldName: keyof TItem,
        index: number,
        prop: string | null,
        value: unknown
    ): void;
    toggleDisableItem(id: number, enable: boolean): Promise<void>;
    clearEditingItem(item_id: ItemKey): void;
    clearEditingItemOnly(item_id: ItemKey): void;
    createItem(): Promise<number>;
}

const emptyItem = {
    item: null,
    loadingItem: false,
    editingItem: {},
    errors: [],
    property: {}
};

type ItemKey = number;

class ItemStorePrototype<ItemObjectType extends TItemExtended, ItemPropertyType = {}>
    implements ItemStoreInterface<ItemObjectType, ItemPropertyType>
{
    item_id: keyof ItemObjectType;

    ApiModule: ApiModuleType<ItemObjectType>;
    moduleName: string;

    @observable
    liveDraft = false;

    constructor(item_id: keyof ItemObjectType, moduleName: string, ApiModule: ApiModuleType<ItemObjectType>) {
        makeObservable(this);

        this.ApiModule = ApiModule;
        this.moduleName = moduleName;
        this.item_id = item_id;

        setTimeout(this.onAfterInitHook.bind(this), 0);
    }

    onAfterInitHook(): void {}

    @action
    toogleLiveDraft() {
        this.liveDraft = !this.liveDraft;
        if (!this.liveDraft) {
            localStorage.removeItem(`creating_${this.moduleName}`);
        }
    }

    @observable
    items: Map<ItemKey, ItemType<ItemObjectType, ItemPropertyType>> = new Map();

    @action
    async reloadItem(id: ItemKey) {
        this.setItem(id, deepCopy<ItemType<ItemObjectType, ItemPropertyType>>(emptyItem));
        this.fetchItem(id);
    }

    @action
    async fetchItem(id: ItemKey, withoutCache?: boolean): Promise<ItemObjectType | null> {
        if (id !== CREATING_ITEM_ID) {
            const oldItem = this.items.get(id);
            let itemStored;

            const preItem = {
                loadingItem: !(oldItem && oldItem.item),
                property: oldItem && oldItem.property ? oldItem.property : {},
                editingItem: {},
                errors: [],
                item: oldItem && oldItem.item ? oldItem.item : null
            };
            this.setItem(id, preItem);

            if (!oldItem && !withoutCache) {
                try {
                    itemStored = await indexDB.get(id, this.moduleName);
                    if (itemStored && itemStored.item) {
                        const { item } = itemStored;
                        this.setItem(id, deepCopy({ ...emptyItem, item }));
                    }
                } catch (error) {}
            }

            try {
                await wait(150);
                if (this.ApiModule.fetchItem) {
                    const item = await this.ApiModule.fetchItem(id);
                    try {
                        indexDB.put(id, this.moduleName, item);
                    } catch (e) {}
                    const { property } = this.getItem(id);

                    const newItem = {
                        loadingItem: false,
                        item,
                        property: property || {},
                        errors: [],
                        editingItem: {}
                    };
                    this.setItem(id, newItem);
                    return item;
                }
            } catch (errors) {
                if (errors instanceof Array) {
                    // if !withoutCache last try to reach item in memory, may be the problem is in an internet connection
                    if (!withoutCache && !itemStored) {
                        itemStored = await indexDB.get(id, this.moduleName);
                    }
                    this.setItem(id, {
                        errors,
                        loadingItem: false,
                        property: {},
                        editingItem: {},
                        item: itemStored ? itemStored.item : null
                    });
                }
            }
        } else if (!this.items.get(id)) {
            let editingItem = {};

            if (id === CREATING_ITEM_ID && this.liveDraft) {
                const cacheItem = localStorage.getItem(`creating_${this.moduleName}`);
                try {
                    editingItem = cacheItem ? JSON.parse(cacheItem) : {};
                } catch (error) {
                    editingItem = {};
                }
            }

            this.setItem(id, {
                property: {},
                editingItem,
                loadingItem: false,
                errors: []
            });
        }

        return null;
    }

    @action setEditingBlockId(item_id: ItemKey, blockId: symbol) {
        this.getItem(item_id).editingBlockId = blockId;
    }

    getItem(id: ItemKey): ItemType<ItemObjectType, ItemPropertyType> {
        const item = this.items.get(id);
        if (!item) {
            throw Error(`Item didn't find: ${this.moduleName}; #${id}`);
        }
        return item;
    }

    @action
    clearEditingItem(id: ItemKey) {
        const item = this.getItem(id);
        item.property = {};
        item.errors = [];
        this.clearEditingItemOnly(id);

        if (this.liveDraft && id === CREATING_ITEM_ID) {
            localStorage.removeItem(`creating_${this.moduleName}`);
        }
    }

    @action
    clearEditingItemOnly(id: ItemKey) {
        const item = this.getItem(id);
        item.editingItem = {};
    }

    getItemForAccess(id: ItemKey): ItemType<ItemObjectType, ItemPropertyType> {
        return this.getItem(id);
    }

    isItemExist(id: ItemKey): boolean {
        return Boolean(this.items.get(id));
    }

    @action
    setItem(id: ItemKey, item: ItemType<ItemObjectType, ItemPropertyType>) {
        const oldItem = this.items.get(id);
        if (!oldItem) {
            this.items.set(id, item);
        } else {
            oldItem.item = item.item;
            oldItem.errors = item.errors || oldItem.errors;
            oldItem.editingItem = item.editingItem;
            oldItem.loadingItem = item.loadingItem;
            oldItem.property = { ...oldItem.property, ...item.property };
        }
    }

    validationItem(editingItem: Partial<ItemObjectType>): Array<string> {
        return [];
    }

    @nProgress
    @action
    async createItem(): Promise<number> {
        const emptyItem = this.getItem(CREATING_ITEM_ID);

        const errors =
            typeof emptyItem.editingItem === 'object' && emptyItem.editingItem !== null
                ? this.validationItem(emptyItem.editingItem)
                : [];

        if (errors.length) {
            emptyItem.errors = errors;
            return CREATING_ITEM_ID;
        }

        emptyItem.loadingItem = true;

        try {
            const item = { ...emptyItem.editingItem };
            delete item[this.item_id];

            const item_id =
                typeof this.ApiModule.createItem === 'function'
                    ? await this.ApiModule.createItem(item)
                    : CREATING_ITEM_ID;

            emptyItem.loadingItem = false;
            emptyItem.errors = [];

            // Уходим на следующий тик, когда роутер переведет карточку на только что созданную:
            window.setTimeout(() => {
                this.clearEditingItem(CREATING_ITEM_ID);
            }, 0);

            return item_id;
        } catch (errors) {
            console.log(errors);
            if (errors instanceof Array) {
                emptyItem.errors = errors;
                emptyItem.loadingItem = false;
            }
            return CREATING_ITEM_ID;
        }
    }

    @nProgressItem
    @action
    async saveItem(id: ItemKey): Promise<boolean | Partial<ItemObjectType>> {
        const thisItem = this.getItem(id);
        const { editingItem, item } = thisItem;

        const newItem: Partial<ItemObjectType> = {};

        if (item && editingItem) {
            for (const key in editingItem) {
                if (editingItem[key] instanceof Array) {
                    if (
                        // @ts-ignore
                        !isEqual(Array.from(editingItem[key]), item[key] instanceof Array ? Array.from(item[key]) : [])
                    ) {
                        // @ts-ignore
                        newItem[key] = editingItem[key] instanceof Array ? Array.from(editingItem[key]) : [];
                    }
                } else if (
                    typeof editingItem[key] === 'object' &&
                    editingItem[key] !== null &&
                    !isEqual(item[key], editingItem[key])
                ) {
                    newItem[key] = editingItem[key];
                } else if (typeof editingItem[key] !== 'object' && item[key] !== editingItem[key]) {
                    newItem[key] = editingItem[key];
                } else if (editingItem[key] === null && item[key] !== null) {
                    newItem[key] = editingItem[key];
                }
            }
        }

        this.mergeItem(id, newItem);

        if (Object.keys(newItem).length && this.ApiModule.saveItem) {
            try {
                await this.ApiModule.saveItem(id, newItem);
            } catch (errors) {
                if (errors instanceof Array) {
                    thisItem.errors = errors;
                }
                indexDB.delete(id, this.moduleName);
                return false;
            } finally {
                this.setEditingBlockId(id, Symbol());
            }
        }
        return newItem;
    }

    @action
    setEditingItem(id: ItemKey, editingProps: Partial<ItemObjectType>) {
        const item: ItemType<ItemObjectType, ItemPropertyType> = this.getItem(id);

        item.errors = [];
        item.editingItem = { ...item.editingItem, ...editingProps };

        if (this.liveDraft && id === CREATING_ITEM_ID) {
            localStorage.setItem(`creating_${this.moduleName}`, JSON.stringify(item.editingItem));
        }

        this.setItem(id, item);
    }

    @action
    changeArrayValue(
        id: ItemKey,
        arrayFieldName: keyof ItemObjectType,
        index: number,
        prop: string | null,
        value: unknown
    ) {
        const { editingItem } = { ...this.getItem(id) };

        if (typeof editingItem === 'object' && editingItem !== null && editingItem[arrayFieldName] instanceof Array) {
            if (prop) {
                // @ts-ignore
                editingItem[arrayFieldName][index][prop] = value;
            } else {
                // @ts-ignore
                editingItem[arrayFieldName][index] = value;
            }
        }
    }

    @action
    setProperty(id: ItemKey, property: Partial<ItemPropertyType>) {
        const item = this.getItem(id);
        item.property = { ...item.property, ...property };
    }

    @action
    mergeItem(id: ItemKey, newItem: Partial<ItemObjectType>) {
        const oldItem: ItemType<ItemObjectType, ItemPropertyType> = this.getItem(id);

        // @ts-ignore
        oldItem.item = deepCopy({ ...oldItem.item, ...newItem });
        indexDB.put(id, this.moduleName, deepCopy({ ...oldItem.item, ...newItem }));
        this.setItem(id, oldItem);
    }

    @action
    async toggleDisableItem(id: ItemKey, enable: boolean) {
        // @ts-ignore
        this.setEditingItem(id, { enable });
        await this.saveItem(id);
    }
}

export default ItemStorePrototype;
