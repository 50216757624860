import React, { ReactNode, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import { speedOptions } from 'common/time';

import favoriteStore from 'stores/favoriteStore';
import cartStore from 'stores/cartStore';
import wishlistStore from 'stores/wishlistStore';
import feedbackReactionStore from 'stores/feedbackReactionStore';
import brandStore from 'stores/brandStore';

import InputAdornment from '@mui/material/InputAdornment';
import Grid from 'ui/Grid';
import { NumberInput } from 'ui/TextInput';
import Select from 'ui/Select';

const QuantitySpeedModal = ({
    item_id,
    store,
    infoPopup
}: {
    item_id: number;
    store:
        | typeof wishlistStore
        | typeof feedbackReactionStore
        | typeof cartStore
        | typeof favoriteStore
        | typeof brandStore;
    infoPopup: ReactNode;
}) => {
    const { editingItem } = store.getItem(item_id);
    const { quantity, speed } = editingItem;

    const handleQuantity = (event: SyntheticEvent, { value }: { value: number }) => {
        store.setEditingItem(item_id, { quantity: value });
    };
    const handleSpeed = ({ value }: { value: number }) => {
        store.setEditingItem(item_id, { speed: value });
    };

    return (
        <>
            <Grid.Column>
                <NumberInput
                    value={quantity || null}
                    onChange={handleQuantity}
                    label="Количество"
                    min={0}
                    step={1}
                    max={100}
                    extraLabel={<InputAdornment position="end">{infoPopup}</InputAdornment>}
                />
            </Grid.Column>
            {Number(quantity) > 1 && (
                <Grid.Column>
                    <Select
                        fullWidth
                        variant="standard"
                        options={speedOptions}
                        value={speed}
                        onChange={handleSpeed}
                        label="Время исполнения"
                    />
                </Grid.Column>
            )}
        </>
    );
};

export default observer(QuantitySpeedModal);
