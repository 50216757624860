import React, { SyntheticEvent, useEffect, useState } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';

import FFSelectPackMaterial from './FFSelectPackMaterial';
import Price from 'components/Price';

import { packMaterialsSet, packetMaterialGroups, PacketMaterialGroups } from './priceData';

import { useToggleModal } from 'ui/Modal';
import Button from 'ui/Button';

const PackListItem = ({ onChange }: { onChange: (pack_id: number | null) => void }) => {
    const [showPackModal, togglePackModal] = useToggleModal();
    const [checked, setChecked] = useState(false);
    const handleChecked = (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setChecked(!checked);
    };

    const [pack_id, setPackId] = useState<number | null>(null);
    const foundPack = packMaterialsSet.find(pack => pack.pack_id === pack_id);
    let label = 'Упаковка';
    let subLabel = 'выберите упаковку';
    let packGroup: PacketMaterialGroups | undefined = undefined;
    if (foundPack) {
        packGroup = packetMaterialGroups.find(({ group_id }) => group_id === foundPack.group_id);
        if (packGroup) {
            label = packGroup.label;
        }
        subLabel = foundPack.size;
    }

    const handleChangePackId = (pack_id: number) => {
        setPackId(pack_id);
        if (pack_id) {
            setChecked(true);
        }
    };

    useEffect(() => {
        onChange(checked ? pack_id : null);
    }, [pack_id, checked]);

    return (
        <>
            <ListItem
                disablePadding
                secondaryAction={
                    foundPack ? (
                        <Price price={foundPack.price} priceWithSale={foundPack.price} direction="row-reverse" />
                    ) : (
                        <Button icon={<EditIcon />} onClick={togglePackModal} tooltip="Выбрать упаковку" />
                    )
                }
            >
                <ListItemButton onClick={togglePackModal}>
                    <ListItemIcon>
                        <Checkbox edge="start" checked={checked} tabIndex={-1} disableRipple onClick={handleChecked} />
                    </ListItemIcon>
                    <Tooltip title="Нажмите, чтобы выбрать упаковку">
                        <ListItemText primary={label} secondary={subLabel}></ListItemText>
                    </Tooltip>
                </ListItemButton>
            </ListItem>
            {packGroup && (
                <ListItem
                    divider
                    secondaryAction={
                        <Price
                            price={packGroup.packPrice}
                            priceWithSale={packGroup.packPrice}
                            direction="row-reverse"
                        />
                    }
                >
                    <ListItemIcon>
                        <Checkbox edge="start" checked={true} tabIndex={-1} disableRipple disabled />
                    </ListItemIcon>
                    <ListItemText primary="Упаковочные работы"></ListItemText>
                </ListItem>
            )}
            {showPackModal && (
                <FFSelectPackMaterial onClose={togglePackModal} onChange={handleChangePackId} pack_id={pack_id} />
            )}
        </>
    );
};

export default PackListItem;
