import fetchApi from 'common/fetchApi';
import { objectToGraphql } from '../common/graphql';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { Review, ReviewsFilter } from 'stores/reviewStore';
import { GQProductListFields } from './productApi';

export const createItem = async (review: Partial<Review>): Promise<number> => {
    const graphql = `mutation { 
          createReview(review: ${objectToGraphql(review)})
        }`;

    const data = await fetchApi.postGQ<{ createReview: number }>(graphql);
    return data.createReview;
};

const GQReviewFields = `
    review_id
    user_id
    ransom_id
    account_id
    accountIsGood
    product_id
    
    rating
    reviewText
    prosText
    consText
    isRecommended
    isAnonymously

    product {
        ${GQProductListFields}
    }
    
    type
    planningExecuteTime
    reviewPhotos
    reviewVideos

    status
    errorMessage
    createTime
    updateTime
    enable
`;

export const fetchItem = async (review_id: number): Promise<Review> => {
    const graphql = `{ 
          fetchReview(review_id: ${review_id}) {
            ${GQReviewFields}
           }
        }`;

    const data = await fetchApi.getGQ<{ fetchReview: Review }>(graphql);
    return data.fetchReview;
};

export const saveItem = async (review_id: number, reviewDiff: Partial<Review>): Promise<number> => {
    const graphql = `mutation { 
          updateReview(review_id: ${review_id}, reviewDiff: ${objectToGraphql(reviewDiff)})
        }`;

    const data = await fetchApi.postGQ<{ updateReview: number }>(graphql);
    return data.updateReview;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    reviewFilter: Partial<ReviewsFilter>,
    controller?: AbortController
): Promise<{ list: Review[]; count: number }> => {
    const graphql = `{
            fetchReviewsList(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(reviewFilter)}) {
                list {
                    ${GQReviewFields}
                }
                count
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchReviewsList: { list: Review[]; count: number } }>(graphql, controller);
    return data.fetchReviewsList;
};

export const refreshUpdateWbReview = async (review_id: number): Promise<boolean> => {
    const graphql = `mutation { 
          refreshUpdateWbReview(review_id: ${review_id})
        }`;

    const data = await fetchApi.postGQ<{ refreshUpdateWbReview: boolean }>(graphql);
    return data.refreshUpdateWbReview;
};
