import fetchApi from 'common/fetchApi';
import { objectToGraphql } from '../common/graphql';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { Delivery, DeliveryFilter } from '../stores/deliveryStore';
import { GQRansomListFields } from './ransomApi';
import { StoreDeliveryPointType } from '../stores/deliveryPointsStore';
import { StoreIds } from '../stores/productStore';
import { CoordinatesSquare } from './deliveryPointsApi';

export const createItem = async (delivery: Partial<Delivery>): Promise<number> => {
    const graphql = `mutation { 
          createDelivery(delivery: ${objectToGraphql(delivery)})
        }`;

    const data = await fetchApi.postGQ<{ createDelivery: number }>(graphql);
    return data.createDelivery;
};

const GQDeliveryFields = `
    delivery_id
    ransom_id
    user_id

    deliveryCode
    status
    errorMessage
    
    ransom {
        ${GQRansomListFields}
    }
    lastName
    firstName
    phone
    
    statuses {
        status
        date    
    }

    major_user_id

    createTime
    updateTime
    enable
`;

export const fetchItem = async (delivery_id: number): Promise<Delivery> => {
    const graphql = `{ 
          fetchDelivery(delivery_id: ${delivery_id}) {
            ${GQDeliveryFields}
            deliveryQR
           }
        }`;

    const data = await fetchApi.getGQ<{ fetchDelivery: Delivery }>(graphql);
    return data.fetchDelivery;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    deliveryFilter: Partial<DeliveryFilter>,
    controller?: AbortController
): Promise<{ list: Delivery[]; count: number }> => {
    const graphql = `{
            fetchDeliveriesList(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(deliveryFilter)}) {
                list {
                    ${GQDeliveryFields}
                }
                count
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchDeliveriesList: { list: Delivery[]; count: number } }>(
        graphql,
        controller
    );
    return data.fetchDeliveriesList;
};

export const downloadDeliveriesXls = async (
    orderBy: string,
    orderDirection: OrderDirectionType,
    deliveryFilter: Partial<DeliveryFilter>
): Promise<string> => {
    const graphql = `{
            downloadDeliveriesXls(orderBy: "${orderBy}", sort: "${orderDirection}", 
                filter: ${objectToGraphql(deliveryFilter)}) 
          }`;

    const data = await fetchApi.getGQ<{ downloadDeliveriesXls: string }>(graphql);
    return data.downloadDeliveriesXls;
};

export const fetchSuitableDeliveriesToReview = async (store_id: StoreIds): Promise<Delivery[]> => {
    const graphql = `{
            fetchSuitableDeliveriesToReview(store_id: ${store_id}) {
                ${GQDeliveryFields}
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchSuitableDeliveriesToReview: Delivery[] }>(graphql);
    return data.fetchSuitableDeliveriesToReview;
};

export const fetchDeliveriesStoresOptions = async (
    deliveryFilter: Partial<DeliveryFilter>
): Promise<StoreDeliveryPointType[]> => {
    const graphql = `{
            fetchDeliveriesStoresOptions(filter: ${objectToGraphql(deliveryFilter)}) {
                store_point_id
                address
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchDeliveriesStoresOptions: StoreDeliveryPointType[] }>(graphql);
    return data.fetchDeliveriesStoresOptions;
};

export const refreshUpdateStoreDelivery = async (delivery_id: number): Promise<boolean> => {
    const graphql = `{ 
          refreshUpdateStoreDelivery(delivery_id: ${delivery_id})
        }`;

    const data = await fetchApi.postGQ<{ refreshUpdateStoreDelivery: boolean }>(graphql);
    return data.refreshUpdateStoreDelivery;
};

export const saveItem = async (delivery_id: number, deliveryDiff: Partial<Delivery>): Promise<number> => {
    const graphql = `mutation { 
          updateDelivery(delivery_id: ${delivery_id}, deliveryDiff: ${objectToGraphql(deliveryDiff)})
        }`;

    const data = await fetchApi.postGQ<{ updateDelivery: number }>(graphql);
    return data.updateDelivery;
};

export const fetchDeliveriesGroup = async (ransom_group_id: number): Promise<Delivery[]> => {
    const graphql = `{
            fetchDeliveriesGroup(ransom_group_id: ${ransom_group_id}) {
                ${GQDeliveryFields}
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchDeliveriesGroup: Delivery[] }>(graphql);
    return data.fetchDeliveriesGroup;
};

export type DeliveryOnMap = {
    delivery_id: number;
    user_id: number;
    status: Delivery['status'];

    product_id: number;
    store_item_id: number;
    // product: Product

    address: string;
    geo_lat: number;
    geo_lon: number;
};

export const findDeliveriesOnMap = async (
    filter: Partial<DeliveryFilter>,
    coordinates: CoordinatesSquare,
    controller: AbortController
): Promise<DeliveryOnMap[]> => {
    const graphql = `{
            findDeliveriesOnMap(filter: ${objectToGraphql(filter)}, 
            coordinates: ${objectToGraphql(coordinates)}) {
                    delivery_id
                    user_id
                    status
                    
                    product_id
                    store_item_id
                
                    address
                    geo_lat
                    geo_lon
            } 
          }`;

    const data = await fetchApi.getGQ<{ findDeliveriesOnMap: DeliveryOnMap[] }>(graphql, controller);
    return data.findDeliveriesOnMap;
};
