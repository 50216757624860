import React from 'react';
import { WbItemFeedbacksPhotos } from 'api/productApi';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

const ProductFeedbackPhotos = ({ photos }: { photos: WbItemFeedbacksPhotos[] }) => {
    return (
        <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            {photos.map(({ minSizeUri }) => (
                <Avatar
                    key={minSizeUri}
                    sx={{ width: `48px`, height: `48px` }}
                    src={`//feedbackphotos.wbstatic.net/${minSizeUri}`}
                    variant="rounded"
                />
            ))}
        </Stack>
    );
};

export default React.memo(ProductFeedbackPhotos, (p1, p2) => p1?.photos?.length !== p2?.photos?.length);
