import { makeObservable } from 'mobx';
import * as seoConsultApi from 'api/seoConsultApi';
import ItemStorePrototype from './prototypes/ItemStore.prototype';

export type SeoConsult = {
    seo_consult_id: number;
    user_id: number;
    product_id: number | null;

    variant: 1 | 2 | 3;
    comment: string;
    status: 1 | 2;
    phone: string;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

class SeoConsultStore extends ItemStorePrototype<SeoConsult, SeoConsult> {
    constructor() {
        super('seo_consult_id', 'seoConsult', seoConsultApi);
        makeObservable(this);
    }

    listFilterClear = {};
}

export default new SeoConsultStore();
