import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import brandStore from 'stores/brandStore';
import productStore, { Product } from 'stores/productStore';

import Grid from 'ui/Grid';
import ListError from 'ui/ListError';
import Button from 'ui/Button';

import Typography from '@mui/material/Typography';

import FavoriteIcon from '@mui/icons-material/Favorite';
import Container from '@mui/material/Container';

import ItemDrawWrapper, { MenuTab } from '../ItemDrawWrapper';
import ProductCard from '../Product/ProductCard';

import CostWithDiscount from '../../CostWithDiscount';
import WbSuggestionsField from '../WbSuggestionsField';

import { BrandLinkState } from './BrandLink';
import QuantitySpeedModal from '../QuantitySpeedField';
import BrandInfoBlock from './BrandInfoBlock';

const BrandCreateMode = () => {
    const brand_id = CREATING_ITEM_ID;

    useMemo(() => {
        productStore.fetchList();
        brandStore.fetchItem(brand_id);
        brandStore.setEditingItem(brand_id, {
            searchRequest: null,
            gender: null,
            quantity: 1,
            speed: null
        });
    }, [brand_id]);

    const { loadingItem, editingItem, errors } = brandStore.getItem(brand_id);
    const { product_id, quantity } = editingItem;

    const handleCreate = async () => {
        const brand_id = await brandStore.createItem();
        if (brand_id) {
            locationHistory.replace(BrandLinkState(brand_id));
        }
    };

    const cost = 10;

    let product: Product | null = null;
    if (product_id) {
        const productItem = productStore.getItem(product_id);
        product = productItem?.item || null;
    }

    const tabs: MenuTab[] = [{ id: 'main', label: 'Бренд в избранное', pathname: '/' }];

    const shortItem = product ? productStore.calcShortProduct(product) : null;

    return (
        <ItemDrawWrapper tabs={tabs} activeTab={'main'}>
            <Container maxWidth="sm">
                <ListError errors={errors} />

                <Grid>
                    {shortItem && product && (
                        <Grid.Column>
                            <Typography variant="subtitle1">Бренд для добавление в избранное</Typography>
                            <BrandInfoBlock brand={shortItem.brand} store_id={product.store_id} />
                        </Grid.Column>
                    )}
                    <Grid.Column>
                        <Typography variant="subtitle1">Поиск бренда через карточку товара</Typography>
                        <ProductCard product={product} size="small" />
                    </Grid.Column>
                    <Grid.Column>
                        <WbSuggestionsField item_id={brand_id} store={brandStore} />
                    </Grid.Column>
                    <QuantitySpeedModal item_id={brand_id} store={brandStore} infoPopup={null} />
                    <Grid.Column style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCreate}
                            loading={loadingItem}
                            endIcon={<FavoriteIcon />}
                            disabled={loadingItem}
                        >
                            Бренд в избранное (<CostWithDiscount cost={cost * (quantity || 1)} />)
                        </Button>
                    </Grid.Column>
                </Grid>
            </Container>
        </ItemDrawWrapper>
    );
};

export default observer(BrandCreateMode);
