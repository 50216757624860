import React from 'react';
import locationHistory from 'locationHistory';
import serverConfig from 'config/server.config';

import { Delivery } from 'stores/deliveryStore';
import reviewStore from 'stores/reviewStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import { TableCell, TableRow } from 'ui/Table';
import Time from 'ui/Time';
import Button from 'ui/Button';
import CutString from 'ui/CutString';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { DeliveryLinkState } from 'components/Items/Delivery/DeliveryLink';
import { ReviewLinkState } from 'components/Items/Review/ReviewLink';
import DeliveryStatus from './DeliveryStatus';
import ProductTitleLink from '../Products/ProductTitleLink';

const handleCreateReview = (product_id: number, ransom_id: number) => {
    setTimeout(() => {
        reviewStore.createEmptyReview(product_id, ransom_id);
    }, 0);
    locationHistory.push(ReviewLinkState(CREATING_ITEM_ID));
};

const DeliveryTableRow = ({ delivery }: { delivery: Delivery }) => {
    const {
        delivery_id,
        ransom_id,
        deliveryCode,
        status,
        ransom: {
            product_id,
            type,
            product,
            deliveryStorePoint: { address },
            accountIsGood,
            ransomsCount
        },
        createTime,
        enable
    } = delivery;

    return (
        <TableRow key={delivery_id}>
            <TableCell>
                <Time time={createTime} />
            </TableCell>
            <TableCell>
                <Button
                    onClick={() => locationHistory.push(DeliveryLinkState(delivery_id))}
                    tooltip="Открыть карточку доставки"
                >
                    <DeliveryStatus status={status} enable={enable} />
                    {/*{type === RansomType.Group && (*/}
                    {/*    <Chip label="Группа доставок" color="primary" sx={{ cursor: 'pointer' }} />*/}
                    {/*)}*/}
                    {/*{type === RansomType.Smart && (*/}
                    {/*    <Chip*/}
                    {/*        icon={<AccessTimeIcon />}*/}
                    {/*        label="Smart доставки"*/}
                    {/*        color="secondary"*/}
                    {/*        sx={{ cursor: 'pointer' }}*/}
                    {/*    />*/}
                    {/*)}*/}
                    {/*{type === RansomType.Once && <DeliveryStatus status={status} enable={enable} />}*/}
                </Button>
            </TableCell>
            <TableCell>
                <ProductTitleLink product={product} />
            </TableCell>
            <TableCell>
                {/*{type !== RansomType.Smart && <CutString title={address} maxLength={30} />}*/}
                {/*{type === RansomType.Smart && (*/}
                {/*    <Typography>*/}
                {/*        {ransomsCount}&nbsp; выкуп*/}
                {/*        {Number(ransomsCount) > 4 ? 'ов' : Number(ransomsCount) > 1 ? 'а' : ''}*/}
                {/*    </Typography>*/}
                {/*)}*/}
                <CutString title={address} maxLength={30} />
            </TableCell>
            <TableCell>
                {status === 4 && product_id && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleCreateReview(product_id, ransom_id)}
                        tooltip="Написать отзыв по данной доставке"
                        disabled={!accountIsGood}
                    >
                        Отзыв
                    </Button>
                )}
                {status === 3 && (
                    <Button
                        startIcon={<CloudDownloadIcon />}
                        tooltip="Скачать QR код на получение в pdf файле"
                        variant="outlined"
                        color="success"
                        href={`${serverConfig.serverUrl}/delivery/pdf?id=${delivery_id}&ct=${createTime}`}
                        download
                    >
                        pdf
                    </Button>
                )}
            </TableCell>
        </TableRow>
    );
};

export default DeliveryTableRow;
