import React from 'react';
import { observer } from 'mobx-react';

import ransomStore, { RansomWbFilter } from 'stores/ransomStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

export const RansomFilterChipsShow = ({
    wbFilters,
    onDelete
}: {
    wbFilters: RansomWbFilter[];
    onDelete?: (name: string) => void;
}) => {
    return (
        <>
            {wbFilters.map(({ name, key, ids }) => {
                const label = (
                    <>
                        <b>{name}</b>: {ids.map(({ name }) => name).join(', ')}
                    </>
                );
                return (
                    <Chip
                        key={name}
                        label={label}
                        onDelete={onDelete ? () => onDelete(name) : undefined}
                        sx={{ mr: 1, mb: 1 }}
                    />
                );
            })}{' '}
        </>
    );
};

const RansomFilterChips = ({ ransom_id, disableDelete }: { ransom_id: number; disableDelete?: boolean }) => {
    const { item, editingItem } = ransomStore.getItem(ransom_id);
    const isCreating = ransom_id === CREATING_ITEM_ID;
    const { wbFilters } = isCreating ? editingItem : item || {};
    if (!wbFilters) {
        return null;
    }
    return (
        <Box>
            <RansomFilterChipsShow
                wbFilters={wbFilters}
                onDelete={
                    disableDelete
                        ? undefined
                        : isCreating
                        ? name => ransomStore.deleteRansomFilter(CREATING_ITEM_ID, name)
                        : undefined
                }
            />
        </Box>
    );
};

export default observer(RansomFilterChips);
