import React from 'react';
import { observer } from 'mobx-react';

import deliveryStore from 'stores/deliveryStore';

import DeliveriesList from './DeliveriesList';
import DeliveryMapView from './MapView';

const Deliveries = () => {
    const { dataSetType } = deliveryStore;

    return dataSetType === 'list' ? <DeliveriesList /> : <DeliveryMapView />;
};

export default observer(Deliveries);
