import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import Grid from 'ui/Grid';
import Button from 'ui/Button';
import ListError from 'ui/ListError';

import productStore, { StoreIds } from 'stores/productStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import ProductSearchDropdownAutocomplete, { DropdownProductType } from './ProductSearchDropdownAutocomplete';
import StoresToggle from 'components/StoresToggle';

const ProductSearchDropdown = () => {
    useMemo(() => {
        productStore.fetchItem(CREATING_ITEM_ID);
    }, []);

    const [option, setOption] = useState<DropdownProductType | null>(null);
    const handleCreate = () => {
        if (option?.value) {
            productStore.createItemFromDropdown(Number(option.value));
            setOption(null);
        }
    };

    const { errors } = productStore.getItem(CREATING_ITEM_ID);
    const { store_id } = productStore.listFilter;

    return (
        <Grid spacing={2} stackable>
            <Grid.Column width={2}>
                <StoresToggle />
            </Grid.Column>

            <Grid.Column width={7}>
                <ProductSearchDropdownAutocomplete
                    store_id={store_id || StoreIds.WB}
                    onChange={setOption}
                    value={option}
                    showPopup
                />
            </Grid.Column>

            <Grid.Column width={3}>
                <Button color="secondary" variant="contained" disabled={!option} onClick={handleCreate}>
                    Добавить
                </Button>
            </Grid.Column>
            {errors.length > 0 && (
                <Grid.Column width={12}>
                    <ListError errors={errors} />
                </Grid.Column>
            )}
        </Grid>
    );
};

export default observer(ProductSearchDropdown);
