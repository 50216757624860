import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const PricePrint = ({ price, priceWithSale }: { price: number; priceWithSale: number }) => {
    return (
        <>
            <Typography variant="subtitle2" style={{ whiteSpace: 'nowrap' }}>
                {priceWithSale.toLocaleString()} ₽
            </Typography>
            {price !== priceWithSale && (
                <Typography variant="caption" style={{ textDecoration: 'line-through', whiteSpace: 'nowrap' }}>
                    {price.toLocaleString()} ₽
                </Typography>
            )}
        </>
    );
};

const Price = ({
    price,
    priceWithSale,
    direction = 'column'
}: {
    price: number;
    priceWithSale: number;
    direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
}) => {
    if (direction === 'column') {
        return <PricePrint price={price} priceWithSale={priceWithSale} />;
    }

    return (
        <Stack direction={direction} alignItems="center" spacing={1}>
            <PricePrint price={price} priceWithSale={priceWithSale} />
        </Stack>
    );
};

export default React.memo(Price);
