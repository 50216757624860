import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import reviewStore from 'stores/reviewStore';

import TextInput from 'ui/TextInput';

const ReviewTextWB = () => {
    const review_id = CREATING_ITEM_ID;

    const { editingItem } = reviewStore.getItem(review_id);
    const { reviewText } = editingItem;

    const handleReviewText = (event: SyntheticEvent, { value }: { value: string }) => {
        reviewStore.setEditingItem(review_id, { reviewText: value });
    };

    return (
        <TextInput
            variant="classic"
            label="Отзыв"
            value={reviewText}
            onChange={handleReviewText}
            maxLength={1000}
            multiline
            rowsMax={10}
            rows={1}
        />
    );
};

export default observer(ReviewTextWB);
