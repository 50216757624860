import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import deepCopy from 'common/deepCopy';

import ransomStore, { DeliveryPointAddress, GenderType, RansomSmartItem } from 'stores/ransomStore';
import productStore, { WbSize } from 'stores/productStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import RansomSmartModal from './ModalBlocks/RansomSmartModal';

const RansomSmartItemModal = ({
    ransom_id,
    onClose,
    index
}: {
    ransom_id: number;
    onClose: () => void;
    index: number;
}) => {
    const { ransomSmartItems = [], product_id } = ransomStore.getItem(ransom_id).editingItem;
    const smartItem = ransomSmartItems[index];
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (smartItem) {
            const { searchRequest, wbFilters } = smartItem;
            ransomStore.setEditingItem(CREATING_ITEM_ID, { wbFilters: wbFilters || [], searchRequest });
        }
    }, []);

    const [smartItemEditing, setSmartItem] = useState<RansomSmartItem>(
        smartItem || { wbSizeName: null, wb_size_id: null, address: null, wb_store_id: null, time: null }
    );

    if (!smartItem || !product_id) {
        return null;
    }

    const { time, address, wb_size_id, gender } = smartItemEditing;

    const handleDateChange = (date: Date | null) => {
        setErrors([]);
        if (date) {
            setSmartItem({ ...smartItemEditing, time: Math.floor(date.getTime() / 1000) });
        }
    };

    const handleChooseAddress = ({ address, store_point_id }: DeliveryPointAddress) => {
        setErrors([]);
        setSmartItem({ ...smartItemEditing, store_point_id, address });
        let { deliveryPointsAddresses } = ransomStore.getItem(ransom_id).property;
        if (!deliveryPointsAddresses) {
            deliveryPointsAddresses = [];
        }
        const deliveryPointsAddressesNew = [...deliveryPointsAddresses, { store_point_id, address }];
        ransomStore.setProperty(ransom_id, { deliveryPointsAddresses: deliveryPointsAddressesNew });
    };

    const handleChooseSize = (wb_size_id: number, wbSize: WbSize) => {
        setErrors([]);
        setSmartItem({ ...smartItemEditing, wb_size_id, wbSizeName: wbSize.sizeName });
        ransomStore.setProperty(ransom_id, { wbSize });
        ransomStore.setEditingItem(ransom_id, { wb_size_id });
    };

    const handleChangeGender = (gender: GenderType) => {
        setErrors([]);
        ransomStore.setEditingItem(ransom_id, { gender });
        setSmartItem({ ...smartItemEditing, gender });
    };

    const handleSave = () => {
        const errors: string[] = [];
        if (!smartItemEditing.address) {
            errors.push('Адрес ПВЗ не задан');
        }
        if (!smartItemEditing.wb_size_id && productStore.whetherProductHasSizes(product_id)) {
            errors.push('Размер не задан');
        }
        if (!errors.length) {
            const editingRansom = ransomStore.getItem(CREATING_ITEM_ID).editingItem;
            if (editingRansom) {
                const { searchRequest, wbFilters, wbFilterOrderBy } = smartItemEditing;
                ransomSmartItems[index] = {
                    ...smartItemEditing,
                    searchRequest: editingRansom.searchRequest || searchRequest,
                    wbFilters: editingRansom.wbFilters ? deepCopy(editingRansom.wbFilters) : wbFilters,
                    wbFilterOrderBy: editingRansom.wbFilterOrderBy || wbFilterOrderBy
                };
                ransomStore.setEditingItem(ransom_id, { ransomSmartItems: [...ransomSmartItems] });
                onClose();
            }
        } else {
            setErrors(errors);
        }
    };

    const { store_point_id } = smartItemEditing;

    return (
        <RansomSmartModal
            ransom_id={ransom_id}
            time={time}
            product_id={product_id}
            errors={errors}
            wb_size_id={wb_size_id}
            address={address}
            onClose={onClose}
            handleDateChange={handleDateChange}
            handleChooseAddress={handleChooseAddress}
            handleChooseSize={handleChooseSize}
            handleSave={handleSave}
            gender={gender || null}
            handleChangeGender={handleChangeGender}
            store_point_id={store_point_id}
        />
    );
};

export default observer(RansomSmartItemModal);
