import { createStyles, withStyles } from '@mui/styles';
import DividerMui from '@mui/material/Divider';

export const Divider = withStyles(() =>
    createStyles({
        root: {
            margin: '1rem 0'
        },
        vertical: {
            margin: '0.5rem'
        }
    })
)(DividerMui);

export default Divider;
