import React from 'react';

import { StoreIds } from 'stores/productStore';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import ProductSearchDropdownAutocomplete, {
    DropdownProductType
} from 'components/List/Products/ProductSearchDropdownAutocomplete';

const RansomOtherItemAdd = ({
    store_id,
    onAddItem
}: {
    store_id: StoreIds;
    onAddItem: (storeItem: DropdownProductType) => void;
}) => {
    return (
        <ListItem>
            <ListItemText>
                <ProductSearchDropdownAutocomplete
                    showPopup={false}
                    value={null}
                    onChange={storeItem => storeItem && onAddItem(storeItem)}
                    store_id={store_id}
                />
            </ListItemText>
        </ListItem>
    );
};

export default RansomOtherItemAdd;
