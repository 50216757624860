import React, { ReactNode, PureComponent, CSSProperties } from 'react';
import MUGrid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type ColumnWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type ColumnsNumber = 'auto' | ColumnWidth;

interface GridColumnProps {
    children: ReactNode;
    xs?: ColumnsNumber;
    sm?: ColumnsNumber;
    md?: ColumnsNumber;
    lg?: ColumnsNumber;
    width?: ColumnWidth;
    className?: string;
    style?: CSSProperties;
    sx?: SxProps<Theme>;
}

const Column = ({ children, xs, sm, md, lg, width, className, style, sx }: GridColumnProps) => {
    return (
        <MUGrid
            item
            xs={xs || width || sm || md || lg || 12}
            sm={sm || width || xs || md || lg || 12}
            md={md || width || sm || xs || lg || 12}
            lg={lg || width || md || sm || xs || 12}
            style={style}
            sx={sx}
            className={className}
        >
            {children}
        </MUGrid>
    );
};

interface GridProps {
    children: ReactNode;
    columns?: 1 | 2 | 3 | 4 | 6;
    spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
    stackable?: boolean;
    disableGutter?: boolean; // убрать нижний margin
    alignItems?: string;
    justify?: string;
}

export default class Grid extends PureComponent<GridProps> {
    static Column = Column;

    render() {
        const { children, spacing = 3, columns, stackable, disableGutter, alignItems = 'center', justify } = this.props;

        let xs: number | null = null;
        let sm: number | null = null;
        let md: number | null = null;
        let lg: number | null = null;
        if (columns) {
            md = 12 / columns;
            sm = 12 / columns;
            xs = 12 / columns;
            lg = 12 / columns;
        }
        if (stackable) {
            sm = 12;
            xs = 12;
        }

        const margin = `${spacing * 0.25}rem`;

        return (
            <MUGrid
                alignItems={alignItems}
                container
                spacing={spacing}
                sx={{
                    marginTop: !disableGutter ? margin : undefined,
                    marginBottom: !disableGutter ? margin : undefined
                }}
                justifyContent={justify}
            >
                {!xs && !sm && !md && !lg && children}
                {(xs || sm || md || lg) &&
                    React.Children.map(children, element => {
                        if (React.isValidElement(element)) {
                            return React.cloneElement(element, { ...element.props, xs, sm, md, lg });
                        }
                        return element;
                    })}
            </MUGrid>
        );
    }
}
