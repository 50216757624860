import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import '../node_modules/nprogress/nprogress.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { configure } from 'mobx';

import luLocale from 'date-fns/locale/ru';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import outerTheme from './muiTheme/index';

configure({ enforceActions: 'never' });

ReactDOM.render(
    <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={luLocale}>
            <ThemeProvider theme={outerTheme}>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </LocalizationProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
