import React, { useMemo, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import questionStore from 'stores/questionStore';
import productStore, { Product } from 'stores/productStore';
import authStore from 'stores/authStore';

import Grid from 'ui/Grid';
import TextInput from 'ui/TextInput';
import ListError from 'ui/ListError';
import InfoPopup from 'ui/InfoPopup';
import Button from 'ui/Button';
import { ErrorMessage } from 'ui/Message';

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';

import ItemDrawWrapper, { MenuTab } from '../ItemDrawWrapper';
import ProductCard from '../Product/ProductCard';

import { QuestionLinkState } from './QuestionLink';
import CostWithDiscount from '../../CostWithDiscount';
import WbSuggestionsField from '../WbSuggestionsField';
import WbGenderField from '../WbGenderField';

const QuestionPopup = React.memo(() => {
    return (
        <InfoPopup size="large">
            <Typography variant="subtitle2">Для чего нужен "вопрос"?</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Вопросы очень важны при выборе покупателями ваших товаров.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Когда ваш товар продвинется в ТОП при помощи выкупов, много людей станут просматривать вашу карточку при
                поиске товаров на Wildberries. Тогда количество и качество вопросов (в купе с фотографиями, отзывами,
                описанием) увеличивает вероятность выбора покупателем именно вашего товара.
            </Typography>
        </InfoPopup>
    );
});

const QuestionModal = () => {
    const question_id = CREATING_ITEM_ID;

    useMemo(() => {
        productStore.fetchList();
        questionStore.fetchItem(question_id);
        questionStore.setEditingItem(question_id, {
            questionText: '',
            gender: null,
            searchRequest: null
        });
    }, [question_id]);

    const { loadingItem, editingItem, errors } = questionStore.getItem(question_id);
    const { questionText, product_id } = editingItem;

    const handleQuestionText = (event: SyntheticEvent, { value }: { value: string }) => {
        questionStore.setEditingItem(question_id, { questionText: value });
    };

    const handleCreate = async () => {
        const question_id = await questionStore.createItem();
        if (question_id) {
            locationHistory.replace(QuestionLinkState(question_id));
        }
    };

    const cost = 15;

    let product: Product | null = null;
    if (product_id) {
        const productItem = productStore.getItem(product_id);
        product = productItem?.item || null;
    }

    const tabs: MenuTab[] = [{ id: 'main', label: 'Добавление вопроса', pathname: '/' }];

    return (
        <ItemDrawWrapper tabs={tabs} activeTab={'main'}>
            <Container maxWidth="sm">
                <ListError errors={errors} />

                <Grid>
                    <Grid.Column>
                        <ProductCard product={product} size="small" />
                    </Grid.Column>
                    <Grid.Column>
                        <WbGenderField title="Вопрос от" store={questionStore} item_id={question_id} />
                    </Grid.Column>
                    <Grid.Column>
                        <WbSuggestionsField item_id={question_id} store={questionStore} />
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            variant="standard"
                            label="Вопрос (до 1000 символов)"
                            value={questionText}
                            onChange={handleQuestionText}
                            maxLength={1000}
                            multiline
                            rowsMax={10}
                            rows={1}
                            extraLabel={
                                <InputAdornment position="end">
                                    <QuestionPopup />
                                </InputAdornment>
                            }
                        />
                    </Grid.Column>
                    {!authStore?.currentUser?.disableWb && (
                        <Grid.Column style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleCreate}
                                loading={loadingItem}
                                endIcon={<QuestionAnswerIcon />}
                                disabled={String(questionText).length < 5 || loadingItem}
                            >
                                Добавить вопрос (<CostWithDiscount cost={cost} />)
                            </Button>
                        </Grid.Column>
                    )}
                    {authStore?.currentUser?.disableWb && (
                        <Grid.Column>
                            <ErrorMessage header="Проблема в Wildberries">
                                На сайте и в приложение Wildberries временные проблемы. Добавить Вопрос временно
                                невозможно.
                            </ErrorMessage>
                        </Grid.Column>
                    )}
                </Grid>
            </Container>
        </ItemDrawWrapper>
    );
};

export default observer(QuestionModal);
