import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import ransomStore from 'stores/ransomStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import Box from '@mui/material/Box';

import Modal, { ModalBtnAction, ModalContent } from 'ui/Modal';
import ListError from 'ui/ListError';

import RansomFilterChips from './RansomFilterChips';
import WbFilterCategory from './WbFilterCategory';
import WbFilterOrderBy from './WbFilterOrderBy';

const RansomFilterModal = ({ onClose, ransom_id }: { onClose: () => void; ransom_id: number }) => {
    const { searchRequest, wbFilters } = ransomStore.getItem(ransom_id).editingItem;
    const {
        wbSearchFilters,
        wbSearchFiltersErrors: errors,
        wbSearchFiltersLoading: loading
    } = ransomStore.getItem(ransom_id).property;

    useEffect(() => {
        if (searchRequest && wbFilters) {
            ransomStore.fetchWbSearchFilters(ransom_id, searchRequest);
        }
    }, [searchRequest, wbFilters]);

    const actions: ModalBtnAction[] = [
        {
            onClick: () => ransomStore.clearRansomFilter(CREATING_ITEM_ID),
            label: 'Очистить',
            variant: 'text',
            color: 'inherit'
        },
        {
            onClick: onClose,
            label: `Сохранить${wbSearchFilters ? ` (Найдено товаров: ${wbSearchFilters.total})` : ''}`,
            variant: 'contained',
            color: 'primary',
            loading
        }
    ];

    return (
        <Modal header="Добавить фильтры поиска" maxWidth="sm" onClose={onClose} actions={actions} loading={loading}>
            {wbFilters && (
                <Box sx={{ px: 2 }}>
                    <RansomFilterChips ransom_id={ransom_id} />
                </Box>
            )}
            <ModalContent>
                <ListError errors={errors} />
                <WbFilterOrderBy ransom_id={ransom_id} />

                {wbSearchFilters &&
                    wbSearchFilters.filters
                        .filter(filter => filter.name !== 'Срок доставки')
                        .map(filter => <WbFilterCategory key={filter.key} filter={filter} />)}
            </ModalContent>
        </Modal>
    );
};

export default observer(RansomFilterModal);
