import React, { ReactNode, CSSProperties } from 'react';
import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import MUITable from '@mui/material/Table';
import TableBodyMUI from '@mui/material/TableBody';
import TableCellMUI, { TableCellProps, SortDirection as SortDirectionUI } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHeadMUI from '@mui/material/TableHead';
import TableRowMUI, { TableRowProps } from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableFooterMUI from '@mui/material/TableFooter';
import LoaderAwait from './LoaderAwait';

import commonStore from 'stores/commonStore';

export type SortDirection = SortDirectionUI;

export const TableHead = TableHeadMUI;
export const TableBody = TableBodyMUI;
export const TableFooter = TableFooterMUI;

// const useStylesTableRow = makeStyles(({ palette, spacing }: Theme) =>
//     createStyles({
//         error: {
//             background: '#fff6f6'
//         },
//         good: {
//             background: '#c5e39c'
//         },
//         head: {
//             // background: '#f9fafb'
//         },
//         sizeSmall: {
//             paddingTop: spacing(0.5),
//             paddingBottom: spacing(0.5)
//         }
//     })
// );

const TableRowTS = styled(TableRowMUI)<TableRowProps>(({ theme }) => ({
    '&.MuiTableRow-sizeSmall': {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5)
    }
}));

export const TableRow = (props: TableRowProps & { negative?: boolean; positive?: boolean }) => {
    // const classes = useStylesTableRow();
    const { children, negative, positive, ...otherProps } = props;

    return (
        <TableRowTS
            {...otherProps}
            // classes={
            //     negative
            //         ? {
            //               root: classes.error
            //           }
            //         : positive
            //         ? {
            //               root: classes.good
            //           }
            //         : {
            //               head: classes.head,
            //               footer: classes.head
            //           }
            // }
            sx={negative ? { background: '#ffc8c8' } : positive ? { background: '#c5e39c' } : undefined}
        >
            {children}
        </TableRowTS>
    );
};

// const useStylesTableCell = makeStyles(({ palette, spacing }: Theme) =>
//     createStyles({
//         sizeSmall: {
//             paddingTop: spacing(1),
//             paddingBottom: spacing(1)
//         },
//         paddingNone: {
//             padding: spacing(0.75, 3, 0.75, 2),
//             '&:last-child': {
//                 padding: spacing(0.75, 2)
//             }
//         }
//     })
// );

const TableCellTS = styled(TableCellMUI)<TableCellProps>(({ theme }) => ({
    '&.MuiTableCell-sizeSmall': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    '&.MuiTableCell-paddingNone': {
        padding: theme.spacing(0.75, 3, 0.75, 2),
        '&:last-child': {
            padding: theme.spacing(0.75, 2)
        }
    }
}));

export const TableCell = (props: TableCellProps & { nowrap?: boolean }) => {
    // const classes = useStylesTableCell();
    const { sortDirection, children, onClick, nowrap, sx, ...otherProps } = props;

    return (
        // <TableCellMUI {...props} style={{ paddingTop: '0.75rem', paddingBottom: '0.75rem' }}>
        <TableCellTS
            {...otherProps}
            // classes={classes}
            sx={{ ...sx, ...{ whiteSpace: nowrap ? 'nowrap' : undefined } }}
        >
            {sortDirection === undefined && children}
            {sortDirection !== undefined && (
                <TableSortLabel active={sortDirection !== false} direction={sortDirection || 'asc'} onClick={onClick}>
                    {children}
                </TableSortLabel>
            )}
        </TableCellTS>
    );
};

type TableProps = {
    children?: ReactNode;
    size?: 'small' | 'medium';
    maxHeight?: string | number;
    compact?: boolean;
    style?: CSSProperties;
    elevation?: number;
    loading?: boolean;
    id?: string;
};

export const Table = (props: TableProps) => {
    const { children, size, maxHeight, compact, style = {}, elevation = 1, loading, id } = props;

    let sizeAdjusted = size || (commonStore.isMobile ? 'medium' : 'small');

    return (
        <TableContainer
            component={Paper}
            elevation={elevation}
            style={{ position: 'relative', maxHeight: maxHeight || undefined, ...style }}
            id={id}
        >
            <LoaderAwait active={loading} size={72} />
            <MUITable stickyHeader={Boolean(maxHeight)} size={sizeAdjusted} padding={compact ? 'none' : undefined}>
                {children}
            </MUITable>
        </TableContainer>
    );
};

export default Table;
