import React, { SyntheticEvent, useState } from 'react';

import Container from '@mui/material/Container';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import ListContainer from '../ListContainer';
import { ListPaper } from '../ListWrapper';

import WbAdsRatesBySearch from './WbAdsRatesBySearch';
import WbAdsRatesByCard from './WbAdsRatesByCard';

const WbAdsRates = () => {
    const [type, setType] = useState<1 | 2>(1);
    const handleChangeType = (event: SyntheticEvent, type: 1 | 2 | null) => {
        if (type) {
            setType(type);
        }
    };

    return (
        <ListContainer bgUrl="/static/listbg/unruly-10-relationships.webp">
            <Container maxWidth="lg">
                <ListPaper store={null}>
                    <ToggleButtonGroup
                        size="small"
                        color="primary"
                        value={type}
                        exclusive
                        onChange={handleChangeType}
                        fullWidth
                        sx={{ marginBottom: '1rem' }}
                    >
                        <ToggleButton value={1} sx={{ gap: 1 }}>
                            В поиске
                        </ToggleButton>
                        <ToggleButton value={2} sx={{ gap: 1 }}>
                            В карточке
                        </ToggleButton>
                    </ToggleButtonGroup>

                    {type === 1 && <WbAdsRatesBySearch />}
                    {type === 2 && <WbAdsRatesByCard />}
                </ListPaper>
            </Container>
        </ListContainer>
    );
};

export default WbAdsRates;
