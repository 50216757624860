import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import deepCopy from 'common/deepCopy';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import authStore from 'stores/authStore';
import ransomStore, { Ransom, RansomType } from 'stores/ransomStore';
import productStore, { StoreIds } from 'stores/productStore';

import ItemDrawWrapper, { MenuTab } from '../ItemDrawWrapper';

import RansomCreateModeTab from './RansomCreateModeTab';
import RansomCreateModeSimilar from './RansomCreateModeSimilar';
import RansomCreateModeSmart from './RansomCreateModeSmart';

const clearRansomObject: Partial<Ransom> = {
    store_point_id: null,
    store_point_ids: [],
    product_id: null,
    wb_size_id: null,
    searchRequest: '',
    gender: 'female',
    type: RansomType.Once,
    planningExecuteTime: null,
    preRunAccount: false,
    oneTimeAccount: false,
    ransomSmartItems: [],
    ransomsCount: null,
    paymentType: 1,
    payPhone: authStore?.currentUser?.phone || null,
    rival_item_ids: [],
    additional_store_item_ids: [],
    wbFilters: [],
    wbFilterOrderBy: 'popular'
};

const RansomCreateMode = () => {
    const ransom_id = CREATING_ITEM_ID;
    const { tabName }: { tabName?: string } = useParams();

    useMemo(() => {
        ransomStore.setEditingItem(ransom_id, {
            ...deepCopy(clearRansomObject),
            store_id: productStore.getCurrentStoreId()
        });
        ransomStore.setProperty(ransom_id, { rivalEnable: false, rivalItems: [] });
    }, [ransom_id]);

    useEffect(() => {
        ransomStore.setEditingItem(ransom_id, {
            ...deepCopy(clearRansomObject),
            store_id: productStore.getCurrentStoreId()
        });
        ransomStore.setProperty(ransom_id, { rivalEnable: false, rivalItems: [] });
    }, [productStore.listFilter.store_id]);

    const tabs: MenuTab[] = [
        { id: 'main', label: 'Создание Выкупа', pathname: `/ransoms/list/detail/${ransom_id}/main` },
        { id: 'smart', label: 'Smart Выкупы', pathname: `/ransoms/list/detail/${ransom_id}/smart` }
    ];

    if (productStore.getCurrentStoreId() === StoreIds.WB) {
        tabs.push({
            id: 'similar',
            label: 'Товары Конкурентов',
            pathname: `/ransoms/list/detail/${ransom_id}/similar`
        });
    }

    return (
        <ItemDrawWrapper tabs={tabs} activeTab={tabName || 'main'}>
            {(!tabName || tabName === 'main') && <RansomCreateModeTab ransom_id={ransom_id} />}
            {tabName === 'smart' && <RansomCreateModeSmart ransom_id={ransom_id} />}
            {tabName === 'similar' && <RansomCreateModeSimilar ransom_id={ransom_id} />}
        </ItemDrawWrapper>
    );
};

export default observer(RansomCreateMode);
