import React, { SyntheticEvent, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import wbProxyStore, { WBProxy } from 'stores/wbProxyStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import Grid from 'ui/Grid';
import Button from 'ui/Button';
import Select from 'ui/Select';
import TextInput, { NumberInput } from 'ui/TextInput';
import ListError from 'ui/ListError';

const protocols = ['http', 'https', 'socks4', 'socks5'] as const;

const AddProxyBox = ({ onClose }: { onClose: () => void }) => {
    useMemo(() => {
        wbProxyStore.fetchItem(CREATING_ITEM_ID);
        wbProxyStore.setEditingItem(CREATING_ITEM_ID, {
            ip: '',
            port: 0,
            urlScheme: 'https',
            login: null,
            pass: null
        });
    }, []);

    const handleChange = <T extends keyof WBProxy>(
        event: SyntheticEvent,
        { value, name }: { value: WBProxy[T]; name: T }
    ) => {
        wbProxyStore.setEditingItem(CREATING_ITEM_ID, { [name]: value });
    };

    const handleChangeSelect = <T extends keyof WBProxy>({ value, name }: { value: WBProxy[T]; name: T }) => {
        wbProxyStore.setEditingItem(CREATING_ITEM_ID, { [name]: value });
    };

    const [disabledSave, setDisabledSave] = useState(true);
    const testProxy = () => {
        window.postMessage({
            operation: 'testProxy',
            proxyRules: `${urlScheme}://${login && pass ? `${login}:${pass}@` : ''}${ip}:${port}`
        });
        setDisabledSave(false);
    };

    const handleSave = async () => {
        if (await wbProxyStore.createItem()) {
            wbProxyStore.fetchList();
            onClose();
        }
    };

    const {
        editingItem: { ip, port, urlScheme, login, pass },
        loadingItem,
        errors
    } = wbProxyStore.getItem(CREATING_ITEM_ID);

    return (
        <Grid>
            <Grid.Column>
                <Button variant="text" color="inherit" startIcon={<ArrowBackIcon />} onClick={onClose}>
                    назад
                </Button>
            </Grid.Column>
            <Grid.Column>
                <ListError errors={errors} />
                <Select
                    value={urlScheme}
                    name="urlScheme"
                    label="urlScheme"
                    onChange={handleChangeSelect}
                    options={protocols.map(protocol => ({ value: protocol, text: protocol }))}
                    fullWidth
                />
            </Grid.Column>
            <Grid.Column width={8}>
                <TextInput value={ip} name="ip" label="ip" onChange={handleChange} />
            </Grid.Column>
            <Grid.Column width={4}>
                <NumberInput value={port || 0} name="port" label="port" onChange={handleChange} min={0} />
            </Grid.Column>
            <Grid.Column width={6}>
                <TextInput value={login} name="login" label="login" onChange={handleChange} />
            </Grid.Column>
            <Grid.Column width={6}>
                <TextInput value={pass} name="pass" label="pass" onChange={handleChange} />
            </Grid.Column>
            <Grid.Column width={6}>
                <Button color="secondary" variant="outlined" fullWidth onClick={testProxy}>
                    Тест
                </Button>
            </Grid.Column>
            <Grid.Column width={6}>
                <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={disabledSave}
                    loading={loadingItem}
                    onClick={handleSave}
                >
                    Сохранить
                </Button>
            </Grid.Column>
        </Grid>
    );
};

export default observer(AddProxyBox);
