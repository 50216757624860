import React from 'react';
import commonStore from 'stores/commonStore';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import FFHeader from './FFHeader';
import FFFooter from './FFFooter';
import ListContainer from '../ListContainer';

import Grid from 'ui/Grid';

const ContactCard = ({
    header,
    text,
    imgSrc,
    href,
    tooltip
}: {
    header: string;
    text: string;
    imgSrc: string;
    href?: string;
    tooltip?: string;
}) => {
    return (
        <Card sx={{ display: 'flex', background: '#dadada', borderRadius: 4 }}>
            <Box sx={{ m: 1, ml: 2, my: 2 }}>
                <Avatar variant="circular" style={{ width: `${48}px`, height: `${48}px` }} src={imgSrc} />
            </Box>
            <CardContent>
                <Typography variant="subtitle1" sx={{ opacity: 0.7 }}>
                    {header}
                </Typography>
                <Typography variant="h6" component="div">
                    {!href && text}
                    {href && (
                        <a href={href} target="_blank" style={{ color: '#1B1A24' }} title={tooltip}>
                            {text}
                        </a>
                    )}
                </Typography>
            </CardContent>
        </Card>
    );
};

const FFPricePage = () => {
    const { isMobile } = commonStore;

    return (
        <ListContainer bgUrl="/static/listbg/unruly-05-done.webp">
            <Container maxWidth="lg" sx={{ px: isMobile ? 0 : 2 }}>
                <Stack spacing={3}>
                    <FFHeader />

                    <Paper elevation={1} sx={{ p: 3, pt: 0 }}>
                        <Grid columns={commonStore.isMobile ? 1 : 2} spacing={3}>
                            <Grid.Column>
                                <ContactCard
                                    header="Менеджер"
                                    text="+7 (924) 998-99-41"
                                    imgSrc="https://static.tildacdn.com/tild3331-3933-4835-a566-303339653763/Group_125093.svg"
                                    href="https://t.me/ffts_manager"
                                    tooltip="Откроется в Telegram"
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ContactCard
                                    header="Руководитель"
                                    text="+7 (924) 998-99-42"
                                    imgSrc="https://static.tildacdn.com/tild3331-3933-4835-a566-303339653763/Group_125093.svg"
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ContactCard
                                    header="Время работы"
                                    text="пн-вс: 10:00-21:00"
                                    imgSrc="https://static.tildacdn.com/tild3064-3966-4261-b261-393336356634/Group_125095.svg"
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <ContactCard
                                    header="Сайт"
                                    text="https://ffts.ru"
                                    href="https://ffts.ru"
                                    imgSrc="https://static.tildacdn.com/tild6630-3634-4630-b766-656237393133/Frame_20.svg"
                                    tooltip="Сайт TopStore FulFillment"
                                />
                            </Grid.Column>
                        </Grid>
                        <Typography sx={{ my: 2, mt: 4 }}>
                            <b>Адрес склада приемки товара:</b> Московская область, г. Подольск ул. Суворова, д. 53А,
                            оф.1
                        </Typography>
                        <a
                            href="https://yandex.ru/maps/10747/podolsk/house/ulitsa_suvorova_53a/Z04Ycg5kSEIGQFtvfX90eXtmYQ==/?ll=37.494170%2C55.358755&z=17"
                            target="_blank"
                            rel="noindex nofollow noreferrer"
                            title="Открыть на Яндекс.Картах"
                        >
                            <Box
                                sx={{
                                    mt: 3,
                                    backgroundImage: `url('https://static.tildacdn.com/tild6431-6132-4135-b663-626537616362/Rectangle_921.svg')`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    height: '400px',
                                    position: 'relative'
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.6)',
                                        p: 2,
                                        borderRadius: 1,
                                        position: 'absolute',
                                        top: 10,
                                        left: 10,
                                        width: 300,
                                        color: 'secondary.dark',
                                        fontWeight: 500
                                    }}
                                >
                                    Московская область, г. Подольск <br />
                                    ул. Суворова, д. 53А, оф.1
                                </Box>
                            </Box>
                        </a>
                    </Paper>
                    <FFFooter />
                </Stack>
            </Container>
        </ListContainer>
    );
};

export default FFPricePage;
