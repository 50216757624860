import React, { useMemo, ReactNode, ReactElement, useState } from 'react';
import { observer } from 'mobx-react';

import wbStatisticsApiStore, { WbStatisticsApiType } from 'stores/wbApi/wbStatisticsApiStore';
import commonStore from 'stores/commonStore';

import Modal, { ModalContent, useToggleModal } from 'ui/Modal';
import Table, { TableCell, TableRow } from 'ui/Table';
import Progress from 'ui/Progress';
import ListError from 'ui/ListError';
import Time from 'ui/Time';
import Checkbox from 'ui/Checkbox';

import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';

const KeysPrinted: Record<string, { label: string; format?: 'money' | 'percent' | 'time' | 'percentDec' }> = {
    odid: { label: 'уникальный идентификатор позиции заказа' },
    gNumber: { label: 'номер заказа' },

    date: { label: 'дата продажи', format: 'time' },
    lastChangeDate: { label: 'дата время обновления информации в сервисе', format: 'time' },

    incomeID: { label: 'номер поставки' },
    saleID: { label: 'уникальный идентификатор продажи/возврата' },
    IsStorno: { label: 'IsStorno' },
    nmId: { label: 'Артикул WB' },

    subject: { label: 'предмет' },
    supplierArticle: { label: 'ваш артикул' },

    category: { label: 'категория' },
    brand: { label: 'бренд' },
    techSize: { label: 'размер' },
    barcode: { label: 'штрих-код' },

    warehouseName: { label: 'склад отгрузки' },
    countryName: { label: 'страна' },
    oblastOkrugName: { label: 'округ' },
    regionName: { label: 'регион' },

    totalPrice: { label: 'начальная розничная цена товара', format: 'money' },
    discountPercent: { label: 'согласованная скидка на товар', format: 'percent' },
    isSupply: { label: 'договор поставки' },
    isRealization: { label: 'договор реализации' },
    promoCodeDiscount: { label: 'согласованный промокод', format: 'percent' },

    spp: { label: 'согласованная скидка постоянного покупателя (СПП)', format: 'percent' },
    forPay: { label: 'к перечислению поставщику', format: 'money' },
    finishedPrice: { label: 'фактическая цена из заказа (с учетом всех скидок, включая и от ВБ)', format: 'money' },
    priceWithDisc: {
        label: 'цена, от которой считается вознаграждение поставщика forpay (с учетом всех согласованных скидок)',
        format: 'money'
    },

    sticker: { label: 'аналогично стикеру, который клеится на товар в процессе сборки' },
    isCancel: { label: 'Отмена заказа' },
    cancel_dt: { label: 'Дата отмены заказа', format: 'time' },

    rrd_id: { label: 'Номер строки' },
    rid: { label: 'Уникальный идентификатор позиции заказа' },
    realizationreport_id: { label: 'Номер отчета' },
    suppliercontract_code: { label: 'Договор' },
    gi_id: { label: 'Номер поставки' },

    subject_name: { label: 'Предмет' },
    nm_id: { label: 'Артикул' },
    brand_name: { label: 'Бренд' },
    sa_name: { label: 'Артикул поставщика' },
    ts_name: { label: 'Размер' },
    doc_type_name: { label: 'Тип документа' },
    quantity: { label: 'Количество' },
    retail_price: { label: 'Цена розничная', format: 'money' },
    retail_amount: { label: 'Сумма продаж(Возвратов)', format: 'money' },
    sale_percent: { label: 'Согласованная скидка', format: 'percent' },
    commission_percent: { label: 'Процент комиссии', format: 'percent' },
    office_name: { label: 'Склад' },
    supplier_oper_name: { label: 'Обоснование для оплаты' },

    shk_id: { label: 'ШК' },
    retail_price_withdisc_rub: { label: 'Цена розничная с учетом согласованной скидки', format: 'money' },
    delivery_amount: { label: 'Кол-во доставок' },
    return_amount: { label: 'Кол-во возвратов' },
    delivery_rub: { label: 'Стоимость логистики', format: 'money' },
    gi_box_type_name: { label: 'Тип коробов' },
    product_discount_for_report: { label: 'Согласованный продуктовый дисконт' },
    supplier_promo: { label: 'Промокод' },

    ppvz_spp_prc: { label: 'Скидка постоянного Покупателя (СПП)', format: 'percentDec' },
    ppvz_kvw_prc_base: { label: 'Размер кВВ без НДС, % Базовый', format: 'percentDec' },
    ppvz_kvw_prc: { label: 'Итоговый кВВ без НДС, %', format: 'percentDec' },
    ppvz_sales_commission: { label: 'Вознаграждение с продаж до вычета услуг поверенного, без НДС', format: 'money' },
    ppvz_for_pay: { label: 'К перечислению Продавцу за реализованный Товар', format: 'money' },
    ppvz_reward: { label: 'Возмещение Расходов услуг поверенного', format: 'money' },
    ppvz_vw: { label: 'Вознаграждение Вайлдберриз (ВВ), без НДС', format: 'money' },
    ppvz_vw_nds: { label: 'НДС с Вознаграждения Вайлдберриз', format: 'money' },
    ppvz_office_id: { label: 'Номер офиса' },
    ppvz_office_name: { label: 'Наименование офиса доставки' },
    ppvz_supplier_id: { label: 'Номер партнера' },
    ppvz_supplier_name: { label: 'Партнер' },
    ppvz_inn: { label: 'ИНН партнера' },

    bonus_type_name: { label: 'Обоснование штрафов и доплат' },
    declaration_number: { label: 'Номер таможенной декларации' },
    sticker_id: { label: 'Аналогично стикеру, который клеится на товар в процессе сборки' },
    site_country: { label: 'Страна продажи' },

    order_dt: { label: 'Даты заказа', format: 'time' },
    sale_dt: { label: 'Дата продажи', format: 'time' },
    rr_dt: { label: 'Дата операции', format: 'time' }
} as const;

const PrintObject = ({ item }: { item: Record<string, string | number | boolean | null> }) => {
    const [printEmpty, toggleEmpty] = useToggleModal();

    return (
        <>
            <Checkbox label="Показать пустые строки" checked={printEmpty} onClick={toggleEmpty} sx={{ ml: 1 }} />
            <Table size="small" compact>
                {Object.keys(item).map(key => {
                    if (!printEmpty && !item[key]) {
                        return null;
                    }

                    let label = key;
                    let value: ReactNode | boolean = item[key];

                    if (key in KeysPrinted) {
                        label = KeysPrinted[key].label;
                        switch (KeysPrinted[key].format) {
                            case 'money':
                                value = Number(value).toLocaleString() + ` ₽`;
                                break;
                            case 'time':
                                value = <Time time={Number(value)} />;
                                break;
                            case 'percent':
                                value = Number(value).toLocaleString() + `%`;
                                break;
                            case 'percentDec':
                                value = (Number(value) * 100).toLocaleString() + `%`;
                                break;
                        }
                    }

                    if (typeof value === 'boolean') {
                        value = value ? 'да' : 'нет';
                    }

                    return (
                        <TableRow key={key}>
                            <TableCell>{label}</TableCell>
                            <TableCell>{value}</TableCell>
                        </TableRow>
                    );
                })}
            </Table>
        </>
    );
};

const WbStatisticsApiContent = ({ order, sale, realizations }: WbStatisticsApiType) => {
    const [activeTab, setActiveTab] = useState(sale ? 100 : order ? 100 : 0);
    const tabs: { label: ReactNode; disabled: boolean; id: number; caption: ReactElement }[] = [];

    tabs.push({
        label: 'Продажа',
        disabled: !sale,
        id: 100,
        caption: <Typography variant="caption">{sale ? <Time time={sale.lastChangeDate} /> : '---'}</Typography>
    });
    tabs.push({
        label: 'Заказ',
        disabled: !order,
        id: 101,
        caption: <Typography variant="caption">{order ? <Time time={order.lastChangeDate} /> : '---'}</Typography>
    });

    realizations.forEach(({ supplier_oper_name, rr_dt }, index) => {
        tabs.push({
            label: `Реализация (${supplier_oper_name})`,
            disabled: false,
            id: index,
            caption: (
                <Typography variant="caption">
                    <Time time={rr_dt} maxDaysToTime={0} />
                </Typography>
            )
        });
    });

    return (
        <>
            <Tabs
                allowScrollButtonsMobile
                value={activeTab}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
            >
                {tabs.map(({ label, id, disabled, caption }) => (
                    <Tab
                        value={id}
                        key={id}
                        label={label}
                        onClick={() => setActiveTab(id)}
                        disabled={disabled}
                        wrapped={commonStore.isMobile}
                        icon={caption}
                        iconPosition="bottom"
                        sx={{ px: commonStore.isMobile ? 1 : 3 }}
                    />
                ))}
            </Tabs>
            <Paper>
                <PrintObject item={activeTab === 100 ? sale : activeTab === 101 ? order : realizations[activeTab]} />
            </Paper>
        </>
    );
};

const WbStatisticsApiModal = ({ odid, onClose }: { odid: number; onClose: () => void }) => {
    useMemo(() => {
        wbStatisticsApiStore.fetchItem(odid);
    }, [odid]);

    const { errors, loadingItem, item } = wbStatisticsApiStore.getItem(odid);

    return (
        <Modal maxWidth="md" onClose={onClose} header="Информация о Движение товара" fullScreen={commonStore.isMobile}>
            <ModalContent>
                <Progress show={loadingItem} />
                <ListError errors={errors} />

                {item && <WbStatisticsApiContent {...item} />}
            </ModalContent>
        </Modal>
    );
};

export default observer(WbStatisticsApiModal);
