import React from 'react';

import deliveryStore from 'stores/deliveryStore';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';
import Confirm from 'ui/Confirm';

const DidNotPayBtn = ({ delivery_id }: { delivery_id: number }) => {
    const [showNotPay, toggleShowNotPay] = useToggleModal();
    const handleDidNotPay = async () => {
        deliveryStore.toggleDisableItem(delivery_id, false);
        toggleShowNotPay();
    };

    return (
        <>
            <Button color="inherit" onClick={toggleShowNotPay} fullWidth>
                Я не оплатил(а)
            </Button>
            {showNotPay && (
                <Confirm
                    content='Если вы не успели оплатить товар в Выкупе, и случайно нажали на кнопку "Я оплатил(а)", то можно отменить данный выкуп. Потраченные средства за выкуп вернутся вам на счет TopStore'
                    header="Покупка не была совершена"
                    confirmButton="Удалить данный выкуп"
                    onConfirm={handleDidNotPay}
                    onCancel={toggleShowNotPay}
                />
            )}
        </>
    );
};

export default DidNotPayBtn;
