import React from 'react';
import { Link } from 'react-router-dom';

export const DeliveryLinkState = (delivery_id: number) => ({
    pathname: `/deliveries/list/detail/${delivery_id}`,
    state: { modal: true }
});

const DeliveryLink = ({ delivery_id }: { delivery_id: number }) => (
    <Link to={DeliveryLinkState(delivery_id)}>Доставка №{delivery_id}</Link>
);

export default DeliveryLink;
