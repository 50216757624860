import React, { ReactNode } from 'react';

import { FeedbacksOrderBy } from 'api/productApi';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';

import Box from '@mui/material/Box';

import Progress from 'ui/Progress';
import ListError from 'ui/ListError';
import UIDivider from 'ui/Divider';

import ProductFeedbacksPult from 'components/Items/Product/FeedbackBlocks/ProductFeedbacksPult';

type WbFeedbacksContainerProps = {
    children: ReactNode;
    loading: boolean;
    errors: string[];

    orderBy?: FeedbacksOrderBy;
    orderDirection?: OrderDirectionType;
    handleSort?: (newOrderBy: FeedbacksOrderBy) => void;

    otherPultElement?: ReactNode;
    bottomPadding?: number;
};

const WbFeedbacksContainer = ({
    children,
    loading,
    errors,
    orderBy,
    orderDirection,
    handleSort,
    otherPultElement,
    bottomPadding = 120
}: WbFeedbacksContainerProps) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: `calc(100vh - ${bottomPadding}px)` }}>
            <Box sx={{ display: 'flex' }}>
                {orderBy && orderDirection && handleSort && (
                    <Box sx={{ flex: 1 }}>
                        <ProductFeedbacksPult
                            handleSort={handleSort}
                            orderBy={orderBy}
                            orderDirection={orderDirection}
                        />
                    </Box>
                )}
                {otherPultElement}
            </Box>
            <UIDivider />

            <Progress show={loading} linear />
            <ListError errors={errors} />
            {children}
        </Box>
    );
};

export default WbFeedbacksContainer;
