import React, { CSSProperties, ReactNode, Ref, MouseEvent } from 'react';
import Progress from './Progress';

import MUButton from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type ButtonProps = {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    basic?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    loading?: boolean;
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large' | 'huge';
    style?: CSSProperties;
    fullWidth?: boolean;
    endIcon?: ReactNode;
    startIcon?: ReactNode;
    tooltip?: ReactNode;
    disableElevation?: boolean;
    primary?: boolean;
    disableRipple?: boolean;
    href?: string;
    target?: string;
    type?: 'submit' | 'button' | 'reset';
    sx?: SxProps<Theme>;
} & ({ children: ReactNode; download?: boolean } | { icon: ReactNode });

const Button = React.forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
    const {
        onClick,
        className,
        variant,
        color,
        loading,
        disabled,
        size,
        style,
        fullWidth,
        endIcon,
        startIcon,
        tooltip,
        disableElevation,
        primary,
        disableRipple,
        href,
        target,
        type,
        sx
    } = props;

    let mergedProps = {
        onClick,
        className,
        color: primary ? 'primary' : color,
        disabled: loading || disabled,
        style,
        disableRipple,
        target,
        href,
        ref,
        type,
        rel: target === '_blank' ? 'noreferrer' : undefined
    };

    const OurButton =
        'icon' in props ? (
            <IconButton size={size === 'huge' ? 'large' : size === 'large' ? 'medium' : size} {...mergedProps} sx={sx}>
                {props.icon}
                {loading && <Progress show size={24} color="primary" />}
            </IconButton>
        ) : (
            <MUButton
                size={size === 'huge' ? 'large' : size}
                component={props.download ? 'a' : 'button'}
                variant={variant}
                fullWidth={fullWidth}
                endIcon={endIcon}
                startIcon={startIcon}
                disableElevation={disableElevation}
                {...mergedProps}
                sx={sx}
                download={props.download}
            >
                {props.children}
                {loading && <Progress show size={24} color="primary" />}
            </MUButton>
        );

    if (tooltip && !disabled) {
        return <Tooltip title={tooltip}>{OurButton}</Tooltip>;
    }

    return OurButton;
});

export default React.memo(Button);
