import React from 'react';
import { observer } from 'mobx-react';
import { useStoreHooks } from 'common/listFilterHooks';

import reviewStore, { ReviewsFilter as ReviewsFilterType, ReviewStatusEnum } from 'stores/reviewStore';
import productStore from 'stores/productStore';
import authStore from 'stores/authStore';

import Select from 'ui/Select';
import Checkbox from 'ui/Checkbox';

import ListFilterWrapper from '../ListFilterWrapper';
import StoresToggle from 'components/StoresToggle';

const ReviewsFilter = () => {
    const { handleToggleAllUsers, handleChangeStatus, handleChangeProductId } =
        useStoreHooks<ReviewsFilterType>(reviewStore);

    const { listFilter } = reviewStore;
    const { status, product_id, allUsers, moderation } = listFilter;

    const { loadingList: loadingListProduct, productOptions } = productStore;

    const handleToggleModeration = () => {
        reviewStore.changeFilter('moderation', !moderation);
    };

    return (
        <>
            <ListFilterWrapper store={reviewStore}>
                {authStore.isAdmin() && <Checkbox label="все" checked={allUsers} onClick={handleToggleAllUsers} />}
                {authStore.isAdmin() && (
                    <Checkbox label="модерация" checked={moderation} onClick={handleToggleModeration} />
                )}

                <StoresToggle />

                <Select
                    label="Статус"
                    size="small"
                    variant="standard"
                    value={status}
                    onChange={handleChangeStatus}
                    options={[
                        { value: 1, text: 'Отправка' },
                        { value: 2, text: 'Отправлен' },
                        { value: 3, text: 'Модерация' },
                        { value: 4, text: 'Возврат' },
                        { value: ReviewStatusEnum.WaitingForExecution, text: 'Ожидает' },
                        { value: 100, text: 'Ошибка' }
                    ]}
                    clearable
                    fullWidth
                />

                <Select
                    label="Товар"
                    value={product_id}
                    onChange={handleChangeProductId}
                    options={productOptions}
                    loading={loadingListProduct}
                    size="small"
                    variant="standard"
                    fullWidth
                    clearable
                />
            </ListFilterWrapper>
        </>
    );
};

export default observer(ReviewsFilter);
