import React, { ReactNode, useState } from 'react';
// import { Theme } from '@mui/material/styles';
// import { createStyles, withStyles } from '@mui/styles';

import Alert, { AlertProps } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

// const MessageMargined = withStyles(({ spacing }: Theme) =>
//     createStyles({
//         root: {
//             margin: spacing(0, 0, 1)
//         }
//     })
// )(Alert);
export const MessageTitle = AlertTitle;

export const Message = (props: AlertProps & { enableClose?: boolean; onClose?: () => void }) => {
    const { enableClose, sx, ...alertProps } = props;
    const [open, setOpen] = useState(true);
    const onClose = enableClose
        ? () => {
              if (props.onClose) {
                  props.onClose();
              }
              setOpen(false);
          }
        : undefined;

    return open ? <Alert sx={{ ...sx, m: '0 0 1' }} {...alertProps} onClose={onClose} closeText="Скрыть" /> : null;
};

type MessageProps = {
    children?: ReactNode;
    variant?: 'standard' | 'filled' | 'outlined';
    header?: ReactNode;
    className?: string;
    enableClose?: boolean;
    onClose?: () => void;
    sx?: SxProps<Theme>;
};

export const WarningMessage = React.memo((props: MessageProps) => (
    <Message severity="warning" {...props}>
        {props.header && <MessageTitle>{props.header}</MessageTitle>}
        {props.children}
    </Message>
));

export const InfoMessage = React.memo((props: MessageProps) => (
    <Message severity="info" {...props}>
        {props.header && <MessageTitle>{props.header}</MessageTitle>}
        {props.children}
    </Message>
));

export const ErrorMessage = React.memo((props: MessageProps) => (
    <Message severity="error" {...props}>
        {props.header && <MessageTitle>{props.header}</MessageTitle>}
        {props.children}
    </Message>
));

export const SuccessMessage = React.memo((props: MessageProps) => (
    <Message severity="success" {...props}>
        {props.header && <MessageTitle>{props.header}</MessageTitle>}
        {props.children}
    </Message>
));

export default Message;
