import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import deliveryStore from 'stores/deliveryStore';

import Box from '@mui/material/Box';

import Table, { TableBody, TableCell, TableHead, TableRow } from 'ui/Table';
import { WarningMessage } from 'ui/Message';

import ListWrapper from '../ListWrapper';
import ListPagination from '../ListPagination';
import DeliveriesFilter from './DeliveriesFilter';
import DeliveryTableRow from './DeliveryTableRow';
import DeliveryDataViewToggle from './DeliveryDataViewToggle';

const DeliveriesList = () => {
    const { list, loadingList, handleSort, adjustDirection } = deliveryStore;

    return (
        <ListWrapper
            store={deliveryStore}
            bgUrl="/static/listbg/unruly-03-delivery.webp"
            createBtn={
                <Box sx={{ width: 300 }}>
                    <DeliveryDataViewToggle />
                </Box>
            }
        >
            <DeliveriesFilter />

            <Table elevation={0} loading={loadingList}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sortDirection={adjustDirection('createTime')}
                            onClick={() => handleSort('createTime')}
                        >
                            Оформлен
                        </TableCell>
                        <TableCell sortDirection={adjustDirection('status')} onClick={() => handleSort('status')}>
                            Статус
                        </TableCell>
                        <TableCell
                            sx={{ minWidth: '250px' }}
                            sortDirection={adjustDirection('product_id')}
                            onClick={() => handleSort('product_id')}
                        >
                            Наименование
                        </TableCell>
                        <TableCell
                            sortDirection={adjustDirection('wb_store_id')}
                            onClick={() => handleSort('wb_store_id')}
                        >
                            Адрес ПВЗ
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(delivery => (
                        <DeliveryTableRow delivery={delivery} />
                    ))}
                </TableBody>
                <ListPagination
                    pagination={deliveryStore.pagination}
                    listCount={deliveryStore.listCount}
                    pageChange={deliveryStore.pageChange}
                    pageSizeChange={deliveryStore.pageSizeChange}
                    colSpan={4}
                />
            </Table>

            {!loadingList && list.length === 0 && (
                <WarningMessage header="Нет доставок">
                    Здесь появится информация о получение товара после того, как вы создадите и оплатите один из&nbsp;
                    <Link to="/ransoms/list">выкупов</Link>
                </WarningMessage>
            )}
        </ListWrapper>
    );
};

export default observer(DeliveriesList);
