import { observable, action, computed, makeObservable } from 'mobx';

import debounce from 'common/debounce';
import deepCopy from 'common/deepCopy';
import isEqual from 'common/isEqual';
import { getDefaultPagination, matchPaginationTotalPages } from 'common/pagination';

import ItemStorePrototype, { TItemExtended } from './ItemStore.prototype';
import { ItemStoreInterface } from './ItemStore.prototype';
import { ApiModuleType } from './ItemStore.prototype';

export interface ApiModuleTypeList<ItemObjectType extends TItemExtended, ItemListType, ItemListFilter>
    extends ApiModuleType<ItemObjectType> {
    fetchList: (
        limit: number,
        offset: number,
        orderBy: string,
        orderDirection: OrderDirectionType,
        filter: Partial<ItemListFilter>,
        controller?: AbortController,
        withoutCount?: boolean
    ) => Promise<{ list: Array<ItemListType>; count: number }>;
}

export type PaginationType = {
    activePage: number;
    totalPages: number;
    pageSize: number;
};

export type DropdownType = {
    key?: number | string;
    value: number | string | boolean | null;
    text: string;
};

export type OrderDirectionType = 'ascending' | 'descending';

export interface ListStoreInterface extends ItemStoreInterface<any> {
    changeItemDropdownSearch(query: string): void;
    loadingDropdownOptions: boolean;
    itemDropdownOptions: Array<DropdownType>;
    item_id: unknown;
    changeFilter(what: unknown, value: unknown): void;
    setRouteFilter(what: string): void;
    fetchList: (orderBy?: string, orderDirection?: OrderDirectionType) => Promise<void>;
    clearFilter(): void;
    debounceFilterFetch(): void;
    listFilter: any;
    listCount: number;
    listErrors: string[];
    loadingList: boolean;
    readonly filterIsEmpty: boolean;
    startLoading(): void;
    orderBy: string;
    orderDirection: OrderDirectionType;
}

abstract class ListStorePrototype<
        ItemObjectType extends TItemExtended,
        ItemListType = ItemObjectType,
        ItemPropertyType = {},
        ItemListFilter = {}
    >
    extends ItemStorePrototype<ItemObjectType, ItemPropertyType>
    implements ListStoreInterface
{
    @observable
    list: ItemListType[] = [];

    @observable
    listCount = 0;
    @observable
    loadingList = false;

    @observable
    listFilter: Partial<ItemListFilter> = {};

    abstract listFilterClear: ItemListFilter;

    orderBy = '';
    orderDirection: OrderDirectionType = 'descending';

    ApiModuleList: ApiModuleTypeList<ItemObjectType, ItemListType, ItemListFilter>;

    @observable
    pagination: PaginationType = getDefaultPagination();

    constructor(
        item_id: keyof ItemObjectType,
        moduleName: string,
        ApiModule: ApiModuleTypeList<ItemObjectType, ItemListType, ItemListFilter>
    ) {
        super(item_id, moduleName, ApiModule);
        makeObservable(this);

        this.ApiModuleList = ApiModule;
        this.orderBy = this.orderBy || String(item_id);
    }

    @action
    resetPagination() {
        this.pagination.activePage = 1;
        this.pagination.totalPages = 1;
    }

    @action
    clearFilter() {
        this.resetPagination();
        this.filterHasChanged = null;
        this.selectedItemsIds = [];
        this.listFilter = deepCopy(this.listFilterClear);
    }

    @action
    setRouteFilter(param: string) {}

    findById(item_id: number): ItemListType {
        // @ts-ignore
        const foundItem = this.list.find(item => item[this.item_id] === item_id);
        if (!foundItem) throw Error('findById Error');
        return foundItem;
    }

    @observable
    listErrors: string[] = [];

    matchFilterField(): Partial<ItemListFilter> {
        const filterFields = {};
        for (const key in this.listFilter) {
            if (this.listFilter[key] !== null) {
                // @ts-ignore
                filterFields[key] = this.listFilter[key];
            }
        }
        return filterFields;
    }

    fetchListAC: AbortController | null = null;

    @action
    matchPagination() {
        this.pagination = matchPaginationTotalPages(this.pagination, this.listCount);
    }

    @action
    async loadList(): Promise<void> {
        const filterFields = this.matchFilterField();

        const { activePage, pageSize } = this.pagination;
        const offset = (activePage - 1) * pageSize;

        let list: ItemListType[] = [];
        let count = 0;

        try {
            if (this.fetchListAC) {
                this.fetchListAC.abort();
            }
            const controller = window.AbortController ? new window.AbortController() : undefined;
            // присваеваем через таймаут, чтобы в catch прошлый вызов (отмененный) перехватить
            if (controller) {
                window.setTimeout(() => {
                    this.fetchListAC = controller;
                }, 0);
            }

            const request = await this.ApiModuleList.fetchList(
                pageSize,
                offset,
                this.orderBy,
                this.orderDirection,
                filterFields,
                controller,
                this.filterHasChanged === false
            );

            this.fetchListAC = null;
            list = request.list;
            count = request.count;
        } catch (errors) {
            if (this.fetchListAC && this.fetchListAC.signal.aborted) {
                return;
            }

            console.error('error', errors);

            if (errors instanceof Array) {
                this.listErrors = errors;
            } else if (errors && typeof errors === 'object') {
                // @ts-ignore
                this.listErrors = [String(errors?.message)];
            }
        }

        this.listCount = count;
        this.list = list;
        this.loadingList = false;
    }

    async fetchList(orderBy?: string, orderDirection?: OrderDirectionType) {
        this.orderBy = orderBy || this.orderBy || String(this.item_id);
        if (orderDirection) {
            this.orderDirection = orderDirection;
        }

        this.loadingList = true;
        this.listErrors = [];

        await this.loadList();

        this.matchPagination();

        if (this.filterHasChanged === null) {
            this.filterHasChanged = false;
        }
    }

    debounceFilterFetch = debounce(() => {
        this.fetchList(this.orderBy, this.orderDirection);
        this.filterHasChanged = false;
    }, 350);

    filterHasChanged: boolean | null = null;

    @action
    changeFilter<T extends keyof ItemListFilter>(what: T, value: ItemListFilter[T]) {
        if (this.listFilter[what] === value) {
            return;
        }
        this.filterHasChanged = true;
        this.listFilter[what] = value;
        this.pagination.activePage = 1;
        this.debounceFilterFetch();
    }

    async saveItem(id: number): Promise<Partial<ItemObjectType> | boolean> {
        const newItem: boolean | Partial<ItemObjectType> = await super.saveItem(id);
        if (newItem && typeof newItem === 'object') {
            this.mergeList(id, newItem);
        }
        return newItem;
    }

    @action
    mergeList(id: number, item: Partial<ItemObjectType>) {
        // @ts-ignore
        const foundIndex = this.list.findIndex(obj => parseInt(obj[this.item_id]) === id);
        if (~foundIndex) {
            this.list[foundIndex] = { ...this.list[foundIndex], ...item };
            this.list = [...this.list];
        }
    }

    @action
    pageChange = (pageNumber: number) => {
        this.pagination = { ...this.pagination, activePage: pageNumber };
        this.fetchList();
    };

    @action
    pageSizeChange = (pageSize: number) => {
        this.pagination = { pageSize: pageSize, activePage: 1, totalPages: 1 };
        this.fetchList();
    };

    async createItem(): Promise<number> {
        const item_id = await super.createItem();
        if (item_id) {
            this.fetchList();
        }
        return item_id;
    }

    @observable
    loadingDropdownOptions = false;
    itemDropdownOptions = [];
    searchQuery: string = '';

    dropdownList: Array<Partial<ItemListType>> = [];

    @action
    async fetchItemDropdownOptions(search: string) {}

    debounceItemDropdownOptions = debounce(() => {
        this.fetchItemDropdownOptions(this.searchQuery);
    }, 350);

    findFromList = (item_id: number): Partial<ItemListType> => {
        // @ts-ignore
        const item = this.dropdownList.find(item => item[this.item_id] === item_id);
        if (!item) {
            throw new Error('Error');
        }
        return item;
    };

    changeItemDropdownSearch(searchQuery: string) {
        this.searchQuery = searchQuery;
        this.debounceItemDropdownOptions();
    }

    @observable
    selectedItemsIds: number[] = [];

    @action
    startLoading() {
        this.loadingList = true;
    }

    @computed get filterIsEmpty(): boolean {
        return isEqual(this.listFilter, this.listFilterClear);
    }

    async toggleDisableItem(id: number, enable: boolean) {
        await super.toggleDisableItem(id, enable);
        this.fetchList();
    }

    @action
    handleSort = (clickedColumn: string) => {
        let { orderBy, orderDirection } = this;

        if (orderBy !== clickedColumn) {
            orderBy = clickedColumn;
            orderDirection = 'ascending';

            this.orderBy = orderBy;
            this.orderDirection = orderDirection;
        } else {
            orderDirection = orderDirection === 'ascending' ? 'descending' : 'ascending';
            this.orderDirection = orderDirection;
        }

        this.fetchList(orderBy, orderDirection);
    };

    adjustDirection = (orderField: keyof ItemObjectType | string): 'asc' | 'desc' | false => {
        if (this.orderBy === orderField) {
            return this.orderDirection === 'descending' ? 'desc' : 'asc';
        }
        return false;
    };
}

export default ListStorePrototype;
