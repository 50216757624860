import React from 'react';
import { Link } from 'react-router-dom';

export const ReviewLinkState = (review_id: number) => ({
    pathname: `/reviews/list/detail/${review_id}`,
    state: { modal: true }
});

const ReviewLink = ({ review_id }: { review_id: number }) => (
    <Link to={ReviewLinkState(review_id)}>Отзыв №{review_id}</Link>
);

export default ReviewLink;
