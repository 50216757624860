import React from 'react';

import { ClosestPointAccount } from 'api/deliveryPointsApi';
import { DeliveryPointAddress } from 'stores/ransomStore';

import Modal, { ModalBtnAction, ModalContent } from 'ui/Modal';
import { InfoMessage, WarningMessage } from 'ui/Message';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import RansomChooseAccountBtn from './RansomChooseAccountBtn';

const RansomChooseAccountsModal = ({
    closestPointAccounts,
    onClose,
    foundCurrentStore,
    current_store_point_id,
    onChangeStorePoint
}: {
    closestPointAccounts: ClosestPointAccount[];
    onClose: () => void;
    foundCurrentStore: boolean;
    current_store_point_id: number;
    onChangeStorePoint: (storeDeliveryPoint: DeliveryPointAddress) => void;
}) => {
    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'inherit', disabled: false }
    ];

    const handleChangeStorePoint = (storeDeliveryPoint: DeliveryPointAddress) => {
        onChangeStorePoint(storeDeliveryPoint);
        onClose();
    };

    return (
        <Modal
            maxWidth="md"
            header="Выбрать подходящий аккаунт и адрес"
            disableCloseBtn
            onClose={onClose}
            actions={actions}
        >
            {foundCurrentStore && (
                <InfoMessage header="Подходящий аккаунт найден">
                    Для вашего выкупа будет предоставлен аккаунт, у которого уже были выкупы с данного ПВЗ
                </InfoMessage>
            )}
            {!foundCurrentStore && (
                <WarningMessage header="Можете поменять ПВЗ">
                    В нашем сервисе еще не было выкупов на данный ПВЗ, но вы можете выбрать ближайший ПВЗ, откуда уже
                    были осуществлены выкупы из данного списка
                </WarningMessage>
            )}
            <ModalContent>
                <List dense disablePadding>
                    {closestPointAccounts.map(closestPointAccount => (
                        <ListItem key={closestPointAccount.account_id}>
                            <RansomChooseAccountBtn
                                closestPointAccount={closestPointAccount}
                                onChangeStorePoint={handleChangeStorePoint}
                                current_store_point_id={current_store_point_id}
                            />
                        </ListItem>
                    ))}
                </List>
            </ModalContent>
        </Modal>
    );
};

export default RansomChooseAccountsModal;
