import React from 'react';
import { styled } from '@mui/material/styles';

import Grid from 'ui/Grid';
import { WarningMessage } from 'ui/Message';
import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import Backdrop from '@mui/material/Backdrop';
import Portal from '@mui/material/Portal';
import Box from '@mui/material/Box';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Stack from '@mui/material/Stack';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.2
        },
        '& .MuiImageMarked-root': {
            opacity: 0
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor'
        }
    }
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%'
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.1,
    transition: theme.transitions.create('opacity')
}));

export const PhotoThumbnail = ({ srcBig, width = 300 }: { srcBig: string; width?: string | number }) => {
    const [show, toggleShow] = useToggleModal();

    return (
        <>
            <ImageButton
                focusRipple
                sx={{
                    width,
                    height: 200
                }}
                onClick={toggleShow}
            >
                <ImageSrc style={{ backgroundImage: `url('${srcBig}')` }} />
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                    <ZoomInIcon fontSize="large" />
                </Image>
            </ImageButton>
            {show && (
                <Portal container={document.body}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
                        open={true}
                        onClick={toggleShow}
                    >
                        <Box
                            sx={{
                                width: '90%',
                                height: '90%',
                                backgroundImage: `url('${srcBig}')`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                            }}
                        />
                    </Backdrop>
                </Portal>
            )}
        </>
    );
};

const SupplierIdSelfInstruction = () => {
    return (
        <Grid columns={2} stackable alignItems="flex-start">
            <Grid.Column>
                <Stack spacing={1}>
                    <Typography variant="subtitle2">Шаг 1.</Typography>
                    <Typography variant="body2">
                        Откройте&nbsp;
                        <a href="https://seller.wildberries.ru/" target="_blank" rel="noreferrer">
                            кабинет селлера ВБ
                        </a>
                        &nbsp; в браузере Google Chrome, Яндекс.Браузер, Opera, Microsoft Edge на компьютере
                    </Typography>
                </Stack>
            </Grid.Column>
            <Grid.Column>
                <PhotoThumbnail srcBig="/static/wb-seller/seller1.png" />
            </Grid.Column>

            <Grid.Column>
                <Stack spacing={1}>
                    <Typography variant="subtitle2">Шаг 2.</Typography>
                    <Typography variant="body2">
                        Нажмите на клавиатуре F12 или <span style={{ whiteSpace: 'nowrap' }}>Ctrl+Shift+I</span>.
                        Откроется панель для разработчиков.
                    </Typography>
                    <Typography variant="body2">
                        Растяните открывшуюся панель, так чтобы вы могли видеть данные, как на картинке.
                    </Typography>
                    <Typography variant="body2">
                        Выберите вкладку "Приложение" (Application в английской версии), см. картинку
                    </Typography>
                </Stack>
            </Grid.Column>
            <Grid.Column>
                <PhotoThumbnail srcBig="/static/wb-seller/seller2.png" />
            </Grid.Column>

            <Grid.Column>
                <Stack spacing={1}>
                    <Typography variant="subtitle2">Шаг 3.</Typography>
                    <Typography variant="body2">
                        Во вкладке "Приложение" в левом столбце нажмите по "Файлы cookie", перейдите по строке из
                        выпадающего списка (см. картинку), откроется таблица.
                    </Typography>
                    <Typography variant="body2">
                        В таблице выберите Название "x-supplier-id", нажмите на него. Это и есть ваш SupplierId.
                        Скопируйте его значение внизу таблицы в поле "Cookie Value" (как на картинке).
                    </Typography>
                </Stack>
            </Grid.Column>
            <Grid.Column>
                <PhotoThumbnail srcBig="/static/wb-seller/seller3.png" />
            </Grid.Column>
        </Grid>
    );
};

const SupplierIdInstruction = () => {
    return (
        <WarningMessage header="Как узнать ваш supplierId?">
            Чтобы подключить "автоответы" на отзывы, нам необходим ваш supplierId на WB. Если вы его не знаете, то вот
            два способа, как его можно узнать:
            <Accordion sx={{ my: 1 }}>
                <AccordionSummary
                    sx={{ background: '#fffbf5' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Как получить SupplierId самостоятельно? (быстрый способ)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <SupplierIdSelfInstruction />
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ my: 1 }}>
                <AccordionSummary
                    sx={{ background: '#fffbf5' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>Через техподдержку ВБ</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        Напишите в&nbsp;
                        <a
                            href="https://seller.wildberries.ru/service-desk-v2/requests/history"
                            target="_blank"
                            rel="noreferrer"
                        >
                            поддержку
                        </a>
                        &nbsp;в кабинете селлера на WB: "Сообщите, пожалуйста, мой supplierId".
                    </Typography>
                </AccordionDetails>
            </Accordion>
            Если у вас возникли вопросы по "автоответам", пишите нам&nbsp;
            <Button target="_blank" href="https://t.me/TopStoreAdm" tooltip="Откроется в Telegram">
                @TopStoreAdm
            </Button>
        </WarningMessage>
    );
};

export default SupplierIdInstruction;
