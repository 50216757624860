import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import locationHistory from 'locationHistory';

import questionStore from 'stores/questionStore';
import { QuestionLinkState } from 'components/Items/Question/QuestionLink';

import Table, { TableHead, TableRow, TableCell, TableBody } from 'ui/Table';
import Time from 'ui/Time';
import Button from 'ui/Button';
import { WarningMessage } from 'ui/Message';
import CutString from 'ui/CutString';

import Chip from '@mui/material/Chip';

import ListWrapper from '../ListWrapper';
import ListPagination from '../ListPagination';
import ProductTitleLink from '../Products/ProductTitleLink';
import QuestionsFilter from './QuestionsFilter';

const QuestionsList = () => {
    const { list, loadingList, handleSort, adjustDirection } = questionStore;

    return (
        <ListWrapper store={questionStore} bgUrl="/static/listbg/unruly-19-security.webp">
            <QuestionsFilter />

            <Table elevation={0} loading={loadingList}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sortDirection={adjustDirection('createTime')}
                            onClick={() => handleSort('createTime')}
                        >
                            Дата
                        </TableCell>
                        <TableCell sortDirection={adjustDirection('status')} onClick={() => handleSort('status')}>
                            Статус
                        </TableCell>
                        <TableCell>Вопрос</TableCell>
                        <TableCell
                            sortDirection={adjustDirection('product_id')}
                            onClick={() => handleSort('product_id')}
                            sx={{ minWidth: '250px' }}
                        >
                            Наименование
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(({ question_id, questionText, product, createTime, status }) => (
                        <TableRow key={question_id}>
                            <TableCell>
                                <Time time={createTime} />
                            </TableCell>
                            <TableCell>
                                <Button
                                    onClick={() => locationHistory.push(QuestionLinkState(question_id))}
                                    tooltip="Открыть карточку вопроса"
                                >
                                    {status === 1 && (
                                        <Chip label="Отправка" color="warning" sx={{ cursor: 'pointer' }} />
                                    )}
                                    {status === 2 && (
                                        <Chip label="Отправлен" color="success" sx={{ cursor: 'pointer' }} />
                                    )}
                                    {status === 100 && <Chip label="Ошибка" color="error" sx={{ cursor: 'pointer' }} />}
                                </Button>
                            </TableCell>
                            <TableCell>
                                <CutString title={questionText} maxLength={30} />
                            </TableCell>
                            <TableCell>
                                <ProductTitleLink product={product} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <ListPagination
                    pagination={questionStore.pagination}
                    listCount={questionStore.listCount}
                    pageChange={questionStore.pageChange}
                    pageSizeChange={questionStore.pageSizeChange}
                    colSpan={3}
                />
            </Table>

            {!loadingList && list.length === 0 && (
                <WarningMessage header="Вы еще не задавали ни один вопрос">
                    Вы можете добавить вопрос к любому вашему <Link to="/products/list">товару</Link> в карточке товара.
                    После чего он появится тут.
                </WarningMessage>
            )}
        </ListWrapper>
    );
};

export default observer(QuestionsList);
