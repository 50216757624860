import React from 'react';

import commonStore from 'stores/commonStore';
import { FeedbacksOrderBy } from 'api/productApi';

import Box from '@mui/material/Box';
// import ButtonGroup from '@mui/material/ButtonGroup';
// import CachedIcon from '@mui/icons-material/Cached';
// import CloseIcon from '@mui/icons-material/Close';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Button from 'ui/Button';
import LoaderAwait from 'ui/LoaderAwait';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { OrderDirectionType } from '../../../../stores/prototypes/ListStore.prototype';

const ProductFeedbacksPult = ({
    orderBy,
    orderDirection,
    handleSort,
    disabled
}: {
    orderBy: FeedbacksOrderBy;
    orderDirection: OrderDirectionType;
    handleSort: (newOrderBy: FeedbacksOrderBy) => void;
    disabled?: boolean;
}) => {
    // const handleRefresh = () => {
    //     // чтобы LoaderAwait отобразился
    //     // store.startLoading();
    //     // setTimeout(store.fetchList.bind(store), 2 * AWAIT_LOADER_SHOW_TIME_MS);
    // };

    // const { orderBy = 'createTime', orderDirection = 'descending' } = productStore.getItem(
    //     product_id
    // ).property;

    // const handleSort = (newOrderBy: FeedbacksOrderBy) => {
    //     if (newOrderBy === orderBy) {
    //         productStore.fetchProductWbFeedbacks(
    //             product_id,
    //             orderBy,
    //             orderDirection === 'ascending' ? 'descending' : 'ascending'
    //         );
    //     } else {
    //         productStore.fetchProductWbFeedbacks(product_id, newOrderBy, orderDirection);
    //     }
    // };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                    px: commonStore.isMobile ? 2 : undefined,
                    pt: commonStore.isMobile ? 1 : undefined
                }}
            >
                {/*<ButtonGroup size="small" color="primary" variant="contained" disableElevation>*/}
                {/*    <Button loading={loadingFeedbacks} tooltip="Обновить" onClick={handleRefresh}>*/}
                {/*        <CachedIcon />*/}
                {/*    </Button>*/}
                {/*</ButtonGroup>*/}

                <Box sx={{ flex: 1, gap: 3, display: 'flex', alignItems: 'center' }}>
                    <Button
                        variant="text"
                        tooltip="Сортировать по Дате"
                        color={orderBy === 'createTime' ? 'secondary' : 'inherit'}
                        endIcon={
                            orderBy === 'createTime' ? (
                                orderDirection === 'descending' ? (
                                    <ArrowDownwardIcon />
                                ) : (
                                    <ArrowUpwardIcon />
                                )
                            ) : undefined
                        }
                        onClick={() => handleSort('createTime')}
                        disabled={disabled}
                    >
                        Дате
                    </Button>
                    <Button
                        variant="text"
                        tooltip="Сортировать по Оценке"
                        color={orderBy === 'star' ? 'secondary' : 'inherit'}
                        endIcon={
                            orderBy === 'star' ? (
                                orderDirection === 'descending' ? (
                                    <ArrowDownwardIcon />
                                ) : (
                                    <ArrowUpwardIcon />
                                )
                            ) : undefined
                        }
                        onClick={() => handleSort('star')}
                        disabled={disabled}
                    >
                        Оценке
                    </Button>
                    <Button
                        variant="text"
                        tooltip="Сортировать по Полезности"
                        color={orderBy === 'rank' ? 'secondary' : 'inherit'}
                        endIcon={
                            orderBy === 'rank' ? (
                                orderDirection === 'descending' ? (
                                    <ArrowDownwardIcon />
                                ) : (
                                    <ArrowUpwardIcon />
                                )
                            ) : undefined
                        }
                        onClick={() => handleSort('rank')}
                        disabled={disabled}
                    >
                        Полезности
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default ProductFeedbacksPult;
