import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';

import productStore, { StoreIds } from 'stores/productStore';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const WbToggleBtn = styled(ToggleButton)({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#cb11ab'
    }
});

const OzonToggleBtn = styled(ToggleButton)({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#005bff'
    }
});

const StoresToggle = () => {
    const handleChangeStoreId = (event: SyntheticEvent, store_id: StoreIds) => {
        productStore.changeFilter('store_id', store_id);
    };

    return (
        <ToggleButtonGroup
            size="small"
            value={productStore.getCurrentStoreId()}
            exclusive
            onChange={handleChangeStoreId}
            fullWidth
        >
            <WbToggleBtn value={StoreIds.WB}>WB</WbToggleBtn>
            <OzonToggleBtn value={StoreIds.Ozon}>OZON</OzonToggleBtn>
        </ToggleButtonGroup>
    );
};

export default observer(StoresToggle);
