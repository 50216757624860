import React from 'react';

import { Ransom } from 'stores/ransomStore';

import Price from 'components/Price';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const RansomOtherItemsList = ({ list }: { list: Ransom['rivalItems'] }) => {
    return (
        <>
            {list.map(({ store_item_id, store_id, title, price, photoUrl }) => {
                return (
                    <ListItem divider key={store_item_id}>
                        <ListItemAvatar>
                            <Avatar sx={{ width: `48px`, height: `48px` }} src={photoUrl} variant="rounded" />
                        </ListItemAvatar>
                        <ListItemText
                            primary={title}
                            secondary={
                                <Stack direction="row" spacing={1} justifyContent="space-between">
                                    <Box sx={{ flex: 1 }}>Артикул: {store_item_id}</Box>
                                    {price && <Price {...price} />}
                                </Stack>
                            }
                        />
                    </ListItem>
                );
            })}
        </>
    );
};

export default RansomOtherItemsList;
