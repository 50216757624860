import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import ItemDrawWrapper, { MenuTab } from '../ItemDrawWrapper';

import productStore, { whetherProductIsWB } from 'stores/productStore';
import commonStore from 'stores/commonStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import authStore from 'stores/authStore';
import deliveryStore from 'stores/deliveryStore';

import ListError from 'ui/ListError';
import LoaderAwait from 'ui/LoaderAwait';
import Button from 'ui/Button';
import Time from 'ui/Time';
import { ErrorMessage, WarningMessage } from 'ui/Message';

import CachedIcon from '@mui/icons-material/Cached';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

import ProductShowMode from './ProductShowMode';
import ProductSearchAnalyseMode from './ProductSearchAnalyseMode';
import ProductStatisticsMode from './ProductStatisticsMode';
import ProductFeedbacksMode from './ProductFeedbacksMode';
import ProductItemMenu from './ProductItemMenu';

const MIN_UPDATE_TIME_SEC = 3600;

const TabUnderConstruction = () => (
    <WarningMessage header="Ведутся техничесские работы">Данный раздел временно недоступен</WarningMessage>
);

const Product = () => {
    const { item_id: item_id_prepare, tabName }: { item_id?: string; tabName?: string } = useParams();
    const product_id = Number(item_id_prepare) || CREATING_ITEM_ID;

    useMemo(() => {
        productStore.fetchItem(product_id).then(product => {
            if (product) {
                const { store_id } = product;
                if (store_id && store_id !== productStore.getCurrentStoreId()) {
                    productStore.changeFilter('store_id', store_id);
                    deliveryStore.changeFilter('store_id', store_id);
                }
            }
        });
    }, [product_id]);

    const { loadingItem, errors, item } = productStore.getItem(product_id);

    if (!item && errors?.length > 0) {
        return <ListError errors={errors} />;
    }

    if (!item || loadingItem) {
        return (
            <div style={{ position: 'relative', height: '100vh' }}>
                <LoaderAwait active />
            </div>
        );
    }

    const refreshStoreTime = () => {
        productStore.refreshUpdateStoreProduct(product_id);
    };

    const { enable } = item;
    const { updateTime } = productStore.calcShortProduct(item);
    const currentTime = Math.floor(Date.now() / 1000);

    const RefreshBtn = (
        <Box sx={{ whiteSpace: 'nowrap' }}>
            {!commonStore.isMobile && (
                <>
                    <Tooltip title="Время обновления информации с сайта маркетплейса">
                        <span>
                            <Time time={updateTime} />
                        </span>
                    </Tooltip>
                    {(authStore.isAdmin() || currentTime - updateTime > MIN_UPDATE_TIME_SEC) && (
                        <Button
                            onClick={refreshStoreTime}
                            icon={<CachedIcon fontSize="large" />}
                            tooltip="Обновить карточку с сайта маркетплейса"
                        />
                    )}
                </>
            )}
            <ProductItemMenu product_id={product_id} />
        </Box>
    );

    const tabs: MenuTab[] = [{ id: 'main', label: 'Ваш товар', pathname: `/products/list/detail/${product_id}` }];

    if (whetherProductIsWB(item)) {
        tabs.push(
            { id: 'search', label: 'Поисковый Анализ', pathname: `/products/list/detail/${product_id}/search` },
            { id: 'feedbacks', label: 'Отзывы', pathname: `/products/list/detail/${product_id}/feedbacks` },
            { id: 'statistics', label: 'Статистика', pathname: `/products/list/detail/${product_id}/statistics` }
        );
    } else {
        tabs.push({
            id: 'statistics',
            label: 'Статистика',
            pathname: `/products/list/detail/${product_id}/statistics`
        });
    }

    return (
        <ItemDrawWrapper tabs={tabs} activeTab={tabName || 'main'} endTab={RefreshBtn}>
            {!enable && <ErrorMessage header="Продукт в архиве" />}
            {(!tabName || tabName === 'main') && <ProductShowMode product_id={product_id} />}
            {tabName === 'search' && <ProductSearchAnalyseMode product_id={product_id} />}
            {/*{tabName === 'search' && <TabUnderConstruction />}*/}
            {tabName === 'statistics' && <ProductStatisticsMode product_id={product_id} />}
            {/*{tabName === 'statistics' && <TabUnderConstruction />}*/}
            {tabName === 'feedbacks' && <ProductFeedbacksMode product_id={product_id} feedbacksType="main" />}
        </ItemDrawWrapper>
    );
};

export default observer(Product);
