import React from 'react';
import { observer } from 'mobx-react';

import matchWbPhotoUrl from 'common/matchWbPhotoUrl';

import ransomStore from 'stores/ransomStore';
import { DropdownType, StoreIds } from 'stores/productStore';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import AddIcon from '@mui/icons-material/Add';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';

import RansomOtherItem from './RansomOtherlItem';
import RansomOtherItemAdd from './RansomOtherlItemAdd';
import Paper from '@mui/material/Paper';

const MAX_ADDITIONAL_ITEMS = 2;

const RansomAdditionalEditingBlock = ({ ransom_id }: { ransom_id: number }) => {
    const [showAddBtn, toggleAddBtn] = useToggleModal();

    const { additionalItems = [] } = ransomStore.getItem(ransom_id).property;
    const { additional_store_item_ids = [] } = ransomStore.getItem(ransom_id).editingItem;

    const handleAddRival = (storeItem: DropdownType) => {
        ransomStore.addAdditionalItem(ransom_id, storeItem);
        toggleAddBtn();
    };

    const handleRemoveAdditional = (index: number) => {
        const additionalItemsNew = [...additionalItems];
        additionalItemsNew.splice(index, 1);

        ransomStore.setProperty(ransom_id, { additionalItems: additionalItemsNew });
        ransomStore.setEditingItem(ransom_id, {
            additional_store_item_ids: additionalItemsNew.map(({ wb_item_id }) => wb_item_id)
        });
    };

    const enableAdd = additional_store_item_ids.length < MAX_ADDITIONAL_ITEMS;

    return (
        <Paper elevation={2}>
            <List>
                {additionalItems.map(({ wb_item_id, title }, index) => (
                    <RansomOtherItem
                        key={Number(wb_item_id)}
                        photoUrl={matchWbPhotoUrl(wb_item_id).preview}
                        title={title}
                        store_item_id={Number(wb_item_id)}
                        onDeleteItem={() => handleRemoveAdditional(index)}
                    />
                ))}
                {enableAdd && additionalItems.length > 0 && showAddBtn && (
                    <ListItem>
                        <Button
                            size="large"
                            startIcon={<LocalGroceryStoreIcon />}
                            endIcon={<AddIcon />}
                            color="secondary"
                            fullWidth
                            onClick={toggleAddBtn}
                            tooltip={`Добавить еще один товар в выкуп (до ${MAX_ADDITIONAL_ITEMS} штук)`}
                        >
                            Добавить еще товар
                        </Button>
                    </ListItem>
                )}
                {enableAdd && (additionalItems.length === 0 || !showAddBtn) && (
                    <RansomOtherItemAdd onAddItem={handleAddRival} store_id={StoreIds.WB} />
                )}
            </List>
        </Paper>
    );
};

export default observer(RansomAdditionalEditingBlock);
