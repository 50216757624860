import React from 'react';
import { observer } from 'mobx-react';
import Countdown from 'react-countdown';
import { addZero } from 'common/time';

import ransomStore from 'stores/ransomStore';
import commonStore from 'stores/commonStore';
import videoStore from 'stores/videoStore';
import { StoreIds } from 'stores/productStore';

import Grid from 'ui/Grid';
import Button from 'ui/Button';
import { ErrorMessage, InfoMessage, WarningMessage } from 'ui/Message';
import Confirm from 'ui/Confirm';
import { useToggleModal } from 'ui/Modal';

import Box from '@mui/material/Box';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Stack from '@mui/material/Stack';

import CreditCardIcon from '@mui/icons-material/CreditCard';

const sizeQrPx = 226;
// const sizePxOzon = 340;

const Completionist = React.memo(() => (
    <ErrorMessage header="Ссылка на оплату просрочна">Выкуп будет удален</ErrorMessage>
));

const renderer =
    (paymentUrl: string) =>
    ({ minutes, seconds, completed }: { minutes: number; seconds: number; completed: boolean }) => {
        if (completed) {
            return <Completionist />;
        } else {
            return (
                <Button
                    color="secondary"
                    variant="contained"
                    href={paymentUrl}
                    target="_blank"
                    startIcon={<CreditCardIcon />}
                    fullWidth
                >
                    Оплатить ({addZero(minutes)}:{addZero(seconds)})
                </Button>
            );
        }
    };

const RansomCountdown = ({ paymentUrl }: { paymentUrl: string }) => {
    const expiresAt = paymentUrl.match(/\/expires_at\/(\d+)\//);
    return <Countdown date={expiresAt ? 1000 * Number(expiresAt[1]) : 0} renderer={renderer(paymentUrl)} />;
};

const RansomPaymentShow = ({ ransom_id }: { ransom_id: number }) => {
    const [showConfirm, toggleConfirm] = useToggleModal();

    const { loadingItem, item } = ransomStore.getItem(ransom_id);
    const { paymentUrl, paymentQR, paymentType, payPhone, store_id } = item || {
        enable: true
    };

    const handleUpdateRansomQR = async () => {
        ransomStore.setEditingItem(ransom_id, { status: 1 });
        await ransomStore.saveItem(ransom_id);
        ransomStore.fetchItem(ransom_id);
        videoStore.findWbVideo('ransom', ransom_id);
    };

    const handleAlreadyPay = async () => {
        ransomStore.setEditingItem(ransom_id, { status: 4 });
        await ransomStore.saveItem(ransom_id);
    };

    // const sizePx = store_id === StoreIds.Ozon ? sizePxOzon : sizePxWB;

    return (
        <>
            <Grid.Column>
                {paymentType === 1 && (
                    <>
                        <InfoMessage header="QR-код на оплату">
                            Откройте приложение банка и выберите оплату по QR-коду. Или просто наведите камеру на него,
                            тогда откроется приложение вашего банка по-умолчанию. После оплаты нажмите на кнопку "Я
                            ОПЛАТИЛ(А)"
                        </InfoMessage>

                        <Button
                            endIcon={<AccountBalanceWalletIcon />}
                            color="secondary"
                            variant="text"
                            fullWidth
                            disabled={loadingItem}
                            loading={loadingItem}
                            onClick={toggleConfirm}
                        >
                            Я оплатил(а)
                        </Button>
                        {showConfirm && (
                            <Confirm
                                content="Вы оплатили данный товар через СБП (QR код)?"
                                header="Подтверждение оплаты"
                                confirmButton="Оплатил(А)"
                                onConfirm={handleAlreadyPay}
                                onCancel={toggleConfirm}
                            />
                        )}
                        <Box
                            sx={{
                                background: `url('${paymentQR}')`,
                                backgroundRepeat: 'no-repeat',
                                width: sizeQrPx,
                                minWidth: sizeQrPx,
                                maxWidth: sizeQrPx,
                                height: sizeQrPx,
                                minHeight: sizeQrPx,
                                maxHeight: sizeQrPx,
                                margin: '1rem auto'
                            }}
                        />
                        {paymentUrl && commonStore.isMobile && (
                            <Button
                                href={paymentUrl}
                                target="_blank"
                                endIcon={<AccountBalanceIcon />}
                                color="secondary"
                                variant="outlined"
                                fullWidth
                            >
                                Выбрать Банк и оплатить
                            </Button>
                        )}
                    </>
                )}
                {paymentType === 2 && (
                    <>
                        <InfoMessage header="SberPay">
                            Оплатите счет, поступивший в ваш Сбербанк Онлайн ({payPhone})
                        </InfoMessage>
                    </>
                )}
                {paymentType === 3 && paymentUrl && (
                    <InfoMessage header="Ссылка на оплату картой">
                        <Stack gap={2}>
                            <div>
                                Совершите оплату покупки на стороне Wildberries. Нажав на кнопку "Оплатить", откроется
                                страница оплаты. Ссылка действительна 60 минут.
                            </div>
                            <RansomCountdown paymentUrl={paymentUrl} />
                            <div>После оплаты нажмите на кнопку "Я ОПЛАТИЛ(А)"</div>
                            <div>
                                <b>Внимание!</b> Обновить ссылку оплаты невозможно. Если вы не оплатите товар по ссылке,
                                то отменить выкуп будет уже нельзя.
                            </div>
                        </Stack>
                    </InfoMessage>
                )}
            </Grid.Column>
            {paymentType === 1 && (
                <Grid.Column>
                    <WarningMessage header="Внимание!">
                        QR на оплату действителен в течение 15 минут. Если вы за это время не успели совершить оплату,
                        то его нужно обновить.
                    </WarningMessage>
                </Grid.Column>
            )}
            {paymentType === 1 && (
                <Grid.Column sm={12} md={6}>
                    {/*<Button*/}
                    {/*    endIcon={paymentType === 3 ? <CreditCardIcon /> : paymentType === 1 ? <QrCodeIcon /> : null}*/}
                    {/*    color="inherit"*/}
                    {/*    fullWidth*/}
                    {/*    onClick={handleUpdateRansomQR}*/}
                    {/*    disabled={loadingItem}*/}
                    {/*>*/}
                    {/*    {paymentType === 1 && 'Обновить QR-код на оплату'}*/}
                    {/*    {paymentType === 2 && 'Обновить счет в SberPay'}*/}
                    {/*    {paymentType === 3 && 'Обновить ссылку'}*/}
                    {/*</Button>*/}
                    <Button
                        endIcon={<QrCodeIcon />}
                        color="inherit"
                        fullWidth
                        onClick={handleUpdateRansomQR}
                        disabled={loadingItem}
                    >
                        Обновить QR-код на оплату
                    </Button>
                </Grid.Column>
            )}
            <Grid.Column sm={12} md={paymentType === 1 ? 6 : 12}>
                <Button
                    endIcon={<AccountBalanceWalletIcon />}
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={loadingItem}
                    loading={loadingItem}
                    onClick={handleAlreadyPay}
                >
                    Я оплатил(а)
                </Button>
            </Grid.Column>
        </>
    );
};

export default observer(RansomPaymentShow);
