import React from 'react';
import { Link } from 'react-router-dom';
import locationHistory from 'locationHistory';

import Button from 'ui/Button';

export const UserLinkState = (user_id: number) => ({
    pathname: `/users/list/detail/${user_id}`,
    state: { modal: true }
});

const UserLink = ({ user_id }: { user_id: number }) => <Link to={UserLinkState(user_id)}>{user_id}</Link>;

export const UserButton = ({ user_id }: { user_id: number }) => {
    return (
        <Button
            onClick={() => locationHistory.push(UserLinkState(user_id))}
            size="small"
            color="error"
            tooltip="Открыть карточку юзера"
        >
            #user_id_{user_id}
        </Button>
    );
};

export default UserLink;
