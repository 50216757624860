import React, { SyntheticEvent, useContext } from 'react';
import { observer } from 'mobx-react';

import feedbackReactionStore, { ClaimTypes } from 'stores/feedbackReactionStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import Grid from 'ui/Grid';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { FeedbackContext } from '../Product/ProductFeedbacksMode';

const FeedbackReactionModal = () => {
    const feedback_reaction_id = CREATING_ITEM_ID;

    const feedback = useContext(FeedbackContext);

    const { editingItem } = feedbackReactionStore.getItem(feedback_reaction_id);
    const { claim } = editingItem;

    const handelChangeClaim = (event: SyntheticEvent, value: string) => {
        if (value) {
            const claim: ClaimTypes = Number(value);
            feedbackReactionStore.setEditingItem(feedback_reaction_id, { claim });
        }
    };

    return (
        <Grid.Column>
            <FormControl sx={{ m: 3 }} error={false} variant="standard">
                <RadioGroup aria-labelledby="demo-error-radios" name="quiz" value={claim} onChange={handelChangeClaim}>
                    <FormControlLabel
                        value={ClaimTypes.Censored}
                        control={<Radio />}
                        label="Отзыв содержит нецензурную, бранную лексику"
                    />
                    <FormControlLabel value={ClaimTypes.Fake} control={<Radio />} label="Заказной, фиктивный" />
                    <FormControlLabel value={ClaimTypes.Spam} control={<Radio />} label="Спам" />
                    <FormControlLabel
                        value={ClaimTypes.BadPhoto}
                        control={<Radio />}
                        label="Некорректное фото, видео"
                    />
                    <FormControlLabel value={ClaimTypes.NotOfProduct} control={<Radio />} label="Отзыв не о товаре" />
                </RadioGroup>
            </FormControl>
        </Grid.Column>
    );
};

export default observer(FeedbackReactionModal);
