import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import commonStore from 'stores/commonStore';
import productStore, { Product, StoreIds, whetherProductIsWB } from 'stores/productStore';
import ransomStore, { RansomType } from 'stores/ransomStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import { DropdownType } from 'stores/prototypes/ListStore.prototype';
import authStore from 'stores/authStore';

import Grid from 'ui/Grid';
import Button from 'ui/Button';
import { NumberInput } from 'ui/TextInput';
import { useToggleModal } from 'ui/Modal';
import { WarningMessage } from 'ui/Message';

import PaymentIcon from '@mui/icons-material/Payment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { RansomLinkState } from '../RansomLink';
import CostWithDiscount from '../../../CostWithDiscount';

const RansomGroupInfo = React.memo(() => {
    const [showInfoGroup, toggleInfoGroup] = useToggleModal();

    return (
        <WarningMessage header="Информация о данном варианте">
            <Button size="small" color="warning" onClick={toggleInfoGroup}>
                {!showInfoGroup ? 'Показать' : 'Скрыть'}
            </Button>
            {showInfoGroup && (
                <div>
                    <ul style={{ padding: 0 }}>
                        <li>Все выкупы на один ПВЗ</li>
                        <li>Можно сделать от 2 до 10 выкупов за один раз</li>
                        <li>Все выкупы с разных аккаунтов</li>
                        <li>По каждому выкупу надо будет оплатить свой QR-код</li>
                        <li>
                            На данные выкупы мы не рекомендуем писать отзывы. Так же на данный тип выкупа мы не
                            принимаем заявки на отзывы "С оплатой за размещение"
                        </li>
                    </ul>
                    Рекомендуем использовать данный вариант, только если у вас уже активно идут продажи на Wildberries
                    данного товара, и вам необходимо увеличить объем продаж. Если вы только начали продавать данный
                    товар, то рекомендуем использовать Обычный Выкуп.
                </div>
            )}
        </WarningMessage>
    );
});

const RansomCreateButtons = () => {
    const ransom_id = CREATING_ITEM_ID;

    const { loadingItem, editingItem } = ransomStore.getItem(ransom_id);
    const {
        product_id,
        wb_size_id,
        store_point_ids,
        type,
        ransomsCount,
        paymentType,
        rival_item_ids,
        wbFilters,
        ransomSmartItems,
        preRunAccount,
        oneTimeAccount
    } = editingItem;

    let product: Product | null = null;
    if (product_id) {
        const productItem = productStore.getItem(product_id);
        product = productItem?.item || null;
    }

    let sizesOptions: DropdownType[] = [];
    if (product && whetherProductIsWB(product)) {
        sizesOptions = product?.wbItem?.sizes?.map(({ wb_size_id, sizeName, sizeNameRus, isSoldOut }) => ({
            value: wb_size_id,
            text: `${sizeName}${sizeNameRus ? ` (${sizeNameRus})` : ''}`,
            disabled: isSoldOut
        }));
    }

    const handleRansomsCount = (event: SyntheticEvent, { value }: { value: number }) => {
        ransomStore.setEditingItem(ransom_id, { ransomsCount: value });
    };

    const handleCreate = async () => {
        const ransom_id = await ransomStore.createItem();
        if (ransom_id) {
            // commonStore.setGlobalInfoMessage(
            //     'ErrУ wildberries.ru с 28 февраля замечено проведение технических работ. Возможны сбои при создании Выкупов и Доставок. Если возникла ошибка, то нажмите на кнопку "Попробовать еще раз" через несколько минут, если ситуация не изменится, свяжитесь с нами.'
            // );
            locationHistory.replace(RansomLinkState(ransom_id));
        }
    };

    const costOnce = 75;
    const costGroup = 60;
    const costRivalItem = 5;
    const costFilters = 10;
    const costPreRun = 20;
    const costOneTime = 40;

    let totalAdditionalCost = Number(rival_item_ids && rival_item_ids?.length * costRivalItem);
    if (wbFilters?.length) {
        totalAdditionalCost += costFilters;
    }
    if (preRunAccount) {
        totalAdditionalCost += costPreRun;
    }
    if (oneTimeAccount) {
        totalAdditionalCost += costOneTime;
    }

    const enabledPaymentMethod =
        productStore.getCurrentStoreId() === StoreIds.WB
            ? authStore.settings[paymentType === 1 ? 'qr' : paymentType === 2 ? 'sberpay' : 'carts']
            : authStore.settings['qrOzon'];

    const disableCreateBtns =
        !enabledPaymentMethod ||
        !product_id ||
        store_point_ids?.length === 0 ||
        (sizesOptions.length > 0 && !wb_size_id);

    return (
        <>
            {productStore.getCurrentStoreId() === StoreIds.WB && paymentType === 3 && (
                <WarningMessage header="Проблема при оплате картой на стороне WB">
                    <Stack spacing={1}>
                        <Typography variant="body2">
                            На Wildberries временно сбои при оплате банковской картой, ждем решения технической проблемы
                            с их стороны.
                        </Typography>
                        <Typography variant="body2">
                            В данный момент, при выборе метода оплаты картой, товар переходит в доставку сразу же до
                            момента фактической оплаты.
                        </Typography>
                        <Typography variant="body2">
                            Вы должны гарантированно произвести оплату товара по первой подгруженной с Wildberries форме
                            оплаты. Если вы НЕ произведете оплату/на карте не хватит средств/будет сбой со стороны
                            банка/прочее, <b>товар все равно будет отгружен складом Wildberries</b> и перейдет в статус
                            "доставка до ПВЗ", в <b style={{ color: 'red' }}>дальнейшем оплатить его не получится</b>.
                        </Typography>
                        <Typography variant="body2">
                            <b>Отменить выкуп</b> или поменять способ оплаты после того, как выкуп будет создан,{' '}
                            <b>не получится</b>.
                        </Typography>
                        <Typography variant="caption">
                            Если вы не смогли оплатить товар по карте, то можно будет прийти на ПВЗ и отказаться от
                            товара, тогда он будет отправлен на склад. Или дождаться автоматического возврата с ПВЗ.
                        </Typography>
                    </Stack>
                </WarningMessage>
            )}
            {type === RansomType.Group && (
                <>
                    <Grid.Column>
                        <RansomGroupInfo />
                        <Box sx={{ width: 200, margin: '0 auto' }}>
                            <NumberInput
                                value={ransomsCount || 2}
                                onChange={handleRansomsCount}
                                label="Количество выкупов"
                                min={2}
                                step={1}
                                max={10}
                                // extraLabel={<InputAdornment position="end">AAA</InputAdornment>}
                            />
                        </Box>
                    </Grid.Column>
                    <Grid.Column sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCreate}
                            loading={loadingItem}
                            endIcon={<PaymentIcon />}
                            disabled={disableCreateBtns}
                        >
                            Создать {!commonStore.isMobile && 'группу выкупов'} (
                            <CostWithDiscount cost={(costGroup + totalAdditionalCost) * Number(ransomsCount)} />)
                        </Button>
                    </Grid.Column>
                </>
            )}
            {type === RansomType.Once && (
                <Grid.Column sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCreate}
                        loading={loadingItem}
                        endIcon={<PaymentIcon />}
                        disabled={disableCreateBtns}
                    >
                        Создать выкуп{store_point_ids && store_point_ids.length > 1 ? 'ы' : ''}&nbsp; (
                        <CostWithDiscount cost={(store_point_ids?.length || 1) * (costOnce + totalAdditionalCost)} />)
                    </Button>
                </Grid.Column>
            )}
            {type === RansomType.Smart && (
                <Grid.Column sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCreate}
                        loading={loadingItem}
                        endIcon={<PaymentIcon />}
                        disabled={!ransomStore.whetherSmartRansomsCompleted(ransom_id) || !enabledPaymentMethod}
                    >
                        Создать smart выкуп{ransomSmartItems && ransomSmartItems?.length > 1 ? 'ы' : ''}&nbsp; (
                        <CostWithDiscount cost={(ransomSmartItems?.length || 1) * (costOnce + totalAdditionalCost)} />)
                    </Button>
                </Grid.Column>
            )}
        </>
    );
};

export default observer(RansomCreateButtons);
