import React from 'react';
import { observer } from 'mobx-react';
import ransomStore, { RansomType } from 'stores/ransomStore';
import productStore, { StoreIds } from 'stores/productStore';

import Button from 'ui/Button';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import Avatar from '@mui/material/Avatar';

import RansomAddressItemBtn from './RansomAddressItemBtn';

const RansomAddressItem = ({ ransom_id, addressIndex }: { ransom_id: number; addressIndex: number }) => {
    const { deliveryPointsAddresses = [] } = ransomStore.getItem(ransom_id).property;
    const { type, oneTimeAccount } = ransomStore.getItem(ransom_id).editingItem;

    const handleRemoveAddress = () => {
        const { deliveryPointsAddresses = [] } = ransomStore.getItem(ransom_id).property;
        const deliveryPointsAddressesNew = [...deliveryPointsAddresses];
        deliveryPointsAddressesNew.splice(addressIndex, 1);
        ransomStore.setProperty(ransom_id, { deliveryPointsAddresses: deliveryPointsAddressesNew });

        ransomStore.setEditingItem(ransom_id, {
            store_point_ids: deliveryPointsAddressesNew.map(({ store_point_id }) => store_point_id)
        });
    };

    const { address } = deliveryPointsAddresses[addressIndex];

    const enableClosestAccounts =
        productStore.getCurrentStoreId() === StoreIds.WB && type !== RansomType.Group && !oneTimeAccount;

    return (
        <ListItem
            secondaryAction={
                address ? (
                    <Button icon={<CloseIcon />} onClick={handleRemoveAddress} tooltip="Убрать адрес" />
                ) : undefined
            }
        >
            <ListItemAvatar>
                {enableClosestAccounts && <RansomAddressItemBtn ransom_id={ransom_id} addressIndex={addressIndex} />}
                {!enableClosestAccounts && (
                    <Avatar>
                        <HomeIcon />
                    </Avatar>
                )}
            </ListItemAvatar>
            <ListItemText primary={address} secondary="Адрес доставки" />
        </ListItem>
    );
};

export default observer(RansomAddressItem);
