export type PriceDataTable = {
    label: string;
    prices: {
        title: string;
        price: number;
        priceWithSale: number;
    }[];
};

export type DataPrice = {
    price: number;
    priceWithSale: number;
};

export type WarehouseDataTable = {
    warehouseName: string;
    box: DataPrice;
    pallet: DataPrice | null;
};

export type WarehouseDataTableSet = {
    store_id: number;
    title: string;
    whData: WarehouseDataTable[];
};

export const deliveryPriceDataFbo: WarehouseDataTableSet[] = [
    {
        store_id: 1,
        title: 'Доставка до складов Wildberries',
        whData: [
            {
                warehouseName: 'Коледино',
                box: {
                    price: 500,
                    priceWithSale: 350
                },
                pallet: {
                    price: 2900,
                    priceWithSale: 2500
                }
            },
            {
                warehouseName: 'Подольск',
                box: {
                    price: 500,
                    priceWithSale: 350
                },
                pallet: {
                    price: 2900,
                    priceWithSale: 2500
                }
            },
            {
                warehouseName: 'Электросталь',
                box: {
                    price: 800,
                    priceWithSale: 550
                },
                pallet: {
                    price: 4500,
                    priceWithSale: 3700
                }
            },
            {
                warehouseName: 'Казань',
                box: {
                    price: 2250,
                    priceWithSale: 1750
                },
                pallet: null
            },
            {
                warehouseName: 'Санкт-Петербург',
                box: {
                    price: 1200,
                    priceWithSale: 1050
                },
                pallet: null
            },
            {
                warehouseName: 'Екатеринбург',
                box: {
                    price: 2800,
                    priceWithSale: 2250
                },
                pallet: null
            },
            {
                warehouseName: 'Новосибирск',
                box: {
                    price: 3300,
                    priceWithSale: 2550
                },
                pallet: null
            },
            {
                warehouseName: 'Ростов-на-Дону',
                box: {
                    price: 2500,
                    priceWithSale: 1950
                },
                pallet: null
            }
        ]
    },
    {
        store_id: 2,
        title: 'Доставка до складов OZON',
        whData: [
            {
                warehouseName: 'МОСКВА, Львовский',
                box: {
                    price: 500,
                    priceWithSale: 350
                },
                pallet: {
                    price: 2900,
                    priceWithSale: 2500
                }
            },
            {
                warehouseName: 'Санкт-Петербург, ШУШАРЫ',
                box: {
                    price: 1400,
                    priceWithSale: 1050
                },
                pallet: null
            },
            {
                warehouseName: 'КАЗАНЬ',
                box: {
                    price: 2500,
                    priceWithSale: 1950
                },
                pallet: null
            }
        ]
    },
    {
        store_id: 3,
        title: 'Доставка до складов SberMegaMarket',
        whData: [
            {
                warehouseName: 'МОСКВА, поселение Марушкинское',
                box: {
                    price: 500,
                    priceWithSale: 350
                },
                pallet: null
            },
            {
                warehouseName: 'САНКТ-ПЕТЕРБУРГ, Шушары',
                box: {
                    price: 1400,
                    priceWithSale: 1050
                },
                pallet: null
            }
        ]
    },
    {
        store_id: 4,
        title: 'Доставка до складов Яндекс.Маркет',
        whData: [
            {
                warehouseName: 'Москва, СОФЬИНО',
                box: {
                    price: 700,
                    priceWithSale: 550
                },
                pallet: null
            },
            {
                warehouseName: 'САНКТ-ПЕТЕРБУРГ',
                box: {
                    price: 1400,
                    priceWithSale: 1050
                },
                pallet: null
            }
        ]
    }
];
