import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import cartStore from 'stores/cartStore';

import Table, { TableHead, TableRow, TableCell, TableBody } from 'ui/Table';
import Time from 'ui/Time';
import CutString from 'ui/CutString';
import { WarningMessage } from 'ui/Message';

import Chip from '@mui/material/Chip';

import ListWrapper from '../ListWrapper';
import ListPagination from '../ListPagination';
import ProductTitleLink from '../Products/ProductTitleLink';
import CartsFilter from './CartsFilter';

const CartsList = () => {
    const { list, loadingList, handleSort, adjustDirection } = cartStore;

    return (
        <ListWrapper store={cartStore} bgUrl="/static/listbg/unruly-07-technologies.webp">
            <CartsFilter />

            <Table elevation={0} loading={loadingList}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sortDirection={adjustDirection('createTime')}
                            onClick={() => handleSort('createTime')}
                        >
                            Дата
                        </TableCell>
                        <TableCell sortDirection={adjustDirection('status')} onClick={() => handleSort('status')}>
                            Статус
                        </TableCell>
                        <TableCell
                            sortDirection={adjustDirection('product_id')}
                            onClick={() => handleSort('product_id')}
                            sx={{ minWidth: '250px' }}
                        >
                            Наименование
                        </TableCell>
                        <TableCell>Количество</TableCell>
                        <TableCell>Запрос</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(
                        ({
                            cart_id,
                            updateTime,
                            speed,
                            quantity,
                            product_id,
                            product,
                            createTime,
                            status,
                            searchRequest
                        }) => (
                            <TableRow key={cart_id}>
                                <TableCell>
                                    <Time time={createTime} />
                                </TableCell>
                                <TableCell>
                                    {status === 1 && <Chip label="Выполняется" color="warning" />}
                                    {status === 2 && <Chip label="Выполнено" color="success" />}
                                </TableCell>
                                <TableCell>
                                    <ProductTitleLink product={product} />
                                </TableCell>
                                <TableCell align="center">{quantity}</TableCell>
                                <TableCell>
                                    <CutString title={searchRequest} maxLength={30} />
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
                <ListPagination
                    pagination={cartStore.pagination}
                    listCount={cartStore.listCount}
                    pageChange={cartStore.pageChange}
                    pageSizeChange={cartStore.pageSizeChange}
                    colSpan={4}
                />
            </Table>

            {!loadingList && list.length === 0 && (
                <WarningMessage header="Нет добавлений в Корзину">
                    Вы можете добавить ваш <Link to="/products/list">товар</Link> в Корзину
                </WarningMessage>
            )}
        </ListWrapper>
    );
};

export default observer(CartsList);
