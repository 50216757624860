import authStore from 'stores/authStore';
import config from 'config/server.config';

export const authHeaders = (): { authorization?: string } => {
    const token = authStore.token;
    if (token) {
        return {
            authorization: 'Bearer ' + token
        };
    }
    return {};
};

const mergeSettings = (settings: any) => {
    settings = settings || {};
    settings = { ...settings, headers: settings.headers || {} };

    settings.headers = { ...settings.headers, ...authHeaders() };

    return settings;
};

const adjustUrl = (url: string): string => {
    return ~url.indexOf('://') ? url : config.serverUrl + url;
};

export const grabMessageFromError = (error: { response?: { data?: { error: string } }; error: string }): string => {
    let message = '';
    try {
        // @ts-ignore
        message = error.response.data.error;
    } catch (e) {
        message = error.error;
    }
    return message;
};

function queryParams(params: any): string {
    return Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
}

const makeFetch = async (adjustedUrl: string, settings: object) => {
    try {
        const response = await fetch(adjustedUrl, mergeSettings(settings));
        return adjustResponse(response);
    } catch (error) {
        // @ts-ignore
        throw [error].map(({ message }) => message);
    }
};

const getOnce = async <T>(url: string, data = {}, controller?: AbortController): Promise<T> => {
    const settings = {
        method: 'GET',
        type: 'JSON',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        ...(controller && { signal: controller.signal })
    };

    const params = {
        ...data,
        _rand: Math.random()
    };

    let adjustedUrl = adjustUrl(url);
    if (Object.keys(params).length) {
        adjustedUrl += (adjustedUrl.includes('?') ? '&' : '?') + queryParams(params);
    }

    return await makeFetch(adjustedUrl, settings);
};

const get = async <T>(url: string, data = {}, controller?: AbortController): Promise<T> => {
    try {
        return await getOnce(url, data, controller);
    } catch (errors) {
        if (errors instanceof Array && errors[0] === 'Failed to fetch') {
            return await getOnce(url, data, controller);
        }
        throw errors;
    }
};

export type GraphQLAnswer<T> = {
    data: T;
    errors?: Array<{ message: string }>;
};

async function getGQ<T>(graphql: string, controller?: AbortController): Promise<T> {
    const { data, errors } = await get<{ data: T; errors?: { message: string }[] }>(
        `/graphql?query=${graphql}`,
        {},
        controller
    );
    if (!errors) {
        return data;
    } else {
        throw errors.map(({ message }) => message);
    }
}

const loadPlain = async (url: string): Promise<string> => {
    const response = await fetch(url);
    return adjustResponse(response, 'text');
};

const postPlain = async <T>(url: string, body: string, controller?: AbortController): Promise<T> => {
    const settings = {
        method: 'POST',
        type: 'JSON',
        body,
        headers: {
            Accept: 'application/json'
        },
        ...(controller && { signal: controller.signal })
    };

    return await makeFetch(adjustUrl(url), settings);
};

async function postGQ<T>(graphql: string, controller?: AbortController): Promise<T> {
    const { data, errors } = await postPlain<{ data: T; errors?: { message: string }[] }>(
        `/graphql`,
        graphql,
        controller
    );
    if (!errors) {
        return data;
    } else {
        throw errors.map(({ message }) => message);
    }
}

const post = async <T>(url: string, data = {}): Promise<T> => {
    const settings = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    };

    const response = await fetch(adjustUrl(url), mergeSettings(settings));
    return adjustResponse(response);
};

export const adjustResponse = async (response: Response, type = 'json'): Promise<any> => {
    const answer = await response.blob();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onload = function () {
            if (reader.result) {
                const answer = type === 'json' ? JSON.parse(reader.result.toString()) : reader.result.toString();

                if (response.ok) {
                    resolve(answer);
                } else {
                    reject(answer);
                }
            } else {
                reject();
            }
        };
        reader.readAsText(answer);
    });
};

const thisModule = {
    get,
    getGQ,
    post,
    postPlain,
    postGQ,
    loadPlain
};

export default thisModule;
