import React from 'react';
import * as userApi from 'api/userApi';

import LinkIcon from '@mui/icons-material/Link';
import Box from '@mui/material/Box';

import Button from 'ui/Button';

const FFFullPriceLink = () => {
    const handleClick = () => {
        userApi.sendUserAction({ FFFullPriceLinkClick: true });
    };

    return (
        <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
            <Button
                endIcon={<LinkIcon />}
                color="inherit"
                target="_blank"
                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSssDCv4z6u2aDsLpFWzZ0ofYs8xVXfj07kYVyz0viDDrzi1ieUOY4VF__5sIhMmZnOeMH4Hl2IajGJ/pubhtml"
                tooltip="Ссылка на полный прайс"
                variant="outlined"
                onClick={handleClick}
            >
                Ссылка на полный прайс
            </Button>
        </Box>
    );
};

export default FFFullPriceLink;
