import fetchApi from 'common/fetchApi';
import { objectToGraphql } from '../common/graphql';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { Wishlist } from 'stores/wishlistStore';
import { GQProductListFields } from './productApi';

export const createItem = async (wishlist: Partial<Wishlist>): Promise<number> => {
    const graphql = `mutation { 
          createWishlist(wishlist: ${objectToGraphql(wishlist)})
        }`;

    const data = await fetchApi.postGQ<{ createWishlist: number }>(graphql);
    return data.createWishlist;
};

const GQWishlistFields = `
    wishlist_id
    user_id
    product_id

    product {
        ${GQProductListFields}
    }

    speed
    quantity
    status
    createTime
    updateTime
    enable
`;

export const fetchItem = async (wishlist_id: number): Promise<Wishlist> => {
    const graphql = `{ 
          fetchWishlist(wishlist_id: ${wishlist_id}) {
            ${GQWishlistFields}
           }
        }`;

    const data = await fetchApi.getGQ<{ fetchWishlist: Wishlist }>(graphql);
    return data.fetchWishlist;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    wishlistFilter: {},
    controller?: AbortController
): Promise<{ list: Wishlist[]; count: number }> => {
    const graphql = `{
            fetchWishlistsList {
                list {
                    ${GQWishlistFields}
                }
                count
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchWishlistsList: { list: Wishlist[]; count: number } }>(graphql);
    return data.fetchWishlistsList;
};
