import * as ozonAccountsApi from 'api/ozonAccountsApi';
import ListStorePrototype from './prototypes/ListStore.prototype';

type AccountGender = 'male' | 'female' | null;

export type OzonAccount = {
    account_id: number;
    phone: string;
    email: string;
    priority: number;
    gender: AccountGender;
    lastName: string | null;
    firstName: string | null;

    SecureRefreshToken: string;
    SecureAccessToken: string;

    updateCookieTime: number | null;

    onlineTime: number;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

class WBAccountStore extends ListStorePrototype<OzonAccount, OzonAccount> {
    listFilterClear = {};

    constructor() {
        super('account_id', 'accounts', ozonAccountsApi);
    }
}

export default new WBAccountStore();
