import fetchApi, { grabMessageFromError } from 'common/fetchApi';
import Cookies from 'js-cookie';

import { User } from 'stores/userStore';
import { GQUserMainFields } from './userApi';

export const logIn = async (phone: string, password: string): Promise<{ token: string }> => {
    try {
        const ref = Cookies.get('ref');

        const answer: { token: string } = await fetchApi.post('/auth/login', { phone, password, ref });
        return answer;
    } catch (e) {
        // @ts-ignore
        throw grabMessageFromError(e);
    }
};

export const pullCurrentUser = async (): Promise<User> => {
    const graphql = `{
            fetchUser {
                ${GQUserMainFields}
                sumClearPayments
                disableWb
            } 
          }`;

    const data: { fetchUser: User } = await fetchApi.getGQ(graphql);
    return data.fetchUser;
};

export type TransactionsItems =
    | 'cart'
    | 'question'
    | 'ransom'
    | 'wishlist'
    | 'favorite'
    | 'review'
    | 'seoConsult'
    | 'brand'
    | 'feedbackReaction';

export type BalanceTransaction =
    | {
          direction: 'in';
          payment: {
              type: 'order' | 'gift';
              cost: number;
              createTime: number;
          };
      }
    | {
          direction: 'out';
          transaction: {
              itemType: TransactionsItems;
              item_id: number;
              cost: number;
              createTime: number;
          };
      };

export const fetchUserTransactions = async (
    limit: number,
    offset: number
): Promise<{ list: BalanceTransaction[]; count: number }> => {
    const graphql = `{
            fetchUserTransactions(limit: ${limit}, offset: ${offset}) {
                list {
                    direction
                    payment {
                        type
                        cost
                        createTime   
                    }
                    transaction {
                        itemType
                        item_id
                        cost
                        createTime   
                    }
                }
                count
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchUserTransactions: { list: BalanceTransaction[]; count: number } }>(
        graphql
    );
    return data.fetchUserTransactions;
};

export const fetchWbApiKey = async (type: 'new' | 'x64'): Promise<string | null> => {
    const graphql = `{ 
          fetchWbApiKey(type: "${type}")
        }`;

    const data = await fetchApi.postGQ<{ fetchWbApiKey: string | null }>(graphql);
    return data.fetchWbApiKey;
};

export const deleteWbApiKey = async (): Promise<boolean> => {
    const graphql = `mutation { 
          deleteWbApiKey
        }`;

    const data = await fetchApi.postGQ<{ deleteWbApiKey: boolean }>(graphql);
    return data.deleteWbApiKey;
};
