import React from 'react';

import Typography from '@mui/material/Typography';

import PriceTable from './PriceTable';

const FFPriceFbs = ({ store_id }: { store_id: number }) => {
    let title = '';
    switch (store_id) {
        case 1:
            title = 'Wildberries';
            break;
        case 2:
            title = 'OZON';
            break;
        case 3:
            title = 'SberMegaMarket';
            break;
        case 4:
            title = 'Яндекс.Маркет';
            break;
        case 5:
            title = 'Авито';
            break;
    }

    return (
        <>
            <Typography variant="h5" color="secondary" sx={{ mt: 4 }}>
                Стоимость подготовки груза при FBS на {title}
            </Typography>
            <PriceTable prices={[{ title: 'Оформление поставки в ЛК за 1 единицу', price: 70, priceWithSale: 40 }]} />

            <Typography variant="h5" color="secondary" sx={{ mt: 4 }}>
                Стоимость доставки FBS-заказов на {title}
            </Typography>
            <PriceTable
                prices={[
                    { title: 'Заказ до 5 кг', price: 115, priceWithSale: 65 },
                    { title: 'Каждый последующий килограмм свыше 5 кг', price: 17, priceWithSale: 10 }
                ]}
            />
        </>
    );
};

export default FFPriceFbs;
