import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';

import { WbItemFeedbackLike } from 'api/productApi';
import { FeedbackReactionType } from 'stores/feedbackReactionStore';
import authStore from 'stores/authStore';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Time from 'ui/Time';
import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';

import FeedbackModal from 'components/Items/FeedbackReaction';
import { FeedbackContext } from '../ProductFeedbacksMode';

const LikesList = ({ likes }: { likes: WbItemFeedbackLike[] }) => {
    return (
        <List dense>
            {likes.map(({ time, wb_user_id }) => (
                <ListItem key={time} disableGutters>
                    <ListItemAvatar>
                        <Avatar
                            src={`https://photos.wbstatic.net/img/${wb_user_id}/small/PersonalPhoto.jpg`}
                            sx={{ width: '24px', height: '24px' }}
                        />
                    </ListItemAvatar>
                    <ListItemText primary={<Time time={time} />} />
                </ListItem>
            ))}
        </List>
    );
};

const ProductThumbsBtns = () => {
    const feedback = useContext(FeedbackContext);
    const { likes } = feedback || { likes: [] };

    const plusLikes = likes.filter(({ helpfulness }) => helpfulness === 'plus');
    const minusLikes = likes.filter(({ helpfulness }) => helpfulness === 'minus');
    const [showFeedbackModal, toggleModal] = useToggleModal();
    const [initialType, setInitialType] = useState<FeedbackReactionType>('like');

    const handleCreateFeedbackLike = (type: FeedbackReactionType) => {
        setInitialType(type);
        toggleModal();
    };

    return (
        <>
            <Stack>
                {/*<Badge badgeContent={plusLikes.length} color="primary">*/}
                {/*    <Button*/}
                {/*        size="small"*/}
                {/*        icon={<ThumbUpIcon />}*/}
                {/*        tooltip={plusLikes.length ? <LikesList likes={plusLikes} /> : 'Поставить лайк'}*/}
                {/*        onClick={() => handleCreateFeedbackLike('like')}*/}
                {/*    />*/}
                {/*</Badge>*/}
                {/*<Badge*/}
                {/*    badgeContent={minusLikes.length}*/}
                {/*    color="secondary"*/}
                {/*    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}*/}
                {/*>*/}
                {/*    <Button*/}
                {/*        size="small"*/}
                {/*        icon={<ThumbDownOffAltIcon />}*/}
                {/*        tooltip={minusLikes.length ? <LikesList likes={minusLikes} /> : 'Поставить дизлайк'}*/}
                {/*        onClick={() => handleCreateFeedbackLike('dislike')}*/}
                {/*    />*/}
                {/*</Badge>*/}
                {/*<Button*/}
                {/*    size="small"*/}
                {/*    icon={<DeleteForeverIcon />}*/}
                {/*    tooltip="Удалить данный комментарий"*/}
                {/*    onClick={() => handleCreateFeedbackLike('remove')}*/}
                {/*    color="error"*/}
                {/*/>*/}
                <Button
                    size="small"
                    icon={<PlaylistRemoveIcon />}
                    tooltip="Пожаловаться на отзыв"
                    onClick={() => handleCreateFeedbackLike('claim')}
                />
            </Stack>
            {showFeedbackModal && <FeedbackModal initialType={initialType} onClose={toggleModal} />}
        </>
    );
};

export default observer(ProductThumbsBtns);
