import React, { useState } from 'react';
import { observer } from 'mobx-react';

import reviewStore, { ReviewStatusEnum } from 'stores/reviewStore';
import authStore from 'stores/authStore';

import Button from 'ui/Button';
import Divider from 'ui/Divider';
import Stack from '@mui/material/Stack';

const ReviewPultButtons = ({ review_id, refreshWbTime }: { review_id: number; refreshWbTime: () => Promise<void> }) => {
    const { item } = reviewStore.getItem(review_id);
    const { status, account_id, accountIsGood } = item || { status: ReviewStatusEnum.Creating };

    const handleDone = () => {
        reviewStore.sendStatus(review_id, ReviewStatusEnum.Done);
    };

    const handleReject = () => {
        reviewStore.sendStatus(review_id, ReviewStatusEnum.Cancel);
    };

    return (
        <>
            {!authStore.isAdmin() && status === ReviewStatusEnum.WaitingForModeration && !accountIsGood && (
                <>
                    <Divider />
                    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                        <Button fullWidth color="secondary" onClick={handleReject}>
                            Отменить заявку на отзыв
                        </Button>
                    </Stack>
                </>
            )}
            {authStore.isAdmin() && status !== ReviewStatusEnum.Creating && (
                <>
                    <Divider />
                    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                        {[ReviewStatusEnum.Done, ReviewStatusEnum.Cancel, ReviewStatusEnum.Error].includes(status) && (
                            <Button fullWidth color="warning" onClick={refreshWbTime}>
                                еще раз
                            </Button>
                        )}
                        {status !== ReviewStatusEnum.Cancel && (
                            <Button fullWidth color="error" onClick={handleReject}>
                                Отменить
                            </Button>
                        )}
                        {[ReviewStatusEnum.WaitingForModeration, ReviewStatusEnum.Error].includes(status) && (
                            <Button fullWidth primary variant="contained" onClick={handleDone}>
                                Готово
                            </Button>
                        )}
                        {status === ReviewStatusEnum.WaitingForModeration && (
                            <Button fullWidth color="secondary" variant="contained" onClick={refreshWbTime}>
                                Опубликовать
                            </Button>
                        )}
                    </Stack>
                </>
            )}
        </>
    );
};

export default observer(ReviewPultButtons);
