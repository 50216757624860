import React from 'react';
import { observer } from 'mobx-react';

import ransomStore from 'stores/ransomStore';
import productStore, { WbSize, whetherProductIsWB } from 'stores/productStore';
import { DropdownType } from 'stores/prototypes/ListStore.prototype';

import Select from 'ui/Select';

export const RansomSizeSelect = observer(
    ({
        wb_size_id,
        product_id,
        onChange
    }: {
        wb_size_id: number | null;
        product_id: number;
        onChange: (wb_size_id: number, wbSize: WbSize) => void;
    }) => {
        const product = productStore.getItem(product_id).item;

        let sizesOptions: DropdownType[] = [];
        if (product && whetherProductIsWB(product)) {
            sizesOptions = product?.wbItem?.sizes?.map(({ wb_size_id, sizeName, sizeNameRus, isSoldOut }) => ({
                value: wb_size_id,
                text: `${sizeName}${sizeNameRus ? ` (${sizeNameRus})` : ''}`,
                disabled: isSoldOut
            }));
        }

        if (sizesOptions.length === 0) {
            return null;
        }

        const handleChangeWbSize = ({ value }: { value: number }) => {
            if (product && whetherProductIsWB(product)) {
                const wbSize = product?.wbItem?.sizes?.find(({ wb_size_id }) => value === wb_size_id);
                if (wbSize) {
                    onChange(value, wbSize);
                }
            }
        };

        return (
            <Select
                label="Размер"
                value={wb_size_id}
                onChange={handleChangeWbSize}
                options={sizesOptions}
                fullWidth
                variant="classic"
                error={!wb_size_id}
            />
        );
    }
);

const RansomSizeEditing = ({ ransom_id }: { ransom_id: number }) => {
    const { editingItem } = ransomStore.getItem(ransom_id);
    const { product_id, wb_size_id = null } = editingItem;
    if (!product_id) {
        return null;
    }

    const handleChangeWbSize = (wb_size_id: number, wbSize: WbSize) => {
        ransomStore.setEditingItem(ransom_id, { wb_size_id });
        ransomStore.setProperty(ransom_id, { wbSize });
    };

    return <RansomSizeSelect product_id={product_id} wb_size_id={wb_size_id} onChange={handleChangeWbSize} />;
};

export default observer(RansomSizeEditing);
