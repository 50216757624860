import { makeObservable } from 'mobx';
import * as brandApi from 'api/brandApi';
import { ProductList, StoreIds } from './productStore';
import ListTransactionStorePrototype from './prototypes/ListTransactionsStore.prototype';

export type Brand = {
    brand_id: number;
    user_id: number;
    product_id: number | null;
    status: 1 | 2 | 100;
    speed: number | null;
    quantity: number;
    errorMessage: string | null;

    searchRequest: string | null;
    gender: 'male' | 'female' | null;

    product: ProductList;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type BrandFilter = {
    status: Brand['status'] | null;
    store_id: StoreIds;
    product_id: number | null;
    allUsers: boolean;
    enable: boolean;
};

class BrandStore extends ListTransactionStorePrototype<Brand, Brand, {}, BrandFilter> {
    constructor() {
        super('brand_id', 'brand', brandApi);

        this.orderBy = 'brand_id';
        this.clearFilter();
        makeObservable(this);
    }

    listFilterClear = {
        status: null,
        store_id: StoreIds.WB,
        product_id: null,
        allUsers: false,
        enable: true
    };
}

export default new BrandStore();
