import React from 'react';

import productStore, { ProductList } from 'stores/productStore';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

import ProductLink from 'components/Items/Product/ProductLink';

const ProductTitleLink = ({ product }: { product: ProductList | null }) => {
    if (!product) {
        return null;
    }
    const { product_id } = product;
    const { title, photos } = productStore.calcShortProduct(product);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <div>
                <Avatar src={photos[0]} variant="rounded" />
            </div>
            <ProductLink product_id={product_id} title={title} />
        </Box>
    );
};

export default ProductTitleLink;
