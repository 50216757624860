import React from 'react';
import { observer } from 'mobx-react';

import deliveryStore from 'stores/deliveryStore';
import Button from 'ui/Button';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import HomeIcon from '@mui/icons-material/Home';
import ListItemText from '@mui/material/ListItemText';
import HeightIcon from '@mui/icons-material/Height';
import MapIcon from '@mui/icons-material/Map';

const DeliveryAddressAndSizeShow = ({ delivery_id }: { delivery_id: number }) => {
    const { item } = deliveryStore.getItem(delivery_id);

    const { ransom } = item || { ransom: null };

    if (!ransom) {
        return null;
    }

    const {
        deliveryStorePoint: { address, geo_lat, geo_lon }
    } = ransom;

    const yandexUrl = `https://maps.yandex.ru/?pt=${geo_lon},${geo_lat}&z=13&l=map`;

    return (
        <List dense disablePadding>
            <ListItem
                secondaryAction={
                    <Button icon={<MapIcon />} target="_blank" href={yandexUrl} tooltip="Открыть на Яндекс.Картах" />
                }
            >
                <ListItemAvatar>
                    <Avatar>
                        <HomeIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={address} secondary="Адрес доставки" />
            </ListItem>
            {ransom?.wbSize && (
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <HeightIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={ransom?.wbSize.sizeName} secondary={ransom?.wbSize.sizeNameRus} />
                </ListItem>
            )}
        </List>
    );
};

export default observer(DeliveryAddressAndSizeShow);
