import fetchApi from '../common/fetchApi';

export const createOrder = async (cost: number): Promise<string> => {
    const graphql = `mutation { 
          createOrder(cost: ${cost})
        }`;

    const data = await fetchApi.postGQ<{ createOrder: string }>(graphql);
    return data.createOrder;
};
