import React, { Fragment } from 'react';

import { WbItemFeedback } from 'api/productApi';

import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';

import Time from 'ui/Time';

import ProductFeedbackAnswer from './ProductFeedbackAnswer';
import ProductFeedbackPhotos from './ProductFeedbackPhotos';

const printMatchingSize = (matchingSize: 'ok' | 'smaller' | 'bigger'): string => {
    switch (matchingSize) {
        case 'bigger':
            return 'большемерит';
        case 'smaller':
            return 'маломерит';
        case 'ok':
            return 'соответствует размеру';
    }
};

const ProductFeedbackItem = ({ feedback }: { feedback: WbItemFeedback }) => {
    const { text, createTime, star, photos, wb_user_id, wbUser, answer, size, matchingSize, color } = feedback;

    return (
        <>
            {wbUser && (
                <ListItemAvatar>
                    <Avatar
                        alt={wbUser.name}
                        src={
                            wbUser.hasPhoto
                                ? `https://photos.wbstatic.net/img/${wb_user_id}/small/PersonalPhoto.jpg`
                                : undefined
                        }
                    />
                </ListItemAvatar>
            )}
            <ListItemText
                primary={
                    <Stack direction="row" spacing={2} alignItems="center">
                        {wbUser && <div>{wbUser.name}</div>}
                        <Typography variant="caption" color="text.primary" component="div">
                            <Time time={createTime} />
                        </Typography>
                    </Stack>
                }
                primaryTypographyProps={{ component: 'div' }}
                secondary={
                    <Fragment>
                        <Stack direction="row" spacing={2} sx={{ my: 1 }}>
                            <Rating defaultValue={star} size="small" readOnly />
                            {color && <Typography variant="caption">Цвет: {color}</Typography>}
                            {size !== '0' && size && (
                                <Typography variant="caption">
                                    Размер: {size}
                                    {matchingSize && <>, {printMatchingSize(matchingSize)}</>}
                                </Typography>
                            )}
                        </Stack>
                        <div>{text}</div>
                        {photos.length > 0 && <ProductFeedbackPhotos photos={photos} />}
                        {answer && <ProductFeedbackAnswer {...answer} />}
                    </Fragment>
                }
                secondaryTypographyProps={{ component: 'div' }}
            />
        </>
    );
};

export default ProductFeedbackItem;
