import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import ransomStore, { RansomType } from 'stores/ransomStore';
import authStore from 'stores/authStore';

import InfoPopup from 'ui/InfoPopup';
import Checkbox from 'ui/Checkbox';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import RansomAdditionalEditingBlock from './RansomAdditionalEditingBlock';

const ProductAdditionalPopup = React.memo(() => {
    return (
        <InfoPopup size="large">
            <Typography variant="subtitle2">Для чего добавлять дополнительный товар в выкуп?</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Добавление других товаров в выкуп резко уменьшает вероятность того, что маркетплейс посчитает данный
                выкуп подозрительным. На данный момент на Wildberries алгоритмы по поиску подозрительных покупок
                обращают в первую очередь на заказы с одним товаром.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Если вы будете добавлять чужие товары (или свои похожие товары), то со временем ваш товар появится в
                разделе “С этим товаром покупают” в карточке конкурента. Тем самым вы получите дополнительный трафик на
                свою карточку с других карточек.
            </Typography>
            <Typography variant="body2" sx={{ my: 1 }}>
                Данная функция <b>Бесплатна</b>. Добавлять можно не более 2 разных товаров за раз.
            </Typography>
            <Typography variant="caption" sx={{ mt: 1 }}>
                После создания Выкупа вам будет доступна видеозапись, где вы сможете убедиться, что товары конкурентов
                на самом деле были добавлены в корзину перед выкупом.
            </Typography>
        </InfoPopup>
    );
});

const RansomAdditionalItemsBlock = ({ ransom_id }: { ransom_id: number }) => {
    const [additionalEnable, setAdditional] = useState(false);

    const handleToggleAdditional = () => {
        ransomStore.setEditingItem(ransom_id, { additional_store_item_ids: [] });
        ransomStore.setProperty(ransom_id, { additionalItems: [] });
        setAdditional(!additionalEnable);
    };

    const { type, additional_store_item_ids } = ransomStore.getItem(ransom_id).editingItem;

    useEffect(() => {
        if (type === RansomType.Group) {
            setAdditional(false);
        }
        if (additional_store_item_ids?.length) {
            setAdditional(true);
        }
    }, [type, additional_store_item_ids?.length]);

    const { settings } = authStore;

    return (
        <>
            <Stack direction="row">
                <Checkbox
                    label="Добавить дополнительные товары в выкуп"
                    checked={additionalEnable}
                    onChange={handleToggleAdditional}
                    disabled={!settings['additionalItems_wb'] || type === RansomType.Group}
                />
                <ProductAdditionalPopup />
            </Stack>
            {additionalEnable && <RansomAdditionalEditingBlock ransom_id={ransom_id} />}
        </>
    );
};

export default observer(RansomAdditionalItemsBlock);
