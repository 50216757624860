import React from 'react';

import Typography from '@mui/material/Typography';

import { useToggleModal } from 'ui/Modal';
import { WarningMessage } from 'ui/Message';
import Button from 'ui/Button';

const ReviewWarning = () => {
    const [showMessage, toggleMessage] = useToggleModal();

    return (
        <WarningMessage header="Как лучше оставить отзыв?">
            {showMessage && (
                <div>
                    <ul>
                        <li>Писать отзывы по истечению 15 дней с момента старта продаж</li>
                        <li>Оставлять отзывы к товару после того, как по нему пройдёт оборот больше 50 тыс. руб.</li>
                        <li>Писать отзыв спустя 3 дня и более с момента получения товара на ПВЗ</li>
                        <li>Отзывы на карточку товара оставлять с интервалами более 5 часов</li>
                        <li>Избегать слов в превосходной степени, таких как: самый, лучший, прекрасный и т. д.</li>
                        <li>
                            Использовать оптимальное количество символов в тексте отзыва, не писать длинные предложения
                        </li>
                        <li>Исключить использование слов: рекомендую, советую и прочих</li>
                        <li>Не прикреплять более двух фотографий на отзыв</li>
                        <li>
                            На фотографии товар должен быть без упаковки, т.к. люди обычно не делают фотографии товара
                            до вскрытия упаковки
                        </li>
                        <li>
                            На фотографии не желательно присутствия следов графической обработки (надписи, эффекты,
                            склейки), т.к. это не соответствует клиентскому поведению
                        </li>
                    </ul>
                    <Typography variant="body2" sx={{ my: 1 }}>
                        Важно понимать, что отзывы оставляют от 1 до 20% пользователей (в зависимости от категории).
                        Учитывайте этот фактор при работе со своей карточкой. Например, в нише «Аксессуары» на 100
                        выкупов приходиться 5 отзывов.
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                        При написании отзыва и ответа на него старайтесь использовать ключевые слова, это поможет в
                        ранжировании карточки.
                    </Typography>
                </div>
            )}
            <Button size="small" color="warning" onClick={toggleMessage}>
                {!showMessage ? 'Открыть рекомендации' : 'Скрыть'}
            </Button>
        </WarningMessage>
    );
};

export default ReviewWarning;
