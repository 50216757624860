import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import ItemDrawWrapper, { MenuTab } from '../ItemDrawWrapper';

import userStore from 'stores/userStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import ListError from 'ui/ListError';
import LoaderAwait from 'ui/LoaderAwait';
import { ErrorMessage } from 'ui/Message';

import UserShowMode from './UserShowMode';
import UserMessageMode from './UserMessageMode';

const User = () => {
    const { item_id: item_id_prepare, tabName }: { item_id?: string; tabName?: string } = useParams();
    const user_id = Number(item_id_prepare) || CREATING_ITEM_ID;

    useMemo(() => {
        userStore.fetchItem(user_id).then(() => {
            const item = userStore.getItem(user_id).item;
            if (item) {
                const { enableReferrals, disableWbAccountAfterRansom } = item;
                userStore.setEditingItem(user_id, { enableReferrals, disableWbAccountAfterRansom });
            }
        });
    }, [user_id]);

    const { loadingItem, errors, item } = userStore.getItem(user_id);

    if (!item && errors?.length > 0) {
        return <ListError errors={errors} />;
    }

    if (!item || loadingItem) {
        return (
            <div style={{ position: 'relative', height: '100vh' }}>
                <LoaderAwait active />
            </div>
        );
    }

    const { enable } = item;

    const tabs: MenuTab[] = [
        { id: 'main', label: 'Юзер', pathname: `/users/list/detail/${user_id}` },
        { id: 'message', label: 'Сообщение', pathname: `/users/list/detail/${user_id}/message` }
    ];

    return (
        <ItemDrawWrapper tabs={tabs} activeTab={tabName || 'main'}>
            {!enable && <ErrorMessage header="Юзер в архиве" />}
            {(!tabName || tabName === 'main') && <UserShowMode user_id={user_id} />}
            {tabName === 'message' && <UserMessageMode user_id={user_id} />}
        </ItemDrawWrapper>
    );
};

export default observer(User);
