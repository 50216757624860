import fetchApi from 'common/fetchApi';
import { objectToGraphql } from '../common/graphql';
import { SeoConsult } from '../stores/seoConsultStore';
import { OrderDirectionType } from '../stores/prototypes/ListStore.prototype';

export const createItem = async (seoConsult: Partial<SeoConsult>): Promise<number> => {
    const graphql = `mutation { 
          createSeoConsult(seoConsult: ${objectToGraphql(seoConsult)})
        }`;

    const data = await fetchApi.postGQ<{ createSeoConsult: number }>(graphql);
    return data.createSeoConsult;
};

const GQSeoConsultFields = `
    seo_consult_id
    user_id
    product_id

    comment
    status
   
    createTime
    updateTime
    enable
`;

export const fetchItem = async (seo_consult_id: number): Promise<SeoConsult> => {
    const graphql = `{ 
          fetchSeoConsult(seo_consult_id: ${seo_consult_id}) {
            ${GQSeoConsultFields}
           }
        }`;

    const data = await fetchApi.getGQ<{ fetchSeoConsult: SeoConsult }>(graphql);
    return data.fetchSeoConsult;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    seoConsultFilter: {},
    controller?: AbortController
): Promise<{ list: SeoConsult[]; count: number }> => {
    return { list: [], count: 0 };
};

export const createSeoControl = async (phone: string, comment: string): Promise<boolean> => {
    const graphql = `mutation { 
          createSeoControl(phone: ${objectToGraphql(phone)}, comment: ${objectToGraphql(comment)})
        }`;

    const data = await fetchApi.postGQ<{ createSeoControl: boolean }>(graphql);
    return data.createSeoControl;
};
