import React, { useState } from 'react';
import { observer } from 'mobx-react';

import productStore from 'stores/productStore';
import ransomStore from 'stores/ransomStore';

import Time from 'ui/Time';
import Button from 'ui/Button';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import WomanIcon from '@mui/icons-material/Woman';
import ManIcon from '@mui/icons-material/Man';

import CloseIcon from '@mui/icons-material/Close';
import RansomSmartItemModal from './RansomSmartItemModal';
import { RansomFilterChipsShow } from '../RansomFilterChips';

const RansomSmartsItem = ({
    ransom_id,
    index,
    product_id
}: {
    ransom_id: number;
    index: number;
    product_id: number;
}) => {
    const [showModalIndex, setModalIndex] = useState<number | null>(null);

    const { editingItem } = ransomStore.getItem(ransom_id);
    const { ransomSmartItems } = editingItem;
    if (!ransomSmartItems) {
        return null;
    }
    const { address, time, wbSizeName, searchRequest, wbFilters, gender } = ransomSmartItems[index];

    const removeSmartItem = (index: number) => {
        const ransomSmartItemsNew = [...ransomSmartItems];
        ransomSmartItemsNew.splice(index, 1);
        ransomStore.setEditingItem(ransom_id, { ransomSmartItems: ransomSmartItemsNew });
    };

    return (
        <>
            <ListItem
                secondaryAction={
                    <Button icon={<CloseIcon />} onClick={() => removeSmartItem(index)} tooltip="Убрать smart выкуп" />
                }
                divider
            >
                <Tooltip title="Нажмите, чтобы откорректировать smart выкуп">
                    <ListItemButton onClick={() => setModalIndex(index)}>
                        <ListItemIcon>
                            <ScheduleIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Stack direction="row" spacing={2} justifyContent="space-between">
                                    <Time time={time} />
                                    {wbSizeName && <Typography variant="caption">Размер: {wbSizeName}</Typography>}
                                    {!wbSizeName && productStore.whetherProductHasSizes(product_id) && (
                                        <Typography variant="caption" sx={{ color: 'error.main' }}>
                                            Укажите размер
                                        </Typography>
                                    )}
                                    {address || (
                                        <Typography sx={{ color: 'error.main' }} variant="caption">
                                            Адрес ПВЗ не задан
                                        </Typography>
                                    )}
                                </Stack>
                            }
                            secondaryTypographyProps={{ variant: 'caption', sx: { mt: 1 } }}
                            secondary={
                                <Stack spacing={0} alignItems="baseline">
                                    <Stack direction="row" spacing={1}>
                                        <div>Поисковой запрос:</div>
                                        <b>{searchRequest || 'не задан'}</b>
                                        {searchRequest && gender === 'male' && <ManIcon fontSize="small" />}
                                        {searchRequest && gender === 'female' && <WomanIcon fontSize="small" />}
                                    </Stack>
                                    <div>
                                        Фильтры:&nbsp;
                                        {wbFilters ? <RansomFilterChipsShow wbFilters={wbFilters} /> : undefined}
                                        {wbFilters?.length === 0 && <b>не установлены</b>}
                                    </div>
                                </Stack>
                            }
                        />
                    </ListItemButton>
                </Tooltip>
            </ListItem>
            {showModalIndex !== null && (
                <RansomSmartItemModal
                    index={showModalIndex}
                    onClose={() => setModalIndex(null)}
                    ransom_id={ransom_id}
                />
            )}
        </>
    );
};

export default observer(RansomSmartsItem);
