import { action, makeObservable, observable } from 'mobx';

import ListStore, { ApiModuleTypeList } from './ListStore.prototype';
import { TItemExtended } from './ItemStore.prototype';
import authStore from '../authStore';

import debounce from 'common/debounce';
import { fetchWbSearchSuggestions } from 'api/productApi';

export type WBSuggestion =
    | { type: 'suggest'; name: string }
    | { type: 'brand'; brand_name: string; brand_id: number; site_id: number };

abstract class ListTransactionStorePrototype<
    ItemObjectType extends TItemExtended,
    ItemListType = ItemObjectType,
    ItemPropertyType = {},
    ItemListFilter = {}
> extends ListStore<ItemObjectType, ItemListType, ItemPropertyType, ItemListFilter> {
    constructor(
        item_id: keyof ItemObjectType,
        moduleName: string,
        ApiModule: ApiModuleTypeList<ItemObjectType, ItemListType, ItemListFilter>
    ) {
        super(item_id, moduleName, ApiModule);
        makeObservable(this);

        this.debounceWbSuggestions = debounce(this.fetchWbSuggestions, 550);
    }

    async createItem(): Promise<number> {
        const item_id = await super.createItem();
        if (item_id) {
            authStore.pullCurrentUserApi();
        }
        return item_id;
    }

    debounceWbSuggestions: () => void;

    suggestGender: 'male' | 'female' = 'female';
    suggestQuery: string = '';

    @observable
    wbSuggestions: string[] = [];

    @action
    fetchWbSuggestions = async (): Promise<void> => {
        const data = await fetchWbSearchSuggestions(this.suggestQuery, this.suggestGender);
        const filtered = data.filter(({ type }) => type === 'suggest');
        // @ts-ignore
        this.wbSuggestions = filtered.map(({ name }) => name);
    };
}

export default ListTransactionStorePrototype;
