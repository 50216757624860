import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import reviewStore from 'stores/reviewStore';
import productStore, { StoreIds } from 'stores/productStore';
import videoStore from 'stores/videoStore';

import ReviewCreateMode from './ReviewCreateMode';
import ReviewShowMode from './ReviewShowMode';
import ItemDrawWrapper, { MenuTab } from '../ItemDrawWrapper';
import VideoTabMode from '../VideoTabMode';
import ProductFeedbacksMode from '../Product/ProductFeedbacksMode';

import Time from 'ui/Time';
import Tooltip from '@mui/material/Tooltip';

const Review = () => {
    const { item_id: item_id_prepare, tabName }: { item_id?: string; tabName?: string } = useParams();
    const review_id = Number(item_id_prepare) || CREATING_ITEM_ID;

    useMemo(() => {
        productStore.fetchList();
        reviewStore.fetchItem(review_id);
        videoStore.findWbVideo('review', review_id);
    }, [review_id]);

    const isCreating = review_id === CREATING_ITEM_ID;

    const { status, updateTime, product_id: product_id_item } = reviewStore.getItem(review_id).item || {};
    const { product_id } = reviewStore.getItem(review_id).editingItem || {};

    const adjustedProductId = product_id || product_id_item;

    const tabs: MenuTab[] = [
        {
            id: 'main',
            label: isCreating ? 'Добавление отзыва' : 'Отзыв',
            pathname: `/reviews/list/detail/${review_id}`
        }
    ];

    if (productStore.getCurrentStoreId() === StoreIds.WB) {
        tabs.push({
            id: 'similar',
            label: 'Отзывы на похожие товары',
            pathname: `/reviews/list/detail/${review_id}/similar`,
            disabled: !adjustedProductId
        });
    }

    let videoId;
    if (!isCreating) {
        videoId = videoStore.getVideoId('review', review_id);

        tabs.push({
            id: 'video',
            label: 'Видеозапись',
            pathname: `/reviews/list/detail/${review_id}/video`,
            disabled: !videoId
        });
    }

    const publishTime =
        status === 2 || status === 4 ? (
            <Tooltip title="Время публикации отзыва">
                <span>
                    <Time time={updateTime} />
                </span>
            </Tooltip>
        ) : null;

    return (
        <ItemDrawWrapper tabs={tabs} activeTab={tabName || 'main'} endTab={publishTime}>
            {(!tabName || tabName === 'main') && !isCreating && <ReviewShowMode review_id={review_id} />}
            {(!tabName || tabName === 'main') && isCreating && <ReviewCreateMode />}
            {tabName === 'video' && videoId && <VideoTabMode videoId={videoId} />}
            {tabName === 'similar' && adjustedProductId && (
                <ProductFeedbacksMode product_id={adjustedProductId} feedbacksType="similar" />
            )}
        </ItemDrawWrapper>
    );
};

export default observer(Review);
