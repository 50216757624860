import React, { useMemo, useState } from 'react';
import * as autoAnswerApi from 'api/autoAnswerApi';

import commonStore from 'stores/commonStore';

import Modal, { ModalContent } from 'ui/Modal';
import ListError from 'ui/ListError';
import Time from 'ui/Time';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

const FeedbackReactionModal = ({
    onClose,
    stars,
    onSelect
}: {
    onClose: () => void;
    stars: number[];
    onSelect: (text: string) => void;
}) => {
    const [answers, setAnswers] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);

    useMemo(() => {
        setLoading(true);

        autoAnswerApi
            .getFeedbacksAnswersSamples(stars)
            .then(setAnswers)
            .catch(setErrors)
            .finally(() => setLoading(false));
    }, []);

    const handleSelect = (text: string) => {
        onSelect(text);
        onClose();
    };

    return (
        <Modal
            maxWidth="md"
            onClose={onClose}
            header="Случайные варианты ответов"
            loading={loading}
            fullScreen={commonStore.isMobile}
        >
            <ModalContent>
                <ListError errors={errors} />
                <List>
                    {answers.map(text => (
                        <ListItem key={text}>
                            <ListItemButton onClick={() => handleSelect(text)}>
                                <ListItemText primary={text}></ListItemText>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </ModalContent>
        </Modal>
    );
};

export default FeedbackReactionModal;
