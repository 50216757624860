import React, { SyntheticEvent, useState } from 'react';
import { observer } from 'mobx-react';

import userStore, { User } from 'stores/userStore';

import ListError from 'ui/ListError';
import Grid from 'ui/Grid';
import { PriceInput, NumberInput } from 'ui/TextInput';
import Button from 'ui/Button';
import Divider from 'ui/Divider';
import Select from 'ui/Select';
import Time from 'ui/Time';
import Table, { TableBody, TableCell, TableHead, TableRow } from 'ui/Table';
import { Switch } from 'ui/Checkbox';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import ReferralResetBalanceBtn from './ReferralResetBalanceBtn';

const expireDaysOptions = [
    { value: 1, text: '1 день' },
    { value: 2, text: '2 дня' },
    { value: 3, text: '3 дня' },
    { value: 4, text: '4 дня' },
    { value: 5, text: '5 дней' },
    { value: 7, text: '7 дней' },
    { value: 10, text: '10 дней' },
    { value: 14, text: '14 дней' },
    { value: 30, text: '30 дней' }
];

const UserTableRow = ({ user }: { user: User }) => {
    const {
        createTime,
        onlineTime,
        phone,
        sum30dPayments,
        sumClearPayments,
        firstName,
        lastName,
        balance,
        referralsCount,
        discount,
        firstPaymentGift,
        firstPaymentExpireTime
    } = user;

    return (
        <TableRow>
            <TableCell>
                <Stack>
                    <Time time={createTime} />
                    <span>online: {onlineTime ? <Time time={onlineTime} /> : '--'}</span>
                </Stack>
            </TableCell>
            <TableCell align="left">
                {firstName} {lastName}
            </TableCell>
            <TableCell>{phone}</TableCell>
            <TableCell>
                <Stack>
                    <span>
                        +{balance?.toLocaleString()} ₽ {discount > 0 && `(-${discount}%)`}
                    </span>
                    <span>
                        {sumClearPayments ? `${sumClearPayments.toLocaleString()} ₽` : '--'}&nbsp;
                        {Number(firstPaymentGift) > 0 && `(+${firstPaymentGift}%)`}
                    </span>
                </Stack>
            </TableCell>
            <TableCell>{referralsCount}</TableCell>
        </TableRow>
    );
};

const UserShowMode = ({ user_id }: { user_id: number }) => {
    const [gift, setGift] = useState(0);

    const handleGift = (event: SyntheticEvent, data: { value: number }) => {
        setGift(data.value);
    };

    const handleAddGift = async (): Promise<void> => {
        if (await userStore.addUserPayment(user_id, gift, 'gift')) {
            setGift(0);
        }
    };

    const handleDecreasePayment = async (): Promise<void> => {
        if (await userStore.addUserPayment(user_id, -gift, 'order')) {
            setGift(0);
        }
    };

    const handleAddPayment = async (): Promise<void> => {
        if (await userStore.addUserPayment(user_id, gift, 'order')) {
            setGift(0);
        }
    };

    const [discount, setDiscount] = useState(0);
    const handleDiscount = (event: SyntheticEvent, data: { value: number }) => {
        setDiscount(data.value);
    };

    const [type, setType] = useState<'discount' | 'firstPayment'>('discount');
    const handleChangeType = (event: SyntheticEvent, type: 'discount' | 'firstPayment' | null) => {
        if (type) {
            setType(type);
        }
    };

    const [expireDays, setExpireDays] = useState(7);
    const handleExpireDays = ({ value }: { value: number }) => {
        setExpireDays(value);
    };

    const handleAddDiscount = async (): Promise<void> => {
        if (await userStore.addUserDiscount(user_id, { discount, type, expireDays })) {
            setDiscount(0);
        }
    };

    const { errors, loadingItem, item, editingItem } = userStore.getItem(user_id);
    const { enableReferrals, disableWbAccountAfterRansom, deliveryManager } = editingItem;

    const changeEnableReferrals = () => {
        userStore.setEditingItem(user_id, { enableReferrals: !enableReferrals });
    };

    const changeDeliveryManager = () => {
        userStore.setEditingItem(user_id, { deliveryManager: !deliveryManager });
    };

    const changeDisableWbAccounts = () => {
        userStore.setEditingItem(user_id, { disableWbAccountAfterRansom: !disableWbAccountAfterRansom });
    };

    const handleSave = () => {
        userStore.saveItem(user_id);
    };

    return (
        <>
            <ListError errors={errors} />
            <Typography variant="h6">
                user_id_#{user_id} {item?.referrer_user_id && `(#${item?.referrer_user_id})`}
            </Typography>

            <Table elevation={0} compact>
                <TableHead>
                    <TableRow>
                        <TableCell>Дата</TableCell>
                        <TableCell>Имя</TableCell>
                        <TableCell>Телефон</TableCell>
                        <TableCell>Баланс</TableCell>
                        <TableCell>Рефералов</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{item && <UserTableRow user={item} />}</TableBody>
            </Table>

            <Grid columns={4}>
                <Grid.Column>
                    <PriceInput value={gift} title="Сумма" onChange={handleGift} />
                </Grid.Column>
                <Grid.Column>
                    <Button
                        disabled={!gift}
                        variant="outlined"
                        color="secondary"
                        loading={loadingItem}
                        onClick={handleAddGift}
                        fullWidth
                    >
                        Зачислить Подарок
                    </Button>
                </Grid.Column>
                <Grid.Column>
                    <Button
                        disabled={!gift}
                        variant="contained"
                        color="primary"
                        loading={loadingItem}
                        onClick={handleAddPayment}
                        fullWidth
                    >
                        Зачислить Платеж
                    </Button>
                </Grid.Column>
                <Grid.Column>
                    <Button
                        disabled={!gift}
                        variant="outlined"
                        color="error"
                        loading={loadingItem}
                        onClick={handleDecreasePayment}
                        fullWidth
                    >
                        Списать
                    </Button>
                </Grid.Column>
            </Grid>

            <Divider />

            <Grid columns={4}>
                <Grid.Column>
                    <ToggleButtonGroup
                        size="small"
                        color="primary"
                        value={type}
                        exclusive
                        onChange={handleChangeType}
                        fullWidth
                    >
                        <ToggleButton value="discount">Скидка</ToggleButton>
                        <ToggleButton value="firstPayment">Бонус</ToggleButton>
                    </ToggleButtonGroup>
                </Grid.Column>
                <Grid.Column>
                    <NumberInput value={discount} label="Процент, %" onChange={handleDiscount} />
                </Grid.Column>
                <Grid.Column>
                    <Select
                        fullWidth
                        variant="standard"
                        options={expireDaysOptions}
                        value={expireDays}
                        onChange={handleExpireDays}
                        label="Время исполнения"
                    />
                </Grid.Column>
                <Grid.Column>
                    <Button
                        variant="outlined"
                        disabled={!discount}
                        color="secondary"
                        loading={loadingItem}
                        onClick={handleAddDiscount}
                    >
                        Добавить
                    </Button>
                </Grid.Column>
            </Grid>

            <Divider />

            <Grid>
                <Grid.Column>
                    <Switch
                        label="Реферальная программа"
                        checked={enableReferrals}
                        onClick={changeEnableReferrals}
                        disabled={loadingItem}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Switch
                        label="Ответственный по доставкам"
                        checked={deliveryManager}
                        onClick={changeDeliveryManager}
                        disabled={loadingItem}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Switch
                        label="Убивать WB аккаунты после выкупа"
                        checked={disableWbAccountAfterRansom}
                        onClick={changeDisableWbAccounts}
                        disabled={loadingItem}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Button variant="contained" loading={loadingItem} onClick={handleSave}>
                        Сохранить
                    </Button>
                </Grid.Column>
            </Grid>

            {item && enableReferrals && (
                <>
                    <Divider />
                    <ReferralResetBalanceBtn user_id={user_id} />
                </>
            )}
        </>
    );
};

export default observer(UserShowMode);
