import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import authStore from 'stores/authStore';
import wbAccountStore from 'stores/wbAccountStore';

import Button from 'ui/Button';
import Grid from 'ui/Grid';
import { useToggleModal } from 'ui/Modal';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ProxiesDropdown from './ProxiesDropdown';
import AddProxyBox from './AddProxyBox';
import AddWbBox from './wb/AddWbBox';
import OpenWbBox from './wb/OpenWbBox';
import AddMarketGuruBox from './AddMarketGuruBox';
import ReviewsList from './ReviewsList';
import AddOzonBox from './ozon/AddOzonBox';
import OpenOzonBox from './ozon/OpenOzonBox';

const Electron = () => {
    const [showProxy, toggleProxy] = useToggleModal();
    const [showWB, toggleWB] = useToggleModal();
    const [showAccountsWb, toggleAccountsWb] = useToggleModal();
    const [showAccountsOzon, toggleAccountsOzon] = useToggleModal();
    const [showReviews, toggleReviews] = useToggleModal();
    const [showMG, toggleMG] = useToggleModal();
    const [showOzon, toggleOzon] = useToggleModal();

    useMemo(() => {
        const { hash } = document.location;
        const openWbAcc = hash.match(/#open_wb_acc=(\d+)*/);

        const urlDecoded = new URL(document.location.href);

        if (urlDecoded.searchParams.has('open_wb_acc')) {
            const open_wb_acc = urlDecoded.searchParams.get('open_wb_acc');
            const url = urlDecoded.searchParams.get('url');

            wbAccountStore.openAccountInElectron(Number(open_wb_acc), url || '').then(() => {
                setTimeout(() => {
                    window.close();
                }, 0);
            });
        } else if (urlDecoded.searchParams.has('set_wb_address')) {
            const set_wb_address = urlDecoded.searchParams.get('set_wb_address');

            wbAccountStore.setAddressInElectron(decodeURIComponent(set_wb_address || '')).then(() => {
                setTimeout(() => {
                    window.close();
                }, 0);
            });
        }
    }, []);

    if (!authStore.isAdmin()) {
        return null;
    }

    if (showProxy) {
        return <AddProxyBox onClose={toggleProxy} />;
    }
    if (showWB) {
        return (
            <>
                <ProxiesDropdown />
                <AddWbBox onClose={toggleWB} />
            </>
        );
    }
    if (showAccountsWb) {
        return (
            <>
                <ProxiesDropdown />
                <OpenWbBox onClose={toggleAccountsWb} />
            </>
        );
    }
    if (showReviews) {
        return (
            <>
                <ProxiesDropdown />
                <ReviewsList onClose={toggleReviews} />
            </>
        );
    }
    if (showMG) {
        return (
            <>
                <ProxiesDropdown />
                <AddMarketGuruBox onClose={toggleMG} />
            </>
        );
    }
    if (showOzon) {
        return (
            <>
                <ProxiesDropdown />
                <AddOzonBox onClose={toggleOzon} />
            </>
        );
    }
    if (showAccountsOzon) {
        return (
            <>
                <ProxiesDropdown />
                <OpenOzonBox onClose={toggleAccountsOzon} />
            </>
        );
    }

    return (
        <>
            <Grid>
                <Grid.Column>
                    <ProxiesDropdown />
                </Grid.Column>

                <Grid.Column>
                    <Button color="info" variant="outlined" onClick={toggleProxy} fullWidth>
                        Добавить Proxy
                    </Button>
                </Grid.Column>
            </Grid>

            <Paper elevation={2} sx={{ p: 1, mt: 2 }}>
                <Typography>Wildberries</Typography>
                <Grid disableGutter>
                    <Grid.Column>
                        <Button color="secondary" variant="contained" onClick={toggleWB} fullWidth>
                            Добавить Аккаунт WB
                        </Button>
                    </Grid.Column>

                    <Grid.Column>
                        <Button color="secondary" variant="outlined" onClick={toggleAccountsWb} fullWidth>
                            Открыть Аккаунт WB
                        </Button>
                    </Grid.Column>
                </Grid>
            </Paper>

            <Paper elevation={2} sx={{ p: 1, mt: 2 }}>
                <Typography>Ozon</Typography>
                <Grid disableGutter>
                    <Grid.Column>
                        <Button color="primary" variant="contained" onClick={toggleOzon} fullWidth>
                            Добавить Аккаунт Ozon
                        </Button>
                    </Grid.Column>

                    <Grid.Column>
                        <Button color="primary" variant="outlined" onClick={toggleAccountsOzon} fullWidth>
                            Открыть Аккаунт Ozon
                        </Button>
                    </Grid.Column>
                </Grid>
            </Paper>

            <Grid>
                <Grid.Column>
                    <Button color="info" variant="contained" onClick={toggleMG} fullWidth>
                        Добавить Аккаунт Market Guru
                    </Button>
                </Grid.Column>

                <Grid.Column>
                    <Button color="error" variant="text" onClick={toggleReviews} fullWidth>
                        Отзывы на модерации
                    </Button>
                </Grid.Column>
            </Grid>
        </>
    );
};

export default observer(Electron);
