import fetchApi from 'common/fetchApi';
import { objectToGraphql } from '../common/graphql';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { FeedbackReaction } from 'stores/feedbackReactionStore';
import { GQProductListFields } from './productApi';

export const createItem = async (feedbackReaction: Partial<FeedbackReaction>): Promise<number> => {
    const graphql = `mutation { 
          createFeedbackReaction(feedbackReaction: ${objectToGraphql(feedbackReaction)})
        }`;

    const data = await fetchApi.postGQ<{ createFeedbackReaction: number }>(graphql);
    return data.createFeedbackReaction;
};

const GQFeedbacksReactionFields = `
    feedback_reaction_id
    user_id
    feedback_id

    type
    claim
    comment

    gender
    searchRequest
    
    quantity
    speed
    
    product_id
    product {
        ${GQProductListFields}
    }
    text

    status
    errorMessage
    createTime
    updateTime
    enable
`;

export const fetchItem = async (feedback_reaction_id: number): Promise<FeedbackReaction> => {
    const graphql = `{ 
          fetchFeedbackReaction(feedback_reaction_id: ${feedback_reaction_id}) {
            ${GQFeedbacksReactionFields}
           }
        }`;

    const data = await fetchApi.getGQ<{ fetchFeedbackReaction: FeedbackReaction }>(graphql);
    return data.fetchFeedbackReaction;
};

export const saveItem = async (
    feedback_reaction_id: number,
    feedbackReactionDiff: Partial<FeedbackReaction>
): Promise<number> => {
    const graphql = `mutation { 
          updateFeedbackReaction(feedback_reaction_id: ${feedback_reaction_id}, feedbackReactionDiff: ${objectToGraphql(
        feedbackReactionDiff
    )}) 
        }`;

    const data = await fetchApi.postGQ<{ updateFeedbackReaction: number }>(graphql);
    return data.updateFeedbackReaction;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    feedbackReactionFilter: {},
    controller?: AbortController
): Promise<{ list: FeedbackReaction[]; count: number }> => {
    const graphql = `{
            fetchFeedbacksReactionsList(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}") {
                list {
                    ${GQFeedbacksReactionFields}
                }
                count
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchFeedbacksReactionsList: { list: FeedbackReaction[]; count: number } }>(
        graphql
    );
    return data.fetchFeedbacksReactionsList;
};
