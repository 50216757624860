import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import productStore, { Product } from 'stores/productStore';
import brandStore from 'stores/brandStore';
import videoStore from 'stores/videoStore';

import ListError from 'ui/ListError';
import LoaderAwait from 'ui/LoaderAwait';
import Grid from 'ui/Grid';
import Progress from 'ui/Progress';
import { InfoMessage, WarningMessage } from 'ui/Message';

import Container from '@mui/material/Container';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';

import ProductCard from '../Product/ProductCard';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Typography from '@mui/material/Typography';

import BrandInfoBlock from './BrandInfoBlock';
import StoreRequestError from '../StoreRequestError';

const BrandShowMode = ({ brand_id }: { brand_id: number }) => {
    const { item, errors, loadingItem } = brandStore.getItem(brand_id);
    const { status, product_id, searchRequest, errorMessage, quantity } = item || {};

    const [fetchInterval, setFetchInterval] = useState<NodeJS.Timeout | null>(null);
    useEffect(() => {
        return () => {
            if (fetchInterval) {
                clearInterval(fetchInterval);
            }
        };
    }, [fetchInterval]);

    useEffect(() => {
        if (status === 1) {
            const fetchInterval = setInterval(() => {
                brandStore.fetchItem(brand_id);
            }, 1000);
            setFetchInterval(fetchInterval);
        } else if (fetchInterval) {
            clearInterval(fetchInterval);
            setFetchInterval(null);
        }
        videoStore.findWbVideo('brand', brand_id);
    }, [status, brand_id]);

    useEffect(() => {
        if (item?.product_id) {
            productStore.fetchItem(item?.product_id);
        }
    }, [item?.product_id]);

    if (!item) {
        return null;
    }

    let product: Product | null = null;
    if (product_id) {
        try {
            const productItem = productStore.getItem(product_id);
            product = productItem?.item || null;
        } catch (err) {}
    }

    // const refreshWbTime = async () => {
    //     await brandStore.refreshUpdateWbBrand(brand_id);
    // };

    const shortItem = product ? productStore.calcShortProduct(product) : null;

    return (
        <Container maxWidth="sm">
            <ListError errors={errors} />
            <LoaderAwait active={loadingItem} />
            <Grid>
                {status === 100 && (
                    <Grid.Column>
                        <StoreRequestError error={errorMessage} store_id={product?.store_id} />
                        {/*<Button*/}
                        {/*    endIcon={<CachedIcon />}*/}
                        {/*    color="error"*/}
                        {/*    fullWidth*/}
                        {/*    onClick={refreshWbTime}*/}
                        {/*    disabled={loadingItem}*/}
                        {/*>*/}
                        {/*    Попробовать еще раз*/}
                        {/*</Button>*/}
                    </Grid.Column>
                )}

                {!loadingItem && (
                    <Grid.Column>
                        {status === 1 && (
                            <>
                                <WarningMessage header="Статус">Выполняется добавление</WarningMessage>
                                <Progress show linear />
                            </>
                        )}
                        {status === 2 && <InfoMessage header="Статус">Бренд добавлен в избранное</InfoMessage>}
                        {Number(quantity) > 1 && (
                            <Typography>
                                Количество: <b>{quantity}</b>
                            </Typography>
                        )}
                    </Grid.Column>
                )}
                {shortItem && product && (
                    <Grid.Column>
                        <Typography variant="subtitle1">Бренд</Typography>
                        <BrandInfoBlock brand={shortItem.brand} store_id={product.store_id} />
                    </Grid.Column>
                )}
                <Grid.Column>
                    <ProductCard product={product} />
                </Grid.Column>
                <Grid.Column>
                    <List dense disablePadding>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <ManageSearchIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={searchRequest} />
                        </ListItem>
                    </List>
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default observer(BrandShowMode);
