import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import locationHistory from 'locationHistory';

import brandStore from 'stores/brandStore';

import Table, { TableHead, TableRow, TableCell, TableBody } from 'ui/Table';
import Time from 'ui/Time';
import Button from 'ui/Button';
import { WarningMessage } from 'ui/Message';

import Chip from '@mui/material/Chip';

import ListWrapper from '../ListWrapper';
import { BrandLinkState } from 'components/Items/Brand/BrandLink';
import ListPagination from '../ListPagination';
import ProductTitleLink from '../Products/ProductTitleLink';

import BrandTitleCell from './BrandTitleCell';
import BrandsFilter from './BrandsFilter';

const BrandsList = () => {
    const { list, loadingList, handleSort, adjustDirection } = brandStore;

    return (
        <ListWrapper store={brandStore} bgUrl="/static/listbg/unruly-18-finance.webp">
            <BrandsFilter />

            <Table elevation={0} loading={loadingList}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sortDirection={adjustDirection('createTime')}
                            onClick={() => handleSort('createTime')}
                        >
                            Дата
                        </TableCell>
                        <TableCell sortDirection={adjustDirection('status')} onClick={() => handleSort('status')}>
                            Статус
                        </TableCell>
                        <TableCell>Бренд</TableCell>
                        <TableCell>Количество</TableCell>
                        <TableCell>Продукт</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(({ brand_id, quantity, product, createTime, status }) => (
                        <TableRow key={brand_id}>
                            <TableCell>
                                <Time time={createTime} />
                            </TableCell>
                            <TableCell>
                                <Button
                                    onClick={() => locationHistory.push(BrandLinkState(brand_id))}
                                    tooltip="Открыть карточку вопроса"
                                >
                                    {status === 1 && (
                                        <Chip label="Добавляется" color="warning" sx={{ cursor: 'pointer' }} />
                                    )}
                                    {status === 2 && (
                                        <Chip label="Добавлен" color="success" sx={{ cursor: 'pointer' }} />
                                    )}
                                    {status === 100 && <Chip label="Ошибка" color="error" sx={{ cursor: 'pointer' }} />}
                                </Button>
                            </TableCell>
                            <TableCell>
                                <BrandTitleCell product={product} />
                            </TableCell>
                            <TableCell align="center">{quantity}</TableCell>
                            <TableCell>
                                <ProductTitleLink product={product} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <ListPagination
                    pagination={brandStore.pagination}
                    listCount={brandStore.listCount}
                    pageChange={brandStore.pageChange}
                    pageSizeChange={brandStore.pageSizeChange}
                    colSpan={4}
                />
            </Table>

            {!loadingList && list.length === 0 && (
                <WarningMessage header="Вы еще не добавили ни один бренд в избранное">
                    Вы можете добавить бренд в избранное вашего <Link to="/products/list">товара</Link> в карточке
                    товара. После чего он появится тут.
                </WarningMessage>
            )}
        </ListWrapper>
    );
};

export default observer(BrandsList);
