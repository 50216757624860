import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import autoAnswerStore from 'stores/autoAnswerStore';
import commonStore from 'stores/commonStore';

import ListWrapper from '../ListWrapper';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import SettingsIcon from '@mui/icons-material/Settings';
import TelegramIcon from '@mui/icons-material/Telegram';
import Paper from '@mui/material/Paper';

import Grid from 'ui/Grid';
import Divider from 'ui/Divider';
import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';

import AutoAnswersList from './AutoAnswersList';
import AutoAnswersConfigModal from './AutoAnswersConfigModal';
import AutoAnswersFeedbacks from './AutoAnswersFeedbacks';
import AutoAnswersInit from './initInfo/AutoAnswersInit';
import AutoAnswersDamage from './initInfo/AutoAnswersDamage';
import ListContainer from '../ListContainer';

const AutoAnswers = () => {
    return (
        <ListContainer bgUrl="/static/listbg/unruly-12-no-messages.webp">
            <Container maxWidth="md">
                <Paper elevation={0} sx={{ p: 2 }}>
                    <AutoAnswersDamage />
                </Paper>
            </Container>
        </ListContainer>
    );

    // const { config } = autoAnswerStore;
    // const [showConfig, toggleConfig] = useToggleModal();

    // useMemo(() => {
    //     autoAnswerStore.pullConfig();
    // }, []);
    //
    // if (!config) {
    //     return (
    //         <ListContainer bgUrl="/static/listbg/unruly-12-no-messages.webp">
    //             <Container maxWidth="md">
    //                 <Paper elevation={0} sx={{ p: 2 }}>
    //                     <AutoAnswersInit />
    //                 </Paper>
    //             </Container>
    //         </ListContainer>
    //     );
    // }
    //
    // return (
    //     <ListWrapper store={autoAnswerStore} bgUrl="/static/listbg/unruly-12-no-messages.webp">
    //         <Grid alignItems="flex-start">
    //             <Grid.Column width={commonStore.isMobile ? 12 : 8}>
    //                 <Box sx={{ position: 'relative' }}>
    //                     <AutoAnswersFeedbacks />
    //                 </Box>
    //             </Grid.Column>
    //             <Grid.Column width={commonStore.isMobile ? 12 : 4}>
    //                 <Box sx={{ position: 'relative', p: commonStore.isMobile ? 2 : 0 }}>
    //                     <Button
    //                         tooltip="Настроить уведомления в Телеграм об отзывах / вопросах"
    //                         startIcon={<SettingsIcon />}
    //                         endIcon={<TelegramIcon />}
    //                         variant="outlined"
    //                         fullWidth
    //                         color="secondary"
    //                         onClick={toggleConfig}
    //                     >
    //                         Уведомления в ТГ
    //                     </Button>
    //                     {showConfig && <AutoAnswersConfigModal onClose={toggleConfig} />}
    //                     <Divider />
    //                     <AutoAnswersList />
    //                 </Box>
    //             </Grid.Column>
    //         </Grid>
    //     </ListWrapper>
    // );
};

export default observer(AutoAnswers);
