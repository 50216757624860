import fetchApi from 'common/fetchApi';

import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { WbStatisticsApiType } from 'stores/wbApi/wbStatisticsApiStore';
import { GQWBItemShort } from '../productApi';

const GQWbApiSale = `
    odid
    gNumber
    incomeID
    saleID
    IsStorno
    nmId

    subject
    category
    brand
    supplierArticle
    techSize
    barcode

    warehouseName
    countryName
    oblastOkrugName
    regionName

    totalPrice
    discountPercent
    isSupply
    isRealization
    promoCodeDiscount

    spp
    forPay
    finishedPrice
    priceWithDisc

    sticker

    date
    lastChangeDate
`;

const GQWbApiOrder = `
    odid
    gNumber
    incomeID
    nmId

    subject
    category
    brand
    supplierArticle
    techSize
    barcode

    warehouseName
    oblast

    totalPrice
    discountPercent
    isCancel

    sticker
    
    date
    lastChangeDate
    cancel_dt
`;

const GQWbApiRealization = `
    rrd_id
    rid

    realizationreport_id
    suppliercontract_code
    gi_id
    subject_name
    nm_id
    brand_name
    sa_name
    ts_name
    barcode
    doc_type_name
    quantity
    retail_price
    retail_amount
    sale_percent
    commission_percent
    office_name
    supplier_oper_name

    shk_id
    retail_price_withdisc_rub
    delivery_amount
    return_amount
    delivery_rub
    gi_box_type_name
    product_discount_for_report
    supplier_promo

    ppvz_spp_prc
    ppvz_kvw_prc_base
    ppvz_kvw_prc
    ppvz_sales_commission
    ppvz_for_pay
    ppvz_reward
    ppvz_vw
    ppvz_vw_nds
    ppvz_office_id
    ppvz_office_name
    ppvz_supplier_id
    ppvz_supplier_name
    ppvz_inn

    bonus_type_name
    declaration_number
    sticker_id
    site_country
    
    penalty
    additional_payment
    
    order_dt
    sale_dt
    rr_dt
`;

const WbStatisticsApiGQ = `
    odid
    
    wb_item_id
    wbItem {
        ${GQWBItemShort}
    }
    
    sale {
        ${GQWbApiSale}
    }
    order {
        ${GQWbApiOrder}
    }
    realizations {
        ${GQWbApiRealization}
    }   
    
    createTime
    updateTime 
`;

export const fetchItem = async (odid: number): Promise<WbStatisticsApiType> => {
    const graphql = `{ 
          fetchWbStatisticsApiItem(odid: ${odid}) {
            ${WbStatisticsApiGQ}
           }
        }`;

    const data = await fetchApi.getGQ<{ fetchWbStatisticsApiItem: WbStatisticsApiType }>(graphql);
    return data.fetchWbStatisticsApiItem;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    wbSalesApiFilter: {},
    controller?: AbortController
): Promise<{ list: WbStatisticsApiType[]; count: number }> => {
    const graphql = ` { 
          fetchApiStatisticsList(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}") {
                list {
                    ${WbStatisticsApiGQ}    
                }
                count
          }
        }`;
    const data = await fetchApi.postGQ<{ fetchApiStatisticsList: { list: WbStatisticsApiType[]; count: number } }>(
        graphql,
        controller
    );
    return data.fetchApiStatisticsList;
};
