import { action, makeObservable, observable, computed } from 'mobx';
import * as productApi from 'api/productApi';
import ListStorePrototype, { OrderDirectionType } from './prototypes/ListStore.prototype';
import { CREATING_ITEM_ID } from './prototypes/ItemStore.prototype';
import {
    FeedbacksOrderBy,
    isFeedbackSimilarType,
    isFeedbackType,
    WbItemFeedback,
    WbSimilarFeedback
} from 'api/productApi';
import wait from 'common/wait';

export enum StoreIds {
    WB = 1,
    Ozon
}

export interface ProductPrototypeFilter {
    status: number | null;
    store_id: StoreIds;
    product_id: number | null;
    allUsers: boolean;
    enable: boolean;
}

export type DropdownType = {
    key?: number | string;
    value: number | string | boolean | null;
    text: string;
};

type ProductInBasePrepare = {
    product_id: number;
    user_id: number;
    store_item_id: number;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type WbSize = {
    wb_size_id: number;
    isSoldOut: boolean;
    sizeName: string;
    sizeNameRus: string;
};

export type WbItem = {
    wb_item_id: number;
    title: string;
    description: string;
    consist: string;
    wb_brand_id: number;

    ordersCount: number | null;
    questionsCount: number | null;
    feedbacksCount: number | null;
    star: number | null;

    photos: string[];

    price?: {
        price: number;
        priceDif: boolean;
        priceWithSale: number;
        sale: number;
    };

    sizes: WbSize[];

    brand: {
        name: string;
        brandUrl: string | null;
        logoUrl: string;
    };

    createTime: number;
    updateTime: number;
};

export type ItemPrice = { price: number; priceWithSale: number };

export type WbItemExtended = WbItem & {
    store_id: StoreIds.WB;
    options: {
        property: string;
        subProperty: string;
    }[];
    tags: string[];
};

type ProductListWb = ProductInBasePrepare & {
    store_id: StoreIds.WB;
    wbItem: WbItem;
};

type ProductListOzon = ProductInBasePrepare & {
    store_id: StoreIds.Ozon;
    ozonItem: OzonItemShortType;
};

export type ProductList = ProductListWb | ProductListOzon;

export const whetherProductListIsOzon = (productItem: ProductList): productItem is ProductListOzon => {
    return productItem.store_id === StoreIds.Ozon;
};

export type WbItemColorType = {
    color_id: number;
    color: string;
};

export type ProductItemColor = {
    wb_item_id: number;
    colors: WbItemColorType[];
};

export type ProductWB = ProductInBasePrepare & {
    store_id: StoreIds.WB;
    wbItem: WbItemExtended;
    colors: ProductItemColor[];
};

export type ProductOzon = ProductInBasePrepare & {
    store_id: StoreIds.Ozon;
    ozonItem: OzonItemShortType;
};

export type Product = ProductWB | ProductOzon;

export const whetherProductIsWB = (product: Product): product is ProductWB => {
    return product.store_id === StoreIds.WB;
};

export const whetherProductIsOzon = (product: Product): product is ProductOzon => {
    return product.store_id === StoreIds.Ozon;
};

export type WB30dShortStat = {
    sold30dQty: number;
    sold30dAmount: number;
};

export type FeedbacksFilterPult = {
    loading: boolean;
    hasMore: boolean;
    errors: string[];
    orderBy: FeedbacksOrderBy;
    orderDirection: OrderDirectionType;
    offset: number;
    limit: number;
};

export type WBSimilarProduct = {
    wb_item_id: number;
    title: string;
    brandName: string;
    sold30dQty: number | null;
    sold30dAmount: number | null;
};

type OzonItemInBaseType = {
    ozon_item_id: number;
    title: string;
    description: string;
    itemUrl: string;
    ozon_brand_id: number;
    feedbacksCount: number;
    star: number;
    updateTime: number;
};

export type OzonBrandType = {
    ozon_brand_id: number;
    ozon_seller_id: number;
    name: string;
    brandUrl: string | null;
};

type OzonItemPriceType = {
    price_id: number;
    ozon_item_id: number;
    price: number;
    priceWithSale: number;
    sale: number;
};

export type OzonItemAspectType = {
    index: number;
    aspect: 'Цвет' | 'Размер';
    aspectValue: string;
};

export type OzonItemShortType = OzonItemInBaseType & {
    photos: string[];
    brand: OzonBrandType;
    price: OzonItemPriceType;
    aspects: [OzonItemAspectType];
};

export const whetherItemIsWb = (item: OzonItemShortType | WbItem): item is WbItem => {
    return 'wb_item_id' in item;
};

type ProductProperty = {
    loadingStatistics: boolean;
    wbItemIds: number[];

    feedbacksPult: FeedbacksFilterPult;

    loadingSimilarProducts: boolean;
    similarProducts: WBSimilarProduct[];
} & (
    | {
          feedbacksType: 'main';
          feedbacks: WbItemFeedback[];
      }
    | {
          feedbacksType: 'similar';
          feedbacks: WbSimilarFeedback[];
      }
);

export type ProductShortItemBrand = {
    name: string;
    brandUrl: string | null;
    logoUrl?: string;
};

type ProductShortItem = {
    title: string;
    titleLong: string;
    description: string;
    star: number | null;
    ordersCount: number | null;
    feedbacksCount: number | null;
    updateTime: number;

    photos: string[];
    price: ItemPrice | null;
    brand: ProductShortItemBrand;
};

export type ProductsFilter = {
    store_id: StoreIds;
};

export const FEEDBACKS_OFFSET_COUNT = 20;

const DEFAULT_STORE_MEMKEY = 'defaultStoreId';

class ProductStore extends ListStorePrototype<Product, ProductList, ProductProperty, ProductsFilter> {
    constructor() {
        super('product_id', 'product', productApi);
        makeObservable(this);

        const defaultStoreId = localStorage.getItem(DEFAULT_STORE_MEMKEY);
        if (defaultStoreId) {
            this.listFilterClear.store_id = Number(defaultStoreId);
        }

        this.clearFilter();
    }

    listFilterClear = {
        store_id: StoreIds.WB
    };

    changeFilter<T extends keyof ProductsFilter>(what: T, value: ProductsFilter[T]) {
        super.changeFilter(what, value);
        if (what === 'store_id') {
            localStorage.setItem(DEFAULT_STORE_MEMKEY, String(value));
        }
    }

    @action
    async createItemFromDropdown(store_item_id: number): Promise<number> {
        this.fetchItem(CREATING_ITEM_ID);
        const { store_id } = this.listFilter;
        if (store_id) {
            this.setEditingItem(CREATING_ITEM_ID, { store_item_id, store_id });
        }
        return await this.createItem();
    }

    @action
    async refreshUpdateStoreProduct(product_id: number): Promise<void> {
        try {
            this.getItem(product_id).loadingItem = true;
            await productApi.refreshUpdateStoreProduct(product_id);
            await this.fetchItem(product_id);
        } catch (errors) {
            if (errors instanceof Array) {
                this.getItem(product_id).errors = errors;
                this.getItem(product_id).loadingItem = false;
            }
        }
    }

    @observable
    wbShortStats: Map<number, WB30dShortStat | null> = new Map();

    getStatItem(wb_item_id: number): WB30dShortStat | null {
        const item = this.wbShortStats.get(wb_item_id);
        if (!item) {
            return null;
        }
        return item;
    }

    @action
    async fetchShortStat(wb_item_id: number): Promise<void> {
        this.wbShortStats.set(wb_item_id, null);
        try {
            const stat = await productApi.fetchWB30dShortStatistics(wb_item_id);
            this.wbShortStats.set(wb_item_id, stat);
        } catch (error) {
            console.log(error);
            this.wbShortStats.set(wb_item_id, { sold30dQty: 0, sold30dAmount: 0 });
        }
    }

    @action
    async fetchTopItemsStat(product_id: number, wbItemIds: number[]): Promise<void> {
        this.setProperty(product_id, { loadingStatistics: true, wbItemIds });

        const promises: Promise<unknown>[] = [];

        let index = 0;
        for (const wb_item_id of wbItemIds) {
            const promise = new Promise(async resolve => {
                await wait(index * 1000);
                await this.fetchShortStat(wb_item_id);
                resolve(null);
            });
            promises.push(promise);
            index++;
        }

        // await Promise.allSettled(wbItemIds.map(wb_item_id => this.fetchShortStat(wb_item_id)));
        await Promise.allSettled(promises);
        this.setProperty(product_id, { loadingStatistics: false });
    }

    @action
    async fetchProductWbFeedbacks(
        product_id: number,
        feedbacksType: 'main' | 'similar',
        orderBy: FeedbacksOrderBy,
        sort: OrderDirectionType,
        page: number
    ): Promise<void> {
        const feedbacksPult: FeedbacksFilterPult = {
            loading: true,
            errors: [],
            orderBy,
            orderDirection: sort,
            limit: FEEDBACKS_OFFSET_COUNT,
            offset: page * FEEDBACKS_OFFSET_COUNT,
            hasMore: true
        };

        let { feedbacks } = this.getItem(product_id).property;
        feedbacks = page === 0 || !feedbacks ? [] : feedbacks;

        this.setProperty(product_id, {
            feedbacksType,
            feedbacksPult
        });
        if (page === 0 || !feedbacks) {
            this.setProperty(product_id, { feedbacks: [] });
        }

        try {
            if (feedbacksType === 'main') {
                const newFeedbacks = await productApi.fetchProductWbFeedbacks(
                    product_id,
                    orderBy,
                    sort,
                    feedbacksPult.offset,
                    feedbacksPult.limit
                );

                if (feedbacks.length > 0 && isFeedbackType(feedbacks)) {
                    this.setProperty(product_id, { feedbacksType, feedbacks: [...feedbacks, ...newFeedbacks] });
                } else {
                    this.setProperty(product_id, { feedbacksType, feedbacks: [...newFeedbacks] });
                }

                if (newFeedbacks.length < FEEDBACKS_OFFSET_COUNT) {
                    const { feedbacksPult } = this.getItem(product_id).property;
                    if (feedbacksPult) {
                        this.setProperty(product_id, {
                            feedbacksPult: {
                                ...feedbacksPult,
                                hasMore: false
                            }
                        });
                    }
                }
            } else {
                const newFeedbacks = await productApi.fetchSimilarWbFeedbacks(
                    product_id,
                    orderBy,
                    sort,
                    feedbacksPult.offset,
                    feedbacksPult.limit
                );

                if (feedbacks.length > 0 && isFeedbackSimilarType(feedbacks)) {
                    this.setProperty(product_id, { feedbacksType, feedbacks: [...feedbacks, ...newFeedbacks] });
                } else {
                    this.setProperty(product_id, { feedbacksType, feedbacks: [...newFeedbacks] });
                }

                if (newFeedbacks.length < FEEDBACKS_OFFSET_COUNT) {
                    const { feedbacksPult } = this.getItem(product_id).property;
                    if (feedbacksPult) {
                        this.setProperty(product_id, {
                            feedbacksPult: {
                                ...feedbacksPult,
                                hasMore: false
                            }
                        });
                    }
                }
            }
        } catch (errors) {
            if (errors instanceof Array) {
                const { feedbacksPult } = this.getItem(product_id).property;
                if (feedbacksPult) {
                    this.setProperty(product_id, {
                        feedbacksPult: {
                            ...feedbacksPult,
                            errors
                        }
                    });
                }
            }
        } finally {
            const { feedbacksPult } = this.getItem(product_id).property;
            if (feedbacksPult) {
                this.setProperty(product_id, {
                    feedbacksPult: {
                        ...feedbacksPult,
                        loading: false
                    }
                });
            }
        }
    }

    @computed get productOptions(): DropdownType[] {
        return this.list.map(product => {
            const { product_id, store_item_id } = product;
            let { titleLong } = this.calcShortProduct(product);

            return {
                value: product_id,
                text: `№${store_item_id}: ${titleLong}`
            };
        });
    }

    @action
    async fetchNextFeedbacks(product_id: number, page: number): Promise<void> {
        const { feedbacksPult, feedbacksType, feedbacks } = this.getItem(product_id).property;
        if (!feedbacksPult || !feedbacks || !feedbacksType) {
            return;
        }
        let { orderBy, orderDirection } = feedbacksPult;
        this.fetchProductWbFeedbacks(product_id, feedbacksType, orderBy, orderDirection, page);
    }

    @action
    async fetchSimilarProducts(product_id: number): Promise<void> {
        const { store_item_id } = this.getItem(product_id).item || { store_item_id: 0 };

        this.setProperty(product_id, { loadingSimilarProducts: true });
        const similarProducts = await productApi.fetchSimilarProducts(store_item_id);
        this.setProperty(product_id, { loadingSimilarProducts: false, similarProducts });
    }

    whetherProductHasSizes(product_id: number): boolean {
        const product = this.getItem(product_id).item;
        if (product && product.store_id === StoreIds.WB) {
            return product?.wbItem?.sizes?.length > 0;
        }
        return false;
    }

    calcShortProduct(productItem: ProductList): ProductShortItem {
        const { store_id } = productItem;

        let title = '';
        let titleLong = '';
        let description = '';
        let photos: string[] = [];
        let price: ItemPrice | null = null;
        let brand: {
            name: string;
            brandUrl: string | null;
            logoUrl?: string;
        } = { name: '', brandUrl: '' };
        let updateTime = 0;

        let star: number | null = null;
        let ordersCount: number | null = null;
        let feedbacksCount: number | null = null;

        switch (store_id) {
            case StoreIds.WB:
                const { wbItem } = productItem;
                const { price: wbPrice, brand: wbBrand } = wbItem;
                [title, description, photos, price, brand, updateTime, star, ordersCount, feedbacksCount] = [
                    wbItem.title,
                    wbItem.description,
                    wbItem.photos,
                    wbPrice ? { price: wbPrice.price, priceWithSale: wbPrice.priceWithSale } : null,
                    {
                        brandUrl: wbBrand.brandUrl || null,
                        name: wbBrand.name,
                        logoUrl: wbBrand.logoUrl
                    },
                    wbItem.updateTime,
                    wbItem.star,
                    wbItem.ordersCount,
                    wbItem.feedbacksCount
                ];

                titleLong = title;
                break;
            case StoreIds.Ozon:
                const { ozonItem } = productItem;
                const { price: ozonPrice, brand: ozonBrand } = ozonItem;
                [title, description, photos, price, brand, updateTime, star, feedbacksCount] = [
                    ozonItem.title,
                    ozonItem.description,
                    ozonItem.photos,
                    ozonPrice ? { price: ozonPrice.price, priceWithSale: ozonPrice.priceWithSale } : null,
                    {
                        brandUrl: ozonBrand?.brandUrl || null,
                        name: ozonBrand?.name || ''
                    },
                    ozonItem.updateTime,
                    ozonItem.star,
                    ozonItem.feedbacksCount
                ];

                titleLong = title;

                const { aspects } = ozonItem;
                if (aspects.length) {
                    const foundColor = aspects.find(({ aspect }) => aspect === 'Цвет');
                    const foundSize = aspects.find(({ aspect }) => aspect === 'Размер');

                    if (foundColor || foundSize) {
                        titleLong += ` (${foundColor && `цвет: ${foundColor.aspectValue}${foundSize && '; '}`}${
                            foundSize && `${foundSize.aspectValue}`
                        })`;
                    }
                }

                break;
            default:
                const error: never = store_id;
        }
        return {
            title,
            titleLong,
            description,
            star,
            ordersCount,
            feedbacksCount,
            photos,
            price,
            brand,
            updateTime
        };
    }

    getCurrentStoreId(): StoreIds {
        return this.listFilter.store_id || StoreIds.WB;
    }
}

export default new ProductStore();
