import React, { SyntheticEvent, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';

import wbAccountStore from 'stores/wbAccountStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import Grid from 'ui/Grid';
import Button from 'ui/Button';
import TextInput from 'ui/TextInput';
import ListError from 'ui/ListError';
import { SuccessMessage } from 'ui/Message';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const phoneRegExp = new RegExp(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/);

let onMessage: (event: { data: { operation: string; cookie: string } }) => void;

const openWb = () => {
    window.postMessage({
        operation: 'openWb'
    });
};

const checkCookie = () => {
    window.postMessage({
        operation: 'checkCookie'
    });
};

const AddWbBox = ({ onClose }: { onClose: () => void }) => {
    useMemo(() => {
        wbAccountStore.fetchItem(CREATING_ITEM_ID);
        wbAccountStore.setEditingItem(CREATING_ITEM_ID, {
            phone: '',
            WILDAUTHNEW_V3: ''
        });
    }, []);

    onMessage = ({ data }) => {
        console.log('onmessage', data);
        if (data.operation === 'WILDAUTHNEW_V3') {
            wbAccountStore.setEditingItem(CREATING_ITEM_ID, { WILDAUTHNEW_V3: data.cookie });
        }
    };

    useEffect(() => {
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, []);

    const handleSave = async () => {
        if (await wbAccountStore.createItem()) {
            wbAccountStore.fetchList();
            onClose();
        }
    };

    const handleChangePhone = (event: SyntheticEvent, { value }: { value: string }) => {
        wbAccountStore.setEditingItem(CREATING_ITEM_ID, { phone: value.replace(/\D/gi, '') });
    };

    const handleChaneGender = (event: SyntheticEvent, gender: 'male' | 'female') => {
        wbAccountStore.setEditingItem(CREATING_ITEM_ID, { gender });
    };

    const {
        editingItem: { phone, WILDAUTHNEW_V3, gender },
        loadingItem,
        errors
    } = wbAccountStore.getItem(CREATING_ITEM_ID);

    return (
        <Grid>
            <Grid.Column>
                <Button variant="text" color="inherit" startIcon={<ArrowBackIcon />} onClick={onClose}>
                    назад
                </Button>
            </Grid.Column>
            <Grid.Column>
                <Button color="secondary" variant="outlined" onClick={openWb} fullWidth>
                    Открыть "чистый" WB
                </Button>
            </Grid.Column>
            <Grid.Column>
                <ListError errors={errors} />
                <TextInput
                    label="Телефон"
                    required
                    onChange={handleChangePhone}
                    name="phone"
                    value={phone}
                    size="small"
                    variant="outlined"
                    mask="+7 (000) 000-00-00"
                    pattern={phoneRegExp}
                />
            </Grid.Column>
            <Grid.Column>
                <ToggleButtonGroup size="small" color="secondary" value={gender} exclusive onChange={handleChaneGender}>
                    <ToggleButton value="female">Женщина</ToggleButton>
                    <ToggleButton value="male">Мужчина</ToggleButton>
                </ToggleButtonGroup>
            </Grid.Column>

            <Grid.Column>
                <Button color="info" variant="outlined" fullWidth onClick={checkCookie}>
                    Получить КУКУ
                </Button>
                {WILDAUTHNEW_V3 && <SuccessMessage>Кука получена</SuccessMessage>}
            </Grid.Column>

            <Grid.Column>
                <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={!WILDAUTHNEW_V3}
                    loading={loadingItem}
                    onClick={handleSave}
                >
                    Сохранить
                </Button>
            </Grid.Column>
        </Grid>
    );
};

export default observer(AddWbBox);
