import * as referralLinkApi from 'api/referralLinkApi';
import ListStorePrototype from './prototypes/ListStore.prototype';

export type ReferralLink = {
    link_id: number;
    code: string;
    user_id: number;
    comment: string;
    createTime: number;
    updateTime: number;
    paymentsResetTime: number | null;

    linkVisited: number;
    userRegistered: number;
    totalPayments: number;

    enable: boolean;
};

class ReferralLinkStore extends ListStorePrototype<ReferralLink, ReferralLink> {
    listFilterClear = {};

    constructor() {
        super('link_id', 'admin', referralLinkApi);
    }
}

export default new ReferralLinkStore();
