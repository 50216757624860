import React from 'react';
import { observer } from 'mobx-react';

import * as userApi from 'api/userApi';
import deliveryStore from 'stores/deliveryStore';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';
import NavigationIcon from '@mui/icons-material/Navigation';
import Stack from '@mui/material/Stack';

import Button from 'ui/Button';

const DeliveryDataViewToggle = () => {
    const { dataSetType, list } = deliveryStore;
    const toggleView = () => {
        deliveryStore.toggleDataSetType();
        userApi.sendUserAction({ DeliveryDataViewToggle: deliveryStore.dataSetType });
    };

    let rtext = list
        .map(
            ({
                ransom: {
                    deliveryStorePoint: { geo_lon, geo_lat }
                }
            }) => `${geo_lat},${geo_lon}`
        )
        .join('~');

    return (
        <Stack direction="row" gap={3} sx={{ flex: 1 }}>
            <ToggleButtonGroup
                size="small"
                value={dataSetType}
                exclusive
                onChange={toggleView}
                color="secondary"
                fullWidth
            >
                <ToggleButton value={'list'}>
                    <ListIcon />
                    Таблица
                </ToggleButton>
                <ToggleButton value={'map'}>
                    <MapIcon /> Карта
                </ToggleButton>
            </ToggleButtonGroup>
            <Button
                icon={<NavigationIcon />}
                target="_blank"
                href={`https://maps.yandex.ru/?rtext=${rtext}&rtt=auto`}
                tooltip="Открыть на Яндекс.Картах"
            />
        </Stack>
    );
};

export default observer(DeliveryDataViewToggle);
