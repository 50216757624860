import React, {SyntheticEvent, useEffect} from 'react';
import { observer } from 'mobx-react';

import authStore from 'stores/authStore';
import ransomStore, { Ransom } from 'stores/ransomStore';
import productStore, { StoreIds } from 'stores/productStore';

import Grid from 'ui/Grid';
import { ErrorMessage, InfoMessage, WarningMessage } from 'ui/Message';
import TextInput from 'ui/TextInput';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import QrCodeIcon from '@mui/icons-material/QrCode';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const phoneRegExp = new RegExp(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/);

const EditRansomPaymentTypeWB = ({
    paymentType,
    handleChangePaymentType,
    payPhone,
    handleChangePhone
}: {
    paymentType: Ransom['paymentType'];
    handleChangePaymentType: (paymentType: Ransom['paymentType']) => void;
    payPhone: Ransom['payPhone'];
    handleChangePhone: (phone: Ransom['payPhone']) => void;
}) => {
    const { qr, sberpay, carts } = authStore.settings;

    return (
        <>
            <Grid.Column>
                <Typography>Способ оплаты товара на Wildberries</Typography>
                <ToggleButtonGroup
                    size="small"
                    color="secondary"
                    value={paymentType}
                    exclusive
                    onChange={(event, type) => handleChangePaymentType(type)}
                    fullWidth
                >
                    <ToggleButton value={1} sx={{ gap: 1 }}>
                        <QrCodeIcon /> По QR (СБП)
                    </ToggleButton>
                    <ToggleButton value={2}>SberPay</ToggleButton>
                    <ToggleButton value={3} sx={{ gap: 1 }}>
                        <CreditCardIcon /> Картой
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid.Column>
            <Grid.Column>
                {paymentType === 1 && (
                    <>
                        {qr && (
                            <InfoMessage header="Оплата по QR-коду (СБП)">
                                После создания "Выкупа", тут появится QR-код на оплату товара на Wildberries
                            </InfoMessage>
                        )}
                        {!qr && (
                            <ErrorMessage header="Способ оплаты по QR недоступен">
                                На Wildberries временно недоступна оплата по СБП (QR-коду), ждем решения технической
                                проблемы на их стороне
                            </ErrorMessage>
                        )}
                    </>
                )}
                {paymentType === 2 && (
                    <>
                        {!sberpay && (
                            <ErrorMessage header="Способ оплаты по SberPay недоступен">
                                На Wildberries временно сбои при оплате по SberPay, ждем решения технической проблемы на
                                их стороне
                            </ErrorMessage>
                        )}
                        {sberpay && (
                            <>
                                <TextInput
                                    label="Телефон, привязанный к Сбербанк Онлайн"
                                    required
                                    onChange={(event: SyntheticEvent, { value }: { value: string }) =>
                                        handleChangePhone(value)
                                    }
                                    autoComplete="phone"
                                    value={payPhone}
                                    size="medium"
                                    variant="classic"
                                    mask="+7 (000) 000-00-00"
                                    pattern={phoneRegExp}
                                />
                                <InfoMessage header="Оплата по SberPay">
                                    Укажите номер телефона, привязанный к приложению Сбербанк Онлайн. В это приложение
                                    вам поступит уведомление о поступление счета на оплату товара с Wildberries
                                </InfoMessage>
                            </>
                        )}
                    </>
                )}
                {paymentType === 3 && (
                    <>
                        {!carts && (
                            <ErrorMessage header="Способ оплаты по Карте недоступен">
                                На Wildberries временно сбои при оплате банковской картой, ждем решения технической
                                проблемы на их стороне
                            </ErrorMessage>
                        )}
                        {carts && (
                            <InfoMessage header="Оплата банковской картой">
                                Вы можете оплатить товар картой Visa, Mastercard, МИР. После создания Выкупа появится
                                ссылка, перейдя по которой, откроется страница оплаты на стороне Wildberries. Никакие
                                данные о вашей карте мы не храним и доступа к ним не имеем.
                            </InfoMessage>
                        )}
                    </>
                )}
            </Grid.Column>
        </>
    );
};

const EditRansomPaymentTypeOzon = ({
    paymentType,
    handleChangePaymentType
}: {
    paymentType: Ransom['paymentType'];
    handleChangePaymentType: (paymentType: Ransom['paymentType']) => void;
}) => {
    const { qrOzon } = authStore.settings;

    return (
        <>
            <Grid.Column>
                <Typography>Способ оплаты товара на Ozon</Typography>
                <ToggleButtonGroup
                    size="small"
                    color="secondary"
                    value={paymentType}
                    exclusive
                    onChange={(event, type) => handleChangePaymentType(type)}
                    fullWidth
                >
                    <ToggleButton value={1} sx={{ gap: 1 }}>
                        <QrCodeIcon /> По QR (СБП)
                    </ToggleButton>
                    <ToggleButton value={3} sx={{ gap: 1 }}>
                        <CreditCardIcon /> Картой
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid.Column>
            <Grid.Column>
                {paymentType === 1 && (
                    <>
                        {qrOzon && (
                            <InfoMessage header="Оплата по QR-коду (СБП)">
                                После создания "Выкупа", тут появится QR-код на оплату товара на Ozon
                            </InfoMessage>
                        )}
                        {!qrOzon && (
                            <ErrorMessage header="Способ оплаты по QR недоступен">
                                На OZON временно недоступна оплата по СБП (QR-коду), ждем решения технической проблемы
                                на их стороне
                            </ErrorMessage>
                        )}
                    </>
                )}
                {paymentType === 3 && (
                    <WarningMessage header="Способ оплаты по Карте недоступен">
                        Способ оплаты выкупа картой на Ozon пока в разработке
                    </WarningMessage>
                )}
            </Grid.Column>
        </>
    );
};

export const EditRansomPaymentType = ({
    paymentType,
    handleChangePaymentType,
    payPhone,
    handleChangePhone
}: {
    paymentType: Ransom['paymentType'];
    handleChangePaymentType: (paymentType: Ransom['paymentType']) => void;
    payPhone: Ransom['payPhone'];
    handleChangePhone: (phone: Ransom['payPhone']) => void;
}) => {
    const store_id = productStore.getCurrentStoreId();

    if (store_id === StoreIds.WB) {
        return (
            <EditRansomPaymentTypeWB
                paymentType={paymentType}
                handleChangePaymentType={handleChangePaymentType}
                payPhone={payPhone}
                handleChangePhone={handleChangePhone}
            />
        );
    } else if (store_id === StoreIds.Ozon) {
        return (
            <EditRansomPaymentTypeOzon paymentType={paymentType} handleChangePaymentType={handleChangePaymentType} />
        );
    } else {
        const error: never = store_id;
        throw new Error(store_id);
    }
};

const RansomPaymentType = ({ ransom_id }: { ransom_id: number }) => {
    const handleChangePaymentType = (paymentType: Ransom['paymentType']) => {
        if (paymentType) {
            ransomStore.setEditingItem(ransom_id, { paymentType });
        }
    };

    const handlePayPhone = (payPhone: string | null) => {
        ransomStore.setEditingItem(ransom_id, { payPhone });
    };

    const { editingItem } = ransomStore.getItem(ransom_id);
    const { paymentType, payPhone } = editingItem;

    useEffect(() => {
        if (!payPhone) {
            handlePayPhone(authStore.currentUser?.phone || null)
        }
    }, [paymentType]);

    return (
        <EditRansomPaymentType
            paymentType={paymentType || 1}
            handleChangePaymentType={handleChangePaymentType}
            payPhone={payPhone || null}
            handleChangePhone={handlePayPhone}
        />
    );
};

export default observer(RansomPaymentType);
