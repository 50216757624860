import { makeObservable, observable } from 'mobx';
import ListTransactionStorePrototype from './prototypes/ListTransactionsStore.prototype';
import * as ransomApi from 'api/ransomApi';
import { WbSearchFilterResults } from '../api/ransomApi';
import { StoreDeliveryPointType } from 'stores/deliveryPointsStore';
import productStore, { ProductList, StoreIds, WbItem, WbSize } from 'stores/productStore';
import authStore from './authStore';
import { DropdownType } from './prototypes/ListStore.prototype';
import { CREATING_ITEM_ID } from './prototypes/ItemStore.prototype';
import deepCopy from 'common/deepCopy';
import videoStore from './videoStore';

export const PRE_RUN_VIDEO_TYPE = 29;

export enum RansomType {
    Once = 1,
    Group,
    Smart
}

export enum RansomStatus {
    Creating = 1,
    WaitingForPayment,
    Delivery,
    CheckingPayment,
    WaitingForExecution = 5,
    Error = 100,
    ErrorExpiredPayment
}

export type RansomWbFilter = {
    name: string;
    key: string;
    ids: { id: number; name: string }[];
};

export type GenderType = 'male' | 'female' | null;

export type RansomSmartItem = {
    time: number;
    store_point_id: number | null;
    address: string | null;
    wb_size_id: number | null;
    wbSizeName: string | null;
    searchRequest: string | null;
    wbFilters: RansomWbFilter[] | null;
    wbFilterOrderBy: 'popular' | 'rate' | 'priceup' | 'pricedown' | 'newly' | 'benefit' | null;
    gender: GenderType;
};

export type ShorStoreItemType = {
    store_id: StoreIds;
    store_item_id: number;
    title: string;
    photoUrl: string;
    price?: { price: number; priceWithSale: number };
};

export type Ransom = {
    ransom_id: number;
    product_id: number | null;
    account_id: number;
    user_id: number;
    accountIsGood: boolean;
    gender: GenderType;

    store_id: StoreIds;
    store_point_id: number | null;
    store_point_ids: number[];

    status: RansomStatus;

    paymentType: 1 | 2 | 3; // Сбп, Сбер, Карта
    payPhone: string | null;
    paymentUrl: string | null;
    paymentQR: string | null;

    additional_store_item_ids: number[];
    additionalStoreItems: WbItem[];

    rival_item_ids: number[];

    searchRequest: string | null;
    wbFilters: RansomWbFilter[];
    wbFilterOrderBy: 'popular' | 'rate' | 'priceup' | 'pricedown' | 'newly' | 'benefit';

    errorMessage: string | null;
    price: number;

    type: RansomType;
    ransomsCount: null | number;
    ransom_group_id: null | number;
    planningExecuteTime: null | number;
    ransomSmartItems: RansomSmartItem[];
    preRunAccount: boolean;
    oneTimeAccount: boolean;

    product: ProductList;
    deliveryStorePoint: StoreDeliveryPointType;

    wbReceiptLink: string | null;

    rivalItems: ShorStoreItemType[];

    createTime: number;
    updateTime: number;
    enable: boolean;
} & (
    | {
          wb_size_id: number;
          wbSize: WbSize;
      }
    | {
          wb_size_id: null;
          wbSize: null;
      }
);

export type RansomFilter = {
    status: Ransom['status'] | null;
    store_id: StoreIds;
    product_id: number | null;
    store_point_id: number | null;
    allUsers: boolean;
    enable: boolean;
};

export type DeliveryPointAddress = {
    store_point_id: number;
    address: string;
};

type RansomStoreProperty = {
    fetchInterval: NodeJS.Timeout | null;
    rivalEnable: boolean;
    rivalItems: { store_item_id: number; title: string; photoUrl: string | null }[];
    additionalItems: { wb_item_id: number; title: string }[];

    wbSearchFilters: WbSearchFilterResults;
    wbSearchFiltersLoading: boolean;
    wbSearchFiltersErrors: string[];

    deliveryPointsAddresses: DeliveryPointAddress[];
    wbSize: WbSize | null;
};

class RansomStore extends ListTransactionStorePrototype<Ransom, Ransom, RansomStoreProperty, RansomFilter> {
    constructor() {
        super('ransom_id', 'ransom', ransomApi);
        this.clearFilter();
        makeObservable(this);
    }

    orderBy = 'ransom_id';

    listFilterClear = {
        status: null,
        store_id: StoreIds.WB,
        product_id: null,
        store_point_id: null,
        allUsers: false,
        enable: true
    };

    new_ransom_ids: number[] = [];

    async createItem(): Promise<number> {
        const editingItem = deepCopy(this.getItem(CREATING_ITEM_ID).editingItem);
        const { store_point_ids, type } = editingItem;

        if (type !== RansomType.Smart) {
            if (!store_point_ids) {
                throw new Error('Не выбрано ПВЗ');
            }
            let new_ransom_ids: number[] = [];

            for await (const store_point_id of store_point_ids) {
                this.setEditingItem(CREATING_ITEM_ID, { ...editingItem, store_point_id });
                const ransom_id = await super.createItem();
                new_ransom_ids.push(ransom_id);
            }

            if (new_ransom_ids.length > 1) {
                this.new_ransom_ids = new_ransom_ids;
            }

            return new_ransom_ids[0];
        } else {
            return await super.createItem();
        }
    }

    async toggleDisableItem(ransom_id: number, enable: boolean) {
        await super.toggleDisableItem(ransom_id, enable);
        const { ransom_group_id } = this.getItem(ransom_id).item || {};
        this.fetchItem(ransom_id);
        if (ransom_group_id) {
            this.fetchRansomGroup(ransom_group_id);
        }
        authStore.pullCurrentUserApi();
    }

    toggleRansomFilter(
        ransom_id: number,
        { name, key, id, itemName }: { name: string; key: string; id: number; itemName: string }
    ): void {
        const { wbFilters: wbFiltersOld } = this.getItem(ransom_id).editingItem;
        if (!wbFiltersOld) {
            return;
        }
        let wbFilters = deepCopy(wbFiltersOld);
        const foundIndex = wbFilters.findIndex(filter => filter.name === name);
        if (!~foundIndex) {
            wbFilters.push({ name, key, ids: [{ id, name: itemName }] });
        } else {
            let { ids } = wbFilters[foundIndex];
            const includedIndexes = ids.map(({ id }) => id);
            if (includedIndexes.includes(id)) {
                const index = includedIndexes.indexOf(id);
                ids.splice(index, 1);
            } else {
                ids.push({ id, name: itemName });
            }
            wbFilters[foundIndex].ids = ids;
        }
        this.setEditingItem(ransom_id, { wbFilters });
    }

    deleteRansomFilter(ransom_id: number, name: string): void {
        const { wbFilters: wbFiltersOld } = this.getItem(ransom_id).editingItem;
        if (!wbFiltersOld) {
            return;
        }
        let wbFilters = deepCopy(wbFiltersOld);
        const foundIndex = wbFilters.findIndex(filter => filter.name === name);
        if (~foundIndex) {
            wbFilters.splice(foundIndex, 1);
            this.setEditingItem(ransom_id, { wbFilters });
        }
    }

    clearRansomFilter(ransom_id: number): void {
        this.setEditingItem(ransom_id, { wbFilters: [] });
    }

    changeFilter<T extends keyof RansomFilter>(what: T, value: RansomFilter[T]) {
        super.changeFilter(what, value);
        if (what !== 'store_point_id') {
            this.fetchRansomsStoresOptions();
        }
    }

    clearFilter() {
        super.clearFilter();
        this.fetchRansomsStoresOptions();
    }

    @observable
    ransomGroups: Map<number, { loading: boolean; ransoms: Ransom[] }> = new Map();

    async fetchRansomGroup(ransom_group_id: number) {
        if (!this.ransomGroups.has(ransom_group_id)) {
            this.ransomGroups.set(ransom_group_id, { loading: true, ransoms: [] });
        }
        const ransoms = await ransomApi.fetchRansomsGroup(ransom_group_id);
        this.ransomGroups.set(ransom_group_id, { loading: false, ransoms });
    }

    getRansomGroup(ransom_group_id: number): { loading: boolean; ransoms: Ransom[] } {
        return this.ransomGroups.get(ransom_group_id) || { loading: true, ransoms: [] };
    }

    @observable
    deliveriesStoresOptions: DropdownType[] = [];
    @observable
    loadingDeliveriesStores = false;

    async fetchRansomsStoresOptions() {
        this.loadingDeliveriesStores = true;
        try {
            const deliveriesStores = await ransomApi.fetchRansomsStoresOptions(this.listFilter);
            this.deliveriesStoresOptions = deliveriesStores.map(({ store_point_id, address }) => ({
                value: store_point_id,
                text: address
            }));
        } catch (err) {
        } finally {
            this.loadingDeliveriesStores = false;
        }
    }

    adjustWbFilter = (wbFilters: RansomWbFilter[]): { key: string; ids: string[] }[] => {
        return wbFilters.map(({ key, ids }) => ({
            key: key.replace('top', ''),
            ids: ids.map(({ id }) => String(id))
        }));
    };

    async fetchWbSearchFilters(ransom_id: number, query: string): Promise<void> {
        const { wbFilters } = this.getItem(ransom_id).editingItem;
        if (!wbFilters) {
            return;
        }

        const adjustedFilters = this.adjustWbFilter(wbFilters);

        this.setProperty(ransom_id, { wbSearchFiltersLoading: true, wbSearchFiltersErrors: [] });
        try {
            const wbSearchFilters = await ransomApi.fetchWbSearchFilters(query, adjustedFilters);
            this.setProperty(ransom_id, { wbSearchFilters });
        } catch (errors) {
            if (errors instanceof Array) {
                this.setProperty(ransom_id, { wbSearchFiltersErrors: errors });
            }
        } finally {
            this.setProperty(ransom_id, { wbSearchFiltersLoading: false });
        }
    }

    async fetchWbFilerOptionsSet(ransom_id: number, query: string, itemKey: string): Promise<void> {
        const { wbFilters } = this.getItem(ransom_id).editingItem;
        if (!wbFilters) {
            return;
        }

        const setFilters = [{ key: 'filters', ids: [itemKey.replace('top', '')] }, ...this.adjustWbFilter(wbFilters)];
        const filtersList = (await ransomApi.fetchWbSearchFilters(query, setFilters)).filters;

        const { wbSearchFilters } = this.getItem(ransom_id).property;
        if (!wbSearchFilters) {
            return;
        }

        let wbSearchFiltersAdjusted = deepCopy(wbSearchFilters);

        wbSearchFiltersAdjusted.filters = wbSearchFiltersAdjusted.filters.map(filterItem => {
            const { key } = filterItem;
            const foundNew = filtersList.find(newList => newList.key.replace('top', '') === key.replace('top', ''));
            if (foundNew) {
                return foundNew;
            }
            return filterItem;
        });
        this.setProperty(ransom_id, { wbSearchFilters: wbSearchFiltersAdjusted });
    }

    whetherSmartRansomsCompleted(ransom_id: number): boolean {
        const { ransomSmartItems, product_id } = this.getItem(ransom_id).editingItem;
        if (!ransomSmartItems || !ransomSmartItems.length || !product_id) {
            return false;
        }
        return ransomSmartItems.every(
            ({ store_point_id, wb_size_id }) =>
                store_point_id !== null && (productStore.whetherProductHasSizes(product_id) ? wb_size_id : true)
        );
    }

    async tryCreateDelivery(ransom_id: number): Promise<void> {
        this.getItem(ransom_id).loadingItem = true;
        try {
            await ransomApi.tryCreateDelivery(ransom_id);
            await this.fetchItem(ransom_id);
        } catch (errors) {
            if (errors instanceof Array) {
                this.getItem(ransom_id).errors = errors;
            }
        } finally {
            this.getItem(ransom_id).loadingItem = false;
        }
    }

    addAdditionalItem(ransom_id: number, storeItem: DropdownType): void {
        const { additionalItems = [] } = this.getItem(ransom_id).property;
        const { product_id } = this.getItem(ransom_id).editingItem;
        if (!product_id) {
            return;
        }
        const product = productStore.getItem(product_id).item;
        if (!product) {
            return;
        }

        if (
            storeItem.value !== product.store_item_id &&
            !additionalItems.find(({ wb_item_id }) => storeItem.value === wb_item_id)
        ) {
            const additionalItem = { wb_item_id: Number(storeItem.value), title: storeItem.text };
            const additionalItemsNew = [...additionalItems, additionalItem];

            this.setEditingItem(ransom_id, {
                additional_store_item_ids: additionalItemsNew.map(({ wb_item_id }) => wb_item_id)
            });
            this.setProperty(ransom_id, { additionalItems: additionalItemsNew });
        }
    }

    copyRansomToEmpty(ransom_id: number): void {
        const ransom = this.getItem(ransom_id).item;
        if (ransom) {
            this.fetchItem(CREATING_ITEM_ID);
            const {
                store_id,
                deliveryStorePoint,
                product_id,
                wb_size_id,
                searchRequest,
                gender,
                preRunAccount,
                paymentType,
                payPhone,
                wbFilters,
                wbFilterOrderBy,
                rivalItems,
                additionalStoreItems,
                wbSize
            } = ransom;
            const { store_point_id } = deliveryStorePoint;
            this.setEditingItem(CREATING_ITEM_ID, {
                store_point_id,
                store_point_ids: [store_point_id],
                product_id,
                wb_size_id,
                searchRequest,
                gender,
                type: RansomType.Once,
                planningExecuteTime: null,
                preRunAccount,
                ransomSmartItems: [],
                ransomsCount: null,
                paymentType,
                payPhone,
                rival_item_ids: rivalItems.map(({ store_item_id }) => store_item_id),
                additional_store_item_ids: additionalStoreItems.map(({ wb_item_id }) => wb_item_id),
                wbFilters,
                wbFilterOrderBy
            });
            this.setProperty(CREATING_ITEM_ID, {
                deliveryPointsAddresses: [{ store_point_id, address: deliveryStorePoint.address }],
                rivalEnable: Boolean(rivalItems.length),
                rivalItems: rivalItems.map(({ store_item_id, title, photoUrl }) => ({
                    store_item_id,
                    title,
                    photoUrl
                })),
                additionalItems: additionalStoreItems.map(({ wb_item_id, title }) => ({ wb_item_id, title })),
                wbSize
            });
        }
    }

    findWbVideo(ransom_id: number) {
        videoStore.findWbVideo('ransom', ransom_id);
        try {
            const ransom = this.getItem(ransom_id).item;
            if (ransom?.preRunAccount) {
                videoStore.findWbVideo('ransom', ransom_id, PRE_RUN_VIDEO_TYPE);
            }
        } catch (err) {
            console.log(err);
        }
    }
}

export default new RansomStore();
