import React, { SyntheticEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import ozonAccountsStore from 'stores/ozonAccountsStore';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import ListError from 'ui/ListError';

import Grid from 'ui/Grid';
import Button from 'ui/Button';

const OpenOzonBox = ({ onClose }: { onClose: () => void }) => {
    useEffect(() => {
        ozonAccountsStore.fetchList();
    }, []);

    const { list, listErrors } = ozonAccountsStore;
    const [account_id, setAccountId] = useState<number | null>(null);

    const onSelect = (event: SyntheticEvent, data: { value: number } | null) => {
        if (data?.value) {
            const { value } = data;
            setAccountId(value);
            ozonAccountsStore.fetchItem(value);
        }
    };

    const openOzonAccount = () => {
        if (account_id) {
            const { SecureRefreshToken, SecureAccessToken } = ozonAccountsStore.findById(account_id);
            window.postMessage({
                operation: 'openOzonAccount',
                SecureRefreshToken,
                SecureAccessToken
            });
        }
    };

    return (
        <Grid>
            <Grid.Column>
                <Button variant="text" color="inherit" startIcon={<ArrowBackIcon />} onClick={onClose}>
                    назад
                </Button>
            </Grid.Column>
            <Grid.Column>
                <ListError errors={listErrors} />

                <Autocomplete
                    disablePortal
                    options={list.map(({ account_id, phone, gender, priority }) => ({
                        value: account_id,
                        label: `#${account_id} :: ${phone} (${
                            gender === 'male' ? 'муж' : gender === 'female' ? 'жен' : '---'
                        }) П${priority}`
                    }))}
                    renderInput={params => <TextField {...params} label="Аккаунты Ozon" />}
                    fullWidth
                    onChange={onSelect}
                />
            </Grid.Column>
            <Grid.Column>
                <Button color="primary" variant="contained" onClick={openOzonAccount} fullWidth disabled={!account_id}>
                    Открыть "залогиненый" Ozon
                </Button>
            </Grid.Column>
        </Grid>
    );
};

export default observer(OpenOzonBox);
