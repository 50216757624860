import React from 'react';

import Button from 'ui/Button';
import { WarningMessage } from 'ui/Message';
import { useToggleModal } from 'ui/Modal';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const WarningDeliveryMessage = () => {
    const [show, toggle] = useToggleModal();

    return (
        <WarningMessage header="Информация о возврате с WB">
            <Button onClick={toggle} color="secondary">
                {show ? 'Скрыть' : 'Подробнее'}
            </Button>
            {show && (
                <Stack spacing={2}>
                    <Typography variant="body2">
                        Если вы не заберете товар, то выкуп, через 10-14 дней после поступления на ПВЗ, отменяется и
                        деньги за товар вернутся на карту, с которой была осуществлена оплата. Минус 100 рублей комиссии
                        ВБ за возврат.
                    </Typography>
                    {/*<Typography variant="body2">*/}
                    {/*    В последнее время WB перестал возвращать деньги на карту, с которой была произведена оплата, в*/}
                    {/*    случае если вы не забрали товар. У вас есть всего 7 дней, чтобы забрать товар с ПВЗ, после чего*/}
                    {/*    товар возвращается на склад. Деньги в этом случае остаются на аккаунте. У нас нет возможности*/}
                    {/*    сделать вам возврат этих средств, поэтому просим делать забор товара вовремя.*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="body2">*/}
                    {/*    По всем доставкам, которые перешли в статус «возврат» вне зависимости от причины, обращения на*/}
                    {/*    использование поступления денежных средств на баланс кабинета, использованного для выкупа,*/}
                    {/*    рассматриваются в течении 21 дня с момента создания выкупа.*/}
                    {/*</Typography>*/}
                    {/*<Typography variant="body2">*/}
                    {/*    Стоимость выкупа, произведенная в ручном режиме, оговаривается индивидуально и не может*/}
                    {/*    составлять менее 150 руб.*/}
                    {/*</Typography>*/}
                </Stack>
            )}
        </WarningMessage>
    );
};

export default React.memo(WarningDeliveryMessage);
