import React from 'react';

import Button from 'ui/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import CloseIcon from '@mui/icons-material/Close';

import Avatar from '@mui/material/Avatar';

const RansomOtherItem = ({
    title,
    photoUrl,
    store_item_id,
    onDeleteItem
}: {
    title: string;
    photoUrl: string | null;
    store_item_id: number;
    onDeleteItem: () => void;
}) => {
    return (
        <ListItem secondaryAction={<Button icon={<CloseIcon />} onClick={onDeleteItem} tooltip="Убрать товар" />}>
            {photoUrl && (
                <ListItemAvatar>
                    <Avatar
                        sx={{ width: `48px`, height: `48px` }}
                        src={photoUrl}
                        variant="rounded"
                        imgProps={{ loading: 'lazy' }}
                    />
                </ListItemAvatar>
            )}
            <ListItemText primary={title} />
        </ListItem>
    );
};

export default RansomOtherItem;
