import React from 'react';
import { Link } from 'react-router-dom';

export const RansomLinkState = (ransom_id: number) => ({
    pathname: `/ransoms/list/detail/${ransom_id}`,
    state: { modal: true }
});

const RansomLink = ({ ransom_id }: { ransom_id: number }) => <Link to={RansomLinkState(ransom_id)}>{ransom_id}</Link>;

export default RansomLink;
