import React from 'react';
import { observer } from 'mobx-react';
import serverConfig from 'config/server.config';

import reviewStore from 'stores/reviewStore';
import authStore from 'stores/authStore';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

import Button from 'ui/Button';

const ReviewPhotosShow = ({ review_id }: { review_id: number }) => {
    const { item } = reviewStore.getItem(review_id);
    const { reviewPhotos, reviewVideos } = item || { reviewPhotos: [], reviewVideos: [] };

    if (!reviewPhotos.length && !reviewVideos.length) {
        return null;
    }

    return (
        <>
            {reviewPhotos.length > 0 && (
                <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                    {reviewPhotos.map(filename => (
                        <div key={filename}>
                            <Avatar
                                sx={{ width: `96px`, height: `96px` }}
                                src={`${serverConfig.photoUrl}/${filename}`}
                                variant="rounded"
                            />
                            {/*{authStore.isAdmin() && (*/}
                            {/*    <a href={`${serverConfig.photoUrl}/${filename}`} target="_blank" rel="noreferrer" download>*/}
                            {/*        скачать*/}
                            {/*    </a>*/}
                            {/*)}*/}
                        </div>
                    ))}
                </Stack>
            )}
            {reviewVideos.length > 0 && (
                <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                    {reviewVideos.map(filename => (
                        <div key={filename}>
                            <Avatar sx={{ width: `96px`, height: `96px` }}>
                                <Button
                                    color="secondary"
                                    icon={<VideoLibraryIcon sx={{ width: `64px`, height: `64px` }} />}
                                    tooltip="Скачать видео"
                                    size="large"
                                    target="_blank"
                                    href={`${serverConfig.photoUrl}/${filename}`}
                                />
                            </Avatar>
                        </div>
                    ))}
                </Stack>
            )}
        </>
    );
};

export default observer(ReviewPhotosShow);
