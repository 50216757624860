import React from 'react';
import { observer } from 'mobx-react';

import deliveryPointsStore from 'stores/deliveryPointsStore';
import productStore, { StoreIds } from 'stores/productStore';
import commonStore from 'stores/commonStore';

import Box from '@mui/material/Box';
import PerfectScrollbar from 'react-perfect-scrollbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import AddressSearchInput from './AddressSearchInput';
import DeliveryPointType from './DeliveryPointType';

const DeliveryPointsAddressList = () => {
    const { activePoints } = deliveryPointsStore;
    const store_id = productStore.getCurrentStoreId();

    const setView = ({
        geo_lat,
        geo_lon,
        store_point_id
    }: {
        geo_lat: number;
        geo_lon: number;
        store_point_id: number;
    }) => {
        deliveryPointsStore.searchMap.setView([geo_lat, geo_lon], 15);
        deliveryPointsStore.activeStorePointId = store_point_id;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: commonStore.isMobile ? '100%' : '350px',
                p: 1,
                bgcolor: 'background.paper',
                height: commonStore.isMobile ? '500px' : '80vh'
            }}
        >
            {store_id === StoreIds.Ozon && <DeliveryPointType />}
            <AddressSearchInput />
            <Box sx={{ flex: 1, display: 'flex', overflowY: 'hidden' }}>
                <PerfectScrollbar>
                    <List dense>
                        {activePoints.map(({ address, workTime, store_point_id, geo_lon, geo_lat }) => (
                            <ListItem
                                disablePadding
                                disableGutters
                                key={store_point_id}
                                onClick={() => setView({ geo_lat, geo_lon, store_point_id })}
                            >
                                <ListItemButton>
                                    <ListItemText primary={address} secondary={workTime} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </PerfectScrollbar>
            </Box>
        </Box>
    );
};

export default observer(DeliveryPointsAddressList);
