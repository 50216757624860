import React, { useEffect, useState } from 'react';

import * as wbAdsRatesApi from 'api/wbAdsRatesApi';
import { WbAdsRate } from 'api/wbAdsRatesApi';

import Stack from '@mui/material/Stack';

import ListError from 'ui/ListError';
import { WarningMessage } from 'ui/Message';

import WbAdsRatesTable from './WbAdsRatesTable';

const WbAdsRatesCardTable = ({ wb_item_id }: { wb_item_id: number }) => {
    const [errors, setErrors] = useState<string[]>([]);
    const [adsRates, setAdsRates] = useState<WbAdsRate[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        wbAdsRatesApi
            .fetchWbAdsRatesByCard(wb_item_id)
            .then(setAdsRates)
            .catch(setErrors)
            .finally(() => setLoading(false));
    }, [wb_item_id]);

    return (
        <Stack spacing={2}>
            <ListError errors={errors} />
            <WbAdsRatesTable adsRates={adsRates} loading={loading} />
            {!loading && !adsRates.length && !errors.length && (
                <WarningMessage header="Ставок не найдено">Попробуйте изменить поисковый запрос</WarningMessage>
            )}
        </Stack>
    );
};

export default WbAdsRatesCardTable;
