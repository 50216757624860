import { makeObservable } from 'mobx';
import * as favoriteApi from 'api/favoriteApi';
import { ProductList, StoreIds } from './productStore';
import ListTransactionStorePrototype from './prototypes/ListTransactionsStore.prototype';

export type Favorite = {
    favorite_id: number;
    user_id: number;
    product_id: number | null;
    quantity: number;
    speed: number | null;
    status: 1 | 2;
    searchRequest: string | null;
    gender: 'male' | 'female' | null;

    product: ProductList;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type FavoriteFilter = {
    status: Favorite['status'] | null;
    store_id: StoreIds;
    product_id: number | null;
    allUsers: boolean;
    enable: boolean;
};

class FavoriteStore extends ListTransactionStorePrototype<Favorite, Favorite, {}, FavoriteFilter> {
    constructor() {
        super('favorite_id', 'favorite', favoriteApi);
        this.orderBy = 'favorite_id';
        this.clearFilter();

        makeObservable(this);
    }

    listFilterClear = {
        status: null,
        store_id: StoreIds.WB,
        product_id: null,
        allUsers: false,
        enable: true
    };
}

export default new FavoriteStore();
