import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import * as productApi from 'api/productApi';

import productStore, { StoreIds } from 'stores/productStore';
import { WBSuggestion } from 'stores/prototypes/ListTransactionsStore.prototype';
import useDebounce from 'common/useDebounce';

import ListError from 'ui/ListError';
import InfoPopup from 'ui/InfoPopup';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Tabs from '@mui/material/Tabs';

import SuggestionsTableWB from './StoreBlocks/SuggestionsTableWB';

let suggestGender = 'common';

const SearchPopup = React.memo(() => {
    return (
        <InfoPopup size="large">
            <Typography variant="subtitle2">Поиск по ключевым запросам на Wildberries</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Выбирайте подходящий ключевой запрос к вашему товару из подсказки, и смотрите на каком месте в поиске
                находится ваш товар, а также 100 верхних товаров по данному запросу.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Все подсказки берутся из поисковой строки на сайте Wildberries и соответствуют заданным фильтрам.
                Продвижение вашего товара вверх по этим запросам - основная задача для получения заказов через
                Wildberries.
            </Typography>
            <Typography variant="caption">
                В строке используются подсказки, взятые напрямую с поисковой строки WB в реальном времени. Учитывайте
                их, чтобы подобрать подходящий ключевой запрос.
            </Typography>
        </InfoPopup>
    );
});

export const WbSearchField = ({
    searchRequest,
    onChangeSearch,
    suggestions,
    handleSelectQuery,
    infoPopup
}: {
    searchRequest: string;
    onChangeSearch: (search: string) => void;
    suggestions: WBSuggestion[];
    handleSelectQuery: (event: any, value: any) => void;
    infoPopup?: ReactNode;
}) => {
    const handleSearchRequest = async (event: SyntheticEvent, searchRequest: string) => {
        onChangeSearch(searchRequest);
    };

    return (
        <Autocomplete
            freeSolo
            inputValue={searchRequest || ''}
            onInputChange={handleSearchRequest}
            disableClearable
            options={suggestions}
            getOptionLabel={option => {
                return typeof option === 'string' ? option : option.type === 'brand' ? option.brand_name : option.name;
            }}
            renderOption={(props, option: WBSuggestion) => (
                <Box component="li" {...props}>
                    {option.type === 'brand' ? option.brand_name : option.name}
                    {option.type === 'brand' && <Chip color="secondary" size="small" label="Brand" sx={{ ml: 1 }} />}
                </Box>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Ключевой запрос"
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        endAdornment: infoPopup ? (
                            <InputAdornment position="end">{infoPopup}</InputAdornment>
                        ) : undefined
                    }}
                    style={{ background: 'white' }}
                />
            )}
            onChange={handleSelectQuery}
        />
    );
};

const ProductSearchAnalyseMode = ({ product_id }: { product_id: number }) => {
    const { item } = productStore.getItem(product_id);
    const [searchRequest, setSearchRequest] = useState('');
    const [selectedSearchRequest, setSelectedSearchRequest] = useState('');
    const [errors, setErrors] = useState<string[]>([]);
    const [suggestions, setSuggestions] = useState<WBSuggestion[]>([]);
    const debouncedValue = useDebounce<string>(searchRequest, 500);

    const [selectedSuggestion, setSelectedSuggestion] = useState<WBSuggestion | null>(null);

    useEffect(() => {
        productApi
            .fetchWbSearchSuggestions(debouncedValue, suggestGender)
            .then(setSuggestions)
            .catch(errors => {
                console.log(errors);
                setErrors(['При поиске произошла ошибка']);
            });
    }, [debouncedValue]);

    if (!item || item.store_id !== StoreIds.WB) {
        return null;
    }

    const {
        wbItem: { tags }
    } = item;

    const handleSelectQuery = (event: any, value: any) => {
        setSelectedSearchRequest(typeof value === 'string' ? value : value.name);
    };

    const handleSetSearchTag = async (tag: string) => {
        setSelectedSearchRequest(tag);
        setSearchRequest(tag);
        try {
            const suggestions = await productApi.fetchWbSearchSuggestions(tag.slice(0, -1), suggestGender);
            setSuggestions(suggestions);
            const foundSuggestion = suggestions.find(wbSuggest =>
                wbSuggest.type === 'brand' ? wbSuggest.brand_name : wbSuggest.name
            );
            if (foundSuggestion) {
                setSelectedSuggestion(foundSuggestion);
            }
        } catch (errors) {
            console.log(errors);
            setErrors(['При поиске произошла ошибка']);
        }
    };

    return (
        <>
            {tags.length > 0 && (
                <Tabs
                    value={0}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                        style: {
                            display: 'none'
                        }
                    }}
                    sx={{ height: '32px', maxHeight: '32px', minHeight: '32px', mb: 2 }}
                >
                    {tags.map(tag => (
                        <Chip
                            key={tag}
                            label={tag}
                            color="secondary"
                            variant="outlined"
                            style={{ margin: '0 0.15rem' }}
                            onClick={() => handleSetSearchTag(tag)}
                            title="Провести анализ по запросу"
                        />
                    ))}
                </Tabs>
            )}

            <ListError errors={errors} />
            <WbSearchField
                searchRequest={searchRequest}
                onChangeSearch={setSearchRequest}
                handleSelectQuery={handleSelectQuery}
                suggestions={suggestions}
                infoPopup={<SearchPopup />}
            />

            {selectedSearchRequest && (
                <SuggestionsTableWB
                    suggestion={selectedSuggestion || null}
                    product_id={product_id}
                    wb_item_id={item.wbItem.wb_item_id}
                    searchRequest={selectedSearchRequest}
                />
            )}
        </>
    );
};

export default observer(ProductSearchAnalyseMode);
