import React from 'react';

import commonStore from 'stores/commonStore';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import SettingsDropdown from './SettingsDropdown';
import HeaderNotifications from './Notifications/HeaderNotifications';
import InfoSnackBar from './InfoSnackBar';
import VideoHeaderModal from './VideoHeaderModal';
import PricesHeaderModal from './PricesHeaderModal';

import Divider from 'ui/Divider';

import ServiceMessage from './ServiceMessage';

const Header = ({ drawerWidth, handleMobileOpen }: { drawerWidth: number; handleMobileOpen: () => void }) => {
    return (
        <>
            <AppBar
                elevation={0}
                position="fixed"
                sx={{
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    ml: { md: `${drawerWidth}px` },
                    backgroundColor: '#fafbfb'
                }}
                color="inherit"
            >
                <ServiceMessage />
                <Toolbar>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={handleMobileOpen}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            justifyContent: 'space-around',
                            gap: commonStore.isMobile ? '0.5rem' : '1rem',
                            alignItems: 'center'
                        }}
                    >
                        <VideoHeaderModal />
                        {!commonStore.isMobile && <PricesHeaderModal />}
                        {/*<HeaderNotifications />*/}
                        <Divider orientation="vertical" flexItem />
                        <SettingsDropdown />
                    </Box>
                </Toolbar>
            </AppBar>
            <InfoSnackBar />
        </>
    );
};

export default Header;
