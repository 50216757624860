import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import locationHistory from './locationHistory';

import authStore from './stores/authStore';
import commonStore from './stores/commonStore';

import { Router } from 'react-router-dom';
import MainRouter from './mainRouter';

import Header from './components/Header';
import LeftMenu from './components/LeftMenu';
import LoginForm from './components/LoginForm';
import Electron from './components/Electron';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import ServiceMessage from './components/Header/ServiceMessage';

const drawerWidth = 250;

const App = () => {
    useEffect(() => {
        if (authStore.token) {
            authStore.whatsUp();
        }
    }, []);

    if (!authStore.loggedIn && !authStore.token) {
        return <LoginForm />;
    }

    if (document.location.pathname === '/electron') {
        return (
            <Box sx={{ m: 2 }}>
                <Electron />
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Router history={locationHistory}>
                <Header drawerWidth={drawerWidth} handleMobileOpen={commonStore.toggleLeftMenu} />
                <LeftMenu
                    drawerWidth={drawerWidth}
                    mobileOpen={commonStore.showLeftMenu}
                    handleMobileOpen={commonStore.toggleLeftMenu}
                />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        px: commonStore.isMobile ? 0 : 1,
                        py: commonStore.isMobile ? 0 : 2,
                        backgroundColor: '#fafbfb',
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                        maxHeight: '100vh',
                        maxWidth: commonStore.isMobile ? '100vw' : `calc(100vw - ${drawerWidth}px)`
                    }}
                >
                    <ServiceMessage />
                    <Toolbar />
                    <MainRouter fullSize location={locationHistory.location} />
                </Box>
            </Router>
        </Box>
    );
};

export default observer(App);
