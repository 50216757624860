import React, { ReactNode } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TelegramIcon from '@mui/icons-material/Telegram';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import PaymentIcon from '@mui/icons-material/Payment';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

import authStore from 'stores/authStore';
import commonStore from 'stores/commonStore';

import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import CommentIcon from '@mui/icons-material/Comment';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
// import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PeopleIcon from '@mui/icons-material/People';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import PieChartIcon from '@mui/icons-material/PieChart';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AssistantIcon from '@mui/icons-material/Assistant';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import BarChartIcon from '@mui/icons-material/BarChart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import Divider from 'ui/Divider';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import MULink from '@mui/material/Link';
import Badge from '@mui/material/Badge';

import Button from 'ui/Button';
import PricesHeaderModal from '../Header/PricesHeaderModal';
import productStore, { StoreIds } from '../../stores/productStore';
import StoresToggle from '../StoresToggle';

// const useStyles = makeStyles(({ palette, spacing }: Theme) =>
//     createStyles({
//         selected: {
//             backgroundColor: `${palette.primary.main} !important`,
//             borderRadius: spacing(1),
//             '& > *': {
//                 color: palette.primary.contrastText
//             }
//         }
//     })
// );

const ListItemButtonTS = styled(ListItemButton)<ListItemButtonProps & { component?: ReactNode; to?: string }>(
    ({ theme }) => ({
        '&.Mui-selected': {
            backgroundColor: `${theme.palette.primary.main} !important`,
            borderRadius: theme.spacing(1),
            '& > *': {
                color: theme.palette.primary.contrastText
            }
        }
    })
);

const menuList: { pathname: string; title: string; icon: ReactNode }[] = [
    {
        pathname: '/products/list',
        title: 'Мои товары',
        icon: <FormatListBulletedIcon />
    },
    {
        pathname: '/ransoms/list',
        title: 'Выкупы',
        icon: <PaymentIcon />
    },
    {
        pathname: '/deliveries/list',
        title: 'Доставки',
        icon: <DeliveryDiningIcon />
    },
    {
        pathname: '/reviews/list',
        title: 'Отзывы',
        icon: <CommentIcon />
    },
    {
        pathname: '/feedbacksReactions/list',
        title: 'Лайки на отзывы',
        icon: <ThumbsUpDownIcon />
    },
    {
        pathname: '/questions/list',
        title: 'Вопросы',
        icon: <QuestionAnswerIcon />
    },
    {
        pathname: '/favorites/list',
        title: 'Избранное',
        icon: <FavoriteIcon />
    },
    {
        pathname: '/brands/list',
        title: 'Бренды',
        icon: <BrandingWatermarkIcon />
    },
    {
        pathname: '/carts/list',
        title: 'Корзина',
        icon: <ShoppingBasketIcon />
    }
    // {
    //     pathname: '/wishlists/list',
    //     title: 'Лист ожидания',
    //     icon: <FormatListNumberedIcon />
    // }
];

// if (authStore.isAdmin()) {
//     menuList.push({
//         pathname: '/api/list',
//         title: 'Работа по API',
//         icon: <SettingsIcon />
//     });
// }

const USERS_PATHNAME = '/users/list';
const SEO_PATHNAME = '/seo/consult';
const SEO_KEYS_RANSOMS = '/keys/ransoms';
const YANDEX_DIRECT_PATHNAME = '/yandex/direct';
const INFOGRAPHICS_PATHNAME = '/seo/infographics';
const REFERALS_PATHNAME = '/referrals/statistics';
const API_LIST_PATHNAME = '/api/list';
const AUTO_ANSWERS_PATHNAME = '/api/answers';
const ADS_RATES_PATHNAME = '/ads/rates';
const PHOTOS_DIRECT_PATHNAME = '/seo/photos';
const SEOCONTROL_PATHNAME = '/seo/control';
const SALESFINDER_PATHNAME = '/seo/salesfinder';
const FULFILLMENT_PATHNAME = '/fulfillment/about';

const MenuList = () => {
    const { pathname } = useLocation();

    const handlePath = () => {
        commonStore.toggleLeftMenu();
    };

    return (
        <PerfectScrollbar
            style={{
                padding: '1rem',
                backgroundImage:
                    'linear-gradient(to right, rgba(255,255,255, 0.7) 0 100%), url(/static/leftmenu-bg.webp)',
                backgroundPosition: 'center center'
            }}
        >
            <MULink
                sx={{
                    height: '64px',
                    backgroundImage: `url('/static/logo-v2.svg')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center',
                    backgroundSize: '160px',
                    display: 'block'
                }}
                href="https://topstore.pro"
                target="_blank"
                title="Открыть информационную страницу"
            />
            {commonStore.isMobile && authStore.isAdmin() && (
                <Chip color="secondary" label="Админ" sx={{ m: 1, width: 'calc(100% - 16px)' }} />
            )}
            <StoresToggle />
            <List component="nav" disablePadding sx={{ mt: 3 }}>
                {menuList.map(({ title, icon, pathname: menuPathName }) => (
                    <ListItemButtonTS
                        key={menuPathName}
                        selected={
                            pathname.includes(menuPathName) || (menuPathName === '/products/list' && pathname === '/')
                        }
                        // classes={classes}
                        component={Link}
                        to={menuPathName}
                        onClick={() => handlePath()}
                        disabled={
                            menuPathName === '/feedbacksReactions/list' &&
                            productStore.getCurrentStoreId() !== StoreIds.WB
                        }
                    >
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText
                            primary={title}
                            primaryTypographyProps={
                                menuPathName === '/feedbacksReactions/list' ? { fontSize: '0.925rem' } : undefined
                            }
                        />
                    </ListItemButtonTS>
                ))}

                <Divider />

                {/*<Badge badgeContent="ТОП" color="secondary">*/}
                {/*    <ListItemButtonTS*/}
                {/*        selected={pathname.includes(SEO_KEYS_RANSOMS)}*/}
                {/*        component={Link}*/}
                {/*        to={SEO_KEYS_RANSOMS}*/}
                {/*        onClick={() => handlePath()}*/}
                {/*        sx={{ pr: 1 }}*/}
                {/*    >*/}
                {/*        <ListItemIcon>*/}
                {/*            <PointOfSaleIcon />*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary="Выкупы под ключ" primaryTypographyProps={{ fontSize: '0.925rem' }} />*/}
                {/*    </ListItemButtonTS>*/}
                {/*</Badge>*/}

                {/*<ListItemButtonTS*/}
                {/*    selected={pathname.includes('/fulfillment/')}*/}
                {/*    component={Link}*/}
                {/*    to={FULFILLMENT_PATHNAME}*/}
                {/*    onClick={() => handlePath()}*/}
                {/*    sx={{ pr: 5 }}*/}
                {/*>*/}
                {/*    <ListItemIcon>*/}
                {/*        <LocalShippingIcon />*/}
                {/*    </ListItemIcon>*/}
                {/*    <ListItemText primary="Фулфилмент" />*/}
                {/*</ListItemButtonTS>*/}

                <ListItemButtonTS
                    selected={pathname.includes(SEOCONTROL_PATHNAME)}
                    component={Link}
                    to={SEOCONTROL_PATHNAME}
                    onClick={() => handlePath()}
                    sx={{ pr: 7 }}
                >
                    <ListItemIcon>
                        <SupervisedUserCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="SEO-control" />
                </ListItemButtonTS>

                <ListItemButtonTS
                    selected={pathname.includes(SEO_PATHNAME)}
                    component={Link}
                    to={SEO_PATHNAME}
                    onClick={() => handlePath()}
                >
                    <ListItemIcon>
                        <ConnectWithoutContactIcon />
                    </ListItemIcon>
                    <ListItemText primary="SEO Карточки" />
                </ListItemButtonTS>

                <ListItemButtonTS
                    selected={pathname.includes(YANDEX_DIRECT_PATHNAME)}
                    component={Link}
                    to={YANDEX_DIRECT_PATHNAME}
                    onClick={() => handlePath()}
                >
                    <ListItemIcon>
                        <ReceiptLongIcon />
                    </ListItemIcon>
                    <ListItemText primary="Яндекс Директ" />
                </ListItemButtonTS>

                {/*<ListItemButtonTS*/}
                {/*    selected={pathname.includes(PHOTOS_DIRECT_PATHNAME)}*/}
                {/*    component={Link}*/}
                {/*    to={PHOTOS_DIRECT_PATHNAME}*/}
                {/*    onClick={() => handlePath()}*/}
                {/*>*/}
                {/*    <ListItemIcon>*/}
                {/*        <PhotoCameraIcon />*/}
                {/*    </ListItemIcon>*/}
                {/*    <ListItemText primary="Фото к отзывам" />*/}
                {/*</ListItemButtonTS>*/}

                {/*<ListItemButtonTS*/}
                {/*    selected={pathname.includes(INFOGRAPHICS_PATHNAME)}*/}
                {/*    component={Link}*/}
                {/*    to={INFOGRAPHICS_PATHNAME}*/}
                {/*    onClick={() => handlePath()}*/}
                {/*>*/}
                {/*    <ListItemIcon>*/}
                {/*        <PreviewIcon />*/}
                {/*    </ListItemIcon>*/}
                {/*    <ListItemText primary="Инфографика / Фото / Видео" primaryTypographyProps={{ fontSize: '0.925rem' }} />*/}
                {/*</ListItemButtonTS>*/}

                <Divider />

                <ListItemButtonTS
                    selected={pathname.includes(AUTO_ANSWERS_PATHNAME)}
                    component={Link}
                    to={AUTO_ANSWERS_PATHNAME}
                    onClick={() => handlePath()}
                >
                    <ListItemIcon>
                        <AssistantIcon />
                    </ListItemIcon>
                    <ListItemText primary="Автоответы" />
                </ListItemButtonTS>

                <ListItemButtonTS
                    selected={pathname.includes(ADS_RATES_PATHNAME)}
                    component={Link}
                    to={ADS_RATES_PATHNAME}
                    onClick={() => handlePath()}
                    sx={{ pr: 1 }}
                >
                    <ListItemIcon>
                        <MonetizationOnIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Рекламные ставки"
                        primaryTypographyProps={{ fontSize: '0.925rem', px: 0, mx: 0 }}
                    />
                </ListItemButtonTS>

                {/*<ListItemButtonTS*/}
                {/*    selected={pathname.includes(API_LIST_PATHNAME)}*/}
                {/*    component={Link}*/}
                {/*    to={API_LIST_PATHNAME}*/}
                {/*    onClick={() => handlePath()}*/}
                {/*>*/}
                {/*    <ListItemIcon>*/}
                {/*        <SettingsIcon />*/}
                {/*    </ListItemIcon>*/}
                {/*    <ListItemText primary="Wildberries API" />*/}
                {/*</ListItemButtonTS>*/}

                <Divider />

                <ListItemButtonTS
                    selected={pathname.includes(SALESFINDER_PATHNAME)}
                    component={Link}
                    to={SALESFINDER_PATHNAME}
                    onClick={() => handlePath()}
                >
                    <ListItemIcon>
                        <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Статистика WB" />
                </ListItemButtonTS>

                {(authStore.isAdmin() || authStore.enableReferrals()) && <Divider />}

                {authStore.isAdmin() && (
                    <ListItemButtonTS
                        selected={pathname.includes(USERS_PATHNAME)}
                        component={Link}
                        to={USERS_PATHNAME}
                        onClick={() => handlePath()}
                    >
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Пользователи" />
                    </ListItemButtonTS>
                )}

                {authStore.enableReferrals() && (
                    <ListItemButtonTS
                        selected={pathname.includes(REFERALS_PATHNAME)}
                        component={Link}
                        to={REFERALS_PATHNAME}
                        onClick={() => handlePath()}
                    >
                        <ListItemIcon>
                            <PieChartIcon />
                        </ListItemIcon>
                        <ListItemText primary="Рефералы" />
                    </ListItemButtonTS>
                )}

                {commonStore.isMobile && (
                    <>
                        <Divider />
                        <PricesHeaderModal />
                    </>
                )}
                {/*<Divider />*/}
                {/*<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>*/}
                {/*    <Typography>Если есть вопросы</Typography>*/}
                {/*    <Button target="_blank" href="https://t.me/TopStoreAdm" tooltip="Откроется в Telegram">*/}
                {/*        @TopStoreAdm*/}
                {/*    </Button>*/}
                {/*</Box>*/}
                {/*<Divider />*/}
                {/*<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>*/}
                {/*    <Typography>Информационный канал</Typography>*/}
                {/*    <Button*/}
                {/*        startIcon={<TelegramIcon />}*/}
                {/*        target="_blank"*/}
                {/*        href="https://t.me/topstorepro"*/}
                {/*        tooltip="Откроется в Telegram"*/}
                {/*    >*/}
                {/*        @TopStorePro*/}
                {/*    </Button>*/}
                {/*</Box>*/}
            </List>
        </PerfectScrollbar>
    );
};

export default withRouter(observer(MenuList));
