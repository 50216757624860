import fetchApi from 'common/fetchApi';
import { objectToGraphql } from '../common/graphql';
import { StoreDeliveryPointFilterType, StoreDeliveryPointType } from '../stores/deliveryPointsStore';
import { StoreIds } from '../stores/productStore';
import { GenderType } from '../stores/ransomStore';

export type CoordinatesCorner = {
    lat: number;
    lng: number;
};

export type CoordinatesSquare = {
    bottomLeft: CoordinatesCorner;
    topRight: CoordinatesCorner;
};

const GQDeliveryPointMapFields = `
    store_point_id
    address
    geo_lat
    geo_lon
    workTime
`;

export const searchStoreDeliveryPointsByCoords = async (
    store_id: StoreIds,
    type: number,
    coordinates: CoordinatesSquare
): Promise<StoreDeliveryPointType[]> => {
    const graphql = `{
            searchStoreDeliveryPointsByCoords(store_id: ${store_id}, type: ${type}, coordinates: ${objectToGraphql(
        coordinates
    )}) {
                ${GQDeliveryPointMapFields}
            } 
          }`;

    const data = await fetchApi.getGQ<{ searchStoreDeliveryPointsByCoords: StoreDeliveryPointType[] }>(graphql);
    return data.searchStoreDeliveryPointsByCoords;
};

export const fetchLastStoreDeliveryPoints = async (store_id: StoreIds): Promise<StoreDeliveryPointType[]> => {
    const graphql = `{
            fetchLastStoreDeliveryPoints(store_id: ${store_id}) {
                ${GQDeliveryPointMapFields}
                workTime
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchLastStoreDeliveryPoints: StoreDeliveryPointType[] }>(graphql);
    return data.fetchLastStoreDeliveryPoints;
};

export const searchStoreDeliveryPointsList = async (
    filter: Partial<StoreDeliveryPointFilterType>
): Promise<StoreDeliveryPointType[]> => {
    const graphql = `{
            searchStoreDeliveryPointsList(filter: ${objectToGraphql(filter)}) {
                ${GQDeliveryPointMapFields}
            } 
          }`;

    const data = await fetchApi.getGQ<{ searchStoreDeliveryPointsList: StoreDeliveryPointType[] }>(graphql);
    return data.searchStoreDeliveryPointsList;
};

export const GQDeliveryPointFields = `
    ${GQDeliveryPointMapFields}
    wayInfo
    workTime
`;

export const fetchItem = async (wb_store_id: number): Promise<StoreDeliveryPointType> => {
    const graphql = `{
            fetchWbStore(wb_store_id: ${wb_store_id}) {
                ${GQDeliveryPointFields}
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchWbStore: StoreDeliveryPointType }>(graphql);
    return data.fetchWbStore;
};

export type ClosestPointAccount = {
    account_id: number;
    gender: GenderType;
    distance: number;
    storeDeliveryPoint: StoreDeliveryPointType;

    ransom_id: number;
    ransomCreateTime: number;
    accountUpdateTime: number;
};

export const findClosestStoreAccountsByPoint = async (
    store_id: StoreIds,
    store_point_id: number,
    product_id: number | null,
    gender: GenderType
): Promise<ClosestPointAccount[]> => {
    const graphql = `{
        findClosestStoreAccountsByPoint(store_id: ${store_id}, store_point_id: ${store_point_id}, 
            product_id: ${objectToGraphql(product_id)}, gender: ${objectToGraphql(gender)}) {
                account_id
                gender
                distance
                storeDeliveryPoint {
                    ${GQDeliveryPointFields}
                }
                
                ransom_id
                ransomCreateTime
                accountUpdateTime
            } 
          }`;

    const data = await fetchApi.getGQ<{ findClosestStoreAccountsByPoint: ClosestPointAccount[] }>(graphql);
    return data.findClosestStoreAccountsByPoint;
};
