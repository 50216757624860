import React, { useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';

import ransomStore, { Ransom } from 'stores/ransomStore';
import productStore from 'stores/productStore';
import authStore from 'stores/authStore';

import Select from 'ui/Select';
import Checkbox from 'ui/Checkbox';

import ListFilterWrapper from '../ListFilterWrapper';
import StoresToggle from 'components/StoresToggle';

const RansomsFilter = () => {
    useMemo(() => {
        ransomStore.changeFilter('store_id', productStore.getCurrentStoreId());
        if (authStore.isAdmin()) {
            ransomStore.changeFilter('allUsers', true);
        }
        productStore.fetchList();
    }, []);

    useEffect(() => {
        ransomStore.changeFilter('store_id', productStore.getCurrentStoreId());
    }, [productStore.listFilter.store_id]);

    const { listFilter, deliveriesStoresOptions, loadingDeliveriesStores } = ransomStore;
    const { status, product_id, store_point_id, allUsers } = listFilter;

    const { loadingList: loadingListProduct, productOptions } = productStore;

    const handleChangeStatus = ({ value }: { value: Ransom['status'] | null }) => {
        ransomStore.changeFilter('status', value);
    };

    const handleChangeProductId = ({ value }: { value: number | null }) => {
        ransomStore.changeFilter('product_id', value);
    };

    const handleChangeStorePointId = ({ value }: { value: number | null }) => {
        ransomStore.changeFilter('store_point_id', value);
    };

    const handleToggleAllUsers = () => {
        ransomStore.changeFilter('allUsers', !allUsers);
    };

    return (
        <ListFilterWrapper store={ransomStore}>
            {authStore.isAdmin() && <Checkbox label="все" checked={allUsers} onClick={handleToggleAllUsers} />}

            <StoresToggle />

            <Select
                label="Статус"
                size="small"
                variant="standard"
                value={status}
                onChange={handleChangeStatus}
                options={[
                    { value: 1, text: 'Создается выкуп' },
                    { value: 2, text: 'Ожидает оплату' },
                    { value: 3, text: 'Доставка' },
                    { value: 4, text: 'Проверка оплаты' },
                    { value: 5, text: 'Ожидает' },
                    { value: 100, text: 'Ошибка' }
                ]}
                clearable
                fullWidth
            />

            <Select
                label="Товар"
                value={product_id}
                onChange={handleChangeProductId}
                options={productOptions}
                loading={loadingListProduct}
                size="small"
                variant="standard"
                fullWidth
                clearable
            />

            <Select
                label="ПВЗ"
                value={store_point_id}
                onChange={handleChangeStorePointId}
                options={deliveriesStoresOptions}
                loading={loadingDeliveriesStores}
                size="small"
                variant="standard"
                fullWidth
                clearable
            />
        </ListFilterWrapper>
    );
};

export default observer(RansomsFilter);
