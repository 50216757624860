import { useEffect, useMemo } from 'react';

import productStore, { ProductPrototypeFilter } from 'stores/productStore';
import { ListStoreInterface } from '../stores/prototypes/ListStore.prototype';
import authStore from '../stores/authStore';

export const useStoreHooks = <FilterType extends ProductPrototypeFilter>(store: ListStoreInterface) => {
    useMemo(() => {
        store.changeFilter('store_id', productStore.getCurrentStoreId());
        if (authStore.isAdmin()) {
            store.changeFilter('allUsers', true);
        }
        productStore.fetchList();
    }, []);

    useEffect(() => {
        store.changeFilter('store_id', productStore.getCurrentStoreId());
    }, [productStore.listFilter.store_id]);

    const handleChangeStatus = ({ value }: { value: FilterType['status'] | null }) => {
        store.changeFilter('status', value);
    };

    const handleChangeProductId = ({ value }: { value: number | null }) => {
        store.changeFilter('product_id', value);
    };

    const handleToggleAllUsers = (checked: boolean) => {
        store.changeFilter('allUsers', checked);
    };

    return {
        handleChangeStatus,
        handleChangeProductId,
        handleToggleAllUsers
    };
};
