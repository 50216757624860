import { observer } from 'mobx-react';
import React, { SyntheticEvent, useState } from 'react';
import { requestSeoPhotos } from 'api/userApi';

import commonStore from 'stores/commonStore';

// import Typography from '@mui/material/Typography';

import Modal, { ModalBtnAction, ModalContent } from 'ui/Modal';
import ListError from 'ui/ListError';
import Grid from 'ui/Grid';
import TextInput from 'ui/TextInput';

const SeoPhotosModal = ({ onClose }: { onClose: () => void }) => {
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const handleCreate = async () => {
        try {
            await requestSeoPhotos(comment);
            commonStore.setGlobalInfoMessage(
                'Заявка на "Фотосъемку ваших товаров" создана, мы свяжемся с вами в течение 24 часов'
            );
            onClose();
        } catch (errors) {
            if (errors instanceof Array) {
                setErrors(errors);
            }
            setLoading(false);
        }
    };

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'inherit' },
        {
            onClick: handleCreate,
            label: 'Оставить заявку',
            variant: 'contained',
            color: 'secondary',
            loading
        }
    ];

    const handleChangeComment = (event: SyntheticEvent, { value }: { value: string }) => {
        setComment(value);
    };

    return (
        <Modal
            maxWidth="sm"
            onClose={onClose}
            actions={actions}
            header='Заявка на "Фото для отзывов ваших товаров"'
            fullScreen={commonStore.isMobile}
        >
            <ModalContent>
                <ListError errors={errors} />
                <Grid.Column>
                    <TextInput
                        variant="standard"
                        label="Комментарий, пожелания (не обязательно)"
                        value={comment}
                        onChange={handleChangeComment}
                        maxLength={1000}
                        multiline
                        rowsMax={10}
                        rows={1}
                    />
                </Grid.Column>
            </ModalContent>
        </Modal>
    );
};

export default observer(SeoPhotosModal);
