import React, { SyntheticEvent, useState } from 'react';
import { observer } from 'mobx-react';

import commonStore from 'stores/commonStore';
import * as orderApi from 'api/orderApi';
import authStore from 'stores/authStore';

import Modal, { ModalContent } from 'ui/Modal';
import { ErrorMessage, WarningMessage } from 'ui/Message';
import Button from 'ui/Button';
import { PriceInput } from 'ui/TextInput';
import Table, { TableCell, TableHead, TableRow, TableBody } from 'ui/Table';
import Grid from 'ui/Grid';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PaymentIcon from '@mui/icons-material/Payment';
import MoneyIcon from '@mui/icons-material/Money';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

const discounts = [
    { sum: 200_000, discount: 50 },
    { sum: 100_000, discount: 40 },
    { sum: 50_000, discount: 30 },
    { sum: 25_000, discount: 20 },
    { sum: 10_000, discount: 10 }
];

const OrderModal = ({ onClose }: { onClose: () => void }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [cost, setCost] = useState(0);
    const handleCost = (event: SyntheticEvent, data: { value: number }) => {
        setCost(data.value);
    };

    const handleCreateOrder = async () => {
        setLoading(true);
        setError('');
        try {
            document.location.href = await orderApi.createOrder(cost);
        } catch (error) {
            setLoading(false);
            if (error instanceof Array) {
                setError(error[0]);
            }
        }
    };

    const sum30dPayments = Number(authStore?.currentUser?.sum30dPayments);
    const selectedDiscount = discounts.find(({ discount, sum }) => cost + sum30dPayments >= sum);

    const { currentUser } = authStore;
    const firstPaymentGift = Number(currentUser?.firstPaymentGift);
    const firstPaymentExpireTime = Number(currentUser?.firstPaymentExpireTime);

    return (
        <Modal
            onClose={onClose}
            fullScreen={commonStore.isMobile}
            header={
                <>
                    <MoneyIcon />
                    &nbsp;Пополнить счет
                </>
            }
            maxWidth="md"
        >
            <ModalContent dividers>
                {error && <ErrorMessage>{error}</ErrorMessage>}

                <Box
                    sx={{
                        background: `url('/static/payment_icons.jpg')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        height: '90px'
                    }}
                ></Box>

                <Grid columns={2} stackable>
                    <Grid.Column>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                            <WarningMessage>Прием платежей временно приостановлен</WarningMessage>
                            <div>
                                <PriceInput disabled label="Сумма пополнения" value={cost} onChange={handleCost} />
                            </div>

                            {firstPaymentGift > 0 && (
                                <Typography color="primary" variant="subtitle2">
                                    {!cost && (
                                        <>
                                            Бонус {firstPaymentGift}% на следующее пополнение.
                                            <br />
                                            Предложение действует до{' '}
                                            <Box sx={{ whiteSpace: 'nowrap', display: 'inline' }}>
                                                {new Date(firstPaymentExpireTime * 1000).toLocaleString('ru')}
                                            </Box>
                                        </>
                                    )}
                                    {cost > 0 && <>+{Math.round((cost * firstPaymentGift) / 100)} рублей в подарок</>}
                                </Typography>
                            )}

                            <Button
                                disabled={loading || cost < 1}
                                onClick={handleCreateOrder}
                                startIcon={<PaymentIcon />}
                                color="secondary"
                                variant="contained"
                            >
                                Оплатить
                            </Button>

                            <List dense disablePadding sx={{ mt: 2 }}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AccountBalanceWalletIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${sum30dPayments} рублей`}
                                        secondary="Ваша сумма пополнений за 30 дней, руб."
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid.Column>

                    <Grid.Column>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                            Сумма пополнений за 30 дней, чтобы получить скидку (скидка действует на все наши услуги)
                        </Typography>
                        <Typography variant="subtitle2" sx={{ mb: 1 }} color="success.dark">
                            При пополнении на {(5000).toLocaleString()}₽ и больше вы можете воспользоваться разовой
                            консультацией нашего SEO специалиста
                        </Typography>
                        <Table elevation={0}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Сумма пополнений за 30 дней</TableCell>
                                    <TableCell>Скидка</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[...discounts].reverse().map(({ sum, discount }) => (
                                    <TableRow key={sum} positive={selectedDiscount?.sum === sum}>
                                        <TableCell>от {sum.toLocaleString()} рублей</TableCell>
                                        <TableCell>{discount}%</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid.Column>
                </Grid>
            </ModalContent>
        </Modal>
    );
};

export default observer(OrderModal);
