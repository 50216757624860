import React from 'react';

import Table, { TableCell, TableRow, TableBody, TableHead } from 'ui/Table';
import Typography from '@mui/material/Typography';
import Price from 'components/Price';

import { deliveryPriceDataFbo, WarehouseDataTable } from './deliveryPriceData';
import { WarningMessage } from 'ui/Message';
import PriceTable from './PriceTable';

const DeliveryPriceTable = ({ whData }: { whData: WarehouseDataTable[] }) => {
    return (
        <Table size="medium">
            <TableHead>
                <TableRow>
                    <TableCell>Склад</TableCell>
                    <TableCell align="right">За 1 короб</TableCell>
                    <TableCell align="right">За 1 паллету</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {whData.map(({ warehouseName, box, pallet }) => (
                    <TableRow key={warehouseName}>
                        <TableCell>{warehouseName}</TableCell>
                        <TableCell align="right">
                            <Price price={box.price} priceWithSale={box.priceWithSale} direction="row-reverse" />
                        </TableCell>
                        <TableCell align="right">
                            {pallet && (
                                <Price
                                    price={pallet.price}
                                    priceWithSale={pallet.priceWithSale}
                                    direction="row-reverse"
                                />
                            )}
                            {!pallet && <>—</>}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

const FFPriceFbo = ({ store_id }: { store_id: number }) => {
    const fboData = deliveryPriceDataFbo.find(fbo => fbo.store_id === store_id);
    const { whData, title } = fboData || { whData: [], title: '' };

    return (
        <>
            {store_id !== 5 && (
                <>
                    <PriceTable
                        prices={[{ title: 'Оформление поставки в ЛК (до 10 SKU)', price: 1000, priceWithSale: 1000 }]}
                    />
                    <Typography variant="h5" color="secondary">
                        {title}
                    </Typography>
                    <DeliveryPriceTable whData={whData} />
                </>
            )}
            {store_id === 5 && (
                <WarningMessage header="Нет доставки по FBO">
                    На Avito нет доставки по FBO, только по FBS
                </WarningMessage>
            )}
        </>
    );
};

export default FFPriceFbo;
