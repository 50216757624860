import React, { SyntheticEvent, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';

import ozonAccountsStore from 'stores/ozonAccountsStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import Grid from 'ui/Grid';
import Button from 'ui/Button';
import TextInput from 'ui/TextInput';
import ListError from 'ui/ListError';
import { SuccessMessage } from 'ui/Message';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const phoneRegExp = new RegExp(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/);

let onMessage: (event: {
    data: { operation: string; cookies: { SecureRefreshToken: string; SecureAccessToken: string } };
}) => void;

const openOzon = () => {
    window.postMessage({
        operation: 'openOzon'
    });
};

const checkCookie = () => {
    window.postMessage({
        operation: 'getOzonCookies'
    });
};

const AddOzonBox = ({ onClose }: { onClose: () => void }) => {
    useMemo(() => {
        ozonAccountsStore.fetchItem(CREATING_ITEM_ID);
        ozonAccountsStore.setEditingItem(CREATING_ITEM_ID, {
            phone: '',
            email: '',
            SecureAccessToken: '',
            SecureRefreshToken: '',
            firstName: ''
        });
    }, []);

    onMessage = ({ data }) => {
        console.log('onmessage', data);
        if (data.operation === 'ozonCookies') {
            const { SecureAccessToken, SecureRefreshToken } = data.cookies;
            ozonAccountsStore.setEditingItem(CREATING_ITEM_ID, { SecureAccessToken, SecureRefreshToken });
        }
    };

    useEffect(() => {
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, []);

    const handleSave = async () => {
        if (await ozonAccountsStore.createItem()) {
            ozonAccountsStore.fetchList();
            onClose();
        }
    };

    const handleChangePhone = (event: SyntheticEvent, { value }: { value: string }) => {
        ozonAccountsStore.setEditingItem(CREATING_ITEM_ID, { phone: value.replace(/\D/gi, '') });
    };

    const handleChangeEmail = (event: SyntheticEvent, { value }: { value: string }) => {
        ozonAccountsStore.setEditingItem(CREATING_ITEM_ID, { email: value });
    };

    const handleChaneGender = (event: SyntheticEvent, gender: 'male' | 'female') => {
        ozonAccountsStore.setEditingItem(CREATING_ITEM_ID, { gender });
    };

    const handleChaneName = (event: SyntheticEvent, { value }: { value: string }) => {
        ozonAccountsStore.setEditingItem(CREATING_ITEM_ID, { firstName: value });
    };

    const {
        editingItem: { phone, SecureAccessToken, SecureRefreshToken, gender, firstName, email },
        loadingItem,
        errors
    } = ozonAccountsStore.getItem(CREATING_ITEM_ID);

    return (
        <Grid>
            <Grid.Column>
                <Button variant="text" color="inherit" startIcon={<ArrowBackIcon />} onClick={onClose}>
                    назад
                </Button>
            </Grid.Column>
            <Grid.Column>
                <Button color="primary" variant="outlined" onClick={openOzon} fullWidth>
                    Открыть "чистый" Ozon
                </Button>
            </Grid.Column>
            <Grid.Column>
                <ListError errors={errors} />
                <TextInput
                    label="Телефон"
                    required
                    onChange={handleChangePhone}
                    name="phone"
                    value={phone}
                    size="small"
                    variant="outlined"
                    mask="+7 (000) 000-00-00"
                    pattern={phoneRegExp}
                />
            </Grid.Column>
            <Grid.Column>
                <TextInput
                    label="Email"
                    required
                    onChange={handleChangeEmail}
                    name="email"
                    value={email}
                    size="small"
                    variant="outlined"
                />
            </Grid.Column>
            <Grid.Column>
                <TextInput
                    label="Имя"
                    required
                    onChange={handleChaneName}
                    name="firstName"
                    value={firstName}
                    size="small"
                    variant="outlined"
                />
            </Grid.Column>
            <Grid.Column>
                <ToggleButtonGroup size="small" color="secondary" value={gender} exclusive onChange={handleChaneGender}>
                    <ToggleButton value="female">Женщина</ToggleButton>
                    <ToggleButton value="male">Мужчина</ToggleButton>
                </ToggleButtonGroup>
            </Grid.Column>

            <Grid.Column>
                <Button color="info" variant="outlined" fullWidth onClick={checkCookie}>
                    Получить КУКУ
                </Button>
                {SecureAccessToken && SecureRefreshToken && <SuccessMessage>Кука получена</SuccessMessage>}
            </Grid.Column>

            <Grid.Column>
                <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={!SecureRefreshToken || !SecureRefreshToken}
                    loading={loadingItem}
                    onClick={handleSave}
                >
                    Сохранить
                </Button>
            </Grid.Column>
        </Grid>
    );
};

export default observer(AddOzonBox);
