import { action, makeObservable } from 'mobx';
import * as feedbackReactionApi from 'api/feedbackReactionApi';
import ListTransactionStorePrototype from './prototypes/ListTransactionsStore.prototype';
import { ProductList } from './productStore';

export type FeedbackReactionType = 'like' | 'dislike' | 'remove' | 'claim';

export enum ClaimTypes {
    Censored = 1,
    Fake,
    Spam,
    BadPhoto,
    NotOfProduct
}

export type FeedbackReaction = {
    feedback_reaction_id: number;
    user_id: number;
    feedback_id: number;

    type: FeedbackReactionType;
    claim: ClaimTypes | null;
    comment: string | null;
    status: 1 | 2 | 3 | 100;
    errorMessage: string | null;

    gender: 'male' | 'female' | null;
    searchRequest: string | null;

    quantity: number;
    speed: number | null;

    product_id: number;
    product: ProductList;
    text: string;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

class FeedbackReactionStore extends ListTransactionStorePrototype<FeedbackReaction, FeedbackReaction> {
    constructor() {
        super('feedback_reaction_id', 'feedbackReaction', feedbackReactionApi);
        makeObservable(this);
    }

    listFilterClear = {};

    @action
    async sendStatus(feedback_reaction_id: number, status: 2 | 3): Promise<void> {
        await this.fetchItem(feedback_reaction_id);
        this.setEditingItem(feedback_reaction_id, { status });
        await this.saveItem(feedback_reaction_id);
    }
}

export default new FeedbackReactionStore();
