import React from 'react';
import { observer } from 'mobx-react';

import ransomStore, { DeliveryPointAddress } from 'stores/ransomStore';

import RansomClosestAddressBtn from './RansomClosestAddressBtn';

const RansomAddressItemBtn = ({ ransom_id, addressIndex }: { ransom_id: number; addressIndex: number }) => {
    const { deliveryPointsAddresses = [] } = ransomStore.getItem(ransom_id).property;
    const { store_point_id } = deliveryPointsAddresses[addressIndex];
    const { gender = null, product_id = null } = ransomStore.getItem(ransom_id).editingItem;

    const onChangeStorePoint = (storeDeliveryPoint: DeliveryPointAddress) => {
        const deliveryPointsAddressesNew = [...deliveryPointsAddresses];
        const { store_point_id, address } = storeDeliveryPoint;
        deliveryPointsAddressesNew[addressIndex] = { store_point_id, address };
        ransomStore.setProperty(ransom_id, { deliveryPointsAddresses: deliveryPointsAddressesNew });

        ransomStore.setEditingItem(ransom_id, {
            store_point_ids: deliveryPointsAddressesNew.map(({ store_point_id }) => store_point_id)
        });
    };

    return (
        <RansomClosestAddressBtn
            store_point_id={store_point_id}
            gender={gender}
            product_id={product_id}
            onChangeStorePoint={onChangeStorePoint}
        />
    );
};

export default observer(RansomAddressItemBtn);
