import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import productStore, { Product } from 'stores/productStore';
import questionStore from 'stores/questionStore';
import videoStore from 'stores/videoStore';

import ListError from 'ui/ListError';
import LoaderAwait from 'ui/LoaderAwait';
import Grid from 'ui/Grid';
import Progress from 'ui/Progress';
import Button from 'ui/Button';
import { InfoMessage, SuccessMessage, WarningMessage } from 'ui/Message';

import Container from '@mui/material/Container';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import CachedIcon from '@mui/icons-material/Cached';

import ProductCard from '../Product/ProductCard';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import StoreRequestError from '../StoreRequestError';
import authStore from '../../../stores/authStore';
import StoreOpenAccountBtn from '../StoreOpenAccountBtn';

const QuestionShowMode = ({ question_id }: { question_id: number }) => {
    const { item, errors, loadingItem } = questionStore.getItem(question_id);
    const { status, product_id, gender, searchRequest, account_id, questionText, errorMessage } = item || {};

    const [fetchInterval, setFetchInterval] = useState<NodeJS.Timeout | null>(null);
    useEffect(() => {
        return () => {
            if (fetchInterval) {
                clearInterval(fetchInterval);
            }
        };
    }, [fetchInterval]);

    useEffect(() => {
        if (status === 1) {
            const fetchInterval = setInterval(() => {
                questionStore.fetchItem(question_id);
            }, 1000);
            setFetchInterval(fetchInterval);
        } else if (fetchInterval) {
            clearInterval(fetchInterval);
            setFetchInterval(null);
        }
        videoStore.findWbVideo('question', question_id);
    }, [status, question_id]);

    useEffect(() => {
        if (item?.product_id) {
            productStore.fetchItem(item?.product_id);
        }
    }, [item?.product_id]);

    if (!item) {
        return null;
    }

    let product: Product | null = null;
    if (product_id) {
        try {
            const productItem = productStore.getItem(product_id);
            product = productItem?.item || null;
        } catch (err) {}
    }

    const refreshStoreTime = async () => {
        await questionStore.refreshUpdateStoreQuestion(question_id);
    };

    return (
        <Container maxWidth="sm">
            <ListError errors={errors} />
            <LoaderAwait active={loadingItem} />
            <Grid>
                {status === 100 && (
                    <Grid.Column>
                        <StoreRequestError error={errorMessage} store_id={product?.store_id} />
                        <Button
                            endIcon={<CachedIcon />}
                            color="error"
                            fullWidth
                            onClick={refreshStoreTime}
                            disabled={loadingItem}
                        >
                            Попробовать еще раз
                        </Button>
                    </Grid.Column>
                )}

                <Grid.Column>
                    {authStore.isAdmin() && product && account_id && (
                        <StoreOpenAccountBtn
                            account_id={account_id}
                            store_id={product.store_id}
                            user_id={product.user_id}
                        />
                    )}
                    <SuccessMessage header="Вопрос">{questionText}</SuccessMessage>
                </Grid.Column>

                {!loadingItem && (
                    <Grid.Column>
                        {status === 1 && (
                            <>
                                <WarningMessage header="Статус">Идет отправка</WarningMessage>
                                <Progress show linear />
                            </>
                        )}
                        {status === 2 && <InfoMessage header="Статус">Вопрос отправлен</InfoMessage>}
                    </Grid.Column>
                )}
                <Grid.Column>
                    <ProductCard product={product} />
                </Grid.Column>
                <Grid.Column>
                    <List dense disablePadding>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <ManageSearchIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={searchRequest}
                                secondary={gender === 'male' ? 'вопрос от мужчины' : 'вопрос от женщины'}
                            />
                        </ListItem>
                    </List>
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default observer(QuestionShowMode);
