import React, { ReactNode, CSSProperties, SyntheticEvent } from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MUSelect, { SelectChangeEvent } from '@mui/material/Select';

import { DropdownType } from 'stores/prototypes/ListStore.prototype';
import Progress from './Progress';

import Tooltip from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type HandleChangeType = (data: { type?: string; name: any; value: any; array?: string; index?: number }) => void;

export type SelectMenuType = DropdownType & {
    caption?: string;
    style?: CSSProperties;
    disabled?: boolean;
};

type SelectProps = {
    label?: string | ReactNode;
    value: any;
    name?: string;
    onChange: HandleChangeType;
    options: SelectMenuType[];
    loading?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
    fullWidth?: boolean;
    clearable?: boolean;
    multiple?: boolean;
    variant?: 'standard' | 'outlined' | 'classic' | 'filled';
    size?: 'small' | 'medium';
    sx?: SxProps<Theme>;
    error?: boolean;
};

// const useStyles = makeStyles(() => ({
//     clearBtn: {
//         position: 'absolute',
//         bottom: '0px',
//         right: '35px',
//         top: 'calc(50% - 12px)'
//     }
// }));

const SelectClearBtn = ({ onClick }: { onClick: (event: SyntheticEvent) => void }) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: '0px',
                right: '35px',
                top: 'calc(50% - 12px)',
                zIndex: 1,
                cursor: 'pointer'
            }}
        >
            <Tooltip title="Очистить поле">
                <IconButton edge="end" color="inherit" onClick={onClick}>
                    <ClearIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

const Select = ({
    label,
    onChange,
    value,
    options,
    name,
    disabled,
    loading,
    fullWidth,
    style,
    clearable,
    multiple,
    variant,
    size,
    sx,
    error
}: SelectProps) => {
    const handleChange = (event: SelectChangeEvent<HTMLInputElement>) => {
        onChange({ type: '', name: name || '', value: event.target.value });
    };

    const handleReset = () => {
        onChange({ type: '', name: name || '', value: null });
    };

    return (
        <FormControl disabled={disabled} fullWidth={fullWidth} sx={sx}>
            {label && (
                <InputLabel
                    sx={variant === 'classic' ? { background: 'white', fontSize: '1.175rem', px: 1 } : undefined}
                >
                    {label}
                </InputLabel>
            )}
            <MUSelect
                value={value ?? ''}
                onChange={handleChange}
                multiple={multiple}
                style={variant === 'classic' ? { ...style, background: 'white' } : style}
                variant={variant === 'classic' ? 'outlined' : variant}
                size={size}
                error={error}
            >
                {options.map(({ text, disabled, key, value, style, caption }) => (
                    <MenuItem disabled={disabled} key={key || text} value={value as any} style={style}>
                        <Stack spacing={0} direction="column">
                            <Typography>{text}</Typography>
                            {caption && <Typography variant="caption">{caption}</Typography>}
                        </Stack>
                    </MenuItem>
                ))}
            </MUSelect>
            {clearable && Boolean(value) && <SelectClearBtn onClick={handleReset} />}

            {loading && <Progress show size={24} />}
        </FormControl>
    );
};

export default Select;
