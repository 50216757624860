import React, { CSSProperties } from 'react';
import { execDaily } from 'common/time';

const style: CSSProperties = { whiteSpace: 'nowrap' };

const Time = ({ time, maxDaysToTime = 2000 }: { time?: string | number | null; maxDaysToTime?: number }) => {
    if (time === undefined || time === null) {
        return (
            <span style={style} title="Время не установлено">
                --.-- --:--
            </span>
        );
    }

    const realMicroTime =
        typeof time === 'number' ? (time < 1000000000000 ? time * 1000 : time) : new Date(String(time)).getTime();
    const { printTime } = execDaily(realMicroTime, maxDaysToTime);
    const printDate = new Date(realMicroTime).toLocaleString('ru');

    return (
        <span title={printDate} style={style}>
            {printTime}
        </span>
    );
};

export default Time;
