import React from 'react';

import { Ransom, RansomStatus } from 'stores/ransomStore';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';
import Time from 'ui/Time';

import QrCodeIcon from '@mui/icons-material/QrCode';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import HomeIcon from '@mui/icons-material/Home';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import HeightIcon from '@mui/icons-material/Height';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';

import RansomFilterChips from '../EditingBlocks/RansomFilterChips';
import { wbFiltersOrders } from '../EditingBlocks/WbFilterOrderBy';
import ChangePaymentTypeModal from '../EditingBlocks/ChangePaymentTypeModal';
import RansomRivalsShow from './RansomRivalsShow';

export const RansomInfoShow = ({ ransom, firstName }: { ransom: Ransom | null; firstName?: string | null }) => {
    const [changePaymentType, togglePaymentType] = useToggleModal();

    const {
        ransom_id,
        searchRequest,
        deliveryStorePoint,
        gender,
        wbSize,
        paymentType,
        status,
        wbFilters = [],
        wbFilterOrderBy,
        planningExecuteTime
    } = ransom || {};

    let genderTitle = gender === null ? 'пол не указан' : gender === 'male' ? 'выкуп от мужчины' : 'выкуп от женщины';
    if (firstName) {
        genderTitle += ` (${firstName})`;
    }

    return (
        <>
            <List dense disablePadding>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <HomeIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={deliveryStorePoint?.address} secondary="Адрес доставки" />
                </ListItem>
                {wbSize && (
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <HeightIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={wbSize.sizeName} secondary={wbSize.sizeNameRus} />
                    </ListItem>
                )}
                {status !== RansomStatus.WaitingForPayment && paymentType && (
                    <ListItem
                        secondaryAction={
                            status && [RansomStatus.Error, RansomStatus.WaitingForExecution].includes(status) ? (
                                <Button
                                    icon={<EditIcon />}
                                    onClick={togglePaymentType}
                                    tooltip="Поменять способ оплаты"
                                />
                            ) : undefined
                        }
                    >
                        <ListItemAvatar>
                            <Avatar>
                                {paymentType === 1 && <QrCodeIcon />}
                                {paymentType === 2 && <AccountBalanceIcon />}
                                {paymentType === 3 && <CreditCardIcon />}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <>
                                    {paymentType === 1 && 'Оплата по QR (СБП)'}
                                    {paymentType === 2 && 'Оплата по SberPay'}
                                    {paymentType === 3 && 'Оплата Картой'}
                                </>
                            }
                        />
                    </ListItem>
                )}
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <ManageSearchIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={searchRequest} secondary={genderTitle} />
                </ListItem>
                {planningExecuteTime && (
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <AccessTimeIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<Time time={planningExecuteTime} />} secondary="Запланированное время" />
                    </ListItem>
                )}
                {wbFilters.length > 0 && (
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <FilterAltIcon />
                            </Avatar>
                        </ListItemAvatar>
                        {ransom_id && (
                            <ListItemText
                                primary={<RansomFilterChips ransom_id={ransom_id} />}
                                secondary={`Фильтры${
                                    wbFilterOrderBy !== 'popular'
                                        ? ` (сортировка по ${
                                              wbFiltersOrders.find(({ value }) => value === wbFilterOrderBy)?.text
                                          })`
                                        : ''
                                }`}
                            />
                        )}
                    </ListItem>
                )}
                <RansomRivalsShow ransom={ransom} />
            </List>
            {changePaymentType && ransom_id && (
                <ChangePaymentTypeModal ransom_id={ransom_id} onClose={togglePaymentType} />
            )}
        </>
    );
};

export default RansomInfoShow;
