import React from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import reviewStore, { ReviewType } from 'stores/reviewStore';
import authStore from 'stores/authStore';
import { StoreIds } from 'stores/productStore';

import Button from 'ui/Button';
import Confirm from 'ui/Confirm';
import { useToggleModal } from 'ui/Modal';
import { ErrorMessage } from 'ui/Message';

import CommentIcon from '@mui/icons-material/Comment';

import { ReviewLinkState } from '../ReviewLink';
import CostWithDiscount from 'components/CostWithDiscount';

const ReviewCreateBtn = ({ store_id, disabled }: { store_id: StoreIds; disabled: boolean }) => {
    const [showConfirm, toggleConfirm] = useToggleModal();

    const cost1 = 60;
    const cost2 = 110;

    const { loadingItem, editingItem } = reviewStore.getItem(CREATING_ITEM_ID);
    const { reviewText, ransom_id, type, reviewPhotos, reviewVideos } = editingItem;

    const sendReview = async () => {
        const review_id = await reviewStore.createItem();
        if (review_id) {
            locationHistory.replace(ReviewLinkState(review_id));
        }
    };

    const onConfirm = async () => {
        toggleConfirm();
        sendReview();
    };

    const handleCreate = async () => {
        if (reviewPhotos?.length || reviewVideos?.length) {
            toggleConfirm();
            return;
        }
        await sendReview();
    };

    const disable = loadingItem || !ransom_id;

    let confirmText = '';
    if (reviewPhotos?.length) {
        confirmText += `Количество загруженных фотографий: ${reviewPhotos?.length}`;
    }
    if (reviewVideos?.length) {
        confirmText += `${confirmText ? '\n' : ''}Количество загруженных видео: ${reviewVideos?.length}`;
    }

    const { settings } = authStore;
    if (store_id === StoreIds.WB && !settings['reviewsEnable_wb'] && type === ReviewType.Auto) {
        return (
            <ErrorMessage header="Временно отключены отзывы на WB">
                В ближайшее время возобновим возможность публикации отзывов
            </ErrorMessage>
        );
    }

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleCreate}
                loading={loadingItem}
                endIcon={<CommentIcon />}
                disabled={disabled || disable}
            >
                Добавить отзыв (
                <CostWithDiscount
                    cost={type === ReviewType.Auto ? cost1 : cost2}
                    disableDiscount={type === ReviewType.Manual}
                />
                )
            </Button>
            {showConfirm && (
                <Confirm
                    content={confirmText}
                    header="Отправить отзыв?"
                    confirmButton="Отправить"
                    onConfirm={onConfirm}
                    onCancel={toggleConfirm}
                />
            )}
        </>
    );
};

export default observer(ReviewCreateBtn);
