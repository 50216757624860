import React, { Fragment, ReactNode, CSSProperties } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import commonStore from 'stores/commonStore';

type ScrollbarProps = {
    disableOnMobile?: boolean;
    children: ReactNode;
    suppressScrollX?: boolean;
    style?: CSSProperties;
    className?: string;
    containerRef?: (element: HTMLElement) => void;
};

const Scrollbar = ({ disableOnMobile, children, className, style, suppressScrollX, containerRef }: ScrollbarProps) => {
    if (disableOnMobile && commonStore.isMobile) {
        return <Fragment>{children}</Fragment>;
    }

    return (
        <PerfectScrollbar
            containerRef={containerRef}
            options={suppressScrollX ? { suppressScrollX: true } : undefined}
            style={style}
            className={className}
        >
            {children}
        </PerfectScrollbar>
    );
};

export default Scrollbar;
