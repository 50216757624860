import React from 'react';
import { observer } from 'mobx-react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import commonStore from '../../stores/commonStore';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InfoSnackBar = () => {
    const { globalInfoMessage } = commonStore;

    const onClose = () => commonStore.setGlobalInfoMessage(null);

    let status: 'success' | 'error' = 'success';
    if (globalInfoMessage && globalInfoMessage.includes('Err')) {
        status = 'error';
    }

    return (
        <Snackbar
            open={Boolean(globalInfoMessage)}
            autoHideDuration={status === 'success' ? 6000 : 15000}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            onClose={onClose}
        >
            <Alert onClose={onClose} severity={status} sx={{ width: '100%' }}>
                {globalInfoMessage && globalInfoMessage.replace('Err', '')}
            </Alert>
        </Snackbar>
    );
};

export default observer(InfoSnackBar);
