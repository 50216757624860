import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import reviewStore, { ReviewType } from 'stores/reviewStore';
import { RansomType } from 'stores/ransomStore';
import authStore from 'stores/authStore';
import productStore, { StoreIds } from 'stores/productStore';

import { InfoMessage, WarningMessage, ErrorMessage } from 'ui/Message';

import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

const ReviewTypeEditing = ({ ransomType }: { ransomType?: RansomType }) => {
    const review_id = CREATING_ITEM_ID;

    const { type, product_id } = reviewStore.getItem(review_id).editingItem;

    const handleChangeType = (event: SyntheticEvent, type: ReviewType | null) => {
        if (type) {
            reviewStore.setEditingItem(review_id, { type });
        }
    };

    const { settings } = authStore;

    return (
        <>
            <Typography>Вариант публикации</Typography>
            <ToggleButtonGroup
                size="small"
                color="success"
                value={type}
                exclusive
                onChange={handleChangeType}
                fullWidth
            >
                <ToggleButton value={ReviewType.Auto}>Автоматическое размещение</ToggleButton>
                <ToggleButton value={ReviewType.Manual} disabled={ransomType === RansomType.Group}>
                    С оплатой за размещение
                </ToggleButton>
            </ToggleButtonGroup>
            {type === ReviewType.Auto && (
                <>
                    {!settings['reviewsEnable_wb'] &&
                        product_id &&
                        productStore.getCurrentStoreId() === StoreIds.WB && (
                            <ErrorMessage header="Вариант не доступен">
                                В данный момент возможно размещать только отзывы с Оплатой за размещение. ближайшее
                                время возобновим возможность публикации отзывов c автоматическим размещением.
                            </ErrorMessage>
                        )}
                    <WarningMessage header="Информация о данном варианте">
                        <ul style={{ padding: 0 }}>
                            <li>Отзыв будет опубликован через нашего робота</li>
                            <li>Вы сможете увидеть подтверждение публикации на видеоролике</li>
                        </ul>
                    </WarningMessage>
                </>
            )}
            {type === ReviewType.Manual && (
                <InfoMessage header="Информация о данном варианте">
                    <ul style={{ padding: 0 }}>
                        <li>Отзыв будет опубликован через нашего модератора вручную</li>
                        <li>Вероятность прохождение модерации на стороне Wildberries 7 из 10</li>
                        <li>Если отзыв "не пройдет", мы вернем вам деньги на счет в личном кабинете</li>
                    </ul>
                </InfoMessage>
            )}
        </>
    );
};

export default observer(ReviewTypeEditing);
