import React from 'react';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import authStore from '../stores/authStore';

const CostWithDiscount = ({
    cost,
    disableDiscount,
    costWithDiscount
}: {
    cost: number;
    disableDiscount?: boolean;
    costWithDiscount?: number;
}) => {
    const discount = disableDiscount ? 0 : Number(authStore.currentUser?.discount);

    if (!discount && !costWithDiscount) {
        return <span>{cost.toFixed(2)}₽</span>;
    }

    let adjustedCost = costWithDiscount || (cost * (100 - discount)) / 100;

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 0.5 }}>
            <Box sx={{ textDecoration: 'line-through', fontSize: '0.75rem' }}>{cost.toFixed(2)}</Box>
            <Box>{adjustedCost.toFixed(2)}₽</Box>
        </Box>
    );
};

export default observer(CostWithDiscount);
