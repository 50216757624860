import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import matchWbPhotoUrl from 'common/matchWbPhotoUrl';

import wbCardStore from 'stores/wbApi/wbCardStore';
import productStore from 'stores/productStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import Table, { TableCell, TableHead, TableRow, TableBody } from 'ui/Table';
import Button from 'ui/Button';
import { WarningMessage } from 'ui/Message';
import ListError from 'ui/ListError';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import ListPagination from '../../ListPagination';
import CardApiModal from './CardApiModal';
import Price from 'components/Price';
import AddItemColorModal from 'components/Items/Product/StoreBlocks/AddItemColorModal';
import ProductLink from 'components/Items/Product/ProductLink';

const ApiCardsList = () => {
    useEffect(() => {
        productStore.fetchItem(CREATING_ITEM_ID);
        wbCardStore.fetchList();
    }, []);

    const { list, loadingList, listErrors } = wbCardStore;
    const [activeWbItemId, setActiveWbItemId] = useState<number | null>(null);
    const [addingWbItemId, setAddingWbItemId] = useState<{ title: string; wb_item_id: number } | null>(null);

    const onAddidngClose = () => {
        setAddingWbItemId(null);
        wbCardStore.fetchList();
    };

    return (
        <>
            <ListError errors={listErrors} />
            <Table elevation={0} loading={loadingList}>
                <TableHead>
                    <TableRow>
                        <TableCell>Артикул</TableCell>
                        <TableCell sx={{ minWidth: '250px' }}>Наименование</TableCell>
                        <TableCell>Цена</TableCell>
                        <TableCell>Промокод</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(({ nmId, price, discount, product_id, wbItem, promoCode }) => (
                        <TableRow key={nmId}>
                            <TableCell>
                                {!product_id && (
                                    <Button
                                        tooltip="Добавить в Мои товары"
                                        onClick={() => setAddingWbItemId({ wb_item_id: nmId, title: wbItem?.title })}
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                    >
                                        {nmId}
                                    </Button>
                                )}
                                {product_id && (
                                    <Button
                                        tooltip="Редактировать карточку по Api"
                                        onClick={() => setActiveWbItemId(nmId)}
                                        color="secondary"
                                        variant="outlined"
                                        size="small"
                                    >
                                        {nmId}
                                    </Button>
                                )}
                            </TableCell>
                            <TableCell>
                                {!product_id && wbItem?.title}

                                {product_id && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                        <div>
                                            <Avatar src={matchWbPhotoUrl(nmId).preview} variant="rounded" />
                                        </div>
                                        <ProductLink product_id={product_id} title={wbItem.title} />
                                    </Box>
                                )}
                            </TableCell>
                            <TableCell>
                                <Price price={price} priceWithSale={(price * (100 - discount)) / 100} />
                            </TableCell>
                            <TableCell>{promoCode}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <ListPagination
                    pagination={wbCardStore.pagination}
                    listCount={wbCardStore.listCount}
                    pageChange={wbCardStore.pageChange}
                    pageSizeChange={wbCardStore.pageSizeChange}
                    colSpan={3}
                />
            </Table>
            {activeWbItemId && <CardApiModal wb_item_id={activeWbItemId} onClose={() => setActiveWbItemId(null)} />}
            {addingWbItemId && (
                <AddItemColorModal
                    onClose={onAddidngClose}
                    wb_item_id={addingWbItemId.wb_item_id}
                    title={addingWbItemId.title}
                    disableGoToCard
                />
            )}
            <Box sx={{ my: 2 }}>
                <WarningMessage header="Beta-версия">
                    Данный раздел находится в стадии тестирования, если вы заметили ошибки при работе с Api WB, просим
                    написать об этом на @TopStoreAdm
                </WarningMessage>
            </Box>
        </>
    );
};

export default observer(ApiCardsList);
