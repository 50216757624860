import React, { useState } from 'react';

import feedbackReactionStore, { ClaimTypes, FeedbackReaction } from 'stores/feedbackReactionStore';
import authStore from 'stores/authStore';
import commonStore from 'stores/commonStore';
import Time from 'ui/Time';
import Modal, { ModalContent } from 'ui/Modal';
import ListError from 'ui/ListError';
import Grid from 'ui/Grid';
import Button from 'ui/Button';

import Chip from '@mui/material/Chip';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

const printClaimType = (claim: ClaimTypes): string => {
    switch (claim) {
        case ClaimTypes.Censored:
            return 'Отзыв содержит нецензурную, бранную лексику';
        case ClaimTypes.Fake:
            return 'Заказной, фиктивный';
        case ClaimTypes.Spam:
            return 'Спам';
        case ClaimTypes.BadPhoto:
            return 'Некорректное фото, видео';
        case ClaimTypes.NotOfProduct:
            return 'Отзыв не о товаре';
        default:
            const error: never = claim;
            return '';
    }
};

const FeedbackReactionInfoModal = ({
    feedbackReaction,
    onClose
}: {
    feedbackReaction: FeedbackReaction;
    onClose: () => void;
}) => {
    const { text, type, claim, createTime, quantity, status, feedback_reaction_id } = feedbackReaction;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    let titleReaction = type === 'like' ? 'Лайк' : 'Дизлайк';
    let titleReactionHeader =
        type === 'claim'
            ? `Жалоба на отзыв: ${claim && printClaimType(claim)}`
            : type === 'remove'
            ? 'Удалить'
            : `${titleReaction} на`;

    const handleDone = async () => {
        setLoading(true);
        try {
            await feedbackReactionStore.sendStatus(feedback_reaction_id, 2);
        } catch (errors) {
            if (errors instanceof Array) {
                setErrors(errors);
            }
        } finally {
            setLoading(false);
            feedbackReactionStore.fetchList();
            onClose();
        }
    };

    const handleReject = async () => {
        setLoading(true);
        try {
            await feedbackReactionStore.sendStatus(feedback_reaction_id, 3);
        } catch (errors) {
            if (errors instanceof Array) {
                setErrors(errors);
            }
        } finally {
            setLoading(false);
            feedbackReactionStore.fetchList();
            onClose();
        }
    };

    return (
        <Modal
            maxWidth="sm"
            onClose={onClose}
            header={
                <>
                    <ThumbsUpDownIcon />
                    &nbsp;{titleReactionHeader} отзыв
                </>
            }
            fullScreen={commonStore.isMobile}
        >
            <ModalContent>
                <ListError errors={errors} />
                <Grid disableGutter>
                    <Grid.Column>
                        Заявка создана: <Time time={createTime} />
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }} disablePadding>
                            <ListItem alignItems="flex-start">{text}</ListItem>
                        </List>
                    </Grid.Column>
                    <Grid.Column>
                        {status === 1 && <Chip label="Выполняется" color="warning" />}
                        {status === 2 && <Chip label="Выполнено" color="success" />}
                        {status === 3 && <Chip label="Возврат" color="error" />}
                        {quantity > 1 && <Typography>Количество: {quantity}</Typography>}
                    </Grid.Column>
                </Grid>
                {authStore.isAdmin() && type === 'remove' && (
                    <Grid columns={2}>
                        <Grid.Column>
                            <Button
                                fullWidth
                                color="secondary"
                                variant="outlined"
                                onClick={handleReject}
                                loading={loading}
                            >
                                Возврат
                            </Button>
                        </Grid.Column>
                        <Grid.Column>
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                onClick={handleDone}
                                loading={loading}
                            >
                                Удаление выполнено
                            </Button>
                        </Grid.Column>
                    </Grid>
                )}
            </ModalContent>
        </Modal>
    );
};

export default FeedbackReactionInfoModal;
