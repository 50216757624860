import React from 'react';
import matchWbPhotoUrl from 'common/matchWbPhotoUrl';

import { Ransom } from 'stores/ransomStore';
import { StoreIds } from 'stores/productStore';

import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import RansomOtherItemsList from './RansomOtherItemsList';

export const RansomAdditionalShow = ({ ransom }: { ransom: Ransom | null }) => {
    const { additionalStoreItems = [] } = ransom || {};

    if (!additionalStoreItems.length) {
        return null;
    }

    return (
        <Paper elevation={2} sx={{ mt: 3, p: 1 }}>
            <Typography variant="subtitle1">Дополнительные товары в выкупе</Typography>
            <List dense disablePadding>
                <RansomOtherItemsList
                    list={additionalStoreItems.map(({ title, wb_item_id, price }) => ({
                        store_id: StoreIds.WB,
                        store_item_id: wb_item_id,
                        title,
                        price,
                        photoUrl: matchWbPhotoUrl(wb_item_id).preview
                    }))}
                />
            </List>
        </Paper>
    );
};

export default RansomAdditionalShow;
