import React from 'react';
import { Link } from 'react-router-dom';

import commonStore from 'stores/commonStore';

import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Button from 'ui/Button';
import Table, { TableBody, TableCell, TableRow } from 'ui/Table';

import FFProsList from './FFProsList';
import FFHeader from './FFHeader';
import FFFooter from './FFFooter';
import FFAboutWarehouse from './FFAboutWarehouse';

import ListContainer from '../ListContainer';
import Price from 'components/Price';

const SeoFulfillment = () => {
    const { isMobile } = commonStore;

    return (
        <ListContainer bgUrl="/static/listbg/unruly-05-done.webp">
            <Container maxWidth="lg" sx={{ px: isMobile ? 0 : 2 }}>
                <Stack spacing={3}>
                    <FFHeader />

                    <Paper elevation={1} sx={{ bgcolor: 'primary.dark', color: 'white', p: 3 }}>
                        <Stack spacing={2} alignItems="center">
                            <Typography variant="h5">
                                Мы исключили все скрытые комиссии и сделали максимально простую формулу расчета
                            </Typography>

                            <Container maxWidth="sm" sx={{ px: isMobile ? 0 : 3 }}>
                                <Table size="medium">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Фулфилмент</TableCell>
                                            <TableCell>
                                                <Stack
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                    direction="row"
                                                    spacing={1}
                                                >
                                                    <div>От</div>
                                                    <Price price={30} priceWithSale={5} direction="row-reverse" />
                                                    <div>за единицу</div>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Доставка на склад МП</TableCell>
                                            <TableCell>
                                                <Stack
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                    direction="row"
                                                    spacing={1}
                                                >
                                                    <div>От</div>
                                                    <Price price={850} priceWithSale={250} direction="row-reverse" />
                                                    <div>за короб</div>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Container>

                            <Link to={'/fulfillment/price'} style={{ textDecoration: 'none' }}>
                                <Button color="secondary" variant="contained">
                                    Калькулятор цен
                                </Button>
                            </Link>
                        </Stack>
                    </Paper>

                    <Paper elevation={1} sx={{ p: isMobile ? 2 : 3, pt: 0 }}>
                        <FFProsList />
                    </Paper>

                    <Paper elevation={1} sx={{ p: isMobile ? 2 : 3, pt: 0 }}>
                        <FFAboutWarehouse />
                    </Paper>

                    <FFFooter />
                </Stack>
            </Container>
        </ListContainer>
    );
};

export default SeoFulfillment;
