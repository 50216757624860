export const HOUR_SEC = 3600;
export const HOUR_MS = HOUR_SEC * 1000;
export const DAY_SEC = 24 * HOUR_SEC;
export const DAY_MS = 24 * HOUR_MS;

export const speedOptions = [
    { value: 0, text: 'Как можно быстрее' },
    { value: HOUR_SEC, text: '1 час' },
    { value: 5 * HOUR_SEC, text: '5 часов' },
    { value: 12 * HOUR_SEC, text: '12 часов' },
    { value: 24 * HOUR_SEC, text: 'день' },
    { value: 48 * HOUR_SEC, text: '2 дня' }
];

const matchHourName = (hour: number): string => {
    if (hour === 1) {
        return 'один час';
    } else if (hour === 2) {
        return 'два часа';
    } else if (hour === 21) {
        return `${hour} час`;
    } else if ((hour > 2 && hour < 5) || (hour >= 22 && hour <= 24)) {
        return `${hour} часа`;
    } else {
        return `${hour} часов`;
    }
};

export const addZero = (i: number): string => {
    if (i < 10) {
        return '0' + i;
    }
    return String(i);
};

export const toDateString = function (date: Date | number): string {
    if (typeof date === 'number') {
        date = new Date(date * 1000);
    }
    return `${addZero(date.getDate())}.${addZero(date.getMonth() + 1)}`;
};

export const execTime = (time: string | number): { printTime: string; deltaTime: number } => {
    const date = new Date(typeof time === 'number' ? (time < 1000000000000 ? time * 1000 : time) : time);
    if (date.getTime() === 0) {
        return {
            printTime: '---',
            deltaTime: Infinity
        };
    }

    const deltaTime = Date.now() - date.getTime();

    let printTime = '';

    let days = Math.floor(deltaTime / DAY_MS);
    let hours = Math.floor(deltaTime / HOUR_MS);

    if (deltaTime > 31 * DAY_MS) {
        printTime = date.toLocaleDateString('ru');
    } else if (deltaTime > 5 * DAY_MS) {
        printTime = `${days} дней`;
    } else if (deltaTime > 3 * DAY_MS) {
        printTime = `${days} дня`;
    } else if (deltaTime > 2 * DAY_MS) {
        hours -= 48;

        if (hours > 12) {
            days = 2.5;
        }

        printTime = `${days} дня`;
    } else if (deltaTime > DAY_MS) {
        hours -= 24;

        printTime = '1 день' + (hours > 1 ? ` ${matchHourName(hours)}` : '');
    } else if (deltaTime > HOUR_MS) {
        printTime = matchHourName(hours);
    } else {
        let min = Math.round(deltaTime / 60000);

        if (min > 15) {
            min = Math.floor(min / 15) * 15;
        }

        switch (min) {
            case 0:
                printTime = `только что`;
                break;
            case 1:
                printTime = `${min} минуту`;
                break;
            case 2:
                printTime = `${min} минуты`;
                break;
            case 3:
                printTime = `${min} минуты`;
                break;
            case 4:
                printTime = `${min} минуты`;
                break;
            default:
                printTime = `${min} минут`;
                break;
        }
    }

    return { printTime, deltaTime };
};

const pad = function (num: number): string {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
};

export const execDaily = (time: number, maxDaysToTime: number = 2000): { printTime: string; deltaTime: number } => {
    if (time === 0) {
        return {
            printTime: '---',
            deltaTime: Infinity
        };
    }

    const date = new Date(time);
    const currentDate = new Date();
    const prevDayDate = new Date(Date.now() - DAY_MS);
    const nextDayDate = new Date(Date.now() + DAY_MS);

    let printTime = '';

    const deltaTime = Date.now() - date.getTime();

    const localeDateString = date.toLocaleDateString('ru');

    if (deltaTime >= 0 && deltaTime < HOUR_MS) {
        let min = Math.round(deltaTime / 60000);

        if (min > 15) {
            min = Math.floor(min / 15) * 15;
        }

        switch (min) {
            case 0:
                printTime = `только что`;
                break;
            case 1:
                printTime = `${min} мин назад`;
                break;
            case 2:
                printTime = `${min} мин назад`;
                break;
            case 3:
                printTime = `${min} мин назад`;
                break;
            case 4:
                printTime = `${min} мин назад`;
                break;
            default:
                printTime = `${min} мин назад`;
                break;
        }
    } else if (localeDateString === currentDate.toLocaleDateString('ru')) {
        printTime = `сегодня, ${pad(date.getHours())}:${pad(date.getMinutes())}`;
    } else if (localeDateString === prevDayDate.toLocaleDateString('ru')) {
        printTime = `вчера, ${pad(date.getHours())}:${pad(date.getMinutes())}`;
    } else if (localeDateString === nextDayDate.toLocaleDateString('ru')) {
        printTime = `завтра, ${pad(date.getHours())}:${pad(date.getMinutes())}`;
    } else if (Math.abs(deltaTime) < DAY_MS * maxDaysToTime) {
        printTime = `${localeDateString}, ${pad(date.getHours())}:${pad(date.getMinutes())}`;
    } else {
        printTime = `${localeDateString}`;
    }

    return { printTime, deltaTime };
};

export function secondsToMS(time: number): string {
    // Hours, minutes and seconds
    const hrs = ~~(time / 3600);
    const mins = ~~((time % 3600) / 60);
    const secs = time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = '';

    if (hrs > 0) {
        ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }

    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;
    return ret;
}

export const printDeltaDaysMs = (t1ms: number, t2ms: number): string => {
    const deltaDays = Math.ceil((t2ms - t1ms) / DAY_MS);
    if (deltaDays > 10 && deltaDays < 20) {
        return `${deltaDays} дней`;
    } else if (deltaDays % 10 === 1) {
        return `${deltaDays} день`;
    } else if ([2, 3, 4].includes(deltaDays % 10)) {
        return `${deltaDays} дня`;
    }
    return `${deltaDays} дней`;
};
