import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import productStore, { Product } from 'stores/productStore';
import reviewStore, { ReviewStatusEnum, ReviewType } from 'stores/reviewStore';
import videoStore from 'stores/videoStore';
import deliveryStore from 'stores/deliveryStore';
import authStore from 'stores/authStore';

import ListError from 'ui/ListError';
import LoaderAwait from 'ui/LoaderAwait';
import Grid from 'ui/Grid';
import Progress from 'ui/Progress';
import Button from 'ui/Button';
import { ErrorMessage, InfoMessage, SuccessMessage, WarningMessage } from 'ui/Message';
import { useToggleModal } from 'ui/Modal';
import Confirm from 'ui/Confirm';
import Time from 'ui/Time';

import Container from '@mui/material/Container';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import CachedIcon from '@mui/icons-material/Cached';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ProductCard from '../Product/ProductCard';
import StoreRequestError from '../StoreRequestError';
import StoreOpenAccountBtn from '../StoreOpenAccountBtn';
import ReviewPultButtons from './ShowBlocks/ReviewPultButtons';
import ReviewPhotosShow from './ShowBlocks/ReviewPhotosShow';

const CancelReviewBtn = ({ review_id, disabled }: { review_id: number; disabled: boolean }) => {
    const [showCancel, toggleCancel] = useToggleModal();
    const handleReject = () => {
        reviewStore.sendStatus(review_id, 4);
    };

    return (
        <>
            <Button color="inherit" onClick={toggleCancel} disabled={disabled} fullWidth>
                Отменить
            </Button>
            {showCancel && (
                <Confirm
                    content="Потраченные средства за отзыв вернутся вам на счет TopStore"
                    header="Отмена отзыва"
                    confirmButton="Отменить отзыв"
                    onConfirm={handleReject}
                    onCancel={toggleCancel}
                />
            )}
        </>
    );
};

const ReviewShowMode = ({ review_id }: { review_id: number }) => {
    const [isSending, setSending] = useState(false);

    const { item, errors, loadingItem } = reviewStore.getItem(review_id);
    const {
        status,
        product_id,
        reviewText,
        prosText,
        consText,
        errorMessage,
        rating,
        type,
        planningExecuteTime,
        account_id,
        createTime,
        isRecommended,
        isAnonymously
    } = item || { status: ReviewStatusEnum.Creating };

    const [fetchInterval, setFetchInterval] = useState<NodeJS.Timeout | null>(null);
    useEffect(() => {
        return () => {
            if (fetchInterval) {
                clearInterval(fetchInterval);
            }
        };
    }, [fetchInterval]);

    useEffect(() => {
        if ([ReviewStatusEnum.Creating, ReviewStatusEnum.WaitingForModeration].includes(status)) {
            const fetchInterval = setInterval(() => {
                reviewStore.fetchItem(review_id);
            }, 1000);
            setFetchInterval(fetchInterval);
        } else if (fetchInterval) {
            clearInterval(fetchInterval);
            setFetchInterval(null);
        }
        deliveryStore.fetchList();
        videoStore.findWbVideo('review', review_id);
    }, [status, review_id]);

    useEffect(() => {
        if (item?.product_id) {
            productStore.fetchItem(item?.product_id);
        }
    }, [item?.product_id]);

    let product: Product | null = null;
    if (product_id) {
        try {
            const productItem = productStore.getItem(product_id);
            product = productItem?.item || null;
        } catch (err) {}
    }

    const refreshWbTime = async () => {
        setSending(true);
        await reviewStore.refreshUpdateWbReview(review_id);
        setSending(false);
    };

    return (
        <Container maxWidth="sm">
            <ListError errors={errors} />
            <Grid>
                <Grid.Column>
                    <InfoMessage header="Тип отзыва">
                        {type === ReviewType.Auto ? 'Автоматическое размещение' : 'С оплатой за размещение'}
                        {status === ReviewStatusEnum.WaitingForExecution && planningExecuteTime && (
                            <Box sx={{ mt: 1 }}>
                                Запланированное время отправки:&nbsp;
                                <b>
                                    <Time time={planningExecuteTime} />
                                </b>
                            </Box>
                        )}
                        {status === ReviewStatusEnum.WaitingForExecution && !planningExecuteTime && (
                            <Box sx={{ mt: 1 }}>
                                <b>В очереди на размещение, скоро будет опубликован</b>
                            </Box>
                        )}
                    </InfoMessage>
                    <LoaderAwait active={loadingItem || isSending} linear />
                </Grid.Column>

                {status === ReviewStatusEnum.Error && (
                    <Grid.Column>
                        <StoreRequestError error={errorMessage} store_id={product?.store_id} />
                        {type !== ReviewType.Manual && (
                            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                                <Button
                                    endIcon={<CachedIcon />}
                                    color="error"
                                    fullWidth
                                    onClick={refreshWbTime}
                                    disabled={loadingItem || isSending}
                                >
                                    Попробовать еще раз
                                </Button>
                                <CancelReviewBtn review_id={review_id} disabled={loadingItem || isSending} />
                            </Stack>
                        )}
                    </Grid.Column>
                )}

                {status === 4 && (
                    <Grid.Column>
                        <ErrorMessage header="Не получилось опубликовать отзыв">
                            Маркетплейс не пропустил данный отзыв. Оплата за отзыв была возвращена вам на счет.
                        </ErrorMessage>
                    </Grid.Column>
                )}

                <Grid.Column>
                    <Typography>
                        {authStore.isAdmin() && product && account_id && (
                            <StoreOpenAccountBtn
                                account_id={account_id}
                                store_id={product.store_id}
                                user_id={product.user_id}
                            />
                        )}
                        Отзыв создан <Time time={createTime} />
                    </Typography>
                    <SuccessMessage header="Отзыв">
                        <Stack spacing={1}>
                            {rating && <Rating defaultValue={rating} readOnly />}
                            <div>{reviewText}</div>
                            {prosText && (
                                <div>
                                    <b>Достоинства:</b> {prosText}
                                </div>
                            )}
                            {consText && (
                                <div>
                                    <b>Недостатки:</b> {consText}
                                </div>
                            )}
                            {isRecommended && (
                                <div>
                                    <b>Рекомендую</b>
                                </div>
                            )}
                            {isAnonymously && (
                                <div>
                                    <b>Анонимный отзыв</b>
                                </div>
                            )}
                        </Stack>
                    </SuccessMessage>
                    <ReviewPhotosShow review_id={review_id} />
                </Grid.Column>

                {!loadingItem && (
                    <Grid.Column>
                        {status === ReviewStatusEnum.Creating && (
                            <>
                                <WarningMessage header="Статус">Идет отправка</WarningMessage>
                                <Progress show linear />
                            </>
                        )}
                        {status === ReviewStatusEnum.WaitingForModeration && (
                            <WarningMessage header="Статус">
                                <b>Ожидает модерации</b>
                                <ul>
                                    <li>
                                        Все опубликованные отзывы проходят модерацию на стороне Wildberries. Поэтому мы
                                        обычно публикуем отзывы в будний день в наиболее загруженное время, когда
                                        вероятность прохождения модерации в ВБ максимальна.
                                    </li>
                                </ul>
                            </WarningMessage>
                        )}
                        {status === ReviewStatusEnum.Done && <InfoMessage header="Статус">Отзыв отправлен</InfoMessage>}
                    </Grid.Column>
                )}
                <Grid.Column>
                    <ProductCard product={product} />
                </Grid.Column>

                <Grid.Column>
                    <ReviewPultButtons review_id={review_id} refreshWbTime={refreshWbTime} />
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default observer(ReviewShowMode);
