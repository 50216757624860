import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import userStore from 'stores/userStore';
import deliveryStore from 'stores/deliveryStore';

import Stack from '@mui/material/Stack';

import Select from 'ui/Select';
import Button from 'ui/Button';

const MajorUserDropdown = ({ delivery_id }: { delivery_id: number }) => {
    const { courierOptions, loadingCouriers } = userStore;
    const {
        editingItem: { major_user_id = null },
        item: delivery,
        loadingItem
    } = deliveryStore.getItem(delivery_id);

    useMemo(() => {
        if (delivery?.major_user_id) {
            deliveryStore.setEditingItem(delivery_id, { major_user_id: delivery.major_user_id });
        }
    }, [delivery]);

    const handleChange = ({ value }: { value: number }) => {
        deliveryStore.setEditingItem(delivery_id, { major_user_id: value });
    };

    const handleSave = () => {
        deliveryStore.saveItem(delivery_id);
    };

    return (
        <Stack direction="row">
            <Select
                label="Ответственный сотрудник"
                loading={loadingCouriers}
                value={major_user_id}
                onChange={handleChange}
                options={courierOptions}
                fullWidth
                variant="classic"
                clearable
            />
            <Button
                onClick={handleSave}
                color="secondary"
                variant="contained"
                disabled={delivery?.major_user_id === major_user_id}
                loading={loadingItem}
            >
                Сохранить
            </Button>
        </Stack>
    );
};

export default observer(MajorUserDropdown);
