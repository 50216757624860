import React from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import deliveryStore from 'stores/deliveryStore';
import productStore, { StoreIds } from 'stores/productStore';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import { WarningMessage } from 'ui/Message';

import DeliveryStatus from '../DeliveryStatus';
import WbItemAvatar from 'components/Items/Product/WbItemAvatar';
import { DeliveryLinkState } from 'components/Items/Delivery/DeliveryLink';

const DeliveryMapListItems = () => {
    const { mapDeliveriesList } = deliveryStore;

    const handleOpen = (delivery_id: number) => {
        locationHistory.push(DeliveryLinkState(delivery_id));
    };

    if (!mapDeliveriesList.length) {
        return (
            <WarningMessage header="Доставки не найдены">Поменяйте область поиск на карте или фильтр</WarningMessage>
        );
    }

    return (
        <List dense disablePadding>
            {mapDeliveriesList.slice(0, 100).map(({ delivery_id, address, status, store_item_id }) => (
                <ListItem key={delivery_id} disableGutters>
                    <ListItemButton onClick={() => handleOpen(delivery_id)}>
                        {productStore.getCurrentStoreId() === StoreIds.WB && (
                            <ListItemAvatar>
                                <Avatar>
                                    <WbItemAvatar wb_item_id={store_item_id} size={40} />
                                </Avatar>
                            </ListItemAvatar>
                        )}

                        <ListItemText
                            primary={address}
                            secondary={<DeliveryStatus status={status} enable={true} />}
                        ></ListItemText>
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
};

export default observer(DeliveryMapListItems);
