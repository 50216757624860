import React, { SyntheticEvent, useState } from 'react';
import { observer } from 'mobx-react';

import { sendTgUserMessage } from 'api/userApi';
import wait from 'common/wait';

import Grid from 'ui/Grid';
import Button from 'ui/Button';
import TextInput from 'ui/TextInput';
import ListError from 'ui/ListError';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const DEFAULT_HEADER = 'Автоматическое сообщение';

const UserMessageMode = ({ user_id }: { user_id: number }) => {
    const [message, setMessage] = useState('');
    const handleMessage = (event: SyntheticEvent, { value }: { value: string }) => {
        setMessage(value);
    };

    const [header, setHeader] = useState(DEFAULT_HEADER);
    const handleHeader = (event: SyntheticEvent, { value }: { value: string }) => {
        setHeader(value);
    };

    const [sending, setSending] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const handleSend = async () => {
        try {
            setSending(true);
            await wait(500);
            await sendTgUserMessage(user_id, header, message);
            setHeader(DEFAULT_HEADER);
            setMessage('');
        } catch (errors) {
            if (errors instanceof Array) {
                setErrors(errors);
            }
        } finally {
            setSending(false);
        }
    };

    return (
        <>
            <ListError errors={errors} />
            <Typography variant="h6">user_id_#{user_id}</Typography>

            <Paper sx={{ p: 1 }}>
                <Grid>
                    <Grid.Column>
                        <TextInput
                            variant="standard"
                            label="Заголовок"
                            value={header}
                            onChange={handleHeader}
                            maxLength={1000}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            variant="standard"
                            label="Сообщение"
                            value={message}
                            onChange={handleMessage}
                            maxLength={10000}
                            multiline
                            rowsMax={20}
                            rows={3}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Button
                            variant="contained"
                            disabled={!message}
                            color="primary"
                            loading={sending}
                            onClick={handleSend}
                        >
                            Отправить
                        </Button>
                    </Grid.Column>
                </Grid>
            </Paper>
        </>
    );
};

export default observer(UserMessageMode);
