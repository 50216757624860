import React, { useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FFDeliveryMPToggle from './FFDeliveryMPToggle';
import FFDeliveryTypeToggle from './FFDeliveryTypeToggle';
import FFPriceFbo from './FFPriceFbo';
import FFPriceFbs from './FFPriceFbs';

import Table, { TableBody, TableCell, TableHead, TableRow } from 'ui/Table';
import Price from 'components/Price';

const FFPriceDelivery = () => {
    const [store_id, setStoreId] = useState(1);
    const [deliveryType, setDeliveryType] = useState<1 | 2>(1);

    return (
        <Stack spacing={4}>
            <FFDeliveryTypeToggle type={deliveryType} onChane={setDeliveryType} />
            <FFDeliveryMPToggle store_id={store_id} onChange={setStoreId} />

            {deliveryType === 1 && <FFPriceFbo store_id={store_id} />}
            {deliveryType === 2 && <FFPriceFbs store_id={store_id} />}

            <Typography variant="h5" color="secondary" sx={{ mt: 4 }}>
                Стоимость хранения
            </Typography>
            <Table size="medium">
                <TableHead>
                    <TableRow>
                        <TableCell>Хранение товара на нашем складе</TableCell>
                        <TableCell>до 7 дней</TableCell>
                        <TableCell>после 7 дней</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>1 короб</TableCell>
                        <TableCell>бесплатно</TableCell>
                        <TableCell>
                            <Price price={50} priceWithSale={30} direction="row-reverse" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>1 палетта</TableCell>
                        <TableCell>бесплатно</TableCell>
                        <TableCell>
                            <Price price={250} priceWithSale={150} direction="row-reverse" />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Stack>
    );
};

export default FFPriceDelivery;
