import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import questionStore from 'stores/questionStore';
import productStore from 'stores/productStore';
import videoStore from 'stores/videoStore';

import QuestionCreateMode from './QuestionCreateMode';
import QuestionShowMode from './QuestionShowMode';
import ItemDrawWrapper, { MenuTab } from '../ItemDrawWrapper';
import VideoTabMode from '../VideoTabMode';

const Question = () => {
    const { item_id: item_id_prepare, tabName }: { item_id?: string; tabName?: string } = useParams();
    const question_id = Number(item_id_prepare) || CREATING_ITEM_ID;

    useMemo(() => {
        productStore.fetchList();
        questionStore.fetchItem(question_id);
        videoStore.findWbVideo('question', question_id);
    }, [question_id]);

    if (question_id === CREATING_ITEM_ID) {
        return <QuestionCreateMode />;
    }

    const videoId = videoStore.getVideoId('question', question_id);

    const tabs: MenuTab[] = [
        { id: 'main', label: 'Вопрос', pathname: `/questions/list/detail/${question_id}` },
        {
            id: 'video',
            label: 'Видеозапись',
            pathname: `/questions/list/detail/${question_id}/video`,
            disabled: !videoId
        }
    ];

    return (
        <ItemDrawWrapper tabs={tabs} activeTab={tabName || 'main'}>
            {(!tabName || tabName === 'main') && <QuestionShowMode question_id={question_id} />}
            {tabName === 'video' && videoId && <VideoTabMode videoId={videoId} />}
        </ItemDrawWrapper>
    );
};

export default observer(Question);
