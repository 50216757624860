import React, { useState, ReactNode } from 'react';
import Popover from '@mui/material/Popover';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';

type InfoPopupProps = {
    children?: ReactNode;
    content?: string | ReactNode;
    size?: 'inherit' | 'large' | 'medium' | 'small';
};

// const useStyles = makeStyles(({ spacing }: Theme) => ({
//     popover: {
//         pointerEvents: 'none'
//     },
//     paper: {
//         padding: spacing(2),
//         maxWidth: '400px'
//     },
//     paperHuge: {
//         padding: spacing(2),
//         maxWidth: '600px'
//     }
// }));

const InfoPopup = ({ children, content, size = 'inherit' }: InfoPopupProps) => {
    // const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Box
                className="crm-Main__hiddenMobile"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{ cursor: 'pointer' }}
            >
                <HelpOutlineIcon fontSize={size} color="primary" />
            </Box>
            <Popover
                sx={{
                    pointerEvents: 'none',
                    '& .MuiPaper-root': {
                        padding: 2,
                        maxWidth: size === 'large' ? '600px' : '400px'
                    }
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                disableRestoreFocus
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <div>
                    {children}
                    {!children && content && <>{content}</>}
                </div>
            </Popover>
        </>
    );
};

export default React.memo(InfoPopup);
