import React from 'react';
import { observer } from 'mobx-react';

import ransomStore, { Ransom } from 'stores/ransomStore';

import Select from 'ui/Select';
import Paper from '@mui/material/Paper';

export const wbFiltersOrders: { value: Ransom['wbFilterOrderBy']; text: string }[] = [
    { value: 'popular', text: 'Популярности' },
    { value: 'rate', text: 'Рейтингу' },
    { value: 'priceup', text: 'Возрастанию цены' },
    { value: 'pricedown', text: 'Убыванию цены' },
    { value: 'newly', text: 'Обновлению' },
    { value: 'benefit', text: 'Сначала выгодные' }
];

const WbFilterOrderBy = ({ ransom_id }: { ransom_id: number }) => {
    const handleSpeed = ({ value }: { value: Ransom['wbFilterOrderBy'] }) => {
        ransomStore.setEditingItem(ransom_id, { wbFilterOrderBy: value });
    };

    const { wbFilterOrderBy } = ransomStore.getItem(ransom_id).editingItem;

    return (
        <Paper sx={{ mb: 1, p: 1 }}>
            <Select
                fullWidth
                variant="standard"
                options={wbFiltersOrders}
                value={wbFilterOrderBy}
                onChange={handleSpeed}
                label="Сортровать по"
            />
        </Paper>
    );
};

export default observer(WbFilterOrderBy);
