import React from 'react';

import { Ransom } from 'stores/ransomStore';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';

import RansomOtherItemsList from './RansomOtherItemsList';

export const RansomRivalsShow = ({ ransom }: { ransom: Ransom | null }) => {
    const [showRivals, toggleRivals] = useToggleModal();
    const { rival_item_ids = [], rivalItems = [] } = ransom || {};

    return (
        <>
            {rival_item_ids.length > 0 && (
                <ListItem
                    secondaryAction={
                        <Button
                            icon={showRivals ? <ArrowDropUp /> : <ArrowDropDownIcon />}
                            onClick={toggleRivals}
                            tooltip={`${showRivals ? 'Скрыть' : 'Показать'} список товаров конкурентов`}
                        />
                    }
                >
                    <ListItemAvatar>
                        <Avatar>
                            <LocalGroceryStoreIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`Товары конкурентов в корзину перед выкупом: ${rival_item_ids.length}`} />
                </ListItem>
            )}
            {showRivals && <RansomOtherItemsList list={rivalItems} />}
        </>
    );
};

export default RansomRivalsShow;
