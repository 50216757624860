import React, { useEffect, useState } from 'react';

import { WBSuggestion } from 'stores/prototypes/ListTransactionsStore.prototype';
import useDebounce from 'common/useDebounce';
import * as productApi from 'api/productApi';

import Stack from '@mui/material/Stack';

import { WbSearchField } from '../../Items/Product/ProductSearchAnalyseMode';

import ListError from 'ui/ListError';
import { InfoMessage } from 'ui/Message';

import WbAdsRatesSearchTable from './WbAdsRatesSearchTable';

const WbAdsRatesBySearch = () => {
    const [searchRequest, setSearchRequest] = useState('');
    const [suggestions, setSuggestions] = useState<WBSuggestion[]>([]);
    const debouncedValue = useDebounce<string>(searchRequest, 500);
    const [errors, setErrors] = useState<string[]>([]);

    const handleSelectQuery = (event: any, value: any) => {};

    useEffect(() => {
        setErrors([]);
        productApi
            .fetchWbSearchSuggestions(debouncedValue, 'female')
            .then(setSuggestions)
            .catch(errors => {
                console.log(errors);
                setErrors(['При поиске произошла ошибка']);
            });
    }, [debouncedValue]);

    return (
        <Stack spacing={2}>
            <ListError errors={errors} />
            <WbSearchField
                searchRequest={searchRequest}
                onChangeSearch={setSearchRequest}
                handleSelectQuery={handleSelectQuery}
                suggestions={suggestions}
            />

            {!debouncedValue && (
                <InfoMessage header="Рекламные ставки конкурентов">
                    Введите поисковый запрос, чтобы увидеть текущие рекламные ставки на Wildberries
                </InfoMessage>
            )}
            {debouncedValue && <WbAdsRatesSearchTable search={debouncedValue} />}
        </Stack>
    );
};

export default WbAdsRatesBySearch;
