import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import ransomStore from 'stores/ransomStore';
import productStore from 'stores/productStore';

import Container from '@mui/material/Container';

import Table, { TableRow, TableHead, TableCell } from 'ui/Table';
import Checkbox from 'ui/Checkbox';
import { InfoMessage, WarningMessage } from 'ui/Message';

import WbItemAvatar from '../Product/WbItemAvatar';
import { WbProductStat } from '../Product/StoreBlocks/ProductsTableWb';
import matchWbPhotoUrl from 'common/matchWbPhotoUrl';

const RansomCreateModeSimilar = ({ ransom_id }: { ransom_id: number }) => {
    const {
        editingItem: { rival_item_ids = [], product_id }
    } = ransomStore.getItem(ransom_id);
    useEffect(() => {
        if (product_id) {
            productStore.fetchSimilarProducts(product_id);
        }
    }, [product_id]);

    if (!product_id) {
        return (
            <WarningMessage header="Товар не выбран">
                Выберите товар в Выкупе, чтобы здесь появились похожие товары
            </WarningMessage>
        );
    }

    const toggleSimilarItem = (similar_item_id: number, title: string) => {
        const {
            editingItem: { rival_item_ids = [] }
        } = ransomStore.getItem(ransom_id);
        const new_rival_item_ids = [...rival_item_ids];
        const { rivalItems = [] } = ransomStore.getItem(ransom_id).property;
        const newRivalItems = [...rivalItems];

        if (new_rival_item_ids.includes(similar_item_id)) {
            const index = new_rival_item_ids.indexOf(similar_item_id);
            new_rival_item_ids.splice(index, 1);
            newRivalItems.splice(index, 1);
        } else {
            new_rival_item_ids.push(similar_item_id);
            newRivalItems.push({
                store_item_id: similar_item_id,
                title,
                photoUrl: matchWbPhotoUrl(similar_item_id).preview
            });
        }

        ransomStore.setProperty(ransom_id, {
            rivalEnable: Boolean(new_rival_item_ids.length),
            rivalItems: newRivalItems
        });
        ransomStore.setEditingItem(ransom_id, { rival_item_ids: new_rival_item_ids });
    };

    const { loadingSimilarProducts = true, similarProducts = [] } = productStore.getItem(product_id).property;

    return (
        <Container maxWidth="lg" sx={{ mb: 2 }}>
            <InfoMessage header="Похожие товары">
                Вы можете выбирать отсюда товары, как Товары конкурентов в Выкупе. Все товары тут отсортированы по
                количеству продаж.
                <br />
                Товары конкурентов: <b>{rival_item_ids.length}</b> из <b>5</b>
            </InfoMessage>
            <Table loading={loadingSimilarProducts}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>Наименование</TableCell>
                        <TableCell>Бренд</TableCell>
                        <TableCell>Продажи</TableCell>
                    </TableRow>
                </TableHead>
                {similarProducts.map(({ wb_item_id, title, brandName, sold30dQty, sold30dAmount }) => (
                    <TableRow key={wb_item_id}>
                        <TableCell>
                            <Checkbox
                                checked={rival_item_ids.includes(wb_item_id)}
                                onClick={() => toggleSimilarItem(wb_item_id, title)}
                                disabled={!rival_item_ids.includes(wb_item_id) && rival_item_ids.length >= 5}
                            />
                        </TableCell>
                        <TableCell>
                            <WbItemAvatar wb_item_id={wb_item_id} size={48} />
                        </TableCell>
                        <TableCell>
                            <a
                                href={`https://www.wildberries.ru/catalog/${wb_item_id}/detail.aspx`}
                                rel="noreferrer"
                                target="_blank"
                                title="Смотреть на сайте wildberries.ru"
                            >
                                {wb_item_id} {title}
                            </a>
                        </TableCell>
                        <TableCell>{brandName}</TableCell>
                        <TableCell>
                            <WbProductStat sold30dQty={sold30dQty} sold30dAmount={sold30dAmount} />
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
        </Container>
    );
};

export default observer(RansomCreateModeSimilar);
