import React, { SyntheticEvent, useMemo, useState } from 'react';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import productStore from 'stores/productStore';
import commonStore from 'stores/commonStore';
import seoConsultStore from 'stores/seoConsultStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import authStore from 'stores/authStore';

import PaymentIcon from '@mui/icons-material/Payment';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Modal, { ModalBtnAction, ModalContent } from 'ui/Modal';
import ListError from 'ui/ListError';
import Grid from 'ui/Grid';
import Select from 'ui/Select';
import TextInput from 'ui/TextInput';
import { InfoMessage } from 'ui/Message';
import InfoPopup from 'ui/InfoPopup';

import CostWithDiscount from '../../CostWithDiscount';

const ConsultVariants = {
    1: { value: 20, cost: 2900, costWithDiscount: 2250 },
    2: { value: 50, cost: 3900, costWithDiscount: 2800 },
    3: { value: 100, cost: 4900, costWithDiscount: 3250 }
} as const;

const SeoConsultModal = ({ onClose }: { onClose: () => void }) => {
    useMemo(() => {
        productStore.fetchList();
        seoConsultStore.fetchItem(CREATING_ITEM_ID);
        seoConsultStore.setEditingItem(CREATING_ITEM_ID, {
            product_id: null,
            comment: '',
            variant: 2,
            phone: `+${authStore.currentUser?.phone}`
        });
    }, []);

    const { loadingList, productOptions } = productStore;

    const {
        editingItem: { product_id, comment, variant, phone },
        loadingItem,
        errors
    } = seoConsultStore.getItem(CREATING_ITEM_ID);

    const handleChangeProductId = ({ value }: { value: number }) => {
        seoConsultStore.setEditingItem(CREATING_ITEM_ID, { product_id: value });
    };

    const handleChangeComment = (event: SyntheticEvent, { value }: { value: string }) => {
        seoConsultStore.setEditingItem(CREATING_ITEM_ID, { comment: value });
    };

    const handleCreate = async () => {
        const seo_consult_id = await seoConsultStore.createItem();
        if (seo_consult_id) {
            commonStore.setGlobalInfoMessage(
                'Заявка на SEO-оптимизацию создана, мы свяжемся с вами в течение 24 часов'
            );
            onClose();
        }
    };

    const handleChangeVariant = (event: SyntheticEvent, variant: 1 | 2 | 3) => {
        if (variant) {
            seoConsultStore.setEditingItem(CREATING_ITEM_ID, { variant });
        }
    };

    const handleChangePhone = (event: SyntheticEvent, { value }: { value: string }) => {
        seoConsultStore.setEditingItem(CREATING_ITEM_ID, { phone: value });
    };

    if (!variant) {
        return null;
    }

    const consultVariant = ConsultVariants[variant];

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'inherit' },
        {
            onClick: handleCreate,
            label: (
                <>
                    Заказать (
                    <CostWithDiscount cost={consultVariant.cost} />)
                </>
            ),
            loading: loadingItem,
            variant: 'contained',
            color: 'secondary',
            endIcon: <PaymentIcon />
        }
    ];

    return (
        <Modal
            maxWidth="sm"
            onClose={onClose}
            actions={actions}
            header="Заказать SEO-оптимизацию"
            fullScreen={commonStore.isMobile}
        >
            <ModalContent>
                <ListError errors={errors} />
                <Grid>
                    <Grid.Column>
                        <Typography sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
                            Карточку какого товара вы хотите улучшить?{' '}
                            <InfoPopup size="large">
                                Если нужного товара нет в списке, добавьте его в разделе&nbsp;
                                <Link to="/products/list">
                                    <b>Мои Товары</b>
                                </Link>
                                . Если у вас нет еще карточки, оставьте поле пустым.
                            </InfoPopup>
                        </Typography>
                        <Select
                            label="Выберите товар"
                            value={product_id}
                            onChange={handleChangeProductId}
                            options={productOptions}
                            fullWidth
                            loading={loadingList}
                            variant="classic"
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Typography>Формирование семантического ядра на основание анализа</Typography>
                        <ToggleButtonGroup
                            color="secondary"
                            value={variant}
                            exclusive
                            onChange={handleChangeVariant}
                            fullWidth
                        >
                            <ToggleButton value={1}>20 карточек</ToggleButton>
                            <ToggleButton value={2}>50 карточек</ToggleButton>
                            <ToggleButton value={3}>100 карточек</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            variant="classic"
                            label="Как с вами связаться?"
                            value={phone}
                            onChange={handleChangePhone}
                            maxLength={1000}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            variant="classic"
                            label="Комментарий, пожелания (не обязательно)"
                            value={comment}
                            onChange={handleChangeComment}
                            maxLength={1000}
                            multiline
                            rowsMax={10}
                            rows={1}
                        />
                    </Grid.Column>
                </Grid>
                <InfoMessage>Если у вас возникли вопросы, обращайтесь @topstoreadm</InfoMessage>
            </ModalContent>
        </Modal>
    );
};

export default observer(SeoConsultModal);
