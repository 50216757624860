import { objectToGraphql } from '../common/graphql';
import fetchApi from '../common/fetchApi';

export const createItem = async (authToken: string): Promise<number> => {
    const graphql = `mutation { 
          createMGAccount(authToken: ${objectToGraphql(authToken)})
        }`;

    const data = await fetchApi.postGQ<{ createMGAccount: number }>(graphql);
    return data.createMGAccount;
};
