import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import ListError from 'ui/ListError';
import wbProxyStore from '../../stores/wbProxyStore';
import Select from '../../ui/Select';

const ProxiesDropdown = () => {
    useEffect(() => {
        wbProxyStore.fetchList();
    }, []);

    const { list, listErrors, loadingList, selectedProxyId } = wbProxyStore;

    return (
        <>
            <ListError errors={listErrors} />
            <Select
                label="Proxy server"
                variant="standard"
                value={selectedProxyId}
                onChange={({ value }: { value: number }) => wbProxyStore.selectProxyId(value)}
                options={list.map(({ proxy_id, port, ip, urlScheme }) => ({
                    value: proxy_id,
                    text: `${urlScheme}://${ip}:${port}`
                }))}
                loading={loadingList}
                fullWidth
            />
        </>
    );
};

export default observer(ProxiesDropdown);
