import { ThemeOptions, createTheme } from '@mui/material/styles';

// export const PRIMARY_COLOR = '#F81B5D';
// export const PRIMARY_COLOR = '#4C2FFB';
export const PRIMARY_COLOR = '#03c9d7';
export const SECONDARY_COLOR = '#FF782C';
export const TEXT_COLOR = '#1B1A24';
export const CONTRAST_COLOR = '#FCFCFC';

const theme: ThemeOptions = {
    palette: {
        primary: {
            main: PRIMARY_COLOR, //'#03c9d7',
            contrastText: CONTRAST_COLOR //'#fff'
        },
        secondary: {
            main: SECONDARY_COLOR, //'#fb9678',
            contrastText: CONTRAST_COLOR //'#fff'
        },
        text: {
            primary: TEXT_COLOR // '#181f31'
        }
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: 'rgb(90 114 123 / 11%) 0px 7px 30px 0px'
                }
            }
        }
    }
};

export default createTheme(theme);
