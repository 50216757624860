import React from 'react';

import commonStore from 'stores/commonStore';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';
// import { SuccessMessage } from 'ui/Message';

import SeoConsultModal from './SeoConsultModal';
import ListContainer from '../ListContainer';

const SeoConsult = () => {
    const [showModal, toggleModal] = useToggleModal();

    return (
        <ListContainer bgUrl="/static/listbg/unruly-05-done.webp">
            <Container maxWidth="md">
                <Paper elevation={1} sx={{ padding: '1rem 2rem' }}>
                    <Typography variant="h5">SEO-оптимизация вашей карточки</Typography>
                    <Typography variant="body1" sx={{ my: 2 }}>
                        Мы предлагаем услугу по SEO-оптимизации вашей карточки на Wildberries:
                        <ul>
                            <li>
                                Формирование сематического ядра на основании анализа 50 - 100 карточек конкурентов,
                                лидеров продаж подкатегории
                            </li>
                            <li>Фильтрация на признак целевого запроса</li>
                            <li>
                                По пунктам заполненная карточка товара (наименование, характеристики, описание) с учетом
                                особенностей ранжирования поисковой системой
                            </li>
                            <li>Формирование наименования товара со всеми возможными ключевыми словами</li>
                            <li>Формирование описания товара со всеми возможными ключевыми словами</li>
                            <li>Рекомендации по продвижению</li>
                        </ul>
                    </Typography>
                    <Typography variant="subtitle2">
                        Заявки обрабатываются в будни с 09 до 18 МСК. Время исполнения 1-2 дня
                    </Typography>
                    <Typography variant="body1" sx={{ my: 2 }}>
                        SEO-оптимизация поможет автоматическому продвижению вашего товара в верхние строчки по ключевым
                        поисковым запросам
                    </Typography>
                </Paper>
                <Box sx={{ display: 'flex', p: 2 }}>
                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        sx={{ margin: 'auto' }}
                        onClick={toggleModal}
                    >
                        Заказать SEO-оптимизацию
                    </Button>
                    {showModal && <SeoConsultModal onClose={toggleModal} />}
                </Box>
                {/*<SuccessMessage header="Скидка до 35%">*/}
                {/*    До 1 июня действует скидка до 35% на SEO-оптимизацию*/}
                {/*</SuccessMessage>*/}
            </Container>
        </ListContainer>
    );
};

export default SeoConsult;
