import React, { useState } from 'react';
import * as userApi from 'api/userApi';
import commonStore from 'stores/commonStore';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { packetMaterialGroups, packMaterialsSet } from './priceData';

import Price from 'components/Price';
import { Modal, ModalBtnAction, ModalContent, ModalContentText, ModalHeader } from 'ui/Modal';
import Divider from 'ui/Divider';

const FFSelectPackMaterialGroup = ({
    group_id,
    currentPackId,
    onChangePackId
}: {
    group_id: number;
    currentPackId: number | null;
    onChangePackId: (pack_id: number) => void;
}) => {
    const packetGroup = packetMaterialGroups.find(group => group.group_id === group_id);
    if (!packetGroup) {
        return null;
    }
    const { label } = packetGroup;
    const materialSet = packMaterialsSet.filter(material => material.group_id === group_id);

    return (
        <Box>
            <Typography variant="subtitle2">{label}</Typography>
            <RadioGroup name={`group-${group_id}`}>
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                    {materialSet.map(({ pack_id, size, price }) => {
                        return (
                            <FormControlLabel
                                key={pack_id}
                                value={pack_id}
                                control={<Radio checked={currentPackId === pack_id} />}
                                label={size}
                                onClick={() => onChangePackId(pack_id)}
                            />
                        );
                    })}
                </Box>
            </RadioGroup>
        </Box>
    );
};

const FFSelectPackMaterial = ({
    onClose,
    onChange,
    pack_id
}: {
    onClose: () => void;
    onChange: (pack_id: number) => void;
    pack_id: number | null;
}) => {
    const [currentPackId, setPuckId] = useState<number | null>(pack_id);
    const handlePackId = (pack_id: number) => {
        setPuckId(pack_id);
    };

    const foundPack = packMaterialsSet.find(pack => pack.pack_id === currentPackId);
    let confirmLabel = 'Выбрать';
    if (foundPack) {
        confirmLabel += ` (${foundPack.price}₽ за 1 шт)`;
    }

    const handleConfirm = () => {
        if (!currentPackId) return;
        userApi.sendUserAction({ changePackId: currentPackId });
        onChange(currentPackId);
        onClose();
    };

    const ConfirmActions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'primary' },
        {
            onClick: handleConfirm,
            label: confirmLabel,
            color: 'primary',
            variant: 'contained',
            disabled: !foundPack
        }
    ];

    return (
        <Modal
            onClose={onClose}
            maxWidth="md"
            actions={ConfirmActions}
            fullScreen={commonStore.isMobile}
            header="Выберите упаковку и размер"
        >
            <ModalContent>
                <Stack spacing={3}>
                    {packetMaterialGroups.map(({ group_id }) => (
                        <FFSelectPackMaterialGroup
                            key={group_id}
                            group_id={group_id}
                            currentPackId={currentPackId}
                            onChangePackId={handlePackId}
                        />
                    ))}
                    <Divider />
                    <Stack direction="row" alignContent="center" justifyContent="space-between">
                        <Typography>Цена за 1 шт:</Typography>
                        {foundPack && (
                            <Price price={foundPack.price} priceWithSale={foundPack.price} direction="row-reverse" />
                        )}
                        {!foundPack && <Typography>Выберите интересующий размер</Typography>}
                    </Stack>
                </Stack>
            </ModalContent>
        </Modal>
    );
};

export default FFSelectPackMaterial;
