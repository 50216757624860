import React from 'react';

import Typography from '@mui/material/Typography';
import LinkIcon from '@mui/icons-material/Link';
import Button from 'ui/Button';

const TitleLinkWb = ({ wb_item_id }: { wb_item_id: number }) => (
    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <span>
            Артикул: <b style={{ fontWeight: 500 }}>{wb_item_id}</b>
        </span>
        <Button
            href={`https://www.wildberries.ru/catalog/${wb_item_id}/detail.aspx`}
            icon={<LinkIcon />}
            tooltip="Смотреть на wildberries.ru"
            target="_blank"
            color="error"
            size="small"
        />
    </Typography>
);

export default React.memo(TitleLinkWb);
