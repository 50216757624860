import ItemStorePrototype from './prototypes/ItemStore.prototype';
import * as deliveryPointsApi from 'api/deliveryPointsApi';
import { action, makeObservable, observable } from 'mobx';
import { StoreIds } from './productStore';
import debounce from 'common/debounce';

const defaultCoords: [number, number] = [55.755819, 37.617644];
const DEFAULT_COORDS_MEMKEY = 'mapStoreCoords';

export type StoreDeliveryPointType = {
    store_point_id: number;
    address: string;
    geo_lat: number;
    geo_lon: number;
    wayInfo: string;
    workTime: string;
    enable: boolean;
};

export type StoreDeliveryPointFilterType = {
    store_id: StoreIds;
    address: string;
    type: number;
};

class DeliveryPointsStore extends ItemStorePrototype<StoreDeliveryPointType> {
    coords: [number, number];

    constructor() {
        super('store_point_id', 'deliveryPointsStore', deliveryPointsApi);
        makeObservable(this);

        const memCoords = localStorage.getItem(DEFAULT_COORDS_MEMKEY);
        if (memCoords) {
            this.coords = JSON.parse(memCoords);
        } else {
            this.coords = defaultCoords;
        }
    }

    setCoords(geo_lat: number, geo_lon: number): void {
        this.coords = [geo_lat, geo_lon];
        localStorage.setItem(DEFAULT_COORDS_MEMKEY, JSON.stringify(this.coords));
    }

    @observable
    activePoints: StoreDeliveryPointType[] = [];
    @observable
    loadingPoints: boolean = true;

    @action
    async fetchLastStoreDeliveryPoints(store_id: StoreIds): Promise<void> {
        this.loadingPoints = true;
        this.activePoints = await deliveryPointsApi.fetchLastStoreDeliveryPoints(store_id);
        this.loadingPoints = false;
    }

    @observable
    storePointsFilter: StoreDeliveryPointFilterType = {
        store_id: StoreIds.WB,
        address: '',
        type: 0
    };

    @action
    async searchStoreDeliveryPointsList(filter: StoreDeliveryPointFilterType): Promise<void> {
        this.loadingPoints = true;
        if (filter.address) {
            this.activePoints = await deliveryPointsApi.searchStoreDeliveryPointsList(filter);
        } else {
            this.activePoints = await deliveryPointsApi.fetchLastStoreDeliveryPoints(filter.store_id);
        }
        this.loadingPoints = false;
    }

    @action
    changeStoresFilter<T extends keyof StoreDeliveryPointFilterType>(what: T, value: StoreDeliveryPointFilterType[T]) {
        this.loadingPoints = true;
        this.storePointsFilter[what] = value;
        this.debounceStoresFilterFetch();
    }

    debounceStoresFilterFetch = debounce(() => {
        this.searchStoreDeliveryPointsList(this.storePointsFilter);
    }, 350);

    searchMap: any;
    markers: any;
    activeStorePointId: number | null = null;
}

export default new DeliveryPointsStore();
