import React from 'react';

import { DateTimePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';

const TimePicker = ({
    time,
    handleChange,
    label
}: {
    time: number;
    handleChange: (date: Date | null) => void;
    label: string;
}) => {
    return (
        <DateTimePicker
            renderInput={props => <TextField {...props} sx={{ background: 'white' }} />}
            label={label}
            value={new Date(time * 1000)}
            onChange={handleChange}
            openTo="hours"
            showToolbar
            views={['day', 'hours', 'minutes']}
            disablePast
        />
    );
};

export default TimePicker;
