import React from 'react';
import { Link } from 'react-router-dom';
import CutString from 'ui/CutString';

export const ProductLinkState = (product_id: number) => ({
    pathname: `/products/list/detail/${product_id}`,
    state: { modal: true }
});

const ProductLink = ({ product_id, title }: { product_id: number; title: string }) => (
    <Link to={ProductLinkState(product_id)}>
        <CutString title={title || 'Наименование не задано'} maxLength={50} />
    </Link>
);

export default ProductLink;
