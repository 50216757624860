import React, { useEffect } from 'react';

import commonStore from 'stores/commonStore';
import * as userApi from 'api/userApi';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';
import Grid from 'ui/Grid';
import { PhotoThumbnail } from '../AutoAnswers/initInfo/SupplierIdInstruction';
import SeoControlModal from './SeoControlModal';

// import { SuccessMessage } from 'ui/Message';

const SeoControl = () => {
    useEffect(() => {
        userApi.sendUserAction('openList');
        return () => {
            userApi.sendUserAction('closeList');
        };
    }, []);

    const [showModal, toggleModal] = useToggleModal();
    const { isMobile } = commonStore;

    return (
        <Box sx={{ overflowY: 'auto' }}>
            <Box sx={{ bgcolor: '#fac9bd', py: 8 }}>
                <Container maxWidth="lg">
                    <Stack spacing={5} alignItems="center">
                        <Typography variant="h3">SEO — Сontrol</Typography>
                        <Typography variant={isMobile ? 'h5' : 'h4'} textAlign="center">
                            Современные алгоритмы Wildberries выдачи по поисковым запросам постоянно меняется и
                            совершенствуется. Маркетплейс стремится обеспечить покупателю наилучший подбор товара
                        </Typography>
                        <Typography variant={isMobile ? 'h6' : 'h5'} textAlign="center">
                            На сегодняшний день, для качественного продвижения карточки товара необходимо уделять особое
                            внимание индексации релевантных ключевых запросов.
                        </Typography>
                        <Typography variant={isMobile ? 'h6' : 'h5'} textAlign="center">
                            Предлагаем услугу по продвижению карточки товара на Wildberries от нашей команды
                            seo-специалистов и аналитков.
                        </Typography>
                        <Button variant="contained" size="large" onClick={toggleModal}>
                            Заказать Seo — Control
                        </Button>
                    </Stack>
                </Container>
            </Box>
            <Box>
                <Container maxWidth="md">
                    <Stack spacing={5} sx={{ py: 4 }} alignItems="center">
                        <Typography variant="h2" color="secondary.main" sx={{ fontWeight: 'bold' }}>
                            01
                        </Typography>

                        <Typography variant={isMobile ? 'h4' : 'h3'} textAlign="center">
                            SEO—оптимизация карточки
                        </Typography>
                        <Box>
                            <ul>
                                <li>
                                    Формирование сематического ядра на основании анализа карточек конкурентов, лидеров
                                    продаж
                                </li>
                                <li>
                                    По пунктам заполненная карточка товара (наименование, характеристики, описание), с
                                    учетом особенностей ранжирования поисковой системой ВБ
                                </li>
                                <li>100% актуальные техники "белого" продвижения карточек товаров на Wildberries</li>
                            </ul>
                        </Box>

                        <Typography variant="h4">Примеры работ</Typography>
                        <Grid columns={3}>
                            <Grid.Column>
                                <PhotoThumbnail srcBig="/static/photoseo/seo-01-1.jpg" width="100%" />
                            </Grid.Column>
                            <Grid.Column>
                                <PhotoThumbnail srcBig="/static/photoseo/seo-01-2.jpg" width="100%" />
                            </Grid.Column>
                            <Grid.Column>
                                <PhotoThumbnail srcBig="/static/photoseo/seo-01-3.jpg" width="100%" />
                            </Grid.Column>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
            <Box>
                <Container maxWidth="md">
                    <Stack spacing={5} sx={{ py: 4 }} alignItems="center">
                        <Typography variant="h2" color="secondary.main" sx={{ fontWeight: 'bold' }}>
                            02
                        </Typography>

                        <Typography variant={isMobile ? 'h4' : 'h3'} textAlign="center">
                            Стратегия по выкупам
                        </Typography>
                        <Box>
                            <ul>
                                <li>Расчет оптимального плана по выкупам для попадания в ТОП</li>
                                <li>Изменение стратегии 1 раз в неделю с учетом мониторинга позиций</li>
                                <li>
                                    Успешный накопленный опыт в продвижении карточек в различных категориях - более 2
                                    лет
                                </li>
                            </ul>
                        </Box>

                        <Typography variant="h4">Примеры работ</Typography>
                        <Grid columns={2}>
                            <Grid.Column>
                                <PhotoThumbnail srcBig="/static/photoseo/seo-02-1.jpg" width="100%" />
                            </Grid.Column>
                            <Grid.Column>
                                <PhotoThumbnail srcBig="/static/photoseo/seo-02-2.jpg" width="100%" />
                            </Grid.Column>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
            <Box>
                <Container maxWidth="md">
                    <Stack spacing={5} sx={{ py: 4 }} alignItems="center">
                        <Typography variant="h2" color="secondary.main" sx={{ fontWeight: 'bold' }}>
                            03
                        </Typography>

                        <Typography variant={isMobile ? 'h4' : 'h3'} textAlign="center">
                            Мониторинг позиций и корректировка
                        </Typography>
                        <Box>
                            <ul>
                                <li>Отслеживание динамики изменений позиций ключевых слов</li>
                                <li>Внесение необходимых правок в структуру карточек</li>
                                <li>Оптимальная корректировка для вхождения в нужные категории</li>
                                <li>Еженедельный отчет о проделанной работе и текущих результатах</li>
                            </ul>
                        </Box>
                    </Stack>
                </Container>
            </Box>

            <Box sx={{ bgcolor: 'secondary.light', color: 'white', py: 6 }}>
                <Container maxWidth="md">
                    <Stack spacing={5} sx={{ py: 4 }} alignItems="center">
                        <Typography variant={isMobile ? 'h4' : 'h3'} textAlign="center">
                            Получите подробную консультацию нашего аналитика с разбором вашей карточки товара
                        </Typography>
                        <Button variant="contained" size="large" onClick={toggleModal}>
                            <Stack spacing={isMobile ? 0 : 1} direction={isMobile ? 'column' : 'row'}>
                                <Box>Заказать консультацию специалиста</Box>
                                <Box sx={{ textDecoration: 'line-through' }}>2900 рублей</Box>
                                <Box>Бесплатно</Box>
                            </Stack>
                        </Button>
                        <Box>
                            <Stack spacing={2}>
                                <Typography variant="h5" textAlign="center">
                                    1. Бесплатный аудит вашей карточки
                                </Typography>
                                <Typography variant="h5" textAlign="center">
                                    2. Разбор в видео / аудио формате до 60 минут
                                </Typography>
                                <Typography variant="h5" textAlign="center">
                                    3. Рекомендации по продвижению
                                </Typography>
                                <Typography variant="h5" textAlign="center">
                                    4. Покажем потенциал финансового роста и укажем на текущие ошибки
                                </Typography>
                            </Stack>
                        </Box>
                        <Typography variant={isMobile ? 'h4' : 'h3'} textAlign="center">
                            Стоимость услуги продвижения в месяц
                        </Typography>
                        <Typography variant={isMobile ? 'h4' : 'h3'} textAlign="center">
                            1 карточка - 20 000 рублей
                        </Typography>
                    </Stack>
                </Container>
            </Box>
            {showModal && <SeoControlModal onClose={toggleModal} />}
        </Box>
    );
};

export default SeoControl;
