import React from 'react';
import BoringAvatar from 'boring-avatars';

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

import { Theme } from '@mui/material/styles';
import { createStyles, withStyles } from '@mui/styles';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'muiTheme';

const OnlineStatusBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            backgroundColor: ({ isOnline }: { isOnline?: boolean }) => (isOnline ? '#44b700' : '#fdd835'),
            color: ({ isOnline }: { isOnline: boolean }) => (isOnline ? '#44b700' : '#fdd835'),
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: '$ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""'
            }
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0
            }
        }
    })
)(Badge);

const colors = [PRIMARY_COLOR, SECONDARY_COLOR, '#EDB92E', '#51A651'];

const BoringAvatarMemo = React.memo(({ name, size }: { size: number; name: string }) => {
    return <BoringAvatar size={size} name={name} variant="bauhaus" colors={colors} square />;
});

type UserAvatarProps = {
    showStatus?: boolean;
    isOnline?: boolean;
    src?: string | null;
    size?: number;
    variant?: 'circular' | 'rounded';
    onLoad?: () => void;
} & (
    | {
          name: string;
      }
    | {
          firstName: string;
          lastName: string;
      }
);

const UserAvatar = (props: UserAvatarProps) => {
    const { showStatus = false, isOnline = false, src, size = 40, variant, onLoad } = props;

    const avatarName = 'name' in props ? props.name : `${props.firstName} ${props.lastName}`;

    const avatar = src ? (
        <Avatar
            imgProps={{ onLoad }}
            alt={avatarName}
            src={src}
            variant={variant || 'circular'}
            style={{ width: `${size}px`, height: `${size}px`, fontSize: size }}
        />
    ) : (
        <Avatar alt={avatarName} variant={variant || 'circular'} style={{ width: `${size}px`, height: `${size}px` }}>
            <BoringAvatarMemo size={size} name={avatarName} />
        </Avatar>
    );

    return showStatus ? (
        <OnlineStatusBadge
            isOnline={isOnline}
            overlap="circular"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            variant="dot"
        >
            {avatar}
        </OnlineStatusBadge>
    ) : (
        avatar
    );
};

export default React.memo(UserAvatar);
