import React, { ReactNode } from 'react';

import { ProductShortItemBrand, StoreIds } from 'stores/productStore';

import Button from 'ui/Button';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinkIcon from '@mui/icons-material/Link';
import Paper from '@mui/material/Paper';

const BrandCardInfo = ({
    store_id,
    brand,
    children
}: {
    store_id: StoreIds;
    brand: ProductShortItemBrand;
    children?: ReactNode;
}) => {
    let url;
    let title;

    switch (store_id) {
        case StoreIds.WB:
            url = `https://www.wildberries.ru${encodeURIComponent(brand.brandUrl || '').replaceAll('%2F', '/')}`;
            title = 'Смотреть на wildberries.ru';
            break;
        case StoreIds.Ozon:
            url = `http://www.ozon.ru${brand.brandUrl || ''}`;
            title = 'Смотреть на ozon.ru';
            break;
        default:
            const error: never = store_id;
    }

    return (
        <Paper
            elevation={1}
            sx={{
                display: 'flex',
                mt: 2,
                alignItems: 'center',
                justifyContent: 'space-around',
                pb: 1
            }}
        >
            {store_id === StoreIds.WB && <img src={brand.logoUrl} height={48} alt={brand.name} />}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {!children && <Typography variant="overline">Бренд</Typography>}
                <Typography variant="body2">
                    {brand.name}
                    <Button icon={<LinkIcon />} tooltip={title} href={url} target="_blank" color="error" />
                </Typography>
                {children}
            </Box>
        </Paper>
    );
};

export default BrandCardInfo;
