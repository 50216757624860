import React, { useState, SyntheticEvent, useMemo } from 'react';
import { observer } from 'mobx-react';
import Cookies from 'js-cookie';

import authStore from 'stores/authStore';
import commonStore from 'stores/commonStore';
import LoaderAwait from 'ui/LoaderAwait';

import { ErrorMessage, SuccessMessage } from 'ui/Message';
import TextInput from 'ui/TextInput';
import Button from 'ui/Button';
import Grid from 'ui/Grid';
import Typography from '@mui/material/Typography';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TelegramIcon from '@mui/icons-material/Telegram';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { useToggleModal } from '../ui/Modal';
import { WarningMessage } from '../ui/Message';

const phoneRegExp = new RegExp(/^\+3\d \(\d{3}\) \d{3}-\d{2}-\d{2}$/);
const cookieRef = Cookies.get('ref');

const LoginForm = () => {
    useMemo(() => {
        const url = new URL(document.location.href);
        const authToken = url.searchParams.get('authToken');
        if (authToken) {
            authStore.setToken(authToken);
            window.history.replaceState(null, '/', '/');
        }
    }, []);

    const [showForgotPassword, toggleForgotForm] = useToggleModal();
    const [showEmailModal, toggleEmailModal] = useToggleModal();

    const [phone, setPhone] = useState('+7');
    const handlePhone = (event: SyntheticEvent, { value }: { value: string }) => {
        setPhone(value);
    };

    const [password, setPassword] = useState('');
    const handlePassword = (event: SyntheticEvent, { value }: { value: string }) => {
        setPassword(value);
    };

    const handleSubmitForm = async (event: SyntheticEvent) => {
        event.preventDefault();
        authStore.login(phone, password);
    };

    const handleResetPassword = async (event: SyntheticEvent) => {
        event.preventDefault();
        // authStore.resetPassword();
    };

    const { inProgress, loginErrors } = authStore;
    const answer = '';

    const Errors = Array.from(loginErrors).length > 0 && (
        <ErrorMessage>
            {Array.from(loginErrors).map(error => (
                <p key={String(error)}>{error}</p>
            ))}
        </ErrorMessage>
    );

    // if (Math.random()) {
    //     return (
    //         <div className="crm-Login" style={{ backgroundImage: `url('/static/bg_login.jpg')` }}>
    //             <div className="crm-Login__box">
    //                 <Grid justify="center" spacing={4}>
    //                     <LoaderAwait active={inProgress} dimmer size="large" />
    //
    //                     <Grid.Column
    //                         xs={12}
    //                         style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}
    //                     >
    //                         <img src="/static/FullLogo_Transparent_NoBuffer.png" alt="topstore.pro" />
    //                     </Grid.Column>
    //                     <Grid.Column>
    //                         <WarningMessage header='Техничесские работы'>
    //                             На сервере topstore.pro ведутся технические работы до 03:00 МСК. Приносим извинения за неудобства.
    //                         </WarningMessage>
    //                     </Grid.Column>
    //                 </Grid>
    //             </div>
    //         </div>)
    // }

    return (
        <div className="crm-Login" style={{ backgroundImage: `url('/static/bg_login.jpg')` }}>
            <div className="crm-Login__box">
                {!showForgotPassword && (
                    <form onSubmit={handleSubmitForm}>
                        <Grid justify="center" spacing={4}>
                            <LoaderAwait active={inProgress} dimmer size="large" />

                            <Grid.Column
                                xs={12}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}
                            >
                                <img src="/static/logo-v2.svg" alt="topstore.pro" style={{ width: '60%' }} />
                            </Grid.Column>
                            <Grid.Column xs={12}>
                                <TextInput
                                    label="Телефон"
                                    required
                                    onChange={handlePhone}
                                    autoComplete="phone"
                                    value={phone}
                                    size="medium"
                                    variant="outlined"
                                    mask="+00000000000[0]"
                                />
                            </Grid.Column>
                            <Grid.Column xs={12}>
                                <TextInput
                                    label="Password"
                                    type="password"
                                    required
                                    onChange={handlePassword}
                                    value={password}
                                    size="medium"
                                    variant="outlined"
                                    minLength={4}
                                    autoComplete="current-password"
                                />
                            </Grid.Column>
                            {Errors && <Grid.Column xs={12}>{Errors}</Grid.Column>}
                            <Grid.Column
                                style={{
                                    display: 'flex',
                                    justifyContent: commonStore.isMobile ? 'flex-start' : 'flex-end'
                                }}
                                xs={12}
                            >
                                {/*<Button*/}
                                {/*    variant="text"*/}
                                {/*    color="inherit"*/}
                                {/*    startIcon={<LockOpenIcon />}*/}
                                {/*    onClick={toggleForgotForm}*/}
                                {/*>*/}
                                {/*    Забыли пароль?*/}
                                {/*</Button>*/}
                            </Grid.Column>
                            <Grid.Column xs={12}>
                                <Button type="submit" fullWidth color="primary" variant="contained" size="large">
                                    Войти
                                </Button>
                            </Grid.Column>
                            <Grid.Column xs={12}>
                                <Button
                                    endIcon={<TelegramIcon />}
                                    color="secondary"
                                    fullWidth
                                    variant="outlined"
                                    size="large"
                                    target="_blank"
                                    href={`https://t.me/topstorepro_bot?start${cookieRef ? `=${cookieRef}` : ''}`}
                                >
                                    Регистрация через Telegram
                                </Button>
                            </Grid.Column>
                            {/*<Grid.Column xs={12}>*/}
                            {/*    <Button*/}
                            {/*        endIcon={<AlternateEmailIcon />}*/}
                            {/*        color="secondary"*/}
                            {/*        fullWidth*/}
                            {/*        variant="text"*/}
                            {/*        size="large"*/}
                            {/*        onClick={toggleEmailModal}*/}
                            {/*    >*/}
                            {/*        Регистрация по email*/}
                            {/*    </Button>*/}
                            {/*</Grid.Column>*/}
                        </Grid>
                    </form>
                )}
                {/*{showForgotPassword && (*/}
                {/*    <form onSubmit={handleResetPassword}>*/}
                {/*        <Grid justify="center" spacing={4}>*/}
                {/*            <LoaderAwait active={inProgress} dimmer size="large" />*/}

                {/*            <Grid.Column*/}
                {/*                xs={12}*/}
                {/*                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}*/}
                {/*            >*/}
                {/*                <img src="/static/brand/brand-logo.png" height="48" alt="topstore.pro" />*/}
                {/*                <Typography variant="h5" align="center">*/}
                {/*                    Сбросить пароль*/}
                {/*                </Typography>*/}
                {/*            </Grid.Column>*/}
                {/*            {(Errors || answer) && (*/}
                {/*                <Grid.Column xs={12}>*/}
                {/*                    {Errors}*/}
                {/*                    {answer && <SuccessMessage>{answer}</SuccessMessage>}*/}
                {/*                </Grid.Column>*/}
                {/*            )}*/}
                {/*            <Grid.Column xs={12}>*/}
                {/*                <Button*/}
                {/*                    variant="text"*/}
                {/*                    color="inherit"*/}
                {/*                    startIcon={<ArrowBackIcon />}*/}
                {/*                    onClick={toggleForgotForm}*/}
                {/*                >*/}
                {/*                    назад*/}
                {/*                </Button>*/}
                {/*            </Grid.Column>*/}

                {/*            <Grid.Column xs={12}>*/}
                {/*                <TextInput*/}
                {/*                    fullWidth*/}
                {/*                    label="Телефон"*/}
                {/*                    required*/}
                {/*                    onChange={handlePhone}*/}
                {/*                    autoComplete="phone"*/}
                {/*                    variant="outlined"*/}
                {/*                    value={phone}*/}
                {/*                    mask="+7 (000) 000-00-00"*/}
                {/*                    pattern={phoneRegExp}*/}
                {/*                />*/}
                {/*            </Grid.Column>*/}
                {/*            <Grid.Column xs={12}>*/}
                {/*                <Button color="secondary" type="submit" fullWidth variant="contained" size="large">*/}
                {/*                    Сбросить*/}
                {/*                </Button>*/}
                {/*            </Grid.Column>*/}
                {/*        </Grid>*/}
                {/*    </form>*/}
                {/*)}*/}
                {/*<div style={{ textAlign: 'center' }}>*/}
                {/*    <a*/}
                {/*        href="https://docs.google.com/document/d/1KSiBwwTja8KfJRZY430lHKCDU9yzw_Kig_Ke1iTah_I"*/}
                {/*        target="_blank"*/}
                {/*        rel="noindex nofollow noreferrer"*/}
                {/*        style={{ color: '#838383', textDecoration: 'none' }}*/}
                {/*    >*/}
                {/*        Договор оферта*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default observer(LoginForm);
