import React, { useState } from 'react';
import { observer } from 'mobx-react';

import userStore from 'stores/userStore';
import * as referralLinkApi from 'api/referralLinkApi';

import Grid from 'ui/Grid';
import Button from 'ui/Button';
import ListError from 'ui/ListError';

const ReferralResetBalanceBtn = ({ user_id }: { user_id: number }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const handleReset = async () => {
        try {
            setErrors([]);
            setLoading(true);
            await referralLinkApi.resetReferralsBalance(user_id);
            userStore.fetchItem(user_id);
        } catch (errors) {
            if (errors instanceof Array) {
                setErrors(errors);
            }
        } finally {
            setLoading(false);
        }
    };

    const { item } = userStore.getItem(user_id);
    if (!item) {
        return null;
    }

    return (
        <Grid columns={2}>
            <Grid.Column>
                Реферальная сумма к списыванию: <b>{item.referralsSumToWithdraw} ₽</b>
            </Grid.Column>
            <Grid.Column>
                <Button color="secondary" variant="contained" loading={loading} onClick={handleReset}>
                    Обнулить
                </Button>
                <ListError errors={errors} />
            </Grid.Column>
        </Grid>
    );
};

export default observer(ReferralResetBalanceBtn);
