import React from 'react';

import Box from '@mui/material/Box';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';

import SeoFulfillmentModal from './SeoFulfillmentModal';
import Typography from '@mui/material/Typography';

const FFFooter = () => {
    const [showModal, toggleModal] = useToggleModal();

    return (
        <Box sx={{ display: 'flex', p: 2, flexDirection: 'column', gap: 2 }}>
            <Typography>Оставьте заявку и наш менеджер свяжется с вами для бесплатной консультации</Typography>
            <Button color="secondary" size="large" variant="contained" sx={{ margin: 'auto' }} onClick={toggleModal}>
                Заявка на FulFillment
            </Button>
            {showModal && <SeoFulfillmentModal onClose={toggleModal} />}
        </Box>
    );
};

export default FFFooter;
