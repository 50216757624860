import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import ransomStore from 'stores/ransomStore';
import productStore, { StoreIds } from 'stores/productStore';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import InfoPopup from 'ui/InfoPopup';
import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';

import RansomFilterModal from './RansomFilterModal';
import RansomFilterChips from './RansomFilterChips';

const RansomPopup = React.memo(() => {
    return (
        <InfoPopup size="large">
            <Typography variant="subtitle2">Это самый главный параметр для продвижения</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Подберите ключевые запросы по которому вы хотите продвигать ваш товар вверх при поиске на Wildberries.
                Чем более конкретный запрос (более низкочастотный) вы продвигаете, тем быстрее вы окажетесь в топе по
                данному запросу. Например, если вы продаете Мягкую игрушку тигра, то по запросу “Мягкая игрушка” сложнее
                вывести товар наверх, чем по запросу “Мягкая игрушка тигр большая”.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Когда мы делаем Выкуп, наш робот вводит указанный вами поисковый запрос в WB и с него переходит на ваш
                товар, тем самым WB запоминает, по каким ключевым словам вас искали.
            </Typography>
            <Typography variant="caption">
                В строке используются подсказки, взятые напрямую с поисковой строки WB в реальном времени. Учитывайте
                их, чтобы подобрать подходящий ключевой запрос.
            </Typography>
        </InfoPopup>
    );
});

const WbFiltersPopup = React.memo(() => {
    return (
        <InfoPopup size="large">
            <Typography variant="subtitle2">Для чего добавлять фильтры поиска?</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Необходимый инструмент, для быстрого продвижения карточки
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Фильтры помогают сделать ваши выкупы высокоточными и получить индексацию в необходимых категориях.
                Выкупы, с учетом фильтров и категорий, позволят оптимизировать стратегию продвижения товара
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                ❗️Для профессионалов - позволят в сжатые сроки тестировать гипотезы ранжирования категорий и ключевых
                фраз
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Также можно устанавливать сортировку. Например, при сортировке «по обновлению» Wildberries показывает
                новые карточки первыми
            </Typography>
            <Typography variant="body2" sx={{ my: 1 }}>
                Стоимость добавления фильтров - 10₽
            </Typography>
            <Typography variant="caption" sx={{ mt: 1 }}>
                После создания Выкупа вам будет доступна видеозапись, где вы сможете убедиться, что все выбранные
                фильтры и сортировка были установлены
            </Typography>
        </InfoPopup>
    );
});

const RansomSearchQuery = ({
    ransom_id,
    hidePopups,
    disableDeleteFilter
}: {
    ransom_id: number;
    hidePopups?: boolean;
    disableDeleteFilter?: boolean;
}) => {
    const [showFilterModal, toggleFilterModal] = useToggleModal();
    const { wbSuggestions } = ransomStore;
    const { editingItem } = ransomStore.getItem(ransom_id);
    const { searchRequest, gender, wbFilters = [] } = editingItem;

    const handleSearchRequest = (event: SyntheticEvent, searchRequest: string) => {
        ransomStore.setEditingItem(ransom_id, { searchRequest });
        ransomStore.suggestQuery = searchRequest;
        ransomStore.suggestGender = gender || 'female';
        ransomStore.debounceWbSuggestions();
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Autocomplete
                    freeSolo
                    value={searchRequest || ''}
                    onInputChange={handleSearchRequest}
                    disableClearable
                    options={wbSuggestions}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Ключевой запрос"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                endAdornment: hidePopups ? undefined : (
                                    <InputAdornment position="end">
                                        <RansomPopup />
                                    </InputAdornment>
                                )
                            }}
                            style={{ background: 'white' }}
                        />
                    )}
                    sx={{ flex: 1 }}
                />
                {productStore.getCurrentStoreId() === StoreIds.WB && (
                    <>
                        <Button variant="contained" onClick={toggleFilterModal} disabled={!searchRequest}>
                            Фильтры
                        </Button>
                        {!hidePopups && <WbFiltersPopup />}
                    </>
                )}
            </Box>
            {showFilterModal && <RansomFilterModal ransom_id={ransom_id} onClose={toggleFilterModal} />}
            {wbFilters.length > 0 && (
                <Box sx={{ mt: 1 }}>
                    <RansomFilterChips ransom_id={ransom_id} disableDelete={disableDeleteFilter} />
                </Box>
            )}
        </>
    );
};

export default observer(RansomSearchQuery);
