import React, { useState, Fragment, ReactNode, ChangeEvent } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

import { PaginationType } from 'stores/prototypes/ListStore.prototype';
import commonStore from 'stores/commonStore';

import Select from 'ui/Select';
import MUIPagination from '@mui/material/Pagination';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import DirectionsIcon from '@mui/icons-material/Directions';
import useMediaQuery from '@mui/material/useMediaQuery';

import Progress from 'ui/Progress';

const pageSizeOptions = [5, 10, 15, 20, 30, 50, 100].map(value => ({ text: `${value} записей`, value }));

export type PaginationProps = {
    pagination: PaginationType;
    listCount: number;
    loadingCount?: boolean;
    pageChange: (pageNumber: number) => void;
    pageSizeChange: (index: number) => void;
    children?: ReactNode;
    showPult?: boolean;
    scrollToElementId?: string;
    reverseOnMobile?: boolean;
    hideGoToPage?: boolean;
};

const DEFAULT_PAGE_NUMBER = 1;
const MAX_PAGE_NUMBERS_WITHOUT_PAGER = 9;

const Pagination = (props: PaginationProps) => {
    const { isMobile } = commonStore;

    const [pageNumber, changePageNumber] = useState(0);
    const {
        pagination,
        listCount,
        loadingCount,
        pageChange,
        pageSizeChange,
        reverseOnMobile = true,
        showPult = false,
        hideGoToPage = false,
        children,
        scrollToElementId
    } = props;

    const scrollToTop = () => {
        if (scrollToElementId) {
            document.getElementById(scrollToElementId)?.scrollIntoView(true);
        }
    };

    const handleChangePageNumber = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        changePageNumber(Number(value) || DEFAULT_PAGE_NUMBER);
        scrollToTop();
    };

    const handleClickGo = () => {
        pageChange(pageNumber || DEFAULT_PAGE_NUMBER);
        scrollToTop();
    };

    const handlePageChange = (event: ChangeEvent<unknown>, activePage: number) => {
        pageChange(Number(activePage));
        scrollToTop();
    };

    const handleChangePageSize = ({ value }: { value: number }) => {
        pageSizeChange(value);
        scrollToTop();
    };

    const isNarrow = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const topSx: SxProps = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...(isNarrow && reverseOnMobile
            ? {
                  flexFlow: 'column',
                  marginBottom: '1rem'
              }
            : {})
    };

    const topBoxSx: SxProps = {
        ...topSx,
        ...(isMobile
            ? {
                  maxWidth: '100vw',
                  position: 'sticky',
                  left: 0
              }
            : {})
    };

    return (
        <Box sx={topBoxSx}>
            <Box sx={topSx}>
                <MUIPagination
                    onChange={handlePageChange}
                    count={pagination.totalPages}
                    page={pagination.activePage}
                    shape="rounded"
                    siblingCount={1}
                    hideNextButton={isMobile}
                    hidePrevButton={isMobile}
                />

                {!isMobile && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ margin: '0 0.5em', display: 'inline' }}>На странице</Box>
                        <Select
                            size="small"
                            options={pageSizeOptions}
                            value={pagination.pageSize}
                            onChange={handleChangePageSize}
                        />
                    </Box>
                )}

                {!hideGoToPage && !showPult && !isMobile && pagination.totalPages > MAX_PAGE_NUMBERS_WITHOUT_PAGER && (
                    <Fragment>
                        &nbsp;&nbsp;
                        <InputBase
                            value={pageNumber || ''}
                            type="number"
                            className="crm-List__pagination_pager"
                            placeholder="Перейти на указанный номер страницы"
                            onChange={handleChangePageNumber}
                        />
                        <IconButton
                            size="small"
                            type="submit"
                            title="Перейти на указанный номер страницы"
                            onClick={handleClickGo}
                        >
                            <DirectionsIcon />
                        </IconButton>
                    </Fragment>
                )}
                {showPult && children}
            </Box>

            <Box sx={{ position: 'relative' }}>
                Найдено:
                <Progress show={Boolean(loadingCount)} linear color="primary" />
                {!loadingCount && listCount.toLocaleString()}
            </Box>
        </Box>
    );
};

export default Pagination;
