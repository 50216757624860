import React from 'react';
import { observer } from 'mobx-react';

import ransomStore from 'stores/ransomStore';
import productStore, { StoreIds } from 'stores/productStore';

import InfoPopup from 'ui/InfoPopup';
import Checkbox from 'ui/Checkbox';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import RansomRivalEditingBlock from './RansomRivalEditingBlock';

const ProductRivalsPopup = React.memo(() => {
    return (
        <InfoPopup size="large">
            <Typography variant="subtitle2">Для чего добавлять товары конкурентов в корзину перед выкупом?</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Если вы будете добавлять товары конкурентов (или свои похожие товары), а выкупать только свой, то со
                временем ваш товар появится в “Похожих товарах” в карточке конкурента. Тем самым вы получите
                дополнительный трафик на свою карточку с карточек конкурентов.
            </Typography>
            <Typography variant="body2" sx={{ my: 1 }}>
                Стоимость добавления одного товара конкурента - 5₽. Добавлять можно не более 5 разных товаров за раз.
            </Typography>
            <Typography variant="caption" sx={{ mt: 1 }}>
                После создания Выкупа вам будет доступна видеозапись, где вы сможете убедиться, что товары конкурентов
                на самом деле были добавлены в корзину перед выкупом.
            </Typography>
        </InfoPopup>
    );
});

const RansomRivalItemsBlock = ({ ransom_id }: { ransom_id: number }) => {
    const { rivalEnable } = ransomStore.getItem(ransom_id).property;

    const handleToggleRivals = () => {
        if (rivalEnable) {
            ransomStore.setEditingItem(ransom_id, { rival_item_ids: [] });
            ransomStore.setProperty(ransom_id, { rivalItems: [] });
        }
        ransomStore.setProperty(ransom_id, { rivalEnable: !rivalEnable });
    };

    return (
        <>
            <Stack direction="row">
                <Checkbox
                    label="Добавить товары конкурентов в корзину перед покупкой"
                    checked={rivalEnable}
                    onChange={handleToggleRivals}
                />
                <ProductRivalsPopup />
            </Stack>
            {rivalEnable && <RansomRivalEditingBlock ransom_id={ransom_id} />}
        </>
    );
};

export default observer(RansomRivalItemsBlock);
