import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import deliveryStore from 'stores/deliveryStore';
import reviewStore from 'stores/reviewStore';
import productStore from 'stores/productStore';

import Select, { SelectMenuType } from 'ui/Select';

const SelectReviewProduct = ({ review_id, onClose }: { review_id: number; onClose: () => void }) => {
    useEffect(() => {
        deliveryStore.fetchSuitableDeliveries(productStore.getCurrentStoreId());
    }, [productStore.listFilter.store_id]);

    const handleChangeRansom = ({ value }: { value: number }) => {
        reviewStore.setEditingItem(review_id, { ransom_id: value });
        onClose();
    };

    const { loadingSuitableDeliveriesToReview, suitableDeliveriesToReview } = deliveryStore;
    const deliveryOptions: SelectMenuType[] = suitableDeliveriesToReview
        .filter(({ status }) => status === 4)
        .map(
            ({
                ransom_id,
                updateTime,
                ransom: {
                    product,
                    deliveryStorePoint: { address }
                }
            }) => {
                const { store_item_id } = product;
                const { title } = productStore.calcShortProduct(product);

                return {
                    value: ransom_id,
                    text: `№${store_item_id}: ${title}`,
                    caption: `${new Date(updateTime * 1000).toLocaleDateString('ru')} :: ${address}`
                };
            }
        );

    const { ransom_id } = reviewStore.getItem(review_id).editingItem || {};

    return (
        <Select
            label="Выберите товар"
            value={ransom_id}
            onChange={handleChangeRansom}
            options={deliveryOptions}
            fullWidth
            loading={loadingSuitableDeliveriesToReview}
            variant="classic"
        />
    );
};

export default observer(SelectReviewProduct);
