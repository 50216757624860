import React from 'react';

import { useToggleModal } from 'ui/Modal';
import { DeliveryPointAddress, GenderType } from 'stores/ransomStore';

import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import HomeIcon from '@mui/icons-material/Home';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

import DeliveryPointModal from 'components/Items/Ransom/EditingBlocks/DeliveryPointModal';
import productStore, { StoreIds } from 'stores/productStore';
import RansomClosestAddressBtn from 'components/Items/Ransom/EditingBlocks/RansomAddress/RansomClosestAddressBtn';

const preventEvent = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
};

const RansomSmartAddressItem = ({
    address,
    onChange,
    store_point_id,
    product_id,
    gender
}: {
    address: string | null;
    onChange: (storeDeliveryPoint: DeliveryPointAddress) => void;
    gender: GenderType;
    store_point_id: number | null;
    product_id: number | null;
}) => {
    const [showModal, toggleModal] = useToggleModal();

    const enableClosestAccounts = productStore.getCurrentStoreId() === StoreIds.WB;

    return (
        <>
            <ListItemButton onClick={toggleModal} sx={{ px: 0 }}>
                <ListItem sx={{ px: 0 }}>
                    <ListItemAvatar onClick={preventEvent}>
                        {enableClosestAccounts && store_point_id && (
                            <RansomClosestAddressBtn
                                store_point_id={store_point_id}
                                gender={gender}
                                product_id={product_id}
                                onChangeStorePoint={onChange}
                            />
                        )}
                        {(!enableClosestAccounts || !store_point_id) && (
                            <Avatar>
                                <HomeIcon />
                            </Avatar>
                        )}
                    </ListItemAvatar>
                    <ListItemText
                        primary={address || <Typography sx={{ color: 'error.main' }}>Адрес ПВЗ не задан</Typography>}
                        secondary="Адрес доставки"
                    />
                </ListItem>
            </ListItemButton>
            {showModal && <DeliveryPointModal onClose={toggleModal} onChoose={onChange} />}
        </>
    );
};

export default RansomSmartAddressItem;
