import React from 'react';
import { Link } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';
import Grid from 'ui/Grid';

import KeysRansomsModal from './KeysRansomsModal';
import ListContainer from '../ListContainer';

const KeysRansoms = () => {
    const [showModal, toggleModal] = useToggleModal();

    return (
        <ListContainer bgUrl="/static/listbg/unruly-05-done.webp">
            <Container maxWidth="md">
                <Paper elevation={1} sx={{ padding: '1rem 2rem' }}>
                    <Typography variant="h5">Выкупы под ключ</Typography>
                    <Typography variant="body1" sx={{ my: 2 }}>
                        Услуги оказывает собственный фулфилмент в 5 регионах РФ
                        <Link to={'/fulfillment/about'} style={{ paddingLeft: '0.5rem' }}>
                            Topstore FulFillment
                        </Link>
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 3 }}>
                        Выкупы с Wildberries, Ozon, Яндекс.Маркет, Авито, СберМегаМаркет от{' '}
                        <b>150 рублей с забором с ПВЗ</b>
                    </Typography>

                    <Grid columns={2} alignItems="flex-start" stackable>
                        <Grid.Column>
                            <Typography variant="h6" sx={{ my: 2 }}>
                                Забор товара с ПВЗ
                            </Typography>

                            <ul>
                                <li>Согласование плана выкупов</li>
                                <li>Забор товара с ПВЗ по маршруту</li>
                                <li>Перемаркировка товара</li>
                                <li>Отправка на склад маркетплейса</li>
                            </ul>
                        </Grid.Column>
                        <Grid.Column>
                            <Typography variant="h6" sx={{ my: 2 }}>
                                Выкупы под ключ
                            </Typography>
                            <ul>
                                <li>Разработка стратегии продвижения</li>
                                <li>Согласование плана выкупов</li>
                                <li>Без повторов ПВЗ</li>
                                <li>Формирование выкупов в сервисе</li>
                                <li>Забор товара с ПВЗ</li>
                                <li>Подготовка и отправка на склад маркетплейса</li>
                                <li>Согласование и написание отзывов</li>
                                <li>Удобная аналитика</li>
                            </ul>
                        </Grid.Column>
                    </Grid>
                    <Stack>
                        <Typography variant="caption">Заявки обрабатываются в будни с 09 до 19 МСК</Typography>
                        <Typography variant="caption">Минимальный заказ 50 выкупов</Typography>
                    </Stack>
                </Paper>
                <Box sx={{ display: 'flex', p: 2 }}>
                    <Button color="primary" variant="contained" sx={{ margin: 'auto' }} onClick={toggleModal}>
                        Заказать Выкупы под ключ
                    </Button>
                    {showModal && <KeysRansomsModal onClose={toggleModal} />}
                </Box>
            </Container>
        </ListContainer>
    );
};

export default KeysRansoms;
