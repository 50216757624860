import React, { useState } from 'react';
import { observer } from 'mobx-react';
import deepCopy from 'common/deepCopy';

import ransomStore, { DeliveryPointAddress, Ransom } from 'stores/ransomStore';
import { WbSize } from 'stores/productStore';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';

import RansomSmartModal from './ModalBlocks/RansomSmartModal';

const RansomSmartEditBtn = ({ ransom }: { ransom: Ransom }) => {
    const [showModal, toggleModal] = useToggleModal();
    const [errors, setErrors] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);

    const [address, setAddress] = useState(ransom.deliveryStorePoint.address);
    const [store_point_id, setStorePointId] = useState(ransom.store_point_id);
    const [wb_size_id, setWbSizeId] = useState(ransom.wb_size_id);
    const [planningExecuteTime, setExecutionTime] = useState(ransom.planningExecuteTime);

    const handleDateChange = (date: Date | null) => {
        if (date) {
            setExecutionTime(Math.floor(date.getTime() / 1000));
        }
    };

    const handleChooseAddress = ({ store_point_id, address }: DeliveryPointAddress) => {
        setStorePointId(store_point_id);
        setAddress(address);
    };

    const handleChooseSize = (wb_size_id: number, wbSize: WbSize) => {
        setWbSizeId(wb_size_id);
    };

    const handleSave = async () => {
        setLoading(true);
        ransomStore.setEditingItem(ransom.ransom_id, {
            store_point_id,
            wb_size_id,
            planningExecuteTime
        });

        const { ransom_id } = ransom;
        await ransomStore.saveItem(ransom_id);
        setLoading(false);

        const { errors } = ransomStore.getItem(ransom_id);
        if (errors.length) {
            setErrors(deepCopy(errors));
        } else {
            ransomStore.fetchItem(ransom_id);
            toggleModal();
        }
    };

    return (
        <>
            <Button color="secondary" onClick={toggleModal} fullWidth>
                Редактировать smart выкуп
            </Button>
            {showModal && (
                <RansomSmartModal
                    ransom_id={ransom.ransom_id}
                    time={Number(planningExecuteTime)}
                    wb_size_id={wb_size_id}
                    address={address}
                    product_id={Number(ransom.product_id)}
                    errors={errors}
                    onClose={toggleModal}
                    handleDateChange={handleDateChange}
                    handleChooseAddress={handleChooseAddress}
                    handleChooseSize={handleChooseSize}
                    handleSave={handleSave}
                    loading={loading}
                    gender={ransom.gender}
                    handleChangeGender={() => {}}
                    store_point_id={store_point_id}
                />
            )}
        </>
    );
};

export default observer(RansomSmartEditBtn);
