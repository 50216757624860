import React from 'react';
import { observer } from 'mobx-react';

import wishlistStore from 'stores/wishlistStore';

import Table, { TableHead, TableRow, TableCell, TableBody } from 'ui/Table';
import Time from 'ui/Time';
import { WarningMessage } from 'ui/Message';
import Avatar from '@mui/material/Avatar';
import ProductLink from 'components/Items/Product/ProductLink';
import Chip from '@mui/material/Chip';

import ListWrapper from '../ListWrapper';
import { Link } from 'react-router-dom';
import productStore from 'stores/productStore';

const WishlistList = () => {
    const { list, loadingList } = wishlistStore;

    return (
        <ListWrapper store={wishlistStore} bgUrl="/static/listbg/unruly-08-downloading.webp">
            <Table elevation={0} loading={loadingList}>
                <TableHead>
                    <TableRow>
                        <TableCell>Наименование</TableCell>
                        <TableCell>Прогресс</TableCell>
                        <TableCell>Статус</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(({ wishlist_id, quantity, product_id, product, createTime, status }) => {
                        const { photos, title } = productStore.calcShortProduct(product);
                        return (
                            <TableRow key={wishlist_id}>
                                <TableCell>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                        <Time time={createTime} />
                                        <div>
                                            <Avatar src={photos[0]} variant="rounded" />
                                        </div>
                                        {product_id && <ProductLink product_id={product_id} title={title} />}
                                    </div>
                                </TableCell>
                                <TableCell>0 / {quantity}</TableCell>
                                <TableCell>
                                    <Chip label="Ожидает оплату" color="warning" />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            {!loadingList && list.length === 0 && (
                <WarningMessage header="Нет добавлений в Лист ожидания">
                    Вы можете добавить ваш <Link to="/products/list">товар</Link> в Лист ожидания
                </WarningMessage>
            )}
        </ListWrapper>
    );
};

export default observer(WishlistList);
