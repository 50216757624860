import React from 'react';
import { observer } from 'mobx-react';

import authStore from 'stores/authStore';

import Grid from 'ui/Grid';
import { WarningMessage } from 'ui/Message';
import Container from '@mui/material/Container';

import ApiStatisticsList from './ApiStatisticsList';
import WbToken64Field from './WbToken64Field';

const ApiWbStatistics = () => {
    if (!authStore.isAdmin()) {
        return (
            <WarningMessage header="Раздел в разработке">
                Скоро здесь будет раздел, в котором можно будет смотреть вашу статистику по продажам в ВБ
            </WarningMessage>
        );
    }

    return (
        <>
            <Container maxWidth="sm">
                <Grid>
                    <WbToken64Field />
                </Grid>
            </Container>
            {authStore.wbTokenX64 && <ApiStatisticsList />}
        </>
    );
};

export default observer(ApiWbStatistics);
