import React, { SyntheticEvent } from 'react';
import { useLocation } from 'react-router-dom';
import locationHistory from 'locationHistory';
import commonStore from 'stores/commonStore';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';

import SeoFulfillmentModal from './SeoFulfillmentModal';

const FFHeader = () => {
    const { pathname } = useLocation();
    const [showModal, toggleModal] = useToggleModal();
    const { isMobile } = commonStore;

    const onTabClick = (event: SyntheticEvent, pathname: string) => {
        locationHistory.push(pathname);
    };

    return (
        <Box sx={{ backgroundColor: 'black' }}>
            <Box
                sx={{
                    backgroundColor: 'black',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <img src={'/static/ff/fflogo.png'} height={isMobile ? 60 : 100} />
                <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', pr: 2 }}>
                    {!isMobile && (
                        <Button color="secondary" variant="contained" onClick={toggleModal}>
                            Хочу консультацию по FulFillment
                        </Button>
                    )}
                    {isMobile && (
                        <Button
                            icon={<HeadsetMicIcon />}
                            tooltip="Хочу консультацию по FulFillment"
                            color="secondary"
                            size="large"
                            variant="contained"
                            onClick={toggleModal}
                        />
                    )}
                </Box>
                {showModal && <SeoFulfillmentModal onClose={toggleModal} />}
            </Box>
            <Box>
                <Tabs value={pathname} onChange={onTabClick} indicatorColor="secondary" textColor="secondary">
                    <Tab value="/fulfillment/about" label="О Фулфилменте" sx={{ color: 'white', fontWeight: 500 }} />
                    <Tab
                        value="/fulfillment/price"
                        label={`Наши цены${isMobile ? '' : ' / Калькулятор'}`}
                        sx={{ color: 'white', fontWeight: 500 }}
                    />
                    <Tab
                        value="/fulfillment/contacts"
                        label={`Адрес и Контакты`}
                        sx={{ color: 'white', fontWeight: 500 }}
                    />
                </Tabs>
            </Box>
        </Box>
    );
};

export default FFHeader;
