import fetchApi from 'common/fetchApi';
import { objectToGraphql } from 'common/graphql';
import { GQWBItemShort } from './productApi';
import { WbItem } from 'stores/productStore';

export type WbAdsRate = {
    wb_item_id: number;
    cpm: number;
    wbItem: WbItem;
};

export const fetchWbAdsRates = async (search: string): Promise<WbAdsRate[]> => {
    const graphql = `{ 
          fetchWbAdsRates(search: ${objectToGraphql(search)}) {
            wb_item_id
            cpm
            wbItem {
                ${GQWBItemShort}
            }  
          }
        }`;

    const data = await fetchApi.postGQ<{ fetchWbAdsRates: WbAdsRate[] }>(graphql);
    return data.fetchWbAdsRates;
};

export const fetchWbAdsRatesByCard = async (wb_item_id: number): Promise<WbAdsRate[]> => {
    const graphql = `{ 
          fetchWbAdsRatesByCard(wb_item_id: ${wb_item_id}) {
            wb_item_id
            cpm
            wbItem {
                ${GQWBItemShort}
            }  
          }
        }`;

    const data = await fetchApi.postGQ<{ fetchWbAdsRatesByCard: WbAdsRate[] }>(graphql);
    return data.fetchWbAdsRatesByCard;
};
