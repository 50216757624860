import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import locationHistory from 'locationHistory';

import reviewStore from 'stores/reviewStore';
import authStore from 'stores/authStore';

import { WarningMessage } from 'ui/Message';
import Table, { TableBody, TableCell, TableHead, TableRow } from 'ui/Table';
import Time from 'ui/Time';
import Button from 'ui/Button';
import CutString from 'ui/CutString';

import { ReviewLinkState } from 'components/Items/Review/ReviewLink';
import ListWrapper from '../ListWrapper';
import ListPagination from '../ListPagination';

import SuitableDeliveriesTabs from './SuitableDeliveriesTabs';
import ReviewsFilter from './ReviewsFilter';
import ReviewStatus from './ReviewStatus';
import ProductTitleLink from '../Products/ProductTitleLink';

const ReviewsList = () => {
    const { list, loadingList, handleSort, adjustDirection } = reviewStore;

    return (
        <ListWrapper
            store={reviewStore}
            bgUrl="/static/listbg/unruly-12-no-messages.webp"
            createBtn={<SuitableDeliveriesTabs />}
        >
            <ReviewsFilter />

            <Table elevation={0} loading={loadingList}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sortDirection={adjustDirection('createTime')}
                            onClick={() => handleSort('createTime')}
                        >
                            Дата
                        </TableCell>
                        <TableCell sortDirection={adjustDirection('status')} onClick={() => handleSort('status')}>
                            Статус
                        </TableCell>
                        <TableCell>Отзыв</TableCell>
                        <TableCell>Наименование</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(
                        ({
                            review_id,
                            user_id,
                            reviewText,
                            planningExecuteTime,
                            product,
                            createTime,
                            status,
                            accountIsGood,
                            enable
                        }) => (
                            <TableRow key={review_id} negative={authStore.isAdmin() && !accountIsGood}>
                                <TableCell>
                                    <Time time={createTime} />
                                    {authStore.isAdmin() && <div>user_id #{user_id}</div>}
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => locationHistory.push(ReviewLinkState(review_id))}
                                        tooltip="Открыть карточку отзыва"
                                    >
                                        <ReviewStatus
                                            status={status}
                                            enable={enable}
                                            planningExecuteTime={planningExecuteTime}
                                        />
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <CutString title={reviewText} maxLength={30} />
                                </TableCell>
                                <TableCell>
                                    <ProductTitleLink product={product} />
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
                <ListPagination
                    pagination={reviewStore.pagination}
                    listCount={reviewStore.listCount}
                    pageChange={reviewStore.pageChange}
                    pageSizeChange={reviewStore.pageSizeChange}
                    colSpan={4}
                />
            </Table>

            {!loadingList && list.length === 0 && (
                <WarningMessage header="Нет отзывов">
                    Вы сможете добавить отзыв на <Link to="/deliveries/list">доставку</Link> после того, как вы заберете
                    товар c Wildberries
                </WarningMessage>
            )}
        </ListWrapper>
    );
};

export default observer(ReviewsList);
