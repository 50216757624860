import React, { ReactNode } from 'react';
import { PaginationType } from 'stores/prototypes/ListStore.prototype';
import LoaderAwait from 'ui/LoaderAwait';
import Pagination from 'ui/Pagination';
import { TableRow, TableCell, TableBody } from 'ui/Table';

export type ListPaginationProps = {
    pagination: PaginationType;
    listCount: number;
    loadingCount?: boolean;
    pageChange: (index: number) => void;
    pageSizeChange: (index: number) => void;
    colSpan?: number;
    loading?: boolean;
    nextListLoaded?: boolean;
    children?: ReactNode;
    showPult?: boolean;
    scrollToElementId?: string;
    hideGoToPage?: boolean;
};

const ListPagination = (props: ListPaginationProps) => {
    const {
        pagination,
        listCount,
        loadingCount,
        pageChange,
        pageSizeChange,
        colSpan = 2,
        loading = false,
        showPult = false,
        children,
        scrollToElementId,
        hideGoToPage = false
    } = props;

    return (
        <TableBody>
            <TableRow>
                <TableCell colSpan={colSpan + 1}>
                    <LoaderAwait size="large" active={loading} dimmer />

                    <Pagination
                        listCount={listCount}
                        pageChange={pageChange}
                        pageSizeChange={pageSizeChange}
                        scrollToElementId={scrollToElementId || 'listWrapper'}
                        showPult={showPult}
                        pagination={pagination}
                        loadingCount={loadingCount}
                        hideGoToPage={hideGoToPage}
                    >
                        {children}
                    </Pagination>
                </TableCell>
            </TableRow>
        </TableBody>
    );
};

export default React.memo(ListPagination);
