import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import authStore from 'stores/authStore';
import ransomStore, { RansomType } from 'stores/ransomStore';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

const RansomTypeEditing = ({ ransom_id }: { ransom_id: number }) => {
    const { editingItem } = ransomStore.getItem(ransom_id);
    const { type, store_point_ids } = editingItem;

    const handleToggleType = (event: SyntheticEvent, type: 1 | 2) => {
        if (type) {
            let ransomsCount: null | number = null;
            if (type === RansomType.Group) {
                ransomsCount = 5;
            }
            ransomStore.setEditingItem(ransom_id, { type, ransomsCount });
        }
    };

    return (
        <>
            <Typography>Вариант выкупа</Typography>
            <ToggleButtonGroup
                size="small"
                color="success"
                value={type}
                exclusive
                onChange={handleToggleType}
                fullWidth
            >
                <ToggleButton value={RansomType.Once}>Обычный выкуп</ToggleButton>
                <ToggleButton
                    value={RansomType.Group}
                    disabled={!authStore.settings.groupRansoms || (store_point_ids && store_point_ids.length > 1)}
                >
                    Группа выкупов
                </ToggleButton>
                <ToggleButton value={RansomType.Smart}>Smart Выкупы</ToggleButton>
            </ToggleButtonGroup>
        </>
    );
};

export default observer(RansomTypeEditing);
