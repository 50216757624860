import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import deliveryPointsStore from 'stores/deliveryPointsStore';

import TextInput from 'ui/TextInput';

const AddressTextInput = () => {
    const { loadingPoints } = deliveryPointsStore;
    const { address } = deliveryPointsStore.storePointsFilter;

    const handleSearch = (event: SyntheticEvent, { value }: { value: string }) => {
        deliveryPointsStore.changeStoresFilter('address', value);
    };

    return (
        <TextInput
            size="small"
            fullWidth
            variant="classic"
            value={address}
            onChange={handleSearch}
            loading={loadingPoints}
            label="Поиск по адресу ПВЗ"
        />
    );
};

export default observer(AddressTextInput);
