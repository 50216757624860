import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import favoriteStore from 'stores/favoriteStore';
import productStore from 'stores/productStore';
import commonStore from 'stores/commonStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import Grid from 'ui/Grid';
import Modal, { ModalBtnAction, ModalContent } from 'ui/Modal';
import ListError from 'ui/ListError';
import InfoPopup from 'ui/InfoPopup';

import FavoriteIcon from '@mui/icons-material/Favorite';
import Typography from '@mui/material/Typography';

import ProductCard from '../Product/ProductCard';
import CostWithDiscount from '../../CostWithDiscount';
import WbSuggestionsField from '../WbSuggestionsField';
import QuantitySpeedModal from '../QuantitySpeedField';

const FavoritePopup = React.memo(() => {
    return (
        <InfoPopup size="large">
            <Typography variant="subtitle2">Для чего нужно добавлять в “Избранное”?</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Количество добавлений в Избранное учитывается в алгоритмах Wildberries при ранжирование товара в поиске.
                Наш робот зайдет на страницу вашего товара и добавит его в Избранное.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Можно делать примерно 1-2 добавлений в Избранное на каждый выкуп, чтобы ваш товар соответствовал средним
                критериям на Wildberries и не вызывал подозрительной активности.
            </Typography>
        </InfoPopup>
    );
});

const FavoriteModal = ({ product_id, onClose }: { product_id: number; onClose: () => void }) => {
    const favorite_id = CREATING_ITEM_ID;

    useMemo(() => {
        productStore.fetchList();
        favoriteStore.fetchItem(favorite_id);
        favoriteStore.setEditingItem(favorite_id, {
            product_id,
            quantity: 1,
            speed: null,
            searchRequest: null
        });
    }, [favorite_id, product_id]);

    const { loadingItem, editingItem, errors } = favoriteStore.getItem(favorite_id);
    const { quantity } = editingItem;

    const handleCreate = async () => {
        if (await favoriteStore.createItem()) {
            commonStore.setGlobalInfoMessage('Заявка на добавление в избранное создана, мы сообщим о ее осуществление');
            onClose();
        }
    };

    const cost = 4;

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'inherit' },
        {
            onClick: handleCreate,
            label: (
                <>
                    Добавить в Избранное (<CostWithDiscount cost={Number(quantity) * cost} />)
                </>
            ),
            loading: loadingItem,
            disabled: !quantity,
            variant: 'contained',
            color: 'secondary',
            endIcon: <FavoriteIcon />
        }
    ];

    const { item: product } = productStore.getItem(product_id);

    return (
        <Modal
            maxWidth="sm"
            onClose={onClose}
            actions={actions}
            header={
                <>
                    <FavoriteIcon />
                    &nbsp;Добавить в Избранное
                </>
            }
            fullScreen={commonStore.isMobile}
        >
            <ModalContent>
                <ListError errors={errors} />
                <Grid>
                    <Grid.Column>
                        <ProductCard product={product} size="small" />
                    </Grid.Column>
                    <Grid.Column>
                        <WbSuggestionsField item_id={favorite_id} store={favoriteStore} />
                    </Grid.Column>
                    <QuantitySpeedModal item_id={favorite_id} store={favoriteStore} infoPopup={<FavoritePopup />} />
                </Grid>
            </ModalContent>
        </Modal>
    );
};

export default observer(FavoriteModal);
