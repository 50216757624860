import { action, makeObservable, observable } from 'mobx';
import * as autoAnswerApi from 'api/autoAnswerApi';
import ListStorePrototype, { OrderDirectionType } from './prototypes/ListStore.prototype';
import { CREATING_ITEM_ID } from './prototypes/ItemStore.prototype';
import { FEEDBACKS_OFFSET_COUNT, FeedbacksFilterPult, WbItem } from './productStore';

import deepCopy from 'common/deepCopy';

import { FeedbacksOrderBy, WbSimilarFeedback } from 'api/productApi';

export type AutoAnswerTemplate = {
    auto_answer_template_id: number;

    title: string;
    text: string;
    stars: number[];

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type AutoAnswerConfig = {
    auto_answer_config_id: number;
    user_id: number;
    wbSupplierId: string;

    feedback_noty_star_1: boolean | null;
    feedback_noty_star_2: boolean | null;
    feedback_noty_star_3: boolean | null;
    feedback_noty_star_4: boolean | null;
    feedback_noty_star_5: boolean | null;

    question_noty: boolean | null;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type WbQuestion = {
    question_id: number;
    wb_item_id: number;
    wb_user_id: number;
    wb_question_id: string;

    text: string;

    createTime: number;
    updateTime: number;
};

export type WbItemQuestion = {
    question: WbQuestion;
    wbItem: WbItem;
};

class AutoAnswerStore extends ListStorePrototype<AutoAnswerTemplate, AutoAnswerTemplate> {
    listFilterClear = {};

    orderBy = 'createTime';

    constructor() {
        super('auto_answer_template_id', 'auto_answer', autoAnswerApi);
        makeObservable(this);
    }

    async fetchItem(id: number, withoutCache?: boolean) {
        const item = await super.fetchItem(id, withoutCache);
        if (id !== CREATING_ITEM_ID) {
            if (item) {
                this.setEditingItem(id, deepCopy(item));
            }
        }
        return item;
    }

    @observable
    feedbacksPult: FeedbacksFilterPult | null = null;
    @observable
    feedbacks: WbSimilarFeedback[] = [];

    @action
    async fetchWbApiFeedbacks(orderBy: FeedbacksOrderBy, sort: OrderDirectionType, page: number): Promise<void> {
        this.feedbacksPult = {
            loading: true,
            errors: [],
            orderBy,
            orderDirection: sort,
            limit: FEEDBACKS_OFFSET_COUNT,
            offset: page * FEEDBACKS_OFFSET_COUNT,
            hasMore: true
        };

        this.feedbacks = page === 0 || !this.feedbacks ? [] : this.feedbacks;

        if (page === 0 || !this.feedbacks) {
            this.feedbacks = [];
        }

        try {
            this.feedbacks = await autoAnswerApi.fetchWbApiFeedbacks(
                orderBy,
                sort,
                this.feedbacksPult.offset,
                this.feedbacksPult.limit
            );

            if (this.feedbacks.length < FEEDBACKS_OFFSET_COUNT) {
                if (this.feedbacksPult) {
                    this.feedbacksPult = {
                        ...this.feedbacksPult,
                        hasMore: false
                    };
                }
            }
        } catch (errors) {
            if (errors instanceof Array) {
                this.feedbacksPult = {
                    ...this.feedbacksPult,
                    errors
                };
            }
        } finally {
            this.feedbacksPult = {
                ...this.feedbacksPult,
                loading: false
            };
        }
    }

    @observable
    loadingQuestions = false;
    @observable
    questions: WbItemQuestion[] = [];
    @observable
    questionsErrors: string[] = [];

    @action
    async fetchWbApiQuestions(): Promise<void> {
        this.loadingQuestions = true;
        try {
            this.questions = await autoAnswerApi.fetchWbApiQuestions();
        } catch (errors) {
            if (errors instanceof Array) {
                this.questionsErrors = errors;
            }
        } finally {
            this.loadingQuestions = false;
        }
    }

    @observable
    config: AutoAnswerConfig | null = null;

    @action
    async pullConfig(): Promise<void> {
        this.config = await autoAnswerApi.pullAutoAnswerConfig();
    }

    @action
    async createAutoAnswerConfig(wbSupplierId: string): Promise<void> {
        await autoAnswerApi.createAutoAnswerConfig(wbSupplierId);
    }
}

export default new AutoAnswerStore();
