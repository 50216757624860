import React, { useEffect, useState } from 'react';

import commonStore from 'stores/commonStore';
import { PaginationType } from 'stores/prototypes/ListStore.prototype';
import { BalanceTransaction, fetchUserTransactions, TransactionsItems } from 'api/authApi';
import { getDefaultPagination, matchPaginationTotalPages } from 'common/pagination';

import Modal, { ModalContent } from 'ui/Modal';
import ListError from 'ui/ListError';
import Table, { TableBody, TableRow, TableCell, TableHead } from 'ui/Table';
import Time from 'ui/Time';
import MoneyIcon from '@mui/icons-material/Money';
import ListPagination from '../List/ListPagination';

const matchTypeName = (itemType: TransactionsItems): string => {
    switch (itemType) {
        case 'cart':
            return 'в корзину';
        case 'favorite':
            return 'в избранное';
        case 'question':
            return 'вопрос';
        case 'ransom':
            return 'выкуп';
        case 'review':
            return 'отзыв';
        case 'wishlist':
            return 'лист ожидания';
        case 'seoConsult':
            return 'SEO карточки';
        case 'brand':
            return 'бренд';
        case 'feedbackReaction':
            return 'Реакция на отзыв';
        default:
            const error: never = itemType;
            return error;
    }
};

const TransactionsModal = ({ onClose }: { onClose: () => void }) => {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [balanceTransactions, setBalanceTransactions] = useState<BalanceTransaction[]>([]);
    const [pagination, setPagination] = useState<PaginationType>({ ...getDefaultPagination(), pageSize: 100 });
    const [count, setCount] = useState(0);

    useEffect(() => {
        setLoading(true);
        const { pageSize, activePage } = pagination;
        fetchUserTransactions(pageSize, (activePage - 1) * pageSize)
            .then(({ list, count }) => {
                setBalanceTransactions(list);
                setPagination(matchPaginationTotalPages(pagination, count));
                setCount(count);
            })
            .catch(setErrors)
            .finally(() => setLoading(false));
    }, [pagination.activePage, pagination.pageSize]);

    const handlePageChange = (activePage: number) => {
        setPagination({ ...pagination, activePage });
    };

    const pageSizeChange = (pageSize: number) => {
        setPagination({ pageSize: pageSize, activePage: 1, totalPages: 1 });
    };

    return (
        <Modal
            maxWidth="md"
            onClose={onClose}
            header={
                <>
                    <MoneyIcon />
                    &nbsp;История операций
                </>
            }
            fullScreen={commonStore.isMobile}
        >
            <ModalContent id="listWrapper">
                <ListError errors={errors} />
                <Table compact loading={loading}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Дата</TableCell>
                            <TableCell>Операция</TableCell>
                            <TableCell>Сумма</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {balanceTransactions.map(bt => {
                            let cost: number;
                            let time: number;
                            const { direction } = bt;

                            if (direction === 'in') {
                                const { payment } = bt;
                                cost = payment.cost;
                                time = payment.createTime;
                            } else {
                                const { transaction } = bt;
                                cost = transaction.cost;
                                time = transaction.createTime;
                            }
                            return (
                                <TableRow
                                    key={time}
                                    positive={direction === 'in' && cost > 0}
                                    negative={direction === 'in' && cost < 0}
                                >
                                    <TableCell>
                                        <Time time={time} />
                                    </TableCell>
                                    <TableCell>
                                        {bt.direction === 'in' &&
                                            (bt.payment.type === 'gift'
                                                ? 'подарок'
                                                : cost > 0
                                                ? 'пополнение'
                                                : 'списание')}
                                        {bt.direction === 'out' && matchTypeName(bt.transaction.itemType)}
                                    </TableCell>
                                    <TableCell>
                                        {bt.direction === 'in' ? (cost > 0 ? '+' : '') : '-'}
                                        {cost} ₽
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <ListPagination
                        pagination={pagination}
                        listCount={count}
                        pageChange={handlePageChange}
                        pageSizeChange={pageSizeChange}
                        colSpan={3}
                        showPult
                    />
                </Table>
            </ModalContent>
        </Modal>
    );
};

export default TransactionsModal;
