import fetchApi from 'common/fetchApi';
import { objectToGraphql } from '../common/graphql';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { AutoAnswerConfig, AutoAnswerTemplate, WbItemQuestion } from 'stores/autoAnswerStore';
import authStore from '../stores/authStore';
import { FeedbacksOrderBy, GQFeedbackFields, GQWBItemShort, WbSimilarFeedback } from './productApi';

export const createItem = async (autoAnswer: Partial<AutoAnswerTemplate>): Promise<number> => {
    const graphql = `mutation { 
          createAutoAnswerTemplate(autoAnswerTemplate: ${objectToGraphql(autoAnswer)})
        }`;

    const data = await fetchApi.postGQ<{ createAutoAnswerTemplate: number }>(graphql);
    return data.createAutoAnswerTemplate;
};

const GQAutoAnswerTemplateFields = `
    auto_answer_template_id
    ${authStore.isAdmin() ? `user_id` : ``}
    text
    title
    stars    
    updateTime
    createTime
`;

export const fetchItem = async (auto_answer_template_id: number): Promise<AutoAnswerTemplate> => {
    const graphql = `{ 
          fetchAutoAnswerTemplate(auto_answer_template_id: ${auto_answer_template_id}) {
            ${GQAutoAnswerTemplateFields}
           }
        }`;

    const data = await fetchApi.getGQ<{ fetchAutoAnswerTemplate: AutoAnswerTemplate }>(graphql);
    return data.fetchAutoAnswerTemplate;
};

export const saveItem = async (
    auto_answer_template_id: number,
    autoAnswerDiff: Partial<AutoAnswerTemplate>
): Promise<number> => {
    const graphql = `mutation { 
          updateAutoAnswerTemplate(auto_answer_template_id: ${auto_answer_template_id}, autoAnswerDiff: ${objectToGraphql(
        autoAnswerDiff
    )})
        }`;

    const data = await fetchApi.postGQ<{ updateAutoAnswerTemplate: number }>(graphql);
    return data.updateAutoAnswerTemplate;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    autoAnswerFilter: {},
    controller?: AbortController
): Promise<{ list: AutoAnswerTemplate[]; count: number }> => {
    const graphql = `{
            fetchAutoAnswersTemplatesList(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}") {
                list {
                    ${GQAutoAnswerTemplateFields}
                }
                count
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchAutoAnswersTemplatesList: { list: AutoAnswerTemplate[]; count: number } }>(
        graphql
    );
    return data.fetchAutoAnswersTemplatesList;
};

export const getFeedbacksAnswersSamples = async (stars: number[]): Promise<string[]> => {
    const graphql = `{ 
          getFeedbacksAnswersSamples(stars: ${objectToGraphql(stars)})
        }`;

    const data = await fetchApi.postGQ<{ getFeedbacksAnswersSamples: string[] }>(graphql);
    return data.getFeedbacksAnswersSamples;
};

export const fetchWbApiFeedbacks = async (
    orderBy: FeedbacksOrderBy,
    sort: OrderDirectionType,
    offset: number,
    limit: number
): Promise<WbSimilarFeedback[]> => {
    const graphql = `{
            fetchApiFeedbacks(orderBy: "${orderBy}", sort: "${sort}", offset: ${offset}, limit: ${limit}) {
                feedback {
                    ${GQFeedbackFields}
                }   
                wbItem {
                    ${GQWBItemShort}
                }   
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchApiFeedbacks: WbSimilarFeedback[] }>(graphql);
    return data.fetchApiFeedbacks;
};

export const sendWbApiFeedbackAnswer = async (wb_feedback_id: string, text: string): Promise<boolean> => {
    const graphql = `mutation { 
          sendWbApiFeedbackAnswer(wb_feedback_id: "${wb_feedback_id}", text: ${objectToGraphql(text)})
        }`;

    const data = await fetchApi.postGQ<{ sendWbApiFeedbackAnswer: boolean }>(graphql);
    return data.sendWbApiFeedbackAnswer;
};

export const pullAutoAnswerConfig = async (): Promise<AutoAnswerConfig> => {
    const graphql = `{ 
          pullAutoAnswerConfig {
                auto_answer_config_id
                wbSupplierId
    
                feedback_noty_star_1
                feedback_noty_star_2
                feedback_noty_star_3
                feedback_noty_star_4
                feedback_noty_star_5
            
                question_noty
            
                createTime
                updateTime
           }
        }`;

    const data = await fetchApi.postGQ<{ pullAutoAnswerConfig: AutoAnswerConfig }>(graphql);
    return data.pullAutoAnswerConfig;
};

export const createAutoAnswerConfig = async (wbSupplierId: string): Promise<boolean> => {
    const graphql = `mutation { 
          createAutoAnswerConfig(wbSupplierId: ${objectToGraphql(wbSupplierId)})
        }`;

    const data = await fetchApi.postGQ<{ createAutoAnswerConfig: boolean }>(graphql);
    return data.createAutoAnswerConfig;
};

type AutoAnswerConfigInput = Pick<
    AutoAnswerConfig,
    | 'feedback_noty_star_1'
    | 'feedback_noty_star_2'
    | 'feedback_noty_star_3'
    | 'feedback_noty_star_4'
    | 'feedback_noty_star_5'
    | 'question_noty'
>;

export const updateAutoAnswerConfig = async (
    auto_answer_config_id: number,
    configDiff: AutoAnswerConfigInput
): Promise<boolean> => {
    const graphql = `mutation { 
          updateAutoAnswerConfig(auto_answer_config_id: ${auto_answer_config_id}, configDiff: ${objectToGraphql(
        configDiff
    )})
        }`;

    const data = await fetchApi.postGQ<{ updateAutoAnswerConfig: boolean }>(graphql);
    return data.updateAutoAnswerConfig;
};

export const fetchWbApiQuestions = async (): Promise<WbItemQuestion[]> => {
    const graphql = `{
            fetchApiQuestions {
                question {
                    question_id
                    wb_item_id
                    wb_user_id
                    wb_question_id
                
                    text
                
                    createTime
                    updateTime
                }   
                wbItem {
                    ${GQWBItemShort}
                }   
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchApiQuestions: WbItemQuestion[] }>(graphql);
    return data.fetchApiQuestions;
};

export const sendWbApiQuestionAnswer = async (wb_question_id: string, text: string): Promise<boolean> => {
    const graphql = `mutation { 
          sendWbApiQuestionAnswer(wb_question_id: "${wb_question_id}", text: ${objectToGraphql(text)})
        }`;

    const data = await fetchApi.postGQ<{ sendWbApiQuestionAnswer: boolean }>(graphql);
    return data.sendWbApiQuestionAnswer;
};
