import React, { SyntheticEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import * as productApi from 'api/productApi';
import * as authApi from 'api/authApi';

import authStore from 'stores/authStore';

import Grid from 'ui/Grid';
import { InfoMessage, WarningMessage } from 'ui/Message';
import ListError from 'ui/ListError';
import TextInput from 'ui/TextInput';
import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';
import Confirm from 'ui/Confirm';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const ApiTokenInstalled = () => {
    const [showConfirm, toggleConfirm] = useToggleModal();

    const handleDeleteKey = async () => {
        await authApi.deleteWbApiKey();
        authStore.pullUserWbTokenX64();
    };

    return (
        <Grid.Column>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'space-between' }}>
                <Typography variant="subtitle1" color="success.main">
                    Api-токен установлен <CheckIcon fontSize="small" />
                </Typography>
                <Button
                    icon={<DeleteForeverIcon />}
                    tooltip="Удалить токен"
                    color="secondary"
                    size="small"
                    onClick={toggleConfirm}
                />
            </Box>
            {showConfirm && (
                <Confirm
                    content="После этого вы сможете добавить новый ключ"
                    header="Удалить api-key?"
                    confirmButton="Удалить"
                    onConfirm={handleDeleteKey}
                    onCancel={toggleConfirm}
                />
            )}
        </Grid.Column>
    );
};

export const WbTokenNewField = () => {
    const { wbTokenLoading, wbTokenX64 } = authStore;

    const [token, setToken] = useState<string | null>(wbTokenX64);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        authStore.pullUserWbTokenX64().then(token => {
            setToken(token);
        });
    }, []);

    const handleChangeToken = (event: SyntheticEvent, { value }: { value: string }) => {
        setToken(value);
    };

    const testWbApiToken = async () => {
        if (!token) {
            return;
        }

        try {
            setErrors([]);
            setLoading(true);
            await productApi.testWbApiKey(token, 'x64');
            await authStore.pullUserWbTokenX64();
        } catch (errors) {
            if (errors instanceof Array) {
                setErrors(errors);
            }
        } finally {
            setLoading(false);
        }
    };

    if (wbTokenX64) {
        return <ApiTokenInstalled />;
    }

    return (
        <>
            <Grid.Column>
                <InfoMessage header="Как получить x64 Api ключ?">
                    <ul style={{ padding: 0 }}>
                        <li>
                            Откройте личный кабинет Селлера ВБ → Профиль → Настройки → Доступ к Api. Можно сразу по
                            этой&nbsp;
                            <a
                                href="https://seller.wildberries.ru/supplier-settings/access-to-api"
                                rel="noreferrer"
                                target="_blank"
                            >
                                ссылке
                            </a>
                        </li>
                        <li>Нажмите на кнопку "Сгенерировать ключи"</li>
                        <li>Скопируйте появившийся "Ключ для работы с API статистики x64" в поле ниже:</li>
                    </ul>
                </InfoMessage>
            </Grid.Column>
            <Grid.Column>
                <ListError errors={errors} />
                <TextInput
                    label="API-токен"
                    value={token}
                    onChange={handleChangeToken}
                    multiline
                    rows={1}
                    rowsMax={4}
                />
            </Grid.Column>
            <Grid.Column>
                <Button
                    onClick={testWbApiToken}
                    loading={loading || wbTokenLoading}
                    disabled={!token || token.length < 20}
                    variant="contained"
                >
                    Сохранить токен
                </Button>
            </Grid.Column>
            <Grid.Column>
                <WarningMessage header="Доступ к данным">
                    По данному ключу мы можем только получать ваши статистические данные по работе на ВБ. У нас не будет
                    доступа к редактирование или удалению каких-либо ваших данных. Вы всегда можете удалить данный ключ
                    в кабинете Селлера на ВБ.
                </WarningMessage>
            </Grid.Column>
        </>
    );
};

export default observer(WbTokenNewField);
