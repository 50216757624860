import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import * as productApi from 'api/productApi';
import { WBProductSuggestion } from 'api/productApi';

import productStore, { StoreIds, WB30dShortStat } from 'stores/productStore';
import { WBSuggestion } from 'stores/prototypes/ListTransactionsStore.prototype';

import ListError from 'ui/ListError';
import LoaderAwait from 'ui/LoaderAwait';
import Grid from 'ui/Grid';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

import ProductsTableWb from './ProductsTableWb';

const RoundedSalePlan = observer(
    ({
        title,
        product_id,
        fromIndex,
        toIndex,
        products
    }: {
        title: string;
        product_id: number;
        fromIndex: number;
        toIndex: number;
        products: WBProductSuggestion[];
    }) => {
        const item = productStore.getItem(product_id).item;
        if (!item || item.store_id !== StoreIds.WB) {
            return null;
        }

        const costItem = Number(item.wbItem.price?.priceWithSale);

        const neededIds = products.slice(fromIndex, toIndex + 1);
        const { allStat, goodCount }: { goodCount: number; allStat: WB30dShortStat } = neededIds.reduce(
            (acc, { sold30dQty, sold30dAmount }) => {
                if (sold30dQty !== null || sold30dAmount !== null) {
                    acc.goodCount++;
                    acc.allStat.sold30dAmount += Number(sold30dAmount);
                    acc.allStat.sold30dQty += Number(sold30dQty);
                }
                return acc;
            },
            { goodCount: 0, allStat: { sold30dQty: 0, sold30dAmount: 0 } }
        );

        const roundAmount = goodCount > 0 ? Math.round(allStat.sold30dAmount / goodCount) : 0;

        return (
            <>
                <Typography variant="h6">{title}</Typography>
                <Paper>
                    <List>
                        {goodCount === 0 && (
                            <ListItem>
                                <ListItemText
                                    primary={`Не удалось получить данные по объему продаж`}
                                    secondary="Попробуйте обновить позже"
                                />
                            </ListItem>
                        )}
                        {roundAmount > 0 && (
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <LocalAtmIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`≈${roundAmount.toLocaleString()} ₽`}
                                    secondary="Объем продаж за 30 дней"
                                />
                            </ListItem>
                        )}
                        {costItem > 0 && roundAmount > 0 && (
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <CreditCardIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`≈${Math.round(roundAmount / costItem).toLocaleString()} шт`}
                                    secondary="Количество выкупов за 30 дней"
                                />
                            </ListItem>
                        )}
                    </List>
                </Paper>
            </>
        );
    }
);

const SuggestionsTableWB = ({
    suggestion,
    product_id,
    wb_item_id,
    searchRequest
}: {
    suggestion: WBSuggestion | null;
    product_id: number;
    wb_item_id: number;
    searchRequest: string;
}) => {
    const [products, setProducts] = useState<WBProductSuggestion[]>([]);
    const [count, setCount] = useState<number | null>(null);
    const [foundNumber, setFoundNumber] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        // const { name } = suggestion;
        setLoading(true);
        setErrors([]);

        (async () => {
            try {
                // const { data } = await axios.get<WBSuggestion>(
                //     `https://wbxsearch.wildberries.ru/exactmatch/v2/common?query=${encodeURIComponent(name)}`
                // );
                // const { shardKey, filters, query } = data;

                // const { list, count, foundNumber } = await productApi.fetchWbProductSuggestions(
                //     product_id,
                //     shardKey,
                //     query,
                //     filters
                // );
                const { list, count, foundNumber } = await productApi.fetchWbProductSuggestionsNew(
                    product_id,
                    searchRequest
                );
                // productStore.fetchTopItemsStat(
                //     product_id,
                //     list.slice(0, 30).map(({ id }) => id)
                // );

                setProducts(list);
                setCount(count);
                setFoundNumber(foundNumber);
            } catch (errors) {
                console.log(errors);
                setErrors(['При поиске произошла ошибка']);
                setProducts([]);
            } finally {
                setLoading(false);
            }
        })();
    }, [searchRequest, product_id]);

    return (
        <Box sx={{ mt: 1 }}>
            <LoaderAwait active={loading} />
            <ListError errors={errors} />
            {!loading && products.length > 0 && (
                <>
                    <Paper elevation={1} sx={{ mb: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2, alignItems: 'center' }}>
                            <div>
                                {typeof foundNumber === 'number' && foundNumber > -1 && (
                                    <>
                                        Ваш товар на <b>{foundNumber + 1}</b> месте
                                    </>
                                )}
                                {foundNumber === -1 && (
                                    <>Ваш товар не найден в {Number(count) > 1000 ? 'первой тысяче' : 'списке'}</>
                                )}
                            </div>
                            <div>
                                Найдено <b>{Number(count).toLocaleString()}</b>
                            </div>
                            {/*<Button*/}
                            {/*    tooltip="Сохранить запрос, чтобы смотреть статистику изменения места в поиске"*/}
                            {/*    startIcon={<StarBorderIcon />}*/}
                            {/*>*/}
                            {/*    Следить*/}
                            {/*</Button>*/}
                        </Box>
                    </Paper>

                    <Typography variant="h6">Примерный объем выкупов, чтобы попасть в</Typography>

                    <Grid columns={2} disableGutter>
                        <Grid.Column>
                            <RoundedSalePlan
                                title="Топ-10"
                                product_id={product_id}
                                fromIndex={5}
                                toIndex={9}
                                products={products}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <RoundedSalePlan
                                title="Топ-30"
                                product_id={product_id}
                                fromIndex={20}
                                toIndex={29}
                                products={products}
                            />
                        </Grid.Column>
                    </Grid>

                    <Typography variant="body2" sx={{ mt: 2 }}>
                        Топ {Number(count) > 100 ? 100 : count} первых товаров при поиске
                    </Typography>
                    <ProductsTableWb products={products} wb_item_id={wb_item_id} />
                </>
            )}
        </Box>
    );
};

export default observer(SuggestionsTableWB);
