import * as wbProxyApi from 'api/wbProxyApi';
import ListStorePrototype from './prototypes/ListStore.prototype';
import { action, makeObservable, observable } from 'mobx';

export type WBProxy = {
    proxy_id: number;
    urlScheme: 'http' | 'https' | 'socks4' | 'socks5';
    ip: string;
    port: number;
    login: string | null;
    pass: string | null;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

class WBProxyStore extends ListStorePrototype<WBProxy, WBProxy> {
    listFilterClear = {};

    constructor() {
        super('proxy_id', 'admin', wbProxyApi);
        makeObservable(this);
    }

    @observable
    selectedProxyId: number | null = null;

    @action
    selectProxyId = (proxy_id: number) => {
        const { urlScheme, ip, port, login, pass } = this.findById(proxy_id);
        this.selectedProxyId = proxy_id;
        window.postMessage({
            operation: 'testProxy',
            proxyRules: `${urlScheme}://${login && pass ? `${login}:${pass}@` : ''}${ip}:${port}`
        });
    };

    @action
    selectNextProxy = () => {
        let proxy_id: number;

        if (!this.selectedProxyId) {
            proxy_id = this.list[0].proxy_id;
        } else {
            const foundIndex = this.list.findIndex(({ proxy_id }) => proxy_id === this.selectedProxyId);
            const nextIndex = foundIndex >= this.list.length - 1 ? 0 : foundIndex + 1;
            proxy_id = this.list[nextIndex].proxy_id;
        }

        this.selectProxyId(proxy_id);
    };
}

export default new WBProxyStore();
