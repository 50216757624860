import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import productStore from 'stores/productStore';
import ransomStore, { PRE_RUN_VIDEO_TYPE, RansomStatus, RansomType } from 'stores/ransomStore';
import videoStore from 'stores/videoStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import CachedIcon from '@mui/icons-material/Cached';
import Box from '@mui/material/Box';

import RansomCreateMode from './RansomCreateMode';
import RansomShowMode from './RansomShowMode';
import ItemDrawWrapper, { MenuTab } from '../ItemDrawWrapper';
import VideoTabMode from '../VideoTabMode';
import RansomItemMenu from './RansomItemMenu';

import RansomAddressesTabs from './ShowBlocks/RansomAddressesTabs';
import authStore from 'stores/authStore';
import Button from 'ui/Button';

const Ransom = () => {
    const { item_id: item_id_prepare, tabName }: { item_id?: string; tabName?: string } = useParams();
    const ransom_id = Number(item_id_prepare) || CREATING_ITEM_ID;
    const { new_ransom_ids } = ransomStore;

    useMemo(() => {
        productStore.fetchList();
        ransomStore.fetchItem(ransom_id).then(ransom => {
            if (ransom) {
                const { type, ransom_group_id } = ransom;
                if (type && [RansomType.Group, RansomType.Smart].includes(type) && ransom_group_id) {
                    ransomStore.fetchRansomGroup(ransom_group_id);
                }
            }
        });
        videoStore.findWbVideo('ransom', ransom_id);
    }, [ransom_id]);

    const { loadingItem, item: ransom } = ransomStore.getItem(ransom_id);

    useEffect(() => {
        if (ransom?.store_id && ransom?.store_id !== productStore.listFilter.store_id) {
            ransomStore.changeFilter('store_id', ransom?.store_id);
            productStore.changeFilter('store_id', ransom?.store_id);
        }
    }, [ransom?.store_id]);

    if (ransom_id === CREATING_ITEM_ID) {
        return <RansomCreateMode />;
    }

    const videoId = videoStore.getVideoId('ransom', ransom_id);
    const videoPreRunId = videoStore.getVideoId('ransom', ransom_id, PRE_RUN_VIDEO_TYPE);

    const tabs: MenuTab[] = [
        { id: 'main', label: 'Выкуп', pathname: `/ransoms/list/detail/${ransom_id}` },
        {
            id: 'video',
            label: 'Видеозапись',
            pathname: `/ransoms/list/detail/${ransom_id}/video`,
            disabled: !videoId
        }
    ];

    if (videoPreRunId) {
        tabs.push({
            id: 'preRunVideo',
            label: 'Прогон аккаунта',
            pathname: `/ransoms/list/detail/${ransom_id}/preRunVideo`
        });
    }

    const refreshWbStatus = async () => {
        await ransomStore.tryCreateDelivery(ransom_id);
    };

    const RefreshBtn = (
        <Box sx={{ whiteSpace: 'nowrap' }}>
            {authStore.isAdmin() &&
                [RansomStatus.CheckingPayment, RansomStatus.ErrorExpiredPayment].includes(
                    ransom ? ransom.status : -1
                ) && (
                    <Button
                        onClick={refreshWbStatus}
                        icon={<CachedIcon fontSize="large" />}
                        tooltip="Попробовать найти доставку"
                        disabled={loadingItem}
                    />
                )}
            <RansomItemMenu ransom_id={ransom_id} />
        </Box>
    );

    return (
        <ItemDrawWrapper tabs={tabs} activeTab={tabName || 'main'} endTab={RefreshBtn}>
            {new_ransom_ids.length > 0 && new_ransom_ids.includes(ransom_id) && (
                <RansomAddressesTabs ransom_id={ransom_id} />
            )}
            {(!tabName || tabName === 'main') && <RansomShowMode ransom_id={ransom_id} />}
            {tabName === 'video' && videoId && <VideoTabMode videoId={videoId} />}
            {tabName === 'preRunVideo' && videoPreRunId && <VideoTabMode videoId={videoPreRunId} />}
        </ItemDrawWrapper>
    );
};

export default observer(Ransom);
