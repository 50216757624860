import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import ransomStore, { RansomStatus } from 'stores/ransomStore';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';

import ErrorIcon from '@mui/icons-material/Error';
import MoneyIcon from '@mui/icons-material/Money';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

import Progress from 'ui/Progress';

import { RansomLinkState } from '../RansomLink';

const RansomTabLabel = observer(({ ransom_id, isActive }: { ransom_id: number; isActive: boolean }) => {
    useMemo(() => {
        ransomStore.fetchItem(ransom_id);
    }, [ransom_id]);

    const { item } = ransomStore.getItem(ransom_id);

    const [fetchInterval, setFetchInterval] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (isActive && fetchInterval) {
            clearInterval(fetchInterval);
        } else if (!isActive && item) {
            const { status } = item;

            if (status === 1 || status === 4) {
                const fetchInterval = setInterval(
                    () => {
                        ransomStore.fetchItem(ransom_id);
                    },
                    status === 1 ? 1000 : 5000
                );
                setFetchInterval(fetchInterval);
            } else if (fetchInterval) {
                clearInterval(fetchInterval);
                setFetchInterval(null);
            }
        }

        return () => {
            if (fetchInterval) {
                clearInterval(fetchInterval);
            }
        };
    }, [ransom_id, isActive, item && item.status]);

    if (!item) {
        return <Progress show size={32} />;
    }

    const { status } = item;

    let icon: ReactNode;
    let color: string = 'error.dark';

    switch (status) {
        case RansomStatus.Creating:
            icon = <QrCodeIcon />;
            color = 'info.dark';
            break;
        case RansomStatus.WaitingForPayment:
            icon = <MoneyIcon />;
            color = 'warning.dark';
            break;
        case RansomStatus.Delivery:
            icon = <DeliveryDiningIcon />;
            color = 'success.dark';
            break;
        case RansomStatus.CheckingPayment:
            icon = <MoneyIcon />;
            color = 'primary.dark';
            break;
        case RansomStatus.WaitingForExecution:
            icon = <ErrorIcon />;
            color = 'error.dark';
            break;
        case RansomStatus.Error:
            icon = <ErrorIcon />;
            color = 'error.dark';
            break;
        case RansomStatus.ErrorExpiredPayment:
            icon = <ErrorIcon />;
            color = 'error.dark';
            break;
        default:
            const error: never = status;
            throw new Error(error);
    }

    return (
        <Box sx={{ display: 'flex', color, ...(isActive ? {} : { opacity: 0.4 }) }}>
            <Box sx={{ position: 'relative', width: '32px' }}>
                {icon}
                {(status === 1 || status === 4) && <Progress show size={32} />}
            </Box>
            <div>{item.deliveryStorePoint.address}</div>
        </Box>
    );
});

const RansomAddressesTab = ({ ransom_id }: { ransom_id: number }) => {
    const { new_ransom_ids } = ransomStore;

    const handleOpenRansomInGroup = (ransom_id: number) => {
        // @ts-ignore
        locationHistory.replace({ ...RansomLinkState(ransom_id), fromTab: true });
    };

    return (
        <Box>
            <Typography variant="h6">Выкупы на разные адреса</Typography>
            <Tabs
                allowScrollButtonsMobile
                value={Number(ransom_id)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                sx={{ background: 'white' }}
            >
                {new_ransom_ids.map(new_ransom_id => (
                    <Tab
                        key={new_ransom_id}
                        value={new_ransom_id}
                        label={<RansomTabLabel ransom_id={new_ransom_id} isActive={new_ransom_id === ransom_id} />}
                        onClick={() => handleOpenRansomInGroup(new_ransom_id)}
                        wrapped
                    />
                ))}
            </Tabs>
        </Box>
    );
};

export default observer(RansomAddressesTab);
