import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import productStore, { Product } from 'stores/productStore';
import ransomStore, { DeliveryPointAddress, RansomStatus, RansomType } from 'stores/ransomStore';
import authStore from 'stores/authStore';

import Grid from 'ui/Grid';
import ListError from 'ui/ListError';
import LoaderAwait from 'ui/LoaderAwait';
import Progress from 'ui/Progress';
import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';
import { ErrorMessage, InfoMessage, SuccessMessage, WarningMessage } from 'ui/Message';
import Confirm from 'ui/Confirm';
import Time from 'ui/Time';

import Container from '@mui/material/Container';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptIcon from '@mui/icons-material/Receipt';

import ProductCard from '../Product/ProductCard';
import { DeliveryLinkState } from '../Delivery/DeliveryLink';

import DeliveryPointModal from './EditingBlocks/DeliveryPointModal';
import RansomGroupTabs from './RansomGroupTabs';
import RansomPaymentShow from './ShowBlocks/RansomPaymentShow';
import RansomInfoShow from './ShowBlocks/RansomInfoShow';
import RansomAccountShow from './ShowBlocks/RansomAccountShow';
import RansomSmartEditBtn from './EditingBlocks/RansomSmarts/RansomSmartEditBtn';
import StoreRequestError from '../StoreRequestError';
import StoreOpenAccountBtn from '../StoreOpenAccountBtn';
import RansomCreatingMessage from './ShowBlocks/RansomCreatingMessage';
import RansomAdditionalShow from './ShowBlocks/RansomAdditionalShow';
import { ReviewStatusEnum, ReviewType } from '../../../stores/reviewStore';
import Box from '@mui/material/Box';

const CancelRansomBtn = ({ ransom_id }: { ransom_id: number }) => {
    const [showConfirm, toggleConfirm] = useToggleModal();
    const handleCancelRansom = async () => {
        ransomStore.toggleDisableItem(ransom_id, false);
        toggleConfirm();
    };

    return (
        <>
            <Button color="inherit" onClick={toggleConfirm} fullWidth>
                Отменить выкуп
            </Button>
            {showConfirm && (
                <Confirm
                    content='Если вы не хотите делать данный выкуп, то можно его отменить. Или можно нажать "Попробовать еще раз". Потраченные средства за выкуп вернутся вам на счет TopStore'
                    header="Отменить выкуп"
                    confirmButton="Удалить данный выкуп"
                    onConfirm={handleCancelRansom}
                    onCancel={toggleConfirm}
                />
            )}
        </>
    );
};

const RansomShowMode = ({ ransom_id }: { ransom_id: number }) => {
    const { loadingItem, item: ransom, errors } = ransomStore.getItem(ransom_id);
    const {
        product_id,
        status,
        paymentUrl,
        paymentQR,
        errorMessage,
        enable,
        createTime,
        store_id,
        account_id,
        ransom_group_id,
        paymentType,
        payPhone,
        wbReceiptLink,
        type,
        preRunAccount,
        planningExecuteTime
    } = ransom || {
        enable: true
    };

    const [fetchInterval, setFetchInterval] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        return () => {
            if (fetchInterval) {
                clearInterval(fetchInterval);
            }
        };
    }, [fetchInterval]);

    useEffect(() => {
        if (product_id) {
            productStore.fetchItem(product_id);
        }
    }, [product_id]);

    useEffect(() => {
        if (
            status &&
            [RansomStatus.Creating, RansomStatus.CheckingPayment, RansomStatus.WaitingForExecution].includes(status)
        ) {
            const fetchInterval = setInterval(
                () => {
                    ransomStore.fetchItem(ransom_id);
                },
                status === RansomStatus.Creating ? 1000 : 5000
            );
            setFetchInterval(fetchInterval);
        } else if (fetchInterval) {
            clearInterval(fetchInterval);
            setFetchInterval(null);
        }
        ransomStore.findWbVideo(ransom_id);
        if (type && [RansomType.Group, RansomType.Smart].includes(type) && ransom_group_id) {
            ransomStore.fetchRansomGroup(ransom_group_id);
        }
    }, [status, ransom_id]);

    let product: Product | null = null;
    // let loadingProduct: boolean = false;
    if (product_id) {
        try {
            const productItem = productStore.getItem(product_id);
            product = productItem?.item || null;
            // loadingProduct = Boolean(productItem?.loadingItem);
        } catch (err) {}
    }

    const handleUpdateRansomQR = async () => {
        ransomStore.setEditingItem(ransom_id, { status: RansomStatus.Creating });
        await ransomStore.saveItem(ransom_id);
        ransomStore.fetchItem(ransom_id);
        ransomStore.findWbVideo(ransom_id);
    };

    const [showAddressModal, toggleAddressModal] = useToggleModal();

    const handleChangeStoreId = async ({ store_point_id }: DeliveryPointAddress) => {
        ransomStore.setEditingItem(ransom_id, { status: RansomStatus.Creating, store_point_id });
        await ransomStore.saveItem(ransom_id);
        ransomStore.fetchItem(ransom_id);
        ransomStore.findWbVideo(ransom_id);
    };

    return (
        <>
            {Number(ransom_group_id) > 0 && (
                <RansomGroupTabs
                    currentRansomId={ransom_id}
                    ransom_group_id={Number(ransom_group_id)}
                    label={type === RansomType.Group ? 'Выкупы в группе' : 'Smart выкупы'}
                />
            )}

            <Container maxWidth="sm">
                <ListError errors={errors} />
                <LoaderAwait active={loadingItem} />

                <Grid>
                    {!enable && (
                        <Grid.Column>
                            <ErrorMessage header="Выкуп отменен">Оплата за выкуп возвращена на ваш счет</ErrorMessage>
                        </Grid.Column>
                    )}

                    <Grid.Column>
                        <Typography>
                            {authStore.isAdmin() && account_id && store_id && (
                                <StoreOpenAccountBtn
                                    account_id={account_id}
                                    store_id={store_id}
                                    user_id={ransom?.user_id}
                                />
                            )}
                            Выкуп создан <Time time={createTime} />
                        </Typography>
                        <ProductCard product={product} />
                        <RansomAdditionalShow ransom={ransom || null} />
                    </Grid.Column>
                    <Grid.Column>
                        <RansomInfoShow ransom={ransom || null} />
                        <RansomAccountShow ransom={ransom || null} />
                    </Grid.Column>

                    {(paymentQR || payPhone || paymentUrl) && status === RansomStatus.WaitingForPayment && (
                        <RansomPaymentShow ransom_id={ransom_id} />
                    )}
                    {status === RansomStatus.Creating && paymentType && (
                        <RansomCreatingMessage paymentType={paymentType} preRunAccount={Boolean(preRunAccount)} />
                    )}
                    {status === RansomStatus.Delivery && (
                        <Grid.Column>
                            <SuccessMessage header="Выкуп оформлен">
                                Тут вы можете узнать статус доставки
                                <Button
                                    color="secondary"
                                    onClick={() => locationHistory.push(DeliveryLinkState(ransom_id))}
                                >
                                    Открыть
                                </Button>
                            </SuccessMessage>
                            {wbReceiptLink && (
                                <Button
                                    color="inherit"
                                    href={wbReceiptLink}
                                    target="_blank"
                                    fullWidth
                                    sx={{ mt: 3, mb: 2 }}
                                    tooltip="Открыть Электронный чек на Wildberries"
                                    startIcon={<ReceiptIcon />}
                                >
                                    Электронный чек WB
                                </Button>
                            )}
                        </Grid.Column>
                    )}
                    {status === RansomStatus.CheckingPayment && (
                        <Grid.Column>
                            <WarningMessage header="Проверка оплаты">
                                Когда оплаченный товар появится в личном кабинете Wildberries (может занимать до 60
                                минут), вы сможете увидеть информацию о Доставке в разделе "Доставки". Карточку данного
                                "Выкупа" можно закрыть. Мы сообщим, когда "Доставка" будет найдена.
                            </WarningMessage>
                            <Progress show linear />
                            {/*<CancelRansomBtn ransom_id={ransom_id} />*/}
                        </Grid.Column>
                    )}
                    {status === RansomStatus.Error && (
                        <Grid.Column>
                            {authStore?.currentUser?.disableWb && (
                                <Grid.Column>
                                    <ErrorMessage header="Проблема в Wildberries">
                                        Сайт и приложение Wildberries временно не работают. Создать Выкуп временно
                                        невозможно.
                                    </ErrorMessage>
                                </Grid.Column>
                            )}

                            <StoreRequestError error={errorMessage} store_id={product?.store_id}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: String(errorMessage)
                                    }}
                                />
                            </StoreRequestError>
                            {errorMessage === 'Невозможно доставить в выбранный ПВЗ' && (
                                <>
                                    <Button
                                        endIcon={<HomeIcon />}
                                        primary
                                        variant="contained"
                                        fullWidth
                                        onClick={toggleAddressModal}
                                        disabled={loadingItem}
                                        sx={{ my: 2 }}
                                    >
                                        Выбрать другой ПВЗ
                                    </Button>
                                    {showAddressModal && (
                                        <DeliveryPointModal
                                            onClose={toggleAddressModal}
                                            onChoose={handleChangeStoreId}
                                        />
                                    )}
                                </>
                            )}
                            {enable && (
                                <Stack direction="row">
                                    <CancelRansomBtn ransom_id={ransom_id} />
                                    {!authStore?.currentUser?.disableWb && (
                                        <Button
                                            endIcon={<QrCodeIcon />}
                                            color="error"
                                            fullWidth
                                            onClick={handleUpdateRansomQR}
                                            disabled={loadingItem}
                                        >
                                            Попробовать еще раз
                                        </Button>
                                    )}
                                </Stack>
                            )}
                        </Grid.Column>
                    )}
                    {status === RansomStatus.WaitingForExecution && !planningExecuteTime && (
                        <Grid.Column>
                            <InfoMessage header="Ожидает создания">
                                <Box sx={{ mt: 1 }}>В очереди на создание, скоро начнется генерация выкупа</Box>
                            </InfoMessage>
                        </Grid.Column>
                    )}
                    {planningExecuteTime && status === RansomStatus.WaitingForExecution && (
                        <>
                            <Grid.Column>{ransom && <RansomSmartEditBtn ransom={ransom} />}</Grid.Column>
                            <Grid.Column>
                                <CancelRansomBtn ransom_id={ransom_id} />
                            </Grid.Column>
                        </>
                    )}
                    {status === RansomStatus.ErrorExpiredPayment && (
                        <Grid.Column>
                            <ErrorMessage header="Просрочена оплата Картой">
                                Ссылка на оплату просрочена. Обновить ссылку на оплату невозможно, т.к. товар отправлен
                                на ПВЗ неоплаченным.
                            </ErrorMessage>
                        </Grid.Column>
                    )}
                </Grid>
            </Container>
        </>
    );
};

export default observer(RansomShowMode);
