const registeredDebounce = new WeakMap();

type Procedure = (...args: any[]) => void;

function debounce(func: (...arg: any[]) => any, wait: number, immediate = false, register = false) {
    let timeout: number | null;
    const debFunc: Procedure = function (this: any, ...args: any[]) {
        // eslint-disable-next-line
        const context = this;
        const later = function () {
            timeout = null;
            if (!immediate) {
                // @ts-ignore
                func.apply(context, args);
            }
        };
        const callNow = immediate && !timeout;
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = window.setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }

        if (register) {
            registeredDebounce.set(debFunc, timeout);
        }
    };
    return debFunc;
}

export const clearDebounce = (func: () => void) => {
    clearTimeout(registeredDebounce.get(func));
    registeredDebounce.delete(func);
};

export default debounce;
