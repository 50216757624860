import React from 'react';
import { observer } from 'mobx-react';

import authStore from 'stores/authStore';

import { WarningMessage } from 'ui/Message';

const ServiceMessage = () => {
    return authStore.settings?.systemMessage ? (
        <WarningMessage>{authStore.settings?.systemMessage}</WarningMessage>
    ) : null;
};

export default observer(ServiceMessage);
