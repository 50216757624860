import React, { useState, MouseEvent } from 'react';
import { observer } from 'mobx-react';

import locationHistory from 'locationHistory';
import ransomStore from 'stores/ransomStore';
import productStore from 'stores/productStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import Button from 'ui/Button';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { RansomLinkState } from './RansomLink';

const RansomItemMenu = ({ ransom_id }: { ransom_id: number }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [loadingRansom, setLoadingRansom] = useState(false);

    const handleOpenDropdown = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const onClose = () => {
        setAnchorEl(null);
    };

    const handleCopyRansom = async (): Promise<void> => {
        try {
            ransomStore.getItem(ransom_id);
        } catch (error) {
            setLoadingRansom(true);
            await ransomStore.fetchItem(ransom_id).then(ransom => {
                setLoadingRansom(false);
                if (ransom?.product_id) {
                    productStore.fetchItem(ransom.product_id);
                }
            });
        }
        setTimeout(() => {
            ransomStore.copyRansomToEmpty(ransom_id);
        }, 0);
        locationHistory.push(RansomLinkState(CREATING_ITEM_ID));
    };

    return (
        <>
            <Button icon={<MoreVertIcon fontSize="large" />} loading={loadingRansom} onClick={handleOpenDropdown} />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} keepMounted onClose={onClose} onClick={onClose}>
                <MenuItem onClick={handleCopyRansom} disabled={loadingRansom}>
                    <ListItemIcon>
                        <ContentCopyIcon fontSize="small" />
                    </ListItemIcon>
                    Повторить (создать черновик)
                </MenuItem>
            </Menu>
        </>
    );
};

export default observer(RansomItemMenu);
