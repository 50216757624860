import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import ransomStore, { RansomSmartItem } from 'stores/ransomStore';

import List from '@mui/material/List';
import AddIcon from '@mui/icons-material/Add';

import RansomSmartsItem from './RansomSmartsItem';
import RansomSmartsPult, { ransomSpeedOptions } from './RansomSmartsPult';

import Button from 'ui/Button';
import Divider from 'ui/Divider';

const RansomSmartsItems = ({ ransom_id }: { ransom_id: number }) => {
    const firstTime = Math.floor(Date.now() / 1000) + 300;

    const { editingItem } = ransomStore.getItem(ransom_id);
    const { ransomSmartItems = [], wb_size_id = null, product_id } = editingItem;

    const [speed, setSpeed] = useState(ransomSpeedOptions[1].value);

    const matchTimes = (speed: number) => {
        const ransomSmartItemsNew: RansomSmartItem[] = [...ransomSmartItems];
        const quantity = ransomSmartItems.length;

        if (quantity > 0) {
            ransomSmartItemsNew[0].time = firstTime;

            for (let index = 1; index < quantity; index++) {
                const time = firstTime + index * Math.floor(speed / (quantity - 1));
                ransomSmartItemsNew[index].time = time;
            }
        }

        ransomStore.setEditingItem(ransom_id, { ransomSmartItems: ransomSmartItemsNew });
    };

    const changeQuantity = (quantity: number) => {
        let ransomSmartItemsNew = [...ransomSmartItems];

        if (quantity > ransomSmartItems.length) {
            const editingRansom = ransomStore.getItem(ransom_id).editingItem;
            const propertyRansom = ransomStore.getItem(ransom_id).property || {};

            for (let index = ransomSmartItemsNew.length; index < quantity; index++) {
                let deliveryPointsAddress = null;
                if (propertyRansom?.deliveryPointsAddresses) {
                    deliveryPointsAddress =
                        propertyRansom.deliveryPointsAddresses[propertyRansom.deliveryPointsAddresses.length - 1];
                }

                ransomSmartItemsNew.push({
                    time: 0,
                    wb_size_id,
                    wbSizeName: propertyRansom?.wbSize?.sizeName || null,
                    address: deliveryPointsAddress?.address || null,
                    store_point_id: deliveryPointsAddress?.store_point_id || null,
                    searchRequest: editingRansom?.searchRequest || null,
                    wbFilters: editingRansom?.wbFilters || null,
                    wbFilterOrderBy: editingRansom?.wbFilterOrderBy || null,
                    gender: editingRansom?.gender || 'female'
                });
            }
        } else {
            ransomSmartItemsNew = ransomSmartItemsNew.slice(0, quantity);
        }

        ransomStore.setEditingItem(ransom_id, { ransomSmartItems: ransomSmartItemsNew });
    };

    const addSmartItem = () => {
        changeQuantity(ransomSmartItems.length + 1);
    };

    useEffect(() => {
        if (!ransomSmartItems.length) {
            changeQuantity(1);
        }
    }, []);

    useEffect(() => {
        if (ransomSmartItems.length) {
            matchTimes(speed);
        }
    }, [ransomSmartItems.length, speed]);

    if (!product_id) {
        return null;
    }

    return (
        <>
            <RansomSmartsPult
                quantity={ransomSmartItems.length}
                speed={speed}
                setQuantity={changeQuantity}
                setSpeed={setSpeed}
            />
            <Divider />
            <List dense>
                {ransomSmartItems.map((ransomSmartItem, index) => (
                    <RansomSmartsItem ransom_id={ransom_id} key={index} index={index} product_id={product_id} />
                ))}
            </List>
            <Button fullWidth color="secondary" startIcon={<AddIcon />} onClick={addSmartItem}>
                добавить smart выкуп
            </Button>
        </>
    );
};

export default observer(RansomSmartsItems);
