import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import ransomStore, { RansomType } from 'stores/ransomStore';
import authStore from 'stores/authStore';

import Container from '@mui/material/Container';

import { WarningMessage } from 'ui/Message';

import RansomSmartsItemsContainer from './EditingBlocks/RansomSmartsItemsContainer';
import RansomSelectProductEditing from './EditingBlocks/SelectProducts/RansomSelectProductEditing';

import RansomCreateButtons from './EditingBlocks/RansomCreateButtons';
import Stack from '@mui/material/Stack';
import RansomPaymentType from './EditingBlocks/RansomPaymentType';
import RansomRivalItemsBlock from './EditingBlocks/RansomOtherItems/RansomRivalItemsBlock';
import Divider from '@mui/material/Divider';

const RansomCreateModeSmart = ({ ransom_id }: { ransom_id: number }) => {
    const {
        editingItem: { product_id }
    } = ransomStore.getItem(ransom_id);

    useEffect(() => {
        if (product_id) {
            ransomStore.setEditingItem(ransom_id, { type: RansomType.Smart });
        }
    }, [ransom_id, product_id]);

    return (
        <Container maxWidth="lg" sx={{ mb: 2 }}>
            <Stack spacing={2}>
                <RansomSelectProductEditing ransom_id={ransom_id} />
                {!product_id && (
                    <WarningMessage header="Товар не выбран">
                        Выберите товар, чтобы здесь появились smart выкупы
                    </WarningMessage>
                )}
                {product_id && (
                    <>
                        <RansomSmartsItemsContainer ransom_id={ransom_id} />
                        <Divider />
                        <RansomRivalItemsBlock ransom_id={ransom_id} />
                        <RansomPaymentType ransom_id={ransom_id} />
                        <Divider />
                        {!authStore?.currentUser?.disableWb && <RansomCreateButtons />}
                    </>
                )}
            </Stack>
        </Container>
    );
};

export default observer(RansomCreateModeSmart);
