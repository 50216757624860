import React from 'react';

import { ClosestPointAccount } from 'api/deliveryPointsApi';
import { DeliveryPointAddress } from 'stores/ransomStore';

import { useToggleModal } from 'ui/Modal';
import Time from 'ui/Time';
import Confirm from 'ui/Confirm';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';

const SetAccountConfirm = ({
    onClose,
    closestPointAccount,
    onChangeStorePoint
}: {
    onClose: () => void;
    closestPointAccount: ClosestPointAccount;
    onChangeStorePoint: (storePoint: DeliveryPointAddress) => void;
}) => {
    const { storeDeliveryPoint, account_id, gender, distance, ransomCreateTime } = closestPointAccount;
    const { address } = storeDeliveryPoint;

    const handleConfirm = () => {
        const { store_point_id, address } = storeDeliveryPoint;
        onChangeStorePoint({ store_point_id, address });
        onClose();
    };

    const content = (
        <List dense>
            <ListItem>
                <ListItemText primary={<b>{address}</b>} secondary="Адрес ПВЗ" />
            </ListItem>
            <ListItem>
                <ListItemText
                    primary={<b>{gender === 'female' ? 'Женский' : 'Мужской'}</b>}
                    secondary="Пол покупателя"
                />
            </ListItem>
            <ListItem>
                <ListItemText
                    primary={
                        <b>
                            <Time time={ransomCreateTime} />
                        </b>
                    }
                    secondary="Дата последней покупки на данном ПВЗ"
                />
            </ListItem>
        </List>
    );

    return (
        <Confirm
            content={content}
            header="Выбрать данный аккаунт и адрес ПВЗ"
            confirmButton="Выбрать"
            onConfirm={handleConfirm}
            onCancel={onClose}
        />
    );
};

const RansomChooseAccountBtn = ({
    closestPointAccount,
    current_store_point_id,
    onChangeStorePoint
}: {
    closestPointAccount: ClosestPointAccount;
    current_store_point_id: number;
    onChangeStorePoint: (storeDeliveryPoint: DeliveryPointAddress) => void;
}) => {
    const { storeDeliveryPoint, gender, distance, ransomCreateTime } = closestPointAccount;
    const { address, store_point_id } = storeDeliveryPoint;

    const [showConfirm, toggleConfirm] = useToggleModal();

    return (
        <>
            <ListItemButton
                sx={{ bgcolor: current_store_point_id === store_point_id ? '#baeeb1' : undefined }}
                onClick={toggleConfirm}
            >
                <ListItemAvatar>
                    {gender === 'male' && <ManIcon />}
                    {gender === 'female' && <WomanIcon />}
                </ListItemAvatar>
                <ListItemText
                    primary={address}
                    secondary={
                        // <Time time={ransomCreateTime} />
                        current_store_point_id === store_point_id
                            ? 'этот же ПВЗ'
                            : `${(distance / 1000).toFixed(1)} км от выбранного ПВЗ`
                    }
                />
            </ListItemButton>
            {showConfirm && (
                <SetAccountConfirm
                    onClose={toggleConfirm}
                    closestPointAccount={closestPointAccount}
                    onChangeStorePoint={onChangeStorePoint}
                />
            )}
        </>
    );
};

export default RansomChooseAccountBtn;
