import React, { ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react';

import { ListStoreInterface } from 'stores/prototypes/ListStore.prototype';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import ListError from 'ui/ListError';
import ListContainer from './ListContainer';

export const ListPaper = observer(({ store, children }: { store: ListStoreInterface | null; children: ReactNode }) => {
    useEffect(() => {
        if (store) {
            store.fetchList();
        }
    }, [store]);

    const { listErrors } = store || { listErrors: [] };

    return (
        <Paper id="listWrapper" elevation={1} sx={{ py: { sx: 1, sm: 2 }, px: { sx: 1, sm: 2, md: 2, lg: 3, xl: 4 } }}>
            <ListError errors={listErrors} />
            {children}
        </Paper>
    );
});

const ListWrapper = ({
    store,
    bgUrl,
    children,
    createBtn
}: {
    store: ListStoreInterface | null;
    bgUrl: string;
    children: ReactNode;
    createBtn?: ReactNode;
}) => {
    return (
        <ListContainer bgUrl={bgUrl}>
            {createBtn && <Box sx={{ mb: 2, px: 2 }}>{createBtn}</Box>}
            <ListPaper store={store}>{children}</ListPaper>
        </ListContainer>
    );
};

export default ListWrapper;
