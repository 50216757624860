import * as wbStatisticsApi from 'api/wbApi/wbStatisticsApi';
import ListStorePrototype from '../prototypes/ListStore.prototype';
import { WbItem } from '../productStore';

export type WbApiSaleType = {
    odid: number;
    gNumber: string;
    incomeID: number;
    saleID: string;
    IsStorno: number;
    nmId: number;

    subject: string;
    category: string;
    brand: string;
    supplierArticle: string;
    techSize: string;
    barcode: string;

    warehouseName: string;
    countryName: string;
    oblastOkrugName: string;
    regionName: string;

    totalPrice: number;
    discountPercent: number;
    isSupply: boolean;
    isRealization: boolean;
    promoCodeDiscount: number;

    spp: number;
    forPay: number;
    finishedPrice: number;
    priceWithDisc: number;

    sticker: string;

    date: number;
    lastChangeDate: number;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

type WbApiOrderType = {
    wb_sale_id: number;
    user_wb_token_id: number;

    odid: number;
    gNumber: string;
    incomeID: number;
    nmId: number;

    subject: string;
    category: string;
    brand: string;
    supplierArticle: string;
    techSize: string;
    barcode: string;

    warehouseName: string;
    oblast: string;

    totalPrice: number;
    discountPercent: number;
    isCancel: boolean;

    sticker: string;

    date: number;
    lastChangeDate: number;
    cancel_dt: number | null;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

type WbApiRealizationType = {
    wb_realization_id: number;
    user_wb_token_id: number;

    rrd_id: number;
    rid: number;
    realizationreport_id: number;
    suppliercontract_code: number | null;
    gi_id: number;
    subject_name: string;
    nm_id: number;
    brand_name: string;
    sa_name: string;
    ts_name: string;
    barcode: string;
    doc_type_name: string;
    quantity: number;
    retail_price: number;
    retail_amount: number;
    sale_percent: number;
    commission_percent: number;
    office_name: string | null;
    supplier_oper_name: string | null;

    shk_id: number;
    retail_price_withdisc_rub: number;
    delivery_amount: number;
    return_amount: number;
    delivery_rub: number;
    gi_box_type_name: string;
    product_discount_for_report: number;
    supplier_promo: number;

    ppvz_spp_prc: number | null;
    ppvz_kvw_prc_base: number | null;
    ppvz_kvw_prc: number | null;
    ppvz_sales_commission: number | null;
    ppvz_for_pay: number | null;
    ppvz_reward: number | null;
    ppvz_vw: number | null;
    ppvz_vw_nds: number | null;
    ppvz_office_id: number | null;
    ppvz_office_name: string | null;
    ppvz_supplier_id: number | null;
    ppvz_supplier_name: string | null;
    ppvz_inn: string | null;

    bonus_type_name: string | null;
    declaration_number: string | null;
    sticker_id: string;
    site_country: string;

    order_dt: number;
    sale_dt: number;
    rr_dt: number;

    penalty: number | null;
    additional_payment: number | null;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

type WbStatisticsApiPrepare = {
    odid: number;

    wb_item_id: number;
    wbItem: WbItem;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type WbStatisticsApiType = WbStatisticsApiPrepare & {
    sale: WbApiSaleType;
    order: WbApiOrderType;
    realizations: WbApiRealizationType[];
};

class WbStatisticsApiStore extends ListStorePrototype<WbStatisticsApiType, WbStatisticsApiType> {
    listFilterClear = {};

    constructor() {
        super('odid', 'user', wbStatisticsApi);
        this.orderBy = 'lastChangeDate';
    }
}

export default new WbStatisticsApiStore();
