import React from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import deliveryStore from 'stores/deliveryStore';
import commonStore from 'stores/commonStore';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { DeliveryLinkState } from './DeliveryLink';
import LoaderAwait from 'ui/LoaderAwait';
import DeliveryStatus from '../../List/Deliveries/DeliveryStatus';

const DeliveryGroupTabs = ({
    ransom_group_id,
    currentDeliveryId,
    label
}: {
    ransom_group_id: number;
    currentDeliveryId: number;
    label: string;
}) => {
    const handleOpenDeliveryInGroup = (delivery_id: number) => {
        // @ts-ignore
        locationHistory.replace({ ...DeliveryLinkState(delivery_id), fromTab: true });
    };

    const { deliveries, loading } = deliveryStore.getDeliveryGroup(ransom_group_id);

    return (
        <Box sx={{ height: '5em' }}>
            <Typography variant="h6">{label}</Typography>
            <LoaderAwait linear active={loading} />
            <Tabs
                allowScrollButtonsMobile
                value={currentDeliveryId}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
            >
                {deliveries.map(({ delivery_id, status, enable }) => (
                    <Tab
                        value={delivery_id}
                        key={delivery_id}
                        label={<DeliveryStatus status={status} enable={enable} />}
                        onClick={() => handleOpenDeliveryInGroup(delivery_id)}
                        wrapped={commonStore.isMobile}
                        sx={{ px: 1, ...(delivery_id === currentDeliveryId ? {} : { opacity: 0.4 }) }}
                    />
                ))}
            </Tabs>
        </Box>
    );
};

export default observer(DeliveryGroupTabs);
