import React, { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import wait from 'common/wait';

import wbAccountStore from 'stores/wbAccountStore';
import wbProxyStore from 'stores/wbProxyStore';
import reviewStore from 'stores/reviewStore';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import ListError from 'ui/ListError';
import Grid from 'ui/Grid';
import Button from 'ui/Button';
import Time from 'ui/Time';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import ReviewShowMode from '../Items/Review/ReviewShowMode';
import ReviewStatus from '../List/Reviews/ReviewStatus';
import productStore from '../../stores/productStore';

const ReviewMode = ({ review_id, onClose }: { review_id: number; onClose: () => void }) => {
    useMemo(() => {
        reviewStore.fetchItem(review_id);
    }, [review_id]);

    const setComment = () => {
        const { reviewText } = reviewStore.getItem(review_id).item || {};

        window.postMessage({
            operation: 'setReviewText',
            text: reviewText
        });
    };

    return (
        <>
            <Button variant="text" color="inherit" startIcon={<ArrowBackIcon />} onClick={onClose}>
                назад
            </Button>
            <Button variant="text" color="secondary" onClick={setComment} tooltip="Отправить текст коммента в форму ВБ">
                Отправить текст
            </Button>
            <ReviewShowMode review_id={review_id} />
        </>
    );
};

const ReviewsList = ({ onClose }: { onClose: () => void }) => {
    useEffect(() => {
        wbAccountStore.fetchList();
        reviewStore.pagination.pageSize = 100;

        reviewStore.listFilter.allUsers = true;
        reviewStore.listFilter.moderation = true;
        reviewStore.listFilter.statuses = [3, 100];
        reviewStore.fetchList();
    }, []);

    const { list, listErrors } = reviewStore;

    const [selectedId, setReviewId] = useState<number | null>(null);

    const handleOpenReview = async (review_id: number, account_id: number, wb_item_id: number) => {
        wbProxyStore.selectNextProxy();
        await wait(500);
        const { WILDAUTHNEW_V3 } = wbAccountStore.findById(account_id);
        window.postMessage({
            operation: 'openWbAccount',
            url: `https://www.wildberries.ru/catalog/${wb_item_id}/detail.aspx?post=1`,
            WILDAUTHNEW_V3
        });
        setReviewId(review_id);
    };

    const handleCloseReview = () => {
        reviewStore.fetchList();
        setReviewId(null);
    };

    if (selectedId) {
        return <ReviewMode review_id={selectedId} onClose={handleCloseReview} />;
    }

    return (
        <Grid>
            <Grid.Column>
                <Button variant="text" color="inherit" startIcon={<ArrowBackIcon />} onClick={onClose}>
                    назад
                </Button>
            </Grid.Column>
            <Grid.Column>
                <ListError errors={listErrors} />
            </Grid.Column>
            <Grid.Column>
                <List>
                    {list.map(
                        ({
                            account_id,
                            reviewPhotos,
                            product,
                            rating,
                            review_id,
                            createTime,
                            product_id,
                            user_id,
                            status,
                            enable,
                            planningExecuteTime
                        }) => {
                            const { store_item_id } = product;
                            const { photos } = productStore.calcShortProduct(product);

                            return (
                                <ListItem key={review_id} disablePadding>
                                    <ListItemButton
                                        onClick={() => handleOpenReview(review_id, account_id, store_item_id)}
                                    >
                                        <ListItemText
                                            primary={
                                                <Box sx={{ display: 'flex', gap: 1 }}>
                                                    <Avatar src={photos[0]} variant="rounded" />
                                                    <ReviewStatus
                                                        status={status}
                                                        planningExecuteTime={planningExecuteTime}
                                                        enable={enable}
                                                    />
                                                    <Stack>
                                                        <span>#user_id_{user_id}</span>
                                                        <span>product_id: #{product_id}</span>
                                                    </Stack>
                                                </Box>
                                            }
                                            secondary={
                                                <Box>
                                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                                        <Time time={createTime} />
                                                        <Box>фоток: {reviewPhotos.length}</Box>
                                                    </Box>
                                                    <Box>account_id: #{account_id}</Box>
                                                </Box>
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            );
                        }
                    )}
                </List>
            </Grid.Column>
        </Grid>
    );
};

export default observer(ReviewsList);
