import React from 'react';

import commonStore from 'stores/commonStore';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';
import Grid from 'ui/Grid';
import { SuccessMessage } from 'ui/Message';

import KeysRansomsModal from './YandexDirectModal';
import Table, { TableRow, TableHead, TableCell, TableBody } from 'ui/Table';
import ListContainer from '../ListContainer';

const YandexDirect = () => {
    const [showModal, toggleModal] = useToggleModal();

    return (
        <ListContainer bgUrl="/static/listbg/unruly-05-done.webp">
            <Container maxWidth="md">
                <Paper elevation={1} sx={{ padding: '1rem 2rem' }}>
                    <Typography variant="h5">
                        Настройка контекстной рекламы в <Box sx={{ color: '#fc3f1d', display: 'inline' }}>Яндекс</Box>
                    </Typography>
                    <Typography variant="body1" sx={{ my: 2 }}>
                        Яндекс Директ — рекламная система, предназначенная для продвижения товаров в интернете, а
                        конкретнее — в поисковой системе Яндекса и его партнерской сети, состоящей из сайтов и
                        приложений. Это вид рекламы, в котором рекламные материалы показываются исходя из запроса
                        пользователя. Реклама в поисковиках и сетях нужна, чтобы найти новых клиентов и увеличить
                        продажи ваших товаров.
                    </Typography>

                    {/*<Grid columns={3} stackable>*/}
                    {/*    <Grid.Column>*/}
                    {/*        <Typography variant="subtitle2" sx={{ my: 2 }}>*/}
                    {/*            Ваша цена настройки 2900 / ведение 10 % от пополнения бюджета*/}
                    {/*        </Typography>*/}
                    {/*        <ul style={{ paddingLeft: '1rem' }}>*/}
                    {/*            <li>Настройка рекламной компании на ПОИСК + РСЯ 1 товар</li>*/}
                    {/*            <li>3 объявления на ПОИСК + 3 объявления на РСЯ</li>*/}
                    {/*            <li>30 ключевых слов</li>*/}
                    {/*            <li>Срок выполнения: 3-5 дней</li>*/}
                    {/*        </ul>*/}
                    {/*    </Grid.Column>*/}

                    {/*    <Grid.Column>*/}
                    {/*        <Typography variant="subtitle2" sx={{ my: 2 }}>*/}
                    {/*            Ваша цена настройки 5900 / ведение 10% от пополнения бюджета*/}
                    {/*        </Typography>*/}
                    {/*        <ul style={{ paddingLeft: '1rem' }}>*/}
                    {/*            <li>Настройка рекламной компании на ПОИСК + РСЯ до 3 товаров</li>*/}
                    {/*            <li>9 объявления на ПОИСК + 9 объявления на РСЯ</li>*/}
                    {/*            <li>90 ключевых слов</li>*/}
                    {/*            <li>Срок выполнения: 3-5 дней</li>*/}
                    {/*        </ul>*/}
                    {/*    </Grid.Column>*/}

                    {/*    <Grid.Column>*/}
                    {/*        <Typography variant="subtitle2" sx={{ my: 2 }}>*/}
                    {/*            Ваша цена настройки 12900 / ведение 10% от пополнения бюджета*/}
                    {/*        </Typography>*/}
                    {/*        <ul style={{ paddingLeft: '1rem' }}>*/}
                    {/*            <li>Настройка рекламной компании на ПОИСК + РСЯ до 10 товаров</li>*/}
                    {/*            <li>30 объявления на ПОИСК + 30 объявления на РСЯ</li>*/}
                    {/*            <li>300 ключевых слов</li>*/}
                    {/*            <li>Срок выполнения: 3-5 дней</li>*/}
                    {/*        </ul>*/}
                    {/*    </Grid.Column>*/}
                    {/*</Grid>*/}

                    <Table size="medium">
                        <TableHead>
                            <TableRow>
                                <TableCell>Цена настройки</TableCell>
                                <TableCell>{(2900).toLocaleString()}₽</TableCell>
                                <TableCell>{(5900).toLocaleString()}₽</TableCell>
                                <TableCell>{(12900).toLocaleString()}₽</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Стоимость сопровождения</TableCell>
                                <TableCell>10% от пополнения бюджета</TableCell>
                                <TableCell>10% от пополнения бюджета</TableCell>
                                <TableCell>10% от пополнения бюджета</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Настройка рекламной компании на ПОИСК</TableCell>
                                <TableCell>+ РСЯ 1 товар</TableCell>
                                <TableCell>+ РСЯ до 3 товаров</TableCell>
                                <TableCell>+ РСЯ до 10 товаров</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Объявления на ПОИСК</TableCell>
                                <TableCell>3 объявления</TableCell>
                                <TableCell>9 объявлений</TableCell>
                                <TableCell>30 объявлений</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Ключевые слова</TableCell>
                                <TableCell>30 слов</TableCell>
                                <TableCell>90 слов</TableCell>
                                <TableCell>300 слов</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Срок выполнения</TableCell>
                                <TableCell>3-5 дней</TableCell>
                                <TableCell>3-5 дней</TableCell>
                                <TableCell>3-5 дней</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Grid alignItems="flex-start" stackable disableGutter>
                        <Grid.Column>
                            <Typography variant="h6" sx={{ my: 2 }}>
                                Этапы настройки:
                            </Typography>

                            <ul>
                                <li>
                                    Создание и настройка параметров кампаний (регион показа, временной интервал,
                                    настройка стратегии, выставление дневного бюджета, добавление счётчика метрики для
                                    статистики)
                                </li>
                                <li>
                                    Сбор семантического ядра по товару. Запросы собираются вручную + проводится анализ
                                    поисковых ядер ТОП-50 конкурентов
                                </li>
                                <li>
                                    Ручной отбор ключевых слов. Исключаем мусорные слова, добавляем минус слова в
                                    компанию
                                </li>
                                <li>Написание уникальных, релевантных объявлений вручную</li>
                                <li>Снижение цены клика и оптимизация кампании</li>
                            </ul>
                        </Grid.Column>
                    </Grid>

                    {/*<SuccessMessage header="Стоимость">*/}
                    {/*    <Typography>*/}
                    {/*        Стоимость услуги&nbsp;*/}
                    {/*        <Typography*/}
                    {/*            sx={{ textDecoration: 'line-through', display: 'inline', color: 'red' }}*/}
                    {/*            variant="subtitle1"*/}
                    {/*        >*/}
                    {/*            25 000*/}
                    {/*        </Typography>*/}
                    {/*        &nbsp; 21 600₽*/}
                    {/*    </Typography>*/}
                    {/*    <Typography>*/}
                    {/*        На счет Яндекс.Директ вы получите <b>21 600₽</b>*/}
                    {/*    </Typography>*/}
                    {/*    <Typography variant="body2">Последующее сопровождение 15% от бюджета</Typography>*/}
                    {/*</SuccessMessage>*/}

                    <Typography variant="body2" sx={{ mt: 2 }}>
                        Заявки обрабатываются в будни с 09 до 18 МСК. Время исполнения 3-5 дней
                    </Typography>
                </Paper>
                <Box sx={{ display: 'flex', p: 2 }}>
                    <Button color="secondary" variant="contained" sx={{ margin: 'auto' }} onClick={toggleModal}>
                        Заказать Яндекс.Директ
                    </Button>
                    {showModal && <KeysRansomsModal onClose={toggleModal} />}
                </Box>
            </Container>
        </ListContainer>
    );
};

export default YandexDirect;
