import React, { ReactNode } from 'react';

import commonStore from 'stores/commonStore';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import Button from 'ui/Button';
import locationHistory from 'locationHistory';

export type MenuTab = {
    id: string;
    label: string | number;
    // icon?: React.ReactElement;
    pathname: string;
    disabled?: boolean;
};

const ItemDrawWrapper = ({
    children,
    tabs,
    endTab,
    activeTab
}: {
    children: ReactNode;
    tabs: MenuTab[];
    activeTab: string;
    endTab?: ReactNode;
}) => {
    const onClose = () => {
        locationHistory.goBack();
    };

    const openTab = (pathname: string) => {
        locationHistory.replace({ pathname, state: { modal: true } });
    };

    return (
        <Box sx={{ height: '100vh' }}>
            <Box
                sx={{
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: commonStore.isMobile ? 1 : 2,
                    px: commonStore.isMobile ? 0 : 2,
                    pt: 1,
                    width: '100%',
                    overflow: 'hidden'
                }}
            >
                <Button onClick={onClose} icon={<HighlightOffIcon fontSize="large" />} tooltip="Закрыть карточку" />
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        flex: 1,
                        maxWidth: endTab ? 'calc(100vw - 120px)' : undefined
                    }}
                >
                    <Tabs
                        allowScrollButtonsMobile
                        value={activeTab}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="scrollable"
                    >
                        {tabs.map(({ label, id, pathname, disabled }) => (
                            <Tab
                                value={id}
                                key={label}
                                label={label}
                                onClick={() => openTab(pathname)}
                                disabled={disabled}
                                wrapped={commonStore.isMobile}
                                sx={{ px: commonStore.isMobile ? 1 : 3 }}
                            />
                        ))}
                    </Tabs>
                </Box>
                {endTab}
            </Box>
            <Box sx={{ p: 2 }}>{children}</Box>
            {/*<PerfectScrollbar options={{ suppressScrollX: true }} style={{ padding: '1rem' }}>*/}
            {/*    {children}*/}
            {/*</PerfectScrollbar>*/}
        </Box>
    );
};

export default ItemDrawWrapper;
