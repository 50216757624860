import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import ransomStore, { Ransom } from 'stores/ransomStore';

import Modal, { ModalBtnAction, ModalContent } from 'ui/Modal';
import Grid from 'ui/Grid';

import { EditRansomPaymentType } from './RansomPaymentType';

const ChangePaymentTypeModal = ({ ransom_id, onClose }: { ransom_id: number; onClose: () => void }) => {
    const [paymentType, setPaymentType] = useState<Ransom['paymentType']>(1);
    const [payPhone, setPayPhone] = useState<Ransom['payPhone']>(null);

    useMemo(() => {
        const { paymentType, payPhone } = ransomStore.getItem(ransom_id).item || {};
        if (paymentType) {
            setPaymentType(paymentType);
        }
        if (payPhone) {
            setPayPhone(payPhone);
        }
    }, [ransom_id]);

    const changePaymentType = (paymentType: Ransom['paymentType']) => {
        setPaymentType(paymentType);
    };

    const changePayPhone = (payPhone: Ransom['payPhone']) => {
        setPayPhone(payPhone);
    };

    const handleSave = () => {
        ransomStore.setEditingItem(ransom_id, { paymentType, payPhone });
        ransomStore.saveItem(ransom_id);
        onClose();
    };

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'inherit', disabled: false },
        { onClick: handleSave, label: 'Сохранить', variant: 'contained', color: 'primary', loading: false }
    ];

    return (
        <Modal maxWidth="sm" header="Выбрать метод оплаты выкупа" disableCloseBtn onClose={onClose} actions={actions}>
            <ModalContent>
                <Grid>
                    <EditRansomPaymentType
                        paymentType={paymentType}
                        handleChangePaymentType={changePaymentType}
                        payPhone={payPhone}
                        handleChangePhone={changePayPhone}
                    />
                </Grid>
            </ModalContent>
        </Modal>
    );
};

export default observer(ChangePaymentTypeModal);
