import { computed, action, observable, makeObservable } from 'mobx';

import * as userApi from 'api/userApi';
import ListStorePrototype from './prototypes/ListStore.prototype';
import { catchItemError } from './helpers/decorators.helpers';

import { DropdownType } from './productStore';

export type User = {
    user_id: number;
    referrer_user_id: number;
    email: string | null;
    phone: string;
    firstName: string;
    lastName: string;
    balance: number;
    sum30dPayments: number;
    sumClearPayments: number;
    discount: number;

    access_id: number;
    referralsCount: number;
    referralsSumToWithdraw: number | null;
    enableReferrals: boolean;
    deliveryManager: boolean;
    disableWbAccountAfterRansom: boolean;
    firstPaymentGift: number | null;
    firstPaymentExpireTime: number | null;

    disableWb: boolean;

    onlineTime: number;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type UserListFilter = {
    search: string;
    enableReferrals: boolean;
};

export type UserDiscount = {
    discount: number;
    type: 'discount' | 'firstPayment';
    expireDays: number;
};

class UserStore extends ListStorePrototype<User, User, {}, UserListFilter> {
    listFilterClear: UserListFilter = { search: '', enableReferrals: false };

    constructor() {
        super('user_id', 'user', userApi);
        makeObservable(this);
    }

    @catchItemError
    async addUserPayment(user_id: number, payment: number, type: 'gift' | 'order'): Promise<boolean> {
        await userApi.addUserPayment(user_id, payment, type);
        this.fetchList();
        return true;
    }

    @catchItemError
    async addUserDiscount(user_id: number, discount: UserDiscount): Promise<boolean> {
        await userApi.addUserDiscount(user_id, discount);
        this.fetchList();
        return true;
    }

    @observable
    loadingCouriers = true;

    @observable
    couriersList: User[] = [];

    @action
    async fetchCouriers(): Promise<void> {
        this.couriersList = await userApi.fetchCouriersList();
        this.loadingCouriers = false;
    }

    @computed get courierOptions(): DropdownType[] {
        return this.couriersList.map(({ user_id, firstName, lastName, phone }) => {
            return {
                value: user_id,
                text: `№${user_id}: ${[firstName, lastName].join(' ').trim()}`
            };
        });
    }
}

export default new UserStore();
