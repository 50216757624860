import React from 'react';

import commonStore from 'stores/commonStore';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';
import Table, { TableCell, TableRow, TableBody } from 'ui/Table';
import Grid from 'ui/Grid';
import { PhotoThumbnail } from '../AutoAnswers/initInfo/SupplierIdInstruction';
// import { SuccessMessage } from 'ui/Message';

import SeoPhotosModal from './SeoPhotosModal';

const SeoPhotos = () => {
    const [showModal, toggleModal] = useToggleModal();

    return (
        <Box
            sx={{
                flex: 1,
                padding: commonStore.isMobile ? '0.5rem' : '0 1rem 2rem',
                position: 'relative',
                zIndex: 1,
                overflowY: 'auto',
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0.4,
                    zIndex: -1,
                    backgroundImage: `url('/static/listbg/unruly-05-done.webp')`,
                    backgroundPosition: 'bottom right',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat'
                }
            }}
        >
            <Container maxWidth="md">
                <Paper elevation={1} sx={{ padding: '1rem 2rem' }}>
                    <Typography variant="h5">
                        Фотосъемка ваших товаров на реальных людях к размещаемым отзывам!
                    </Typography>
                    <Box sx={{ display: 'flex', p: 2 }}>
                        <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            sx={{ margin: 'auto' }}
                            onClick={toggleModal}
                        >
                            Заказать Фото для отзывов
                        </Button>
                    </Box>
                    <Typography variant="body1" sx={{ my: 2 }}>
                        Клиенты маркетплейсов при просмотре отзывов в приоритете обращают свое внимание на отзывы с
                        фото. Такие отзывы наиболее просматриваемы и получают максимальное количество лайков и внимания
                        пользователей. Используя сервис TrendBox вы можете заказать необходимое количество фотографий
                        ваших товаров на разных людях.
                    </Typography>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Стоимость</TableCell>
                                <TableCell>от 200 руб. за 1 фотографию</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Параметры съемки</TableCell>
                                <TableCell>
                                    <ul>
                                        <li>Съемка осуществляется в примерочных WB</li>
                                        <li>Любое количество ракурсов. Каждый ракурс оплачивается отдельно.</li>
                                        <li>Фотографии в высоком качестве</li>
                                    </ul>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>База моделей</TableCell>
                                <TableCell>
                                    <ul>
                                        <li>Женщины, 18-45 лет</li>
                                        <li>Возможные размеры XS - XL</li>
                                    </ul>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Срок исполнения</TableCell>
                                <TableCell>10 - 12 раб. дней</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Typography variant="h5" sx={{ mt: 2 }}>
                        Этапы работы
                    </Typography>
                    <ul>
                        <li>Мы с вами определяемся по списку SKU, размерному ряду и количеству фотографий.</li>
                        <li>
                            Вы передаете нам нужные товары для съемки. Возможные варианты передачи товаров: курьерская
                            доставка по Москве, СДЭК, отправка через самовыкуп на конкретный пункт ПВЗ.
                        </li>
                        <li>Фотоcъемка товаров.</li>
                        <li>
                            Мы отправляем вам ссылку на архив с фотографиями. Все фото будут рассортированы по папкам.
                        </li>
                        <li>
                            При необходимости возврата товаров, мы можем отправить их курьером, СДЭК, или осуществить
                            возврат на ПВЗ.
                        </li>
                    </ul>

                    <Grid columns={3}>
                        <Grid.Column>
                            <PhotoThumbnail srcBig="/static/photoseo/1.webp" width="100%" />
                        </Grid.Column>
                        <Grid.Column>
                            <PhotoThumbnail srcBig="/static/photoseo/2.webp" width="100%" />
                        </Grid.Column>
                        <Grid.Column>
                            <PhotoThumbnail srcBig="/static/photoseo/3.webp" width="100%" />
                        </Grid.Column>
                    </Grid>

                    <Typography variant="subtitle2" sx={{ mt: 2 }}>
                        Заявки обрабатываются в будни с 09 до 18 МСК
                    </Typography>
                </Paper>
                <Box sx={{ display: 'flex', p: 2 }}>
                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        sx={{ margin: 'auto' }}
                        onClick={toggleModal}
                    >
                        Заказать Фото для отзывов
                    </Button>
                    {showModal && <SeoPhotosModal onClose={toggleModal} />}
                </Box>
            </Container>
        </Box>
    );
};

export default SeoPhotos;
