import React from 'react';

import authStore from 'stores/authStore';
import commonStore from 'stores/commonStore';

import Button from 'ui/Button';
import Modal, { ModalContent } from 'ui/Modal';
import Table, { TableRow, TableCell, TableBody, TableHead } from 'ui/Table';

import MoneyIcon from '@mui/icons-material/Money';
import Typography from '@mui/material/Typography';

const prices: { title: string; cost: number }[] = [
    { title: 'Выкуп', cost: 75 },
    { title: 'Добавить товар конкурента при выкупе', cost: 5 },
    { title: 'Добавить фильтры при выкупе', cost: 10 },
    { title: 'Прогон аккаунта при выкупе', cost: 20 },
    { title: 'Одноразовый аккаунт при выкупе', cost: 40 },
    { title: 'Групповой выкуп', cost: 60 },
    { title: 'Отзыв автоматический', cost: 60 },
    { title: 'Отзыв с оплатой за размещение', cost: 110 },
    { title: 'Лайк на отзыв', cost: 6 },
    { title: 'Товар в избранное', cost: 4 },
    { title: 'Бренд в избранное', cost: 10 },
    { title: 'Товар в корзину', cost: 4 }
];

const PricesHeaderModal = () => {
    const Trigger = (
        <Button
            startIcon={<MoneyIcon />}
            variant={'outlined'}
            color="success"
            size="small"
            fullWidth={commonStore.isMobile}
        >
            Тарифы
        </Button>
    );

    const discount = Number(authStore?.currentUser?.discount);

    return (
        <Modal
            trigger={Trigger}
            header={
                <>
                    <MoneyIcon />
                    &nbsp;Цены на услуги нашего сервиса
                </>
            }
            maxWidth="md"
            fullScreen={commonStore.isMobile}
        >
            <ModalContent>
                <Typography>
                    Ваша скидка: <b>{discount}%</b>
                </Typography>
                <Table size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell>Услуга</TableCell>
                            <TableCell align="center">Цена</TableCell>
                            <TableCell align="center">Со скидкой</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {prices.map(({ title, cost }) => (
                            <TableRow key={title}>
                                <TableCell>{title}</TableCell>
                                <TableCell align="center">{cost.toFixed(2)}₽</TableCell>
                                <TableCell align="center">{((cost * (100 - discount)) / 100).toFixed(2)}₽</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </ModalContent>
        </Modal>
    );
};

export default PricesHeaderModal;
