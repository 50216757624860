import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import questionStore from 'stores/questionStore';
import ransomStore, { GenderType } from 'stores/ransomStore';
import feedbackReactionStore from 'stores/feedbackReactionStore';

import InfoPopup from 'ui/InfoPopup';

import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

const GenderInfoPopup = React.memo(() => (
    <InfoPopup size="large">
        <Typography variant="subtitle2">Это важный параметр для продвижения</Typography>
        <Typography variant="body2">
            Учитывайте Целевую Аудиторию данного товара. Wildberries учитывает гендер вашего товара при ранжирование в
            поисковой выдаче. Также подсказки в поисковой строке Wildberries ранжируются по указанному гендеру в профиле
            аккаунта WB.
        </Typography>
        <Typography variant="caption">
            По-умолчанию WB считает, что товар относится больше к женской аудитории.
        </Typography>
    </InfoPopup>
));

export const WbGenderFieldEditing = ({
    gender,
    title,
    handleChange,
    hidePopup
}: {
    gender: GenderType;
    title: string;
    handleChange: (gender: GenderType) => void;
    hidePopup?: boolean;
}) => {
    const handleChaneGender = (event: SyntheticEvent, gender: GenderType) => {
        handleChange(gender);
    };

    return (
        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <Typography>{title}</Typography>
            <ToggleButtonGroup size="small" color="secondary" value={gender} exclusive onChange={handleChaneGender}>
                <ToggleButton value="female">Женщины</ToggleButton>
                <ToggleButton value="male">Мужчины</ToggleButton>
            </ToggleButtonGroup>
            {!hidePopup && <GenderInfoPopup />}
        </Box>
    );
};

const WbGenderField = ({
    item_id,
    store,
    title
}: {
    item_id: number;
    store: typeof questionStore | typeof feedbackReactionStore | typeof ransomStore;
    title: string;
}) => {
    const { editingItem } = store.getItem(item_id);
    const { gender } = editingItem;

    const handleChaneGender = (gender: GenderType) => {
        store.setEditingItem(item_id, { gender });
    };

    return <WbGenderFieldEditing gender={gender || null} handleChange={handleChaneGender} title={title} />;
};

export default observer(WbGenderField);
