import React, { SyntheticEvent, useState } from 'react';
import { observer } from 'mobx-react';

import ListWrapper from '../ListWrapper';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import ApiCardsView from './ApiCard';
import ApiStatisticsView from './ApiStatistics';

const ApiList = () => {
    const [type, setType] = useState<1 | 2>(2);
    const handleChangeType = (event: SyntheticEvent, type: 1 | 2 | null) => {
        if (type) {
            setType(type);
        }
    };

    return (
        <ListWrapper store={null} bgUrl="/static/listbg/unruly-18-finance.webp">
            <ToggleButtonGroup
                size="small"
                color="primary"
                value={type}
                exclusive
                onChange={handleChangeType}
                fullWidth
            >
                <ToggleButton value={2} sx={{ gap: 1 }}>
                    Редактирование карточки
                </ToggleButton>
                <ToggleButton value={1} sx={{ gap: 1 }}>
                    Статистика
                </ToggleButton>
            </ToggleButtonGroup>

            {type === 1 && <ApiStatisticsView />}
            {type === 2 && <ApiCardsView />}
        </ListWrapper>
    );
};

export default observer(ApiList);
