import React, { SyntheticEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useDebounce from 'common/useDebounce';

import commonStore from 'stores/commonStore';
import * as productApi from 'api/productApi';
import { DropdownType, StoreIds } from 'stores/productStore';

import Paper from '@mui/material/Paper';
import Progress from 'ui/Progress';
import InfoPopup from 'ui/InfoPopup';

import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ProductSearchPopup = React.memo(({ storeTitle }: { storeTitle: string }) => {
    return (
        <InfoPopup size="large">
            <Typography variant="subtitle2">Добавляйте ваши товары с {storeTitle}</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Найдите тут ваш товар по артикулу или по ссылке на сайт {storeTitle}. Добавьте его в этот список и
                карточка с вашим товаром останется тут. Дальше вы сможете создавать выкупы, писать отзывы, добавлять в
                избранное данный товар, а также наблюдать за интересующей вас статистикой.
            </Typography>
        </InfoPopup>
    );
});

const CustomizedInputBase = (
    props: AutocompleteRenderInputParams & {
        error: string;
        showPopup: boolean;
        store_id: StoreIds;
    }
) => {
    const { store_id, error, showPopup, ...restProps } = props;

    const storeTitle = store_id === StoreIds.WB ? 'Wildberries' : 'OZON';
    const placeholder = commonStore.isMobile
        ? store_id === StoreIds.WB
            ? 'Артикул в WB или ссылка на товар'
            : 'Артикул в OZON или ссылка на товар'
        : `Артикул товара в ${storeTitle} или ссылка на товар`;

    return (
        <Paper elevation={0} sx={{ p: '2px 0px', display: 'flex', alignItems: 'center' }}>
            <TextField
                {...restProps}
                label={error || placeholder}
                placeholder={error || placeholder}
                InputProps={{
                    ...restProps.InputProps,
                    endAdornment: showPopup ? (
                        <InputAdornment position="end" sx={{ background: 'white', zIndex: 1001, height: '100%' }}>
                            <ProductSearchPopup storeTitle={storeTitle} />
                        </InputAdornment>
                    ) : undefined
                }}
                sx={{ ml: 1, flex: 1 }}
                error={Boolean(error)}
            />
        </Paper>
    );
};

const matchStoreItemId = (
    search: string,
    store_id: StoreIds
): { matched_store_item_id: number; matchedUrl: boolean } => {
    let matched_store_item_id: number;
    const matchUrl =
        store_id === StoreIds.WB ? search.match(/catalog\/(\d+)\/detail/) : search.match(/product\/(.+)-(\d+)\//);

    if (matchUrl) {
        matched_store_item_id = Number(matchUrl[store_id === StoreIds.WB ? 1 : 2]);
    } else {
        matched_store_item_id = Number(search);
    }

    return {
        matched_store_item_id,
        matchedUrl: Boolean(matchUrl)
    };
};

export type DropdownProductType = DropdownType & {
    photoUrl: string | null;
};

export const ProductSearchDropdownAutocomplete = ({
    onChange,
    value,
    showPopup,
    store_id
}: {
    onChange: (value: DropdownProductType | null) => void;
    value: DropdownProductType | null;
    showPopup: boolean;
    store_id: StoreIds;
}) => {
    const [search, setSearch] = useState('');
    const [store_item_id, setStoreItemId] = useState<number | null>(null);
    const [searchLoading, setLoading] = useState(false);
    const [searchOptions, setSearchOptions] = useState<DropdownProductType[]>([]);
    const [searchError, setSearchError] = useState<string>('');

    const handleChangeSearch = (event: SyntheticEvent, search: string) => {
        setSearch(search);
    };

    const storeItemIdDebounced = useDebounce(store_item_id, 1200);

    useEffect(() => {
        let { matched_store_item_id, matchedUrl } = matchStoreItemId(search, store_id);
        setSearchOptions([]);

        if (matched_store_item_id && matched_store_item_id > 9999) {
            setStoreItemId(matched_store_item_id);
            if (matchedUrl) {
                setSearch('');
            }
        }
    }, [search]);

    useEffect(() => {
        if (storeItemIdDebounced) {
            setLoading(true);
            productApi
                .searchStoreProduct(storeItemIdDebounced, store_id || StoreIds.WB)
                .then(storeItem => {
                    const searchOptions = [
                        {
                            value: store_item_id,
                            text: `№${store_item_id} ${storeItem.title}`,
                            photoUrl: storeItem.photoUrl
                        }
                    ];
                    setSearchOptions(searchOptions);
                })
                .catch(errors => {
                    if (errors instanceof Array) {
                        setSearchError(errors[0]);
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [storeItemIdDebounced]);

    const handleChange = (event: SyntheticEvent, option: DropdownProductType | null | string) => {
        if (option !== null && typeof option === 'object') {
            onChange(option || null);
        } else {
            onChange(null);
        }
    };

    return (
        <Autocomplete
            options={searchOptions}
            filterOptions={x => x}
            value={value}
            freeSolo
            getOptionLabel={option => (typeof option === 'string' ? option : option.text)}
            renderInput={params => (
                <CustomizedInputBase {...params} error={searchError} showPopup={showPopup} store_id={store_id} />
            )}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.photoUrl && <img loading="lazy" width="48" src={option.photoUrl} />}
                    {option.text}
                </Box>
            )}
            popupIcon={
                searchLoading ? (
                    <div style={{ position: 'relative', left: '-12px', background: 'white' }}>
                        <Progress show size={24} />
                    </div>
                ) : undefined
            }
            loading={searchLoading}
            onChange={handleChange}
            onInputChange={handleChangeSearch}
            noOptionsText="Товар не найден"
            loadingText="Поиск товара..."
        />
    );
};

export default observer(ProductSearchDropdownAutocomplete);
