import React from 'react';
import { observer } from 'mobx-react';

import { useStoreHooks } from 'common/listFilterHooks';
import brandStore from 'stores/brandStore';
import productStore from 'stores/productStore';
import authStore from 'stores/authStore';

import Select from 'ui/Select';
import Checkbox from 'ui/Checkbox';

import ListFilterWrapper from '../ListFilterWrapper';
import StoresToggle from 'components/StoresToggle';

const BrandsFilter = () => {
    const { handleToggleAllUsers, handleChangeStatus, handleChangeProductId } = useStoreHooks(brandStore);

    const { listFilter } = brandStore;
    const { status, product_id, allUsers } = listFilter;

    const { loadingList: loadingListProduct, productOptions } = productStore;

    return (
        <>
            <ListFilterWrapper store={brandStore} disableArchive>
                {authStore.isAdmin() && <Checkbox label="все" checked={allUsers} onClick={handleToggleAllUsers} />}

                <StoresToggle />

                <Select
                    label="Статус"
                    size="small"
                    variant="standard"
                    value={status}
                    onChange={handleChangeStatus}
                    options={[
                        { value: 1, text: 'Выполняется' },
                        { value: 2, text: 'Выполнено' },
                        { value: 100, text: 'Ошибка' }
                    ]}
                    clearable
                    fullWidth
                />

                <Select
                    label="Товар"
                    value={product_id}
                    onChange={handleChangeProductId}
                    options={productOptions}
                    loading={loadingListProduct}
                    size="small"
                    variant="standard"
                    fullWidth
                    clearable
                />
            </ListFilterWrapper>
        </>
    );
};

export default observer(BrandsFilter);
