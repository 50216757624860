import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import deliveryPointsStore from 'stores/deliveryPointsStore';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { setMapMarkers } from './index';

const DeliveryPointType = () => {
    const { type } = deliveryPointsStore.storePointsFilter;

    const handleType = (event: SyntheticEvent, type: number) => {
        deliveryPointsStore.changeStoresFilter('type', type);
        setMapMarkers();
    };

    return (
        <ToggleButtonGroup
            size="small"
            color="primary"
            value={type}
            exclusive
            onChange={handleType}
            fullWidth
            sx={{ mb: 2 }}
        >
            <ToggleButton value={0}>Все</ToggleButton>
            <ToggleButton value={1}>Пункт Ozon</ToggleButton>
            <ToggleButton value={2}>Ozon Box</ToggleButton>
        </ToggleButtonGroup>
    );
};

export default observer(DeliveryPointType);
