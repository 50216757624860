import React, { useState } from 'react';
import { observer } from 'mobx-react';
import deepCopy from 'common/deepCopy';

import {
    WbCardAddinParam,
    WbCardConfiguration,
    WBCardType,
    WbNCardNomencluatureConfig,
    WbCardNomenclature
} from 'api/wbApi/wbCardApi';
import * as wbCardApi from 'api/wbApi/wbCardApi';

import commonStore from 'stores/commonStore';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TextInput from 'ui/TextInput';
import Button from 'ui/Button';
import ListError from 'ui/ListError';
import { SuccessMessage } from 'ui/Message';

import CardEditField from './CardEditField';

const CardNomenclatures = ({
    config,
    nomenclatures,
    onChange
}: {
    config: WbNCardNomencluatureConfig;
    nomenclatures: WbCardNomenclature[];
    onChange: (nomIndex: number, type: string, params: WbCardAddinParam[]) => void;
}) => {
    const [currentVendorCode, setVendorCode] = useState(nomenclatures[0].vendorCode);

    return (
        <Paper elevation={2} sx={{ p: 2 }}>
            <Tabs
                allowScrollButtonsMobile
                value={currentVendorCode}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                sx={{ pb: 1 }}
            >
                {nomenclatures.map(({ vendorCode }) => (
                    <Tab
                        value={vendorCode}
                        key={vendorCode}
                        label={vendorCode}
                        onClick={() => setVendorCode(vendorCode)}
                        disabled={false}
                        wrapped={commonStore.isMobile}
                    />
                ))}
            </Tabs>
            <Stack spacing={3} sx={{ mb: 0, position: 'relative' }}>
                {config.addin.map(addinFiled => {
                    const { type } = addinFiled;
                    const foundNomenclature = nomenclatures.find(({ vendorCode }) => vendorCode === currentVendorCode);
                    if (!foundNomenclature) {
                        return null;
                    }
                    const foundAddin = foundNomenclature.addin.find(add => add.type === type);
                    if (!foundAddin) {
                        return null;
                    }

                    const nomIndex = nomenclatures.findIndex(({ vendorCode }) => vendorCode === currentVendorCode);

                    return (
                        <CardEditField
                            key={type}
                            addin={addinFiled}
                            params={foundAddin?.params || []}
                            onChange={(type, params) => onChange(nomIndex, type, params)}
                        />
                    );
                })}
            </Stack>
        </Paper>
    );
};

const CardConfiguration = ({
    initCard,
    configuration
}: {
    initCard: WBCardType;
    configuration: WbCardConfiguration;
}) => {
    const [card, setCard] = useState<WBCardType>(initCard);
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [done, setDone] = useState<boolean>(false);

    const handleChangeAddinType = (type: string, params: WbCardAddinParam[]) => {
        setDone(false);
        if (!card) {
            return;
        }

        const { addin, ...restCard } = card;
        const foundAddin = addin.find(add => add.type === type);
        if (foundAddin) {
            foundAddin.params = params;
        }
        setCard({ ...restCard, addin: [...addin] });
    };

    const handleChangeNomenclatureAddinType = (nomIndex: number, type: string, params: WbCardAddinParam[]) => {
        setDone(false);
        if (!card) {
            return;
        }

        const { nomenclatures, ...restCard } = card;
        const foundNom = nomenclatures[nomIndex];
        if (!foundNom) {
            return;
        }

        const { addin, ...restNom } = foundNom;
        const foundAddin = addin.find(add => add.type === type);
        if (foundAddin) {
            foundAddin.params = params;
        }

        const nomenclature: WbCardNomenclature = { ...restNom, addin: [...addin] };
        const nomeclatures = [...nomenclatures];
        nomenclatures[nomIndex] = nomenclature;

        setCard({ ...restCard, nomenclatures: [...nomeclatures] });
    };

    const handleSave = async () => {
        if (card) {
            setSaving(true);
            setErrors([]);
            setDone(false);
            console.log(deepCopy(card));
            try {
                await wbCardApi.updateUserWbCard(deepCopy(card));
                setDone(true);
            } catch (errors) {
                if (errors instanceof Array) {
                    setErrors(errors);
                }
            } finally {
                setSaving(false);
            }
        }
    };

    const { name, parent, addin, nomenclature } = configuration;

    const { addin: addinCard } = card;
    const addinTitle = addinCard.find(add => add.type === 'Наименование');

    return (
        <Stack spacing={3} sx={{ mb: 0, position: 'relative' }}>
            <TextInput label={`Категория (${parent})`} readOnly value={name} />
            <CardEditField
                addin={{
                    required: true,
                    useOnlyDictionaryValues: false,
                    type: 'Наименование',
                    isAvailable: true
                }}
                params={addinTitle?.params || []}
                onChange={handleChangeAddinType}
            />
            {addin.map(addinFiled => {
                const { type } = addinFiled;
                const foundAddin = card.addin.find(add => add.type === type);

                return (
                    <CardEditField
                        key={type}
                        addin={addinFiled}
                        params={foundAddin?.params || []}
                        onChange={handleChangeAddinType}
                    />
                );
            })}

            <CardNomenclatures
                config={nomenclature}
                nomenclatures={card.nomenclatures}
                onChange={handleChangeNomenclatureAddinType}
            />

            <Box
                sx={{
                    position: 'sticky',
                    bottom: '-1.5rem',
                    zIndex: 1,
                    background: '#eef0f1',
                    py: 2,
                    width: 'inherit',
                    maxWidth: 'inherit'
                }}
            >
                <ListError errors={errors} />
                {done && (
                    <SuccessMessage header="Изменения сохранены">
                        Изменения появятся на сайте WB через несколько минут
                    </SuccessMessage>
                )}
                <Button onClick={handleSave} variant="contained" color="primary" fullWidth loading={saving}>
                    Сохранить изменения
                </Button>
            </Box>
        </Stack>
    );
};

export default observer(CardConfiguration);
