import React from 'react';
import locationHistory from 'locationHistory';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import brandStore from 'stores/brandStore';

import Button from 'ui/Button';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { BrandLinkState } from '../../Brand/BrandLink';

const BrandToFavorite = ({ product_id, disabled }: { product_id: number; disabled: boolean }) => {
    const openBrandModal = () => {
        setTimeout(() => {
            brandStore.setEditingItem(CREATING_ITEM_ID, {
                product_id
            });
        }, 0);
        locationHistory.push(BrandLinkState(CREATING_ITEM_ID));
    };
    return (
        <Button
            color="secondary"
            startIcon={<FavoriteIcon />}
            tooltip="Добавить Бренд в Избранное"
            onClick={openBrandModal}
            variant="outlined"
            size="small"
            fullWidth
            disabled={disabled}
        >
            Бренд в избранное
        </Button>
    );
};

export default React.memo(BrandToFavorite);
