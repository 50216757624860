import React, { useEffect, useState } from 'react';
import { XAxis, YAxis, Bar, ComposedChart, Area, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import * as productApi from 'api/productApi';

import { WBStatChart } from 'api/productApi';
import LoaderAwait from 'ui/LoaderAwait';
import Box from '@mui/material/Box';
import { SECONDARY_COLOR } from 'muiTheme';

const ProductStatisticsMode = ({ product_id }: { product_id: number }) => {
    const [chart, setChart] = useState<WBStatChart[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log('product_id', product_id);
        productApi
            .fetchWBProductStatistics(product_id)
            .then(setChart)
            .finally(() => setLoading(false));
    }, [product_id]);

    if (loading) {
        return <LoaderAwait active />;
    }

    return (
        <Box sx={{ height: '600px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    width={500}
                    height={400}
                    data={chart}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="day" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    <Area
                        name="Остатки, шт."
                        type="monotone"
                        dataKey="availability"
                        stroke={SECONDARY_COLOR}
                        fill="#FFBCAB"
                        yAxisId="right"
                    />
                    {/*<Line type="monotone" dataKey="soldQty" stroke="#82ca9d" yAxisId="right" />*/}
                    <Bar name="Заказы, шт." dataKey="soldQty" fill="#4AC0C9" stroke="#006167" yAxisId="left" />
                    {/*<Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" />*/}
                    {/*<Bar dataKey="pv" barSize={20} fill="#413ea0" />*/}
                    {/*<Line type="monotone" dataKey="uv" stroke="#ff7300" />*/}
                    {/*<Scatter dataKey="cnt" fill="red" />*/}
                </ComposedChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default ProductStatisticsMode;
