import React from 'react';

import { ReviewStatusEnum } from 'stores/reviewStore';

import Chip from '@mui/material/Chip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Time from 'ui/Time';

const ReviewStatus = ({
    status,
    enable,
    planningExecuteTime
}: {
    status: ReviewStatusEnum;
    enable: boolean;
    planningExecuteTime: number | null;
}) => {
    if (!enable) {
        return <Chip label="Отменен" color="default" sx={{ cursor: 'pointer' }} />;
    }

    switch (status) {
        case ReviewStatusEnum.Creating:
            return <Chip label="Отправка" color="warning" sx={{ cursor: 'pointer' }} />;
        case ReviewStatusEnum.Done:
            return <Chip label="Отправлен" color="success" sx={{ cursor: 'pointer' }} />;
        case ReviewStatusEnum.WaitingForModeration:
            return <Chip label="Модерация" color="warning" sx={{ cursor: 'pointer' }} />;
        case ReviewStatusEnum.Cancel:
            return <Chip label="Возврат" color="error" sx={{ cursor: 'pointer' }} />;
        case ReviewStatusEnum.WaitingForExecution:
            return (
                <Chip
                    icon={<AccessTimeIcon />}
                    label={planningExecuteTime ? <Time time={planningExecuteTime} /> : 'Ожидает'}
                    color="default"
                    sx={{ cursor: 'pointer' }}
                />
            );

        case ReviewStatusEnum.Error:
            return <Chip label="Ошибка" color="error" sx={{ cursor: 'pointer' }} />;
        default:
            const error: never = status;
            console.log(error);
    }
    return null;
};

export default React.memo(ReviewStatus);
