import React, { useState } from 'react';
import axios from 'axios';

import Stack from '@mui/material/Stack';

import { StoreIds } from 'stores/productStore';
import * as ozonAccountsApi from 'api/ozonAccountsApi';
import wbAccountStore from 'stores/wbAccountStore';

import Button from 'ui/Button';
import { UserButton } from './User/UserLink';

const StoreOpenAccountBtn = ({
    account_id,
    store_id,
    user_id
}: {
    account_id: number;
    store_id: StoreIds;
    user_id?: number;
}) => {
    const [loading, setLoading] = useState(false);

    const handleOpenAccount = async () => {
        setLoading(true);
        if (store_id === StoreIds.WB) {
            wbAccountStore.openAccountInElectron(account_id);
        } else if (store_id === StoreIds.Ozon) {
            const { SecureAccessToken, SecureRefreshToken } = await ozonAccountsApi.fetchItem(account_id);
            await axios.post('http://localhost:11390/open/ozon', { SecureAccessToken, SecureRefreshToken });
        } else {
            const error: never = store_id;
        }
        setLoading(false);
    };

    return (
        <>
            <Button
                size="small"
                color={store_id === StoreIds.WB ? 'secondary' : 'primary'}
                tooltip="Открыть аккаунт. Electron должен быть включен"
                onClick={handleOpenAccount}
                loading={loading}
            >
                {store_id === StoreIds.WB ? 'WB' : 'Ozon'} #{account_id}
            </Button>
            {user_id && <UserButton user_id={user_id} />}
        </>
    );
};

export default StoreOpenAccountBtn;
