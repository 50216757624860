import React from 'react';
import { observer } from 'mobx-react';

import commonStore from 'stores/commonStore';

import Modal, { ModalBtnAction, ModalContent } from 'ui/Modal';
import Grid from 'ui/Grid';
import Select from 'ui/Select';
import productStore, { StoreIds } from 'stores/productStore';
import ransomStore from 'stores/ransomStore';

const RansomAddItemsModal = ({ ransom_id, onClose }: { ransom_id: number; onClose: () => void }) => {
    const { loadingList, productOptions } = productStore;

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'inherit', disabled: false }
    ];

    const handleChangeItem = ({ value: product_id }: { value: number }) => {
        const product = productStore.findById(product_id);
        if (product.store_id === StoreIds.WB) {
            const { wbItem, store_item_id } = product;
            ransomStore.addAdditionalItem(ransom_id, { value: store_item_id, text: wbItem?.title });
        }
        onClose();
    };

    return (
        <Modal
            maxWidth="sm"
            header="Добавить товар в выкуп (из списка Моих товаров)"
            disableCloseBtn
            onClose={onClose}
            actions={actions}
            fullScreen={commonStore.isMobile}
        >
            <ModalContent>
                <Grid>
                    <Grid.Column width={12}>
                        <Select
                            label="Выберите товар"
                            value={null}
                            onChange={handleChangeItem}
                            options={productOptions}
                            fullWidth
                            loading={loadingList}
                            disabled={loadingList}
                            variant="classic"
                        />
                    </Grid.Column>
                </Grid>
            </ModalContent>
        </Modal>
    );
};

export default observer(RansomAddItemsModal);
