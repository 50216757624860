// const volHostV2 = function (e: number): string {
//     return e >= 0 && e <= 143
//         ? '//basket-01.wb.ru/'
//         : e >= 144 && e <= 287
//         ? '//basket-02.wb.ru/'
//         : e >= 288 && e <= 431
//         ? '//basket-03.wb.ru/'
//         : e >= 432 && e <= 719
//         ? '//basket-04.wb.ru/'
//         : e >= 720 && e <= 1007
//         ? '//basket-05.wb.ru/'
//         : e >= 1008 && e <= 1061
//         ? '//basket-06.wb.ru/'
//         : e >= 1062 && e <= 1115
//         ? '//basket-07.wb.ru/'
//         : e >= 1116 && e <= 1169
//         ? '//basket-08.wb.ru/'
//         : e >= 1170 && e <= 1313
//         ? '//basket-09.wb.ru/'
//         : e >= 1314 && e <= 1601
//         ? '//basket-10.wb.ru/'
//         : e >= 1602 && e <= 1655
//         ? '//basket-11.wb.ru/'
//         : '//basket-12.wb.ru/';
// };

const volHostV2 = function (e: number): string {
    let t;
    switch (!0) {
        case e >= 0 && e <= 143:
            t = '01';
            break;
        case e >= 144 && e <= 287:
            t = '02';
            break;
        case e >= 288 && e <= 431:
            t = '03';
            break;
        case e >= 432 && e <= 719:
            t = '04';
            break;
        case e >= 720 && e <= 1007:
            t = '05';
            break;
        case e >= 1008 && e <= 1061:
            t = '06';
            break;
        case e >= 1062 && e <= 1115:
            t = '07';
            break;
        case e >= 1116 && e <= 1169:
            t = '08';
            break;
        case e >= 1170 && e <= 1313:
            t = '09';
            break;
        case e >= 1314 && e <= 1601:
            t = '10';
            break;
        case e >= 1602 && e <= 1655:
            t = '11';
            break;
        case e >= 1656 && e <= 1919:
            t = '12';
            break;
        case e >= 1920 && e <= 2045:
            t = '13';
            break;
        case e >= 2046 && e <= 2189:
            t = '14';
            break;
        case e >= 2091 && e <= 2405:
            t = '15';
            break;
        default:
            t = '16';
    }
    return `//basket-${t}.wbbasket.ru/`;
};

const constructHostV2 = (e: string): string => {
    const n = parseInt(e, 10),
        r = ~~(n / 1e5),
        o = ~~(n / 1e3);
    return `${volHostV2(r)}vol${r}/part${o}/${n}`;
};

const matchWbPhotoUrl = (wb_item_id: number, number = 1): { zoom: string; preview: string } => {
    const constructUrl = constructHostV2(String(wb_item_id));
    const url = `https:${constructUrl}/images`;

    return {
        zoom: `${url}/big/${number}.jpg`,
        preview: `${url}/c246x328/${number}.jpg`
    };
};

export default matchWbPhotoUrl;
