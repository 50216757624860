import React from 'react';

import Paper from '@mui/material/Paper';
import ReviewUploadFiles from './ReviewUploadFiles';

const UploadVideo = () => {
    return (
        <Paper elevation={2} sx={{ p: 1 }}>
            <ReviewUploadFiles
                type="video"
                acceptedFileTypes={['video/*', '.mov']}
                maxFiles={3}
                labelIdle={`<div>Перенесите сюда видео (до 3 штук; от 3 секунд до 5 минут) или <span class="filepond--label-action"> Выберите в папке </span></div>`}
            />
        </Paper>
    );
};

export default UploadVideo;
