import { observer } from 'mobx-react';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { requestFulFillment } from 'api/userApi';
import * as userApi from 'api/userApi';

import authStore from 'stores/authStore';
import commonStore from 'stores/commonStore';

// import Typography from '@mui/material/Typography';

import Modal, { ModalBtnAction, ModalContent } from 'ui/Modal';
import ListError from 'ui/ListError';
import Grid from 'ui/Grid';
import TextInput from 'ui/TextInput';
import { InfoMessage } from 'ui/Message';

const SeoSeoFulfillmentModal = ({ onClose }: { onClose: () => void }) => {
    useEffect(() => {
        userApi.sendUserAction({ SeoSeoFulfillmentModal: true });
    }, []);

    const [phone, setPhone] = useState(`+${authStore.currentUser?.phone}`);
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const handleCreate = async () => {
        try {
            await requestFulFillment(phone, comment);
            commonStore.setGlobalInfoMessage('Заявка на "FulFillment" создана, мы свяжемся с вами в течение 24 часов');
            onClose();
        } catch (errors) {
            if (errors instanceof Array) {
                setErrors(errors);
            }
            setLoading(false);
        }
    };

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'inherit' },
        {
            onClick: handleCreate,
            label: 'Оставить заявку',
            variant: 'contained',
            color: 'secondary',
            loading
        }
    ];

    const handleChangePhone = (event: SyntheticEvent, { value }: { value: string }) => {
        setPhone(value);
    };

    const handleChangeComment = (event: SyntheticEvent, { value }: { value: string }) => {
        setComment(value);
    };

    return (
        <Modal maxWidth="sm" onClose={onClose} actions={actions} header="Заявка (консультация) на FulFillment">
            <ModalContent>
                <ListError errors={errors} />
                <Grid>
                    <Grid.Column>
                        <TextInput
                            variant="standard"
                            label="Как с вами связаться?"
                            value={phone}
                            onChange={handleChangePhone}
                            maxLength={1000}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            variant="standard"
                            label="Комментарий, пожелания (не обязательно)"
                            value={comment}
                            onChange={handleChangeComment}
                            multiline
                            rowsMax={10}
                            rows={1}
                        />
                    </Grid.Column>
                </Grid>
                <InfoMessage>Если у вас возникли вопросы, обращайтесь @topstoreadm</InfoMessage>
            </ModalContent>
        </Modal>
    );
};

export default observer(SeoSeoFulfillmentModal);
