import React, { useEffect, useState } from 'react';

import * as userApi from 'api/userApi';
import commonStore from 'stores/commonStore';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

// import FFPriceCalclulatorSelectProduct from './FFPriceCalclulatorSelectProduct';
import PackListItem from './PackListItem';
import Price from 'components/Price';

import { productPriceItems, ProductPriceItem, packMaterialsSet, packetMaterialGroups } from './priceData';

const ProductPriceListItem = ({
    label,
    subLabel,
    price,
    priceWithSale,
    checked,
    isLast,
    onChange
}: ProductPriceItem & { isLast: boolean; onChange: () => void }) => {
    const { isMobile } = commonStore;

    return (
        <ListItem
            secondaryAction={<Price price={price} priceWithSale={priceWithSale} direction="row-reverse" />}
            disablePadding
            divider={isLast}
            disableGutters={isMobile}
        >
            <ListItemButton onClick={onChange}>
                <ListItemIcon>
                    <Checkbox edge="start" checked={checked} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText primary={label} secondary={subLabel}></ListItemText>
            </ListItemButton>
        </ListItem>
    );
};

const FFPriceCalculator = () => {
    const { isMobile } = commonStore;

    const [checkedPrices, setCheckedPrices] = useState<boolean[]>(productPriceItems.map(({ checked }) => checked));
    const [pack_id, setPackId] = useState<number | null>(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceWithSale, setTotalPriceWithSale] = useState(0);

    useEffect(() => {
        let totalPrice = 0;
        let totalPriceWithSale = 0;
        checkedPrices.forEach((checked, index) => {
            if (checked) {
                totalPrice += productPriceItems[index].price;
                totalPriceWithSale += productPriceItems[index].priceWithSale;
            }
        });
        if (pack_id) {
            const foundPack = packMaterialsSet.find(pack => pack.pack_id === pack_id);
            if (foundPack) {
                totalPrice += foundPack.price;
                totalPriceWithSale += foundPack.price;
                const packGroup = packetMaterialGroups.find(({ group_id }) => group_id === foundPack.group_id);
                if (packGroup) {
                    totalPrice += packGroup.packPrice;
                    totalPriceWithSale += packGroup.packPrice;
                }
            }
        }
        setTotalPrice(totalPrice);
        setTotalPriceWithSale(totalPriceWithSale);
    }, [checkedPrices, pack_id]);

    const handleCheckedPrice = (index: number) => {
        checkedPrices[index] = !checkedPrices[index];
        setCheckedPrices([...checkedPrices]);
        userApi.sendUserAction({ checkedItemPrice: index });
    };

    return (
        <Box>
            {/*<FFPriceCalclulatorSelectProduct />*/}
            <Container maxWidth="sm" sx={{ px: isMobile ? 0 : 2 }}>
                <List dense disablePadding={isMobile}>
                    {productPriceItems.map(({ price, priceWithSale, checked, subLabel, label }, index) => (
                        <ProductPriceListItem
                            key={label}
                            label={label}
                            subLabel={subLabel}
                            price={price}
                            priceWithSale={priceWithSale}
                            checked={checkedPrices[index]}
                            isLast={index === productPriceItems.length - 1}
                            onChange={() => handleCheckedPrice(index)}
                        />
                    ))}
                    <PackListItem onChange={setPackId} />
                    <ListItem
                        secondaryAction={
                            <Price price={totalPrice} priceWithSale={totalPriceWithSale} direction="row-reverse" />
                        }
                        disableGutters={isMobile}
                    >
                        <ListItemText primary="Итого" secondary="За 1 единицу"></ListItemText>
                    </ListItem>
                </List>
            </Container>
        </Box>
    );
};

export default FFPriceCalculator;
