import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import productStore, { Product, StoreIds } from 'stores/productStore';
import deliveryStore from 'stores/deliveryStore';
import videoStore from 'stores/videoStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import authStore from 'stores/authStore';
import reviewStore from 'stores/reviewStore';
import { RansomType } from 'stores/ransomStore';

import ListError from 'ui/ListError';
import LoaderAwait from 'ui/LoaderAwait';
import Grid from 'ui/Grid';
import Progress from 'ui/Progress';
import Button from 'ui/Button';
import { ErrorMessage, InfoMessage, SuccessMessage, WarningMessage } from 'ui/Message';
import Time from 'ui/Time';

import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CachedIcon from '@mui/icons-material/Cached';

import ProductCard from '../Product/ProductCard';
import { RansomLinkState } from '../Ransom/RansomLink';
import DeliveryGroupTabs from './DeliveryGroupTabs';
import { ReviewLinkState } from '../Review/ReviewLink';
import StoreRequestError from '../StoreRequestError';
import StoreOpenAccountBtn from '../StoreOpenAccountBtn';

import MajorUserDropdown from './MajorUserDropdown';
import WarningDeliveryMessage from './ShowBlocks/WarningDeliveryMessage';
import DeliveryAddressAndSizeShow from './ShowBlocks/DeliveryAddressAndSizeShow';
import DidNotPayBtn from './ShowBlocks/DidNotPayBtn';

const sizePx = 196;

const DeliveryShowMode = ({ delivery_id }: { delivery_id: number }) => {
    const { item, errors, loadingItem } = deliveryStore.getItem(delivery_id);

    const [fetchInterval, setFetchInterval] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        return () => {
            if (fetchInterval) {
                clearInterval(fetchInterval);
            }
        };
    }, [fetchInterval]);

    useEffect(() => {
        if (item?.ransom?.product_id) {
            productStore.fetchItem(item?.ransom?.product_id);
        }
    }, [item?.ransom?.product_id]);

    const {
        deliveryQR,
        ransom_id,
        ransom,
        status,
        errorMessage,
        deliveryCode,
        phone,
        firstName,
        enable,
        createTime,
        updateTime,
        user_id
    } = item || {
        status: 1,
        enable: true
    };

    useEffect(() => {
        if (status === 1) {
            const fetchInterval = setInterval(() => {
                deliveryStore.fetchItem(delivery_id);
            }, 1000);
            setFetchInterval(fetchInterval);
        } else if (fetchInterval) {
            clearInterval(fetchInterval);
            setFetchInterval(null);
        }
        videoStore.findWbVideo('delivery', delivery_id);
    }, [status, delivery_id]);

    let product: Product | null = null;
    if (ransom?.product_id) {
        try {
            const productItem = productStore.getItem(ransom?.product_id);
            product = productItem?.item || null;
        } catch (err) {}
    }

    const refreshStoreTime = async () => {
        await deliveryStore.refreshUpdateStoreDelivery(delivery_id);
    };

    const handleCreateReview = (product_id: number, ransom_id: number) => {
        setTimeout(() => {
            reviewStore.createEmptyReview(product_id, ransom_id);
        }, 0);
        locationHistory.push(ReviewLinkState(CREATING_ITEM_ID));
    };

    const handleOpenReview = (review_id: number) => {
        locationHistory.push(ReviewLinkState(review_id));
    };

    const qrWidth = ransom?.store_id === StoreIds.Ozon ? 350 : sizePx;

    return (
        <>
            {ransom?.ransom_group_id && (
                <DeliveryGroupTabs
                    ransom_group_id={ransom.ransom_group_id}
                    currentDeliveryId={delivery_id}
                    label={ransom?.type === RansomType.Group ? 'Доставки в группе' : 'Smart доставки'}
                />
            )}

            <Container maxWidth="sm">
                <ListError errors={errors} />
                <LoaderAwait active={loadingItem} />
                <Grid>
                    {authStore.isAdmin() && (
                        <Grid.Column>
                            <MajorUserDropdown delivery_id={delivery_id} />
                        </Grid.Column>
                    )}

                    {authStore?.currentUser?.disableWb && (
                        <Grid.Column>
                            <ErrorMessage header="Проблема в Wildberries">
                                Сайт и приложение Wildberries временно не работают. Обновить информацию о доставке пока
                                невозможно
                            </ErrorMessage>
                        </Grid.Column>
                    )}

                    {!enable && (
                        <Grid.Column>
                            <ErrorMessage header="Выкуп отменен">
                                Оплата за выкуп возвращена на ваш счет <Time time={updateTime} />
                            </ErrorMessage>
                        </Grid.Column>
                    )}
                    {status === 100 && (
                        <Grid.Column>
                            <StoreRequestError error={errorMessage} store_id={product?.store_id} />
                            {enable && (
                                <Stack direction="row">
                                    <DidNotPayBtn delivery_id={delivery_id} />
                                    {!authStore?.currentUser?.disableWb && (
                                        <Button
                                            endIcon={<CachedIcon />}
                                            color="error"
                                            onClick={refreshStoreTime}
                                            disabled={loadingItem}
                                            fullWidth
                                        >
                                            Попробовать еще раз
                                        </Button>
                                    )}
                                </Stack>
                            )}
                            {/*{lastName && phone && (*/}
                            {/*    <>*/}
                            {/*        <Typography variant="h6" color="primary">*/}
                            {/*            Контактные данные для получения*/}
                            {/*        </Typography>*/}
                            {/*        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>*/}
                            {/*            <Typography>Телефон</Typography>*/}
                            {/*            <Typography color="secondary" variant="h6" sx={{ fontWeight: 400 }}>*/}
                            {/*                + 7 {phone.slice(1, 4)}*/}
                            {/*                <b style={{ padding: '0 0.5rem' }}>{phone.slice(4, 7)}</b>*/}
                            {/*                {phone.slice(7, 11)}*/}
                            {/*            </Typography>*/}
                            {/*        </Box>*/}
                            {/*        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>*/}
                            {/*            <Typography>Фамилия Имя</Typography>*/}
                            {/*            <Typography color="secondary" variant="h6">*/}
                            {/*                <b>*/}
                            {/*                    {lastName} {firstName}*/}
                            {/*                </b>*/}
                            {/*            </Typography>*/}
                            {/*        </Box>*/}
                            {/*    </>*/}
                            {/*)}*/}
                        </Grid.Column>
                    )}
                    {!loadingItem && (
                        <Grid.Column>
                            {status === 1 && (
                                <>
                                    <WarningMessage header="Статус">Проверка оплаты</WarningMessage>
                                    <Progress show linear />
                                </>
                            )}
                            {status === 2 && <InfoMessage header="Статус">Доставка до ПВЗ</InfoMessage>}
                            {status === 3 && <SuccessMessage header="Статус">Готов к выдаче</SuccessMessage>}
                            {status === 4 && <SuccessMessage header="Статус">Выдан</SuccessMessage>}
                            {status === 5 && <ErrorMessage header="Статус">Возврат</ErrorMessage>}
                        </Grid.Column>
                    )}
                    {(deliveryQR || deliveryCode) && [/*1, 2,*/ 3].includes(status) && (
                        <Grid.Column>
                            {deliveryQR && (
                                <>
                                    <InfoMessage header="QR-код на получение" />
                                    <Progress show={loadingItem} linear />
                                    <Box
                                        sx={{
                                            background: `url('${deliveryQR}')`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '100% 100%',
                                            width: qrWidth,
                                            minWidth: qrWidth,
                                            maxWidth: qrWidth,
                                            height: sizePx,
                                            minHeight: sizePx,
                                            maxHeight: sizePx,
                                            margin: '1rem auto 2rem'
                                        }}
                                    />
                                </>
                            )}
                            {firstName && (
                                <>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <Typography>Код для получения товара</Typography>
                                        <Typography color="secondary" variant="h6">
                                            <b>{deliveryCode}</b>
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <Typography>Имя</Typography>
                                        <Typography color="secondary" variant="h6">
                                            <b>{firstName}</b>
                                        </Typography>
                                    </Box>
                                </>
                            )}
                            {phone && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Typography>Телефон</Typography>
                                    <Typography color="secondary" variant="h6" sx={{ fontWeight: 400 }}>
                                        + 7 {phone.slice(1, 4)}
                                        <b style={{ padding: '0 0.5rem' }}>{phone.slice(4, 7)}</b>
                                        {phone.slice(7, 11)}
                                    </Typography>
                                </Box>
                            )}
                        </Grid.Column>
                    )}
                    <Grid.Column>
                        <Typography>
                            {authStore.isAdmin() && product && ransom && (
                                <StoreOpenAccountBtn
                                    account_id={ransom.account_id}
                                    store_id={product.store_id}
                                    user_id={user_id}
                                />
                            )}
                            Доставка создана <Time time={createTime} />
                        </Typography>
                        <ProductCard product={product} />
                    </Grid.Column>
                    <Grid.Column>
                        <DeliveryAddressAndSizeShow delivery_id={delivery_id} />
                    </Grid.Column>

                    {item?.statuses && (
                        <Grid.Column>
                            <List dense disablePadding>
                                {item?.statuses.map(({ status, date }) => (
                                    <ListItem key={status}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <AccessTimeIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={status} secondary={date} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid.Column>
                    )}

                    {ransom?.store_id === StoreIds.WB && [2, 3].includes(status) && (
                        <Grid.Column>
                            <WarningDeliveryMessage />
                        </Grid.Column>
                    )}

                    {ransom_id && (
                        <Grid.Column>
                            <Stack direction="row">
                                <Button
                                    color="secondary"
                                    onClick={() => locationHistory.push(RansomLinkState(ransom_id))}
                                    fullWidth
                                >
                                    Открыть карточку Выкупа
                                </Button>

                                {status === 4 && ransom?.product_id && (
                                    <Button
                                        color="primary"
                                        onClick={() => handleCreateReview(Number(ransom.product_id), ransom_id)}
                                        fullWidth
                                        variant="outlined"
                                        disabled={!ransom?.accountIsGood}
                                    >
                                        Написать отзыв
                                    </Button>
                                )}
                                {status === 6 && (
                                    <Button
                                        color="primary"
                                        onClick={() => handleOpenReview(ransom_id)}
                                        fullWidth
                                        variant="contained"
                                    >
                                        Открыть отзыв
                                    </Button>
                                )}
                            </Stack>
                        </Grid.Column>
                    )}
                </Grid>
            </Container>
        </>
    );
};

export default observer(DeliveryShowMode);
