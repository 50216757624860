import fetchApi from 'common/fetchApi';
import { objectToGraphql } from 'common/graphql';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { ReferralLink } from 'stores/referralLinkStore';

export const GQReferralLinkFields = `
    link_id
    code
    user_id
    comment
    
    linkVisited
    userRegistered
    totalPayments
    
    createTime
    updateTime
    paymentsResetTime
`;

export const fetchItem = async (link_id: number): Promise<ReferralLink> => {
    const graphql = `{
            fetchReferralLink(link_id: ${link_id}) {
                ${GQReferralLinkFields}
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchReferralLink: ReferralLink }>(graphql);
    return data.fetchReferralLink;
};

export const createItem = async (link: Partial<ReferralLink>): Promise<number> => {
    const graphql = `mutation { 
          createReferralLink(link: ${objectToGraphql(link)})
        }`;

    const data = await fetchApi.postGQ<{ createReferralLink: number }>(graphql);
    return data.createReferralLink;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    productFilter: {},
    controller?: AbortController
): Promise<{ list: ReferralLink[]; count: number }> => {
    const graphql = `{
            fetchReferralLinksList {
                list {
                    ${GQReferralLinkFields}
                }
                count
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchReferralLinksList: { list: ReferralLink[]; count: number } }>(graphql);
    return data.fetchReferralLinksList;
};

export type ReferralPayment = {
    cost: number;
    createTime: number;
    comment: string;
};

export const fetchReferralsPayments = async (
    limit: number,
    offset: number
): Promise<{ list: ReferralPayment[]; count: number }> => {
    const graphql = `{
            fetchReferralsPayments(limit: ${limit}, offset: ${offset}) {
                list {
                    cost
                    createTime
                    comment    
                }
                count
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchReferralsPayments: { list: ReferralPayment[]; count: number } }>(graphql);
    return data.fetchReferralsPayments;
};

export const resetReferralsBalance = async (user_id: number): Promise<boolean> => {
    const graphql = `mutation { 
          resetReferralsBalance(user_id: ${user_id})
        }`;

    const data = await fetchApi.postGQ<{ resetReferralsBalance: boolean }>(graphql);
    return data.resetReferralsBalance;
};
