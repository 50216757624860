import React from 'react';
import commonStore from 'stores/commonStore';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import FFHeader from './FFHeader';
import ListContainer from '../ListContainer';
import FFPrice from './FFPrice';
import FFFooter from './FFFooter';
import FFFullPriceLink from './FFFullPriceLink';

const FFPricePage = () => {
    const { isMobile } = commonStore;

    return (
        <ListContainer bgUrl="/static/listbg/unruly-05-done.webp">
            <Container maxWidth="lg" sx={{ px: isMobile ? 0 : 2 }}>
                <Stack spacing={3}>
                    <FFHeader />
                    <FFFullPriceLink />
                    <Paper elevation={1} sx={{ p: isMobile ? 2 : 3 }}>
                        <FFPrice />
                        <FFFullPriceLink />
                    </Paper>

                    <FFFooter />
                </Stack>
            </Container>
        </ListContainer>
    );
};

export default FFPricePage;
