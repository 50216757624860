import React from 'react';

import matchWbPhotoUrl from 'common/matchWbPhotoUrl';
import { WBProductSuggestion } from 'api/productApi';

import Table, { TableBody, TableCell, TableHead, TableRow } from 'ui/Table';
import Price from 'components/Price';

import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';

export const WbProductStat = React.memo(
    ({ sold30dQty, sold30dAmount }: { sold30dQty: number | null; sold30dAmount: number | null }) => {
        if (sold30dAmount === null || sold30dQty === null) {
            return <span>н/д</span>;
        }

        return (
            <Box sx={{ position: 'relative' }}>
                <Tooltip title="Статистика за 30 дней">
                    <Stack>
                        {sold30dQty > 0 && <Box sx={{ whiteSpace: 'nowrap' }}>{sold30dQty.toLocaleString()} шт.</Box>}
                        {sold30dAmount > 0 && (
                            <Box sx={{ whiteSpace: 'nowrap' }}>{sold30dAmount.toLocaleString()} ₽</Box>
                        )}
                    </Stack>
                </Tooltip>
            </Box>
        );
    }
);

const ProductsTableWB = ({ products, wb_item_id }: { products: WBProductSuggestion[]; wb_item_id: number }) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>№</TableCell>
                    <TableCell />
                    <TableCell>Наименование</TableCell>
                    <TableCell>Бренд</TableCell>
                    <TableCell>Цена</TableCell>
                    <TableCell>Отзывы</TableCell>
                    <TableCell>
                        <Tooltip title="Количество заказов за 30 дней">
                            <span>Заказы</span>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {products.map(
                    (
                        {
                            id,
                            name,
                            brand,
                            pics,
                            salePriceU,
                            priceU,
                            rating,
                            feedbacks,
                            siteBrandId,
                            sold30dQty,
                            sold30dAmount
                        },
                        index
                    ) => (
                        <TableRow key={id} positive={id === wb_item_id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                                <Avatar
                                    imgProps={{ loading: 'lazy' }}
                                    variant="rounded"
                                    src={matchWbPhotoUrl(id).preview}
                                />
                            </TableCell>
                            <TableCell>
                                <a
                                    href={`https://www.wildberries.ru/catalog/${id}/detail.aspx`}
                                    rel="noreferrer"
                                    target="_blank"
                                    title="Смотреть на сайте wildberries.ru"
                                >
                                    {id} {name}
                                </a>
                            </TableCell>
                            <TableCell>{brand}</TableCell>
                            <TableCell>
                                <Price price={Math.floor(priceU / 100)} priceWithSale={Math.floor(salePriceU / 100)} />
                            </TableCell>
                            <TableCell>
                                <Tooltip
                                    title={
                                        <Stack>
                                            <Rating max={5} precision={0.2} value={5} readOnly />
                                            Отзывов: {feedbacks}
                                        </Stack>
                                    }
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                                        <Rating max={1} precision={0.2} value={rating / 5} readOnly />
                                        {feedbacks}
                                    </Box>
                                </Tooltip>
                            </TableCell>
                            <TableCell align="center">
                                {index < 30 && <WbProductStat sold30dQty={sold30dQty} sold30dAmount={sold30dAmount} />}
                                {index >= 30 && (
                                    <Tooltip title="Отображаем статистику только для топ-30 товаров">
                                        <span>--</span>
                                    </Tooltip>
                                )}
                            </TableCell>
                        </TableRow>
                    )
                )}
            </TableBody>
        </Table>
    );
};

export default ProductsTableWB;
