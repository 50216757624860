import React from 'react';

import { WbAdsRate } from 'api/wbAdsRatesApi';

import Typography from '@mui/material/Typography';

import Table, { TableHead, TableRow, TableCell, TableBody } from 'ui/Table';
import WbItemAvatar from '../../Items/Product/WbItemAvatar';

const WbAdsRatesTable = ({ adsRates, loading }: { adsRates: WbAdsRate[]; loading: boolean }) => {
    return (
        <Table elevation={0} loading={loading}>
            <TableHead>
                <TableRow>
                    <TableCell>Место</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Артикул</TableCell>
                    <TableCell>Ставка</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {adsRates.map(({ wb_item_id, wbItem, cpm }, index) => (
                    <TableRow key={wb_item_id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                            <WbItemAvatar wb_item_id={wb_item_id} size={48} />
                        </TableCell>
                        <TableCell>
                            <a
                                href={`https://www.wildberries.ru/catalog/${wb_item_id}/detail.aspx`}
                                rel="noreferrer"
                                target="_blank"
                                title="Смотреть на сайте wildberries.ru"
                            >
                                <Typography variant="subtitle2">
                                    {wb_item_id} {wbItem.title}
                                </Typography>
                            </a>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2" style={{ whiteSpace: 'nowrap' }}>
                                {cpm.toLocaleString()} ₽
                            </Typography>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default WbAdsRatesTable;
