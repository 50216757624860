import fetchApi from 'common/fetchApi';
import { objectToGraphql } from 'common/graphql';
import {
    Product,
    ProductList,
    ProductsFilter,
    StoreIds,
    WB30dShortStat,
    WbItem,
    WBSimilarProduct
} from 'stores/productStore';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { WBSuggestion } from 'stores/prototypes/ListTransactionsStore.prototype';

const GQWbItemFields = `
    wb_item_id
    title
    description
    consist
    wb_brand_id
    
    ordersCount
    questionsCount
    feedbacksCount
    star
`;

export type StoreProductTitle = {
    store_item_id: number;
    photoUrl: string | null;
    title: string;
};

export const searchStoreProduct = async (store_item_id: number, store_id: StoreIds): Promise<StoreProductTitle> => {
    const graphql = `{
            searchStoreProduct(store_item_id: ${store_item_id}, store_id: ${store_id}) {
                store_item_id
                title
                photoUrl
            } 
          }`;

    const data = await fetchApi.getGQ<{ searchStoreProduct: StoreProductTitle }>(graphql);
    return data.searchStoreProduct;
};

export const createItem = async (product: Partial<Product>): Promise<number> => {
    const graphql = `mutation { 
          createProduct(product: ${objectToGraphql(product)})
        }`;

    const data = await fetchApi.postGQ<{ createProduct: number }>(graphql);
    return data.createProduct;
};

export const saveItem = async (product_id: number, product: Partial<Product>): Promise<number> => {
    const graphql = `mutation { 
          updateProduct(product_id: ${product_id}, productDiff: ${objectToGraphql(product)})
        }`;

    const data = await fetchApi.postGQ<{ updateProduct: number }>(graphql);
    return data.updateProduct;
};

export const GQWBItemShort = `
    ${GQWbItemFields}
    photos
    price {
        price
        priceDif
        priceWithSale
        sale
    }   
    brand {
        name
        brandUrl
        logoUrl    
    } 
`;

const GQOzonItemFields = `
    ozon_item_id
    title
    description
    itemUrl
    ozon_brand_id
    feedbacksCount
    star
    updateTime
`;

export const GQOzonItemShort = `
    ${GQOzonItemFields}
    photos
    price {
        price
        priceWithSale
        sale
    }   
    brand {
        name
        brandUrl
    } 
    aspects {
        index
        aspect
        aspectValue   
    }
`;

export const GQProductListFields = `
    product_id
    store_id
    store_item_id
    wbItem {
        ${GQWBItemShort}    
    }
    ozonItem {
        ${GQOzonItemShort}
    }
    createTime
`;

export const GQWbSizeFields = `
    wb_size_id
    isSoldOut
    sizeName
    sizeNameRus    
`;

const GQProductFields = `
    product_id
    store_id
    store_item_id
    wbItem {
        ${GQWBItemShort}
        brand {
            name
            brandUrl
            logoUrl    
        }
        tags
        options {
            property
            subProperty    
        }
        sizes {
            ${GQWbSizeFields}    
        }
        createTime
        updateTime
    }
    colors {
        wb_item_id
        colors {
            color_id
            color  
        }    
    }
    ozonItem {
        ${GQOzonItemShort}
    }
    enable
`;

export const fetchItem = async (product_id: number): Promise<Product> => {
    const graphql = `{ 
          fetchProduct(product_id: ${product_id}) {
            ${GQProductFields}
           }
        }`;

    const data = await fetchApi.getGQ<{ fetchProduct: Product }>(graphql);
    return data.fetchProduct;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    filter: Partial<ProductsFilter>,
    controller?: AbortController
): Promise<{ list: ProductList[]; count: number }> => {
    const graphql = `{
            fetchProductList(filter: ${objectToGraphql(filter)}, orderBy: "${orderBy}", sort: "${orderDirection}") {
                list {
                    ${GQProductListFields}
                }
                count
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchProductList: { list: ProductList[]; count: number } }>(
        graphql,
        controller
    );
    return data.fetchProductList;
};

export const refreshUpdateStoreProduct = async (product_id: number): Promise<boolean> => {
    const graphql = `{ 
          refreshUpdateStoreProduct(product_id: ${product_id})
        }`;

    const data = await fetchApi.postGQ<{ refreshUpdateStoreProduct: boolean }>(graphql);
    return data.refreshUpdateStoreProduct;
};

export type WBProductSuggestion = {
    id: number;
    root: number;
    kindId: number;
    subjectId: number;
    name: string;
    brand: string;
    brandId: number;
    siteBrandId: number;
    sale: number;
    priceU: number;
    salePriceU: number;
    pics: number;
    rating: number;
    feedbacks: number;
    colors: { name: string; id: number }[];
    sizes: { name: string; origName: string; rank: number; optionId: number }[];
    diffPrice: Boolean;

    sold30dQty: number | null;
    sold30dAmount: number | null;
};

const GQWBProductSuggestion = `
    id
    root
    kindId
    subjectId
    name
    brand
    brandId
    siteBrandId
    sale
    priceU
    salePriceU
    pics
    rating
    feedbacks
    diffPrice
    
    sold30dQty
    sold30dAmount
`;

export const fetchWbProductSuggestions = async (
    product_id: number,
    shardKey: string,
    query: string,
    filters: string
): Promise<{ list: WBProductSuggestion[]; count: number; foundNumber: number }> => {
    const graphql = `{
            fetchWbProductSuggestions(product_id: ${product_id}, shardKey: "${shardKey}", 
                query: "${encodeURIComponent(query)}", filters: "${encodeURIComponent(filters)}") {
                    list {
                        ${GQWBProductSuggestion}
                    }
                    count
                    foundNumber
            }
          }`;

    const data = await fetchApi.getGQ<{
        fetchWbProductSuggestions: { list: WBProductSuggestion[]; count: number; foundNumber: number };
    }>(graphql);
    return data.fetchWbProductSuggestions;
};

export const fetchWbProductSuggestionsNew = async (
    product_id: number,
    searchQuery: string
): Promise<{ list: WBProductSuggestion[]; count: number; foundNumber: number }> => {
    const graphql = `{
            fetchWbProductSuggestionsNew(product_id: ${product_id}, searchQuery: "${searchQuery}") {
                    list {
                        ${GQWBProductSuggestion}
                    }
                    count
                    foundNumber
            }
          }`;

    const data = await fetchApi.getGQ<{
        fetchWbProductSuggestionsNew: { list: WBProductSuggestion[]; count: number; foundNumber: number };
    }>(graphql);
    return data.fetchWbProductSuggestionsNew;
};

export type WBStatChart = {
    soldQty: number;
    soldAmount: number;
    availability: number;
    day: string;
};

export const fetchWBProductStatistics = async (product_id: number): Promise<WBStatChart[]> => {
    const graphql = `{
            fetchWBProductStatistics(product_id: ${product_id}) {
                soldQty
                soldAmount
                availability
                day
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchWBProductStatistics: WBStatChart[] }>(graphql);
    return data.fetchWBProductStatistics;
};

export const fetchWB30dShortStatistics = async (wb_item_id: number): Promise<WB30dShortStat> => {
    const graphql = `{
            fetchWB30dShortStatistics(wb_item_id: ${wb_item_id}) {
                sold30dQty
                sold30dAmount
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchWB30dShortStatistics: WB30dShortStat }>(graphql);
    return data.fetchWB30dShortStatistics;
};

export type WbItemFeedbacksPhotos = {
    fullSizeUri: string;
    minSizeUri: string;
};

export type WbItemFeedbacksAnswer = {
    text: string;
    createTime: number | null;
    updateTime: number | null;
    enable: boolean;
};

type WbUser = {
    country: string;
    name: string;
    hasPhoto: boolean;
};

type WbItemFeedbackInBase = {
    feedback_id: number;
    wb_item_id: number;
    wb_user_id: number;
    wb_feedback_id: string;

    text: string;
    color: string | null;
    size: string | null;
    matchingSize: 'ok' | 'smaller' | 'bigger' | null;

    votesPlus: number | null;
    votesMinus: number | null;

    star: 1 | 2 | 3 | 4 | 5;
    rank: number | null;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type WbItemFeedbackLike = {
    wb_user_id: number;
    helpfulness: 'plus' | 'minus';
    time: number;
};

export type WbItemFeedback = WbItemFeedbackInBase & {
    photos: WbItemFeedbacksPhotos[];
    answer: WbItemFeedbacksAnswer | null;
    wbUser: WbUser | null;
    likes: WbItemFeedbackLike[];
};

export type FeedbacksOrderBy = 'createTime' | 'star' | 'rank';

export const GQFeedbackFields = `
    feedback_id
    wb_item_id
    wb_user_id
    wb_feedback_id

    text
    color
    size
    matchingSize

    votesPlus
    votesMinus

    star
    rank

    photos {
        fullSizeUri
        minSizeUri   
    }
    wbUser {
        country
        name
        hasPhoto
    }
    answer {
        text
        createTime
        updateTime
        enable
    }
    likes {
        wb_user_id
        helpfulness
        time
    }

    createTime
    updateTime
    enable
`;

export const isFeedbackType = (objs: WbItemFeedback[] | WbSimilarFeedback[]): objs is WbItemFeedback[] => {
    return objs.length === 0 || 'feedback_id' in objs[0];
};

export const isFeedbackSimilarType = (objs: WbItemFeedback[] | WbSimilarFeedback[]): objs is WbSimilarFeedback[] => {
    return objs.length === 0 || 'wbItem' in objs[0];
};

export const fetchProductWbFeedbacks = async (
    product_id: number,
    orderBy: FeedbacksOrderBy,
    sort: OrderDirectionType,
    offset: number,
    limit: number
): Promise<WbItemFeedback[]> => {
    const graphql = `{
            fetchProductWbFeedbacks(product_id: ${product_id}, orderBy: "${orderBy}", sort: "${sort}", offset: ${offset}, limit: ${limit}) {
                ${GQFeedbackFields}    
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchProductWbFeedbacks: WbItemFeedback[] }>(graphql);
    return data.fetchProductWbFeedbacks;
};

export type WbSimilarFeedback = {
    feedback: WbItemFeedback;
    wbItem: WbItem;
};

export const fetchSimilarWbFeedbacks = async (
    product_id: number,
    orderBy: FeedbacksOrderBy,
    sort: OrderDirectionType,
    offset: number,
    limit: number
): Promise<WbSimilarFeedback[]> => {
    const graphql = `{
            fetchSimilarWbFeedbacks(product_id: ${product_id}, orderBy: "${orderBy}", sort: "${sort}", offset: ${offset}, limit: ${limit}) {
                feedback {
                    ${GQFeedbackFields}
                }   
                wbItem {
                    ${GQWBItemShort}
                } 
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchSimilarWbFeedbacks: WbSimilarFeedback[] }>(graphql);
    return data.fetchSimilarWbFeedbacks;
};

export const testWbApiKey = async (token: string, type: 'new' | 'x64'): Promise<number> => {
    const graphql = `mutation { 
          testWbApiKey(token: ${objectToGraphql(token)}, type: ${objectToGraphql(type)})
        }`;

    const data = await fetchApi.postGQ<{ testWbApiKey: number }>(graphql);
    return data.testWbApiKey;
};

export const changeItemWbTitle = async (product_id: number, title: string): Promise<boolean> => {
    const graphql = `mutation { 
          changeItemWbTitle(product_id: ${product_id}, title: ${objectToGraphql(title)})
        }`;

    const data = await fetchApi.postGQ<{ changeItemWbTitle: boolean }>(graphql);
    return data.changeItemWbTitle;
};

export const fetchSimilarProducts = async (wb_item_id: number): Promise<WBSimilarProduct[]> => {
    const graphql = `{
            fetchSimilarProducts(wb_item_id: ${wb_item_id}) {
                wb_item_id
                title
                brandName
                sold30dQty
                sold30dAmount  
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchSimilarProducts: WBSimilarProduct[] }>(graphql);
    return data.fetchSimilarProducts;
};

export const fetchWbSearchSuggestions = async (query: string, gender: string): Promise<WBSuggestion[]> => {
    const graphql = `{
            fetchWbSearchSuggestions(query: ${objectToGraphql(query)}, gender: "${gender}") {
                type
                name
                brand_name 
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchWbSearchSuggestions: WBSuggestion[] }>(graphql);
    return data.fetchWbSearchSuggestions;
};
