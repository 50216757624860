import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import ransomStore, { RansomType } from 'stores/ransomStore';
import productStore, { StoreIds } from 'stores/productStore';
import authStore from 'stores/authStore';

import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import TimesOneMobiledataIcon from '@mui/icons-material/TimesOneMobiledata';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';

import Checkbox from 'ui/Checkbox';
import { Message, MessageTitle } from 'ui/Message';
import Grid from 'ui/Grid';
import InfoPopup from 'ui/InfoPopup';

const RansomPreRunInfo = React.memo(() => (
    <InfoPopup size="large">
        <Typography variant="subtitle1">Прогон Аккаунта</Typography>
        <Typography variant="body2" sx={{ m: 1 }}>
            Создание дополнительных поведенческих действий пользователем перед покупкой позволяет полностью обойти
            алгоритм идентификации пользователя на МП.
        </Typography>
        <Typography variant="body2" sx={{ m: 1 }}>
            <b>За 20-30 минут</b> происходят события, характерные для покупки пользователя МП.
        </Typography>
        <ul>
            <li>Просмотр поисковых запросов</li>
            <li>Просмотр карточек товара по поисковым запросам</li>
            <li>Просмотр фотографий в карточках товара</li>
            <li>Просмотр вопросов и отзывов</li>
            <li>Добавления похожих товаров в корзину, сравнение похожих товаров</li>
            <li>Множество прочих поведенческих событий</li>
        </ul>
        <Typography variant="body2" sx={{ m: 1 }}>
            Все действия происходят в эмуляторе браузера. После создания выкупа, полный отчет можно просмотреть во
            вкладке "ВИДЕОЗАПИСЬ".
        </Typography>
        <Typography variant="subtitle2" sx={{ m: 1 }}>
            После прогона аккаунта и создания выкупа вам придет уведомления в телеграм с ссылкой на данный выкуп с
            qr-кодом или ссылкой на оплату.
        </Typography>
    </InfoPopup>
));

const RansomOneTimeInfo = React.memo(() => (
    <InfoPopup size="large">
        <Typography variant="subtitle1">Одноразовый аккаунт</Typography>
        <Typography variant="body2" sx={{ m: 1 }}>
            При использование данной функции выкуп будет осуществлен через аккаунт, на котором ранее не было ни одной
            покупки. И после этого выкупа аккаунт будет убран из оборота.
        </Typography>
        <Typography variant="body2" sx={{ m: 1 }}>
            При выкупе используются мобильные прокси с уникальным одноразовым ip-адресом через мобильные сети.
        </Typography>
    </InfoPopup>
));

const RansomAccountEditing = ({ ransom_id }: { ransom_id: number }) => {
    const { editingItem } = ransomStore.getItem(ransom_id);
    const { preRunAccount, oneTimeAccount, type, store_id } = editingItem;
    const { settings } = authStore;

    useEffect(() => {
        if (type === RansomType.Group || productStore.getCurrentStoreId() !== StoreIds.WB) {
            ransomStore.setEditingItem(ransom_id, { preRunAccount: false, oneTimeAccount: false });
        }
    }, [type, productStore.listFilter.store_id]);

    const handleTogglePreRun = () => {
        ransomStore.setEditingItem(ransom_id, { preRunAccount: !preRunAccount });
    };
    const handleToggleOneTime = () => {
        ransomStore.setEditingItem(ransom_id, { oneTimeAccount: !oneTimeAccount });
    };

    if (type === RansomType.Group) {
        return null;
    }

    return (
        <Grid.Column>
            <Message icon={<RecentActorsIcon />}>
                <Box sx={{ pt: 1 }}>
                    <Badge badgeContent="new" color="secondary">
                        <Box sx={{ pr: 3 }}>
                            <MessageTitle>Действия с аккаунтом</MessageTitle>
                        </Box>
                    </Badge>
                </Box>
                <Stack>
                    {productStore.getCurrentStoreId() === StoreIds.WB && (
                        <Checkbox
                            label={
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <DirectionsRunIcon />
                                    <div>Прогон аккаунта</div>
                                    <RansomPreRunInfo />
                                </Stack>
                            }
                            onChange={handleTogglePreRun}
                            checked={preRunAccount}
                            disabled={productStore.getCurrentStoreId() === StoreIds.WB && !settings['preRunAccount_wb']}
                        />
                    )}
                    <Checkbox
                        label={
                            <Stack direction="row" spacing={1} alignItems="center">
                                <TimesOneMobiledataIcon />
                                <div>Одноразовый аккаунт</div>
                                <RansomOneTimeInfo />
                            </Stack>
                        }
                        onChange={handleToggleOneTime}
                        checked={oneTimeAccount}
                        disabled={
                            productStore.getCurrentStoreId() === StoreIds.WB
                                ? !settings['oneTimeAccount_wb']
                                : !settings['oneTimeAccount_ozon']
                        }
                    />
                </Stack>
            </Message>
        </Grid.Column>
    );
};

export default observer(RansomAccountEditing);
