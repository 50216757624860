import React, { useState, useEffect, ReactNode } from 'react';
import { FilePondFile, FilePondInitialFile } from 'filepond';
import { observer } from 'mobx-react';

import { authHeaders } from 'common/fetchApi';
import deepCopy from 'common/deepCopy';

import serverConfig from 'config/server.config';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import reviewStore, { ReceiveFile } from 'stores/reviewStore';

import { FilePond, registerPlugin } from 'react-filepond';
import '../../../../../node_modules/filepond/dist/filepond.min.css';

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import '../../../../../node_modules/filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import Paper from '@mui/material/Paper';

registerPlugin(
    FilePondPluginImagePreview,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
    FilePondPluginFileValidateType
);

const ReviewUploadFiles = ({
    type,
    acceptedFileTypes,
    labelIdle,
    maxFiles
}: {
    type: 'photo' | 'video';
    acceptedFileTypes: string | string[];
    labelIdle: string;
    maxFiles: number;
}) => {
    const [files, setFiles] = useState<FilePondInitialFile[]>([]);

    const onLoadFile = (error: any, file: FilePondFile) => {
        if (error) {
            return;
        }
        const { serverId } = file;
        const { response }: { response: ReceiveFile } = JSON.parse(serverId);

        reviewStore.addTempFile(CREATING_ITEM_ID, response, type);
    };

    const onProcessFiles = () => {
        reviewStore.getItem(CREATING_ITEM_ID).loadingItem = false;
    };

    const startLoading = () => {
        reviewStore.getItem(CREATING_ITEM_ID).loadingItem = true;
    };

    const onUpdateFiles = (newFiles: any[]) => {
        const newFilenames: string[] = newFiles.map(({ file: { name } }) => name);
        const propertyName = type === 'photo' ? 'receivePhotos' : 'receiveVideos';
        let receiveFiles = reviewStore.getItem(CREATING_ITEM_ID).property[propertyName];

        if (receiveFiles) {
            const filtered = deepCopy(receiveFiles.filter(({ originalname }) => newFilenames.includes(originalname)));
            reviewStore.setProperty(CREATING_ITEM_ID, { [propertyName]: filtered });
            reviewStore.setEditingItem(CREATING_ITEM_ID, {
                [type === 'photo' ? 'reviewPhotos' : 'reviewVideos']: filtered.map(({ filename }) => filename)
            });
        }

        setFiles(newFiles);
    };

    return (
        <FilePond
            files={files}
            onupdatefiles={onUpdateFiles}
            allowMultiple={true}
            maxFiles={maxFiles}
            server={{
                url: serverConfig.photoUploadUrl,
                process: {
                    method: 'POST',
                    withCredentials: false,
                    headers: authHeaders(),
                    timeout: 60000,
                    url: '',
                    onerror: a => {
                        console.log(a);
                    }
                }
            }}
            name="files"
            onprocessfile={onLoadFile}
            onaddfile={startLoading}
            onprocessfiles={onProcessFiles}
            imageResizeTargetWidth={1920}
            labelIdle={labelIdle}
            labelInvalidField={`Поле содержит недопустимые файлы`}
            labelFileWaitingForSize={`Ожидание размера файла`}
            labelFileSizeNotAvailable={`Размер файла недопустим`}
            labelFileLoading={`Загрузка`}
            labelFileLoadError={`Ошибка при загрузке`}
            labelFileProcessing={`Отправка`}
            labelFileProcessingComplete={`Отправка завершена`}
            labelFileProcessingAborted={`Отправка прервана`}
            labelFileProcessingError={`Ошибка при отправке`}
            labelTapToCancel={`Нажмите для отмены`}
            labelTapToRetry={`Нажмите для повтора`}
            labelTapToUndo={`Нажмите для отката назад`}
            labelButtonRemoveItem={`Удалить`}
            labelButtonAbortItemLoad={`Прервать`}
            labelButtonRetryItemLoad={`Повторить`}
            labelButtonAbortItemProcessing={`Отменить`}
            labelButtonUndoItemProcessing={`Откатить`}
            labelButtonRetryItemProcessing={`Повторить`}
            labelButtonProcessItem={`Отправить`}
            maxParallelUploads={2}
            imageTransformOutputQuality={100}
            itemInsertLocation={'after'}
            acceptedFileTypes={typeof acceptedFileTypes === 'string' ? [acceptedFileTypes] : acceptedFileTypes}
            labelFileTypeNotAllowed="Неверный формат"
            fileValidateTypeLabelExpectedTypes=""
            imagePreviewMaxHeight={196}
        />
    );
};

export default observer(ReviewUploadFiles);
