import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import questionStore from 'stores/questionStore';
import favoriteStore from 'stores/favoriteStore';
import cartStore from 'stores/cartStore';
import brandStore from 'stores/brandStore';
import feedbackReactionStore from 'stores/feedbackReactionStore';

import InfoPopup from 'ui/InfoPopup';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

const QuestionSearchPopup = React.memo(() => {
    return (
        <InfoPopup size="large">
            <Typography variant="subtitle2">Поисковый запрос</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Мы создаем пользовательское поведение, при котором человек сначала ищет ваш товар, потом переходит на
                него из поиска.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Подберите ключевой запрос по которому вы хотите продвигать ваш товар вверх при поиске на Wildberries.
            </Typography>
            <Typography variant="caption">
                В строке используются подсказки, взятые напрямую с поисковой строки WB в реальном времени. Учитывайте
                их, чтобы подобрать подходящий ключевой запрос.
            </Typography>
        </InfoPopup>
    );
});

const WbSuggestionsField = ({
    item_id,
    store
}: {
    item_id: number;
    store:
        | typeof questionStore
        | typeof favoriteStore
        | typeof cartStore
        | typeof brandStore
        | typeof feedbackReactionStore;
}) => {
    const { wbSuggestions } = store;
    const { editingItem } = store.getItem(item_id);
    const { searchRequest, gender } = editingItem;

    const handleSearchRequest = (event: SyntheticEvent, searchRequest: string) => {
        store.setEditingItem(item_id, { searchRequest });
        store.suggestQuery = searchRequest;
        store.suggestGender = gender || 'female';
        store.debounceWbSuggestions();
    };

    return (
        <Autocomplete
            freeSolo
            value={searchRequest || ''}
            onInputChange={handleSearchRequest}
            disableClearable
            options={wbSuggestions}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Поисковый запрос"
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        endAdornment: (
                            <InputAdornment position="end">
                                <QuestionSearchPopup />
                            </InputAdornment>
                        )
                    }}
                    style={{ background: 'white' }}
                />
            )}
        />
    );
};

export default observer(WbSuggestionsField);
