import React from 'react';

import { ErrorMessage } from 'ui/Message';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const AutoAnswersDamage = () => {
    return (
        <Paper elevation={0} sx={{ p: 1, display: 'flex', gap: 2, flexDirection: 'column' }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
                В данном разделе вы можете подключить уведомления в Телеграм о новых отзывах и вопросах на ваши товары.
                Благодаря данной функции вы можете оперативно отвечать на обращения покупателей.
            </Typography>

            <ErrorMessage header="Данный раздел временно не работает">
                <p>
                    Wildberries изменил метод авторизации, и в данный момент мы не можем получать отзывы и вопросы на
                    ваши товары.
                </p>
                <p>Мы ведем работы над новым методом авторизации. Приносим извинения за доставленные неудобства.</p>
            </ErrorMessage>
        </Paper>
    );
};

export default AutoAnswersDamage;
