import React from 'react';

import Typography from '@mui/material/Typography';
import LinkIcon from '@mui/icons-material/Link';
import Button from 'ui/Button';

const TitleLinkOzon = ({ ozon_item_id, itemUrl }: { ozon_item_id: number; itemUrl: string }) => (
    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <span>
            Артикул: <b style={{ fontWeight: 500 }}>{ozon_item_id}</b>
        </span>
        <Button
            href={itemUrl}
            icon={<LinkIcon />}
            tooltip="Смотреть на ozon.ru"
            target="_blank"
            color="primary"
            size="small"
        />
    </Typography>
);

export default React.memo(TitleLinkOzon);
