import { action, makeObservable, observable } from 'mobx';
import ListStorePrototype, { DropdownType, OrderDirectionType } from './prototypes/ListStore.prototype';
import * as deliveryApi from 'api/deliveryApi';
import { Ransom } from './ransomStore';
import videoStore from './videoStore';
import authStore from './authStore';
import { StoreIds } from './productStore';
import { DeliveryOnMap } from 'api/deliveryApi';
import { CoordinatesCorner } from '../api/deliveryPointsApi';
import deliveryPointsStore from './deliveryPointsStore';

export type Delivery = {
    delivery_id: number;
    ransom_id: number;
    user_id: number;

    deliveryCode: string;
    deliveryQR: string;
    status: 1 | 2 | 3 | 4 | 5 | 6 | 100;
    errorMessage: string | null;

    ransom: Ransom;
    lastName: string | null;
    firstName: string | null;
    phone: string;
    statuses: { status: string; date: string }[];

    major_user_id: number;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type DeliveryFilter = {
    status: Delivery['status'] | null;
    store_id: StoreIds;
    product_id: number | null;
    wb_store_id: number | null;
    allUsers: boolean;
    enable: boolean;
};

class DeliveryStore extends ListStorePrototype<Delivery, Delivery, {}, DeliveryFilter> {
    constructor() {
        super('delivery_id', 'delivery', deliveryApi);
        this.orderBy = 'delivery_id';
        this.clearFilter();

        makeObservable(this);
    }

    listFilterClear = {
        status: null,
        store_id: StoreIds.WB,
        product_id: null,
        wb_store_id: null,
        allUsers: false,
        enable: true
    };

    @action
    async refreshUpdateStoreDelivery(delivery_id: number): Promise<void> {
        this.getItem(delivery_id).loadingItem = true;
        try {
            await deliveryApi.refreshUpdateStoreDelivery(delivery_id);
            await this.fetchItem(delivery_id);
        } catch (errors) {
            if (errors instanceof Array) {
                this.getItem(delivery_id).errors = errors;
            }
        } finally {
            videoStore.findWbVideo('delivery', delivery_id);
            this.getItem(delivery_id).loadingItem = false;
        }
    }

    changeFilter<T extends keyof DeliveryFilter>(what: T, value: DeliveryFilter[T]) {
        super.changeFilter(what, value);
        if (what !== 'wb_store_id') {
            this.fetchDeliveriesStoresOptions();
        }
    }

    clearFilter() {
        super.clearFilter();
        this.fetchDeliveriesStoresOptions();
    }

    async toggleDisableItem(delivery_id: number, enable: boolean) {
        await super.toggleDisableItem(delivery_id, enable);
        const { ransom } = this.getItem(delivery_id).item || {};
        this.fetchItem(delivery_id);
        if (ransom?.ransom_group_id) {
            this.fetchDeliveriesGroup(ransom.ransom_group_id);
        }

        authStore.pullCurrentUserApi();
    }

    @observable
    deliveriesGroups: Map<number, { loading: boolean; deliveries: Delivery[] }> = new Map();

    async fetchDeliveriesGroup(ransom_group_id: number) {
        if (!this.deliveriesGroups.has(ransom_group_id)) {
            this.deliveriesGroups.set(ransom_group_id, { loading: true, deliveries: [] });
        }
        const deliveries = await deliveryApi.fetchDeliveriesGroup(ransom_group_id);
        this.deliveriesGroups.set(ransom_group_id, { loading: false, deliveries });
    }

    getDeliveryGroup(ransom_group_id: number): { loading: boolean; deliveries: Delivery[] } {
        return this.deliveriesGroups.get(ransom_group_id) || { loading: true, deliveries: [] };
    }

    @observable
    deliveriesStoresOptions: DropdownType[] = [];
    @observable
    loadingDeliveriesStores = false;

    async fetchDeliveriesStoresOptions() {
        this.loadingDeliveriesStores = true;
        try {
            const deliveriesStores = await deliveryApi.fetchDeliveriesStoresOptions(this.listFilter);
            this.deliveriesStoresOptions = deliveriesStores.map(({ store_point_id, address }) => ({
                value: store_point_id,
                text: address
            }));
        } catch (err) {
        } finally {
            this.loadingDeliveriesStores = false;
        }
    }

    @observable
    suitableDeliveriesToReview: Delivery[] = [];
    @observable
    loadingSuitableDeliveriesToReview: boolean = true;
    @observable
    errorsSuitableDeliveriesToReview: string[] = [];

    resetSuitableDeliveries() {
        this.suitableDeliveriesToReview = [];
    }

    @action
    async fetchSuitableDeliveries(store_id: StoreIds): Promise<void> {
        this.loadingSuitableDeliveriesToReview = this.suitableDeliveriesToReview.length === 0;
        try {
            this.suitableDeliveriesToReview = await deliveryApi.fetchSuitableDeliveriesToReview(store_id);
        } catch (errors) {
            if (errors instanceof Array) {
                this.errorsSuitableDeliveriesToReview = errors;
            }
        } finally {
            this.loadingSuitableDeliveriesToReview = false;
        }
    }

    @observable
    dataSetType: 'list' | 'map' = 'list';

    @action
    toggleDataSetType = () => {
        this.dataSetType = this.dataSetType === 'list' ? 'map' : 'list';
    };

    async fetchList(orderBy?: string, orderDirection?: OrderDirectionType) {
        if (this.dataSetType === 'list') {
            super.fetchList(orderBy, orderDirection);
        } else {
            this.findDeliveriesOnMap();
        }
    }

    @observable
    mapDeliveriesList: DeliveryOnMap[] = [];

    @action
    async findDeliveriesOnMap(): Promise<void> {
        const {
            _northEast,
            _southWest
        }: {
            _northEast: CoordinatesCorner;
            _southWest: CoordinatesCorner;
        } = deliveryPointsStore.searchMap.getBounds();

        const coordinates = { bottomLeft: _southWest, topRight: _northEast };
        const { status, store_id, allUsers } = this.listFilter;
        if (this.fetchListAC) {
            this.fetchListAC.abort();
        }
        const controller = new AbortController();
        // присваеваем через таймаут, чтобы в catch прошлый вызов (отмененный) перехватить
        if (controller) {
            window.setTimeout(() => {
                this.fetchListAC = controller;
            }, 0);
        }
        try {
            this.listErrors = [];
            this.loadingList = true;
            this.mapDeliveriesList = await deliveryApi.findDeliveriesOnMap(
                { status, store_id, allUsers },
                coordinates,
                controller
            );
        } catch (errors) {
            if (this.fetchListAC && this.fetchListAC.signal.aborted) {
                return;
            }
            console.log('errors', errors);

            if (errors instanceof Array) {
                this.listErrors = errors;
            } else if (errors && typeof errors === 'object') {
                // @ts-ignore
                this.listErrors = [String(errors?.message)];
            }
        } finally {
            this.fetchListAC = null;
            this.loadingList = false;
        }
    }
}

export default new DeliveryStore();
