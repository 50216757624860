import React from 'react';

import { RansomStatus } from 'stores/ransomStore';
import Chip from '@mui/material/Chip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import Time from 'ui/Time';

export const RansomStatusLabel = ({
    status,
    enable,
    planningExecuteTime
}: {
    status: RansomStatus;
    enable: boolean;
    planningExecuteTime?: number | null;
}) => {
    if (!enable) {
        return <Chip label="Отменен" color="default" sx={{ cursor: 'pointer' }} />;
    }
    switch (status) {
        case RansomStatus.Creating:
            return <Chip label="Создается выкуп" color="info" sx={{ cursor: 'pointer' }} />;
        case RansomStatus.WaitingForPayment:
            return <Chip label="Ожидает оплату" color="warning" sx={{ cursor: 'pointer' }} />;
        case RansomStatus.Delivery:
            return <Chip label="Доставка" color="success" sx={{ cursor: 'pointer' }} />;
        case RansomStatus.CheckingPayment:
            return <Chip label="Проверка оплаты" color="primary" sx={{ cursor: 'pointer' }} />;
        case RansomStatus.WaitingForExecution:
            return (
                <Chip
                    icon={<AccessTimeIcon />}
                    label={planningExecuteTime ? <Time time={planningExecuteTime || 0} /> : 'Ожидает'}
                    color="default"
                    sx={{ cursor: 'pointer' }}
                />
            );
        case RansomStatus.Error:
            return <Chip label="Ошибка" color="error" sx={{ cursor: 'pointer' }} />;
        case RansomStatus.ErrorExpiredPayment:
            return <Chip label="Просрочен" color="error" sx={{ cursor: 'pointer' }} />;
        default:
            const error: never = status;
            throw new Error(error);
    }
};

export default RansomStatusLabel;
