import React, { SyntheticEvent } from 'react';
import commonStore from 'stores/commonStore';
import * as userApi from 'api/userApi';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const FFDeliveryMPToggle = ({ store_id, onChange }: { store_id: number; onChange: (store_id: number) => void }) => {
    const { isMobile } = commonStore;
    let imgWidths = isMobile ? 40 : 120;

    const handleChangeMP = (event: SyntheticEvent, value: number) => {
        onChange(value);
        userApi.sendUserAction({ changeFboMP: value });
    };

    return (
        <ToggleButtonGroup
            size="small"
            color="secondary"
            value={store_id}
            exclusive
            onChange={handleChangeMP}
            fullWidth
        >
            <ToggleButton value={1}>
                <img width={imgWidths} src={isMobile ? '/static/ff/wb_min.png' : '/static/ff/wb_big.png'} />
            </ToggleButton>
            <ToggleButton value={2}>
                <img width={imgWidths} src={isMobile ? '/static/ff/ozon_min.png' : '/static/ff/ozon_big.png'} />
            </ToggleButton>
            <ToggleButton value={3}>
                <img width={imgWidths} src={isMobile ? '/static/ff/sbermega_min.png' : '/static/ff/sbermega_big.png'} />
            </ToggleButton>
            <ToggleButton value={4}>
                <img width={imgWidths} src={isMobile ? '/static/ff/ya_min.png' : '/static/ff/ya_market_big.svg'} />
            </ToggleButton>
            <ToggleButton value={5}>
                <img width={imgWidths} src={isMobile ? '/static/ff/avito_min.png' : '/static/ff/avito_big.png'} />
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default FFDeliveryMPToggle;
