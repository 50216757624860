import React from 'react';
import { observer } from 'mobx-react';

import ransomStore from 'stores/ransomStore';

import Paper from '@mui/material/Paper';

import { WarningMessage, InfoMessage } from 'ui/Message';
import Button from 'ui/Button';

import RansomSmartsItems from './RansomSmarts/RansomSmartsItems';
import { useToggleModal } from 'ui/Modal';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const hideRansomMessageKey = 'hideRansomMessage';

const RansomSmartInfoBlock = () => {
    const [showInfo, toggleInfo] = useToggleModal();
    const closedInfo = localStorage.getItem(hideRansomMessageKey);

    if (closedInfo) {
        return null;
    }

    const onClose = () => {
        localStorage.setItem(hideRansomMessageKey, 'true');
    };

    return (
        <InfoMessage
            header={
                <Button size="small" color="inherit" onClick={toggleInfo}>
                    Что такое smart выкупы?
                </Button>
            }
            enableClose
            onClose={onClose}
            sx={{ mb: 1 }}
        >
            {showInfo && (
                <Stack spacing={1}>
                    <Typography variant="body2">
                        В smart выкупах вы можете создать неограниченное количество выкупов на выбранный товар, с
                        заданными фильтрами поиска и выбранными товарами конкурентов. В каждом smart выкупе вы можете
                        установить время, когда наш бот должен его создать, а также выбрать нужное ПВЗ. В установленное
                        вами время бот создаст выкуп и пришлет вам на него ссылку в Telegram, по которой вы сможете
                        оплатить товар.
                    </Typography>
                    <Typography variant="body2">
                        Таким образом, вы сэкономите время, не создавая выкупы каждый раз заново, устанавливая фильтры,
                        товары конкурентов и дожидаясь получения qr-кода/ссылки на оплату.
                    </Typography>
                    <Typography variant="body2">
                        После получения QR-кода/Ссылки на оплату вам будет доступно видео-подтверждение создания выкупа.
                    </Typography>
                </Stack>
            )}
        </InfoMessage>
    );
};

const RansomSmartsItemsContainer = ({ ransom_id }: { ransom_id: number }) => {
    const { editingItem } = ransomStore.getItem(ransom_id);
    const { product_id } = editingItem;

    return (
        <>
            <RansomSmartInfoBlock />
            <Paper sx={{ p: 2 }}>
                {!product_id ? (
                    <WarningMessage header="Товар не выбран">
                        Выберите товар в Выкупе, чтобы здесь появились smart выкупы
                    </WarningMessage>
                ) : (
                    <RansomSmartsItems ransom_id={ransom_id} />
                )}
            </Paper>
        </>
    );
};

export default observer(RansomSmartsItemsContainer);
