import React from 'react';

import commonStore from 'stores/commonStore';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import Button from 'ui/Button';

// import { SuccessMessage } from 'ui/Message';

const SalesFinder = () => {
    const { isMobile } = commonStore;

    return (
        <Box sx={{ overflowY: 'auto' }}>
            <Container maxWidth="md">
                <Paper elevation={1} sx={{ padding: '1rem 2rem' }}>
                    <Stack spacing={3}>
                        <Box
                            sx={{
                                backgroundImage: `url('/static/photoseo/sflogo.png')`,
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                height: isMobile ? 60 : 100,
                                width: '100%'
                            }}
                        />
                        <Typography variant="h4" textAlign="center">
                            Аналитика маркетплейсов для увеличения ваших продаж
                        </Typography>
                        <Typography variant="subtitle1">
                            Найдите самые прибыльные товары и ниши, анализируйте стратегию продаж конкурентов,
                            оптимизируйте ассортимент и поставки вашего магазина
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 200 }}>
                            Для всех пользователей TopStore.pro скидка 20% на любой тариф и срок подписки{' '}
                            <a
                                href="https://salesfinder.ru/promo?code=TOPSTORE&invite=2931"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Перейти в сервис
                            </a>
                        </Typography>
                        <Typography variant="h5" sx={{ pt: 2 }}>
                            Анализ ниш, товаров и продаж конкурентов
                        </Typography>
                        <Typography>
                            Правильный выбор категории и товара, исследование трендов продаж, анализ конкурентов в нише
                            - важные процессы для построения высоких стабильных продаж на маркетплейсе.
                        </Typography>
                        <img alt="" src="/static/photoseo/sf1.png" style={{ width: '100%' }} />
                        <Typography variant="h6">Возможности модуля аналитики</Typography>
                        <Typography>
                            <ul style={{ marginTop: 0 }}>
                                <li>Данные по OZON и Wildberries</li>
                                <li>Ежедневное обновление данных по 100+ млн товаров всех категорий</li>
                                <li>
                                    Анализ продаж, остатков, выручки и других метрик в любых срезах: по категориям,
                                    брендам и селлерам
                                </li>
                                <li>Анализ видимости в категориях</li>
                                <li>Анализ позиций по поисковым запросам</li>
                                <li>Анализ продаж товаров по размерам и складам</li>
                                <li>Удобные дэшборды, фильтры и настройки отчетов</li>
                                <li>Рейтинги ниш, брендов и селлеров</li>
                                <li>Поиск новинок и трендовых товаров</li>
                            </ul>
                        </Typography>

                        <Typography variant="h5" sx={{ pt: 2 }}>
                            Ежедневный мониторинг позиций
                        </Typography>
                        <Typography>
                            Поисковый трафик - важнейший источник продаж на маркетплейсе. Регулярная аналитика видимости
                            ваших товаров поможет оптимизировать правильный контент карточек для ранжирования по
                            наиболее целевым продающим запросам.
                        </Typography>
                        <img alt="" src="/static/photoseo/sf2.png" style={{ width: '100%' }} />
                        <Typography variant="h6">Главное о мониторинге позиций</Typography>
                        <Typography>
                            <ul style={{ marginTop: 0 }}>
                                <li>Мониторинг работает для WB и OZON</li>
                                <li>Ежедневное обновление данных</li>
                                <li>Глубина проверки - 1000 позиций</li>
                                <li>Удобный дэшборд для контроля динамики проекта</li>
                                <li>Отчеты с настраиваемым видом и экспортом данных</li>
                            </ul>
                        </Typography>

                        <Typography variant="h5" sx={{ pt: 2 }}>
                            Подбор SEO запросов
                        </Typography>
                        <Typography>
                            Инструмент поможет найти наиболее эффективные ключевые слова для контента карточек на
                            Wildberries и OZON, что позволит улучшить видимость ваших товаров среди покупателей
                            маркетплейсов и увеличить продажи.
                        </Typography>
                        <img alt="" src="/static/photoseo/sf4.png" style={{ width: '100%' }} />
                        <Typography>
                            <ul style={{ marginTop: 0 }}>
                                <li>Подбор запросов для WB и OZON</li>
                                <li>Актуальные базы запросов с раздельными частотностями</li>
                                <li>Подбор запросов по фразам / товарам / категориям</li>
                            </ul>
                        </Typography>
                        <Button
                            target="_blank"
                            variant="contained"
                            color="secondary"
                            href="https://salesfinder.ru/promo?code=TOPSTORE&invite=2931"
                        >
                            Зарегистрироваться в salesfinder
                        </Button>
                        <Typography variant="h6" sx={{ fontWeight: 200 }}>
                            Для всех пользователей TopStore.pro скидка 20% на любой тариф и срок подписки
                        </Typography>
                    </Stack>
                </Paper>
            </Container>
        </Box>
    );
};

export default SalesFinder;
