import { observable, makeObservable, action } from 'mobx';
import * as videoApi from '../api/videoApi';

class VideoStore {
    @observable
    videos: Map<string, string | null> = new Map();

    constructor() {
        makeObservable(this);
    }

    @action
    findWbVideo = async (
        item_type: 'ransom' | 'delivery' | 'question' | 'review' | 'brand',
        item_id: number,
        otherType: number | null = null
    ) => {
        const key = `${item_type}_${item_id}_${otherType}`;
        this.videos.set(key, null);

        const videoId = await videoApi.findWbVideo(item_type, item_id, otherType);

        this.videos.set(key, videoId);
    };

    getVideoId = (
        item_type: 'ransom' | 'delivery' | 'question' | 'review' | 'brand',
        item_id: number,
        otherType: number | null = null
    ): string | null => {
        const key = `${item_type}_${item_id}_${otherType}`;
        return this.videos.get(key) || null;
    };
}

export default new VideoStore();
