import React, { useState, MouseEvent } from 'react';
import { observer } from 'mobx-react';

import productStore, { StoreIds } from 'stores/productStore';

import Button from 'ui/Button';
import Time from 'ui/Time';
import Divider from 'ui/Divider';
import Confirm from 'ui/Confirm';
import { useToggleModal } from 'ui/Modal';

import CachedIcon from '@mui/icons-material/Cached';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ProductApiModal from './ProductApiModal';

const MIN_UPDATE_TIME_SEC = 3600;

const ProductItemMenu = ({ product_id }: { product_id: number }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showEditModal, toggleEditModal] = useToggleModal();

    const handleOpenDropdown = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const onClose = () => {
        setAnchorEl(null);
    };

    const [showDel, toggleDel] = useToggleModal();
    const currentTime = Math.floor(Date.now() / 1000);

    const refresStoreTime = () => {
        productStore.refreshUpdateStoreProduct(product_id);
    };

    const { item: product } = productStore.getItem(product_id);
    if (!product) {
        return null;
    }

    const { enable, store_id } = product;
    const { updateTime, title } = productStore.calcShortProduct(product);

    const toggleDisableProduct = async (): Promise<void> => {
        await productStore.toggleDisableItem(product_id, !enable);
        productStore.fetchList();
        toggleDel();
    };

    return (
        <>
            <Button icon={<MoreVertIcon fontSize="large" />} onClick={handleOpenDropdown} />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} keepMounted onClose={onClose} onClick={onClose}>
                <MenuItem disabled>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        Обновлено
                        <Typography variant="caption">
                            <Time time={updateTime} />
                        </Typography>
                    </Box>
                </MenuItem>
                <MenuItem onClick={refresStoreTime} disabled={currentTime - updateTime < MIN_UPDATE_TIME_SEC}>
                    <ListItemIcon>
                        <CachedIcon fontSize="small" />
                    </ListItemIcon>
                    Обновить с {store_id === StoreIds.WB ? 'WB' : 'Ozon'}
                </MenuItem>
                {/*{store_id === StoreIds.WB && (*/}
                {/*    <MenuItem onClick={toggleEditModal}>*/}
                {/*        <ListItemIcon>*/}
                {/*            <SettingsIcon fontSize="small" />*/}
                {/*        </ListItemIcon>*/}
                {/*        Поменять наименование на WB*/}
                {/*    </MenuItem>*/}
                {/*)}*/}
                <Divider />
                <MenuItem onClick={toggleDel}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    {enable ? `Убрать в архив` : `Вернуть из архива`}
                </MenuItem>
            </Menu>
            {showEditModal && <ProductApiModal product_id={product_id} onClose={toggleEditModal} title={title} />}
            {showDel && (
                <Confirm
                    content={
                        enable
                            ? `Продукт будет убран в архив в вашем личном кабинете. Это никак не повлияет на его размещение на сайте ${
                                  store_id === StoreIds.WB ? 'Wildberries' : 'Ozon'
                              }`
                            : ''
                    }
                    header={enable ? 'Убрать продукт в Архив' : 'Вернуть из архива'}
                    confirmButton={enable ? 'В архив' : 'Вернуть из архива'}
                    onConfirm={toggleDisableProduct}
                    onCancel={toggleDel}
                />
            )}
        </>
    );
};

export default observer(ProductItemMenu);
