import React, { Component, SyntheticEvent } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cs from 'classnames';
import locationHistory, { HistoryLocation } from './locationHistory';
import isEqual from './common/isEqual';

import Product from 'components/Items/Product';
import Ransom from 'components/Items/Ransom';
import User from 'components/Items/User';
import Delivery from 'components/Items/Delivery';
import Question from 'components/Items/Question';
import Brand from 'components/Items/Brand';
import Review from 'components/Items/Review';
import ProductsList from 'components/List/Products';
import RansomsList from 'components/List/Ransoms';
import Reviews from 'components/List/Reviews';
import Deliveries from 'components/List/Deliveries';
import Questions from 'components/List/Questions';
import Carts from 'components/List/Carts';
import Wishlists from 'components/List/Wishlists';
import Users from 'components/List/Users';
import Favorites from 'components/List/Favorites';
import Brands from 'components/List/Brands';
import ReferralLinks from 'components/List/ReferralLinks';
import FeedbacksReactions from 'components/List/FeedbacksReactions';
import Api from 'components/List/Api';
import AutoAnswers from 'components/List/AutoAnswers';
import AutoAnswer from 'components/Items/AutoAnswer';
// import UnderConstruction from 'components/List/UnderConstruction';
import SeoConsult from 'components/List/SeoConsult';
import KeysRansoms from 'components/List/KeysRansoms';
import YandexDirect from 'components/List/YandexDirect';
import SeoInfographics from 'components/List/SeoInfographics';
import SeoPhotos from 'components/List/SeoPhotos';
import SeoFulfillment from 'components/List/SeoFulfillment';
import FFPricePage from 'components/List/SeoFulfillment/FFPricePage';
import FFContactsPage from 'components/List/SeoFulfillment/FFContactsPage';
import WbAdsRates from 'components/List/WbAdsRates';
import SeoControl from 'components/List/SeoControl';
import SalesFinder from 'components/List/SalesFinder';

import Drawer from '@mui/material/Drawer';
import deepCopy from './common/deepCopy';

const preventEvent = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
};

const SidebarSwitch = ({ location, fullSize }: { location?: HistoryLocation | null; fullSize: boolean }) => {
    const SidebarContent = (
        <div>
            {location && (
                <Switch location={location}>
                    <Route exact path="/products/list/detail/:item_id/:tabName" component={Product} />
                    <Route exact path="/products/list/detail/:item_id" component={Product} />
                    <Route exact path="/ransoms/list/detail/:item_id/:tabName" component={Ransom} />
                    <Route exact path="/ransoms/list/detail/:item_id" component={Ransom} />
                    <Route exact path="/deliveries/list/detail/:item_id/:tabName" component={Delivery} />
                    <Route exact path="/deliveries/list/detail/:item_id" component={Delivery} />
                    <Route exact path="/questions/list/detail/:item_id/:tabName" component={Question} />
                    <Route exact path="/questions/list/detail/:item_id" component={Question} />
                    <Route exact path="/reviews/list/detail/:item_id/:tabName" component={Review} />
                    <Route exact path="/reviews/list/detail/:item_id" component={Review} />
                    <Route exact path="/brands/list/detail/:item_id/:tabName" component={Brand} />
                    <Route exact path="/brands/list/detail/:item_id" component={Brand} />
                    <Route exact path="/users/list/detail/:item_id/:tabName" component={User} />
                    <Route exact path="/users/list/detail/:item_id" component={User} />
                    <Route exact path="/api/answers/detail/:item_id/:tabName" component={AutoAnswer} />
                    <Route exact path="/api/answers/detail/:item_id" component={AutoAnswer} />
                </Switch>
            )}
        </div>
    );

    // if (commonStore.isMobile) {
    //     // Без onScroll={preventEvent} не работает на мобилках кнопка с открытием комментов всплывающая. Она не фиксируется на месте в новом окне
    //     return <span onScroll={preventEvent}>{SidebarContent}</span>;
    // }

    return (
        <span onScroll={preventEvent}>
            <PerfectScrollbar>{SidebarContent}</PerfectScrollbar>
        </span>
    );
};

const MainSwitch = ({ location }: { location?: HistoryLocation }) => (
    <Switch location={location}>
        <Route exact path="/" component={ProductsList} />
        <Route path="/products/list" component={ProductsList} />
        <Route path="/ransoms/list" component={RansomsList} />
        <Route path="/deliveries/list" component={Deliveries} />
        <Route path="/reviews/list" component={Reviews} />
        <Route path="/questions/list" component={Questions} />
        <Route path="/favorites/list" component={Favorites} />
        <Route path="/carts/list" component={Carts} />
        <Route path="/wishlists/list" component={Wishlists} />
        <Route path="/brands/list" component={Brands} />
        <Route path="/users/list" component={Users} />
        <Route path="/seo/consult" component={SeoConsult} />
        <Route path="/seo/infographics" component={SeoInfographics} />
        <Route path="/seo/photos" component={SeoPhotos} />
        <Route path="/seo/control" component={SeoControl} />
        <Route path="/seo/salesfinder" component={SalesFinder} />
        <Route path="/keys/ransoms" component={KeysRansoms} />
        <Route path="/yandex/direct" component={YandexDirect} />
        <Route path="/referrals/statistics" component={ReferralLinks} />
        <Route path="/feedbacksReactions/list" component={FeedbacksReactions} />
        <Route path="/api/list" component={Api} />
        <Route exact path="/api/answers" component={AutoAnswers} />
        <Route exact path="/ads/rates" component={WbAdsRates} />
        <Route exact path="/fulfillment/about" component={SeoFulfillment} />
        <Route exact path="/fulfillment/price" component={FFPricePage} />
        <Route exact path="/fulfillment/contacts" component={FFContactsPage} />
    </Switch>
);

// const SidebarSwitchModal = ({ visible, color, children }) => (
//     <Sidebar
//         as={Segment}
//         animation={commonStore.isMobile ? 'push' : 'overlay'}
//         width="very wide"
//         direction="right"
//         visible={visible}
//         basic
//         color={color}
//         className="crm-List__sidebar_modal"
//     >
//         {children}
//     </Sidebar>
// );

type MainRouterProps = {
    location: HistoryLocation;
    fullSize: boolean;
};

type MainRouterState = {
    isSidebar: boolean;
    switchFromTab: boolean;
    pathname: string;
    isError: string | null;
};

let previousLocations: HistoryLocation[] = [];
let previousLocations2: HistoryLocation[] = [];

// @ts-ignore
@withRouter
class MainRouter extends Component<MainRouterProps, MainRouterState> {
    constructor(props: MainRouterProps) {
        super(props);

        const { pathname } = document.location;
        // TODO: надо понять, когда открывать модальное окно
        const isSidebar = (pathname.replace(/\/$/, '').match(/\//g) || []).length >= 3;

        this.state = {
            isSidebar,
            switchFromTab: false,
            isError: null,
            pathname
        };

        if (isSidebar) {
            // При первом заходе с модальным окном, меняем историю, чтобы работала кнопка "назад" (закрыть) окно
            locationHistory.replace(isSidebar ? '/' + pathname.split('/').slice(1, 3).join('/') : '/');
            previousLocations.push(deepCopy(locationHistory.location));

            locationHistory.push(pathname, {
                modal: isSidebar
            });
            props.location.state = { modal: true };

            previousLocations.push({ ...locationHistory.location });
        } else {
            previousLocations = [deepCopy(locationHistory.location)];
        }
    }

    toggleVisibility = () => {
        if (this.state.isSidebar) {
            locationHistory.goBack();
        }
    };

    static getDerivedStateFromProps(nextProps: MainRouterProps, prevState: MainRouterState): Partial<MainRouterState> {
        // Skip 2 attempts, because first attempt calls after constructor, second after componentDidMount
        const {
            location: { state, pathname }
        } = nextProps;

        const isSidebar = Boolean(state?.modal);
        const switchFromTab = Boolean(state?.switchFromTab);

        // Тут какая-то лютая магия, пиздец! Массив previousLocations все время растет на один, надо снимать его копию
        previousLocations2 = deepCopy(previousLocations);

        return { ...prevState, isSidebar, switchFromTab, pathname };
    }

    shouldComponentUpdate(nextProps: MainRouterProps, nextState: MainRouterState) {
        if (nextProps.fullSize !== this.props.fullSize) {
            return true;
        } else if (isEqual(nextState, this.state)) {
            return false;
        }

        const { switchFromTab } = nextState;
        const { location } = nextProps;

        previousLocations = [];
        // Вот тут восстанавливаем копию массива, тк push и [...previousLocations] увеличивают массив на один
        // Возможно, тут проблема с Proxy mobx или роутера, но где массив previousLocations к ним подключается - не понятно
        for (let i = 0; i < previousLocations2.length; i++) {
            previousLocations.push(previousLocations2[i]);
        }

        if (locationHistory.action === 'PUSH' && !switchFromTab) {
            previousLocations.push(deepCopy(location));
        } else if (locationHistory.action === 'POP') {
            previousLocations.pop();
        } else if (locationHistory.action === 'REPLACE' || switchFromTab) {
            previousLocations[previousLocations.length - 1] = deepCopy(location);
        }

        return true;
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.log('componentDidCatch', error);
        console.log('errorInfo', errorInfo);
        this.setState({ isError: error.message });
    }

    render() {
        // const { isSidebar, switchFromTab, isError } = this.state;
        const { isSidebar, isError } = this.state;
        const { location, fullSize } = this.props;

        if (isError) {
            return null; //<GlobalError error={isError} />;
        }

        let subSidebar = false;
        const prevLoc = previousLocations[previousLocations.length - 2];

        if (isSidebar && prevLoc?.state?.modal) {
            subSidebar = true;
        }

        let lastNoneModalLocation;
        let firstModalLocation: HistoryLocation | null = null;

        if (isSidebar) {
            const reverseLocation = [...previousLocations].reverse();
            const foundIndex = reverseLocation.findIndex(loc => !loc.state || !loc.state.modal);
            lastNoneModalLocation = reverseLocation[foundIndex];
            firstModalLocation = reverseLocation[foundIndex - 1];
        } else {
            lastNoneModalLocation = location;
        }

        // return (
        //     <Fragment>
        //         <Sidebar.Pushable>
        //             <SidebarSwitchModal visible={isSidebar} color="blue">
        //                 {isSidebar && (
        //                     <Sidebar.Pushable>
        //                         <SidebarSwitchModal visible={subSidebar} color="red">
        //                             {subSidebar && <SidebarSwitch fullSize={fullSize} />}
        //                         </SidebarSwitchModal>
        //                         <Sidebar.Pusher
        //                             dimmed={subSidebar}
        //                             onClick={subSidebar ? this.toggleVisibility : null}
        //                         >
        //                             <SidebarSwitch fullSize={fullSize} location={firstModalLocation} />
        //                         </Sidebar.Pusher>
        //                     </Sidebar.Pushable>
        //                 )}
        //             </SidebarSwitchModal>
        //
        //             <Sidebar.Pusher
        //                 dimmed={isSidebar}
        //                 onClick={isSidebar ? this.toggleVisibility : null}
        //                 className="crm-List__sidebar_pusher"
        //             >
        //                 <MainSwitch location={lastNoneModalLocation} />
        //             </Sidebar.Pusher>
        //         </Sidebar.Pushable>
        //     </Fragment>
        // );

        return (
            <>
                <Drawer
                    anchor="right"
                    open={isSidebar}
                    onClose={this.toggleVisibility}
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: 900,
                            maxWidth: '100vw',
                            backgroundColor: '#eef0f1'
                        }
                    }}
                >
                    <Drawer
                        anchor="right"
                        open={subSidebar}
                        onClose={this.toggleVisibility}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: 800,
                                maxWidth: '100vw',
                                backgroundColor: '#eef0f1'
                            }
                        }}
                    >
                        {subSidebar && <SidebarSwitch fullSize={fullSize} location={location} />}
                    </Drawer>
                    <SidebarSwitch fullSize={fullSize} location={firstModalLocation} />
                </Drawer>
                <MainSwitch location={lastNoneModalLocation} />
            </>
        );
    }
}

export default MainRouter;
