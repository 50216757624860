import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import wbCardStore from 'stores/wbApi/wbCardStore';
import commonStore from 'stores/commonStore';

import Modal, { ModalContent } from 'ui/Modal';
import Progress from 'ui/Progress';
import ListError from 'ui/ListError';

import CardApiConfiguration from './CardApiConfiguration';

const CardApiModal = ({ wb_item_id, onClose }: { wb_item_id: number; onClose: () => void }) => {
    useMemo(() => {
        wbCardStore.fetchItem(wb_item_id);
    }, [wb_item_id]);

    const { errors, loadingItem, item } = wbCardStore.getItem(wb_item_id);

    return (
        <Modal maxWidth="md" onClose={onClose} header="Редактирование карточки" fullScreen={commonStore.isMobile}>
            <ModalContent>
                <Progress show={loadingItem} />
                <ListError errors={errors} />
                {item && <CardApiConfiguration configuration={item.configuration} initCard={item.card} />}
            </ModalContent>
        </Modal>
    );
};

export default observer(CardApiModal);
