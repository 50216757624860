import React from 'react';

import { WbSize } from 'stores/productStore';

import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import Divider from 'ui/Divider';

const ProductShowMode = ({ sizes }: { sizes: WbSize[] }) => {
    if (sizes.length < 1) {
        return null;
    }

    return (
        <>
            <Typography variant="subtitle1" mt={1}>
                Размеры
            </Typography>
            {sizes.map(({ wb_size_id, sizeName, isSoldOut }) => (
                <Chip
                    style={{ margin: '0.25rem' }}
                    key={wb_size_id}
                    label={sizeName}
                    variant={isSoldOut ? 'filled' : 'outlined'}
                />
            ))}
            <Divider />
        </>
    );
};

export default ProductShowMode;
