import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import * as marketguruApi from 'api/marketguruApi';

import Grid from 'ui/Grid';
import Button from 'ui/Button';
import { SuccessMessage } from 'ui/Message';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListError from '../../ui/ListError';

let onMessage: (event: { data: { operation: string; token: string } }) => void;

const openMG = () => {
    window.postMessage({
        operation: 'openMarketGuru'
    });
};

const getMGToken = () => {
    window.postMessage({
        operation: 'getMGToken'
    });
};

const AddMarketGuru = ({ onClose }: { onClose: () => void }) => {
    const [mgToken, setMgToken] = useState<string | null>(null);
    const [creating, setCreating] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    onMessage = ({ data }) => {
        console.log('onmessage', data);
        if (data.operation === 'mgAuthToken') {
            setMgToken(data.token);
        }
    };

    useEffect(() => {
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, []);

    const handleSave = async () => {
        if (!mgToken) {
            return;
        }
        try {
            setCreating(true);
            if (await marketguruApi.createItem(mgToken)) {
                onClose();
            }
        } catch (errors) {
            if (errors instanceof Array) {
                setErrors(errors);
            }
        } finally {
            setCreating(false);
        }
    };

    return (
        <Grid>
            <Grid.Column>
                <Button variant="text" color="inherit" startIcon={<ArrowBackIcon />} onClick={onClose}>
                    назад
                </Button>
            </Grid.Column>
            <Grid.Column>
                <Button color="secondary" variant="outlined" onClick={openMG} fullWidth>
                    Открыть "чистый" MarketGuru
                </Button>
            </Grid.Column>

            <Grid.Column>
                <Button color="info" variant="outlined" fullWidth onClick={getMGToken}>
                    Получить Токен
                </Button>
                {mgToken && <SuccessMessage>Токен Получен</SuccessMessage>}
            </Grid.Column>
            <ListError errors={errors} />
            <Grid.Column>
                <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={!mgToken}
                    loading={creating}
                    onClick={handleSave}
                >
                    Сохранить
                </Button>
            </Grid.Column>
        </Grid>
    );
};

export default observer(AddMarketGuru);
