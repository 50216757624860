import React, { SyntheticEvent } from 'react';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

const WbFeedbacksTypeSwitch = ({
    feedbacksType,
    onChange
}: {
    feedbacksType: 'main' | 'similar';
    onChange: (feedbacksType: 'main' | 'similar') => void;
}) => {
    const handleChangeType = (event: SyntheticEvent, feedbacksType: 'main' | 'similar' | null) => {
        if (feedbacksType) {
            onChange(feedbacksType);
        }
    };

    return (
        <ToggleButtonGroup
            size="small"
            color={feedbacksType === 'main' ? 'primary' : 'secondary'}
            value={feedbacksType}
            exclusive
            onChange={handleChangeType}
        >
            <ToggleButton value="main">На ваш товар</ToggleButton>
            <ToggleButton value="similar">На похожие товары</ToggleButton>
        </ToggleButtonGroup>
    );
};

export default WbFeedbacksTypeSwitch;
