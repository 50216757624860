import React from 'react';

import { OzonItemShortType } from 'stores/productStore';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Grid from 'ui/Grid';

const AspectsItemsOzon = ({ ozonItem }: { ozonItem: OzonItemShortType }) => {
    const { aspects } = ozonItem;

    return (
        <Grid alignItems="flex-start" columns={2} spacing={1}>
            {aspects.map(({ aspect, aspectValue }) => (
                <Grid.Column key={aspect}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography variant="subtitle1" sx={{ color: 'grey.600' }}>
                            {aspect}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                            {aspectValue}
                        </Typography>
                    </Stack>
                </Grid.Column>
            ))}
        </Grid>
    );
};

export default AspectsItemsOzon;
