import fetchApi from '../../common/fetchApi';
import { objectToGraphql } from '../../common/graphql';
import { GQWBItemShort } from '../productApi';
import { OrderDirectionType } from '../../stores/prototypes/ListStore.prototype';
import { WbCardWithConfiguration, WbPriceImtProduct } from '../../stores/wbApi/wbCardStore';

// const GQWbCardAddinFields = `
//     type
//     params {
//         value
//         count
//         units
//     }
// `;

// const GQWbCardFields = `
//     id
//     imtId
//     userId
//     supplierId
//     imtSupplierId
//     object
//     parent
//     countryProduction
//     supplierVendorCode
//     addin {
//         ${GQWbCardAddinFields}
//     }
//     nomenclatures {
//         addin {
//             ${GQWbCardAddinFields}
//         }
//     }
// `;

const GQWbCardConfigurationAddinFields = `
    isAvailable
    required
    useOnlyDictionaryValues
    type
    maxCount
    dictionary
    isNumber
    units
`;

const GQWbCardConfigurationFields = `
    name
    parent
    addin {
        ${GQWbCardConfigurationAddinFields}
    }
    nomenclature {
        addin {
            ${GQWbCardConfigurationAddinFields}
        }
    }
`;

export type WbCardAddinParam = {
    value?: string | null;
    count?: number | null;
    units?: string | null;
};

type WbCardAddin = {
    type: 'Бренд' | 'Пол' | 'Наименование' | 'Описание' | string;
    params: WbCardAddinParam[];
};

export type WbCardNomenclature = {
    id: string;
    nmId: number;
    vendorCode: string;
    variations: object[];
    addin: WbCardAddin[];
};

export type WBCardType = {
    id: string;
    imtId: number;
    userId: number;
    supplierId: string;
    imtSupplierId: 0;
    object: string;
    parent: string;
    countryProduction: string;
    supplierVendorCode: string;
    addin: WbCardAddin[];
    nomenclatures: WbCardNomenclature[];
};

export type WbConfigurationAddin = {
    isAvailable: boolean;
    required: boolean;
    useOnlyDictionaryValues: boolean;
    type: string;
    maxCount?: number;
    dictionary?: string;
    isNumber?: boolean;
    units?: string[];
};

export type WbNCardNomencluatureConfig = {
    variation: {
        addin: [];
    };
    addin: WbConfigurationAddin[];
};

export type WbCardConfiguration = {
    name: string;
    parent: string;
    addin: WbConfigurationAddin[];
    nomenclature: WbNCardNomencluatureConfig;
};

export type WbDictionaryField = {
    key: string;
    translate: string;
};

export const fetchItem = async (wb_item_id: number): Promise<WbCardWithConfiguration> => {
    const graphql = ` { 
          fetchWbCardWithConfiguration(wb_item_id: ${wb_item_id}) {
            cardJson 
            configuration {
                ${GQWbCardConfigurationFields}
            }
          }
        }`;

    const {
        fetchWbCardWithConfiguration: { cardJson, configuration }
    } = await fetchApi.getGQ<{
        fetchWbCardWithConfiguration: { cardJson: string; configuration: WbCardConfiguration };
    }>(graphql);

    return {
        card: JSON.parse(cardJson),
        configuration,
        wb_item_id,
        enable: true
    };
};

export const fetchWbCardDictionaryFields = async (
    dictionary: string,
    option: string,
    search: string
): Promise<string[]> => {
    const graphql = ` { 
          fetchWbCardDictionaryFields(dictionary: ${objectToGraphql(dictionary)}, 
                                        option: ${objectToGraphql(option)}, 
                                        search: ${objectToGraphql(decodeURIComponent(search))})
        }`;

    try {
        const data = await fetchApi.getGQ<{ fetchWbCardDictionaryFields: string[] }>(graphql);
        return data.fetchWbCardDictionaryFields;
    } catch (errors) {
        console.log(graphql);
        throw errors;
    }
};

export const updateUserWbCard = async (card: WBCardType): Promise<boolean> => {
    const graphql = `mutation { 
          updateUserWbCard(cardJson: ${objectToGraphql(JSON.stringify(card))}) 
        }`;

    const data = await fetchApi.postGQ<{ updateUserWbCard: boolean }>(graphql);
    return data.updateUserWbCard;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    userFilter: {},
    controller?: AbortController
): Promise<{ list: WbPriceImtProduct[]; count: number }> => {
    const graphql = ` { 
          fetchWbCardsByApiList(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}") {
                list {
                    nmId
                    price
                    discount
                    promoCode
                    product_id
                    wbItem {
                        ${GQWBItemShort}
                    }
                }
                count
          }
        }`;
    const data = await fetchApi.getGQ<{ fetchWbCardsByApiList: { list: WbPriceImtProduct[]; count: number } }>(
        graphql,
        controller
    );
    return data.fetchWbCardsByApiList;
};
