import React from 'react';

import { WbSize } from 'stores/productStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import { DeliveryPointAddress, GenderType } from 'stores/ransomStore';

import Modal, { ModalContent } from 'ui/Modal';
import Button from 'ui/Button';
import ListError from 'ui/ListError';

import ScheduleIcon from '@mui/icons-material/Schedule';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import { RansomSizeSelect } from '../../RansomSizeEditing';
import RansomSearchQuery from '../../RansomSearchQuery';

import RansomSmartItemTime from './RansomSmartItemTime';
import RansomSmartAddressItem from './RansomSmartAddressItem';
import { WbGenderFieldEditing } from 'components/Items/WbGenderField';

type RansomSmartModalProps = {
    ransom_id: number;
    time: number;
    wb_size_id: number | null;
    address: string | null;
    product_id: number;
    errors: string[];
    onClose: () => void;
    handleDateChange: (date: Date | null) => void;
    handleChooseAddress: (deliveryPointAddress: DeliveryPointAddress) => void;
    handleChooseSize: (wb_size_id: number, wbSize: WbSize) => void;
    handleSave: () => void | Promise<void>;
    loading?: boolean;
    gender: GenderType;
    handleChangeGender: (gender: GenderType) => void;
    store_point_id: number | null;
};

export const RansomSmartModal = ({
    ransom_id,
    time,
    wb_size_id,
    address,
    product_id,
    errors,
    onClose,
    handleDateChange,
    handleChooseAddress,
    handleChooseSize,
    handleSave,
    loading,
    gender,
    handleChangeGender,
    store_point_id
}: RansomSmartModalProps) => {
    return (
        <Modal
            maxWidth="xs"
            header={
                <>
                    <ScheduleIcon />
                    &nbsp;Настройка smart выкупа
                </>
            }
            disableCloseBtn
            onClose={onClose}
        >
            <ModalContent>
                <Stack spacing={3}>
                    <ListError errors={errors} />
                    <Tooltip title="Установить адрес ПВЗ">
                        <RansomSmartAddressItem
                            address={address}
                            onChange={handleChooseAddress}
                            store_point_id={store_point_id}
                            product_id={product_id}
                            gender={gender}
                        />
                    </Tooltip>
                    <RansomSizeSelect wb_size_id={wb_size_id} onChange={handleChooseSize} product_id={product_id} />
                    {ransom_id === CREATING_ITEM_ID && (
                        <>
                            <WbGenderFieldEditing
                                gender={gender}
                                handleChange={handleChangeGender}
                                title="Выкуп от"
                                hidePopup
                            />
                            <RansomSearchQuery ransom_id={CREATING_ITEM_ID} hidePopups disableDeleteFilter />
                        </>
                    )}
                    <RansomSmartItemTime time={time} handleChange={handleDateChange} />
                    <Button loading={loading} fullWidth variant="contained" onClick={handleSave} color="secondary">
                        Установить
                    </Button>
                </Stack>
            </ModalContent>
        </Modal>
    );
};

export default RansomSmartModal;
