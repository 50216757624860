import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import brandStore from 'stores/brandStore';
import productStore from 'stores/productStore';
import videoStore from 'stores/videoStore';

import BrandCreateMode from './BrandCreateMode';
import BrandShowMode from './BrandShowMode';
import ItemDrawWrapper, { MenuTab } from '../ItemDrawWrapper';
import VideoTabMode from '../VideoTabMode';

const Brand = () => {
    const { item_id: item_id_prepare, tabName }: { item_id?: string; tabName?: string } = useParams();
    const brand_id = Number(item_id_prepare) || CREATING_ITEM_ID;

    useMemo(() => {
        productStore.fetchList();
        brandStore.fetchItem(brand_id);
        videoStore.findWbVideo('brand', brand_id);
    }, [brand_id]);

    if (brand_id === CREATING_ITEM_ID) {
        return <BrandCreateMode />;
    }

    const videoId = videoStore.getVideoId('brand', brand_id);

    const tabs: MenuTab[] = [
        { id: 'main', label: 'Бренд в избранном', pathname: `/brands/list/detail/${brand_id}` }
        // {
        //     id: 'video',
        //     label: 'Видеозапись',
        //     pathname: `/brands/list/detail/${brand_id}/video`,
        //     disabled: !videoId
        // }
    ];

    return (
        <ItemDrawWrapper tabs={tabs} activeTab={tabName || 'main'}>
            {(!tabName || tabName === 'main') && <BrandShowMode brand_id={brand_id} />}
            {tabName === 'video' && videoId && <VideoTabMode videoId={videoId} />}
        </ItemDrawWrapper>
    );
};

export default observer(Brand);
