import { makeObservable } from 'mobx';
import ListTransactionStorePrototype from './prototypes/ListTransactionsStore.prototype';
import * as wishlistApi from 'api/wishlistApi';
import { ProductList } from './productStore';

export type Wishlist = {
    wishlist_id: number;
    user_id: number;
    product_id: number;
    quantity: number;
    speed: number | null;
    status: 1 | 2;

    product: ProductList;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

class WishlistStore extends ListTransactionStorePrototype<Wishlist, Wishlist> {
    constructor() {
        super('wishlist_id', 'wishlist', wishlistApi);
        makeObservable(this);
    }

    listFilterClear = {};
}

export default new WishlistStore();
