import fetchApi from 'common/fetchApi';
import { objectToGraphql } from '../common/graphql';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { Question, QuestionFilter } from 'stores/questionStore';
import { GQProductListFields } from './productApi';

export const createItem = async (question: Partial<Question>): Promise<number> => {
    const graphql = `mutation { 
          createQuestion(question: ${objectToGraphql(question)})
        }`;

    const data = await fetchApi.postGQ<{ createQuestion: number }>(graphql);
    return data.createQuestion;
};

const GQQuestionFields = `
    question_id
    user_id
    product_id

    gender
    account_id
    searchRequest

    product {
        ${GQProductListFields}
    }

    questionText
    status
    errorMessage
    createTime
    updateTime
    enable
`;

export const fetchItem = async (question_id: number): Promise<Question> => {
    const graphql = `{ 
          fetchQuestion(question_id: ${question_id}) {
            ${GQQuestionFields}
           }
        }`;

    const data = await fetchApi.getGQ<{ fetchQuestion: Question }>(graphql);
    return data.fetchQuestion;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    questionFilter: Partial<QuestionFilter>,
    controller?: AbortController
): Promise<{ list: Question[]; count: number }> => {
    const graphql = `{
            fetchQuestionsList(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(questionFilter)}) {
                list {
                    ${GQQuestionFields}
                }
                count
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchQuestionsList: { list: Question[]; count: number } }>(graphql, controller);
    return data.fetchQuestionsList;
};

export const refreshUpdateStoreQuestion = async (question_id: number): Promise<boolean> => {
    const graphql = `mutation { 
          refreshUpdateStoreQuestion(question_id: ${question_id})
        }`;

    const data = await fetchApi.postGQ<{ refreshUpdateStoreQuestion: boolean }>(graphql);
    return data.refreshUpdateStoreQuestion;
};
