export const productTypes: { label: string; value: number }[] = [
    { value: 1, label: 'Рубашка женская' },
    { value: 2, label: 'Юбка женская' },
    { value: 3, label: 'Зонт трость' },
    { value: 4, label: 'Коврик для ванной' },
    { value: 5, label: 'Шампунь 400мл' },
    { value: 6, label: 'Будильник' },
    { value: 7, label: 'Сланцы' },
    { value: 8, label: 'Стол сборный  48х51х4' }
];

export type PacketMaterialGroups = {
    group_id: number;
    packPrice: number;
    label: string;
};

export const packetMaterialGroups: PacketMaterialGroups[] = [
    { group_id: 10, packPrice: 5, label: 'Пакет гриппер, Zip Lock' },
    { group_id: 20, packPrice: 5, label: 'Пакет гриппер, Zip Lock с бегунком' },
    { group_id: 30, packPrice: 5, label: 'Упаковочные пакеты с клеевым клапаном (БОПП)' },
    { group_id: 40, packPrice: 5, label: 'Пузырчатая пленка' },
    { group_id: 50, packPrice: 5, label: 'Крафтовая бумага' },
    { group_id: 60, packPrice: 8, label: 'Вакуумная упаковка' }
];

export const packMaterialsSet: {
    pack_id: number;
    group_id: number;
    size: string;
    price: number;
}[] = [
    { pack_id: 11, group_id: 10, size: '6х8 см', price: 2 },
    { pack_id: 12, group_id: 10, size: '10х15 см', price: 5 },
    { pack_id: 13, group_id: 10, size: '20х25 см', price: 7 },
    { pack_id: 14, group_id: 10, size: '25х35 см', price: 10 },
    { pack_id: 15, group_id: 10, size: '35х50 см', price: 12 },
    { pack_id: 16, group_id: 10, size: '40х50 см', price: 15 },
    { pack_id: 17, group_id: 10, size: '50х65 см', price: 27 },

    { pack_id: 21, group_id: 20, size: '6х8 см', price: 3 },
    { pack_id: 22, group_id: 20, size: '10х15 см', price: 7 },
    { pack_id: 23, group_id: 20, size: '20х25 см', price: 9 },
    { pack_id: 24, group_id: 20, size: '25х35 см', price: 12 },
    { pack_id: 25, group_id: 20, size: '35х50 см', price: 18 },
    { pack_id: 26, group_id: 20, size: '40х50 см', price: 20 },
    { pack_id: 27, group_id: 20, size: '50х65 см', price: 32 },

    { pack_id: 31, group_id: 30, size: '6х8 см', price: 2 },
    { pack_id: 32, group_id: 30, size: '10х15 см', price: 3 },
    { pack_id: 33, group_id: 30, size: '20х25 см', price: 4 },
    { pack_id: 34, group_id: 30, size: '25х35 см', price: 5 },
    { pack_id: 35, group_id: 30, size: '35х50 см', price: 7 },
    { pack_id: 36, group_id: 30, size: '40х50 см', price: 9 },
    { pack_id: 37, group_id: 30, size: '50х65 см', price: 11 },

    { pack_id: 41, group_id: 40, size: '200 см²', price: 4 },
    { pack_id: 42, group_id: 40, size: '400 см²', price: 8 },
    { pack_id: 43, group_id: 40, size: '600 см²', price: 12 },
    { pack_id: 44, group_id: 40, size: '800 см²', price: 16 },
    { pack_id: 45, group_id: 40, size: '1000 см²', price: 20 },

    { pack_id: 51, group_id: 50, size: '200 см²', price: 4 },
    { pack_id: 52, group_id: 50, size: '400 см²', price: 8 },
    { pack_id: 53, group_id: 50, size: '600 см²', price: 12 },
    { pack_id: 54, group_id: 50, size: '800 см²', price: 16 },
    { pack_id: 55, group_id: 50, size: '1000 см²', price: 20 },

    { pack_id: 61, group_id: 60, size: '200 см²', price: 2 },
    { pack_id: 62, group_id: 60, size: '400 см²', price: 4 },
    { pack_id: 63, group_id: 60, size: '600 см²', price: 6 },
    { pack_id: 64, group_id: 60, size: '800 см²', price: 8 },
    { pack_id: 65, group_id: 60, size: '1000 см²', price: 10 }
];

export type ProductPriceItem = {
    label: string;
    subLabel?: string;
    price: number;
    priceWithSale: number;
    checked: boolean;
};

export const productPriceItems: ProductPriceItem[] = [
    {
        label: 'Приемка',
        subLabel: 'пересчет товара',
        price: 5,
        priceWithSale: 3,
        checked: true
    },
    {
        label: 'Распаковка',
        subLabel: 'если товар в транспортной упаковке',
        price: 6,
        priceWithSale: 4,
        checked: false
    },
    {
        label: 'Сортировка',
        subLabel: 'по размеру, цвету и тд',
        price: 6,
        priceWithSale: 4,
        checked: false
    },
    {
        label: 'Проверка комплектности товара',
        subLabel: 'по размеру, цвету и тд',
        price: 15,
        priceWithSale: 12,
        checked: false
    },
    {
        label: 'Проверка на брак',
        subLabel: 'ОТК по ТЗ заказчика',
        price: 30,
        priceWithSale: 20,
        checked: false
    },
    {
        label: 'Стикеровка',
        subLabel: 'Распечатка + Наклейка',
        price: 8,
        priceWithSale: 5,
        checked: true
    },
    {
        label: 'ЧЗ-стикеровка',
        subLabel: 'Распечатка + Наклейка',
        price: 8,
        priceWithSale: 5,
        checked: false
    },
    {
        label: 'Вложение в товар',
        subLabel: '',
        price: 5,
        priceWithSale: 3,
        checked: false
    },
    {
        label: 'Типография',
        subLabel: 'согласовывается индивидуально от 1000шт.',
        price: 15,
        priceWithSale: 10,
        checked: false
    }
];
