import * as React from 'react';

import productStore, { Product } from 'stores/productStore';
import locationHistory from 'locationHistory';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import Price from 'components/Price';
import { ProductLinkState } from './ProductLink';
import Button from 'ui/Button';

export const ProductCardFrame = ({
    photoUrl,
    title,
    brandName
}: {
    photoUrl: string;
    title: string;
    brandName: string;
}) => {
    return (
        <Card sx={{ display: 'flex', height: 180 }}>
            <CardMedia component="img" sx={{ width: 151 }} image={photoUrl} alt={title} />
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h6">
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {brandName}
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    );
};

const ProductCardLoading = React.memo(({ size }: { size?: 'small' | 'large' }) => {
    const height = size === 'small' ? 120 : 180;
    return (
        <Card sx={{ display: 'flex', height }}>
            <Skeleton variant="rectangular" width={size === 'small' ? 120 : 151} height={height} />
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Skeleton variant="rectangular" height={50} />
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', pr: 1, pb: 1 }}>
                    <Skeleton variant="rectangular" height={20} width={50} />
                </Box>
            </Box>
        </Card>
    );
});

const ProductCard = ({
    product,
    size = 'large'
}: {
    product: Product | null | undefined;
    size?: 'small' | 'large';
}) => {
    if (!product) {
        return <ProductCardLoading />;
    }

    const handleOpenProduct = () => {
        locationHistory.push(ProductLinkState(product.product_id));
    };

    const { photos, title, price, brand } = productStore.calcShortProduct(product);

    return (
        <Card sx={{ display: 'flex', height: size === 'small' ? 120 : 180 }}>
            <CardMedia component="img" sx={{ width: size === 'small' ? 120 : 151 }} image={photos[0]} alt={title} />
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Button
                        onClick={handleOpenProduct}
                        color="inherit"
                        sx={{ textTransform: 'none', p: 0, textAlign: 'left' }}
                        tooltip="Открыть карточку продукта"
                    >
                        <Typography component="div" variant="h6">
                            {title || 'Наименование не задано'}
                        </Typography>
                    </Button>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {brand.name}
                    </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', pr: 1, pb: 1 }}>
                    {price && <Price {...price} />}
                </Box>
            </Box>
        </Card>
    );
};

export default React.memo(ProductCard);
