import React, { useMemo, SyntheticEvent, useContext } from 'react';
import { observer } from 'mobx-react';

import feedbackReactionStore, { FeedbackReactionType } from 'stores/feedbackReactionStore';
import productStore from 'stores/productStore';
import commonStore from 'stores/commonStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import Grid from 'ui/Grid';
import Modal, { ModalBtnAction, ModalContent } from 'ui/Modal';
import ListError from 'ui/ListError';
import InfoPopup from 'ui/InfoPopup';
import { ErrorMessage, WarningMessage } from 'ui/Message';

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Typography from '@mui/material/Typography';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import List from '@mui/material/List';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';

import CostWithDiscount from '../../CostWithDiscount';
import WbSuggestionsField from '../WbSuggestionsField';
import WbGenderField from '../WbGenderField';

import ProductFeedbackItem from '../Product/FeedbackBlocks/ProductFeedbackItem';
import { FeedbackContext } from '../Product/ProductFeedbacksMode';
import QuantitySpeedModal from '../QuantitySpeedField';
import FeedbackReactionClaim from './FeedbackReactionClaim';

const FeedbacksPopup = React.memo(() => {
    return (
        <InfoPopup size="large">
            <Typography variant="subtitle2">Для чего нужно "Лайкать и Дизлайкать отзывы"?</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Отзывы играют большую роль при принятии решения о покупке товара.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                При сортировке отзывов «по полезности», вверху списка будут отзывы с наибольшим количеством активностей.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Потенциальные покупатели видят лайки на «хороших» отзывах и дизлайки на «плохих», принимают решение
                более рационально.
            </Typography>
        </InfoPopup>
    );
});

const FeedbackReactionModal = ({
    initialType,
    onClose
}: {
    initialType: FeedbackReactionType;
    onClose: () => void;
}) => {
    const feedback_reaction_id = CREATING_ITEM_ID;

    const feedback = useContext(FeedbackContext);

    useMemo(() => {
        if (feedback) {
            productStore.fetchList();
            feedbackReactionStore.fetchItem(feedback_reaction_id);
            feedbackReactionStore.setEditingItem(feedback_reaction_id, {
                feedback_id: feedback.feedback_id,
                comment: null,
                type: initialType,
                claim: null,
                gender: null,
                searchRequest: '',
                quantity: 1,
                speed: null
            });
        }
    }, [feedback_reaction_id, feedback]);

    const { loadingItem, editingItem, errors } = feedbackReactionStore.getItem(feedback_reaction_id);
    const { type, quantity, claim } = editingItem;

    let titleReaction = type === 'like' ? 'Лайк' : 'Дизлайк';
    let titleReactionHeader =
        type === 'remove' ? 'Удалить' : type === 'claim' ? 'Пожаловаться на' : `${titleReaction} на`;

    const handelChangeType = (event: SyntheticEvent, type: 'like' | 'dislike' | null) => {
        if (type) {
            feedbackReactionStore.setEditingItem(feedback_reaction_id, { type });
        }
    };

    const handleCreate = async () => {
        if (await feedbackReactionStore.createItem()) {
            commonStore.setGlobalInfoMessage(
                type === 'claim'
                    ? 'Заявка на жалобу на отзыв создана'
                    : type !== 'remove'
                    ? `Заявка на добавление ${titleReaction}а создана, мы сообщим о ее осуществление`
                    : `Заявка на удаление отзыва создана, мы сообщим о ее осуществление`
            );
            onClose();
        }
    };

    const isOldFeedback = Date.now() / 1000 - Number(feedback?.createTime) > 90 * 24 * 3600;

    const cost = type === 'remove' ? (isOldFeedback ? 3490 : 2490) : 8;

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'inherit' },
        {
            onClick: handleCreate,
            label: (
                <>
                    {titleReactionHeader} отзыв (
                    <CostWithDiscount cost={Number(quantity) * cost} disableDiscount={type === 'remove'} />)
                </>
            ),
            loading: loadingItem,
            disabled: type !== 'claim' || !claim || !quantity,
            variant: 'contained',
            color: 'secondary',
            endIcon: <ShoppingBasketIcon />
        }
    ];

    return (
        <Modal
            maxWidth="sm"
            onClose={onClose}
            actions={actions}
            header={
                <>
                    <ThumbsUpDownIcon />
                    &nbsp;{titleReactionHeader} отзыв
                </>
            }
            fullScreen={commonStore.isMobile}
        >
            <ModalContent>
                <ListError errors={errors} />
                <Grid disableGutter>
                    <Grid.Column>
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }} disablePadding>
                            <ListItem alignItems="flex-start">
                                {feedback && <ProductFeedbackItem feedback={feedback} />}
                            </ListItem>
                        </List>
                    </Grid.Column>
                    {(type === 'like' || type === 'dislike') && (
                        <>
                            <Grid.Column>
                                <WbGenderField
                                    title={`${titleReaction} от`}
                                    item_id={feedback_reaction_id}
                                    store={feedbackReactionStore}
                                />
                            </Grid.Column>

                            <Grid.Column>
                                <WbSuggestionsField item_id={feedback_reaction_id} store={feedbackReactionStore} />
                            </Grid.Column>
                            <Grid.Column>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                                    <Typography variant="subtitle1">Реакция</Typography>
                                    <ToggleButtonGroup
                                        size="small"
                                        color="secondary"
                                        value={type}
                                        exclusive
                                        onChange={handelChangeType}
                                    >
                                        <ToggleButton value="like">
                                            <ThumbUpIcon color={type === 'like' ? 'primary' : undefined} />
                                        </ToggleButton>
                                        <ToggleButton value="dislike">
                                            <ThumbDownIcon color={type === 'dislike' ? 'secondary' : undefined} />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                            </Grid.Column>
                            <QuantitySpeedModal
                                store={feedbackReactionStore}
                                item_id={feedback_reaction_id}
                                infoPopup={<FeedbacksPopup />}
                            />
                            <ErrorMessage header="Внимание">
                                Временно невозможно поставить лайк и дизлайк на отзыв. Приносим извинения за неудобство.
                                Скоро возможность вернется.
                            </ErrorMessage>
                        </>
                    )}
                </Grid>

                {type === 'remove' && (
                    <>
                        <WarningMessage header="Внимание">
                            Удаление отзыва происходит в течение 14 дней. <br />
                            Если через 14 дней нам не удастся удалить данный отзыв, то мы вернем вам потраченные деньги
                            на счет TopStore.
                            <br />
                            <b>
                                Данная услуга предоставляется в тестовом режиме, количество отзывов, которые мы можем
                                взять в работу на удаление - ограничено.
                            </b>
                        </WarningMessage>
                        {isOldFeedback && (
                            <WarningMessage header="Отзыв старше 90 дней">
                                Этот отзыв старше 90 дней, удалить его будет сложнее, поэтому цена за удаление выше
                            </WarningMessage>
                        )}
                        <WarningMessage header="Внимание">
                            Удаление отзывов временно приостановлено, скоро мы возобновим данную услугу
                        </WarningMessage>
                    </>
                )}

                {type === 'claim' && (
                    <Grid>
                        <FeedbackReactionClaim />
                        <QuantitySpeedModal
                            store={feedbackReactionStore}
                            item_id={feedback_reaction_id}
                            infoPopup={<FeedbacksPopup />}
                        />
                    </Grid>
                )}
            </ModalContent>
        </Modal>
    );
};

export default observer(FeedbackReactionModal);
