import React, { SyntheticEvent, useMemo } from 'react';
import { observer } from 'mobx-react';

import referralLinkStore from 'stores/referralLinkStore';
import commonStore from 'stores/commonStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import Modal, { ModalBtnAction, ModalContent } from 'ui/Modal';
import ListError from 'ui/ListError';
import Grid from 'ui/Grid';
import TextInput from 'ui/TextInput';

import LinkIcon from '@mui/icons-material/Link';

const CreateRefModal = ({ onClose }: { onClose: () => void }) => {
    useMemo(() => {
        referralLinkStore.fetchItem(CREATING_ITEM_ID);
        referralLinkStore.setEditingItem(CREATING_ITEM_ID, {
            comment: ''
        });
    }, []);

    const { loadingItem, editingItem, errors } = referralLinkStore.getItem(CREATING_ITEM_ID);
    const { comment } = editingItem;

    const handleComment = (event: SyntheticEvent, { value }: { value: string }) => {
        referralLinkStore.setEditingItem(CREATING_ITEM_ID, { comment: value });
    };

    const handleCreate = async () => {
        if (await referralLinkStore.createItem()) {
            onClose();
        }
    };

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'inherit' },
        {
            onClick: handleCreate,
            label: `Добавить ссылку`,
            loading: loadingItem,
            variant: 'contained',
            color: 'secondary',
            endIcon: <LinkIcon />
        }
    ];

    return (
        <Modal
            maxWidth="sm"
            onClose={onClose}
            actions={actions}
            header={
                <>
                    <LinkIcon />
                    &nbsp;Добавить ссылку
                </>
            }
            fullScreen={commonStore.isMobile}
        >
            <ModalContent>
                <ListError errors={errors} />
                <Grid>
                    <Grid.Column>
                        <TextInput
                            variant="standard"
                            label="Описание"
                            value={comment}
                            onChange={handleComment}
                            maxLength={255}
                            multiline
                            rowsMax={10}
                            rows={1}
                        />
                    </Grid.Column>
                </Grid>
            </ModalContent>
        </Modal>
    );
};

export default observer(CreateRefModal);
