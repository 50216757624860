import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import reviewStore from 'stores/reviewStore';

import { useToggleModal } from 'ui/Modal';
import Checkbox from 'ui/Checkbox';
import TimePicker from 'ui/TimePicker';
import InfoPopup from 'ui/InfoPopup';

import Stack from '@mui/material/Stack';

const ReviewPlanningExecuteTime = ({ review_id }: { review_id: number }) => {
    const { loadingItem, editingItem } = reviewStore.getItem(review_id);
    const { planningExecuteTime } = editingItem;

    const [toggle, useToggle] = useToggleModal();
    useEffect(() => {
        reviewStore.setEditingItem(review_id, {
            planningExecuteTime: toggle ? Math.floor(Date.now() / 1000) + 300 : null
        });
    }, [toggle]);

    const handleTimeChange = (date: Date | null) => {
        if (date) {
            reviewStore.setEditingItem(review_id, {
                planningExecuteTime: Math.floor(date.getTime() / 1000)
            });
        }
    };

    return (
        <Stack spacing={3}>
            <Stack direction="row" alignItems="center">
                <Checkbox checked={toggle} label="Запланировать время отправки отзыва" onChange={useToggle} />
                <InfoPopup size="medium">Вы можете запланировать время публикации отзыва</InfoPopup>
            </Stack>
            {toggle && (
                <TimePicker
                    label="Дата и время публикации"
                    time={planningExecuteTime || Math.floor(Date.now() / 1000)}
                    handleChange={handleTimeChange}
                />
            )}
        </Stack>
    );
};

export default observer(ReviewPlanningExecuteTime);
