import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import feedbackReactionStore, { FeedbackReaction } from 'stores/feedbackReactionStore';

import Table, { TableHead, TableRow, TableCell, TableBody } from 'ui/Table';
import Time from 'ui/Time';
import { WarningMessage } from 'ui/Message';
import CutString from 'ui/CutString';
import Button from 'ui/Button';

import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

import { ListPaper } from '../ListWrapper';
import Stack from '@mui/material/Stack';
import ListPagination from '../ListPagination';
import ProductTitleLink from '../Products/ProductTitleLink';
import FeedbackReactionInfoModal from './FeedbackReactionInfoModal';

const FeedbacksReactions = () => {
    const { list, loadingList, handleSort, adjustDirection } = feedbackReactionStore;
    const [activeFr, setActiveFR] = useState<FeedbackReaction | null>(null);

    return (
        <ListPaper store={feedbackReactionStore}>
            <Table elevation={0} loading={loadingList}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sortDirection={adjustDirection('createTime')}
                            onClick={() => handleSort('createTime')}
                        >
                            Дата
                        </TableCell>
                        <TableCell sortDirection={adjustDirection('status')} onClick={() => handleSort('status')}>
                            Статус
                        </TableCell>
                        <TableCell sx={{ minWidth: '250px' }}>Наименование</TableCell>
                        <TableCell>Отзыв</TableCell>
                        <TableCell sortDirection={adjustDirection('type')} onClick={() => handleSort('type')}>
                            Тип
                        </TableCell>
                        <TableCell>Запрос</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(feedbackReaction => {
                        const {
                            feedback_reaction_id,
                            type,
                            // updateTime,
                            // speed,
                            quantity,
                            createTime,
                            searchRequest,
                            status,
                            product,
                            text,
                            claim
                        } = feedbackReaction;

                        return (
                            <TableRow key={feedback_reaction_id}>
                                <TableCell>
                                    <Time time={createTime} />
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => setActiveFR(feedbackReaction)}>
                                        {status === 1 && (
                                            <Chip label="Выполняется" color="warning" sx={{ cursor: 'pointer' }} />
                                        )}
                                        {status === 2 && (
                                            <Chip label="Выполнено" color="success" sx={{ cursor: 'pointer' }} />
                                        )}
                                        {status === 3 && (
                                            <Chip label="Возврат" color="error" sx={{ cursor: 'pointer' }} />
                                        )}
                                    </Button>
                                </TableCell>

                                <TableCell>
                                    <ProductTitleLink product={product} />
                                </TableCell>
                                <TableCell>
                                    <CutString title={text} maxLength={30} />
                                </TableCell>
                                <TableCell align="center">
                                    <Stack direction="column" alignItems="flex-start">
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                            {type === 'like' && (
                                                <>
                                                    <ThumbUpIcon color="primary" />
                                                    <span>Лайк</span>
                                                </>
                                            )}
                                            {type === 'dislike' && (
                                                <>
                                                    <ThumbDownIcon color="secondary" />
                                                    <span>Дизлайк</span>
                                                </>
                                            )}
                                            {type === 'remove' && (
                                                <>
                                                    <DeleteForeverIcon color="error" />
                                                    <span>Удаление</span>
                                                </>
                                            )}
                                            {type === 'claim' && (
                                                <>
                                                    <PlaylistRemoveIcon color="secondary" />
                                                    <span>Жалоба</span>
                                                </>
                                            )}
                                        </Box>
                                        {quantity > 1 && (
                                            <Box sx={{ whiteSpace: 'nowrap' }}>
                                                Количество: <b>{quantity}</b>
                                            </Box>
                                        )}
                                    </Stack>
                                </TableCell>

                                <TableCell>
                                    <CutString title={searchRequest} maxLength={30} />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <ListPagination
                    pagination={feedbackReactionStore.pagination}
                    listCount={feedbackReactionStore.listCount}
                    pageChange={feedbackReactionStore.pageChange}
                    pageSizeChange={feedbackReactionStore.pageSizeChange}
                    colSpan={5}
                />
            </Table>

            {!loadingList && list.length === 0 && (
                <WarningMessage header="Нет лайков на отзывы">
                    Вы можете добавить лайк на отзыв вашего <Link to="/products/list">товара</Link>
                </WarningMessage>
            )}

            {activeFr && <FeedbackReactionInfoModal feedbackReaction={activeFr} onClose={() => setActiveFR(null)} />}
        </ListPaper>
    );
};

export default observer(FeedbacksReactions);
