import React from 'react';

import MenuList from './MenuList';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

const LeftMenu = ({
    mobileOpen,
    handleMobileOpen,
    drawerWidth
}: {
    mobileOpen: boolean;
    handleMobileOpen: () => void;
    drawerWidth: number;
}) => {
    return (
        <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleMobileOpen}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                }}
            >
                <MenuList />
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                }}
                open
                PaperProps={{
                    elevation: 2
                }}
            >
                <MenuList />
            </Drawer>
        </Box>
    );
};

export default LeftMenu;
