import React from 'react';
import { observer } from 'mobx-react';

import Paper from '@mui/material/Paper';

import ReviewUploadFiles from './ReviewUploadFiles';

const UploadPhotos = () => {
    return (
        <Paper elevation={2} sx={{ p: 3 }}>
            {/*<WarningMessage header="Загрузка фотографий">*/}
            {/*    {showMessage && (*/}
            {/*        <div>*/}
            {/*            Наш модератор выберет не более двух наиболее подходящих фотографий, т.к. с большим количеством*/}
            {/*            фотографий отзыв редко проходит модерацию на стороне Wildberries.*/}
            {/*            <ul style={{ paddingLeft: '1rem' }}>*/}
            {/*                <li>*/}
            {/*                    На фотографии товар должен быть без упаковки, т.к. люди обычно не делают фотографии*/}
            {/*                    товара до вскрытия упаковки*/}
            {/*                </li>*/}
            {/*                <li>*/}
            {/*                    На фотографии не желательно присутствия следов графической обработки (надписи, эффекты,*/}
            {/*                    склейки), т.к. это не соответствует клиентскому поведению*/}
            {/*                </li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*    <Button size="small" color="warning" onClick={toggleMessage}>*/}
            {/*        {!showMessage ? 'Показать информацию' : 'Скрыть'}*/}
            {/*    </Button>*/}
            {/*</WarningMessage>*/}
            <ReviewUploadFiles
                type="photo"
                acceptedFileTypes="image/*"
                maxFiles={5}
                labelIdle={`<div>Перенесите сюда фотографии или <span class="filepond--label-action"> Выберите в папке </span> Не более 5 файлов за раз</div>`}
            />
        </Paper>
    );
};

export default UploadPhotos;
