import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import ransomStore, { RansomType, DeliveryPointAddress } from 'stores/ransomStore';
import productStore from 'stores/productStore';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';

import DeliveryPointModal from '../DeliveryPointModal';
import RansomAddressItem from './RansomAddressItem';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';

const AddAddressBtn = ({
    addNew,
    onChooseAddress
}: {
    addNew: boolean;
    onChooseAddress: (deliveryPointAddress: DeliveryPointAddress) => void;
}) => {
    const [showAddressModal, toggleAddressModal] = useToggleModal();

    return (
        <ListItem>
            <Button
                variant={addNew ? 'outlined' : 'contained'}
                color="secondary"
                onClick={toggleAddressModal}
                sx={{ ml: 1 }}
                startIcon={<HomeIcon />}
                tooltip={addNew ? 'Создастся еще один выкуп' : undefined}
                endIcon={addNew ? <AddIcon /> : undefined}
                fullWidth
            >
                {addNew ? 'Добавить еще адрес' : 'Выбрать адрес доставки'}
            </Button>
            {showAddressModal && <DeliveryPointModal onClose={toggleAddressModal} onChoose={onChooseAddress} />}
        </ListItem>
    );
};

const RansomAddressBlock = ({ ransom_id }: { ransom_id: number }) => {
    const { deliveryPointsAddresses = [] } = ransomStore.getItem(ransom_id).property;
    const { store_point_ids, type, store_id } = ransomStore.getItem(ransom_id).editingItem;

    useEffect(() => {
        if (productStore.listFilter.store_id !== store_id) {
            ransomStore.setProperty(ransom_id, { deliveryPointsAddresses: [] });
            ransomStore.setEditingItem(ransom_id, {
                store_point_ids: []
            });
        }
    }, [store_id, productStore.listFilter.store_id]);

    const handleAddAddress = (deliveryPointsAddress: DeliveryPointAddress) => {
        const { deliveryPointsAddresses = [] } = ransomStore.getItem(ransom_id).property;
        const deliveryPointsAddressesNew = [...deliveryPointsAddresses, deliveryPointsAddress];
        ransomStore.setProperty(ransom_id, { deliveryPointsAddresses: deliveryPointsAddressesNew });

        ransomStore.setEditingItem(ransom_id, {
            store_point_ids: deliveryPointsAddressesNew.map(({ store_point_id }) => store_point_id)
        });
    };

    const enableAdd = type === RansomType.Once || (store_point_ids && store_point_ids?.length < 1);

    return (
        <List dense disablePadding>
            {deliveryPointsAddresses.map(({ store_point_id }, index) => (
                <RansomAddressItem key={store_point_id} ransom_id={ransom_id} addressIndex={index} />
            ))}
            {enableAdd && (
                <AddAddressBtn addNew={deliveryPointsAddresses.length > 0} onChooseAddress={handleAddAddress} />
            )}
        </List>
    );
};

export default observer(RansomAddressBlock);
