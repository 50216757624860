import React from 'react';

import authStore from 'stores/authStore';
import commonStore from 'stores/commonStore';

import Button from 'ui/Button';
import Modal from 'ui/Modal';

import YouTubeIcon from '@mui/icons-material/YouTube';

const VideoHeaderModal = () => {
    const createTime = Number(authStore?.currentUser?.createTime);
    const isNew = Date.now() / 1000 - createTime < 3600 * 3;

    const Trigger =
        commonStore.isMobile && !isNew ? (
            <Button
                icon={<YouTubeIcon />}
                variant={isNew ? 'contained' : 'outlined'}
                color="secondary"
                size="large"
                tooltip="Видеообзор"
            />
        ) : (
            <Button
                startIcon={<YouTubeIcon />}
                variant={isNew ? 'contained' : 'outlined'}
                color="secondary"
                size={isNew && !commonStore.isMobile ? 'large' : 'small'}
            >
                Видеообзор
            </Button>
        );

    return (
        <Modal trigger={Trigger} header="Видеообзор нашего сервиса" maxWidth="md">
            <iframe
                width="100%"
                height={390}
                src="https://www.youtube.com/embed/IQG68G6a-Is?controls=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </Modal>
    );
};

export default React.memo(VideoHeaderModal);
