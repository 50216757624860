import { observable, makeObservable, action } from 'mobx';
import { throttle } from 'common/throttle';

class CommonStore {
    matchExpandedMenuStatus(): boolean {
        return window.matchMedia('(min-width: 1440px)').matches;
    }

    @observable
    isMobile: boolean = true;

    private checkWindowSize() {
        makeObservable(this);

        this.isMobile = window.matchMedia('(max-width: 768px)').matches;
    }

    throttleWindowSize = throttle(() => {
        this.checkWindowSize();
    }, 100);

    constructor() {
        this.checkWindowSize();

        if ('ResizeObserver' in window) {
            const resizeObserver = new window.ResizeObserver(this.throttleWindowSize);
            const $root = document.getElementById('root');
            if ($root) {
                resizeObserver.observe($root);
            }
        } else {
            // window.onresize = this.throttleWindowSize;
        }
    }

    @observable
    globalInfoMessage: string | null = null;
    @action
    setGlobalInfoMessage(message: string | null) {
        this.globalInfoMessage = message;
    }

    @observable
    showLeftMenu: boolean = false;
    @action
    toggleLeftMenu = () => {
        this.showLeftMenu = !this.showLeftMenu;
    };
}

export default new CommonStore();
