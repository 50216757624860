import React from 'react';
import { observer } from 'mobx-react';

import referralLinkStore from 'stores/referralLinkStore';
import commonStore from 'stores/commonStore';
import authStore from 'stores/authStore';

import Table, { TableHead, TableRow, TableCell, TableBody } from 'ui/Table';
import Time from 'ui/Time';
import { WarningMessage } from 'ui/Message';
import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';
import TextInput from 'ui/TextInput';

import LinkIcon from '@mui/icons-material/Link';
import MoneyIcon from '@mui/icons-material/Money';
import Box from '@mui/material/Box';

import ListWrapper from '../ListWrapper';
import CreateRefModal from './CreateRefModal';
import PaymentsModal from './PaymentsModal';

const ReferralLinks = () => {
    const { list, loadingList } = referralLinkStore;
    const [showRefLinkModal, toggleRefLinkModal] = useToggleModal();
    const [showPaymentsModal, togglePaymentsModal] = useToggleModal();

    if (!authStore.enableReferrals()) {
        return null;
    }

    let totalUsers = 0;
    let totalSum = 0;

    return (
        <ListWrapper store={referralLinkStore} bgUrl="/static/listbg/unruly-01-success.webp">
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, gap: 2 }}>
                <Button variant="contained" color="secondary" startIcon={<MoneyIcon />} onClick={togglePaymentsModal}>
                    {!commonStore.isMobile ? 'пополнения' : ''}
                </Button>
                {showPaymentsModal && <PaymentsModal onClose={togglePaymentsModal} />}
                <Button variant="contained" startIcon={<LinkIcon />} onClick={toggleRefLinkModal}>
                    Создать {!commonStore.isMobile ? 'реферальную ' : ''}ссылку
                </Button>
                {showRefLinkModal && <CreateRefModal onClose={toggleRefLinkModal} />}
            </Box>

            <Table elevation={0} loading={loadingList}>
                <TableHead>
                    <TableRow>
                        <TableCell>Дата создания</TableCell>
                        <TableCell>Описание</TableCell>
                        <TableCell>Ссылка</TableCell>
                        <TableCell>Телеграм</TableCell>
                        <TableCell>Переходов</TableCell>
                        <TableCell>Регистраций</TableCell>
                        <TableCell>Сумма, руб</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(
                        ({
                            link_id,
                            paymentsResetTime,
                            comment,
                            code,
                            createTime,
                            linkVisited,
                            userRegistered,
                            totalPayments
                        }) => {
                            totalUsers += userRegistered;
                            totalSum += totalPayments;

                            return (
                                <TableRow key={link_id}>
                                    <TableCell>
                                        <Time time={createTime} />
                                        {paymentsResetTime && (
                                            <Box sx={{ whiteSpace: 'nowrap' }}>
                                                Обнуление: <Time time={paymentsResetTime} />
                                            </Box>
                                        )}
                                    </TableCell>
                                    <TableCell>{comment}</TableCell>
                                    <TableCell sx={{ minWidth: '300px' }}>
                                        <TextInput value={`https://topstore.pro/?r=${code}`} />
                                    </TableCell>
                                    <TableCell sx={{ minWidth: '300px' }}>
                                        <TextInput value={`https://t.me/topstorepro_bot?start=${code}`} />
                                    </TableCell>
                                    <TableCell align="center">{linkVisited.toLocaleString()}</TableCell>
                                    <TableCell align="center">{userRegistered.toLocaleString()}</TableCell>
                                    <TableCell align="center">{totalPayments.toLocaleString()}</TableCell>
                                </TableRow>
                            );
                        }
                    )}
                </TableBody>
                <TableRow>
                    <TableCell colSpan={5} align="right">
                        <b>Итого</b>
                    </TableCell>
                    <TableCell align="center">{totalUsers.toLocaleString()}</TableCell>
                    <TableCell align="center">{totalSum.toLocaleString()}</TableCell>
                </TableRow>
            </Table>

            {!loadingList && list.length === 0 && (
                <WarningMessage header="Не найдено реферальных ссылок">
                    Создайте одну или несколько ссылок и привлекайте рефералов
                </WarningMessage>
            )}
        </ListWrapper>
    );
};

export default observer(ReferralLinks);
