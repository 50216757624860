import fetchApi from 'common/fetchApi';
import { User, UserDiscount, UserListFilter } from 'stores/userStore';
import { OrderDirectionType } from '../stores/prototypes/ListStore.prototype';
import { objectToGraphql } from '../common/graphql';

export const GQUserMainFields = `
    email  
    phone  
    firstName
    lastName
    balance
    discount
    sum30dPayments
    access_id
    enableReferrals
    
    firstPaymentGift
    firstPaymentExpireTime
    deliveryManager
    
    createTime
    enable
`;

const GQUserFields = `
    ${GQUserMainFields}
    referralsCount
    referrer_user_id
    referralsSumToWithdraw
    sumClearPayments
    disableWbAccountAfterRansom
    
    updateTime
    onlineTime
`;

export const fetchItem = async (user_id: number): Promise<User> => {
    const graphql = `{
            fetchUser(user_id: ${user_id}) {
                ${GQUserFields}
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchUser: User }>(graphql);
    return data.fetchUser;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    userFilter: Partial<UserListFilter>,
    controller?: AbortController
): Promise<{ list: User[]; count: number }> => {
    const graphql = `{
            fetchUsersList(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(userFilter)}) {
                list {
                    user_id
                    ${GQUserFields}
                }
                count
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchUsersList: { list: User[]; count: number } }>(graphql, controller);
    return data.fetchUsersList;
};

export const requestKeysRansom = async (phone: string, comment: string): Promise<boolean> => {
    const graphql = `mutation { 
          requestKeysRansom(phone: ${objectToGraphql(phone)}, comment: ${objectToGraphql(comment)})
        }`;

    const data = await fetchApi.postGQ<{ requestKeysRansom: boolean }>(graphql);
    return data.requestKeysRansom;
};

export const requestYandexDirect = async (comment: string): Promise<boolean> => {
    const graphql = `mutation { 
          requestYandexDirect(comment: ${objectToGraphql(comment)})
        }`;

    const data = await fetchApi.postGQ<{ requestYandexDirect: boolean }>(graphql);
    return data.requestYandexDirect;
};

export const requestInfographics = async (comment: string): Promise<boolean> => {
    const graphql = `mutation { 
          requestInfographics(comment: ${objectToGraphql(comment)})
        }`;

    const data = await fetchApi.postGQ<{ requestInfographics: boolean }>(graphql);
    return data.requestInfographics;
};

export const requestSeoPhotos = async (comment: string): Promise<boolean> => {
    const graphql = `mutation { 
          requestSeoPhotos(comment: ${objectToGraphql(comment)})
        }`;

    const data = await fetchApi.postGQ<{ requestSeoPhotos: boolean }>(graphql);
    return data.requestSeoPhotos;
};

export const requestFulFillment = async (phone: string, comment: string): Promise<boolean> => {
    const graphql = `mutation { 
          requestFulFillment(phone: ${objectToGraphql(phone)}, comment: ${objectToGraphql(comment)})
        }`;

    const data = await fetchApi.postGQ<{ requestFulFillment: boolean }>(graphql);
    return data.requestFulFillment;
};

export const addUserPayment = async (user_id: number, payment: number, type: 'gift' | 'order'): Promise<boolean> => {
    const graphql = `mutation { 
          addUserPayment(user_id: ${user_id}, payment: ${payment}, type: "${type}")
        }`;

    const data = await fetchApi.postGQ<{ addUserPayment: boolean }>(graphql);
    return data.addUserPayment;
};

export const addUserDiscount = async (user_id: number, discount: UserDiscount): Promise<boolean> => {
    const graphql = `mutation { 
          addUserDiscount(user_id: ${user_id}, userDiscount: ${objectToGraphql(discount)})
        }`;

    const data = await fetchApi.postGQ<{ addUserDiscount: boolean }>(graphql);
    return data.addUserDiscount;
};

export const sendTgUserMessage = async (user_id: number, header: string, message: string): Promise<boolean> => {
    const graphql = `mutation { 
          sendTgUserMessage(user_id: ${user_id}, header: ${objectToGraphql(header)}, message: ${objectToGraphql(
        message
    )})
        }`;

    const data = await fetchApi.postGQ<{ sendTgUserMessage: boolean }>(graphql);
    return data.sendTgUserMessage;
};

export const saveItem = async (user_id: number, userDiff: Partial<User>): Promise<number> => {
    const graphql = `mutation { 
          updateUser(user_id: ${user_id}, userDiff: ${objectToGraphql(userDiff)})
        }`;

    const data = await fetchApi.postGQ<{ updateUser: number }>(graphql);
    return data.updateUser;
};

export const sendUserAction = async (action: string | object): Promise<void> => {
    const graphql = `mutation { 
          sendUserAction(pathname: ${objectToGraphql(document.location.pathname)}, 
          action: ${objectToGraphql(typeof action === 'string' ? action : JSON.stringify(action))})
        }`;

    try {
        await fetchApi.postGQ<{ updateUser: number }>(graphql);
    } catch (error) {}
};

export const fetchCouriersList = async (): Promise<User[]> => {
    const graphql = `{ 
          fetchCouriersList {
              user_id
              ${GQUserMainFields}
          }
    }`;

    const data = await fetchApi.postGQ<{ fetchCouriersList: User[] }>(graphql);
    return data.fetchCouriersList;
};
