import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import productStore from 'stores/productStore';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TocIcon from '@mui/icons-material/Toc';

import Select from 'ui/Select';
import Button from 'ui/Button';

import FeedbacksReactionsList from './FeedbacksReactionsList';
import ListContainer from '../ListContainer';
import ProductFeedbacksMode from '../../Items/Product/ProductFeedbacksMode';
import { ListPaper } from '../ListWrapper';
import commonStore from '../../../stores/commonStore';

const ProductListSelect = observer(
    ({ product_id, onChange }: { product_id: number | null; onChange: (product_id: number | null) => void }) => {
        useMemo(() => {
            productStore.fetchList();
        }, []);

        const { loadingList, productOptions } = productStore;

        const handleChange = ({ value }: { value: number }) => {
            productStore.fetchItem(value);
            onChange(value);
        };

        return (
            <Box sx={{ py: 1, display: 'flex', gap: 2 }}>
                <Select
                    label="Выберите товар"
                    value={product_id}
                    onChange={handleChange}
                    options={productOptions}
                    fullWidth
                    loading={loadingList}
                    variant="classic"
                    sx={{ maxWidth: '500px' }}
                />
                {product_id && (
                    <Button
                        tooltip="Открыть список заявок Лайков на Отзывы"
                        startIcon={<TocIcon />}
                        size="small"
                        onClick={() => onChange(null)}
                        color="secondary"
                    >
                        назад
                    </Button>
                )}
            </Box>
        );
    }
);

const FeedbacksReactions = () => {
    const [product_id, setProductId] = useState<number | null>(null);

    return (
        <ListContainer bgUrl="/static/listbg/unruly-10-relationships.webp">
            <ProductListSelect product_id={product_id} onChange={setProductId} />
            {!product_id && <FeedbacksReactionsList />}
            {product_id && (
                <Container maxWidth="lg">
                    <ListPaper store={null}>
                        <ProductFeedbacksMode
                            product_id={product_id}
                            feedbacksType="main"
                            bottomPadding={commonStore.isMobile ? 170 : 220}
                        />
                    </ListPaper>
                </Container>
            )}
        </ListContainer>
    );
};

export default observer(FeedbacksReactions);
