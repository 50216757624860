import React from 'react';
import { Link } from 'react-router-dom';

export const QuestionLinkState = (question_id: number) => ({
    pathname: `/questions/list/detail/${question_id}`,
    state: { modal: true }
});

const QuestionLink = ({ question_id }: { question_id: number }) => (
    <Link to={QuestionLinkState(question_id)}>Вопрос №{question_id}</Link>
);

export default QuestionLink;
