import { makeObservable, observable, action, reaction } from 'mobx';
import * as authApi from 'api/authApi';
import * as settingsApi from 'api/settingsApi';
import wait from 'common/wait';
import userStore, { User } from './userStore';
import { nProgress } from './helpers/decorators.helpers';

const JWT_NAME = 'crm-jwt';
const CURRENT_USER_MEM_KEY = 'pullCurrentUser';

const ERROR_FAILED_TO_FETCH = 'Failed to fetch';

export type SystemSettingsType = {
    qr: boolean;
    sberpay: boolean;
    carts: boolean;
    systemMessage: string;
    groupRansoms: boolean;
    qrOzon: boolean;

    preRunAccount_wb: boolean;
    oneTimeAccount_wb: boolean;
    additionalItems_wb: boolean;

    reviewsEnable_wb: boolean;

    oneTimeAccount_ozon: boolean;
};

const SETTINGS_UPDATE_TIME = 60000;

class AuthStore {
    @observable
    token: string | null = window.localStorage.getItem(JWT_NAME);

    @observable
    inProgress = false;
    @observable
    loggedIn = false;

    @action
    setToken(token: string) {
        this.token = token;
    }

    constructor() {
        makeObservable(this);

        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem(JWT_NAME, token);
                } else {
                    window.localStorage.removeItem(JWT_NAME);
                }
            }
        );
    }

    isAdmin(): Boolean {
        return this.currentUser?.access_id === 1;
    }

    enableReferrals(): Boolean {
        return this.currentUser?.enableReferrals || false;
    }

    @action
    async whatsUp() {
        this.inProgress = true;
        try {
            await Promise.all([this.pullCurrentUser()]);
            this.loggedIn = true;
        } catch (error) {
            await this.logout();
        }
        this.inProgress = false;
    }

    @observable
    currentUser: User | null = null;

    @action
    async pullCurrentUser() {
        const currentUserStr = localStorage.getItem(CURRENT_USER_MEM_KEY);
        if (currentUserStr) {
            this.currentUser = JSON.parse(currentUserStr);
            this.pullCurrentUserApi();
        } else {
            await this.pullCurrentUserApi();
        }
    }

    @observable
    loginErrors: string[] = [];

    fetchIntervalId: number | null = null;

    @action
    async login(phone: string, password: string) {
        this.inProgress = true;
        this.loginErrors = [];

        try {
            const { token } = await authApi.logIn(phone, password);
            this.token = token;

            await Promise.all([this.pullCurrentUser()]);
            this.loggedIn = true;
        } catch (err) {
            console.log('err', err);
            // @ts-ignore
            this.loginErrors = [err.toString()];
        } finally {
            this.inProgress = false;
        }
    }

    @action
    pullSettings = async (): Promise<void> => {
        this.settings = await settingsApi.pullSystemSettings();
    };

    @action
    async pullCurrentUserApi() {
        try {
            this.currentUser = await authApi.pullCurrentUser();
            this.pullSettings();
            this.fetchIntervalId = window.setInterval(this.pullSettings, SETTINGS_UPDATE_TIME);
            localStorage.setItem(CURRENT_USER_MEM_KEY, JSON.stringify(this.currentUser));
            if (this.isAdmin()) {
                userStore.fetchCouriers();
            }
        } catch (error) {
            if (error instanceof Array && error[0] === ERROR_FAILED_TO_FETCH) {
                await wait(500);
                this.pullCurrentUserApi();
            } else {
                this.logout();
            }
        }
    }

    @action
    async logout() {
        this.token = null;
        this.loggedIn = false;
        await localStorage.clear();
        window.location.reload();
    }

    @observable
    wbTokenNew: string | null = null;

    @observable
    wbTokenX64: string | null = null;

    @observable
    wbTokenLoading = true;

    @action
    async pullUserWbTokenNew(): Promise<string | null> {
        this.wbTokenNew = await authApi.fetchWbApiKey('new');
        this.wbTokenLoading = false;
        return this.wbTokenNew;
    }

    @action
    async pullUserWbTokenX64(): Promise<string | null> {
        this.wbTokenX64 = await authApi.fetchWbApiKey('x64');
        this.wbTokenLoading = false;
        return this.wbTokenX64;
    }

    @observable
    settings: SystemSettingsType = {
        qr: true,
        carts: true,
        sberpay: true,
        systemMessage: '',
        groupRansoms: true,
        qrOzon: true,

        preRunAccount_wb: true,
        oneTimeAccount_wb: true,
        additionalItems_wb: true,

        reviewsEnable_wb: true,

        oneTimeAccount_ozon: true
    };

    @nProgress
    @action
    async changeSettings(newSettings: Partial<SystemSettingsType>): Promise<void> {
        await settingsApi.updateSettings(newSettings);
        this.settings = { ...this.settings, ...newSettings };
    }

    @nProgress
    @action
    async changeSettingsKey<T extends keyof SystemSettingsType>(key: T, value: SystemSettingsType[T]): Promise<void> {
        await settingsApi.changeSettingsKey(key, value);
        this.settings = { ...this.settings, [key]: value };
    }
}

export default new AuthStore();
