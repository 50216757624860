import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import cartStore from 'stores/cartStore';
import productStore from 'stores/productStore';
import commonStore from 'stores/commonStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import Grid from 'ui/Grid';
import Modal, { ModalBtnAction, ModalContent } from 'ui/Modal';
import ListError from 'ui/ListError';
import InfoPopup from 'ui/InfoPopup';

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Typography from '@mui/material/Typography';

import ProductCard from '../Product/ProductCard';
import CostWithDiscount from '../../CostWithDiscount';
import WbSuggestionsField from '../WbSuggestionsField';
import QuantitySpeedModal from '../QuantitySpeedField';

const CartPopup = React.memo(() => {
    return (
        <InfoPopup size="large">
            <Typography variant="subtitle2">Для чего нужно "Добавлять в корзину"?</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Количество добавлений в корзину учитывается в алгоритмах Wildberries при ранжирование товара в поиске.
                Наш робот зайдет на страницу вашего товара, добавит его в корзину и оставит его в ней.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
                Можно делать примерно 2-4 "добавлений в корзину" на каждый выкуп, чтобы ваш товар соответствовал средним
                критериям на Wildberries и не вызывал подозрительной активности.
            </Typography>
        </InfoPopup>
    );
});

const CartModal = ({ product_id, onClose }: { product_id: number; onClose: () => void }) => {
    const cart_id = CREATING_ITEM_ID;

    useMemo(() => {
        productStore.fetchList();
        cartStore.fetchItem(cart_id);
        cartStore.setEditingItem(cart_id, {
            product_id,
            quantity: 1,
            speed: null,
            searchRequest: null
        });
    }, [cart_id, product_id]);

    const { loadingItem, editingItem, errors } = cartStore.getItem(cart_id);
    const { quantity } = editingItem;

    const handleCreate = async () => {
        if (await cartStore.createItem()) {
            commonStore.setGlobalInfoMessage('Заявка на добавление в корзину создана, мы сообщим о ее осуществление');
            onClose();
        }
    };

    const cost = 4;

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'inherit' },
        {
            onClick: handleCreate,
            label: (
                <>
                    Добавить в корзину (<CostWithDiscount cost={Number(quantity) * cost} />)
                </>
            ),
            loading: loadingItem,
            disabled: !quantity,
            variant: 'contained',
            color: 'secondary',
            endIcon: <ShoppingBasketIcon />
        }
    ];

    const { item: product } = productStore.getItem(product_id);

    return (
        <Modal
            maxWidth="sm"
            onClose={onClose}
            actions={actions}
            header={
                <>
                    <ShoppingBasketIcon />
                    &nbsp;Добавить в корзину
                </>
            }
            fullScreen={commonStore.isMobile}
        >
            <ModalContent>
                <ListError errors={errors} />
                <Grid>
                    <Grid.Column>
                        <ProductCard product={product} size="small" />
                    </Grid.Column>
                    <Grid.Column>
                        <WbSuggestionsField item_id={cart_id} store={cartStore} />
                    </Grid.Column>
                    <QuantitySpeedModal item_id={cart_id} store={cartStore} infoPopup={<CartPopup />} />
                </Grid>
            </ModalContent>
        </Modal>
    );
};

export default observer(CartModal);
