import React from 'react';

import { Delivery } from 'stores/deliveryStore';
import Chip from '@mui/material/Chip';

const DeliveryStatus = ({ status, enable }: { status: Delivery['status']; enable: boolean }) => {
    if (!enable) {
        return <Chip label="Отменена" color="default" sx={{ cursor: 'pointer' }} />;
    }

    switch (status) {
        case 1:
            return <Chip label="Проверка оплаты" color="warning" sx={{ cursor: 'pointer' }} />;
        case 2:
            return <Chip label="Доставка до ПВЗ" color="info" sx={{ cursor: 'pointer' }} />;
        case 3:
            return <Chip label="Готов к выдаче" color="success" sx={{ cursor: 'pointer' }} />;
        case 4:
            return <Chip label="Выдан" color="primary" sx={{ cursor: 'pointer' }} />;
        case 5:
            return <Chip label="Возврат" color="error" sx={{ cursor: 'pointer' }} />;
        case 6:
            return <Chip label="Оставлен отзыв" color="primary" sx={{ cursor: 'pointer' }} />;
        case 100:
            return <Chip label="Ошибка" color="error" sx={{ cursor: 'pointer' }} />;
        default:
            const error: never = status;
            console.log(error);
    }
    return null;
};

export default DeliveryStatus;
