import fetchApi from '../common/fetchApi';

export const findWbVideo = async (
    item_type: 'ransom' | 'delivery' | 'question' | 'review' | 'brand',
    item_id: number,
    otherType: number | null
): Promise<string> => {
    const graphql = `{
            findWbVideo (item_type: "${item_type}", item_id: ${item_id}, otherType: ${otherType})
          }`;

    const data = await fetchApi.getGQ<{ findWbVideo: string }>(graphql);
    return data.findWbVideo;
};
