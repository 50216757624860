import React, { SyntheticEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import wbAccountStore from 'stores/wbAccountStore';
import * as wbAccountApi from 'api/wbAccountApi';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import ListError from 'ui/ListError';
import { SuccessMessage } from 'ui/Message';

import Grid from 'ui/Grid';
import Button from 'ui/Button';

let onMessage: (event: { data: { operation: string; cookie: string } }) => void;

let selectedAccountId: number;

const OpenWbBox = ({ onClose }: { onClose: () => void }) => {
    useEffect(() => {
        wbAccountStore.fetchList();
    }, []);

    const { list, listErrors } = wbAccountStore;
    const [account_id, setAccountId] = useState<number | null>(null);
    const [saveDone, setSaveDone] = useState(false);

    const onSelect = (event: SyntheticEvent, data: { value: number } | null) => {
        if (data?.value) {
            const { value } = data;
            setSaveDone(false);
            selectedAccountId = value;
            setAccountId(value);
            wbAccountStore.fetchItem(value);
        }
    };

    const openWb = () => {
        if (account_id) {
            const { WILDAUTHNEW_V3 } = wbAccountStore.findById(account_id);
            window.postMessage({
                operation: 'openWbAccount',
                WILDAUTHNEW_V3
            });
        }
    };

    onMessage = ({ data }) => {
        console.log('onmessage', data, selectedAccountId);
        setSaveDone(false);
        if (data.operation === 'WILDAUTHNEW_V3' && selectedAccountId) {
            wbAccountApi.saveItem(selectedAccountId, { WILDAUTHNEW_V3: data.cookie }).then(() => setSaveDone(true));
        }
    };

    useEffect(() => {
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, []);

    return (
        <Grid>
            <Grid.Column>
                <Button variant="text" color="inherit" startIcon={<ArrowBackIcon />} onClick={onClose}>
                    назад
                </Button>
            </Grid.Column>
            <Grid.Column>
                <ListError errors={listErrors} />
                {/*<Select*/}
                {/*    label="Аккаунты WB"*/}
                {/*    variant="standard"*/}
                {/*    value={account_id}*/}
                {/*    onChange={onSelect}*/}
                {/*    options={list.map(({ account_id, phone, gender }) => ({*/}
                {/*        value: account_id,*/}
                {/*        text: `${phone} (${*/}
                {/*            gender === 'male' ? 'муж' : gender === 'female' ? 'жен' : '---'*/}
                {/*        }) #${account_id}`*/}
                {/*    }))}*/}
                {/*    loading={loadingList}*/}
                {/*    fullWidth*/}
                {/*/>*/}
                <Autocomplete
                    disablePortal
                    options={list.map(({ account_id, phone, gender, priority }) => ({
                        value: account_id,
                        label: `#${account_id} :: ${phone} (${
                            gender === 'male' ? 'муж' : gender === 'female' ? 'жен' : '---'
                        }) П${priority}`
                    }))}
                    renderInput={params => <TextField {...params} label="Аккаунты WB" />}
                    fullWidth
                    onChange={onSelect}
                />
            </Grid.Column>
            <Grid.Column>
                <Button color="secondary" variant="contained" onClick={openWb} fullWidth disabled={!account_id}>
                    Открыть "залогиненый" WB
                </Button>
            </Grid.Column>
            <Grid.Column>
                {/*<Button color="info" variant="outlined" fullWidth onClick={checkCookie} disabled={!account_id}>*/}
                {/*    Перезаписать КУКУ*/}
                {/*</Button>*/}
                {saveDone && <SuccessMessage>Кука сохранена</SuccessMessage>}
            </Grid.Column>
        </Grid>
    );
};

export default observer(OpenWbBox);
