import React, { useState } from 'react';
import { StoreIds } from 'stores/productStore';

import Stack from '@mui/material/Stack';

import WbAdsRatesCardTable from './WbAdsRatesCardTable';
import ProductSearchDropdownAutocomplete from '../Products/ProductSearchDropdownAutocomplete';
import { InfoMessage } from 'ui/Message';

const WbAdsRatesByCard = () => {
    const [wbItemId, setWbItemId] = useState<number | null>(null);

    return (
        <Stack spacing={2}>
            <ProductSearchDropdownAutocomplete
                onChange={value => value && setWbItemId(Number(value.value))}
                value={null}
                showPopup={false}
                store_id={StoreIds.WB}
            />
            {wbItemId && <WbAdsRatesCardTable wb_item_id={wbItemId} />}
            {!wbItemId && (
                <InfoMessage header="Рекламные ставки конкурентов">
                    Рекламные ставки в карточке на Wildberries
                </InfoMessage>
            )}
        </Stack>
    );
};

export default WbAdsRatesByCard;
