import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import favoriteStore from 'stores/favoriteStore';

import Table, { TableHead, TableRow, TableCell, TableBody } from 'ui/Table';
import Time from 'ui/Time';
import { WarningMessage } from 'ui/Message';
import CutString from 'ui/CutString';

import Chip from '@mui/material/Chip';

import ListWrapper from '../ListWrapper';
import ListPagination from '../ListPagination';
import ProductTitleLink from '../Products/ProductTitleLink';
import FavoritesFilter from './FavoritesFilter';

const FavoritesList = () => {
    const { list, loadingList, handleSort, adjustDirection } = favoriteStore;

    return (
        <ListWrapper store={favoriteStore} bgUrl="/static/listbg/unruly-02-workflow.webp">
            <FavoritesFilter />

            <Table elevation={0} loading={loadingList}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sortDirection={adjustDirection('createTime')}
                            onClick={() => handleSort('createTime')}
                        >
                            Дата
                        </TableCell>
                        <TableCell sortDirection={adjustDirection('status')} onClick={() => handleSort('status')}>
                            Статус
                        </TableCell>
                        <TableCell
                            sortDirection={adjustDirection('product_id')}
                            onClick={() => handleSort('product_id')}
                            sx={{ minWidth: '250px' }}
                        >
                            Наименование
                        </TableCell>
                        <TableCell>Количество</TableCell>
                        <TableCell>Запрос</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(
                        ({ favorite_id, updateTime, speed, quantity, product, createTime, searchRequest, status }) => (
                            <TableRow key={favorite_id}>
                                <TableCell>
                                    <Time time={createTime} />
                                </TableCell>
                                <TableCell>
                                    {status === 1 && <Chip label="Выполняется" color="warning" />}
                                    {status === 2 && <Chip label="Выполнено" color="success" />}
                                </TableCell>

                                <TableCell>
                                    <ProductTitleLink product={product} />
                                </TableCell>
                                <TableCell align="center">{quantity}</TableCell>
                                <TableCell>
                                    <CutString title={searchRequest} maxLength={30} />
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
                <ListPagination
                    pagination={favoriteStore.pagination}
                    listCount={favoriteStore.listCount}
                    pageChange={favoriteStore.pageChange}
                    pageSizeChange={favoriteStore.pageSizeChange}
                    colSpan={4}
                />
            </Table>

            {!loadingList && list.length === 0 && (
                <WarningMessage header="Нет добавлений в Избранное">
                    Вы можете добавить ваш <Link to="/products/list">товар</Link> в Избранное
                </WarningMessage>
            )}
        </ListWrapper>
    );
};

export default observer(FavoritesList);
