import React, { ReactNode } from 'react';

import { StoreIds } from 'stores/productStore';

import { ErrorMessage } from 'ui/Message';

const StoreRequestError = ({
    error,
    store_id,
    children
}: {
    error?: string | null;
    store_id?: StoreIds;
    children?: ReactNode;
}) => {
    return (
        <ErrorMessage header={`Произошла ошибка на стороне ${store_id === StoreIds.WB ? 'Wildberries' : 'Ozon'}`}>
            {children || error}
        </ErrorMessage>
    );
};

export default StoreRequestError;
