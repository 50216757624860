import React from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import productStore, { StoreIds } from 'stores/productStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import ransomStore from 'stores/ransomStore';

import Table, { TableBody, TableCell, TableHead, TableRow } from 'ui/Table';
import { WarningMessage } from 'ui/Message';

import ProductSearchDropdown from './ProductSearchDropdown';
import { RansomLinkState } from 'components/Items/Ransom/RansomLink';

import ListWrapper from '../ListWrapper';
import ProductTableCell from './ProductTableCell';
import Box from '@mui/material/Box';

const ProductsList = () => {
    const { list, loadingList, handleSort, adjustDirection, listFilter } = productStore;

    const toggleRansomModal = (product_id: number) => {
        setTimeout(() => {
            productStore.fetchItem(product_id);
            ransomStore.fetchItem(CREATING_ITEM_ID);
            ransomStore.setEditingItem(CREATING_ITEM_ID, {
                product_id
            });
        }, 0);
        locationHistory.push(RansomLinkState(CREATING_ITEM_ID));
    };

    return (
        <ListWrapper store={productStore} bgUrl="/static/listbg/unruly-06-searching.webp">
            <Box sx={{ px: 2 }}>
                <ProductSearchDropdown />
            </Box>

            <Table elevation={0} loading={loadingList}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sortDirection={adjustDirection('wb_item_id')}
                            onClick={() => handleSort('wb_item_id')}
                        >
                            Артикул
                        </TableCell>
                        <TableCell
                            sx={{ minWidth: '300px' }}
                            sortDirection={adjustDirection('title')}
                            onClick={() => handleSort('title')}
                        >
                            Наименование
                        </TableCell>
                        {listFilter.store_id === StoreIds.Ozon && <TableCell></TableCell>}
                        <TableCell
                            sortDirection={adjustDirection('priceWithSale')}
                            onClick={() => handleSort('priceWithSale')}
                        >
                            Цена
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(item => (
                        <ProductTableCell
                            key={item.product_id}
                            productItem={item}
                            toggleRansomModal={toggleRansomModal}
                        />
                    ))}
                </TableBody>
            </Table>

            {!loadingList && list.length === 0 && (
                <WarningMessage header="Нет товаров">
                    Добавьте сюда свои товары на {listFilter.store_id === StoreIds.WB ? 'Wildberries' : 'OZON'}
                </WarningMessage>
            )}
        </ListWrapper>
    );
};

export default observer(ProductsList);
