import * as wbAccountApi from 'api/wbAccountApi';
import ListStorePrototype from './prototypes/ListStore.prototype';
import axios from 'axios/index';
import * as deliveryPointsApi from '../api/deliveryPointsApi';
import { StoreIds } from './productStore';

export type WBAccount = {
    account_id: number;
    phone: string;
    priority: number;
    gender: 'male' | 'female' | null;
    WILDAUTHNEW_V3: string;

    onlineTime: number;
    createTime: number;
    updateTime: number;
    enable: boolean;
};

class WBAccountStore extends ListStorePrototype<WBAccount, WBAccount> {
    listFilterClear = {};

    constructor() {
        super('account_id', 'accounts', wbAccountApi);
    }

    async openAccountInElectron(account_id: number, url?: string): Promise<void> {
        const { WILDAUTHNEW_V3 } = await wbAccountApi.fetchItem(account_id);
        await axios.post('http://localhost:11390/open/wb', { WILDAUTHNEW_V3, url });
    }

    async setAddressInElectron(address: string): Promise<void> {
        console.log({ address });
        const list = await deliveryPointsApi.searchStoreDeliveryPointsList({ store_id: StoreIds.WB, address });
        if (list.length === 1) {
            await axios.post('http://localhost:11390/wb/setStorePoint', { wb_store_id: list[0].store_point_id });
        } else {
            alert(`Адрес не найден, количество найденных: ${list.length}`);
        }
    }
}

export default new WBAccountStore();
