import React from 'react';

import productStore, { OzonItemAspectType, ProductList, whetherProductListIsOzon } from 'stores/productStore';

import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { TableCell, TableRow } from 'ui/Table';
import Time from 'ui/Time';
import Button from 'ui/Button';

import ProductLink from 'components/Items/Product/ProductLink';
import Chip from '@mui/material/Chip';
import Price from 'components/Price';
import Box from '@mui/material/Box';

type ProductTableCellProps = {
    productItem: ProductList;
    toggleRansomModal: (product_id: number) => void;
};

const OzonItemAspects = ({ aspects }: { aspects: OzonItemAspectType[] }) => {
    return (
        <Stack>
            {aspects.map(({ aspect, aspectValue }) => (
                <Box key={aspect}>
                    {aspect}: {aspectValue}
                </Box>
            ))}
        </Stack>
    );
};

const ProductTableCell = ({ productItem, toggleRansomModal }: ProductTableCellProps) => {
    const { store_item_id, product_id, createTime } = productItem;
    let { price, title, photos } = productStore.calcShortProduct(productItem);

    return (
        <TableRow>
            <TableCell>
                <Stack spacing={1} direction="row">
                    <Avatar src={photos[0]} variant="rounded" />
                    <Stack spacing={0}>
                        <Typography variant="subtitle2">{store_item_id}</Typography>
                        <Time time={createTime} />
                    </Stack>
                </Stack>
            </TableCell>
            <TableCell>
                <ProductLink product_id={product_id} title={title} />
            </TableCell>
            {whetherProductListIsOzon(productItem) && (
                <TableCell>
                    <OzonItemAspects aspects={productItem.ozonItem.aspects} />
                </TableCell>
            )}
            <TableCell colSpan={!price ? 2 : 1}>
                {price && <Price {...price} />}
                {!price && <Chip label="Нет в наличии" color="warning" />}
            </TableCell>
            {price && (
                <TableCell>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => toggleRansomModal(product_id)}
                        tooltip="Создать выкуп данного товара"
                    >
                        Выкуп
                    </Button>
                </TableCell>
            )}
        </TableRow>
    );
};

export default ProductTableCell;
