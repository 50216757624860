import React from 'react';
import { WbItemFeedbacksAnswer } from 'api/productApi';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Time from 'ui/Time';
import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';

const ProductFeedbackAnswer = ({ createTime, text }: WbItemFeedbacksAnswer) => {
    const [show, toggleShow] = useToggleModal();

    return (
        <Box sx={{ mt: 1, pl: 2 }}>
            <Stack direction="row" spacing={2} alignItems="center">
                <Button
                    size="small"
                    color="secondary"
                    onClick={toggleShow}
                    tooltip="Ответ от представителя Бренда"
                    sx={{ textTransform: 'none', textDecoration: 'underline dotted' }}
                >
                    {show ? 'Скрыть' : 'Показать'} ответ
                </Button>
                {createTime && (
                    <Typography variant="caption">
                        <Time time={createTime} />
                    </Typography>
                )}
            </Stack>
            {show && <Typography variant="caption">{text}</Typography>}
        </Box>
    );
};

export default React.memo(ProductFeedbackAnswer);
