import React, { MouseEvent, useState } from 'react';
import { observer } from 'mobx-react';
import authStore from 'stores/authStore';
import commonStore from 'stores/commonStore';

import Button from 'ui/Button';

import UserAvatar from 'ui/UserAvatar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Divider from 'ui/Divider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useToggleModal } from 'ui/Modal';

import TransactionsModal from './TransactionsModal';
import OrderModal from './OrderModal';

const SettingsDropdown = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [showTransactionsModal, toggleTransactionsModal] = useToggleModal();
    const openTransactionsModal = () => {
        toggleTransactionsModal();
        handleClose();
    };

    const [showOrderModal, toggleOrderModal] = useToggleModal();
    const openOrderModal = () => {
        toggleOrderModal();
        handleClose();
    };

    const { currentUser } = authStore;
    const discount = Number(currentUser?.discount);
    const firstPaymentGift = Number(currentUser?.firstPaymentGift);
    const firstPaymentExpireTime = Number(currentUser?.firstPaymentExpireTime);

    const currentUserTitle =
        currentUser?.firstName || currentUser?.lastName || (currentUser?.phone && `+${currentUser?.phone}`) || '---';
    const currentUserBalance = currentUser?.balance ? Number(currentUser?.balance).toLocaleString() : '---';

    return (
        <>
            <Button onClick={handleOpen}>
                {!commonStore.isMobile && authStore.isAdmin() && (
                    <Chip color="secondary" label="Админ" sx={{ mr: 1 }} />
                )}
                <UserAvatar
                    src={null}
                    size={32}
                    firstName={currentUser?.firstName || ''}
                    lastName={currentUser?.lastName || ''}
                />
                &nbsp;
                {currentUserTitle}&nbsp;({currentUserBalance}₽)
                {!commonStore.isMobile && discount > 0 && (
                    <Tooltip title="Ваша скидка">
                        <Chip color="secondary" label={`-${discount}%`} sx={{ ml: 2 }} />
                    </Tooltip>
                )}
                {!commonStore.isMobile && firstPaymentGift > 0 && (
                    <Tooltip
                        title={`Бонус на пополнение счета до ${new Date(firstPaymentExpireTime * 1000).toLocaleString(
                            'ru'
                        )}`}
                    >
                        <Chip color="success" label={`+${firstPaymentGift}%`} sx={{ ml: 2 }} />
                    </Tooltip>
                )}
            </Button>

            {showTransactionsModal && <TransactionsModal onClose={toggleTransactionsModal} />}
            {showOrderModal && <OrderModal onClose={toggleOrderModal} />}

            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                anchorEl={anchorEl}
                onClose={handleClose}
                open={Boolean(anchorEl)}
            >
                <Box sx={{ p: 4 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flex: '1 auto',
                            gap: 4,
                            pb: 2,
                            alignItems: 'center',
                            maxWidth: '100vw',
                            width: '330px'
                        }}
                    >
                        <UserAvatar
                            src={null}
                            size={64}
                            firstName={currentUser?.firstName || ''}
                            lastName={currentUser?.lastName || ''}
                        />
                        <div>
                            <Typography variant="subtitle1">{currentUser?.firstName}</Typography>
                            <Typography variant="subtitle1">{currentUser?.lastName}</Typography>
                            <Typography variant="subtitle1">+{currentUser?.phone}</Typography>
                        </div>
                    </Box>
                    <Box sx={{ my: 1 }}>
                        <Button
                            variant="outlined"
                            fullWidth
                            tooltip="Посмотреть историю операций"
                            onClick={openTransactionsModal}
                        >
                            Баланс &nbsp;<b>{Number(currentUser?.balance).toLocaleString()} ₽</b>&nbsp;
                            {discount > 0 ? `(скидка: ${discount}%)` : ''}
                        </Button>
                    </Box>
                    <Button
                        startIcon={<AccountBalanceWalletIcon />}
                        primary
                        variant="contained"
                        fullWidth
                        onClick={openOrderModal}
                    >
                        Пополнить
                    </Button>
                    <Divider />
                    <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        endIcon={<ExitToAppIcon />}
                        onClick={() => authStore.logout()}
                    >
                        Выйти
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default observer(SettingsDropdown);
