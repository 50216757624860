import React from 'react';

import productStore, { ProductList } from 'stores/productStore';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

const BrandTitleCell = ({ product }: { product: ProductList }) => {
    const { brand } = productStore.calcShortProduct(product);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Tooltip title={brand.name}>
                <>
                    {brand.logoUrl && <img src={brand.logoUrl} height={32} alt={brand.name} />}
                    {!brand.logoUrl && <div>{brand.name}</div>}
                </>
            </Tooltip>
        </Box>
    );
};

export default BrandTitleCell;
