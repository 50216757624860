import React from 'react';

import commonStore from 'stores/commonStore';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';

import Grid from 'ui/Grid';

const ProsCard = ({ header, text, imgSrc }: { header: string; text: string; imgSrc: string }) => {
    return (
        <Card sx={{ display: 'flex', background: '#1b1a24', color: 'white', borderRadius: 4 }}>
            <Box sx={{ m: 1, ml: 2, my: 2 }}>
                <Avatar variant={'circular'} style={{ width: `${48}px`, height: `${48}px` }} src={imgSrc} />
            </Box>
            <CardContent>
                <Typography variant="h5" component="div">
                    {header}
                </Typography>
                <Typography sx={{ mb: 1.5, opacity: 0.7 }}>{text}</Typography>
            </CardContent>
        </Card>
    );
};

const FFProsList = () => {
    return (
        <Grid columns={commonStore.isMobile ? 1 : 2}>
            <Grid.Column>
                <ProsCard
                    header="Доставка по всей стране"
                    text="Отгрузим со своих складов в 7 городах"
                    imgSrc="https://static.tildacdn.com/tild3866-3032-4234-b538-653231623466/Frame_89.svg"
                />
            </Grid.Column>
            <Grid.Column>
                <ProsCard
                    header="Хранение любых объемов"
                    text="Излишки храним у нас до следующей поставки"
                    imgSrc="https://static.tildacdn.com/tild3032-3037-4462-a433-613765666463/Frame_90.svg"
                />
            </Grid.Column>
            <Grid.Column>
                <ProsCard
                    header="Отправка на все склады Маркетплейсов"
                    text="WB, Ozon, Яндекс.Маркет, Авито, СберМегаМаркет, KazanExpress, Lamoda и другие МП и интернет-площадки"
                    imgSrc="https://static.tildacdn.com/tild3861-3530-4731-b530-643535653734/Frame_91.svg"
                />
            </Grid.Column>
            <Grid.Column>
                <ProsCard
                    header="Разместим ваш товар на всех маркетплейсах"
                    text="Возьмем под контроль поставки, включая систему FBS (Маркетплейс)"
                    imgSrc="https://static.tildacdn.com/tild6264-3464-4438-a661-393762396239/Frame_92.svg"
                />
            </Grid.Column>
            <Grid.Column>
                <ProsCard
                    header="Оптимальная упаковка по необходимым стандартам"
                    text=""
                    imgSrc="https://static.tildacdn.com/tild6261-6632-4765-b361-316537636539/Frame_93.svg"
                />
            </Grid.Column>
            <Grid.Column>
                <ProsCard
                    header="Обработка всех товарных групп"
                    text="КГТ, хрупкие, мелкие, 18+"
                    imgSrc="https://static.tildacdn.com/tild6230-3264-4435-a263-393539323865/Frame_94.svg"
                />
            </Grid.Column>
        </Grid>
    );
};

export default React.memo(FFProsList);
