import React, { useState } from 'react';
import { observer } from 'mobx-react';
import deliveryStore from 'stores/deliveryStore';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import DeliveryMapListItems from './DeliveryMapListItems';

import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800]
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)'
}));

const DeliveryMapView = () => {
    const { mapDeliveriesList } = deliveryStore;

    const drawerBleeding = 56;
    const container = window !== undefined ? () => window.document.body : undefined;

    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    return (
        <SwipeableDrawer
            container={container}
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{
                keepMounted: true
            }}
            variant="persistent"
            sx={{
                '& .MuiDrawer-paper': {
                    height: `calc(50% - ${drawerBleeding}px)`,
                    overflow: 'visible',
                    width: '100vw',
                    maxWidth: '100vw',
                    backgroundColor: '#eef0f1'
                }
            }}
        >
            <StyledBox
                sx={{
                    position: 'absolute',
                    top: -drawerBleeding,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    visibility: 'visible',
                    right: 0,
                    left: 0
                }}
                onClick={toggleDrawer(!open)}
            >
                <Puller />
                <Typography sx={{ p: 2, color: 'text.secondary' }}>{mapDeliveriesList.length} доставок</Typography>
            </StyledBox>
            <StyledBox
                sx={{
                    px: 2,
                    pb: 2,
                    height: '100%',
                    overflow: 'auto'
                }}
            >
                {/*<Skeleton variant="rectangular" height="100%" />*/}
                <DeliveryMapListItems />
            </StyledBox>
        </SwipeableDrawer>
    );
};

export default observer(DeliveryMapView);
