import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import serverConfig from 'config/server.config';

import { downloadDeliveriesXls } from 'api/deliveryApi';

import deliveryStore, { Delivery } from 'stores/deliveryStore';
import productStore from 'stores/productStore';
import authStore from 'stores/authStore';
import commonStore from 'stores/commonStore';

import Select from 'ui/Select';
import Checkbox from 'ui/Checkbox';
import Button from 'ui/Button';
import ListError from 'ui/ListError';

import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import ListFilterWrapper from '../ListFilterWrapper';
import StoresToggle from 'components/StoresToggle';

export const DeliveriesFilterInner = observer(() => {
    useMemo(() => {
        deliveryStore.changeFilter('store_id', productStore.getCurrentStoreId());
        if (authStore.isAdmin()) {
            deliveryStore.changeFilter('allUsers', true);
        }
        productStore.debounceFilterFetch();
    }, []);

    useEffect(() => {
        deliveryStore.changeFilter('store_id', productStore.getCurrentStoreId());
    }, [productStore.listFilter.store_id]);

    const { listFilter, deliveriesStoresOptions, loadingDeliveriesStores } = deliveryStore;
    const { status, allUsers } = listFilter;

    const handleChangeStatus = ({ value }: { value: Delivery['status'] | null }) => {
        deliveryStore.changeFilter('status', value);
    };

    const handleToggleAllUsers = () => {
        deliveryStore.changeFilter('allUsers', !allUsers);
    };

    return (
        <>
            {authStore.isAdmin() && <Checkbox label="все" checked={allUsers} onClick={handleToggleAllUsers} />}

            <StoresToggle />

            <Select
                label="Статус"
                size="small"
                variant="standard"
                value={status}
                onChange={handleChangeStatus}
                options={[
                    { value: 1, text: 'Проверка оплаты' },
                    { value: 2, text: 'Доставка до ПВЗ' },
                    { value: 3, text: 'Готов к выдаче' },
                    { value: 4, text: 'Выдан' },
                    { value: 5, text: 'Возврат' },
                    { value: 6, text: 'Оставлен отзыв' },
                    { value: 100, text: 'Ошибка' }
                ]}
                clearable
                fullWidth
            />
        </>
    );
});

const DeliveriesFilter = () => {
    const [loadingFile, setLoadingFile] = useState(false);
    const [fileErrors, setFileErrors] = useState<string[]>([]);

    const { listFilter, deliveriesStoresOptions, loadingDeliveriesStores } = deliveryStore;
    const { product_id, wb_store_id } = listFilter;

    const { productOptions, loadingList: loadingListProduct } = productStore;

    const handleChangeProductId = ({ value }: { value: number | null }) => {
        deliveryStore.changeFilter('product_id', value);
    };

    const handleChangeWbStoreId = ({ value }: { value: number | null }) => {
        deliveryStore.changeFilter('wb_store_id', value);
    };

    const handleDownload = async () => {
        setLoadingFile(true);
        setFileErrors([]);
        try {
            const filename = await downloadDeliveriesXls(
                deliveryStore.orderBy,
                deliveryStore.orderDirection,
                deliveryStore.listFilter
            );

            const fileBtn = document.createElement('a');
            fileBtn.download = filename;
            fileBtn.href = serverConfig.photoUrl + '/' + filename;
            document.body.appendChild(fileBtn);
            fileBtn.click();
            document.body.removeChild(fileBtn);
        } catch (errors) {
            if (errors instanceof Array) {
                setFileErrors(errors);
            }
        } finally {
            setLoadingFile(false);
        }
    };

    return (
        <>
            <ListError errors={fileErrors} />
            <ListFilterWrapper store={deliveryStore}>
                <DeliveriesFilterInner />

                <Select
                    label="Товар"
                    value={product_id}
                    onChange={handleChangeProductId}
                    options={productOptions}
                    loading={loadingListProduct}
                    size="small"
                    variant="standard"
                    fullWidth
                    clearable
                />

                <Select
                    label="ПВЗ"
                    value={wb_store_id}
                    onChange={handleChangeWbStoreId}
                    options={deliveriesStoresOptions}
                    loading={loadingDeliveriesStores}
                    size="small"
                    variant="standard"
                    fullWidth
                    clearable
                />

                {!commonStore.isMobile && (
                    <Button
                        startIcon={<SimCardDownloadIcon />}
                        tooltip="Скачать все в Excel файл, согласно настройкам фильтра таблицы"
                        variant="outlined"
                        color="success"
                        onClick={handleDownload}
                        loading={loadingFile}
                        disabled={deliveryStore.loadingList || deliveryStore.list.length === 0}
                    >
                        xls
                    </Button>
                )}
            </ListFilterWrapper>
        </>
    );
};

export default observer(DeliveriesFilter);
