import React, { SyntheticEvent, useState } from 'react';
import { observer } from 'mobx-react';
import * as productApi from 'api/productApi';

import authStore from 'stores/authStore';

import Modal, { ModalContent } from 'ui/Modal';
import TextInput from 'ui/TextInput';
import Grid from 'ui/Grid';
import Button from 'ui/Button';
import ListError from 'ui/ListError';
import { SuccessMessage } from 'ui/Message';

import SettingsIcon from '@mui/icons-material/Settings';
import Stack from '@mui/material/Stack';

import WbTokenField from 'components/List/Api/ApiCard/WbTokenNewField';

const ProductApiModal = ({
    onClose,
    product_id,
    title
}: {
    product_id: number;
    onClose: () => void;
    title: string;
}) => {
    const { wbTokenNew } = authStore;

    const [newTitle, setNewTitle] = useState(title);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [saveDone, setSaveDone] = useState(false);

    const handleChangeTitle = (event: SyntheticEvent, { value }: { value: string }) => {
        setNewTitle(value);
    };

    const handleSaveTitle = async () => {
        try {
            setErrors([]);
            setLoading(true);
            setSaveDone(false);
            await productApi.changeItemWbTitle(product_id, newTitle);
            setSaveDone(true);
        } catch (errors) {
            if (errors instanceof Array) {
                setErrors(errors);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            header={
                <Stack direction="row" alignItems="center" gap={1}>
                    <SettingsIcon fontSize="small" />
                    Поменять Наименование продукта на WB
                </Stack>
            }
            maxWidth="sm"
            onClose={onClose}
        >
            <ModalContent>
                <Grid>
                    <WbTokenField />
                    {saveDone && (
                        <Grid.Column>
                            <SuccessMessage header="Наименование изменено">
                                Запрос по api прошел удачно. На сайте Wildberries Наименование вашего товара поменяется
                                в течение 30 минут.
                            </SuccessMessage>
                        </Grid.Column>
                    )}
                    <Grid.Column>
                        <ListError errors={errors} />
                        <TextInput
                            value={newTitle}
                            onChange={handleChangeTitle}
                            variant="classic"
                            multiline
                            rows={1}
                            rowsMax={4}
                            disabled={!wbTokenNew || loading}
                        />
                    </Grid.Column>
                    <Grid.Column sx={{ textAlign: 'right' }}>
                        <Button
                            onClick={handleSaveTitle}
                            loading={loading}
                            color="secondary"
                            variant="outlined"
                            disabled={!wbTokenNew}
                        >
                            Поменять
                        </Button>
                    </Grid.Column>
                </Grid>
            </ModalContent>
        </Modal>
    );
};

export default observer(ProductApiModal);
