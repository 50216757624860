import React, { ReactNode, CSSProperties, SyntheticEvent, ChangeEvent } from 'react';

import MUCheckbox from '@mui/material/Checkbox';
import MUSwitch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type CheckboxProps = {
    label?: ReactNode;
    value?: string | number;
    name?: string;
    checked?: boolean | null;
    disabled?: boolean;
    title?: string;
    style?: CSSProperties;
    size?: 'medium' | 'small';
    sx?: SxProps<Theme>;
} & (
    | {
          onChange?:
              | ((event: SyntheticEvent) => void)
              | ((event: SyntheticEvent, { name, checked }: { name: string; checked: boolean }) => void);
      }
    | { handleChange?: (event: SyntheticEvent, { name, checked }: { name: string; checked: boolean }) => void }
    | { onClick: (...args: any[]) => void }
);

export const Checkbox = (props: CheckboxProps) => {
    const { label, value, name, checked, disabled, title, style, size, sx } = props;

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if ('onClick' in props) {
            props.onClick(event.target.checked);
            return;
        }
        if ('onChange' in props && props.onChange) {
            props.onChange(event, { name: String(name), checked: !checked });
            return;
        }
        if ('handleChange' in props && props.handleChange) {
            props.handleChange(event, { name: String(name), checked: !checked });
            return;
        }
    };

    const Element = (
        <FormControlLabel
            control={
                <MUCheckbox
                    checked={Boolean(checked)}
                    disabled={disabled}
                    onChange={handleChange}
                    name={name}
                    value={value}
                    style={style}
                    size={size}
                />
            }
            label={label}
            sx={sx}
        />
    );

    if (title) {
        return <Tooltip title={title}>{Element}</Tooltip>;
    }

    return Element;
};

export const Switch = (props: CheckboxProps) => {
    const { label, value, name, checked, disabled, title } = props;

    const handleChange = (event: SyntheticEvent) => {
        if ('onClick' in props) {
            props.onClick();
            return;
        }
        if ('onChange' in props && props.onChange) {
            props.onChange(event, { name: String(name), checked: !checked });
            return;
        }
        if ('handleChange' in props && props.handleChange) {
            props.handleChange(event, { name: String(name), checked: !checked });
            return;
        }
    };

    const Element = (
        <FormControlLabel
            control={
                <MUSwitch
                    checked={Boolean(checked)}
                    disabled={disabled}
                    onClick={handleChange}
                    name={name}
                    value={value}
                />
            }
            label={label}
        />
    );

    if (title) {
        return <Tooltip title={title}>{Element}</Tooltip>;
    }

    return Element;
};

export default Checkbox;
