import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import deliveryStore from 'stores/deliveryStore';
import commonStore from 'stores/commonStore';
import reviewStore from 'stores/reviewStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import productStore from 'stores/productStore';

import ListError from 'ui/ListError';
import Progress from 'ui/Progress';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { ReviewLinkState } from '../../Items/Review/ReviewLink';
import Tooltip from '@mui/material/Tooltip';

const LoadingTab = ({ value }: { value: number }) => {
    return (
        <Tab
            value={value}
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <Skeleton variant="rounded" width={64} height={64} />
                    <div>
                        <Skeleton variant="text" sx={{ minWidth: '100px' }} />
                        <Skeleton variant="text" sx={{ minWidth: '100px' }} />
                    </div>
                </Box>
            }
            disabled={false}
            wrapped
            sx={{ px: commonStore.isMobile ? 1 : 3 }}
        />
    );
};

const SuitableDeliveriesTabs = () => {
    useEffect(() => {
        deliveryStore.resetSuitableDeliveries();
        deliveryStore.fetchSuitableDeliveries(productStore.getCurrentStoreId());
    }, [productStore.listFilter.store_id]);

    const handleCreateReview = (product_id: number, ransom_id: number) => {
        setTimeout(() => {
            reviewStore.createEmptyReview(product_id, ransom_id);
        }, 0);
        locationHistory.push(ReviewLinkState(CREATING_ITEM_ID));
    };

    const { suitableDeliveriesToReview, loadingSuitableDeliveriesToReview, errorsSuitableDeliveriesToReview } =
        deliveryStore;

    if (!loadingSuitableDeliveriesToReview && suitableDeliveriesToReview.length === 0) {
        return <ListError errors={errorsSuitableDeliveriesToReview} />;
    }

    return (
        <>
            <Typography>Доступные товары для отзыва</Typography>

            <Tabs allowScrollButtonsMobile indicatorColor="secondary" textColor="secondary" variant="scrollable">
                {loadingSuitableDeliveriesToReview && [1, 2, 3, 4].map(index => <LoadingTab value={index} />)}
                {suitableDeliveriesToReview.map(({ delivery_id, ransom: { product, product_id, ransom_id } }) => {
                    const { title, photos } = productStore.calcShortProduct(product);
                    return (
                        <Tab
                            value={delivery_id}
                            key={delivery_id}
                            label={
                                <Tooltip title="Добавить отзыв на данный товар">
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                        <div>
                                            <Avatar
                                                src={photos[0]}
                                                variant="rounded"
                                                sx={{ width: '64px', height: '64px' }}
                                            />
                                        </div>
                                        {title}
                                    </Box>
                                </Tooltip>
                            }
                            onClick={() => product_id && handleCreateReview(product_id, ransom_id)}
                            disabled={false}
                            wrapped
                            sx={{ px: commonStore.isMobile ? 1 : 3 }}
                        />
                    );
                })}
            </Tabs>
        </>
    );
};

export default observer(SuitableDeliveriesTabs);
