import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import autoAnswerStore from 'stores/autoAnswerStore';

import AutoAnswerEditing from './AutoAnswerEditing';
import ItemDrawWrapper, { MenuTab } from '../ItemDrawWrapper';

const AutoAnswer = () => {
    const { item_id: item_id_prepare, tabName }: { item_id?: string; tabName?: string } = useParams();
    const auto_answer_id = Number(item_id_prepare) || CREATING_ITEM_ID;

    useMemo(() => {
        autoAnswerStore.fetchItem(auto_answer_id);
    }, [auto_answer_id]);

    const tabs: MenuTab[] = [
        { id: 'main', label: 'Шаблон автоответа', pathname: `/brands/list/detail/${auto_answer_id}` }
    ];

    return (
        <ItemDrawWrapper tabs={tabs} activeTab={tabName || 'main'}>
            {(!tabName || tabName === 'main') && <AutoAnswerEditing auto_answer_id={auto_answer_id} />}
        </ItemDrawWrapper>
    );
};

export default observer(AutoAnswer);
