import React from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import questionStore from 'stores/questionStore';
import productStore from 'stores/productStore';

import Grid from 'ui/Grid';
import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
// import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import CartModal from 'components/Items/Cart';
// import WishlistModal from 'components/Items/Wishlist';
import FavoriteModal from 'components/Items/Favorite';

import { QuestionLinkState } from 'components/Items/Question/QuestionLink';

const ProductActionsButtons = ({ product_id }: { product_id: number }) => {
    const [showCartModal, toggleCartModal] = useToggleModal();
    // const [showWishlistModal, toggleWishlistModal] = useToggleModal();
    const [showFavoriteModal, toggleFavoriteModal] = useToggleModal();

    const openQuestionModal = () => {
        setTimeout(() => {
            questionStore.setEditingItem(CREATING_ITEM_ID, {
                product_id
            });
        }, 0);
        locationHistory.push(QuestionLinkState(CREATING_ITEM_ID));
    };

    const { item } = productStore.getItem(product_id);
    if (!item) {
        return null;
    }
    const { price } = productStore.calcShortProduct(item);

    return (
        <Grid>
            <Grid.Column xs={12} sm={4} md={4}>
                <Button
                    color="success"
                    variant="outlined"
                    size="small"
                    disableElevation
                    onClick={openQuestionModal}
                    fullWidth
                    startIcon={<QuestionAnswerIcon />}
                    tooltip="Задать вопрос о данном товаре"
                >
                    Вопрос
                </Button>
            </Grid.Column>
            <Grid.Column xs={12} sm={4} md={4}>
                <Button
                    color="warning"
                    variant="outlined"
                    size="small"
                    disableElevation
                    onClick={toggleFavoriteModal}
                    fullWidth
                    startIcon={<FavoriteIcon />}
                    tooltip="Добавить данный товар в Избранное"
                >
                    В избранное
                </Button>
                {showFavoriteModal && <FavoriteModal product_id={product_id} onClose={toggleFavoriteModal} />}
            </Grid.Column>
            <Grid.Column xs={12} sm={4} md={4}>
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    disableElevation
                    onClick={toggleCartModal}
                    fullWidth
                    startIcon={<ShoppingBasketIcon />}
                    tooltip="Добавить данный товар в Корзину"
                    disabled={!price}
                >
                    В корзину
                </Button>
                {showCartModal && <CartModal product_id={product_id} onClose={toggleCartModal} />}
            </Grid.Column>
            {/*<Grid.Column xs={12} sm={6} md={3}>*/}
            {/*    <Button*/}
            {/*        color="error"*/}
            {/*        variant="outlined"*/}
            {/*        size="small"*/}
            {/*        disableElevation*/}
            {/*        onClick={toggleWishlistModal}*/}
            {/*        fullWidth*/}
            {/*        startIcon={<FormatListNumberedIcon />}*/}
            {/*        tooltip="Добавить данный товар в Лист ожидания"*/}
            {/*    >*/}
            {/*        В Лист ожидания*/}
            {/*    </Button>*/}
            {/*    {showWishlistModal && <WishlistModal product_id={product_id} onClose={toggleWishlistModal} />}*/}
            {/*</Grid.Column>*/}
        </Grid>
    );
};

export default observer(ProductActionsButtons);
