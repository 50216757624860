import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { FeedbacksOrderBy, WbItemFeedback } from 'api/productApi';
import productStore from 'stores/productStore';
import commonStore from 'stores/commonStore';

import { WarningMessage } from 'ui/Message';

import ProductFeedbackList from './FeedbackBlocks/ProductFeedbackList';
import WbFeedbacksContainer from 'components/WbFeedbacks/WbFeedbacksContainer';
import WbFeedbacksTypeSwitch from 'components/WbFeedbacks/WbFeedbacksTypeSwitch';

export const FeedbackContext = React.createContext<WbItemFeedback | null>(null);

const ProductFeedbacksMode = ({
    product_id,
    feedbacksType,
    bottomPadding = 120
}: {
    product_id: number;
    feedbacksType: 'main' | 'similar';
    bottomPadding?: number;
}) => {
    const [currentFeedbackType, setFeedbackType] = useState<'main' | 'similar'>(feedbacksType);
    const handleChangeFeedbackType = (type: 'main' | 'similar') => {
        setFeedbackType(type);
        productStore.fetchProductWbFeedbacks(product_id, type, 'createTime', 'descending', 0);
    };

    useEffect(() => {
        productStore.fetchProductWbFeedbacks(product_id, feedbacksType, 'createTime', 'descending', 0);
        setFeedbackType(feedbacksType);
    }, [product_id, feedbacksType]);

    const { feedbacks = [], feedbacksPult } = productStore.getItem(product_id).property;
    if (!feedbacksPult) {
        return null;
    }

    const { loading, errors, orderBy = 'createTime', orderDirection = 'ascending' } = feedbacksPult;

    const handleSort = (newOrderBy: FeedbacksOrderBy) => {
        if (newOrderBy === orderBy) {
            productStore.fetchProductWbFeedbacks(
                product_id,
                currentFeedbackType,
                orderBy,
                orderDirection === 'ascending' ? 'descending' : 'ascending',
                0
            );
        } else {
            productStore.fetchProductWbFeedbacks(product_id, currentFeedbackType, newOrderBy, orderDirection, 0);
        }
    };

    return (
        <WbFeedbacksContainer
            loading={loading}
            bottomPadding={bottomPadding}
            errors={errors}
            orderBy={orderBy}
            orderDirection={orderDirection}
            handleSort={handleSort}
            otherPultElement={
                !commonStore.isMobile ? (
                    <WbFeedbacksTypeSwitch feedbacksType={currentFeedbackType} onChange={handleChangeFeedbackType} />
                ) : null
            }
        >
            {!loading && feedbacks.length === 0 && <WarningMessage>Отзывов не найдено</WarningMessage>}
            <ProductFeedbackList product_id={product_id} />
        </WbFeedbacksContainer>
    );
};

export default observer(ProductFeedbacksMode);
