import React from 'react';
import { observer } from 'mobx-react';

import authStore from 'stores/authStore';
import productStore, { Product, StoreIds } from 'stores/productStore';
import ransomStore, { RansomType } from 'stores/ransomStore';

import Grid from 'ui/Grid';
import Divider from 'ui/Divider';
import ListError from 'ui/ListError';
import { ErrorMessage } from 'ui/Message';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import RansomPaymentType from './EditingBlocks/RansomPaymentType';
import RansomSearchQuery from './EditingBlocks/RansomSearchQuery';
import RansomCreateButtons from './EditingBlocks/RansomCreateButtons';
import RansomAddressBlock from './EditingBlocks/RansomAddress/RansomAddressBlock';
import RansomRivalItemsBlock from './EditingBlocks/RansomOtherItems/RansomRivalItemsBlock';
import RansomAdditionalItemsBlock from './EditingBlocks/RansomOtherItems/RansomAdditionalItemsBlock';
import RansomSmartsItemsContainer from './EditingBlocks/RansomSmartsItemsContainer';
import RansomSizeEditing from './EditingBlocks/RansomSizeEditing';
import RansomSelectProductEditing from './EditingBlocks/SelectProducts/RansomSelectProductEditing';
import RansomTypeEditing from './EditingBlocks/RansomTypeEditing';
import RansomAccountEditing from './EditingBlocks/RansomAccountEditing';
import RansomPlanningExecuteTime from './EditingBlocks/RansomPlanningExecuteTime';

import WbGenderField from '../WbGenderField';
import ProductCard from '../Product/ProductCard';

const RansomCreateModeTab = ({ ransom_id }: { ransom_id: number }) => {
    const { editingItem, errors } = ransomStore.getItem(ransom_id);
    const { product_id, type } = editingItem;

    let product: Product | null = null;
    if (product_id) {
        const productItem = productStore.getItem(product_id);
        product = productItem?.item || null;
    }

    return (
        <>
            <Container maxWidth="sm" sx={{ mb: 2 }}>
                <ListError errors={errors} />

                <Grid disableGutter>
                    <Grid.Column>
                        <RansomSelectProductEditing ransom_id={ransom_id} />
                    </Grid.Column>
                    {product_id && (
                        <Grid.Column>
                            <ProductCard product={product} />
                        </Grid.Column>
                    )}
                    {product_id && productStore.getCurrentStoreId() === StoreIds.WB && (
                        <Grid.Column>
                            <RansomAdditionalItemsBlock ransom_id={ransom_id} />
                            <Divider />
                        </Grid.Column>
                    )}
                    {type !== RansomType.Smart && (
                        <Grid.Column>
                            <RansomSizeEditing ransom_id={ransom_id} />
                        </Grid.Column>
                    )}
                    {type !== RansomType.Smart && (
                        <>
                            <Grid.Column>
                                <WbGenderField store={ransomStore} item_id={ransom_id} title="Выкуп от" />
                            </Grid.Column>
                            <Grid.Column>
                                <RansomSearchQuery ransom_id={ransom_id} />
                            </Grid.Column>

                            <Grid.Column>
                                <Paper sx={{ p: 1 }} elevation={2}>
                                    <RansomAddressBlock ransom_id={ransom_id} />
                                </Paper>
                            </Grid.Column>
                        </>
                    )}
                    <Grid.Column>
                        <RansomRivalItemsBlock ransom_id={ransom_id} />
                        <Divider />
                    </Grid.Column>

                    <RansomPaymentType ransom_id={ransom_id} />

                    <Grid.Column>
                        <Divider />
                        {type === RansomType.Once && <RansomPlanningExecuteTime ransom_id={ransom_id} />}
                    </Grid.Column>

                    <Grid.Column>
                        <RansomTypeEditing ransom_id={ransom_id} />
                    </Grid.Column>

                    <RansomAccountEditing ransom_id={ransom_id} />
                </Grid>
            </Container>
            {type === RansomType.Smart && (
                <Container maxWidth="md">
                    <RansomSmartsItemsContainer ransom_id={ransom_id} />
                </Container>
            )}
            <Container maxWidth="sm">
                <Grid>
                    {authStore?.currentUser?.disableWb && (
                        <Grid.Column>
                            <ErrorMessage header="Проблема в Wildberries">
                                Сайт и приложение Wildberries временно не работают. Создать Выкуп временно невозможно.
                            </ErrorMessage>
                        </Grid.Column>
                    )}
                    {!authStore?.currentUser?.disableWb && <RansomCreateButtons />}
                </Grid>

                <ListError errors={errors} />
            </Container>
        </>
    );
};

export default observer(RansomCreateModeTab);
