import React, { SyntheticEvent, useState } from 'react';

import commonStore from 'stores/commonStore';
import authStore from 'stores/authStore';

import * as seoConsultApi from 'api/seoConsultApi';

import Modal, { ModalBtnAction, ModalContent } from 'ui/Modal';
import ListError from 'ui/ListError';
import Grid from 'ui/Grid';
import TextInput from 'ui/TextInput';
import { InfoMessage } from 'ui/Message';

const SeoControlModal = ({ onClose }: { onClose: () => void }) => {
    const [phone, setPhone] = useState(`+${authStore.currentUser?.phone}`);
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const handleChangeComment = (event: SyntheticEvent, { value }: { value: string }) => {
        setComment(value);
    };

    const handleChangePhone = (event: SyntheticEvent, { value }: { value: string }) => {
        setPhone(value);
    };

    const handleCreate = async () => {
        try {
            setLoading(true);
            await seoConsultApi.createSeoControl(phone, comment);
            commonStore.setGlobalInfoMessage('Заявка на SEO-control создана, мы свяжемся с вами в течение 24 часов');
            onClose();
        } catch (errors) {
            if (errors instanceof Array) {
                setErrors(errors);
            }
        } finally {
            setLoading(false);
        }
    };

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'inherit' },
        {
            onClick: handleCreate,
            label: <>Заказать</>,
            loading: loading,
            variant: 'contained',
            color: 'secondary'
        }
    ];

    return (
        <Modal
            maxWidth="xs"
            onClose={onClose}
            actions={actions}
            header="Заказать SEO-control"
            fullScreen={commonStore.isMobile}
        >
            <ModalContent>
                <ListError errors={errors} />
                <Grid>
                    <Grid.Column>
                        <TextInput
                            variant="standard"
                            label="Как с вами связаться?"
                            value={phone}
                            onChange={handleChangePhone}
                            maxLength={1000}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            variant="standard"
                            label="Артикул товара, комментарий (не обязательно)"
                            value={comment}
                            onChange={handleChangeComment}
                            maxLength={1000}
                            multiline
                            rowsMax={10}
                            rows={1}
                        />
                    </Grid.Column>
                </Grid>
                <InfoMessage>Если у вас возникли вопросы, обращайтесь @topstoreadm</InfoMessage>
            </ModalContent>
        </Modal>
    );
};

export default SeoControlModal;
