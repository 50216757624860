import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import userStore from 'stores/userStore';

import Table, { TableHead, TableRow, TableCell, TableBody } from 'ui/Table';
import Time from 'ui/Time';
import TextInput from 'ui/TextInput';
import Checkbox from 'ui/Checkbox';
import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';

import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';

import ListWrapper from '../ListWrapper';
import ListPagination from '../ListPagination';

import { UserLinkState } from '../../Items/User/UserLink';
import SystemSettingsModal from './SystemSettingsModal';

const RansomsList = () => {
    const [showSettings, toggleSettings] = useToggleModal();
    const { list, loadingList, adjustDirection, handleSort } = userStore;

    const handleOpenUser = (user_id: number) => {
        locationHistory.push(UserLinkState(user_id));
    };

    const {
        listFilter: { search, enableReferrals }
    } = userStore;

    const handleChangeSearch = (event: SyntheticEvent, { value }: { value: string }) => {
        userStore.changeFilter('search', value);
    };

    const toggleRefEnable = () => {
        userStore.changeFilter('enableReferrals', !enableReferrals);
    };

    return (
        <ListWrapper store={userStore} bgUrl="">
            <Stack direction="row">
                <TextInput
                    value={search}
                    onChange={handleChangeSearch}
                    label="id, телефон или имя"
                    startLabel={<SearchIcon />}
                    sx={{ maxWidth: '300px' }}
                />
                <Checkbox label="С рефералкой" checked={enableReferrals} onClick={toggleRefEnable} />
                <Button variant="outlined" onClick={toggleSettings} size="small">
                    Настройка Topstore
                </Button>
                {showSettings && <SystemSettingsModal onClose={toggleSettings} />}
            </Stack>

            <Table elevation={0} loading={loadingList} compact>
                <TableHead>
                    <TableRow>
                        <TableCell sortDirection={adjustDirection('user_id')} onClick={() => handleSort('user_id')}>
                            #
                        </TableCell>
                        <TableCell
                            sortDirection={adjustDirection('onlineTime')}
                            onClick={() => handleSort('onlineTime')}
                        >
                            Дата
                        </TableCell>
                        <TableCell>Имя</TableCell>
                        <TableCell>Телефон</TableCell>
                        <TableCell
                            sortDirection={adjustDirection('sumClearPayments')}
                            onClick={() => handleSort('sumClearPayments')}
                        >
                            Баланс
                        </TableCell>
                        {enableReferrals && <TableCell>Рефка</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(
                        ({
                            user_id,
                            referrer_user_id,
                            createTime,
                            onlineTime,
                            phone,
                            sum30dPayments,
                            sumClearPayments,
                            firstName,
                            lastName,
                            balance,
                            referralsCount,
                            discount,
                            firstPaymentGift,
                            firstPaymentExpireTime,
                            referralsSumToWithdraw
                        }) => (
                            <TableRow key={user_id}>
                                <TableCell>
                                    <Button
                                        fullWidth
                                        tooltip="Управление"
                                        color="inherit"
                                        sx={{ textTransform: 'none', p: 0 }}
                                        onClick={() => handleOpenUser(user_id)}
                                    >
                                        {user_id} {referrer_user_id && `(#${referrer_user_id})`}
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Stack>
                                        <Time time={createTime} />
                                        <span>online: {onlineTime ? <Time time={onlineTime} /> : '--'}</span>
                                    </Stack>
                                </TableCell>
                                <TableCell align="left">
                                    {firstName} {lastName}
                                </TableCell>
                                <TableCell>{phone}</TableCell>
                                <TableCell>
                                    <Stack>
                                        <span>
                                            +{balance?.toLocaleString()} ₽ {discount > 0 && `(-${discount}%)`}
                                        </span>
                                        <span>
                                            {sumClearPayments ? `${sumClearPayments.toLocaleString()} ₽` : '--'}&nbsp;
                                            {Number(firstPaymentGift) > 0 && `(+${firstPaymentGift}%)`}
                                        </span>
                                    </Stack>
                                </TableCell>
                                {enableReferrals && (
                                    <TableCell>
                                        {referralsSumToWithdraw ? `${referralsSumToWithdraw.toLocaleString()} ₽` : '--'}
                                        <br />
                                        Юзеры: {referralsCount}
                                    </TableCell>
                                )}
                            </TableRow>
                        )
                    )}
                </TableBody>
                <ListPagination
                    pagination={userStore.pagination}
                    listCount={userStore.listCount}
                    pageChange={userStore.pageChange}
                    pageSizeChange={userStore.pageSizeChange}
                    colSpan={enableReferrals ? 5 : 4}
                />
            </Table>
        </ListWrapper>
    );
};

export default observer(RansomsList);
