import React from 'react';

import commonStore from 'stores/commonStore';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import Grid from 'ui/Grid';
import { SuccessMessage } from 'ui/Message';

import { PhotoThumbnail } from '../AutoAnswers/initInfo/SupplierIdInstruction';

const FFAboutWarehouse = () => {
    const { isMobile } = commonStore;

    return (
        <>
            <Typography variant="h4" color="secondary" sx={{ mt: 2 }}>
                Наш склад
            </Typography>
            <Stack direction="row">
                <Box sx={{ flex: 1 }}>
                    <ul>
                        <li>930 квадратных метров складских помещений</li>
                        <li>Мощности по обработке до 20 тысяч единиц товара в день</li>
                        <li>Удобный подъезд</li>
                        <li>Расположен рядом со складами Wildberrise, Ozon, Яндекс.Маркет и Сбермегамаркет</li>
                        <li>
                            На складе работает круглосуточная система видео-наблюдения и объект находится под охраной
                        </li>
                    </ul>
                    <SuccessMessage header="Поможем с переездом">
                        Организуем перевозку ваших товаров со склада другого Фулфилмента
                    </SuccessMessage>
                </Box>
                <Box sx={{ width: isMobile ? 240 : 480, maxWidth: isMobile ? '40%' : '50%', overflow: 'hidden' }}>
                    <img width={isMobile ? 240 : 480} src="/static/ff/whAbout.jpg" />
                </Box>
            </Stack>

            <Grid columns={commonStore.isMobile ? 2 : 3}>
                <Grid.Column>
                    <PhotoThumbnail srcBig="/static/ff/4.jpg" width="100%" />
                </Grid.Column>
                <Grid.Column>
                    <PhotoThumbnail srcBig="/static/ff/2.jpg" width="100%" />
                </Grid.Column>
                <Grid.Column>
                    <PhotoThumbnail srcBig="/static/ff/6.jpg" width="100%" />
                </Grid.Column>
                <Grid.Column>
                    <PhotoThumbnail srcBig="/static/ff/5.jpg" width="100%" />
                </Grid.Column>
                <Grid.Column>
                    <PhotoThumbnail srcBig="/static/ff/3.jpg" width="100%" />
                </Grid.Column>
                <Grid.Column>
                    <PhotoThumbnail srcBig="/static/ff/7.jpg" width="100%" />
                </Grid.Column>
            </Grid>

            {/*<Typography variant="subtitle2" sx={{ mt: 2 }}>*/}
            {/*    Заявки обрабатываются в будни с 09 до 19 МСК*/}
            {/*</Typography>*/}
        </>
    );
};

export default FFAboutWarehouse;
