import React from 'react';
import { observer } from 'mobx-react';

import deliveryStore from 'stores/deliveryStore';
import commonStore from 'stores/commonStore';

import Box from '@mui/material/Box';
import FilterListIcon from '@mui/icons-material/FilterList';
import Stack from '@mui/material/Stack';

import ListError from 'ui/ListError';
import Progress from 'ui/Progress';
import { useToggle } from 'ui/Modal';
import Button from 'ui/Button';

import { DeliveriesFilterInner } from '../DeliveriesFilter';
import DeliveryDataViewToggle from '../DeliveryDataViewToggle';

const DeliveryMapPult = () => {
    const { isMobile } = commonStore;
    const { listErrors, loadingList } = deliveryStore;
    const [showFilter, toggleFilter] = useToggle(!isMobile);

    return (
        <Box
            sx={{
                position: 'absolute',
                top: isMobile ? 0 : 20,
                left: isMobile ? 0 : 10,
                zIndex: 1000,
                background: 'white',
                opacity: 0.85,
                p: 2,
                borderRadius: 1,
                width: isMobile ? '100%' : 300,
                display: 'flex',
                flexDirection: 'column',
                gap: 2
            }}
        >
            <Stack direction="row">
                <DeliveryDataViewToggle />
                {isMobile && (
                    <Button
                        color={deliveryStore.filterIsEmpty ? 'inherit' : 'secondary'}
                        icon={<FilterListIcon />}
                        onClick={toggleFilter}
                        tooltip="Фильтр"
                    />
                )}
            </Stack>
            <ListError errors={listErrors} />
            {showFilter && (
                <>
                    <DeliveriesFilterInner />
                    <Box sx={{ height: 5 }}>
                        <Progress show={loadingList} linear />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default observer(DeliveryMapPult);
