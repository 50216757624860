import fetchApi from 'common/fetchApi';
import { objectToGraphql } from '../common/graphql';
import { Ransom, RansomFilter } from '../stores/ransomStore';
import { OrderDirectionType } from '../stores/prototypes/ListStore.prototype';
import { GQProductListFields, GQWBItemShort, GQWbSizeFields } from './productApi';
import { GQDeliveryPointFields } from './deliveryPointsApi';
import { StoreDeliveryPointType } from '../stores/deliveryPointsStore';

export const createItem = async (ransom: Partial<Ransom>): Promise<number> => {
    const graphql = `mutation { 
          createRansom(ransom: ${objectToGraphql(ransom)})
        }`;

    const data = await fetchApi.postGQ<{ createRansom: number }>(graphql);
    return data.createRansom;
};

export const saveItem = async (ransom_id: number, ransomInput: Partial<Ransom>): Promise<number> => {
    const graphql = `mutation { 
          updateRansom(ransom_id: ${ransom_id}, ransomInput: ${objectToGraphql(ransomInput)})
        }`;

    const data = await fetchApi.postGQ<{ updateRansom: number }>(graphql);
    return data.updateRansom;
};

export const GQRansomListFields = `
    ransom_id    
    store_id
    product_id
    account_id
    user_id
    accountIsGood
    
    type
    ransomsCount
    ransom_group_id
    planningExecuteTime
    preRunAccount
    oneTimeAccount
    
    gender
    wb_size_id
    searchRequest
    wbFilterOrderBy
    price
    status
    paymentUrl
    errorMessage
    paymentType
    payPhone
    
    product {
        ${GQProductListFields}
    }
    
    store_point_id
   
    deliveryStorePoint {
        ${GQDeliveryPointFields}
    }
    wbSize {
        ${GQWbSizeFields}
    }
    
    createTime
    enable
`;

export const GQRansomFields = `
    ${GQRansomListFields}
    
    paymentQR
    wbReceiptLink
    
    additional_store_item_ids
    additionalStoreItems {
        ${GQWBItemShort}    
    }
    
    rival_item_ids
    rivalItems {
        store_id
        store_item_id
        title
        photoUrl
        price {
            price
            priceWithSale    
        }  
    }
    wbFilters {
        name
        key
        ids {
            id
            name
        }   
    }
`;

export const fetchItem = async (ransom_id: number): Promise<Ransom> => {
    const graphql = `{ 
          fetchRansom(ransom_id: ${ransom_id}) {
            ${GQRansomFields}
           }
        }`;

    const data = await fetchApi.getGQ<{ fetchRansom: Ransom }>(graphql);
    return data.fetchRansom;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    ransomFilter: Partial<RansomFilter>,
    controller?: AbortController
): Promise<{ list: Ransom[]; count: number }> => {
    const graphql = `{
            fetchRansomsList(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(ransomFilter)}) {
                list {
                    ${GQRansomListFields}
                }
                count
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchRansomsList: { list: Ransom[]; count: number } }>(graphql, controller);
    return data.fetchRansomsList;
};

export const fetchRansomsGroup = async (ransom_group_id: number): Promise<Ransom[]> => {
    const graphql = `{
            fetchRansomsGroup(ransom_group_id: ${ransom_group_id}) {
                ${GQRansomListFields}
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchRansomsGroup: Ransom[] }>(graphql);
    return data.fetchRansomsGroup;
};

export const fetchRansomsStoresOptions = async (
    ransomFilter: Partial<RansomFilter>
): Promise<StoreDeliveryPointType[]> => {
    const graphql = `{
            fetchRansomsStoresOptions(filter: ${objectToGraphql(ransomFilter)}) {
                store_point_id
                address
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchRansomsStoresOptions: StoreDeliveryPointType[] }>(graphql);
    return data.fetchRansomsStoresOptions;
};

export type WBSearchFilterItem = {
    id: number;
    name: string;
    count: number;
};

export type WBSearchFilter = {
    name: string;
    key: string;
    isFull?: boolean | null;
    multiselect?: 0;
    items?: WBSearchFilterItem[];
    maxPriceU?: number;
    minPriceU?: number;
};

export type WbSearchFilterResults = {
    filters: WBSearchFilter[];
    total: number;
};

export const fetchWbSearchFilters = async (
    query: string,
    setFilters: { key: string; ids: string[] }[]
): Promise<WbSearchFilterResults> => {
    const graphql = `{
            fetchWbSearchFilters(query: ${objectToGraphql(query)}, setFilters: ${objectToGraphql(setFilters)}) {
                filters {
                    name
                    key
                    isFull
                    multiselect
                    items {
                        id   
                        name
                        count 
                    }
                    maxPriceU
                    minPriceU
                }
                total
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchWbSearchFilters: WbSearchFilterResults }>(graphql);
    return data.fetchWbSearchFilters;
};

export const tryCreateDelivery = async (ransom_id: number): Promise<boolean> => {
    const graphql = `mutation { 
          tryCreateDelivery(ransom_id: ${ransom_id})
        }`;

    const data = await fetchApi.postGQ<{ tryCreateDelivery: boolean }>(graphql);
    return data.tryCreateDelivery;
};
