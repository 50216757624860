import { openDB, IDBPDatabase, deleteDB } from 'idb';

const DATABASE_NAME = 'crm';
const ITEM_STORE_NAME = 'items';
const DATABASE_VERSION = 1;

let db: IDBPDatabase<unknown>;

const init = async () => {
    if (!db) {
        try {
            db = await openDB(DATABASE_NAME, DATABASE_VERSION, {
                upgrade: upgradeDB => {
                    upgradeDB.createObjectStore(ITEM_STORE_NAME, {
                        keyPath: 'id'
                    });
                }
            });
        } catch (e) {}
    }
};

async function put(item_id: number | string, moduleName: string, item: any): Promise<void> {
    await init();
    if (!db) {
        return;
    }
    try {
        const tx = db.transaction(ITEM_STORE_NAME, 'readwrite');
        const store = tx.objectStore(ITEM_STORE_NAME);
        store.put({ id: `${moduleName}_${item_id}`, item_id, moduleName, item, time: Date.now() });
        return tx.done;
    } catch (err) {
        console.log(err);
    }
}

async function get(item_id: number | string, moduleName: string): Promise<any> {
    await init();
    if (!db) {
        return null;
    }
    try {
        const tx = db.transaction(ITEM_STORE_NAME, 'readwrite');
        const store = tx.objectStore(ITEM_STORE_NAME);
        return (await store.get(`${moduleName}_${item_id}`)) || null;
    } catch (err) {
        console.log(err);
        return null;
    }
}

async function del(item_id: number | string, moduleName: string): Promise<void> {
    await init();
    if (!db) {
        return;
    }
    try {
        const tx = db.transaction(ITEM_STORE_NAME, 'readwrite');
        const store = tx.objectStore(ITEM_STORE_NAME);
        await store.delete(`${moduleName}_${item_id}`);
    } catch (err) {
        console.log(err);
    }
}

async function reset() {
    await deleteDB(DATABASE_NAME);
}

const thisModule = {
    put,
    get,
    delete: del,
    reset
};

export default thisModule;
