import React from 'react';
import { Link } from 'react-router-dom';

export const BrandLinkState = (brand_id: number) => ({
    pathname: `/brands/list/detail/${brand_id}`,
    state: { modal: true }
});

const BrandLink = ({ brand_id }: { brand_id: number }) => <Link to={BrandLinkState(brand_id)}>Бренд №{brand_id}</Link>;

export default BrandLink;
