import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import reviewStore from 'stores/reviewStore';
import commonStore from 'stores/commonStore';

import TextInput from 'ui/TextInput';
import Divider from 'ui/Divider';
import { Switch } from 'ui/Checkbox';

import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import ReviewUploadVideo from './ReviewUploadVideo';

const ReviewTextEditing = () => {
    const review_id = CREATING_ITEM_ID;

    const { editingItem } = reviewStore.getItem(review_id);
    const { reviewText, prosText, consText, isRecommended, isAnonymously } = editingItem;

    const handleReviewText = (event: SyntheticEvent, { value }: { value: string }) => {
        reviewStore.setEditingItem(review_id, { reviewText: value });
    };

    const handleReviewPros = (event: SyntheticEvent, { value }: { value: string }) => {
        reviewStore.setEditingItem(review_id, { prosText: value });
    };

    const handleReviewConst = (event: SyntheticEvent, { value }: { value: string }) => {
        reviewStore.setEditingItem(review_id, { consText: value });
    };

    const handleChaneIsRecommended = (event: SyntheticEvent, isRecommended: boolean | null) => {
        reviewStore.setEditingItem(review_id, { isRecommended });
    };

    const toggleIsAnonymously = () => {
        reviewStore.setEditingItem(review_id, { isAnonymously: !isAnonymously });
    };

    const { isMobile } = commonStore;

    return (
        <Stack spacing={3}>
            <TextInput
                variant="classic"
                size="small"
                label="Достоинства"
                value={prosText}
                onChange={handleReviewPros}
                maxLength={1000}
                multiline
                rowsMax={10}
                rows={1}
            />
            <TextInput
                variant="classic"
                size="small"
                label="Недостатки"
                value={consText}
                onChange={handleReviewConst}
                maxLength={1000}
                multiline
                rowsMax={10}
                rows={1}
            />
            <TextInput
                variant="classic"
                size="small"
                label="Комментарий"
                value={reviewText}
                onChange={handleReviewText}
                maxLength={1000}
                multiline
                rowsMax={10}
                rows={1}
            />
            <Stack
                direction={isMobile ? 'column' : 'row'}
                spacing={3}
                alignItems={isMobile ? 'flex-start' : 'center'}
                justifyContent="space-between"
            >
                <Typography>Мнение о товаре</Typography>
                <ToggleButtonGroup
                    size="small"
                    color="secondary"
                    value={isRecommended}
                    exclusive
                    onChange={handleChaneIsRecommended}
                >
                    <ToggleButton value={false}>Не рекомендую</ToggleButton>
                    <ToggleButton value={true}>Рекомендую</ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Switch label="Анонимный отзыв" checked={isAnonymously} onClick={toggleIsAnonymously} />
            <ReviewUploadVideo />
            <Divider />
        </Stack>
    );
};

export default observer(ReviewTextEditing);
