import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import ransomStore from 'stores/ransomStore';
import productStore from 'stores/productStore';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import AddIcon from '@mui/icons-material/Add';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';

import RansomOtherItem from './RansomOtherlItem';
import RansomOtherItemAdd from './RansomOtherlItemAdd';
import { DropdownProductType } from 'components/List/Products/ProductSearchDropdownAutocomplete';

const RansomRivalEditingBlock = ({ ransom_id }: { ransom_id: number }) => {
    const [showAddBtn, toggleAddBtn] = useToggleModal();

    const { rivalItems = [] } = ransomStore.getItem(ransom_id).property;
    const { rival_item_ids = [], store_id } = ransomStore.getItem(ransom_id).editingItem;

    useEffect(() => {
        if (productStore.listFilter.store_id !== store_id) {
            ransomStore.setProperty(ransom_id, { rivalItems: [], rivalEnable: false });
            ransomStore.setEditingItem(ransom_id, {
                rival_item_ids: []
            });
        }
    }, [store_id, productStore.listFilter.store_id]);

    const handleAddRival = (storeItem: DropdownProductType) => {
        if (!rivalItems.find(({ store_item_id }) => storeItem.value === store_item_id)) {
            const rivalItem = {
                store_item_id: Number(storeItem.value),
                title: storeItem.text,
                photoUrl: storeItem.photoUrl
            };
            const rivalItemsNew = [...rivalItems, rivalItem];

            ransomStore.setEditingItem(ransom_id, {
                rival_item_ids: rivalItemsNew.map(({ store_item_id }) => store_item_id)
            });
            ransomStore.setProperty(ransom_id, { rivalItems: rivalItemsNew });
        }
        toggleAddBtn();
    };

    const handleRemoveRival = (index: number) => {
        const rivalItemsNew = [...rivalItems];
        rivalItemsNew.splice(index, 1);

        ransomStore.setProperty(ransom_id, { rivalItems: rivalItemsNew });
        ransomStore.setEditingItem(ransom_id, {
            rival_item_ids: rivalItemsNew.map(({ store_item_id }) => store_item_id)
        });
    };

    const enableAdd = rival_item_ids.length < 5;

    return (
        <List>
            {rivalItems.map(({ store_item_id, title, photoUrl }, index) => (
                <RansomOtherItem
                    key={Number(store_item_id)}
                    photoUrl={photoUrl}
                    title={title}
                    store_item_id={Number(store_item_id)}
                    onDeleteItem={() => handleRemoveRival(index)}
                />
            ))}
            {enableAdd && rivalItems.length > 0 && showAddBtn && (
                <ListItem>
                    <Button
                        size="large"
                        startIcon={<LocalGroceryStoreIcon />}
                        endIcon={<AddIcon />}
                        color="secondary"
                        fullWidth
                        onClick={toggleAddBtn}
                        tooltip="Добавить еще один товар в корзину перед выкупом (до 5 штук)"
                    >
                        Добавить еще товар
                    </Button>
                </ListItem>
            )}
            {enableAdd && (rivalItems.length === 0 || !showAddBtn) && (
                <RansomOtherItemAdd store_id={productStore.getCurrentStoreId()} onAddItem={handleAddRival} />
            )}
        </List>
    );
};

export default observer(RansomRivalEditingBlock);
