import React from 'react';

import commonStore from 'stores/commonStore';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';
import Grid from 'ui/Grid';
import { SuccessMessage } from 'ui/Message';

import KeysRansomsModal from './SeoInfographicsModal';
import ListContainer from '../ListContainer';

const SeoInfographics = () => {
    const [showModal, toggleModal] = useToggleModal();

    return (
        <ListContainer bgUrl="/static/listbg/unruly-05-done.webp">
            <Container maxWidth="md">
                <Paper elevation={1} sx={{ padding: '1rem 2rem' }}>
                    <Typography variant="h5">Инфографика для вашей карточки</Typography>
                    <Typography variant="body1" sx={{ my: 2 }}>
                        Яндекс Директ — рекламная система, предназначенная для продвижения товаров в интернете, а
                        конкретнее — в поисковой системе Яндекса и его партнерской сети, состоящей из сайтов и
                        приложений. Это вид рекламы, в котором рекламные материалы показываются исходя из запроса
                        пользователя. Реклама в поисковиках и сетях нужна, чтобы найти новых клиентов и увеличить
                        продажи ваших товаров.
                    </Typography>

                    <Typography variant="h5">Примеры работ</Typography>
                    <ul>
                        <li>
                            <a
                                href="https://www.wildberries.ru/catalog/49888464/detail.aspx?targetUrl=GP&size=95171226"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Корректор осанки
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.wildberries.ru/catalog/53503192/detail.aspx?targetUrl=BP"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Штора для ванной
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.wildberries.ru/catalog/53427628/detail.aspx?targetUrl=BP"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Мыльница для ванной
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.wildberries.ru/catalog/50895258/detail.aspx?targetUrl=EX"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Сковородка
                            </a>
                        </li>
                    </ul>

                    <Grid alignItems="flex-start" stackable disableGutter>
                        <Grid.Column>
                            <Typography variant="h6" sx={{ my: 2 }}>
                                В услугу входит:
                            </Typography>

                            <ul>
                                <li>
                                    Дизайн одной карточки — подбор интерьера к товару, описание ключевых характеристик,
                                    преимуществ
                                </li>
                                <li>Две бесплатные правки</li>
                            </ul>
                        </Grid.Column>
                    </Grid>

                    <SuccessMessage header="Стоимость">
                        <Typography>
                            Стоимость Инфографики для одной карточки <b>490₽</b>
                        </Typography>
                        <Typography>
                            Минимальный заказ - <b>3 карточки</b>
                        </Typography>
                    </SuccessMessage>

                    <Typography variant="body2" sx={{ mt: 2 }}>
                        Заявки обрабатываются в будни с 09 до 18 МСК. Время исполнения 3-5 дней
                    </Typography>
                </Paper>
                <Box sx={{ display: 'flex', p: 2 }}>
                    <Button color="secondary" variant="contained" sx={{ margin: 'auto' }} onClick={toggleModal}>
                        Заказать Инфографику
                    </Button>
                    {showModal && <KeysRansomsModal onClose={toggleModal} />}
                </Box>
            </Container>
        </ListContainer>
    );
};

export default SeoInfographics;
