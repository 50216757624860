import fetchApi from 'common/fetchApi';
import { objectToGraphql } from 'common/graphql';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { OzonAccount } from 'stores/ozonAccountsStore';

export const GQOzonAccountFields = `
    account_id
    phone
    email
    priority
    gender
    
    SecureRefreshToken
    SecureAccessToken

    onlineTime
    createTime
    updateTime
    enable
`;

export const fetchItem = async (account_id: number): Promise<OzonAccount> => {
    const graphql = `{
            fetchOzonAccount(account_id: ${account_id}) {
                ${GQOzonAccountFields}
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchOzonAccount: OzonAccount }>(graphql);
    return data.fetchOzonAccount;
};

export const saveItem = async (account_id: number, accountDiff: Partial<OzonAccount>): Promise<number> => {
    const graphql = `mutation { 
          updateOzonAccount(account_id: ${account_id}, accountDiff: ${objectToGraphql(accountDiff)})
        }`;

    const data = await fetchApi.postGQ<{ updateOzonAccount: number }>(graphql);
    return data.updateOzonAccount;
};

export const createItem = async (ozonAccount: Partial<OzonAccount>): Promise<number> => {
    const graphql = `mutation { 
          createOzonAccount(account: ${objectToGraphql(ozonAccount)})
        }`;

    const data = await fetchApi.postGQ<{ createOzonAccount: number }>(graphql);
    return data.createOzonAccount;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    productFilter: {},
    controller?: AbortController
): Promise<{ list: OzonAccount[]; count: number }> => {
    const graphql = `{
            fetchOzonAccountList {
                list {
                    ${GQOzonAccountFields}
                }
                count
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchOzonAccountList: { list: OzonAccount[]; count: number } }>(graphql);
    return data.fetchOzonAccountList;
};
