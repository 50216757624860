import fetchApi from 'common/fetchApi';
import { objectToGraphql } from '../common/graphql';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { Favorite, FavoriteFilter } from 'stores/favoriteStore';
import { GQProductListFields } from './productApi';

export const createItem = async (favorite: Partial<Favorite>): Promise<number> => {
    const graphql = `mutation { 
          createFavorite(favorite: ${objectToGraphql(favorite)})
        }`;

    const data = await fetchApi.postGQ<{ createFavorite: number }>(graphql);
    return data.createFavorite;
};

const GQFavoriteFields = `
    favorite_id
    user_id
    product_id

    product {
        ${GQProductListFields}
    }

    speed
    searchRequest
    quantity
    status
    createTime
    updateTime
    enable
`;

export const fetchItem = async (favorite_id: number): Promise<Favorite> => {
    const graphql = `{ 
          fetchFavorite(favorite_id: ${favorite_id}) {
            ${GQFavoriteFields}
           }
        }`;

    const data = await fetchApi.getGQ<{ fetchFavorite: Favorite }>(graphql);
    return data.fetchFavorite;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    favoriteFilter: Partial<FavoriteFilter>,
    controller?: AbortController
): Promise<{ list: Favorite[]; count: number }> => {
    const graphql = `{
            fetchFavoritesList(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(favoriteFilter)}) {
                list {
                    ${GQFavoriteFields}
                }
                count
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchFavoritesList: { list: Favorite[]; count: number } }>(graphql, controller);
    return data.fetchFavoritesList;
};
