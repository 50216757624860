import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import productStore from 'stores/productStore';
import deliveryStore from 'stores/deliveryStore';
import { RansomType } from 'stores/ransomStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import videoStore from 'stores/videoStore';
import authStore from 'stores/authStore';

import ItemDrawWrapper, { MenuTab } from '../ItemDrawWrapper';
import ListError from 'ui/ListError';
import LoaderAwait from 'ui/LoaderAwait';
import Time from 'ui/Time';
import Button from 'ui/Button';

import Tooltip from '@mui/material/Tooltip';
import CachedIcon from '@mui/icons-material/Cached';

import DeliveryShowMode from './DeliveryShowMode';
import VideoTabMode from '../VideoTabMode';

const Delivery = () => {
    const { item_id: item_id_prepare, tabName }: { item_id?: string; tabName?: string } = useParams();
    const delivery_id = Number(item_id_prepare) || CREATING_ITEM_ID;

    useMemo(() => {
        productStore.debounceFilterFetch();
        deliveryStore.fetchItem(delivery_id).then(delivery => {
            if (delivery) {
                const {
                    ransom: { type, ransom_group_id, store_id }
                } = delivery;
                if (type && [RansomType.Group, RansomType.Smart].includes(type) && ransom_group_id) {
                    deliveryStore.fetchDeliveriesGroup(ransom_group_id);
                }
                if (store_id && store_id !== productStore.getCurrentStoreId()) {
                    productStore.changeFilter('store_id', store_id);
                    deliveryStore.changeFilter('store_id', store_id);
                }
            }
        });
        videoStore.findWbVideo('delivery', delivery_id);
    }, [delivery_id]);

    const { item, errors, loadingItem } = deliveryStore.getItem(delivery_id);

    if (!item && errors?.length > 0) {
        return <ListError errors={errors} />;
    }

    if (!item) {
        return (
            <div style={{ position: 'relative', height: '100vh' }}>
                <LoaderAwait active />
            </div>
        );
    }

    const { updateTime, status } = item;
    const currentTime = Math.floor(Date.now() / 1000);

    const refreshStoreTime = async () => {
        await deliveryStore.refreshUpdateStoreDelivery(delivery_id);
    };

    const canUpdate =
        !authStore?.currentUser?.disableWb &&
        (status === 1 || currentTime - updateTime > 3600) &&
        ![4, 5, 6].includes(status);

    const RefreshBtn = (
        <div>
            <Tooltip title="Время обновления информации с сайта маркетплейса">
                <span>
                    <Time time={updateTime} />
                </span>
            </Tooltip>
            {(authStore.isAdmin() || canUpdate) && (
                <Button
                    onClick={refreshStoreTime}
                    icon={<CachedIcon fontSize="large" />}
                    tooltip="Обновить информацию о доставке"
                    disabled={loadingItem}
                />
            )}
        </div>
    );

    const videoId = videoStore.getVideoId('delivery', delivery_id);

    const tabs: MenuTab[] = [
        { id: 'main', label: 'Доставка', pathname: `/deliveries/list/detail/${delivery_id}` },
        {
            id: 'video',
            label: 'Видеозапись',
            pathname: `/deliveries/list/detail/${delivery_id}/video`,
            disabled: !videoId || loadingItem
        }
    ];

    return (
        <ItemDrawWrapper tabs={tabs} activeTab={tabName || 'main'} endTab={RefreshBtn}>
            {(!tabName || tabName === 'main') && <DeliveryShowMode delivery_id={delivery_id} />}
            {tabName === 'video' && videoId && <VideoTabMode videoId={videoId} />}
        </ItemDrawWrapper>
    );
};

export default observer(Delivery);
