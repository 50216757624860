import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import deliveryPointsStore from 'stores/deliveryPointsStore';
import commonStore from 'stores/commonStore';
import productStore, { StoreIds } from 'stores/productStore';

import Modal, { ModalContent } from 'ui/Modal';

import debounce from 'common/debounce';
import wait from 'common/wait';
import { CoordinatesCorner, searchStoreDeliveryPointsByCoords } from 'api/deliveryPointsApi';
import { DeliveryPointAddress } from 'stores/ransomStore';

import Box from '@mui/material/Box';

import DeliveryPointsAddressList from './DeliveryPointsAddressList';

const CLUSTER_PLUGIN_URL = 'https://2gis.github.io/mapsapi/vendors/Leaflet.markerCluster/leaflet.markercluster-src.js';

// @ts-ignore
let DG: any = window.DG;

export const initSearchMap = async (
    targetId: string,
    mapLat: number,
    mapLng: number,
    onChange: () => Promise<void>
): Promise<void> => {
    // @ts-ignore
    while (!window.DG) {
        await wait(100);
    }
    // @ts-ignore
    DG = window.DG;
    await DG.plugin(CLUSTER_PLUGIN_URL);

    DG.then(function () {
        deliveryPointsStore.searchMap = DG.map(targetId, {
            center: [mapLat, mapLng],
            zoom: 12,
            fullscreenControl: false,
            zoomControl: false,
            poi: false
        });

        if (commonStore.isMobile) {
            DG.control.location({ position: 'bottomright' }).addTo(deliveryPointsStore.searchMap);
        } else {
            DG.control.scale({ position: 'bottomleft' }).addTo(deliveryPointsStore.searchMap);
        }

        const debounceOnChange = debounce(() => onChange(), 350);
        deliveryPointsStore.searchMap.on('moveend', debounceOnChange);

        onChange();
    });
};

export const setMapMarkers = async () => {
    const {
        _northEast,
        _southWest
    }: {
        _northEast: CoordinatesCorner;
        _southWest: CoordinatesCorner;
    } = deliveryPointsStore.searchMap.getBounds();
    const { lat, lng }: { lat: number; lng: number } = deliveryPointsStore.searchMap.getCenter();
    deliveryPointsStore.setCoords(lat, lng);

    const coordinates = { bottomLeft: _southWest, topRight: _northEast };
    const store_id = productStore.getCurrentStoreId();
    const wbStores = await searchStoreDeliveryPointsByCoords(
        store_id,
        deliveryPointsStore.storePointsFilter.type,
        coordinates
    );

    if (deliveryPointsStore.markers) {
        deliveryPointsStore.markers.removeFrom(deliveryPointsStore.searchMap);
    }

    deliveryPointsStore.markers = DG.markerClusterGroup({
        maxClusterRadius: commonStore.isMobile ? 50 : 30,
        showCoverageOnHover: true,
        spiderfyOnMaxZoom: false
    });

    for (const { geo_lat, geo_lon, address, store_point_id, workTime } of wbStores) {
        const marker = DG.marker(DG.latLng(geo_lat, geo_lon), {
            title: address,
            store_point_id
        });
        marker.bindPopup(
            `${
                store_id === StoreIds.Ozon ? `<i>${workTime}</i><br />` : ''
            }${address}<br /><button onclick="window._chooseStore(${store_point_id}, '${address}')">Выбрать</button>`
        );
        deliveryPointsStore.markers.addLayer(marker);
    }

    deliveryPointsStore.searchMap.addLayer(deliveryPointsStore.markers);

    if (deliveryPointsStore.activeStorePointId) {
        activateMarker(deliveryPointsStore.activeStorePointId);
    }
};

const activateMarker = (store_point_id: number) => {
    const marker = deliveryPointsStore.markers
        .getLayers()
        // @ts-ignore
        .find(({ options }) => options.store_point_id === store_point_id);
    if (marker) {
        marker.openPopup();
    }
};

export const MAP_ID = 'mapSearch';

const DeliveryPointModal = ({
    onClose,
    onChoose
}: {
    onClose: () => void;
    onChoose: (storeDeliveryPoint: DeliveryPointAddress) => void;
}) => {
    useMemo(() => {
        deliveryPointsStore.storePointsFilter.store_id = productStore.getCurrentStoreId();
    }, []);

    const { coords, storePointsFilter } = deliveryPointsStore;
    const store_id = productStore.getCurrentStoreId();

    // @ts-ignore
    window._chooseStore = (store_point_id: number, address: string) => {
        onChoose({ store_point_id, address });
        onClose();
    };

    const { isMobile } = commonStore;

    useEffect(() => {
        deliveryPointsStore.fetchLastStoreDeliveryPoints(store_id);
        initSearchMap(MAP_ID, coords[0], coords[1], setMapMarkers);
    }, []);

    return (
        <Modal
            maxWidth="xl"
            onClose={onClose}
            actions={[]}
            header={`Выберите адрес доставки ${store_id === StoreIds.WB ? 'Wildberries' : 'Ozon'}`}
            style={{ overflowY: 'visible' }}
            fullScreen={isMobile}
        >
            <ModalContent>
                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%' }}>
                    <Box id={MAP_ID} sx={{ height: isMobile ? '500px' : '80vh', flex: isMobile ? undefined : 1 }}></Box>
                    <DeliveryPointsAddressList />
                </Box>
            </ModalContent>
        </Modal>
    );
};

export default observer(DeliveryPointModal);
