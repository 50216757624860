import React, { SyntheticEvent } from 'react';

import commonStore from 'stores/commonStore';

import { HOUR_SEC } from 'common/time';

import Select from 'ui/Select';
import Grid from 'ui/Grid';
import { NumberInput } from 'ui/TextInput';
import Container from '@mui/material/Container';

export const ransomSpeedOptions = [
    { value: 0, text: 'Как можно быстрее' },
    { value: HOUR_SEC, text: '1 час' },
    { value: 2 * HOUR_SEC, text: '2 часа' },
    { value: 3 * HOUR_SEC, text: '3 часа' },
    { value: 5 * HOUR_SEC, text: '5 часов' },
    { value: 8 * HOUR_SEC, text: '8 часов' },
    { value: 12 * HOUR_SEC, text: '12 часов' },
    { value: 24 * HOUR_SEC, text: 'день' },
    { value: 48 * HOUR_SEC, text: '2 дня' }
];

const RansomSmartsPult = ({
    quantity,
    speed,
    setSpeed,
    setQuantity
}: {
    quantity: number;
    speed: number;
    setQuantity: (quantity: number) => void;
    setSpeed: (speed: number) => void;
}) => {
    const changeQuantity = (event: SyntheticEvent, { value }: { value: number }) => {
        setQuantity(value);
    };

    const changeSpeed = ({ value }: { value: number }) => {
        setSpeed(value);
    };

    return (
        <Container maxWidth="sm">
            <Grid columns={commonStore.isMobile ? 1 : 2}>
                <Grid.Column>
                    <NumberInput
                        value={quantity || null}
                        onChange={changeQuantity}
                        label="Количество"
                        min={0}
                        step={1}
                        max={100}
                        extraLabel={null}
                    />
                </Grid.Column>
                <Grid.Column>
                    <Select
                        fullWidth
                        variant="standard"
                        options={ransomSpeedOptions}
                        value={speed}
                        onChange={changeSpeed}
                        label="Время исполнения"
                        disabled={quantity < 2}
                    />
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default RansomSmartsPult;
