import React from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import ransomStore from 'stores/ransomStore';
import commonStore from 'stores/commonStore';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import RansomStatus from '../../List/Ransoms/RansomStatus';
import { RansomLinkState } from './RansomLink';
import LoaderAwait from 'ui/LoaderAwait';

const RansomGroupTabs = ({
    ransom_group_id,
    currentRansomId,
    label
}: {
    ransom_group_id: number;
    currentRansomId: number;
    label: string;
}) => {
    const handleOpenRansomInGroup = (ransom_id: number) => {
        // @ts-ignore
        locationHistory.replace({ ...RansomLinkState(ransom_id), fromTab: true });
    };

    const { ransoms, loading } = ransomStore.getRansomGroup(ransom_group_id);

    return (
        <Box sx={{ height: '5em' }}>
            <Typography variant="h6">{label}</Typography>
            <LoaderAwait linear active={loading} />
            <Tabs
                allowScrollButtonsMobile
                value={currentRansomId}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
            >
                {ransoms.map(({ ransom_id, status, enable, planningExecuteTime }) => (
                    <Tab
                        value={ransom_id}
                        key={ransom_id}
                        label={
                            <RansomStatus status={status} enable={enable} planningExecuteTime={planningExecuteTime} />
                        }
                        onClick={() => handleOpenRansomInGroup(ransom_id)}
                        wrapped={commonStore.isMobile}
                        sx={{ px: 1, ...(ransom_id === currentRansomId ? {} : { opacity: 0.4 }) }}
                    />
                ))}
            </Tabs>
        </Box>
    );
};

export default observer(RansomGroupTabs);
