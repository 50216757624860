import React, { SyntheticEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import ransomStore from 'stores/ransomStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import useDebounce from 'common/useDebounce';
import { WBSearchFilter, WBSearchFilterItem } from 'api/ransomApi';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Checkbox from 'ui/Checkbox';
import Button from 'ui/Button';
import TextInput, { PriceInput } from 'ui/TextInput';
import { useToggleModal } from 'ui/Modal';
import Scrollbar from 'ui/Scrollbar';

const MAX_ITEMS = 5;
const MAX_ITEMS_TO_SEARCH = 30;

const ExpandListBtn = ({
    items,
    showAll,
    toggleShowAll
}: {
    items: WBSearchFilterItem[];
    showAll: boolean;
    toggleShowAll: () => void;
}) => {
    if (items.length > MAX_ITEMS) {
        return (
            <Button fullWidth size="small" color="secondary" variant="outlined" onClick={toggleShowAll}>
                {showAll ? 'Скрыть' : `Показать еще +${items.length - MAX_ITEMS}`}
            </Button>
        );
    }
    return null;
};

const LoadFullListBtn = ({ filterKey }: { filterKey: string }) => {
    const [loading, setLoading] = useState(false);

    const loadSet = () => {
        const { searchRequest } = ransomStore.getItem(CREATING_ITEM_ID).editingItem;
        setLoading(true);
        ransomStore.fetchWbFilerOptionsSet(CREATING_ITEM_ID, searchRequest || '', filterKey);
    };

    return (
        <Button fullWidth size="small" color="secondary" variant="outlined" onClick={loadSet} loading={loading}>
            Показать все
        </Button>
    );
};

const isFullCache: Record<string, WBSearchFilter['isFull']> = {};

const WbFilterItemsCheckboxes = observer(
    ({
        label,
        filterKey,
        items,
        isFull
    }: {
        label: string;
        filterKey: string;
        items: WBSearchFilterItem[];
        isFull?: WBSearchFilter['isFull'];
    }) => {
        const [showAll, toggleShowAll] = useToggleModal(isFull === false);
        const [search, setSearch] = useState('');

        useEffect(() => {
            const prevIsFull = isFullCache[label];
            if (prevIsFull === false && isFull !== false && !showAll) {
                toggleShowAll();
            }
            isFullCache[label] = isFull;
        }, [isFull]);

        const { wbFilters } = ransomStore.getItem(CREATING_ITEM_ID).editingItem;
        if (!wbFilters) {
            return null;
        }

        const handleChangeSearch = (event: SyntheticEvent, { value }: { value: string }) => {
            setSearch(value);
        };

        return (
            <>
                {items.length > MAX_ITEMS_TO_SEARCH && (
                    <TextInput value={search} label="поиск" onChange={handleChangeSearch} />
                )}
                <Scrollbar disableOnMobile style={{ maxHeight: '300px' }}>
                    <Stack spacing={0}>
                        {items
                            .filter(({ name }) => (search ? name.toLowerCase().includes(search.toLowerCase()) : true))
                            .slice(0, showAll ? 1000 : MAX_ITEMS)
                            .map(({ name, id, count }) => {
                                let checked = false;
                                const foundFilter = wbFilters.find(filter => filter.name === label);
                                if (foundFilter) {
                                    checked = foundFilter.ids.map(({ id }) => id).includes(id);
                                }

                                return (
                                    <Checkbox
                                        key={id}
                                        label={`${name}${count ? ` (${count})` : ''}`}
                                        size="medium"
                                        checked={checked}
                                        onClick={() =>
                                            ransomStore.toggleRansomFilter(CREATING_ITEM_ID, {
                                                name: label,
                                                key: filterKey,
                                                id,
                                                itemName: name
                                            })
                                        }
                                    />
                                );
                            })}
                    </Stack>
                </Scrollbar>
                {isFull !== false && <ExpandListBtn items={items} showAll={showAll} toggleShowAll={toggleShowAll} />}
                {isFull === false && <LoadFullListBtn filterKey={filterKey} />}
            </>
        );
    }
);

const WbFilterItemsRadio = observer(
    ({ items, filterKey, label }: { label: string; filterKey: string; items: WBSearchFilterItem[] }) => {
        const [showAll, toggleShowAll] = useToggleModal();
        const { wbFilters } = ransomStore.getItem(CREATING_ITEM_ID).editingItem;
        if (!wbFilters) {
            return null;
        }

        return (
            <>
                <RadioGroup aria-labelledby="demo-error-radios" name="quiz" value={0} onChange={() => {}}>
                    {items.slice(0, showAll ? 1000 : MAX_ITEMS).map(({ name, id, count }) => {
                        let checked = false;
                        const foundFilter = wbFilters.find(filter => filter.name === label);
                        if (foundFilter) {
                            checked = foundFilter.ids.map(({ id }) => id).includes(id);
                        }

                        return (
                            <FormControlLabel
                                key={id}
                                value={id}
                                control={<Radio checked={checked} />}
                                label={`${name}${count ? ` (${count})` : ''}`}
                                onClick={() => {
                                    ransomStore.deleteRansomFilter(CREATING_ITEM_ID, label);
                                    ransomStore.toggleRansomFilter(CREATING_ITEM_ID, {
                                        name: label,
                                        key: filterKey,
                                        id,
                                        itemName: name
                                    });
                                }}
                            />
                        );
                    })}
                </RadioGroup>
                <ExpandListBtn items={items} showAll={showAll} toggleShowAll={toggleShowAll} />
            </>
        );
    }
);

const WbFilterPrice = ({ minPriceU, maxPriceU }: { minPriceU: number; maxPriceU: number }) => {
    const [minPrice, setMinPrice] = useState(minPriceU / 100);
    const [maxPrice, setMaxPrice] = useState(maxPriceU / 100);

    const minPriceDebounced = useDebounce<number>(minPrice, 500);
    const maxPriceDebounced = useDebounce<number>(maxPrice, 500);

    const label = 'priceU';
    const name = 'Цена';

    useEffect(() => {
        setMinPrice(minPriceU / 100);
        setMaxPrice(maxPriceU / 100);
    }, [minPriceU, maxPriceU]);

    useEffect(() => {
        ransomStore.deleteRansomFilter(CREATING_ITEM_ID, name);
        if (minPrice !== minPriceU / 100 || maxPrice !== maxPriceU / 100) {
            ransomStore.toggleRansomFilter(CREATING_ITEM_ID, {
                name,
                key: label,
                id: minPrice * 100,
                itemName: `от ${minPrice.toLocaleString()}`
            });
            ransomStore.toggleRansomFilter(CREATING_ITEM_ID, {
                name,
                key: label,
                id: maxPrice * 100,
                itemName: `до ${maxPrice.toLocaleString()}`
            });
        }
    }, [minPriceDebounced, maxPriceDebounced]);

    return (
        <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
            <PriceInput
                label="от"
                value={minPrice}
                variant="outlined"
                size="small"
                onChange={(e, { value }) => setMinPrice(value)}
            />
            <PriceInput
                label="до"
                value={maxPrice}
                variant="outlined"
                size="small"
                onChange={(e, { value }) => setMaxPrice(value)}
            />
        </Stack>
    );
};

const WbFilterCategory = ({ filter }: { filter: WBSearchFilter }) => {
    const { items, name, minPriceU, maxPriceU, multiselect, key, isFull } = filter;
    return (
        <Paper sx={{ mb: 1, p: 1 }}>
            <Typography variant="h6">{name}</Typography>
            {items && multiselect !== 0 && (
                <WbFilterItemsCheckboxes items={items} label={name} filterKey={key} isFull={isFull} />
            )}
            {items && multiselect === 0 && <WbFilterItemsRadio items={items} label={name} filterKey={key} />}
            {minPriceU && maxPriceU && <WbFilterPrice maxPriceU={maxPriceU} minPriceU={minPriceU} />}
        </Paper>
    );
};

export default WbFilterCategory;
