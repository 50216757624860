import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { fetchReferralsPayments, ReferralPayment } from 'api/referralLinkApi';
import commonStore from 'stores/commonStore';

import Modal, { ModalContent } from 'ui/Modal';
import ListError from 'ui/ListError';

import LinkIcon from '@mui/icons-material/Link';
import { PaginationType } from 'stores/prototypes/ListStore.prototype';
import { getDefaultPagination, matchPaginationTotalPages } from 'common/pagination';
import Table, { TableBody, TableCell, TableHead, TableRow } from 'ui/Table';
import Time from 'ui/Time';
import ListPagination from '../ListPagination';

const PaymentsModal = ({ onClose }: { onClose: () => void }) => {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [referralPayments, setReferralPayments] = useState<ReferralPayment[]>([]);
    const [pagination, setPagination] = useState<PaginationType>({ ...getDefaultPagination(), pageSize: 100 });
    const [count, setCount] = useState(0);

    useEffect(() => {
        setLoading(true);
        const { pageSize, activePage } = pagination;
        fetchReferralsPayments(pageSize, (activePage - 1) * pageSize)
            .then(({ list, count }) => {
                setReferralPayments(list);
                setPagination(matchPaginationTotalPages(pagination, count));
                setCount(count);
            })
            .catch(setErrors)
            .finally(() => setLoading(false));
    }, [pagination.activePage, pagination.pageSize]);

    const handlePageChange = (activePage: number) => {
        setPagination({ ...pagination, activePage });
    };

    const pageSizeChange = (pageSize: number) => {
        setPagination({ pageSize: pageSize, activePage: 1, totalPages: 1 });
    };

    return (
        <Modal
            maxWidth="md"
            onClose={onClose}
            header={
                <>
                    <LinkIcon />
                    &nbsp;Пополнения баланса рефералов
                </>
            }
            fullScreen={commonStore.isMobile}
        >
            <ModalContent>
                <ListError errors={errors} />
                <Table compact loading={loading} id="TablePaymentUsers">
                    <TableHead>
                        <TableRow>
                            <TableCell>Дата</TableCell>
                            <TableCell>Ссылка</TableCell>
                            <TableCell>Сумма</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {referralPayments.map(({ cost, createTime, comment }) => {
                            return (
                                <TableRow key={createTime}>
                                    <TableCell>
                                        <Time time={createTime} />
                                    </TableCell>
                                    <TableCell>{comment}</TableCell>
                                    <TableCell>{cost} ₽</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <ListPagination
                        pagination={pagination}
                        listCount={count}
                        pageChange={handlePageChange}
                        pageSizeChange={pageSizeChange}
                        colSpan={3}
                        showPult
                        scrollToElementId="TablePaymentUsers"
                    />
                </Table>
            </ModalContent>
        </Modal>
    );
};

export default observer(PaymentsModal);
