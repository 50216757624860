import { PaginationType } from 'stores/prototypes/ListStore.prototype';

export const getDefaultPagination = (): PaginationType => ({
    activePage: 1,
    totalPages: 1,
    pageSize: 15
});

export const matchPaginationTotalPages = (pagination: PaginationType, count: number): PaginationType => {
    pagination.totalPages = Math.floor(count / pagination.pageSize) + 1;
    if (count > 0 && count % pagination.pageSize === 0) {
        pagination.totalPages -= 1;
    }
    return { ...pagination };
};
