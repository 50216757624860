import React from 'react';
import { observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';

import { isFeedbackSimilarType, isFeedbackType, WbItemFeedback } from 'api/productApi';
import productStore, { WbItem } from 'stores/productStore';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import Progress from 'ui/Progress';

import ProductThumbsBtns from './ProductThumbsBtns';
import ProductFeedbackItem from './ProductFeedbackItem';
import Price from 'components/Price';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FeedbackContext } from '../ProductFeedbacksMode';

export const FeedbackItem = React.memo(
    ({ feedback, feedback_id }: { feedback: WbItemFeedback; feedback_id: number }) => {
        return (
            <FeedbackContext.Provider value={feedback} key={feedback_id}>
                <ListItem alignItems="flex-start" secondaryAction={<ProductThumbsBtns />}>
                    <ProductFeedbackItem feedback={feedback} />
                </ListItem>
                <Divider variant="inset" component="li" />
            </FeedbackContext.Provider>
        );
    },
    (p1, p2) => p1.feedback_id !== p2.feedback_id
);

export const SimilarListItem = React.memo(
    ({ feedback_id, feedback, wbItem }: { feedback_id: number; feedback: WbItemFeedback; wbItem: WbItem }) => {
        const { title, photos } = wbItem;

        return (
            <FeedbackContext.Provider value={feedback} key={feedback_id}>
                <ListItem
                    alignItems="flex-start"
                    sx={{ minHeight: 140, paddingRight: '100px' }}
                    secondaryAction={
                        <Stack direction="column" spacing={0} alignItems="center">
                            <Avatar alt={title} src={photos[0]} variant="square" sx={{ width: 72, height: 72 }} />
                            <Typography
                                sx={{
                                    width: 96,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    fontSize: '11px'
                                }}
                            >
                                <a
                                    href={`https://www.wildberries.ru/catalog/${wbItem.wb_item_id}/detail.aspx`}
                                    target="_blank"
                                    title="Смотреть на wildberries.ru"
                                    rel="noreferrer"
                                >
                                    {title}
                                </a>
                            </Typography>
                            <Stack direction="row" spacing={1} alignItems="center">
                                {wbItem.price && <Price {...wbItem.price} />}
                            </Stack>
                        </Stack>
                    }
                >
                    <ProductFeedbackItem feedback={feedback} />
                </ListItem>
                <Divider variant="inset" component="li" />
            </FeedbackContext.Provider>
        );
    },
    (p1, p2) => p1.feedback_id !== p2.feedback_id
);

const FeedbacksInfinityScroll = ({ product_id }: { product_id: number }) => {
    const { feedbacks = [], feedbacksPult } = productStore.getItem(product_id).property;

    return (
        <PerfectScrollbar>
            <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={page => productStore.fetchNextFeedbacks(product_id, page)}
                hasMore={feedbacksPult?.hasMore}
                loader={
                    <div key="loading" style={{ position: 'relative' }}>
                        <Progress show linear />
                    </div>
                }
                useWindow={false}
            >
                <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {isFeedbackType(feedbacks) &&
                        feedbacks.map(feedback => (
                            <FeedbackItem
                                key={feedback.feedback_id}
                                feedback_id={feedback.feedback_id}
                                feedback={feedback}
                            />
                        ))}
                    {isFeedbackSimilarType(feedbacks) &&
                        feedbacks.map(({ feedback, wbItem }) => (
                            <SimilarListItem
                                key={feedback.feedback_id}
                                feedback_id={feedback.feedback_id}
                                feedback={feedback}
                                wbItem={wbItem}
                            />
                        ))}
                </List>
            </InfiniteScroll>
        </PerfectScrollbar>
    );
};

export default observer(FeedbacksInfinityScroll);
