import React from 'react';
import serverConfig from 'config/server.config';
import Container from '@mui/material/Container';

const VideoTabMode = ({ videoId }: { videoId: string }) => {
    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', py: 2 }}>
            <video controls controlsList="nodownload" autoPlay style={{ maxWidth: '100%' }}>
                <source src={`${serverConfig.videoUrl}/${videoId}.mp4`} type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
            </video>
        </Container>
    );
};

export default VideoTabMode;
