import Nprogress from 'nprogress';

export const catchItemError = (target: any, property: string, descriptor: { value?: (...args: any) => any }) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args) {
        Nprogress.start();
        const item_id = args[0];
        // @ts-ignore
        this.getItem(item_id).errors = [];
        // @ts-ignore
        this.getItem(item_id).loadingItem = true;

        try {
            // @ts-ignore
            return await originalMethod.call(this, ...args);
        } catch (errors) {
            // @ts-ignore
            this.getItem(item_id).errors = errors;
            return 0;
        } finally {
            // @ts-ignore
            this.getItem(item_id).loadingItem = false;
            Nprogress.done();
        }
    };

    return descriptor;
};

export const catchOnlyError = (target: any, property: string, descriptor: { value?: (...args: any) => any }) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args) {
        const item_id = args[0];
        // @ts-ignore
        this.getItem(item_id).errors = [];

        try {
            // @ts-ignore
            return await originalMethod.call(this, ...args);
        } catch (errors) {
            // @ts-ignore
            this.getItem(item_id).errors = errors;
            return 0;
        }
    };

    return descriptor;
};

export const nProgress = (target: any, property: string, descriptor: { value?: (...args: any) => Promise<any> }) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args) {
        Nprogress.start();

        try {
            if (originalMethod) {
                return await originalMethod.call(this, ...args);
            }
        } catch (errors) {
            console.log(errors);
            throw errors;
        } finally {
            Nprogress.done();
        }
    };

    return descriptor;
};

export const nProgressItem = (target: any, property: string, descriptor: { value?: (...args: any) => any }) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args) {
        Nprogress.start();

        const item_id = args[0];
        // @ts-ignore
        this.getItem(item_id).errors = [];

        try {
            // @ts-ignore
            return await originalMethod.call(this, ...args);
        } catch (errors) {
            // @ts-ignore
            this.getItem(item_id).errors = errors;
            return 0;
        } finally {
            Nprogress.done();
        }
    };

    return descriptor;
};

export const withAbortController =
    (abortControllerName: string) => (target: any, property: string, descriptor: { value?: (...args: any) => any }) => {
        const originalMethod = descriptor.value;

        descriptor.value = async function (...args) {
            const abortedKey = `${property}_aborted`;
            // @ts-ignore
            this[abortedKey] = false;

            // @ts-ignore
            if (this[abortControllerName]) {
                // @ts-ignore
                this[abortedKey] = true;
                // @ts-ignore
                this[abortControllerName].abort();
            }

            const controller = window.AbortController ? new window.AbortController() : null;
            // @ts-ignore
            this[abortControllerName] = controller;

            try {
                // @ts-ignore
                await originalMethod.apply(this, args);
                // @ts-ignore
                this[abortControllerName] = null;
            } catch (error) {
                // @ts-ignore
                if (!this[abortedKey]) {
                    console.log(error);
                    throw error;
                }
            }
        };

        return descriptor;
    };
