import React, { SyntheticEvent, useEffect, useState } from 'react';

import isEqual from 'common/isEqual';
import useDebounce from 'common/useDebounce';
import { WbCardAddinParam, WbConfigurationAddin } from 'api/wbApi/wbCardApi';
import * as wbCardApi from 'api/wbApi/wbCardApi';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import TextInput from 'ui/TextInput';
import LoaderAwait from 'ui/LoaderAwait';
import Select from 'ui/Select';

const CardFieldDictionary = ({
    addin,
    params,
    onChange
}: {
    addin: WbConfigurationAddin;
    params: WbCardAddinParam[];
    onChange: (type: string, params: WbCardAddinParam[]) => void;
}) => {
    const [options, setOptions] = useState<string[]>([]);
    const [search, setSearch] = useState('');
    const [errors, setErrors] = useState<string[]>([]);
    const [value, setValue] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const debounceSearch = useDebounce(search, 350);

    const handleChangeSearch = (event: SyntheticEvent, search: string) => {
        setSearch(search || '');
    };
    const { type, dictionary, maxCount, useOnlyDictionaryValues } = addin;

    useEffect(() => {
        if (dictionary) {
            setLoading(true);
            wbCardApi
                .fetchWbCardDictionaryFields(dictionary, type, search)
                .then(fields => {
                    if (fields?.length) {
                        setOptions(fields);
                    }
                })
                .catch(setErrors)
                .finally(() => setLoading(false));
        }
    }, [addin, debounceSearch]);

    useEffect(() => {
        let value: string[] = [];

        if (params.length) {
            if (params[0].value) {
                value = params.map(({ value }) => value || '');
            } else {
                value = params.map(({ count, units }) => `${count} ${units}`);
            }
        }
        setValue(value);
    }, [params]);

    const handleChange = (event: SyntheticEvent, value: string | string[] | null) => {
        setSearch('');
        if (typeof value === 'string') {
            onChange(type, [{ value }]);
        } else if (value instanceof Array) {
            if (value.length <= Number(maxCount)) {
                onChange(
                    type,
                    value.map(valueString => ({ value: valueString }))
                );
            }
        }
    };

    const label = `${type}${Number(maxCount) > 1 ? ` (до ${maxCount} штук)` : ''} - ${
        useOnlyDictionaryValues ? 'только' : 'можно не'
    } из словаря`;

    return (
        <Autocomplete
            multiple={Number(maxCount) > 1}
            options={options}
            loading={loading}
            renderInput={params => (
                <TextField
                    sx={{ background: 'white' }}
                    {...params}
                    label={label}
                    error={errors.length > 0}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <Box sx={{ position: 'relative' }}>
                                        <LoaderAwait active size={20} />
                                    </Box>
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
            fullWidth
            value={value}
            onChange={handleChange}
            getOptionLabel={(label: string | string[]) =>
                typeof label === 'string' ? label : label?.length ? label[0] : ''
            }
            onInputChange={handleChangeSearch}
            inputValue={search}
            freeSolo
            loadingText="Загрузка справочника с WB..."
        />
    );
};

const CardEditFieldUnits = ({
    addin,
    params,
    onChange
}: {
    addin: WbConfigurationAddin;
    params: WbCardAddinParam[];
    onChange: (type: string, params: WbCardAddinParam[]) => void;
}) => {
    const { type, required, isAvailable, units } = addin;
    const [currentUnit, setCurrentUnit] = useState<null | string>((units && units[0]) || null);

    const multilineProps = type === 'Описание' ? { multiline: true, rows: 3, rowsMax: 10, maxLength: 1000 } : {};

    let value = '';
    if (params.length) {
        if (params[0].value) {
            value = params[0].value;
        } else {
            const { count, units } = params[0];
            value = String(count);
            if (!currentUnit && units) {
                setCurrentUnit(units || null);
            }
        }
    }

    return (
        <TextInput
            label={type}
            required={required}
            disabled={!isAvailable}
            value={value}
            variant="classic"
            {...multilineProps}
            onChange={(event, { value }: { value: string }) =>
                onChange(type, [{ count: Number(value), units: currentUnit }])
            }
            extraLabel={
                <Select
                    value={currentUnit}
                    onChange={({ value }) => {
                        setCurrentUnit(value);
                    }}
                    options={(units || []).map(unit => ({ text: unit, value: unit }))}
                    variant="standard"
                />
            }
        />
    );
};

const CardEditField = React.memo(
    ({
        addin,
        params,
        onChange
    }: {
        addin: WbConfigurationAddin;
        params: WbCardAddinParam[];
        onChange: (type: string, params: WbCardAddinParam[]) => void;
    }) => {
        const { type, required, isAvailable, units, dictionary } = addin;

        if (dictionary) {
            return <CardFieldDictionary addin={addin} params={params} onChange={onChange} />;
        }
        if (units) {
            return <CardEditFieldUnits addin={addin} params={params} onChange={onChange} />;
        }

        const multilineProps = type === 'Описание' ? { multiline: true, rows: 3, rowsMax: 10, maxLength: 1000 } : {};

        let value = '';
        if (params.length) {
            if (params[0].value) {
                value = params[0].value;
            } else {
                console.log('See here!');
            }
        }

        return (
            <TextInput
                label={type}
                required={required}
                disabled={!isAvailable}
                value={value}
                variant="classic"
                {...multilineProps}
                onChange={(event, { value }: { value: string }) => onChange(type, [{ value }])}
            />
        );
    },
    (p1, p2) => isEqual(p1.params, p2.params)
);

export default CardEditField;
