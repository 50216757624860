import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import productStore, { StoreIds } from 'stores/productStore';
import ransomStore from 'stores/ransomStore';
import authStore from 'stores/authStore';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';

import Select from 'ui/Select';
import Button from 'ui/Button';
import { useToggleModal } from 'ui/Modal';

import StoresToggle from 'components/StoresToggle';
import RansomAddItemsModal from './RansomAddItemsModal';

const RansomSelectProductEditing = ({ ransom_id }: { ransom_id: number }) => {
    const [showModal, toggleModal] = useToggleModal();

    const handleChangeWbItem = ({ value }: { value: number }) => {
        ransomStore.setEditingItem(ransom_id, { product_id: value, store_id: productStore.getCurrentStoreId() });
        productStore.fetchItem(value);
    };

    const { list, loadingList } = productStore;
    const productOptions = list.map(product => {
        const { product_id, store_item_id } = product;
        const { price, titleLong } = productStore.calcShortProduct(product);

        return {
            value: product_id,
            text: `№${store_item_id}: ${titleLong}`,
            disabled: !price
        };
    });

    const { product_id } = ransomStore.getItem(ransom_id).editingItem;
    const { settings } = authStore;

    return (
        <Stack spacing={2}>
            <StoresToggle />
            <Stack direction="row" spacing={1} justifyContent="space-between">
                <Select
                    label="Выберите товар"
                    value={product_id}
                    onChange={handleChangeWbItem}
                    options={productOptions}
                    fullWidth
                    loading={loadingList}
                    disabled={loadingList}
                    variant="classic"
                />
                {product_id && productStore.getCurrentStoreId() === StoreIds.WB && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={toggleModal}>
                        <Button
                            size="huge"
                            icon={<AddIcon />}
                            tooltip={'Добавить дополнительные товар(ы) из списка "Мои товары" в выкуп'}
                            disabled={!settings['additionalItems_wb']}
                        />
                    </Box>
                )}
                {showModal && <RansomAddItemsModal ransom_id={ransom_id} onClose={toggleModal} />}
            </Stack>
        </Stack>
    );
};

export default observer(RansomSelectProductEditing);
