import React, { useState } from 'react';
import { observer } from 'mobx-react';

import wbSalesApiStore from 'stores/wbApi/wbStatisticsApiStore';

import Box from '@mui/material/Box';

import Table, { TableBody, TableCell, TableHead, TableRow } from 'ui/Table';
import Time from 'ui/Time';
import Button from 'ui/Button';

import ProductLink from 'components/Items/Product/ProductLink';
import WbItemAvatar from 'components/Items/Product/WbItemAvatar';
import ListPagination from '../../ListPagination';

import ListWrapper from '../../ListWrapper';
import WbStatisticsApiModal from './WbStatisticsApiModal';

const ApiStatisticsList = () => {
    const [activeOdid, setActiveOdis] = useState<number | null>(null);

    const { list, loadingList } = wbSalesApiStore;

    return (
        <ListWrapper store={wbSalesApiStore} bgUrl={''}>
            <Table elevation={0} loading={loadingList} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>id реализации</TableCell>
                        <TableCell>Создание</TableCell>
                        <TableCell>Обновление</TableCell>
                        <TableCell>Товар</TableCell>
                        <TableCell>sale</TableCell>
                        <TableCell>order</TableCell>
                        <TableCell>realizations</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(({ odid, sale, order, realizations, wb_item_id, wbItem, createTime, updateTime }) => (
                        <TableRow key={odid}>
                            <TableCell>
                                <Button onClick={() => setActiveOdis(odid)}>{odid}</Button>
                            </TableCell>
                            <TableCell>
                                <Time time={createTime} />
                            </TableCell>
                            <TableCell>
                                <Time time={updateTime} />
                            </TableCell>
                            <TableCell>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                    <div>
                                        <WbItemAvatar wb_item_id={wb_item_id} size={48} />
                                    </div>
                                    <ProductLink product_id={wb_item_id} title={wbItem.title} />
                                </Box>
                            </TableCell>
                            <TableCell>{sale ? '+++' : '---'}</TableCell>

                            <TableCell>{order ? '+++' : '---'}</TableCell>

                            <TableCell>{realizations.length}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <ListPagination
                    pagination={wbSalesApiStore.pagination}
                    listCount={wbSalesApiStore.listCount}
                    pageChange={wbSalesApiStore.pageChange}
                    pageSizeChange={wbSalesApiStore.pageSizeChange}
                    colSpan={6}
                />
            </Table>
            {activeOdid && <WbStatisticsApiModal odid={activeOdid} onClose={() => setActiveOdis(null)} />}
        </ListWrapper>
    );
};

export default observer(ApiStatisticsList);
