import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import locationHistory from 'locationHistory';

import ransomStore, { RansomType } from 'stores/ransomStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import productStore from 'stores/productStore';

import Table, { TableBody, TableCell, TableHead, TableRow } from 'ui/Table';
import Time from 'ui/Time';
import { WarningMessage } from 'ui/Message';
import Button from 'ui/Button';
import CutString from 'ui/CutString';

import Chip from '@mui/material/Chip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaymentIcon from '@mui/icons-material/Payment';
import Typography from '@mui/material/Typography';

import ListWrapper from '../ListWrapper';
import ListPagination from '../ListPagination';

import { RansomLinkState } from 'components/Items/Ransom/RansomLink';
import RansomItemMenu from 'components/Items/Ransom/RansomItemMenu';

import RansomsFilter from './RansomsFilter';
import RansomStatus from './RansomStatus';
import ProductTitleLink from '../Products/ProductTitleLink';

const RansomsList = () => {
    const { list, loadingList, handleSort, adjustDirection } = ransomStore;

    const toggleRansomModal = () => {
        setTimeout(() => {
            productStore.fetchList();
            ransomStore.fetchItem(CREATING_ITEM_ID);
            ransomStore.setEditingItem(CREATING_ITEM_ID, {});
        }, 0);
        locationHistory.push(RansomLinkState(CREATING_ITEM_ID));
    };

    return (
        <ListWrapper
            store={ransomStore}
            bgUrl="/static/listbg/unruly-11-message-sent.webp"
            createBtn={
                <Button startIcon={<PaymentIcon />} variant="contained" onClick={() => toggleRansomModal()}>
                    Создать выкуп
                </Button>
            }
        >
            <RansomsFilter />

            <Table elevation={0} loading={loadingList}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sortDirection={adjustDirection('createTime')}
                            onClick={() => handleSort('createTime')}
                        >
                            Дата
                        </TableCell>
                        <TableCell sortDirection={adjustDirection('status')} onClick={() => handleSort('status')}>
                            Статус
                        </TableCell>
                        <TableCell
                            sx={{ minWidth: '250px' }}
                            sortDirection={adjustDirection('product_id')}
                            onClick={() => handleSort('product_id')}
                        >
                            Наименование
                        </TableCell>
                        <TableCell>Цена</TableCell>
                        <TableCell
                            sx={{ minWidth: '150px' }}
                            sortDirection={adjustDirection('wb_store_id')}
                            onClick={() => handleSort('wb_store_id')}
                        >
                            Адрес
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map(
                        ({
                            ransom_id,
                            product,
                            deliveryStorePoint,
                            createTime,
                            price,
                            status,
                            type,
                            planningExecuteTime,
                            ransomsCount,
                            enable
                        }) => (
                            <TableRow key={ransom_id}>
                                <TableCell>
                                    <Time time={createTime} />
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => locationHistory.push(RansomLinkState(ransom_id))}
                                        tooltip={
                                            type === RansomType.Group
                                                ? 'Открыть группу выкупов'
                                                : 'Открыть карточку выкупа'
                                        }
                                    >
                                        {type === RansomType.Group && (
                                            <Chip label="Группа выкупов" color="primary" sx={{ cursor: 'pointer' }} />
                                        )}
                                        {type === RansomType.Smart && (
                                            <Chip
                                                icon={<AccessTimeIcon />}
                                                label="Smart выкупы"
                                                color="secondary"
                                                sx={{ cursor: 'pointer' }}
                                            />
                                        )}
                                        {type === RansomType.Once && (
                                            <RansomStatus
                                                status={status}
                                                enable={enable}
                                                planningExecuteTime={planningExecuteTime}
                                            />
                                        )}
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <ProductTitleLink product={product} />
                                </TableCell>
                                <TableCell nowrap>{price.toLocaleString()} ₽</TableCell>
                                <TableCell>
                                    {type !== RansomType.Smart && (
                                        <CutString title={deliveryStorePoint?.address} maxLength={30} />
                                    )}
                                    {type === RansomType.Smart && (
                                        <Typography>
                                            {ransomsCount}&nbsp; выкуп
                                            {Number(ransomsCount) > 4 ? 'ов' : Number(ransomsCount) > 1 ? 'а' : ''}
                                        </Typography>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <RansomItemMenu ransom_id={ransom_id} />
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
                <ListPagination
                    pagination={ransomStore.pagination}
                    listCount={ransomStore.listCount}
                    pageChange={ransomStore.pageChange}
                    pageSizeChange={ransomStore.pageSizeChange}
                    colSpan={5}
                />
            </Table>

            {!loadingList && list.length === 0 && (
                <WarningMessage header="Нет выкупов">
                    Вы можете добавить выкуп любого вашего <Link to="/products/list">товара</Link>
                </WarningMessage>
            )}
        </ListWrapper>
    );
};

export default observer(RansomsList);
