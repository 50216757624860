import { action, makeObservable } from 'mobx';
import * as questionApi from 'api/questionApi';
import { ProductList, ProductPrototypeFilter, StoreIds } from './productStore';
import ListTransactionStorePrototype from './prototypes/ListTransactionsStore.prototype';
import videoStore from './videoStore';

export type Question = {
    question_id: number;
    user_id: number;
    product_id: number | null;
    questionText: string;
    account_id: number;
    status: 1 | 2 | 100;
    errorMessage: string | null;

    gender: 'male' | 'female' | null;
    searchRequest: string | null;

    product: ProductList;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export interface QuestionFilter extends ProductPrototypeFilter {
    status: Question['status'] | null;
    store_id: StoreIds;
    product_id: number | null;
    allUsers: boolean;
    enable: boolean;
}

class QuestionStore extends ListTransactionStorePrototype<Question, Question, {}, QuestionFilter> {
    constructor() {
        super('question_id', 'question', questionApi);
        this.orderBy = 'question_id';
        this.clearFilter();

        makeObservable(this);
    }

    listFilterClear = {
        status: null,
        store_id: StoreIds.WB,
        product_id: null,
        allUsers: false,
        enable: true
    };

    @action
    async refreshUpdateStoreQuestion(question_id: number): Promise<void> {
        this.getItem(question_id).loadingItem = true;
        try {
            await questionApi.refreshUpdateStoreQuestion(question_id);
            await this.fetchItem(question_id);
        } catch (errors) {
            if (errors instanceof Array) {
                this.getItem(question_id).errors = errors;
            }
        } finally {
            videoStore.findWbVideo('question', question_id);
            this.getItem(question_id).loadingItem = false;
        }
    }
}

export default new QuestionStore();
