import React, { SyntheticEvent, useMemo } from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import autoAnswerStore from 'stores/autoAnswerStore';

import TextInput from 'ui/TextInput';
import Grid from 'ui/Grid';
import Checkbox from 'ui/Checkbox';
import { useToggleModal } from 'ui/Modal';
import ListError from 'ui/ListError';
import Button from 'ui/Button';
import Confirm from 'ui/Confirm';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Container from '@mui/material/Container';

import AnswersSamplesModal from './AnswersSamplesModal';

const AutoAnswers = ({ auto_answer_id }: { auto_answer_id: number }) => {
    const [showSamplesModal, toggleSamplesModal] = useToggleModal();
    const [showConfirmDelete, toggleConfirmDelete] = useToggleModal();

    useMemo(() => {
        autoAnswerStore.fetchItem(auto_answer_id);
        autoAnswerStore.setEditingItem(auto_answer_id, {
            title: '',
            text: '',
            stars: [5]
        });
    }, [auto_answer_id]);

    const {
        editingItem: { title, stars = [], text = '' },
        loadingItem,
        errors
    } = autoAnswerStore.getItem(auto_answer_id);

    const handleChangeTitle = (event: SyntheticEvent, { value }: { value: string }) => {
        autoAnswerStore.setEditingItem(auto_answer_id, { title: value });
    };

    const handleChangeText = (event: SyntheticEvent, { value }: { value: string }) => {
        autoAnswerStore.setEditingItem(auto_answer_id, { text: value });
    };

    const toggleStar = (star: 1 | 2 | 3 | 4 | 5) => {
        const newStars = [...stars];
        const index = newStars.indexOf(star);

        if (~index) {
            newStars.splice(index, 1);
        } else {
            newStars.push(star);
        }
        autoAnswerStore.setEditingItem(auto_answer_id, { stars: [...newStars] });
    };

    const closeTab = () => locationHistory.push({ pathname: '/api/answers' });

    const handleSave = async () => {
        if (await autoAnswerStore.saveItem(auto_answer_id)) {
            autoAnswerStore.fetchList();
            closeTab();
        }
    };

    const handleCreate = async () => {
        const auto_answer_id = await autoAnswerStore.createItem();
        if (auto_answer_id) {
            autoAnswerStore.fetchList();
            closeTab();
        }
    };

    const handleDelete = async () => {
        await autoAnswerStore.toggleDisableItem(auto_answer_id, false);
        closeTab();
    };

    const isCreating = auto_answer_id === CREATING_ITEM_ID;

    const onSelectAnswer = (text: string) => {
        autoAnswerStore.setEditingItem(auto_answer_id, { text });
    };

    return (
        <Container maxWidth="sm">
            <ListError errors={errors} />
            <Grid>
                <Grid.Column>
                    <TextInput
                        maxLength={255}
                        label="Название шаблона"
                        value={title}
                        onChange={handleChangeTitle}
                    ></TextInput>
                </Grid.Column>
                <Grid.Column>
                    {([1, 2, 3, 4, 5] as const).map(star => (
                        <Checkbox
                            key={star}
                            label={star}
                            checked={stars.includes(star)}
                            onClick={() => toggleStar(star)}
                        />
                    ))}
                </Grid.Column>
                <Grid.Column width={11}>
                    <TextInput
                        multiline
                        rows={2}
                        rowsMax={20}
                        value={text}
                        label="Шаблон ответа"
                        onChange={handleChangeText}
                    />
                </Grid.Column>
                <Grid.Column width={1}>
                    <Button
                        onClick={toggleSamplesModal}
                        variant="outlined"
                        disabled={stars.length === 0}
                        color="secondary"
                        tooltip="Посмотреть примеры ответов"
                        icon={<PlaylistAddIcon />}
                    />
                </Grid.Column>
                {!isCreating && (
                    <Grid.Column width={6}>
                        <Button color="secondary" fullWidth onClick={toggleConfirmDelete}>
                            Удалить
                        </Button>
                        {showConfirmDelete && (
                            <Confirm
                                content="Убрать шаблон в архив?"
                                header="Удалить шаблон"
                                confirmButton="Удалить"
                                onConfirm={handleDelete}
                                onCancel={toggleConfirmDelete}
                            />
                        )}
                    </Grid.Column>
                )}
                <Grid.Column width={6}>
                    <Button
                        loading={loadingItem}
                        variant="contained"
                        disabled={stars.length === 0 || text.length < 2}
                        onClick={isCreating ? handleCreate : handleSave}
                        fullWidth
                    >
                        {isCreating ? 'Создать' : 'Сохранить'}
                    </Button>
                </Grid.Column>
            </Grid>
            {showSamplesModal && (
                <AnswersSamplesModal onClose={toggleSamplesModal} onSelect={onSelectAnswer} stars={stars} />
            )}
        </Container>
    );
};

export default observer(AutoAnswers);
