import React, { SyntheticEvent, useState } from 'react';
import { observer } from 'mobx-react';

import authStore, { SystemSettingsType } from 'stores/authStore';

import Modal, { ModalBtnAction, ModalContent } from 'ui/Modal';
import { Switch } from 'ui/Checkbox';
import TextInput from 'ui/TextInput';
import Grid from 'ui/Grid';
import Button from 'ui/Button';
import Divider from 'ui/Divider';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const SystemSettingsModal = ({ onClose }: { onClose: () => void }) => {
    const { settings } = authStore;

    const [systemMessage, setSystemMessage] = useState(settings.systemMessage);

    const actions: ModalBtnAction[] = [{ onClick: onClose, label: 'Отмена', variant: 'text', color: 'inherit' }];

    const toggleValue = (key: keyof SystemSettingsType) => () => {
        authStore.changeSettingsKey(key, !settings[key]);
    };

    const saveSystemMessage = () => {
        authStore.changeSettingsKey('systemMessage', systemMessage);
    };

    return (
        <Modal maxWidth="md" onClose={onClose} actions={actions} header="Настройки Topstore">
            <ModalContent>
                <Stack spacing={2}>
                    <Stack direction="row">
                        <TextInput
                            value={systemMessage}
                            name="login"
                            label="Системное сообщение"
                            onChange={(event: SyntheticEvent, { value }: { value: string }) => {
                                setSystemMessage(value);
                            }}
                            fullWidth
                        />
                        <Button color="secondary" variant="outlined" size="small" onClick={saveSystemMessage}>
                            Сохранить
                        </Button>
                    </Stack>
                    <Grid columns={2} alignItems="flex-start">
                        <Grid.Column>
                            <Paper>
                                <Stack spacing={2}>
                                    <Typography variant="h6">Wildberries</Typography>
                                    <Switch label="QR-СБП" checked={settings['qr']} onChange={toggleValue('qr')} />
                                    <Switch
                                        label="Sberpay"
                                        checked={settings['sberpay']}
                                        onChange={toggleValue('sberpay')}
                                    />
                                    <Switch label="Карта" checked={settings['carts']} onChange={toggleValue('carts')} />
                                    <Divider />
                                    <Switch
                                        label="Прогон аккаунта"
                                        checked={settings['preRunAccount_wb']}
                                        onChange={toggleValue('preRunAccount_wb')}
                                    />
                                    <Switch
                                        label="Одноразовый аккаунт"
                                        checked={settings['oneTimeAccount_wb']}
                                        onChange={toggleValue('oneTimeAccount_wb')}
                                    />
                                    <Switch
                                        label="Дополнительные товары в выкуп"
                                        checked={settings['additionalItems_wb']}
                                        onChange={toggleValue('additionalItems_wb')}
                                    />
                                    <Switch
                                        label="Писать отзывы"
                                        checked={settings['reviewsEnable_wb']}
                                        onChange={toggleValue('reviewsEnable_wb')}
                                    />
                                </Stack>
                            </Paper>
                        </Grid.Column>
                        <Grid.Column>
                            <Paper>
                                <Stack spacing={2}>
                                    <Typography variant="h6">Ozon</Typography>
                                    <Switch
                                        label="QR-СБП"
                                        checked={settings['qrOzon']}
                                        onChange={toggleValue('qrOzon')}
                                    />
                                    <Divider />
                                    <Switch
                                        label="Одноразовый аккаунт"
                                        checked={settings['oneTimeAccount_ozon']}
                                        onChange={toggleValue('oneTimeAccount_ozon')}
                                    />
                                </Stack>
                            </Paper>
                        </Grid.Column>
                    </Grid>
                    <Switch
                        label="Групповые выкупы"
                        checked={settings['groupRansoms']}
                        onChange={toggleValue('groupRansoms')}
                    />
                </Stack>
            </ModalContent>
        </Modal>
    );
};

export default observer(SystemSettingsModal);
