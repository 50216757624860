import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';
import matchWbPhotoUrl from 'common/matchWbPhotoUrl';

import productStore from 'stores/productStore';
import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';

import Typography from '@mui/material/Typography';

import Modal, { ModalBtnAction, ModalContent } from 'ui/Modal';
import ListError from 'ui/ListError';

import { ProductLinkState } from '../ProductLink';
import { ProductCardFrame } from '../ProductCard';

const AddItemColorModal = ({
    onClose,
    wb_item_id,
    title,
    brandName,
    disableGoToCard
}: {
    onClose: () => void;
    wb_item_id: number;
    title: string;
    brandName?: string;
    disableGoToCard?: boolean;
}) => {
    useEffect(() => {
        productStore.clearEditingItem(CREATING_ITEM_ID);
    }, []);

    const handleAdd = async () => {
        const product_id = await productStore.createItemFromDropdown(wb_item_id);
        if (product_id && !disableGoToCard) {
            locationHistory.replace(ProductLinkState(product_id));
        } else if (disableGoToCard) {
            onClose();
        }
    };

    const { loadingItem, errors } = productStore.getItem(CREATING_ITEM_ID);

    const actions: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', variant: 'text', color: 'inherit', disabled: loadingItem },
        { onClick: handleAdd, label: 'Добавить', variant: 'contained', color: 'primary', loading: loadingItem }
    ];

    const { preview } = matchWbPhotoUrl(wb_item_id);

    return (
        <Modal
            header="Добавить данный Артикул в список выших товаров?"
            maxWidth="sm"
            onClose={onClose}
            actions={actions}
        >
            <ModalContent>
                <ListError errors={errors} />
                <Typography>
                    Артикул: <b>{wb_item_id}</b>
                </Typography>
                <ProductCardFrame
                    photoUrl={preview}
                    title={title || 'Наименование не задано'}
                    brandName={brandName || ''}
                />
            </ModalContent>
        </Modal>
    );
};

export default observer(AddItemColorModal);
