import React, { useState } from 'react';
import { observer } from 'mobx-react';
import locationHistory from 'locationHistory';

import productStore, { ProductItemColor, StoreIds, WbItemColorType } from 'stores/productStore';
import commonStore from 'stores/commonStore';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Badge from '@mui/material/Badge';

import { ProductLinkState } from '../ProductLink';
import AddItemColorModal from './AddItemColorModal';
import WbItemAvatar from '../WbItemAvatar';

const ProductColorTab = React.memo(
    ({
        wb_item_id,
        colors,
        isActive,
        inYourList
    }: {
        wb_item_id: number;
        isActive: boolean;
        colors: WbItemColorType[];
        inYourList: boolean;
    }) => {
        return (
            <Box>
                <Tooltip title={`Артикул: ${wb_item_id}; Цвет: ${colors.map(({ color }) => color).join(', ')}`}>
                    <Badge
                        variant="dot"
                        invisible={inYourList}
                        color="primary"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                    >
                        <WbItemAvatar wb_item_id={wb_item_id} size={80} />
                    </Badge>
                </Tooltip>
            </Box>
        );
    },
    (p1, p2) => p1.wb_item_id !== p2.wb_item_id
);

const ProductColorsTabs = ({
    colors,
    activeWbId,
    color,
    product_id
}: {
    colors: ProductItemColor[];
    activeWbId: number;
    color?: ProductItemColor;
    product_id: number;
}) => {
    const [activeColorItem, setActiveWbItem] = useState<null | number>(null);
    const handleActiveColorItem = (wb_item_id: number | null) => {
        setActiveWbItem(wb_item_id);
    };

    const { list } = productStore;
    const handleOpenProduct = (product_id: number) => {
        locationHistory.replace(ProductLinkState(product_id));
    };

    const item = productStore.getItem(product_id).item;
    if (!item) {
        return null;
    }
    const { title, brand } = productStore.calcShortProduct(item);

    return (
        <Box sx={{ pt: 2 }}>
            {color && color.colors.length > 0 && (
                <Typography>
                    Цвет: <b>{color.colors.map(({ color }) => color).join(', ')}</b>
                </Typography>
            )}
            <Tabs
                allowScrollButtonsMobile
                value={activeWbId}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
            >
                {colors.map(({ wb_item_id, colors }) => {
                    const foundProduct = list.find(
                        product => product.store_item_id === wb_item_id && product.store_id === StoreIds.WB
                    );

                    return (
                        <Tab
                            value={wb_item_id}
                            key={wb_item_id}
                            label={
                                <ProductColorTab
                                    wb_item_id={wb_item_id}
                                    isActive={activeWbId === wb_item_id}
                                    colors={colors}
                                    inYourList={Boolean(foundProduct)}
                                />
                            }
                            onClick={() =>
                                foundProduct
                                    ? handleOpenProduct(foundProduct.product_id)
                                    : handleActiveColorItem(wb_item_id)
                            }
                            disabled={false}
                            wrapped={commonStore.isMobile}
                            sx={{ px: commonStore.isMobile ? 1 : 3 }}
                        />
                    );
                })}
            </Tabs>
            {activeColorItem && item && (
                <AddItemColorModal
                    onClose={() => handleActiveColorItem(null)}
                    wb_item_id={activeColorItem}
                    title={title}
                    brandName={brand?.name}
                />
            )}
        </Box>
    );
};

export default observer(ProductColorsTabs);
