import { objectToGraphql } from 'common/graphql';
import fetchApi from 'common/fetchApi';
import { SystemSettingsType } from '../stores/authStore';

export const updateSettings = async (settings: Partial<SystemSettingsType>): Promise<boolean> => {
    const graphql = `mutation { 
          updateSettings(settings: ${objectToGraphql(settings)})
        }`;

    const data = await fetchApi.postGQ<{ updateSettings: boolean }>(graphql);
    return data.updateSettings;
};

export const pullSystemSettings = async (): Promise<SystemSettingsType> => {
    const graphql = `{ 
          pullSystemSettings {
            config {
                key
                value
            }
          }
        }`;

    const data = await fetchApi.getGQ<{ pullSystemSettings: { config: { key: string; value: string }[] } }>(graphql);
    const settings = data.pullSystemSettings.config.reduce((acc, { key, value }) => {
        return {
            ...acc,
            [key]: value === 'false' ? false : value === 'true' ? true : value
        };
    }, {}) as SystemSettingsType;
    return settings;
};

export const changeSettingsKey = async <T extends keyof SystemSettingsType>(
    key: T,
    value: SystemSettingsType[T]
): Promise<boolean> => {
    const graphql = `mutation { 
          changeSettingsKey(key: ${objectToGraphql(key)}, value: ${objectToGraphql(String(value))})
        }`;

    const data = await fetchApi.postGQ<{ updateSettings: boolean }>(graphql);
    return data.updateSettings;
};
