import React from 'react';

import Table, { TableCell, TableRow, TableBody } from 'ui/Table';
import Price from 'components/Price';

const PriceTable = ({ prices }: { prices: { title: string; price: number; priceWithSale: number }[] }) => {
    return (
        <Table size="medium">
            <TableBody>
                {prices.map(({ price, priceWithSale, title }) => (
                    <TableRow key={title}>
                        <TableCell>{title}</TableCell>
                        <TableCell>
                            <Price price={price} priceWithSale={priceWithSale} direction="row-reverse" />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default PriceTable;
