import React from 'react';
import commonStore from 'stores/commonStore';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

const FFDeliveryTypeToggle = ({ type, onChane }: { type: 1 | 2; onChane: (type: 1 | 2) => void }) => {
    const { isMobile } = commonStore;

    return (
        <ToggleButtonGroup
            size={isMobile ? 'small' : 'large'}
            color="secondary"
            value={type}
            exclusive
            onChange={(event, type) => onChane(type)}
            fullWidth
        >
            <ToggleButton value={1} sx={{ gap: 1 }}>
                <LocalShippingIcon /> FBO {isMobile ? '' : '(На склад Маркетплейса)'}
            </ToggleButton>
            <ToggleButton value={2} sx={{ gap: 1 }}>
                <DeliveryDiningIcon /> FBS {isMobile ? '' : '(Система маркетплейс)'}
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default FFDeliveryTypeToggle;
