import React, { ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react';
import * as userApi from 'api/userApi';

import commonStore from 'stores/commonStore';
import authStore from 'stores/authStore';

import Box from '@mui/material/Box';

import Scrollbar from 'ui/Scrollbar';

const ListContainer = ({ children, bgUrl }: { children: ReactNode; bgUrl?: string }) => {
    useEffect(() => {
        userApi.sendUserAction('openList');
        return () => {
            userApi.sendUserAction('closeList');
        };
    }, []);

    const { isMobile } = commonStore;
    const hasServiceMessage = Boolean(authStore.settings?.systemMessage);

    const height = `calc(100vh - ${isMobile ? '72' : '80'}px${hasServiceMessage ? ' - 3rem' : ''})`;

    return (
        <Scrollbar disableOnMobile style={{ minHeight: height, maxHeight: height, flex: 1 }}>
            <Box
                sx={{
                    padding: isMobile ? 0 : '0 1rem 2rem',
                    position: 'relative',
                    minHeight: height,
                    zIndex: 1,
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0.45,
                        zIndex: -1,
                        backgroundImage: bgUrl ? `url('${bgUrl}')` : undefined,
                        backgroundPosition: 'bottom right',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat'
                    }
                }}
            >
                {children}
            </Box>
        </Scrollbar>
    );
};

export default observer(ListContainer);
