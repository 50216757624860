import React, { ReactNode, SyntheticEvent } from 'react';
import { Modal, ModalContentText, ModalHeader, ModalBtnAction, ModalContent } from 'ui/Modal';

type ConfirmTriggerProps = {
    content: ReactNode;
    header: string;
    confirmButton: string;
    onConfirm: (event?: SyntheticEvent) => void;
    onCancel: (event?: SyntheticEvent) => void;
};

const Confirm = ({ header, content, confirmButton, onConfirm, onCancel }: ConfirmTriggerProps) => {
    const ConfirmActions: ModalBtnAction[] = [
        { onClick: onCancel, label: 'Отмена', variant: 'text', color: 'primary' },
        { onClick: onConfirm, label: confirmButton, variant: 'outlined', color: 'primary' }
    ];

    return (
        <Modal onClose={onCancel} maxWidth="xs" actions={ConfirmActions} transition={'slideUp'}>
            <ModalHeader>{header}</ModalHeader>
            <ModalContent>
                <ModalContentText>{content}</ModalContentText>
            </ModalContent>
        </Modal>
    );
};

export default Confirm;
