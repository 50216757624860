import React from 'react';

import { Ransom } from 'stores/ransomStore';

import RecentActorsIcon from '@mui/icons-material/RecentActors';
import Stack from '@mui/material/Stack';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import TimesOneMobiledataIcon from '@mui/icons-material/TimesOneMobiledata';

import { Message, MessageTitle } from 'ui/Message';

export const RansomAccountShow = ({ ransom }: { ransom: Ransom | null }) => {
    const { preRunAccount, oneTimeAccount } = ransom || {};
    if (!preRunAccount && !oneTimeAccount) {
        return null;
    }

    return (
        <Message icon={<RecentActorsIcon />} sx={{ mt: 3 }}>
            <MessageTitle>Действия с аккаунтом</MessageTitle>
            <Stack>
                {preRunAccount && (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <DirectionsRunIcon />
                        <div>Прогон аккаунта</div>
                    </Stack>
                )}
                {oneTimeAccount && (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <TimesOneMobiledataIcon />
                        <div>Одноразовый аккаунт</div>
                    </Stack>
                )}
            </Stack>
        </Message>
    );
};

export default RansomAccountShow;
