import React from 'react';

import { WbItemExtended } from 'stores/productStore';

import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import Grid from 'ui/Grid';
import Table, { TableBody, TableRow, TableCell } from 'ui/Table';

const OptionsAndTagsWB = ({ wbItem }: { wbItem: WbItemExtended }) => {
    return (
        <Paper elevation={1} sx={{ padding: '0 2rem 2rem', my: 3 }}>
            <Grid alignItems="flex-start" spacing={3} stackable>
                <Grid.Column width={12}>
                    <Table elevation={0} compact>
                        <TableBody>
                            {wbItem.options.map(({ subProperty, property }) => (
                                <TableRow key={property}>
                                    <TableCell>{property}</TableCell>
                                    <TableCell>{subProperty}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid.Column>
                <Grid.Column width={12}>
                    <Typography variant="h6">C этим товаром искали</Typography>
                    {wbItem.tags.map(tag => (
                        <Chip
                            key={tag}
                            label={tag}
                            variant="outlined"
                            color="secondary"
                            style={{ margin: '0.25rem' }}
                        />
                    ))}
                </Grid.Column>
            </Grid>
        </Paper>
    );
};

export default OptionsAndTagsWB;
