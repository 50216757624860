import React from 'react';
import Avatar from '@mui/material/Avatar';
import matchWbPhotoUrl from 'common/matchWbPhotoUrl';

const WbItemAvatar = ({ wb_item_id, size = 48 }: { wb_item_id: number; size: number }) => {
    const { preview } = matchWbPhotoUrl(wb_item_id);

    return (
        <Avatar
            sx={{ width: `${size}px`, height: `${size}px` }}
            src={preview}
            variant="rounded"
            imgProps={{ loading: 'lazy' }}
        />
    );
};

export default WbItemAvatar;
