import fetchApi from 'common/fetchApi';
import { objectToGraphql } from '../common/graphql';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { Brand, BrandFilter } from 'stores/brandStore';
import { GQProductListFields } from './productApi';

export const createItem = async (brand: Partial<Brand>): Promise<number> => {
    const graphql = `mutation { 
          createBrand(brand: ${objectToGraphql(brand)})
        }`;

    const data = await fetchApi.postGQ<{ createBrand: number }>(graphql);
    return data.createBrand;
};

const GQBrandFields = `
    brand_id
    user_id
    product_id

    searchRequest
    quantity
    speed

    product {
        ${GQProductListFields}
    }

    status
    errorMessage
    createTime
    updateTime
    enable
`;

export const fetchItem = async (brand_id: number): Promise<Brand> => {
    const graphql = `{ 
          fetchBrand(brand_id: ${brand_id}) {
            ${GQBrandFields}
           }
        }`;

    const data = await fetchApi.getGQ<{ fetchBrand: Brand }>(graphql);
    return data.fetchBrand;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    brandFilter: Partial<BrandFilter>,
    controller?: AbortController
): Promise<{ list: Brand[]; count: number }> => {
    const graphql = `{
            fetchBrandsList(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(brandFilter)}) {
                list {
                    ${GQBrandFields}
                }
                count
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchBrandsList: { list: Brand[]; count: number } }>(graphql, controller);
    return data.fetchBrandsList;
};

export const refreshUpdateWbBrand = async (brand_id: number): Promise<boolean> => {
    const graphql = `mutation {
          refreshUpdateWbBrand(brand_id: ${brand_id})
        }`;

    const data = await fetchApi.postGQ<{ refreshUpdateWbBrand: boolean }>(graphql);
    return data.refreshUpdateWbBrand;
};
