import fetchApi from 'common/fetchApi';
import { objectToGraphql } from '../common/graphql';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { Cart, CartFilter } from 'stores/cartStore';
import { GQProductListFields } from './productApi';

export const createItem = async (cart: Partial<Cart>): Promise<number> => {
    const graphql = `mutation { 
          createCart(cart: ${objectToGraphql(cart)})
        }`;

    const data = await fetchApi.postGQ<{ createCart: number }>(graphql);
    return data.createCart;
};

const GQCartFields = `
    cart_id
    user_id
    product_id

    product {
        ${GQProductListFields}
    }

    speed
    quantity
    searchRequest
    status
    createTime
    updateTime
    enable
`;

export const fetchItem = async (cart_id: number): Promise<Cart> => {
    const graphql = `{ 
          fetchCart(cart_id: ${cart_id}) {
            ${GQCartFields}
           }
        }`;

    const data = await fetchApi.getGQ<{ fetchCart: Cart }>(graphql);
    return data.fetchCart;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    cartFilter: Partial<CartFilter>,
    controller?: AbortController
): Promise<{ list: Cart[]; count: number }> => {
    const graphql = `{
            fetchCartsList(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(cartFilter)}) {
                list {
                    ${GQCartFields}
                }
                count
            }
          }`;

    const data = await fetchApi.getGQ<{ fetchCartsList: { list: Cart[]; count: number } }>(graphql, controller);
    return data.fetchCartsList;
};
