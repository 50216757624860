import { makeObservable } from 'mobx';
import ListTransactionStorePrototype from './prototypes/ListTransactionsStore.prototype';
import * as cartApi from 'api/cartApi';
import { ProductList, StoreIds } from './productStore';

export type Cart = {
    cart_id: number;
    user_id: number;
    product_id: number;
    quantity: number;
    speed: number | null;
    searchRequest: string | null;
    gender: 'male' | 'female' | null;
    status: 1 | 2;

    product: ProductList;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type CartFilter = {
    status: Cart['status'] | null;
    store_id: StoreIds;
    product_id: number | null;
    allUsers: boolean;
    enable: boolean;
};

class CartStore extends ListTransactionStorePrototype<Cart, Cart, {}, CartFilter> {
    constructor() {
        super('cart_id', 'cart', cartApi);

        this.orderBy = 'cart_id';
        this.clearFilter();
        makeObservable(this);
    }

    listFilterClear = {
        status: null,
        store_id: StoreIds.WB,
        product_id: null,
        allUsers: false,
        enable: true
    };
}

export default new CartStore();
