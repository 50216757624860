import { action, makeObservable } from 'mobx';
import * as reviewApi from 'api/reviewApi';
import productStore, { ProductList, ProductPrototypeFilter, StoreIds } from './productStore';
import ListTransactionStorePrototype from './prototypes/ListTransactionsStore.prototype';
import videoStore from './videoStore';
import { CREATING_ITEM_ID } from './prototypes/ItemStore.prototype';

export enum ReviewType {
    Auto = 1,
    Manual
}

export enum ReviewStatusEnum {
    Creating = 1,
    Done,
    WaitingForModeration,
    Cancel,
    WaitingForExecution,
    Error = 100
}

export type Review = {
    review_id: number;
    user_id: number;
    ransom_id: number | null;
    account_id: number;
    accountIsGood: boolean;
    product_id: number | null;

    type: ReviewType;
    planningExecuteTime: null | number;

    reviewText: string;
    prosText: string | null;
    consText: string | null;
    rating: number;
    isRecommended: boolean | null;
    isAnonymously: boolean | null;

    status: ReviewStatusEnum;
    errorMessage: string | null;

    product: ProductList;
    reviewPhotos: string[];
    reviewVideos: string[];

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export interface ReviewsFilter extends ProductPrototypeFilter {
    status: Review['status'] | null;
    statuses: Review['status'][] | null;
    store_id: StoreIds;
    product_id: number | null;
    allUsers: boolean;
    moderation: boolean;
    enable: boolean;
}

export type ReceiveFile = { filename: string; originalname: string };

class ReviewStore extends ListTransactionStorePrototype<
    Review,
    Review,
    { receivePhotos: ReceiveFile[]; receiveVideos: ReceiveFile[] },
    ReviewsFilter
> {
    constructor() {
        super('review_id', 'review', reviewApi);
        this.orderBy = 'createTime';
        this.clearFilter();

        makeObservable(this);
    }

    listFilterClear: ReviewsFilter = {
        status: null,
        statuses: null,
        store_id: StoreIds.WB,
        product_id: null,
        allUsers: false,
        moderation: false,
        enable: true
    };

    @action
    async refreshUpdateWbReview(review_id: number): Promise<void> {
        this.getItem(review_id).loadingItem = true;
        try {
            await reviewApi.refreshUpdateWbReview(review_id);
            await this.fetchItem(review_id);
        } catch (errors) {
            if (errors instanceof Array) {
                this.getItem(review_id).errors = errors;
            }
        } finally {
            videoStore.findWbVideo('review', review_id);
            this.getItem(review_id).loadingItem = false;
        }
    }

    @action
    async sendStatus(review_id: number, status: 2 | 4): Promise<void> {
        this.setEditingItem(review_id, { status });
        await this.saveItem(review_id);
    }

    @action
    addTempFile(review_id: number, file: ReceiveFile, type: 'photo' | 'video'): void {
        const propertyName = type === 'photo' ? 'receivePhotos' : 'receiveVideos';
        let receiveFiles = this.getItem(review_id).property[propertyName];
        receiveFiles = receiveFiles || [];
        receiveFiles = [...receiveFiles, file];

        this.setProperty(review_id, { [propertyName]: receiveFiles });
        this.setEditingItem(CREATING_ITEM_ID, {
            [type === 'photo' ? 'reviewPhotos' : 'reviewVideos']: receiveFiles.map(({ filename }) => filename)
        });
    }

    createEmptyReview(product_id: number, ransom_id: number) {
        productStore.fetchItem(product_id);
        this.fetchItem(CREATING_ITEM_ID);
        this.setEditingItem(CREATING_ITEM_ID, {
            product_id,
            ransom_id
        });
        this.setProperty(CREATING_ITEM_ID, {
            receivePhotos: [],
            receiveVideos: []
        });
    }
}

export default new ReviewStore();
