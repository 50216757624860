import fetchApi from 'common/fetchApi';
import { objectToGraphql } from 'common/graphql';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { WBProxy } from 'stores/wbProxyStore';

export const GQWBProxyFields = `
    proxy_id
    urlScheme
    ip
    port
    login
    pass
    
    createTime
    updateTime
    onlineTime
`;

export const fetchItem = async (proxy_id: number): Promise<WBProxy> => {
    const graphql = `{
            fetchWBProxy(proxy_id: ${proxy_id}) {
                ${GQWBProxyFields}
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchWBProxy: WBProxy }>(graphql);
    return data.fetchWBProxy;
};

export const createItem = async (proxy: Partial<WBProxy>): Promise<number> => {
    const graphql = `mutation { 
          createWBProxy(proxy: ${objectToGraphql(proxy)})
        }`;

    const data = await fetchApi.postGQ<{ createWBProxy: number }>(graphql);
    return data.createWBProxy;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    productFilter: {},
    controller?: AbortController
): Promise<{ list: WBProxy[]; count: number }> => {
    const graphql = `{
            fetchProxiesList {
                list {
                    ${GQWBProxyFields}
                }
                count
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchProxiesList: { list: WBProxy[]; count: number } }>(graphql);
    return data.fetchProxiesList;
};
