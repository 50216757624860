import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import reviewStore, { ReviewType } from 'stores/reviewStore';
import deliveryStore from 'stores/deliveryStore';
import ransomStore from 'stores/ransomStore';
import productStore, { Product, StoreIds } from 'stores/productStore';

import Grid from 'ui/Grid';
import { ErrorMessage } from 'ui/Message';
import ListError from 'ui/ListError';
import Button from 'ui/Button';
import Divider from 'ui/Divider';

import Rating from '@mui/material/Rating';
// import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import ProductCard from '../Product/ProductCard';

import RansomInfoShow from '../Ransom/ShowBlocks/RansomInfoShow';
import ReviewUploadPhotos from './EditBlocks/ReviewUploadPhotos';

import ReviewWarning from './EditBlocks/ReviewWarningMsg';
import SelectReviewProduct from './EditBlocks/SelectReviewProduct';
import ReviewCreateBtn from './EditBlocks/ReviewCreateBtn';
import ReviewTextWB from './EditBlocks/ReviewTextWB';
import ReviewTextOzon from './EditBlocks/ReviewTextOzon';
import ReviewTypeEditing from './EditBlocks/ReviewTypeEditing';
import ReviewPlanningExecuteTime from './EditBlocks/ReviewPlanningExecuteTime';
import ReviewUploadVideo from './EditBlocks/ReviewUploadVideo';

const ReviewCreateMode = () => {
    const review_id = CREATING_ITEM_ID;

    useMemo(() => {
        productStore.fetchList();
        reviewStore.fetchItem(review_id);
        reviewStore.setEditingItem(review_id, {
            type: 1,
            reviewText: '',
            prosText: null,
            consText: null,
            isRecommended: null,
            isAnonymously: null,
            rating: 5,
            planningExecuteTime: null,
            reviewPhotos: [],
            reviewVideos: []
        });
    }, [review_id]);

    const { editingItem, errors } = reviewStore.getItem(review_id);
    const { product_id, ransom_id, rating, type } = editingItem;

    const [showProductSelect, setShowProduct] = useState(true);
    const toggleProductSelect = () => {
        setShowProduct(!showProductSelect);
    };

    useMemo(() => {
        if (ransom_id) {
            ransomStore.fetchItem(ransom_id).then(() => {
                const { product_id } = ransomStore.getItem(ransom_id)?.item || {};
                if (product_id) {
                    productStore.fetchItem(product_id);
                    reviewStore.setEditingItem(review_id, { product_id });
                }
            });
            deliveryStore.fetchItem(ransom_id);
            setShowProduct(false);
        }
    }, [ransom_id, review_id]);

    let firstName = null;
    if (ransom_id) {
        firstName = deliveryStore.getItem(ransom_id).item?.firstName;
    }

    const handleChangeRating = (event: any, rating: number | null) => {
        if (rating) {
            reviewStore.setEditingItem(review_id, { rating });
        }
    };

    let product: Product | null = null;
    if (product_id) {
        const ransomItem = productStore.getItem(product_id);
        product = ransomItem?.item || null;
    }

    let ransom = ransom_id ? ransomStore.getItem(ransom_id).item : null;

    return (
        <Container maxWidth="sm">
            <ReviewWarning />

            <Grid>
                {(!product || showProductSelect) && (
                    <Grid.Column>
                        <SelectReviewProduct review_id={review_id} onClose={toggleProductSelect} />
                    </Grid.Column>
                )}
                {product && (
                    <Grid.Column>
                        {!showProductSelect && (
                            <Button
                                tooltip="Написать отзыв на другой товар"
                                size="small"
                                color="secondary"
                                onClick={toggleProductSelect}
                            >
                                Выбрать другой товар
                            </Button>
                        )}
                        <ProductCard product={product} size="small" />
                    </Grid.Column>
                )}
                {ransom && (
                    <Grid.Column>
                        <RansomInfoShow ransom={ransom} firstName={firstName} />
                    </Grid.Column>
                )}

                <Grid.Column>
                    <ReviewTypeEditing ransomType={ransom?.type} />
                </Grid.Column>

                <Grid.Column>
                    <Typography>Фотографии товара (до 5 штук)</Typography>
                    <ReviewUploadPhotos />
                </Grid.Column>

                <Grid.Column sm={6}>
                    <Typography>Ваша оценка</Typography>
                </Grid.Column>
                <Grid.Column sm={6}>
                    <Rating value={rating} size="large" onChange={handleChangeRating} />
                </Grid.Column>

                <Grid.Column>{product?.store_id === StoreIds.Ozon ? <ReviewTextOzon /> : <ReviewTextWB />}</Grid.Column>

                {type === ReviewType.Auto && (
                    <Grid.Column>
                        <ReviewPlanningExecuteTime review_id={review_id} />
                    </Grid.Column>
                )}

                <ListError errors={errors} />

                <Grid.Column>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                        <ReviewCreateBtn
                            store_id={product?.store_id || StoreIds.WB}
                            disabled={!ransom?.accountIsGood}
                        />
                    </Box>
                </Grid.Column>

                {ransom_id && ransom && !ransom?.accountIsGood && (
                    <Grid.Column>
                        <ErrorMessage header="Проблема в Wildberries">
                            На сайте и в приложение Wildberries временные проблемы. Создать Отзыв временно невозможно.
                        </ErrorMessage>
                    </Grid.Column>
                )}
            </Grid>
        </Container>
    );
};

export default observer(ReviewCreateMode);
