import fetchApi from 'common/fetchApi';
import { objectToGraphql } from 'common/graphql';
import { OrderDirectionType } from 'stores/prototypes/ListStore.prototype';
import { WBAccount } from 'stores/wbAccountStore';

export const GQWBAccountFields = `
    account_id
    phone
    priority
    gender
    WILDAUTHNEW_V3

    onlineTime
    createTime
    updateTime
    enable
`;

export const fetchItem = async (account_id: number): Promise<WBAccount> => {
    const graphql = `{
            fetchWbAccount(account_id: ${account_id}) {
                ${GQWBAccountFields}
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchWbAccount: WBAccount }>(graphql);
    return data.fetchWbAccount;
};

export const saveItem = async (account_id: number, accountDiff: Partial<WBAccount>): Promise<number> => {
    const graphql = `mutation { 
          updateWbAccount(account_id: ${account_id}, accountDiff: ${objectToGraphql(accountDiff)})
        }`;

    const data = await fetchApi.postGQ<{ updateWbAccount: number }>(graphql);
    return data.updateWbAccount;
};

export const createItem = async (wbAccount: Partial<WBAccount>): Promise<number> => {
    const graphql = `mutation { 
          createWBAccount(account: ${objectToGraphql(wbAccount)})
        }`;

    const data = await fetchApi.postGQ<{ createWBAccount: number }>(graphql);
    return data.createWBAccount;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    productFilter: {},
    controller?: AbortController
): Promise<{ list: WBAccount[]; count: number }> => {
    const graphql = `{
            fetchWbAccountList {
                list {
                    ${GQWBAccountFields}
                }
                count
            } 
          }`;

    const data = await fetchApi.getGQ<{ fetchWbAccountList: { list: WBAccount[]; count: number } }>(graphql);
    return data.fetchWbAccountList;
};
