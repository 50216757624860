import React from 'react';

import { Ransom } from 'stores/ransomStore';

import Grid from 'ui/Grid';
import Progress from 'ui/Progress';
import InfoPopup from 'ui/InfoPopup';
import { InfoMessage } from 'ui/Message';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const RansomLongTimeInfo = React.memo(() => (
    <InfoPopup size="large">
        <Typography variant="subtitle1">Почему так долго?</Typography>
        <Typography variant="body2" sx={{ m: 1 }}>
            Создание выкупа занимает продолжительное время, т.к. мы создаем выкуп с применением Пользовательской Модели
            Поведения.
        </Typography>
        <Typography variant="body2" sx={{ m: 1 }}>
            Сначала наш робот вводит ваш поисковой запрос. Переходит на страницу поиска. От туда уходит на карточку
            вашего товара. Добавляет его в корзину и переходит на страницу корзины. Далее заполняются все данные по
            доставке.
        </Typography>
        <Typography variant="body2" sx={{ m: 1 }}>
            Все действия происходят в эмуляторе браузера и после создания выкупа вы сможете посмотреть весь процесс во
            вкладке Видеозапись.
        </Typography>
        <Typography variant="subtitle2" sx={{ m: 1 }}>
            Только сервис TopStore.pro создает выкупы, руководствуясь Пользовательской Моделью Поведения.
        </Typography>
        <Typography variant="caption">
            Важно знать! Такая модель поведения учитывается в маркетплейсе при ранжирование вашего товара. В скором
            времени маркетплейсы перестанут учитывать выкупы, созданные роботом не по Пользовательскому Поведению.
        </Typography>
    </InfoPopup>
));

const NotPreRunInfo = ({ paymentType }: { paymentType: Ransom['paymentType'] }) => {
    return (
        <>
            {paymentType === 1 && <>Идет создание выкупа, дождитесь QR-кода для оплаты (≈60-90 секунд).</>}
            {paymentType === 2 && (
                <>Идет создание выкупа, дождитесь получения уведомления от Сбербанк Онлайн (≈60-90 секунд).</>
            )}
            {paymentType === 3 && <>Идет создание выкупа, дождитесь ссылку на оплату картой (≈60-90 секунд).</>}
        </>
    );
};

const PreRunInfo = ({ paymentType }: { paymentType: Ransom['paymentType'] }) => {
    return (
        <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
                <DirectionsRunIcon />
                <div>Происходит прогон аккаунта (≈20-30 минут).</div>
            </Stack>
            <div>
                {paymentType === 1 && (
                    <>Как только QR-код для оплаты будет готов, мы пришлем вам уведомление в телеграм.</>
                )}
                {paymentType === 2 && (
                    <>
                        Как только выкуп будет готов, вы получите уведомление от Сбербанк Онлайн. Так же мы пришлем вам
                        уведомление в телеграм.
                    </>
                )}
                {paymentType === 3 && (
                    <>Как только ссылка на оплату будет готова, мы пришлем вам уведомление в телеграм.</>
                )}
            </div>
        </Stack>
    );
};

const RansomCreatingMessage = ({
    paymentType,
    preRunAccount
}: {
    paymentType: Ransom['paymentType'];
    preRunAccount: boolean;
}) => {
    return (
        <Grid.Column>
            <InfoMessage
                header={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        Оформление выкупа
                        {!preRunAccount && <RansomLongTimeInfo />}
                    </Box>
                }
            >
                {!preRunAccount && <NotPreRunInfo paymentType={paymentType} />}
                {preRunAccount && <PreRunInfo paymentType={paymentType} />}
            </InfoMessage>
            <Progress show linear />
        </Grid.Column>
    );
};

export default React.memo(RansomCreatingMessage);
