import React from 'react';
import { observer } from 'mobx-react';

import { CREATING_ITEM_ID } from 'stores/prototypes/ItemStore.prototype';
import productStore, { whetherProductIsOzon, whetherProductIsWB } from 'stores/productStore';

import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Grid from 'ui/Grid';
import ListError from 'ui/ListError';
import Button from 'ui/Button';

import { RansomLinkState } from '../Ransom/RansomLink';
import locationHistory from 'locationHistory';
import ransomStore from 'stores/ransomStore';
import commonStore from 'stores/commonStore';

import PaymentIcon from '@mui/icons-material/Payment';
import Rating from '@mui/material/Rating';

import ProductPhotosSlider from './ShowBlocks/ProductPhotosSlider';
import ProductActionsButtons from './ShowBlocks/ProductActionsButtons';
import BrandToFavorite from './ShowBlocks/BrandToFavorite';
import ProductColorTabs from './StoreBlocks/ProductColorTabs';
import OptionsAndTagsWB from './StoreBlocks/OptionsAndTagsWB';
import ProductSizesShow from './ShowBlocks/ProductSizesShow';
import TitleLinkWb from './StoreBlocks/TitleLinkWb';
import TitleLinkOzon from './StoreBlocks/TitleLinkOzon';
import AspectsItemOzon from './StoreBlocks/AspectsItemOzon';
import BrandInfoBlock from '../Brand/BrandInfoBlock';

const ProductShowMode = ({ product_id }: { product_id: number }) => {
    const { errors, item } = productStore.getItem(product_id);

    const toggleRansomModal = () => {
        setTimeout(() => {
            ransomStore.setEditingItem(CREATING_ITEM_ID, {
                product_id
            });
        }, 0);
        locationHistory.push(RansomLinkState(CREATING_ITEM_ID));
    };

    if (!item) {
        return null;
    }

    const { store_item_id } = item;
    const { price, brand, description, title, photos, ordersCount, feedbacksCount, star } =
        productStore.calcShortProduct(item);

    return (
        <>
            <ListError errors={errors} />

            <Grid
                spacing={1}
                columns={!whetherProductIsWB(item) ? 3 : commonStore.isMobile ? 2 : 4}
                alignItems="center"
            >
                <Grid.Column>
                    {whetherProductIsWB(item) && <TitleLinkWb wb_item_id={item.wbItem.wb_item_id} />}
                    {whetherProductIsOzon(item) && (
                        <TitleLinkOzon ozon_item_id={item.ozonItem.ozon_item_id} itemUrl={item.ozonItem.itemUrl} />
                    )}
                </Grid.Column>
                <Grid.Column>
                    <Rating value={star} readOnly />
                </Grid.Column>
                {whetherProductIsWB(item) && (
                    <Grid.Column>
                        <Typography variant="body2">
                            Продаж: {ordersCount ? `более ${ordersCount.toLocaleString()}` : '-'}
                        </Typography>
                    </Grid.Column>
                )}
                <Grid.Column>
                    <Typography variant="body2">
                        отзывов: {feedbacksCount ? feedbacksCount.toLocaleString() : '-'}
                    </Typography>
                </Grid.Column>
            </Grid>
            <Grid alignItems="flex-start" spacing={3} stackable disableGutter>
                <Grid.Column width={5}>
                    <Paper elevation={1} sx={{ p: 2 }}>
                        <ProductPhotosSlider photos={photos ? photos.slice(0, 14) : []} />
                    </Paper>
                </Grid.Column>
                <Grid.Column width={7}>
                    <Typography variant="h4" sx={{ overflowX: 'auto' }}>
                        {title || 'Наименование не задано'}
                    </Typography>

                    {whetherProductIsWB(item) && <ProductSizesShow sizes={item.wbItem.sizes} />}
                    {whetherProductIsOzon(item) && <AspectsItemOzon ozonItem={item.ozonItem} />}

                    <Grid spacing={2} justify="flex-end" stackable>
                        {price && (
                            <Grid.Column width={6}>
                                <Stack spacing={2} direction="row">
                                    <Typography variant="h4" style={{ whiteSpace: 'nowrap' }}>
                                        {price?.priceWithSale.toLocaleString()} ₽
                                    </Typography>
                                    <Typography variant="caption" style={{ textDecoration: 'line-through' }}>
                                        {price?.price.toLocaleString()} ₽
                                    </Typography>
                                </Stack>
                            </Grid.Column>
                        )}
                        <Grid.Column width={6}>
                            {price && (
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    onClick={toggleRansomModal}
                                    fullWidth
                                    startIcon={<PaymentIcon />}
                                >
                                    Добавить выкуп
                                </Button>
                            )}
                            {!price && <Chip label="Нет в наличии" color="warning" size="medium" />}
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <BrandInfoBlock store_id={item.store_id} brand={brand}>
                                <BrandToFavorite
                                    product_id={product_id}
                                    disabled={!brand.brandUrl || (whetherProductIsWB(item) && !brand.logoUrl)}
                                />
                            </BrandInfoBlock>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>

            <ProductActionsButtons product_id={product_id} />

            {whetherProductIsWB(item) && item.colors.length > 1 && (
                <ProductColorTabs
                    colors={item.colors}
                    activeWbId={store_item_id}
                    color={item.colors.find(color => color.wb_item_id === store_item_id)}
                    product_id={product_id}
                />
            )}

            <Paper elevation={1} sx={{ padding: '0 2rem 2rem', my: 3 }}>
                <Grid alignItems="flex-start" spacing={3} stackable>
                    <Grid.Column width={12}>
                        <Typography variant="h6">Описание</Typography>
                        <Typography variant="body2">{description}</Typography>
                    </Grid.Column>
                    {whetherProductIsWB(item) && (
                        <Grid.Column width={12}>
                            <Typography variant="h6">Состав</Typography>
                            <Typography variant="body2">{item.wbItem.consist}</Typography>
                        </Grid.Column>
                    )}
                </Grid>
            </Paper>

            {whetherProductIsWB(item) && <OptionsAndTagsWB wbItem={item.wbItem} />}
        </>
    );
};

export default observer(ProductShowMode);
