import React from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import FFPriceCalculator from './FFPriceCalculator';
import FFPriceDelivery from './FFPriceDelivery';

const FFPrice = () => {
    return (
        <Stack spacing={2}>
            <Typography variant="h4">Тарифы</Typography>

            <Typography variant="body1">
                Мы исключили все скрытые комиссии и сделали максимально простую формулу расчета
            </Typography>

            <Typography variant="h5" color="secondary">
                Стоимость услуг фулфилмента, калькулятор
            </Typography>

            <FFPriceCalculator />

            <Typography variant="h5" color="secondary">
                Стоимость отправки
            </Typography>

            <FFPriceDelivery />
        </Stack>
    );
};

export default React.memo(FFPrice);
